import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Row, Col, Divider, Spin, Card, Icon, Layout, Table, Button, Modal, Typography, Popconfirm, Tag, Popover } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
	getFeedbackList,
	deleteFeedback,
	confirmFeedback
} from "../../actions/feedbackActions";


import Moment from 'react-moment';
import 'moment/locale/ru';

const CPFeedback = props => {
	useFetching(getFeedbackList, useDispatch());
	const dispatch = useDispatch();

	const feedbacks = useSelector(state => state.feedback.feedbacks);
	const loading = useSelector(state => state.feedback.isLoading);

	const handleDelFeedback = (feedback) => {
		dispatch(deleteFeedback(feedback._id));
	};

	function renderFeedbackInfo(feedback) {
		return (
			<>
				<Row gutter={16}>
					<Col className="gutter-row" xs={24}>
					<div key={feedback.id}>
						<div>
							<span><b>Имя:</b></span><br />
							<span>{feedback.name}</span><br />

							<br />
							<span><b>Телефон:</b></span><br />
							<span>{feedback.phone}</span><br />

							<br />
							<span><b>E-mail:</b></span><br />
							<span>{feedback.email}</span><br />

							<br />
							<span><b>Текст:</b></span><br />
							<span>{feedback.text}</span><br />

						</div>
					</div>
					</Col>
				</Row>
				<Divider/>
				<Row gutter={16}>
					<Col className="gutter-row" xs={24}>
						<Popconfirm title="Удалить обращение?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelFeedback(feedback)}>
							<Button style={{ marginRight: 5 }} size="small" type="danger" ghost icon="delete">УДАЛИТЬ</Button>
						</Popconfirm>
					</Col>
				</Row>
			</>
		);
	}

	function renderConfirmFeedbackPopup(row) {

		console.log(row._id)

		return (
			<>

				<Button type="primary" onClick={() => dispatch(confirmFeedback(row._id))}>Обработать</Button>
			</>
		)

	}


	function renderSort() {

		const columns = [
			{
				title: '', dataIndex: 'created_at', key: 'created_at',
				render: (created_at, row) => {
					return (
						<>
							{row.confirmed_at != null ? (<></>) : (<>
								<Popover content={renderConfirmFeedbackPopup(row)} trigger="hover">
									<Button icon="check" style={{ marginRight: 5, backgroundColor: '#9FBE34', border: 0 }} type="primary" ></Button>
								</Popover></>)}
							Поступило - <Moment date={created_at} format="L" locale="ru" /> - <Moment date={created_at} format="LT" locale="ru" /><br />
							{row.confirmed_at != null ? (<>Подтверждено - <Moment date={row.confirmed_at} format="L" locale="ru" /> - <Moment date={row.confirmed_at} format="LT" locale="ru" /></>) : (<></>)}
						</>
					)
				}
			},
			{ title: 'Имя', dataIndex: 'name', key: 'name' },
			{ title: 'Телефон', dataIndex: 'phone', key: 'phone' },
			{ title: 'E-mail', dataIndex: 'email', key: 'email' },
			{
				title: 'Дата', dataIndex: 'created_at', key: 'created_at',
				render: time => {
					let timestamp = Date.parse(time);
					var date = new Date(timestamp);
					var formattedDate = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ' ' + ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
					return (
						<Tag>{formattedDate}</Tag>
					);
				}
			}
		];

		if (feedbacks.length > 0) {
			return (
				<Table
					rowKey={record => record._id}
					style={{ marginTop: 20 }}
					columns={columns}
					expandedRowRender={record => renderFeedbackInfo(record)}
					dataSource={feedbacks}
				/>
			);
		} else {
			return (
				<div>
					<Card title="Обращений не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	}

	if (loading) {
		return (
				<div className="content-div">
					<Divider className="header-div" orientation="left"><Spin /></Divider>
				</div>
		);
	} else {
		return (
				<QueueAnim className="anim-content"
					type={['right', 'left']}
					ease={['easeOutQuart', 'easeInOutQuart']}>
					{!loading ? [
						<div key="a" className="content-div">
							<Divider className="header-div" orientation="left">Обращения</Divider>
							<Row type="flex" justify="end">
							</Row>
							{renderSort()}
							<Divider />
						</div>
					] : null}
				</QueueAnim>
		);
	}
};

const useFetching = (getFeedbackList, dispatch) => {
	useEffect(() => {
		dispatch(getFeedbackList());
	}, []);
};

export default CPFeedback;
