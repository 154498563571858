import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getIntegrationSettings, editArchiSettings, editDeliveryclubSettings, saveArchiSettings, saveDeliveryclubSettings } from '../../actions/integrationActions'
import { Select, Checkbox, Button, Typography, Divider, Spin, Layout, Card, Input } from 'antd';
import QueueAnim from 'rc-queue-anim';
const { Option } = Select;


const CPIntegrations = props => {
  useFetching(getIntegrationSettings, useDispatch());
  const dispatch = useDispatch();
  const loading = useSelector(state => state.integrations.isLoading);

  const archiEnabled = useSelector(state => state.integrations.archiEnabled);
  const deliveryClubEnabled = useSelector(state => state.integrations.deliveryClubEnabled);

  const needSaveArchi = useSelector(state => state.integrations.needSaveArchi);
  const needSaveDeliveryclub = useSelector(state => state.integrations.needSaveDeliveryclub);

  const archiSendOrders = useSelector(state => state.integrations.archiSettings.sendOrders);
  const archiUseBonus = useSelector(state => state.integrations.archiSettings.useBonus);
  const archiIp = useSelector(state => state.integrations.archiSettings.ip);
  const archiSettings = useSelector(state => state.integrations.archiSettings);

  const handleArchiChange = (e, from) => {
    var newArchiEnabled = archiEnabled;
    var newArchiSendOrders = archiSendOrders;
    var newArchiUseBonus = archiUseBonus;
    var newArchiIp = archiIp;
    var newArchiSettings = archiSettings;

    if (from == 'enabled') {
      newArchiEnabled = e;
    }
    if (e.target != null) {
      if (e.target.name == 'sendOrders') {
        newArchiSendOrders = e.target.checked;
        console.log('newArchiSendOrders '+newArchiSendOrders);
      }
      if (e.target.name == 'useBonus') {
        newArchiUseBonus = e.target.checked;
        console.log('newArchiUseBonus '+newArchiUseBonus);
      }
      if (e.target.name == 'archiIp') {
        newArchiIp = e.target.value;
        console.log('newArchiIp '+newArchiIp);
      }
    }
    const data = {
      newArchiEnabled,
      newArchiSettings: {
        newArchiSendOrders,
        newArchiUseBonus,
        newArchiIp,
      }
    }
    dispatch(editArchiSettings(data));
  }

  const handleSaveArchiButton = (e) => {
    const setsettings = {
      enabled: archiEnabled,
      settings: archiSettings,
    }
    dispatch(saveArchiSettings(setsettings));
  };

  const handleDeliveryclubChange = (e, from) => {
    var newDeliveryClubEnabled = deliveryClubEnabled;
    if (from == 'enabled') {
      newDeliveryClubEnabled = e;
    }
    const data = {
      newDeliveryClubEnabled,
    }
    dispatch(editDeliveryclubSettings(data));
  }
  const handleSaveDeliveryclubButton = (e) => {
    const setsettings = {
      enabled: deliveryClubEnabled
    }
    dispatch(saveDeliveryclubSettings(setsettings));
  };

  function renderArchi() {
    return (
      <div>
        <Card title={'ArchiDelivery'} style={{ width: '100%', marginBottom: 20 }} size="small">
          <Typography>Включить:</Typography>
          <Select
            defaultValue={archiEnabled}
            style={{ width: 220, marginBottom: 10 }}
            onChange={(e) => handleArchiChange(e, "enabled")}
          >
            <Option value={0}>Нет</Option>
            <Option value={1}>Да</Option>
          </Select>
          {archiEnabled ? renderArchiSettings() : <div></div>}
          <Button disabled={!needSaveArchi} type="primary" icon="save" onClick={handleSaveArchiButton}>Сохранить</Button>
        </Card>
      </div>
    )
  }

  function renderArchiSettings() {
    return (
      <div>
        <Divider />
        <Checkbox name={"sendOrders"} checked={archiSendOrders} onChange={(e) => handleArchiChange(e, "sendOrders")}>Отправлять заказы</Checkbox>
        <Checkbox name={"useBonus"} checked={archiUseBonus} onChange={(e) => handleArchiChange(e, "useBonus")}>Использовать бонусную систему на стороне ArchiDelivery</Checkbox>
        <Divider />
        <Typography>IP-адрес сервера:</Typography>
									<Input
										value={archiIp}
										name="archiIp"
										style={{ width: 220, marginBottom: 10 }}
										onChange={(e) => handleArchiChange(e, "archiIp")}
									/>
        <Divider />
      </div>
    )
  }

  function renderDeliveryclub() {
    return (
      <div>
        <Card title={'Delivery Club'} style={{ width: '100%', marginBottom: 20 }} size="small">
          <Typography>Включить:</Typography>
          <Select
            defaultValue={deliveryClubEnabled}
            style={{ width: 220, marginBottom: 10 }}
            onChange={(e) => handleDeliveryclubChange(e, "enabled")}
          >
            <Option value={0}>Нет</Option>
            <Option value={1}>Да</Option>
          </Select>
          {deliveryClubEnabled ? renderDeliveryclubSettings() : <div></div>}
          <Button disabled={!needSaveDeliveryclub} type="primary" icon="save" onClick={handleSaveDeliveryclubButton}>Сохранить</Button>
        </Card>
      </div>
    )
  }

  function renderDeliveryclubSettings() {
    return (
      <div>
        <p>Delivery Сlub в настоящий момент не поддерживается</p>
      </div>
    )
  }

  if (loading) {
    return (
        <div className="content-div">
          <Divider className="header-div" orientation="left"><Spin /></Divider>
        </div>
    );
  } else {
    return (
        <QueueAnim className="anim-content"
          type={['right', 'left']}
          ease={['easeOutQuart', 'easeInOutQuart']}>
          {!loading ? [
            <div key="a" className="content-div">
              <div>
                <Divider className="header-div" orientation="left">Настройки интеграций</Divider>
              </div>
              {renderArchi()}
              {/* {renderDeliveryclub()} */}
            </div>
          ] : null}
        </QueueAnim>
    );
  }

};

const useFetching = (getIntegrationSettings, dispatch) => {
  useEffect(() => {
    dispatch(getIntegrationSettings());
  }, []);
};

export default CPIntegrations;
