import { EDIT_API_SETTINGS_ORDER_STATUS, GET_API_SETTINGS, LOADING_API_SETTINGS, EDIT_API_SETTINGS, SAVED_API_SETTINGS, SAVED_API_SETTINGS_ERRORS, EDIT_API_SETTINGS_CLIENT_STATUS, EDIT_API_SETTINGS_CRM_STATUS } from "../actions/types";

const initialState = {
  isLoading: true,
  smscAuth: 0,
  smscLogin: '',
  smscPassword: '',
  needSave: false,
  onlinePaymentType: 0,
  fondyMerchantId: 0,
  yandexShopId: 0,
  yandexKey: '',
  cloudShopId: '',
  cloudKey: '',
  applePay: 0,
  googlePay: 0,
  currencySymbol: '',
  currencyCode: '',
  clientInitStatus: '',
  clientInitStatusColor: '',
  crmInitStatus: '',
  crmInitStatusColor: '',
  dadataEnabled: false,
  dadataAPI: '',
  orderStatuses: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EDIT_API_SETTINGS_CLIENT_STATUS:
      return {
        ...state,
        customClientStatuses: action.payload,
        isLoading: false,
        needSave: true,
      }
    case EDIT_API_SETTINGS_CRM_STATUS:
      return {
        ...state,
        customCrmStatuses: action.payload,
        isLoading: false,
        needSave: true,
      }
    case EDIT_API_SETTINGS_ORDER_STATUS:
      return {
        ...state,
        orderStatuses: action.payload,
        isLoading: false,
        needSave: true,
      }
    case GET_API_SETTINGS:
      console.log('GET_API_SETTINGS');
      console.log(action.payload.apisettings[0]);
      return {
        ...state,
        smscAuth: action.payload.apisettings[0].smscAuth,
        smscLogin: action.payload.apisettings[0].smscLogin,
        smscPassword: action.payload.apisettings[0].smscPassword,
        onlinePaymentType: action.payload.apisettings[0].onlinePaymentType,
        fondyMerchantId: action.payload.apisettings[0].fondyMerchantId,
        yandexShopId: action.payload.apisettings[0].yandexShopId,
        yandexKey: action.payload.apisettings[0].yandexKey,
        cloudShopId: action.payload.apisettings[0].cloudShopId,
        cloudKey: action.payload.apisettings[0].cloudKey,

        applePay: action.payload.apisettings[0].applePay,
        googlePay: action.payload.apisettings[0].googlePay,
        currencySymbol: action.payload.apisettings[0].currencySymbol,
        currencyCode: action.payload.apisettings[0].currencyCode,
        clientInitStatus: action.payload.apisettings[0].clientInitStatus,
        crmInitStatus: action.payload.apisettings[0].crmInitStatus,
        clientInitStatusColor: action.payload.apisettings[0].clientInitStatusColor,
        crmInitStatusColor: action.payload.apisettings[0].crmInitStatusColor,
        orderStatuses: action.payload.apisettings[0].orderStatuses,
        dadataEnabled: action.payload.apisettings[0].dadataEnabled,
        dadataAPI: action.payload.apisettings[0].dadataAPI,
        isLoading: false,
        needSave: false,
      };
    case EDIT_API_SETTINGS:
      console.log(action.payload);
      return {
        ...state,
        smscAuth: action.payload.newSmscAuth,
        smscLogin: action.payload.newSmscLogin,
        smscPassword: action.payload.newSmscPassword,
        onlinePaymentType: action.payload.newOnlinePaymentType,
        yandexShopId: action.payload.newYandexShopId,
        yandexKey: action.payload.newYandexKey,
        cloudShopId: action.payload.newCloudShopId,
        cloudKey: action.payload.newCloudKey,

        fondyMerchantId: action.payload.newFondyMerchantId,
        applePay: action.payload.newApplePay,
        googlePay: action.payload.newGooglePay,
        currencySymbol: action.payload.newCurrencySymbol,
        currencyCode: action.payload.newCurrencyCode,
        clientInitStatus: action.payload.newClientInitStatus,
        crmInitStatus: action.payload.newCrmInitStatus,
        clientInitStatusColor: action.payload.newClientInitStatusColor,
        crmInitStatusColor: action.payload.newCrmInitStatusColor,
        dadataEnabled: action.payload.newDadataEnabled,
        dadataAPI: action.payload.newDadataAPI,
        needSave: true,
      };
    case SAVED_API_SETTINGS:
      return {
        ...state,
        needSave: false,
        showMsg: true
      };
    case LOADING_API_SETTINGS:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
}
