import {
    NOMENCLATURE_LIST_LOADED,
    NOMENCLATURE_LIST_LOADING,
    NOMENCLATURE_ADDED,
    NOMENCLATURE_ADDING
} from "../actions/types";

const initialState = {
    isLoading: true,
    isAdding: false,
    nomenclatures: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case NOMENCLATURE_LIST_LOADED:
            return {
                ...state,
                nomenclatures: action.payload.nomenclatures,
                isLoading: false,
            }
        case NOMENCLATURE_LIST_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case NOMENCLATURE_ADDING:
            return {
                ...state,
                isAdding: true
            }
        case NOMENCLATURE_ADDED:
            return {
                ...state,
                nomenclatures: [...state.nomenclatures, action.payload.nomenclature],
                isAdding: false
            }
        default:
            return state;
    }
}
