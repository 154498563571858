import {
    GET_DELIVERY_SERVICES_LIST,
    LOADING_DELIVERY_SERVICES,
    GET_DELIVERY_SERVICES_ERRORS,
} from "../actions/types";

const initialState = {
    isLoading: true,
    deliveryservices: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DELIVERY_SERVICES_LIST:
            return {
              ...state,
              isLoading: false,
              deliveryservices: action.payload.deliveryservices,
            };
          case LOADING_DELIVERY_SERVICES:
            return {
              ...state,
              isLoading: true,
            };
        default:
            return state;
    }
}
