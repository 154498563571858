import { combineReducers } from "redux";
import authReducers from "./authReducers";
import errorReducers from "./errorReducers";
import itemReducers from "./itemReducers";
import optionReducers from "./optionReducers";
import categoryReducers from "./categoryReducers";
import settingReducers from "./settingReducers";
import apiSettingReducers from "./apiSettingReducers";
import deliveryReducers from "./deliveryReducers";
import paymentReducers from "./paymentReducers";
import orderReducers from "./orderReducers";
import integrationReducers from "./integrationReducers";
import customerReducers from "./customerReducers";
import articlesReducers from "./articlesReducers";
import bonusSystemReducers from "./bonusSystemReducers";
import webStaticPageReducer from "./webStaticPageReducer";
import webSettingReducer from "./webSettingReducer";
import itemFilterReducers from "./itemFilterReducers";
import reviewReducers from "./reviewReducers";
import newsReducers from "./newsReducers";
import promotionReducers from "./promotionReducers";
import userReducers from "./userReducers";
import feedbackReducers from "./feedbackReducers";
import reserveReducers from "./reserveReducers";
import printSettingsReducers from "./printSettingsReducers";
import couriersReducers from "./couriersReducers";
import emailSettingsReducers from "./emailSettingsReducers";
import cartGiftsReducers from "./cartGiftsReducers";
import deliveryServicesReducers from "./deliveryServicesReducers";
import pushSendReducers from "./pushSendReducers";
import couponsReducers from "./couponsReducers";
import iikoReducers from "./iikoReducers";
import sumoIikoReducers from "./sumoIikoReducers";
import seoPagesReducers from "./seoPagesReducers";
import timesheetReducers from "./timesheetReducers";
import nomenclatureReducers from "./nomenclatureReducers";
import complectReducers from "./complectReducers";

import reportReducers from "./reportReducers";

export default combineReducers({
	nomenclature:nomenclatureReducers,
	complect:complectReducers,
	reports:reportReducers,
	timesheet: timesheetReducers,
	articles: articlesReducers,
	integrations: integrationReducers,
	payment: paymentReducers,
	settings: settingReducers,
	apisettings: apiSettingReducers,
	delivery: deliveryReducers,
	items: itemReducers,
	customers: customerReducers,
	options: optionReducers,
	categories: categoryReducers,
	orders: orderReducers,
	auth: authReducers,
	errors: errorReducers,
	bonus: bonusSystemReducers,
	webstaticpage: webStaticPageReducer,
	websettings: webSettingReducer,
	itemfilters: itemFilterReducers,
	review: reviewReducers,
	news: newsReducers,
	promotions: promotionReducers,
	users: userReducers,
	feedback: feedbackReducers,
	reserve: reserveReducers,
	printsettings: printSettingsReducers,
	couriers: couriersReducers,
	emailsettings: emailSettingsReducers,
	cartgifts: cartGiftsReducers,
	deliveryservices: deliveryServicesReducers,
	pushsend: pushSendReducers,
	coupons: couponsReducers,
	iiko: iikoReducers,
	sumoiiko: sumoIikoReducers,
	seopages: seoPagesReducers,
});
