import React, { useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Select, Input, Form, Tag, Button, Row, Col, Table, Divider, Spin, Card, Icon, Layout, Modal, List, Popconfirm, Typography, Radio, Popover, DatePicker, InputNumber, Alert } from 'antd';
import {
  loadClientBonus,
  checkPayment,
  getOrderList,
  showContentModal,
  closeContentModal,
  showStatusModal,
  closeStatusModal,
  saveOrderStatus,
  delItemFromOrder,
  showAddToOrder,
  closeAddToOrder,
  loadItems,
  loadItemsWithCategories,
  addAddItem,
  showChequeModal,
  closeChequeModal,
  showCourierAdd,
  closeCourierAdd,
  getCourierList,
  addCourierToOrder,
  getNewOrder,
  changeOrderStatus,
  changeOrderPayment,
  changeOrderDeliveryDate,
  changeOrderCourier,
  changeOrderPaidStatus,
  changeOrderDeliveryPrice,
  getPhoneFiltered,
  getEmailFiltered,
  getNumberFiltered,
  showCreateOrderModal,
  hideCreateOrderModal,
  searchClientEmail,
  searchClientPhone,
  resetNewOrderSearch,
  createOrder,
  createNewOrder,
  openCalcChange,
  closeCalcChange,
  sendCalcChange,
  openEditComment,
  closeEditComment,
  sendEditComment,
  getSmallReport,
  showAddressModal,
  closeAddressModal,
  saveAddress,
  getSmartFiltered,
  getCourierFiltered,
  showPrintCurrentFilterDay,
  hidePrintCurrentFilterDay,
  applyBonus,
  toggleKitering,
  showHideKitering
} from "../../actions/orderActions";
import QueueAnim from 'rc-queue-anim';
import Moment from 'react-moment';
import 'moment/locale/ru';
import moment from 'moment';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import { useReactToPrint } from 'react-to-print';
import ReactToPrint from 'react-to-print';
import locale from 'antd/es/date-picker/locale/ru_RU';
//import EditableTable from 'antd-editabletable';
import MaskedInput from 'antd-mask-input'
import Grid from "antd/lib/card/Grid";
import { T } from "antd/lib/upload/utils";

const { Option } = Select;
const { Title } = Typography;
const { Text } = Typography;
const { TextArea } = Input;

class ComponentToPrint extends React.Component {
  render() {

    var totals = 0;

    this.props.chequeToPrint.cartitems.map((cartitem, i) => {
      console.log(cartitem);
      totals += parseInt(cartitem.price, 10) * parseInt(cartitem.quantity, 10);
    })

    return (
      <>
        <div className="cheque-80mm-no-border">
          <div className="cheque-title-80mm">
            <span>{this.props.cheque_title}</span>
          </div>
          <div className="cheque-field_1-80mm">
            <span>{this.props.cheque_field_1}</span>
          </div>
          <div className="cheque-field_2-80mm">
            <span>{this.props.cheque_field_2}</span>
          </div>
          <div className="cheque-field_3-80mm">
            <span>{this.props.cheque_field_3}</span>
          </div>
          {this.props.chequeToPrint.deliveryTime != undefined ? (
            <div className="cheque-date-80mm">
              Доставить к: <span><Moment date={this.props.chequeToPrint.deliveryTime} format={'L HH:mm'} locale="ru"></Moment></span>
            </div>
          ) : (<></>)}
          {this.props.cheque_courier_name != undefined ? (
            <div className="">
              Курьер: <span>{this.props.cheque_courier_name}</span>
            </div>
          ) : (<></>)}
          <div className="cheque-number-80mm">
            <span>##{this.props.chequeToPrint.id}</span>
          </div>

          {this.props.cheque_user_name ? (<div className="cheque-user-80mm">
            <span>Сотрудник: {this.props.user}</span>
          </div>) : (<></>)}

          <div className="cheque-table-80mm">


            <div className="cheque-table-80mm-div-h">
              <div className="cheque-table-80mm-div-1"><span><b>Наименование</b></span></div>
              <div className="cheque-table-80mm-div-2"><span><b>Кол.</b></span></div>
              <div className="cheque-table-80mm-div-3"><span><b>Сум.</b></span></div>
            </div>

            {this.props.chequeToPrint.cartitems.map((cartitem, i) => (
              <div key={i.toString()} className="cheque-table-80mm-div">
                <div className="cheque-table-80mm-div-1"><span>{cartitem.name}
                  <div className="cheque-table-80mm-div-options">
                    {cartitem.selectedOptions.map(option => {
                      if (!option.label.includes('Не добавлять')) {
                        return (
                          <>
                            <span> {option.name}: </span><span>{option.label}</span><br />
                          </>
                        )
                      }

                    }
                    )}
                  </div>
                </span></div>
                <div className="cheque-table-80mm-div-2"><span>{cartitem.quantity}шт</span></div>
                <div className="cheque-table-80mm-div-3"><span>{cartitem.price * cartitem.quantity}</span></div>
              </div>
            ))}

            <div className="cheque-table-80mm-div-itogo">
              <div className="cheque-table-80mm-div-1"><span>ИТОГО</span></div>
              <div className="cheque-table-80mm-div-4"><span>{totals}</span></div>
            </div>

            {this.props.chequeToPrint.usedbonus != 0 ? (
              <div className="cheque-table-80mm-div-sub">
                <div className="cheque-table-80mm-div-1"><span>Скидка</span></div>
                <div className="cheque-table-80mm-div-4"><span>{this.props.chequeToPrint.usedbonus}</span></div>
              </div>
            ) : (<></>)}

            {this.props.chequeToPrint.deliveryprice != 0 ? (
              <div className="cheque-table-80mm-div-sub">
                <div className="cheque-table-80mm-div-1"><span>Доставка</span></div>
                <div className="cheque-table-80mm-div-4"><span>{this.props.chequeToPrint.deliveryprice}</span></div>
              </div>
            ) : (<></>)}

            <div className="cheque-table-80mm-div-itogo" style={{ borderBottom: 'none' }}>
              <div className="cheque-table-80mm-div-1"><span><b>К ОПЛАТЕ</b></span></div>

              <div className="cheque-table-80mm-div-4"><span><b>
                {parseInt(this.props.chequeToPrint.summa, 10) + parseInt(this.props.chequeToPrint.deliveryprice, 10)}
              </b></span></div>
            </div>


          </div>
          <div className="cheque-field_4-80mm">
            <span>{this.props.cheque_field_4}</span>
          </div>
          {this.props.cheque_customer_data ? (
            <div className="cheque-customer-80mm">

              <div className="cheque-table-80mm-div-extra">
                <div className="cheque-table-80mm-div-6">Тип оплаты:</div>
                <div className="cheque-table-80mm-div-7">{this.props.paymentName}</div>
              </div>
              <div className="cheque-table-80mm-div-extra">
                <div className="cheque-table-80mm-div-6">Оплачено:</div>
                <div className="cheque-table-80mm-div-7">{this.props.chequeToPrint.paid ? (<>Да</>) : (<>Нет</>)}</div>
              </div>
              <div className="cheque-table-80mm-div-extra">
                <div className="cheque-table-80mm-div-6">Клиент:</div>
                <div className="cheque-table-80mm-div-7">{this.props.chequeToPrint.clientname}</div>
              </div>

              <div className="cheque-table-80mm-div-extra">
                <div className="cheque-table-80mm-div-6">Улица:</div>
                <div className="cheque-table-80mm-div-7">{this.props.chequeToPrint.clientstreet}</div>
              </div>

              <div className="cheque-table-80mm-div-extra">
                <div className="cheque-table-80mm-div-6">Дом:</div>
                <div className="cheque-table-80mm-div-7">{this.props.chequeToPrint.clientbuilding}|{this.props.chequeToPrint.clientbuildingextra}</div>
              </div>

              <div className="cheque-table-80mm-div-extra">
                <div className="cheque-table-80mm-div-6">Этаж:</div>
                <div className="cheque-table-80mm-div-7">{this.props.chequeToPrint.clientappartmentslevel}</div>
              </div>

              <div className="cheque-table-80mm-div-extra">
                <div className="cheque-table-80mm-div-6">Кв. (офис):</div>
                <div className="cheque-table-80mm-div-7">{this.props.chequeToPrint.clientappartments}</div>
              </div>

              <div className="cheque-table-80mm-div-extra">
                <div className="cheque-table-80mm-div-6">Телефон:</div>
                <div className="cheque-table-80mm-div-7">{this.props.chequeToPrint.clientphone}</div>
              </div>

              <div className="cheque-table-80mm-div-extra">
                <div className="cheque-table-80mm-div-6">Примечание:</div>
                <div className="cheque-table-80mm-div-7">{this.props.chequeToPrint.clientcomment}</div>
              </div>

              {this.props.chequeToPrint.comment != '' ? (
                <div style={{ width: '90%', margin: '0 auto', borderTop: '1px solid black', marginTop: 20 }}>
                  <div style={{ width: '100%' }}>Заметки: </div>
                  <div style={{ width: '100%' }}>{this.props.chequeToPrint.comment}</div>
                </div>
              ) : (<></>)}

            </div>
          ) : (<></>)}
        </div>
      </>
    );
  }
}

function groupBy(arr, property) {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) { memo[x[property]] = []; }
    memo[x[property]].push(x);
    return memo;
  }, {});

}


class OrdersToPrint extends React.Component {
  render() {
    if (this.props.orderstoPrint != null) {
      var ordersArray = [];
      ordersArray = [...this.props.orderstoPrint];

      for (let index = 0; index < ordersArray.length; index++) {
        var m = moment(ordersArray[index].deliveryTime);
        var roundDown = m.startOf('minute');
        ordersArray[index].deliveryTime = roundDown;
      }

      var groupedOrders = ordersArray.reduce(function (memo, x) {
        if (!memo[x['deliveryTime']]) { memo[x['deliveryTime']] = []; }
        memo[x['deliveryTime']].push(x);
        return memo;
      }, {});

      //var groupedOrders = groupBy(ordersArray, 'deliveryTime');

      console.log(groupedOrders);

      var ll = Object.keys(groupedOrders).reverse().map(function (key, index) {
        return groupedOrders[key];
      })

      console.log('ll')
      console.log(ll);

      return (
        <>
          <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }, 20]}>
            {Object.keys(groupedOrders).reverse().map(function (key, index) {
              console.log(key);
              console.log(groupedOrders[key]);
              return (
                <Col span={12}>
                  <div style={{ width: '100%', backgroundColor: '#eeeeee' }}><b><Moment date={key} format={'HH:mm'} locale="ru"></Moment></b></div>
                  {groupedOrders[key].map(number => {
                    return (
                      <>
                        <span>#{number.id} </span>
                      </>
                    )
                  })}
                  <br />
                  {groupedOrders[key].map(order => {
                    return (
                      <>
                        {order.cartitems.map((cart, i) => {
                          return (
                            <div className="list-print-item" style={{ width: '100%' }}>
                              <b>{cart.name}</b>
                              {cart.selectedOptions.map((option, i) => {
                                if (!option.label.includes('Не добавлять')) {
                                  return (
                                    <span key={i.toString()}><span> {option.name}: </span><span>{option.label} /</span></span>
                                  )
                                }
                              })}
                              <b> - {cart.quantity} шт.</b>
                            </div>
                          )
                        })
                        }
                      </>
                    )
                  })}
                  <br />
                  <br />
                </Col>

              )
            })}
          </Row>
        </>

      )


      // for (let index = 0; index < groupedOrders.length; index++) {
      //   console.log(groupedOrders[0]);
      // }



      // if(groupedOrders){
      //   return(
      //     <div className="orders-filter-print">
      //       <div className="menu-logo">
      //         <img src={'https://hungrygator.ru/pics/logo-cp.png'} />
      //       </div>
      //       {groupedOrders.map((ordergroup, i) => (
      //         <>
      //           {ordergroup.map((order, x) => (
      //             <>
      //               {order.id}
      //             </>
      //           ))}
      //         </>
      //       ))}
      //     </div>
      //   )
      // }

      // return (
      //   <div className="orders-filter-print">
      //     <div className="menu-logo">
      //       <img src={'https://hungrygator.ru/pics/logo-cp.png'} />
      //     </div>
      //     <p className="orders-filter-dic">Внемлите! Приготовить в срочном порядке, дабы удовлетворить потребности жаждущих!</p>
      //     {ordersArray.sort((a, b) => a.deliveryTime - b.deliveryTime).reverse().map((order, i) => (
      //       <div key={i.toString()} className="orders-filter-div">
      //         <div className="cheque-table-80mm-div-1"><span><span className="orders-filter-num">#{order.id} к <b><Moment date={order.deliveryTime} format={'HH:mm'} locale="ru"></Moment></b></span>
      //           <div className="cheque-table-80mm-div-options">
      //             {order.cartitems.map((cart, i) => {
      //               return (
      //                 <div key={i.toString()}>
      //                   <span><b>{cart.name}</b></span>
      //                   {cart.selectedOptions.map((option, i) => {
      //                     if (!option.label.includes('Не добавлять')) {
      //                       return (
      //                         <div key={i.toString()}>
      //                           <span> {option.name}: </span><span>{option.label} /</span>
      //                         </div>
      //                       )
      //                     }
      //                   })}
      //                   <span><b> - {cart.quantity} шт.</b></span>
      //                   <div className="orders-filter-separator"></div>
      //                 </div>
      //               )
      //             }
      //             )}
      //           </div>
      //         </span></div>
      //       </div>
      //     ))}
      //     <p className="orders-filter-dic">Солнце еще высоко</p>
      //   </div>
      // )
    } else {
      return (<></>)
    }
  }
}





//РЕДАКТИРОВАНИЕ
const EditableContext = React.createContext();
const EditableRow = ({ form, index, ...props }) => (
  <EditableContext.Provider value={form}>
    <tr key={index} {...props} />
  </EditableContext.Provider>
);
const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
  state = {
    editing: false,
  };

  toggleEdit = () => {
    const editing = !this.state.editing;
    this.setState({ editing }, () => {
      if (editing) {
        this.input.focus();
      }
    });
  };

  savePaymentType = e => {
    const { record, handleSavePaymentType } = this.props;
    this.form.validateFields((error, values) => {
      this.toggleEdit();
      handleSavePaymentType({ ...record, ...values }, values);
    });
  };

  saveCourier = e => {
    const { record, handleSaveCourier } = this.props;
    this.form.validateFields((error, values) => {
      this.toggleEdit();
      handleSaveCourier({ ...record, ...values }, values);
    });
  };

  saveStatus = e => {
    const { record, handleSaveStatus } = this.props;
    this.form.validateFields((error, values) => {
      this.toggleEdit();
      handleSaveStatus({ ...record, ...values }, values);
    });
  };

  saveDeliveryPrice = e => {
    const { record, handleSaveDeliveryPrice } = this.props;
    this.form.validateFields((error, values) => {
      this.toggleEdit();
      handleSaveDeliveryPrice({ ...record, ...values }, values);
    });
  };

  savePaid = e => {
    const { record, handleSavePaid } = this.props;
    this.form.validateFields((error, values) => {
      if (values.paid == record.paid || record.onlinePayment == true) {
        this.toggleEdit();
        return;
      } else {
        this.toggleEdit();
        handleSavePaid({ ...record, ...values }, values);
      }
    });
  };

  onOk = (e, dateString) => {
    const { record, handleSaveDeliveryDate } = this.props;
    console.log('onOk');
    console.log(e);
    this.toggleEdit();
    let values = {
      deliveryDate: e
    }
    handleSaveDeliveryDate({ ...record, ...values }, values);
  }

  onCancelDate = () => {
    this.toggleEdit();
  }


  disabledDate = (current) => {
    let beforeDate = moment().subtract(1, "days");
    let nextDate = moment().add(1, "month");
    return !(beforeDate.isSameOrBefore(current) && nextDate.isAfter(current));
  }


  renderCell = form => {
    this.form = form;
    const { children, dataIndex, record, title } = this.props;
    const { editing } = this.state;

    if (dataIndex == 'deliveryprice') {
      return editing ? (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: 0,
          })(
            <InputNumber ref={node => (this.input = node)} onPressEnter={this.saveDeliveryPrice} onBlur={this.saveDeliveryPrice} />
          )}
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={this.toggleEdit}
        >
          {children}
        </div>
      );
    }

    if (dataIndex == 'paid') {
      return editing ? (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: "Заказ оплачен?",
          })(
            <Select style={{ width: 220 }} ref={node => (this.input = node)} onPressEnter={this.savePaid} onBlur={this.savePaid}>
              <Option key={0} value={0}>Нет</Option>
              <Option key={1} value={1}>Да</Option>
            </Select>
          )}
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={this.toggleEdit}
        >
          {children}
        </div>
      );
    }

    if (dataIndex == 'courier_id') {
      return editing ? (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: "Выберите курьера",
          })(
            <Select style={{ width: 220 }} ref={node => (this.input = node)} onPressEnter={this.saveCourier} onBlur={this.saveCourier}>
              {this.props.courierList.map((courier, i) => {
                return (
                  <Option key={i.toString()} value={i}>{courier.name}</Option>
                )
              })}
            </Select>
          )}
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={this.toggleEdit}
        >
          {children}
        </div>
      );
    }

    if (dataIndex == 'deliveryDate') {
      return editing ? (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
          })(
            <>
              <DatePicker locale={locale} disabledDate={this.disabledDate} showTime placeholder="Укажите время" ref={node => (this.input = node)} onOk={this.onOk} />
              <Button style={{ marginLeft: 10 }} type="dashed" size="small" shape="circle" icon="rollback" onClick={this.onCancelDate} />
            </>
          )}
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={this.toggleEdit}
        >
          {children}
        </div>
      );
    }


    if (dataIndex == 'status') {
      return editing ? (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: "Выберите статус",
          })(
            <Select style={{ width: 220 }} ref={node => (this.input = node)} onPressEnter={this.saveStatus} onBlur={this.saveStatus}>
              {this.props.orderStatuses.map((status, i) => {
                return (
                  <Option key={i.toString()} value={i} style={{ color: `${status.apicolor}` }}>{status.apiname}</Option>
                )
              })}
            </Select>
          )}
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={this.toggleEdit}
        >
          {children}
        </div>
      );
    }

    if (dataIndex == 'paymenttype') {

      return editing ? (
        <Form.Item style={{ margin: 0 }}>
          {form.getFieldDecorator(dataIndex, {
            rules: [
              {
                required: true,
                message: `${title} is required.`,
              },
            ],
            initialValue: "Выберите способ оплаты",
          })(

            <Select style={{ width: 220 }} ref={node => (this.input = node)} onPressEnter={this.savePaymentType} onBlur={this.savePaymentType}>
              {this.props.payments.map((payment, i) => {
                return (
                  <Option key={i.toString()} value={payment._id}>{payment.name}</Option>
                )
              })}
            </Select>

          )}
        </Form.Item>
      ) : (
        <>
          {record.paid ? (
            <div
              className="editable-cell-value-wrap"
              style={{ paddingRight: 24 }}
            >
              {children}
            </div>
          ) : (
            <div
              className="editable-cell-value-wrap"
              style={{ paddingRight: 24 }}
              onClick={this.toggleEdit}
            >
              {children}
            </div>
          )}

        </>
      );
    }
  };


  render() {
    const {
      editable,
      dataIndex,
      title,
      record,
      index,
      handleSaveStatus,
      handleSavePaid,
      handleSaveDeliveryPrice,
      handleSavePaymentType,
      handleSaveDeliveryDate,
      handleSaveCourier,
      children,
      ...restProps
    } = this.props;


    if (editable) {
      return (
        <td>
          <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
        </td>
      );
    } else {
      return (
        <>
          <td>
            {children}
          </td>
        </>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  total: state.orders.total,
  orderStatuses: state.orders.orderStatuses,
  payments: state.orders.payments,
  courierList: state.orders.courierList,
})
const ConnectedEditableCell = connect(mapStateToProps)(EditableCell);

const CPSales = props => {
  const total = useSelector(state => state.orders.total);
  const pageLoading = useSelector(state => state.orders.pageLoading);
  const needToUpdate = useSelector(state => state.orders.needToUpdate);
  const orderToLoad = useSelector(state => state.orders.orderToLoad);
  const [pagination, setPagination] = React.useState({
    pageSize: 10,
    total: null
  });

  useEffect(() => {
    console.log('pagination');
    console.log(total)
    let paginationTmp = pagination;
    paginationTmp.total = total;
    paginationTmp.pageSize = 10;
    if (total == null) {
      paginationTmp.pageSize = undefined;
      console.log(paginationTmp);
    }
    setPagination(paginationTmp);
  }, [total]);

  useEffect(() => {
    console.log('needToUpdate')
    if (needToUpdate) {
      if (pagination.current == 1 || pagination.current == undefined) {
        console.log('needToUpdate 2');
        setPagination({
          pageSize: 999999,
          total: null
        });
        if (ddFilter == null && courierFilter == null && paidFilter == null) {
          dispatch(getNewOrder(orderToLoad));
        } else {
          dispatch(getSmartFiltered(ddFilter, courierFilter, paidFilter, moment.now()));
        }
      }
    } else {
      console.log(order);
    }
  }, [needToUpdate]);



  const handlePaginationChange = (value) => {
    console.log('handlePaginationChange');
    let paginationTmp = pagination;
    paginationTmp.pageSize = value;
    paginationTmp.current = pagination.current;
    dispatch(getOrderList(pagination.current, pagination.pageSize, true));
    setPagination(paginationTmp);
  }
  const handleTableChange = (pagination, filters, sorter) => {
    dispatch(getOrderList(pagination.current, pagination.pageSize, true));
    let paginationTmp = pagination;
    paginationTmp.current = pagination.current;
    setPagination(paginationTmp);
  }

  useFetching(getOrderList, useDispatch(), pagination.pageSize);

  const componentRef = useRef();
  const orderPrintRef = useRef();

  const user = useSelector(state => state.auth.user);
  const order = useSelector(state => state.orders.orders);
  const loading = useSelector(state => state.orders.isLoading);
  const showContent = useSelector(state => state.orders.showContent);
  const orderContent = useSelector(state => state.orders.orderContent);
  const orderStatuses = useSelector(state => state.orders.orderStatuses);
  const initcrmstatus = useSelector(state => state.orders.initcrmstatus);

  const kiteringShow = useSelector(state => state.orders.kiteringShow)

  const showAddItem = useSelector(state => state.orders.showAddItem);
  const itemsToAdd = useSelector(state => state.orders.itemsToAdd);
  const itemCategoriesToAdd = useSelector(state => state.orders.itemCategoriesToAdd);
  const itemsToAddLoading = useSelector(state => state.orders.itemsToAddLoading);

  const addingToOrder = useSelector(state => state.orders.addingToOrder);

  const payments = useSelector(state => state.orders.payments);
  const deliveries = useSelector(state => state.orders.deliveries);

  //const chequeToPrint = useSelector(state => state.orders.chequeToPrint);
  const showCheque = useSelector(state => state.orders.showCheque);

  const cheque_title = useSelector(state => state.orders.chequesettings.cheque_title);
  const cheque_field_1 = useSelector(state => state.orders.chequesettings.cheque_field_1);
  const cheque_field_2 = useSelector(state => state.orders.chequesettings.cheque_field_2);
  const cheque_field_3 = useSelector(state => state.orders.chequesettings.cheque_field_3);
  const cheque_user_name = useSelector(state => state.orders.chequesettings.cheque_user_name);
  const cheque_field_4 = useSelector(state => state.orders.chequesettings.cheque_field_4);
  const cheque_customer_data = useSelector(state => state.orders.chequesettings.cheque_customer_data);

  const isShowAddCourier = useSelector(state => state.orders.isShowAddCourier);
  const isCourierLoading = useSelector(state => state.orders.isCourierLoading);
  const isCourierAdding = useSelector(state => state.orders.isCourierAdding);
  const courierList = useSelector(state => state.orders.courierList);

  const [phoneSearch, setPhoneSearch] = React.useState('');
  const [emailSearch, setEmailSearch] = React.useState('');
  const [numberSearch, setNumberSearch] = React.useState('');


  const showKitering = () => {
    setPagination({
      pageSize: null,
      total: null
    });
    dispatch(showHideKitering());
  }
  const resetKitering = () => {
    setPagination({
      pageSize: undefined,
      total: null
    });
    dispatch(getOrderList(1, 10, false));
  }

  const setKitering = (orderId) => {
    dispatch(toggleKitering(orderId));
  }

  const filterPhone = () => {
    setPagination({
      pageSize: null,
      total: null
    });
    dispatch(getPhoneFiltered(phoneSearch));
  }
  const resetPhone = () => {
    setPagination({
      pageSize: undefined,
      total: null
    });
    setPhoneSearch('');
    setEmailSearch('');
    setNumberSearch('');
    dispatch(getOrderList(1, 10, false));
  }
  const filterEmail = () => {
    setPagination({
      pageSize: null,
      total: null
    });
    dispatch(getEmailFiltered(emailSearch));
  }
  const resetEmail = () => {
    setPagination({
      pageSize: undefined,
      total: null
    });
    setPhoneSearch('');
    setEmailSearch('');
    setNumberSearch('');
    dispatch(getOrderList(1, 10, false));
  }
  const filterNumber = () => {
    setPagination({
      pageSize: null,
      total: null
    });
    dispatch(getNumberFiltered(numberSearch));
  }
  const resetNumber = () => {
    setPagination({
      pageSize: undefined,
      total: null
    });
    setPhoneSearch('');
    setEmailSearch('');
    setNumberSearch('');
    dispatch(getOrderList(1, 10, false));
  }

  const [ddFilter, setDdFilter] = React.useState(null);
  const [courierFilter, setCourierFilter] = React.useState(null);
  const [paidFilter, setPaidFilter] = React.useState(null);
  const [showDDFilterTimeSelect, setShowDDFilterTimeSelect] = React.useState(false);


  //ПЕЧАТЬ СПИСКА
  const isShowPrintCurrentFilterDay = useSelector(state => state.orders.isShowPrintCurrentFilterDay);
  const openPrintCurrentFilterDay = () => {
    if (ddFilter != null) {
      setPagination({
        pageSize: 999999,
        total: null
      });
    }
    dispatch(showPrintCurrentFilterDay());
  }
  const closePrintCurrentFilterDay = () => {
    if (ddFilter != null) {
      setPagination({
        pageSize: 999999,
        total: null
      });
    }

    dispatch(hidePrintCurrentFilterDay());
  }
  ///////////


  function changeDDFilterTimeSelect(e) {
    console.log(moment(e));
    setShowDDFilterTimeSelect(false);
    setDdFilter(e);
    setPagination({
      pageSize: 999999,
      total: null
    });
    dispatch(getSmartFiltered(e, courierFilter, paidFilter, moment.now()));
  }

  const handleDeliveryDateFilterChange = (e) => {
    if (e == null) {
      setDdFilter(null);
      if (e == null && courierFilter == null && paidFilter == null) {
        dispatch(getOrderList(1, 10, false));
      } else {
        dispatch(getSmartFiltered(e, courierFilter, paidFilter, moment.now()));
      }
    } else {
      if (e == 999) {
        //выбрано - указать день
        setShowDDFilterTimeSelect(true);
      } else {
        setDdFilter(e);
        setPagination({
          pageSize: 999999,
          total: null
        });
        dispatch(getSmartFiltered(e, courierFilter, paidFilter, moment.now()));
      }
    }
  }

  const handleCourierFilterChange = (e) => {
    if (e == null) {
      setCourierFilter(null);
      if (ddFilter == null && e == null && paidFilter == null) {
        dispatch(getOrderList(1, 10, false));
      } else {
        dispatch(getSmartFiltered(ddFilter, e, paidFilter, moment.now()));
      }
    } else {
      setCourierFilter(e);
      setPagination({
        pageSize: 999999,
        total: null
      });
      dispatch(getSmartFiltered(ddFilter, e, paidFilter, moment.now()));
    }
  }

  const handlePaidFilterChange = (e) => {
    if (e == null) {
      setPaidFilter(null);
      if (ddFilter == null && courierFilter == null && e == null) {
        dispatch(getOrderList(1, 10, false));
      } else {
        dispatch(getSmartFiltered(ddFilter, courierFilter, e, moment.now()));
      }
    } else {
      setPaidFilter(e);
      setPagination({
        pageSize: 999999,
        total: null
      });
      dispatch(getSmartFiltered(ddFilter, courierFilter, e, moment.now()));
    }
  }

  const dispatch = useDispatch();


  const orderListColumns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Опции',
      dataIndex: 'selectedOptions',
      key: 'selectedOptions',
      render: selectedOptions => {
        if (selectedOptions) {
          return (
            selectedOptions.map(option => (
              <>
                {!option.hide ? (<><span>{option.name} - {option.label}</span><br /></>) : (<></>)}
              </>
            ))
          );
        }
      }
    },
    {
      title: 'Кол-во',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      render: (price, row) => {
        return (
          <>
            {price * row.quantity}
          </>
        )
      }
    },
    {
      title: '',
      dataIndex: '',
      key: 'x',
      render: (record, row, index) =>
        orderContent.cartitems.length >= 1 && user.role != 'operator' ? (
          <Popconfirm title="Удалить позицию из заказа?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => deleteItemFromList(record, row, index)}>
            <Button size="small" type="danger" shape="circle" icon="delete" />
          </Popconfirm>
        ) : null,
    },
  ];

  const deleteItemFromList = (value, r, index) => {
    dispatch(delItemFromOrder(orderContent._id, value, index));
  }

  // ПОИСК ФИЛЬТРАЦИЯ

  //КУРЬЕРЫ
  const [orderToAddCourier, setOrderToAddCourier] = React.useState({});
  const [courierToAdd, setCourierToAdd] = React.useState({});
  const [isCourierSelected, setIsCourierSelected] = React.useState(false);

  const handleOpenAddCourier = order => {
    setOrderToAddCourier(order);
    dispatch(getCourierList());
    dispatch(showCourierAdd());
  }
  const handleCloseAddCourier = () => {
    setOrderToAddCourier({});
    dispatch(closeCourierAdd());
  };
  const handleAddCourierToOrder = () => {
    dispatch(addCourierToOrder(orderToAddCourier._id, courierToAdd));
    setIsCourierSelected(false);
  }
  const handleSelectCourierToAdd = (value) => {
    setIsCourierSelected(true);
    setCourierToAdd(value);
  }
  function renderAddCourierToOrder() {
    if (isCourierLoading) {
      return (
        <>
          <span>Загружаем список курьеров</span><br />
          <Spin />
        </>
      )
    } else {
      if (isCourierAdding) {
        return (
          <>
            <span>Назначаем курьера</span><br />
            <Spin />
          </>
        )
      } else {
        return (
          <>
            <span>Заказ №{orderToAddCourier.id}</span>
            <Select size="default" style={{ width: '100%' }} onChange={handleSelectCourierToAdd}>
              {courierList.map(courier => {
                return (
                  <Option key={courier.name} value={courier._id}>{courier.name}</Option>
                );
              })}
            </Select>
          </>
        )
      }
    }


  }

  // СОЗДАНИЕ ЗАКАЗА 
  const showAddOrder = useSelector(state => state.orders.showAddOrder);
  const searchedClients = useSelector(state => state.orders.searchedClients);

  const [newOrderClientEmailSearch, setNewOrderClientEmailSearch] = React.useState('');
  const [newOrderClientPhoneSearch, setNewOrderClientPhoneSearch] = React.useState('');
  const [newOrderClientSearch, setNewOrderClientSearch] = React.useState(false);
  const [newOrderPaymentError, setNewOrderPaymentError] = React.useState(false);
  const [newOrderFieldsError, setNewOrderFieldsError] = React.useState(false);
  const [newOrderPaymentType, setNewOrderPaymentType] = React.useState('');
  const [newOrderDeliveryType, setNewOrderDeliveryType] = React.useState('');

  const [newOrderClient, setNewOrderClient] = React.useState({
    name: '',
    phone: '',
    email: '',
    comment: '',
    street: '',
    building: '',
    building_extra: '',
    appartments: '',
    appartments_level: '',
    appartments_block: '',
  })

  const openCreateOrderModal = () => {
    dispatch(showCreateOrderModal());
  }

  // const [ordersToPrint, setOrdersToPrint] = React.useState(null);

  const printCurrentFilterDay = useReactToPrint({
    content: () => orderPrintRef.current,
  });

  // const printCurrentFilterDay = () => {
  //   return (
  //     <ReactToPrint
  //       trigger={() => <Button type="primary">Печатать</Button>}
  //       content={() => componentRef.current}
  //     />
  //   )
  // }


  const closeCreateOrderModal = () => {
    setNewOrderClient({
      name: '',
      phone: '',
      email: '',
      comment: '',
      street: '',
      building: '',
      building_extra: '',
      appartments: '',
      appartments_level: '',
      appartments_block: '',
    })
    setNewOrderPaymentType('');
    setNewOrderDeliveryType('');
    dispatch(hideCreateOrderModal());
  }

  const newOrderSearchClientEmail = () => {
    if (newOrderClientEmailSearch.length >= 2) {
      dispatch(searchClientEmail(newOrderClientEmailSearch));
    }

  }
  const newOrderSearchClientPhone = () => {
    if (newOrderClientPhoneSearch.length >= 18) {
      if (newOrderClientPhoneSearch.indexOf('_') == -1) {
        dispatch(searchClientPhone(newOrderClientPhoneSearch));
      }
    }
  }

  const newOrderResetSearch = () => {
    dispatch(resetNewOrderSearch());
    setNewOrderClientEmailSearch('');
    setNewOrderClientPhoneSearch('');
    setNewOrderClientData({});
  }

  const handleSelectClientNewOrder = (client) => {
    newOrderResetSearch();
    setNewOrderClientSearch(false);
    console.log(client);
    setNewOrderClient({
      ...newOrderClient,
      name: client.name,
      phone: client.phone,
      email: client.email,
      street: client.delivery_address.street,
      building: client.delivery_address.building,
      building_extra: client.delivery_address.building_extra,
      appartments: client.delivery_address.appartments,
      appartments_level: client.delivery_address.appartments_level,
      appartments_block: client.delivery_address.appartments_block,
    })
  }

  const handleCreateNewOrder = () => {
    console.log('handleCreateNewOrder');

    let ordernew = {};

    if (newOrderClient.name != '' && newOrderClient.phone != '' && newOrderDeliveryType != '' && newOrderPaymentType != '') {
      setNewOrderFieldsError(false);
      ordernew = {
        name: newOrderClient.name,
        phone: newOrderClient.phone,
        email: newOrderClient.email,
        street: newOrderClient.street,
        comment: newOrderClient.comment,
        building: newOrderClient.building,
        building_extra: newOrderClient.building_extra,
        appartments: newOrderClient.appartments,
        appartments_level: newOrderClient.appartments_level,
        appartments_block: newOrderClient.appartments_block,
        deliverytype: newOrderDeliveryType,
        paymenttype: newOrderPaymentType
      }
    } else {
      setNewOrderFieldsError(true);
      return;
    }
    dispatch(createOrder(ordernew));
  }



  const handleSelectDeliveryNewOrder = (e) => {
    var deliveryObj = deliveries.filter(obj => {
      return obj.name === e
    })
    console.log(deliveryObj[0]);
    setNewOrderDeliveryType(deliveryObj[0]._id);
  }
  const handleSelectPaymentNewOrder = (e) => {
    console.log(e);
    var paymentObj = payments.filter(obj => {
      return obj.name === e
    })
    console.log(paymentObj[0]);
    if (paymentObj[0].online) {
      setNewOrderPaymentError(true);
      return;
    } else {
      setNewOrderPaymentError(false);
      setNewOrderPaymentType(paymentObj[0]._id);
    }
  }

  function renderCreateOrder() {

    const columns_searched = [
      {
        title: 'Имя', dataIndex: 'name', key: 'name'
      },
      {
        title: 'Телефон', dataIndex: 'phone', key: 'phone'
      },
      {
        title: 'E-mail', dataIndex: 'email', key: 'email'
      },
      {
        render: (client, row) => {
          return (
            <Button icon="check" onClick={() => handleSelectClientNewOrder(row)}></Button>
          );
        }
      },
    ]

    return (
      <>
        <Button type="dashed" onClick={() => setNewOrderClientSearch(!newOrderClientSearch)}>Поиск клиента</Button><br />
        {newOrderClientSearch ? (
          <>
            <Row gutter={16} style={{ marginTop: 20 }}>
              <Col xs={24} sm={24}>
                <Row type="flex">
                  <Input placeholder="Поиск по email" style={{ maxWidth: 200 }} value={newOrderClientEmailSearch} onChange={(e) => setNewOrderClientEmailSearch(e.target.value)} />
                  <Button icon="search" onClick={newOrderSearchClientEmail}></Button>
                  <Button icon="close-circle" type="dashed" onClick={newOrderResetSearch}></Button>
                </Row>

              </Col>
              <Col xs={24} sm={24} style={{ marginTop: 10 }}>
                <Row type="flex">
                  <MaskedInput style={{ maxWidth: 200 }} icon="search" placeholder="Поиск по телефону" mask="+7 (111) 111-11-11" size="20" value={newOrderClientPhoneSearch} onChange={(e) => { setNewOrderClientPhoneSearch(e.target.value); console.log(e) }} />
                  <Button icon="search" onClick={newOrderSearchClientPhone}></Button>
                  <Button icon="close-circle" type="dashed" onClick={newOrderResetSearch}></Button>
                </Row>
              </Col>
            </Row>
            {searchedClients.length > 0 ? (
              <>
                <Table
                  style={{ marginTop: 20 }}
                  rowKey={record => record._id}
                  pagination={false}
                  size="small"
                  columns={columns_searched}
                  scroll={{ x: true }}
                  dataSource={searchedClients}
                />
              </>
            ) : (<></>)}
            <Divider />
          </>
        ) : (<></>)}
        <br />
        <Row type="flex" gutter={16}>
          <Col xs={24} sm={12}>
            <Typography>Данные клиента:</Typography>
            <Input placeholder="Имя клиента" style={{ marginTop: 10 }} value={newOrderClient.name} onChange={(e) => setNewOrderClient({ ...newOrderClient, "name": e.target.value })} /><br />
            <Input placeholder="Телефон клиента" style={{ marginTop: 10 }} value={newOrderClient.phone} onChange={(e) => setNewOrderClient({ ...newOrderClient, "phone": e.target.value })} /><br />
            <Input placeholder="Email клиента" style={{ marginTop: 10 }} value={newOrderClient.email} onChange={(e) => setNewOrderClient({ ...newOrderClient, "email": e.target.value })} /><br />
            <Input placeholder="Комментарий" style={{ marginTop: 10 }} value={newOrderClient.comment} onChange={(e) => setNewOrderClient({ ...newOrderClient, "comment": e.target.value })} /><br />
            <Typography style={{ marginTop: 10 }}>Способ оплаты и доставки:</Typography>
            <Select style={{ width: '100%', marginTop: 10 }} placeholder="Способ доставки" onChange={handleSelectDeliveryNewOrder}>
              {deliveries.map((delivery, i) => {
                return (
                  <Option key={delivery.name.toString()}>{delivery.name}</Option>
                )
              })}
            </Select>
            <Select style={{ width: '100%', marginTop: 10 }} placeholder="Способ оплаты" onChange={handleSelectPaymentNewOrder}>
              {payments.map((payment, i) => {
                return (
                  <Option key={payment.name.toString()}>{payment.name}</Option>
                )
              })}
            </Select>
            {newOrderPaymentError ? (<Typography style={{ color: 'red' }}>Онлайн оплата запрещена при создании заказа</Typography>) : (<></>)}
          </Col>
          <Col xs={24} sm={12}>
            <Typography>Адрес доставки:</Typography>

            <Input placeholder="Улица" style={{ marginTop: 10 }} value={newOrderClient.street} onChange={(e) => setNewOrderClient({ ...newOrderClient, "street": e.target.value })} /><br />
            <Input placeholder="Номер дома" style={{ marginTop: 10 }} value={newOrderClient.building} onChange={(e) => setNewOrderClient({ ...newOrderClient, "building": e.target.value })} /><br />
            <Input placeholder="Строение / Корпус" style={{ marginTop: 10 }} value={newOrderClient.building_extra} onChange={(e) => setNewOrderClient({ ...newOrderClient, "building_extra": e.target.value })} /><br />
            <Input placeholder="Квартира / Офис" style={{ marginTop: 10 }} value={newOrderClient.appartments} onChange={(e) => setNewOrderClient({ ...newOrderClient, "appartments": e.target.value })} /><br />
            <Input placeholder="Этаж" style={{ marginTop: 10 }} value={newOrderClient.appartments_level} onChange={(e) => setNewOrderClient({ ...newOrderClient, "appartments_level": e.target.value })} /><br />
            <Input placeholder="Подъезд" style={{ marginTop: 10 }} value={newOrderClient.appartments_block} onChange={(e) => setNewOrderClient({ ...newOrderClient, "appartments_block": e.target.value })} /><br />
          </Col>
          <Col xs={24} sm={24} style={{ textAlign: 'center', marginTop: 20 }}>
            {newOrderFieldsError ? (<Typography style={{ color: 'red' }}>Не заполнены необходимые поля</Typography>) : (<></>)}
            <Button style={{ backgroundColor: '#9FBE34', border: 0 }} type="primary" onClick={handleCreateNewOrder}>СОЗДАТЬ</Button>
          </Col>
        </Row>
      </>
    )
  }

  // КРАТКИЙ ОТЧЕТ
  const [smallReportOpened, setSmallReportOpened] = React.useState(false);
  const onOpenSmallReport = () => {
    setSmallReportOpened(true);
    dispatch(getSmallReport(ddFilter, courierFilter, moment.now()));
    if (ddFilter != null || courierFilter != null) {
      setPagination({
        pageSize: 999999,
        total: null
      });
    }
  }
  const onCloseSmallReport = () => {
    setSmallReportOpened(false);
  }

  const smallReport = useSelector(state => state.orders.smallReport);
  const smallReportLoading = useSelector(state => state.orders.smallReportLoading);


  function renderCategoryReport(categories, ostatok, deletesum) {
    let extraDeletesum = 0;
    let categoryIndexToSkip = [];
    console.log('categories');
    console.log(categories);

    // categories.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0));
    categories.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    console.log(categories);
    for (let index = 0; index < categories.length; index++) {
      const element = categories[index];
      if (categories[index].price < deletesum) {
        console.log('категория меньше ');
        console.log(categories[index]);
        extraDeletesum += deletesum;
        categoryIndexToSkip.push(index);
      }
    }

    console.log('extraDeletesum')
    console.log(extraDeletesum)
    console.log('categoryIndexToSkip')
    console.log(categoryIndexToSkip)

    return (
      categories.map((complcat, i) => (
        <div key={i.toString()} style={{ display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
          <span>{complcat.categoryName}</span>
          <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>

            {i == 0 ? (
              <>
                {categoryIndexToSkip.length > 0 && extraDeletesum > 0 ? (<>{((complcat.price - deletesum) - ostatok) - extraDeletesum}</>) : (<>{(complcat.price - deletesum) - ostatok}</>)}
              </>
            ) : (
              <>
                {categoryIndexToSkip.length > 0 && categoryIndexToSkip.includes(i) ? (<>{complcat.price}</>) : (<>{complcat.price - deletesum}</>)}
              </>
            )}

          </span>
        </div>
      ))
    )

  }

  function renderSmallReport() {
    if (!smallReportLoading) {
      console.log('smallReport');
      console.log(smallReport);
      let payments_table = [];
      for (var i = 0; i < smallReport.completedpaymenttypes.length; i++) {
        if (payments_table.some(e => e.payName === smallReport.completedpaymenttypes[i].payName)) {
          //payments_table уже имеет такую оплату
          let index = payments_table.findIndex(x => x.payName === smallReport.completedpaymenttypes[i].payName);
          payments_table[index].payTotal += smallReport.completedpaymenttypes[i].payTotal;
        } else {
          // такой оплаты нет. добавляем
          payments_table.push(smallReport.completedpaymenttypes[i]);
        }
      }

      let courier_table = [];
      for (var i = 0; i < smallReport.completedcouriersearn.length; i++) {
        if (courier_table.some(e => e.courierName === smallReport.completedcouriersearn[i].courierName)) {
          //courier_table уже имеет такого курьера
          let index = courier_table.findIndex(x => x.courierName === smallReport.completedcouriersearn[i].courierName);
          courier_table[index].courierPay += smallReport.completedcouriersearn[i].courierPay;
        } else {
          // такого курьера нет. добавляем
          courier_table.push(smallReport.completedcouriersearn[i]);
        }
      }

      let ostatok = parseInt(smallReport.usedbonuses, 10) % smallReport.completedcategoryearn.length;
      let deletesum = (parseInt(smallReport.usedbonuses, 10) - ostatok) / smallReport.completedcategoryearn.length;

      console.log('ostatok');
      console.log(ostatok);
      console.log('deletesum');
      console.log(deletesum);
      return (
        <div style={{ width: 300 }}>
          <div style={{ display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
            <span>Всего заказов за сутки:</span>
            <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{parseInt(smallReport.dailysales, 10) - parseInt(smallReport.canceledcount, 10)}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
            <span>Заказов в работе:</span>
            <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{smallReport.dailyinprocess}</span>
          </div>
          <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
            <span>Заказов завершено:</span>
            <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{smallReport.dailycompleted}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
            <span>Сумма завершенных заказов:</span>
            <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{smallReport.dailycompletedsumm}</span>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
            <span style={{ fontSize: 12 }}> <Icon type="caret-right" /> Оплачено бонусами:</span>
            <span style={{ fontSize: 12, marginLeft: 'auto', fontWeight: 'bold' }}>{smallReport.usedbonuses}</span>
          </div>

          {smallReport.dailyinprocess > 0 ? (<>
            <div style={{ display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
              <span>Сумма заказов в работе:</span>
              <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{smallReport.dailyinprocesssumm}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
              <span style={{ fontSize: 12 }}> <Icon type="caret-right" /> Оплачено бонусами:</span>
              <span style={{ fontSize: 12, marginLeft: 'auto', fontWeight: 'bold' }}>{smallReport.inprogressusedbonuses}</span>
            </div>
          </>) : (<></>)}


          <div style={{ marginTop: 10, marginBottom: 10, display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
            <span>Общая стоимость всех заказов:</span>
            <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{parseInt(smallReport.dailycompletedsumm + smallReport.dailyinprocesssumm, 10)}</span>
          </div>

          <span style={{ fontWeight: 'bold' }}>Принятые способы оплаты:</span><br />
          {payments_table.map((payment, i) => {
            return (
              <div key={i.toString()} style={{ display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
                <span>{payment.payName}</span>
                <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{payment.payTotal}</span>
              </div>
            )
          })}

          <span style={{ fontWeight: 'bold' }}>Завершенный заказ - Категории:</span><br />
          {renderCategoryReport(smallReport.completedcategoryearn, ostatok, deletesum)}
          <div style={{ display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
            <span>Доставка</span>
            <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{smallReport.completeddelivery}</span>
          </div>
          {smallReport.inprogresscategoryearn.length > 0 ? (<><span style={{ fontWeight: 'bold' }}>Заказ в работе - Категории:</span><br /></>) : (<></>)}
          {smallReport.inprogresscategoryearn.map((inprocesscat, i) => {
            return (
              <div key={i.toString()} style={{ display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
                <span>{inprocesscat.categoryName}</span>
                <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{inprocesscat.price}</span>
              </div>
            )
          })}
          {smallReport.inprogresscategoryearn.length > 0 ? (<>
            <div style={{ display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
              <span>Доставка</span>
              <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{smallReport.inprogressdelivery}</span>
            </div>
          </>) : (<></>)}


          {smallReport.canceledcount > 0 ? (
            <Alert style={{ padding: 3, marginTop: 10 }} message={
              <>
                <div style={{ fontSize: 12, display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
                  <span>Отмененные заказы:</span>
                  <span style={{ marginLeft: 'auto', fontWeight: 'bold', color: 'red' }}>{parseInt(smallReport.canceledcount, 10)}</span>
                </div>
                <div style={{ fontSize: 12, display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
                  <span>Сумма отмененных заказов:</span>
                  <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{parseInt(smallReport.canceledsumma, 10)}</span>
                </div>
                <div style={{ fontSize: 12, display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
                  <span> <Icon type="caret-right" /> Оплачено бонусами:</span>
                  <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{smallReport.canceledusedbonuses}</span>
                </div>
              </>
            } type="error" />
          ) : (<></>)}

          <Alert style={{ padding: 3, marginTop: 10 }} message={<>
            <div style={{ fontSize: 12, display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
              <span>Средний чек завершенных заказов:</span>
              <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{parseInt(smallReport.dailycompletedsumm / smallReport.dailycompleted, 10)}</span>
            </div>
            <div style={{ fontSize: 12, display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
              <span>Среднее число позиций в заказе:</span>
              <span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{parseInt(smallReport.dailypositionsinorder / smallReport.dailysales, 10)}</span>
            </div>
          </>} type="info" />

        </div>
      )

    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          <Spin /><br />
          <span>Формируем отчет</span>
        </div>
      )
    }

  }
  // РЕДАКТИРОВАНИЕ ЗАМЕТКИ

  const [orderToEditComment, setOrderToEditComment] = React.useState({});
  const isShowEditComment = useSelector(state => state.orders.isShowEditComment);
  const [editComment, setEditComment] = React.useState('');
  const handleOpenEditComment = order => {
    setOrderToEditComment(order);
    setEditComment(order.comment);
    dispatch(openEditComment());
  }
  const handleCloseEditComment = () => {
    setOrderToEditComment({});
    setEditComment('');
    dispatch(closeEditComment());
  }

  const handleEditComment = () => {
    console.log('handleEditComment');
    dispatch(sendEditComment(orderToEditComment._id, editComment));
  }
  const onChangeEditComment = (e) => {
    setEditComment(e.target.value);
  }
  function renderEditComment() {
    return (
      <>
        <TextArea value={editComment} onChange={(e) => onChangeEditComment(e)} rows={4} />

      </>
    )
  }





  // РАСЧЕТ СДАЧИ
  const [orderToCalcChange, setOrderToCalcChange] = React.useState({});
  const isShowCalcChange = useSelector(state => state.orders.isShowCalcChange);

  const [calcInput, setCalcInput] = React.useState(0);
  const [calcChange, setCalcChange] = React.useState(0);

  const handleOpenCalcChange = order => {
    setOrderToCalcChange(order);
    dispatch(openCalcChange());
  }
  const handleCloseCalcChange = () => {
    setOrderToCalcChange({});
    dispatch(closeCalcChange());
  }
  const handleCalcChange = () => {
    console.log('handleCalcChange');
    dispatch(sendCalcChange(orderToCalcChange._id, calcChange));
  }

  function onChangeCalcInput(value) {
    setCalcInput(value);

    let total = parseInt(orderToCalcChange.summa, 10) + parseInt(orderToCalcChange.deliveryprice, 10);
    setCalcChange(parseInt(value, 10) - total);

  }
  function renderCalcChange() {
    return (
      <>
        <Divider className="header-div" orientation="center">Итого: {parseInt(orderToCalcChange.summa, 10) + parseInt(orderToCalcChange.deliveryprice, 10)}</Divider>
        <InputNumber style={{ width: '100%', marginBottom: 20 }} value={calcInput} onChange={onChangeCalcInput}></InputNumber>
        <Divider className="header-div" orientation="center" >Сдача: {parseInt(calcChange, 10)}</Divider>
        <Button type="primary" onClick={handleCalcChange}>Сохранить в заметках</Button>
      </>
    )
  }

  // РЕДАКТИРОВАНИЕ АДРЕСА

  const isAddressEditing = useSelector(state => state.orders.isAddressEditing);
  const isShowAddressModal = useSelector(state => state.orders.isShowAddressModal);
  const [orderAddressContent, setOrderAddressContent] = React.useState({});
  const handleOpenAddressModal = item => {
    console.log(item);
    setOrderAddressContent(item);
    dispatch(showAddressModal());
  };
  const handleCloseAddressModal = useCallback(() => dispatch(closeAddressModal()), [
    dispatch
  ]);

  const handleSaveAddress = () => {
    let data = {
      id: orderAddressContent.id,
      clientstreet: orderAddressContent.clientstreet,
      clientbuilding: orderAddressContent.clientbuilding,
      clientbuildingextra: orderAddressContent.clientbuildingextra,
      clientappartmentslevel: orderAddressContent.clientappartmentslevel,
      clientappartments: orderAddressContent.clientappartments
    }
    dispatch(saveAddress(data));
  }

  function renderEditAddress() {
    return (
      <>
        <span>Улица:</span>
        <Input placeholder="Улица" style={{ marginTop: 10 }} value={orderAddressContent.clientstreet} onChange={(e) => setOrderAddressContent({ ...orderAddressContent, "clientstreet": e.target.value })} /><br /><br />
        <span>Номер дома:</span>
        <Input placeholder="Номер дома" style={{ marginTop: 10 }} value={orderAddressContent.clientbuilding} onChange={(e) => setOrderAddressContent({ ...orderAddressContent, "clientbuilding": e.target.value })} /><br /><br />
        <span>Строение / Корпус:</span>
        <Input placeholder="Строение / Корпус" style={{ marginTop: 10 }} value={orderAddressContent.clientbuildingextra} onChange={(e) => setOrderAddressContent({ ...orderAddressContent, "clientbuildingextra": e.target.value })} /><br /><br />
        <span>Этаж:</span>
        <Input placeholder="Этаж" style={{ marginTop: 10 }} value={orderAddressContent.clientappartmentslevel} onChange={(e) => setOrderAddressContent({ ...orderAddressContent, "clientappartmentslevel": e.target.value })} /><br /><br />
        <span>Квартира / Офис:</span>
        <Input placeholder="Квартира / Офис" style={{ marginTop: 10 }} value={orderAddressContent.clientappartments} onChange={(e) => setOrderAddressContent({ ...orderAddressContent, "clientappartments": e.target.value })} /><br /><br />
        <Button type="primary" disabled={isAddressEditing} onClick={handleSaveAddress}>Сохранить</Button>
        {isAddressEditing ? (<><Spin /></>) : (<></>)}
      </>
    )
  }

  const [showCustomerBonus, setShowCustomerBonus] = React.useState(false);
  const [orderToCheckBonusHistory, setOrderToCheckBonusHistory] = React.useState(null);

  const customerBonusHistory = useSelector(state => state.orders.customerBonusHistory);
  const customerOrderHistory = useSelector(state => state.orders.customerOrderHistory);

  const orderCustomerBonusLoading = useSelector(state => state.orders.orderCustomerBonusLoading);

  const handleOpenShowHistoryBonus = (order) => {
    setShowCustomerBonus(true);
    setOrderToCheckBonusHistory(order);
    console.log(order);
    if (order != null) {
      console.log('not null');
      if (order.customerId != "") {
        console.log('customerId not "')
        dispatch(loadClientBonus(order.customerId));
      }
    }

  }

  const handleCloseShowHistoryBonus = (order) => {
    setShowCustomerBonus(false);
    setOrderToCheckBonusHistory(null);
  }

  function renderCustomerBonus() {

    if (showCustomerBonus) {


      if (orderToCheckBonusHistory != null) {

        if (orderToCheckBonusHistory.customerId == "") {
          return (
            <>Клиент не зарегистрирован</>
          )
        } else {
          if (orderCustomerBonusLoading) {
            return (
              <><Spin /></>
            )
          } else {
            return (
              <>
                <table border="0" style={{ width: '100%', textAlign: 'center' }}>
                  <thead>
                    <tr style={{ backgroundColor: '#eeeeee' }}>
                      <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Заказ</th>
                      <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Сумма</th>
                      <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Дата</th>
                      <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Статус</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerOrderHistory.map((order, i) => {
                      return (
                        <tr style={{ borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'solid' }} key={i.toString()}>
                          <td style={{ borderRightColor: '#eeeeee', borderRightWidth: 1, borderRightStyle: 'solid' }}>{order.id}</td>
                          <td style={{ borderRightColor: '#eeeeee', borderRightWidth: 1, borderRightStyle: 'solid' }}>{parseInt(order.summa, 10) + parseInt(order.deliveryprice, 10)}</td>
                          <td style={{ borderRightColor: '#eeeeee', borderRightWidth: 1, borderRightStyle: 'solid' }}><Moment date={order.created_at} format="lll" locale="ru" /></td>
                          <td style={{ borderRightColor: '#eeeeee', borderRightWidth: 1, borderRightStyle: 'solid' }}>
                            {order.completed ? (<span style={{ color: 'green' }}>Завершен</span>) : order.canceled ? (<span style={{ color: 'red' }}>Отменен</span>) : (<span style={{ color: 'blue' }}>В работе</span>)}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>

                <div style={{ marginTop: 20, textAlign: 'left' }}>
                  <span><b>Имя клиента:</b></span><br />
                  <span>{customerBonusHistory.name}</span><br />
                  <span>Всего бонусов: {customerBonusHistory.bonus}</span><br /><br />

                  <table border="0" style={{ width: '100%', textAlign: 'center' }}>
                    <thead>
                      <tr style={{ backgroundColor: '#eeeeee' }}>
                        <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Заказ</th>
                        <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Начислено</th>
                        <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Списано</th>
                        <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Дата</th>
                        <th>Кем</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerBonusHistory.bonusHistory.map((history, i) => {
                        return (
                          <tr style={history.granted ? { backgroundColor: '#e0ffe9' } : { backgroundColor: '#ffe1e0' }} key={i.toString()}>
                            <td style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>{history.order_id}</td>
                            <td style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>{history.granted}</td>
                            <td style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>{history.removed}</td>
                            <td style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>{history.granted_at ? (<Moment date={history.granted_at} format="lll" locale="ru" />) : (<Moment date={history.removed_at} format="lll" locale="ru" />)}</td>
                            <td>{history.granted_by}{history.removed_by}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

              </>
            )
          }

        }
      }
    }
  }

  // ОКНО СПИСКА ТОВАРОВ В ЗАКАЗЕ

  const handleOpenContentModal = item => {
    console.log(item);
    dispatch(showContentModal(item));
  };
  const handleCloseContentModal = useCallback(() => dispatch(closeContentModal()), [
    dispatch
  ]);

  function getPaymentType(payment) {
    var paymentName = payments.filter(obj => {
      return obj._id === payment
    })
    if (paymentName[0])
      return paymentName[0].name;
  }
  function getDeliveryType(delivery) {
    var deliveryName = deliveries.filter(obj => {
      return obj._id === delivery
    })
    if (deliveryName[0] == undefined || deliveryName[0].name == undefined) {
      return 'Способ доставки был удален';
    } else {
      return deliveryName[0].name;
    }

  }
  function getCourierName(courier, order) {
    var courierName = courierList.filter(obj => {
      return obj._id === courier
    })
    if (courierName[0])
      return courierName[0].name;
  }
  function getCourierId(courier, order) {
    //console.log(courier);
    var courierName = courierList.filter(obj => {
      return obj._id === courier
    })
    if (courierName[0] != undefined) {
      return (
        <>
          <Tag>{courierName[0].name}</Tag>
        </>
      )
    } else {
      return (
        <>
          <Tag>ПОЛЬЗОВАТЕЛЬ УДАЛЕН</Tag>
        </>
      )
    }

  }

  function renderOrderInfo(order) {
    return (
      <>
        <Row >
          <Col sm={8} xs={24} style={{ paddingBottom: 20, borderRadius: 9, paddingLeft: 16, paddingRight: 16 }}>
            <Divider>Клиент</Divider>
            <Icon type="user" /> - <span>{order.clientname}</span><br />
            <Icon type="phone" /> - <span>{order.clientphone}</span><br />
            <Icon type="mail" /> - <span>{order.clientemail}</span><br />
            <Icon type="home" /> - <span>{order.clientstreet}, д. {order.clientbuilding}, корп/стр {order.clientbuildingextra}, этаж {order.clientappartmentslevel}, кв. {order.clientappartments}</span><br />
            <Icon type="message" /> - <span>{order.clientcomment}</span>
          </Col>
          <Col sm={8} xs={24} style={{ paddingBottom: 20, borderRadius: 9, paddingLeft: 16, paddingRight: 16 }}>
            <Divider>Оплата и Доставка</Divider>
            {order.usedbonus != 0 ? (
              <>
                <Icon type="dollar" /> - <span>Использовано <span style={{ fontWeight: 'bold' }}>{order.usedbonus}</span> бонусов</span><br />
              </>) : (<></>)}
            {order.usedcoupon != '' ? (
              <>
                <Icon type="tags" /> - <span>Использован купон <span style={{ fontWeight: 'bold' }}>{order.usedcoupon}</span></span><br />
              </>) : (<></>)}
            <Icon type="credit-card" /> - <span>{getPaymentType(order.paymenttype)}</span><br />
            <Icon type="credit-card" /> - <span>{order.summa}</span><br />
            <Icon type="car" /> - <span>{getDeliveryType(order.deliverytype)}</span><br />
            <Icon type="car" /> - <span>{order.deliveryprice}</span><br />
            {order.waitingAt != null ? (<><Icon type="clock-circle" /> - Время ожидаемой доставки: <b>{moment(order.waitingAt).format('lll')}</b></>):null}
            
            <div style={{ borderBottomWidth: 1, borderBottomColor: '#bdbdbd', borderBottomStyle: 'dashed', borderTopWidth: 1, borderTopColor: '#bdbdbd', borderTopStyle: 'dashed', width: '90%', marginTop: 10 }}>
              <span>Итого: <Text strong>{parseInt(order.deliveryprice, 10) + parseInt(order.summa, 10)}</Text></span>
            </div>
          </Col>

          <Col sm={8} xs={24} style={{ paddingBottom: 20, borderRadius: 9, paddingLeft: 16, paddingRight: 16 }}>
            <Divider>Курьеры</Divider>
            {order.courier != null ? (
              <div style={{ marginTop: 5 }}>
                <div>
                  <Icon type="car" /> - <span>Имя курьера: {order.courier.name}</span>
                </div>
              </div>
            ) : (<></>)}
            {order.courier_assigned_at != null ? (
              <div style={{ marginTop: 5 }}>
                <div>
                  <Icon type="car" /> - <span>Время назначения курьера: <Moment date={order.courier_assigned_at} format="lll" locale="ru" /></span>
                </div>
              </div>
            ) : (<></>)}

            {order.courier != null && order.courier_delivered_at == null ? (
              <div style={{ marginTop: 5 }}>
                <div>
                  <Icon type="car" /> - <span>Статус доставки: </span><span style={{ color: 'orange' }}>В процессе</span>
                </div>
              </div>
            ) : (<></>)}

            {order.courier != null && order.courier_delivered_at != null ? (
              <div style={{ marginTop: 5 }}>
                <div>
                  <Icon type="car" /> - <span>Статус доставки: </span><span style={{ color: 'green' }}>Доставлено <Moment date={order.courier_delivered_at} format="lll" locale="ru" /></span>
                </div>
              </div>
            ) : (<></>)}

          </Col>
          <Col xs={24}>
          </Col>
          <Col sm={12} xs={24} style={{ paddingBottom: 20, borderRadius: 9, paddingLeft: 16, paddingRight: 16 }}>
            <Divider>Состав</Divider>
            <table className="order-content-table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Наименование</th>
                  <th>Кол-во</th>
                  <th>Сумма</th>
                </tr>
              </thead>
              <tbody>
                {
                  order.cartitems.map((item, i) => {
                    let itemname = item.name;
                    item.selectedOptions.map((option, y) => {
                      if (option.name) {
                        if (!option.hide) {
                          itemname += ' ';
                          itemname += option.name + ': ';
                          itemname += option.label;
                        }
                      }
                    })
                    return (
                      <tr key={i.toString()}>
                        <td><Text>{i + 1}</Text></td>
                        <td><Text>{itemname}</Text></td>
                        <td><Text>{item.quantity}</Text></td>
                        <td><Text>{item.price * item.quantity}</Text></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </Col>

          <Col sm={12} xs={24} style={{ paddingBottom: 20, borderRadius: 9, paddingLeft: 16, paddingRight: 16 }}>
            <Divider>Заметки</Divider>
            <Alert message={order.comment} type="success" />
            {/* <span>{order.comment}</span> */}
          </Col>
        </Row>

        {/* <div className="sale-info-box">
          <div className="sale-info-section">
            <div>
              <Icon type="user" /> - <span>{order.clientname}</span><br />
              <Icon type="phone" /> - <span>{order.clientphone}</span><br />
              <Icon type="mail" /> - <span>{order.clientemail}</span><br />
              <Icon type="home" /> - <span>{order.clientstreet}, д. {order.clientbuilding}, корп/стр {order.clientbuildingextra}, этаж {order.clientappartmentslevel}, кв. {order.clientappartments}</span><br />
              <Icon type="message" /> - <span>{order.clientcomment}</span>
            </div>
          </div>
          <div className="sale-info-section-2">
            <div>
              <Icon type="credit-card" /> - <span>{getPaymentType(order.paymenttype)}</span><br />
              <Icon type="credit-card" /> - <span>{order.summa}</span>
            </div>
            <div>
              <div>
                <Icon type="car" /> - <span>{getDeliveryType(order.deliverytype)}</span><br />
                <Icon type="car" /> - <span>{order.deliveryprice}</span><br />
              </div>
              <div style={{ borderBottomWidth: 1, borderBottomColor: '#bdbdbd', borderBottomStyle: 'dashed', borderTopWidth: 1, borderTopColor: '#bdbdbd', borderTopStyle: 'dashed', width: '90%', marginTop: 10 }}>
                <span>Итого: <Text strong>{parseInt(order.deliveryprice, 10) + parseInt(order.summa, 10)}</Text></span>
              </div>
            </div>
            {order.courier != null ? (
              <div style={{ marginTop: 5 }}>
                <div>
                  <Icon type="car" /> - <span>Имя курьера: {order.courier.name}</span>
                </div>
              </div>
            ) : (<></>)}
            {order.courier_assigned_at != null ? (
              <div style={{ marginTop: 5 }}>
                <div>
                  <Icon type="car" /> - <span>Время назначения курьера: <Moment date={order.courier_assigned_at} format="lll" locale="ru" /></span>
                </div>
              </div>
            ) : (<></>)}

            {order.courier != null && order.courier_delivered_at == null ? (
              <div style={{ marginTop: 5 }}>
                <div>
                  <Icon type="car" /> - <span>Статус доставки: </span><span style={{ color: 'orange' }}>В процессе</span>
                </div>
              </div>
            ) : (<></>)}

            {order.courier != null && order.courier_delivered_at != null ? (
              <div style={{ marginTop: 5 }}>
                <div>
                  <Icon type="car" /> - <span>Статус доставки: </span><span style={{ color: 'green' }}>Доставлено <Moment date={order.courier_delivered_at} format="lll" locale="ru" /></span>
                </div>
              </div>
            ) : (<></>)}

          </div>
          <div className="sale-info-section-2" style={{ paddingLeft: 10 }}>
            <Icon type="pushpin" /> - <Text strong>заметки:</Text>
          </div>
        </div>

        <div className="content-order-header">
          <Icon type="shopping-cart" /> - <Text>СОСТАВ ЗАКАЗА:</Text>
        </div>
        <div className="content-order">
          <table className="order-content-table">
            <tr>
              <th>#</th>
              <th>Наименование</th>
              <th>Кол-во</th>
            </tr>

            {
              order.cartitems.map((item, i) => {
                let itemname = item.name;
                item.selectedOptions.map((option, y) => {
                  if (!option.hide) {
                    itemname += ' ';
                    itemname += option.name + ': ';
                    itemname += option.label;
                  }
                })
                return (
                  <tr>
                    <td><Text>{i + 1}</Text></td>
                    <td><Text>{itemname}</Text></td>
                    <td><Text>{item.quantity}</Text></td>
                  </tr>
                )
              })
            }
          </table>
        </div> */}

        <div className="sale-buttons">
          <Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleOpenCalcChange(order)}>РАСЧЕТ СДАЧИ</Button>
          <Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleOpenContentModal(order)}>РЕДАКТИРОВАТЬ СОСТАВ</Button>
          <Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleOpenAddressModal(order)}>РЕДАКТИРОВАТЬ АДРЕС</Button>
          <Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleOpenEditComment(order)}>РЕДАКТИРОВАТЬ ЗАМЕТКУ</Button>

          <Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleOpenShowHistoryBonus(order)}>ИСТОРИЯ БОНУСОВ</Button>

          <Button style={{ marginRight: 5 }} type="dashed" onClick={() => setKitering(order._id)}>НАЗНАЧИТЬ КАЙТЕРИНГ</Button>


          {/* <Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleOpenAddCourier(order)}>КУРЬЕРЫ</Button> */}
          {/* <Button style={{ marginRight: 5 }} disabled type="dashed" onClick={() => handleOpenContentModal(order)}>БОНУСНЫЕ БАЛЫ</Button> */}
        </div>
      </>
    );
  }

  const handleOpenAddToOrder = order => {
    //console.log(order);
    dispatch(loadItems());
    dispatch(showAddToOrder(order));
  };

  const handleCloseAddToOrder = () => {
    setItemToAdd(null);
    setItemToAddSelected(false);
    dispatch(closeAddToOrder());
    setItemTmpPrice(null);
    setItemToAdd(null);
    setItemOptions([]);
    setItemToAddTmpQuantity(1);
  };

  const handleAddToOrder = () => {

    // if(itemToAddTmp.sale > 0){
    //   itemToAdd.price = parseInt(itemToAdd.price - ( itemToAdd.price / 100 ) * itemToAdd.sale, 10) * itemToAdd.quantity;
    // }
    //console.log(itemToAddTmp);
    dispatch(addAddItem(orderContent._id, itemToAddTmp, itemToAddTmpQuantity));
    //dispatch(closeAddToOrder());
    handleCloseAddToOrder();
  };

  const [itemToAdd, setItemToAdd] = React.useState(null);
  const [itemToAddSelected, setItemToAddSelected] = React.useState(false);

  //Все доступные опции
  const [itemOptions, setItemOptions] = React.useState([]);

  //Временная цена товара
  const [itemTmpPrice, setItemTmpPrice] = React.useState(null);

  //Сформированный товар для добавления
  const [itemToAddTmp, setItemToAddTmp] = React.useState(null);

  //Кол-во позиций
  const [itemToAddTmpQuantity, setItemToAddTmpQuantity] = React.useState(1);

  // const [showAddBonusPopup, setShowAddBonusPopup] = React.useState(false);

  const handleSelectItemToAdd = (e) => {
    var result = itemsToAdd.filter(obj => {
      return obj._id === e
    })


    /// //// /// /// /// ///
    //Наполняем опции

    //Все доступные опции товара
    let optionarray = [];

    for (var i = 0; i < result[0].options.length; i++) {
      console.log('заполняем опции');
      let tmp_option = {};
      // тут проверять тип опции
      if (result[0].options[i].type == 'radio') {
        tmp_option = {
          optionNumber: i,
          name: result[0].options[i].label,
          selectedOption: result[0].options[i].availableOptions[0].name,
          availableOptions: result[0].options[i].availableOptions,
          type: result[0].options[i].type,
        };
      }

      if (result[0].options[i].type == 'simple_toggle') {
        tmp_option = {
          optionNumber: i,
          name: result[0].options[i].label,
          base_name: result[0].options[i].base_name,
          second_name: result[0].options[i].second_name,
          prefix: result[0].options[i].prefix,
          optionprice: result[0].options[i].optionprice,
          type: result[0].options[i].type,
          extraOptionId: result[0].options[i].extraOptionId,
          selectedOption: result[0].options[i].base_name,
        };
      }
      optionarray.push(tmp_option);
    }

    //Назначили массив из всех опций
    setItemOptions(optionarray);

    //Назначили добавляемый товар
    setItemToAdd(result[0]);

    //Указали, что товар выбран
    setItemToAddSelected(true);

    //Массив выбранных опций у товара
    let tmp_selected = [];

    //Перебираем доступные опции товара
    for (var x = 0; x < optionarray.length; x++) {

      //Если опция "Радио"
      if (optionarray[x].type == 'radio') {
        //Перебираем доступные опции у опции "Радио"
        for (var z = 0; z < optionarray[x].availableOptions.length; z++) {
          //Не понятно что блять я тут делал
          //Нашли в массиве всех опций товара выбранную под-опцию
          if (optionarray[x].selectedOption == optionarray[x].availableOptions[z].name) {
            let value = z;
            let name = optionarray[x].name;
            let hide = optionarray[x].availableOptions[z].hide;
            let label = optionarray[x].selectedOption;
            let prefix = optionarray[x].availableOptions[z].prefix;
            let optionprice = optionarray[x].availableOptions[z].optionprice;
            let radio = null;
            if (optionarray[x].type == 'radio') {
              radio = x;
            }
            let tmp_option = {
              type: 'radio',
              value: value,
              hide: hide,
              name: name,
              label: label,
              prefix: prefix,
              optionprice: optionprice,
              radio: radio
            }
            // console.log('push');
            // console.log(tmp_option);
            tmp_selected.push(tmp_option);
          }
        }
      }

      //Если опция "Переключатель"
      if (optionarray[x].type == 'simple_toggle') {

        let value = 1;
        let name = optionarray[x].name;
        let label = optionarray[x].base_name;
        let prefix = optionarray[x].prefix;
        let optionprice = optionarray[x].optionprice;

        let tmp_option = {
          type: 'simple_toggle',
          togglenum: x,
          toggle: x,
          label: label,
          value: value,
          name: name,
          prefix: prefix,
          optionprice: 0,
        }

        // console.log('push toggle');
        // console.log(tmp_option);
        tmp_selected.push(tmp_option);

      }

    }

    let tmp_price = result[0].base_price;

    for (var i = 0; i < tmp_selected.length; i++) {
      if (tmp_selected[i].prefix == "+") {
        tmp_price += parseInt(tmp_selected[i].optionprice, 10);
      } else {
        tmp_price -= parseInt(tmp_selected[i].optionprice, 10);
      }
    }

    if (result[0].sale > 0) {
      tmp_price = parseInt(result[0].base_price - (result[0].base_price / 100) * result[0].sale, 10) * 1;
    }

    let tmp_item = result[0];

    tmp_item = {
      id: tmp_item._id,
      name: tmp_item.name,
      price: tmp_price,
      base_price: tmp_item.base_price,
      quantity: itemToAddTmpQuantity,
      image1: tmp_item.image1,
      selectedOptions: tmp_selected,
      sale: tmp_item.sale,
    }

    // console.log('tmp_item');
    // console.log(tmp_item);

    setItemTmpPrice(tmp_price);
    setItemToAddTmp(tmp_item);

  }

  const onChange1Quantity = (e, i) => {
    // console.log(e);
    setItemToAddTmpQuantity(e);
  }

  const onChange1 = (e, i) => {
    //пробежимся по опциям и выставим выбранную
    // console.log(e);
    // console.log(i);
    // console.log('---');
    let tmp_itemOptions = itemOptions;
    for (var y = 0; y < itemOptions.length; y++) {
      if (y == i) {
        console.log('нашли ' + y)
        tmp_itemOptions[y].selectedOption = e;
        // console.log(tmp_itemOptions[i].name)
      }
    }
    setItemOptions(tmp_itemOptions);
    tmp_itemOptions = [];
    // console.log(itemOptions);

    //Составим массив товара с выбранными опциями

    let tmp_selected = [];

    for (var x = 0; x < itemOptions.length; x++) {
      if (itemOptions[x].type == 'radio') {
        for (var z = 0; z < itemOptions[x].availableOptions.length; z++) {
          if (itemOptions[x].selectedOption == itemOptions[x].availableOptions[z].name) {
            // console.log('совпадение');
            // console.log(itemOptions[x].selectedOption);
            let value = z;
            let hide = itemOptions[x].availableOptions[z].hide;
            let name = itemOptions[x].name;
            let label = itemOptions[x].selectedOption;
            let prefix = itemOptions[x].availableOptions[z].prefix;
            let optionprice = itemOptions[x].availableOptions[z].optionprice;
            let radio = null;
            if (itemOptions[x].type == 'radio') {
              radio = x;
            }
            let tmp_option = {
              value: value,
              name: name,
              hide: hide,
              label: label,
              prefix: prefix,
              optionprice: optionprice,
              radio: radio
            }
            tmp_selected.push(tmp_option);
          }
        }
      }
      if (itemOptions[x].type == 'simple_toggle') {
        if (itemOptions[x].selectedOption == itemOptions[x].base_name) {
          console.log('Выбрана первая опция');
          //делаем нулевую опцию
          let label = itemOptions[x].base_name;
          let name = itemOptions[x].name;
          let prefix = itemOptions[x].prefix;
          let tmp_option = {
            value: 1,
            name: name,
            label: label,
            prefix: prefix,
            optionprice: 0,
            toggle: x
          }
          tmp_selected.push(tmp_option);
        }
        if (itemOptions[x].selectedOption == itemOptions[x].second_name) {
          console.log('Выбрана вторая опция');
          let label = itemOptions[x].second_name;
          let name = itemOptions[x].name;
          let prefix = itemOptions[x].prefix;
          let optionprice = itemOptions[x].optionprice;
          let extraOptionId = itemOptions[x].extraOptionId;
          let tmp_option = {
            value: 2,
            name: name,
            label: label,
            prefix: prefix,
            optionprice: optionprice,
            toggle: x,
            extraOptionId: extraOptionId,
          }
          tmp_selected.push(tmp_option);
        }
      }
    }

    let tmp_price = itemToAdd.base_price;

    for (var i = 0; i < tmp_selected.length; i++) {
      if (tmp_selected[i].prefix == "+") {
        tmp_price += parseInt(tmp_selected[i].optionprice, 10);
      } else {
        tmp_price -= parseInt(tmp_selected[i].optionprice, 10);
      }
    }


    if (itemToAdd.sale > 0) {
      tmp_price = parseInt(tmp_price - (tmp_price / 100) * itemToAdd.sale, 10);
    }

    let tmp_item = itemToAdd;

    tmp_item = {
      id: tmp_item._id,
      name: tmp_item.name,
      price: tmp_price,
      base_price: tmp_item.base_price,
      quantity: itemToAddTmpQuantity,
      image1: tmp_item.image1,
      selectedOptions: tmp_selected,
      sale: tmp_item.sale,
    }

    // console.log('tmp_item');
    // console.log(tmp_item);
    setItemTmpPrice(tmp_price);
    setItemToAddTmp(tmp_item);
  }



  function renderAddToOrder() {

    if (itemsToAddLoading) {
      return (
        <>
          <p>ЗАГРУЖАЕМ СПИСОК ТОВАРОВ</p>
          <Spin />
        </>
      );
    }
    if (!itemsToAddLoading && !itemToAddSelected) {
      return (
        <>
          <Typography>Выберите товар из списка</Typography>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            size="default"
            style={{ width: '100%' }}
            onChange={handleSelectItemToAdd}>
            {itemsToAdd.map(item => {
              return (
                <Option key={item.name} value={item._id}>{item.name}</Option>
              );
            })}
          </Select>
        </>
      );
    }
    if (itemToAddSelected) {
      return (
        <>
          <div style={{ width: '100%', textAlign: 'left' }}>
            <Typography>Название: {itemToAdd.name}</Typography>
            <hr />
            <Typography>Базовая цена: {itemToAdd.base_price}</Typography>
            {itemToAdd.sale > 0 ? (<>
              <hr />
              <Typography>Цена со скидкой: {(parseInt(itemToAdd.base_price - (itemToAdd.base_price / 100) * itemToAdd.sale, 10)) * 1}</Typography>
            </>) : (<></>)}

          </div>
          <hr />
          <div style={{ width: '100%', textAlign: 'left' }}>
            <Text strong>Доступные опции:</Text>
            <div>
              <Row gutter={16}>
                {itemToAdd.options.map((parentoption, i) => {
                  if (parentoption.type == 'radio') {
                    return (
                      <Col span={12}>
                        <Typography>{parentoption.label}</Typography>
                        {renderAddToOrderOption(i)}
                      </Col>
                    )
                  } else if (parentoption.type == 'simple_toggle') {
                    return (
                      <Col span={12}>
                        <Typography>{parentoption.label}</Typography>
                        {renderAddToOrderOptionToggle(i)}
                      </Col>
                    )
                  }
                })}
              </Row>
            </div>
          </div>
          <hr />

          <div style={{ width: '100%', textAlign: 'left' }}>
            <Text strong>Укажите кол-во: </Text>
            <InputNumber min={1} value={itemToAddTmpQuantity} onChange={onChange1Quantity} /><br />
            {itemToAdd.sale > 0 ? (<>
              <Text strong>{itemTmpPrice * itemToAddTmpQuantity}</Text>
            </>
            ) : (
              <>
                <Text strong>Итоговая цена: {itemTmpPrice * itemToAddTmpQuantity}</Text>
              </>
            )}
          </div>
        </>
      );
    }
  }

  function renderAddBonusPopup(order) {

    let percentBonus = 10;
    let bonusToAdd = (Math.floor(order.summa / percentBonus));

    return (
      <>
        Номер заказа: <b>{order.id}</b>
        <br />
        К начислению: <b>{bonusToAdd}</b><br /><br />
        <Button type="primary" onClick={() => dispatch(applyBonus(order.id))}>Начислить</Button>
      </>
    )

  }

  function renderAddToOrderOptionToggle(i) {
    return (
      <RadioGroup onChange={(e) => onChange1(e, i)} value={itemOptions[i].selectedOption}>
        <ReversedRadioButton rootColor={'#bdbdbd'} pointColor={'#9FBE34'} padding={8} iconSize={20} value={itemOptions[i].base_name}>{itemOptions[i].base_name}</ReversedRadioButton>
        <ReversedRadioButton rootColor={'#bdbdbd'} pointColor={'#9FBE34'} padding={8} iconSize={20} value={itemOptions[i].second_name}>{itemOptions[i].second_name}</ReversedRadioButton>
      </RadioGroup>
    );
  }

  function renderAddToOrderOption(i) {
    return (
      <RadioGroup onChange={(e) => onChange1(e, i)} value={itemOptions[i].selectedOption}>
        {itemOptions[i].availableOptions.map((childoption, y) => (
          <ReversedRadioButton rootColor={'#bdbdbd'} pointColor={'#9FBE34'} padding={8} iconSize={20} value={childoption.name}>{childoption.name}</ReversedRadioButton>
        ))}
      </RadioGroup>
    );
  }

  function renderOrderInfoFooter() {
    return (
      <Row type="flex" justify="end">
        <Col style={{ display: 'flex', marginRight: 20 }}>
          <Text strong style={{ alignSelf: 'center' }}>Стоимость позиций: {orderContent.summa}</Text>
        </Col>
        <Col>
          <Button className="hungry-add-btn" type="primary" onClick={() => handleOpenAddToOrder(orderContent)}>Добавить позицию</Button>
        </Col>
      </Row>
    );
  }

  const [chequeToPrint, setChequeToPrint] = React.useState({ cartitems: [] });

  const openChequePrint = order => {
    setChequeToPrint(order);
    console.log(order);
    dispatch(showChequeModal(order));
  };
  const closeChequePrint = () => {
    setChequeToPrint({ cartitems: [] });
    dispatch(closeChequeModal());
  };


  function renderHistory(order) {
    return (
      <>
        <div>
          <Tag>{order.orderFrom}</Tag>
        </div>
        <div>
          <Tag>Создан: <Moment date={order.created_at} format="lll" locale="ru" /></Tag>
        </div>
        <div style={{ paddingTop: 4, clear: 'both', borderBottomWidth: 2, borderBottomColor: '#9FBE34', borderBottomStyle: 'dashed' }}></div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {order.statusHistory.map((record, i) => {
              return (
                <tr key={i}>
                  <td style={{ paddingRight: 20 }}><Tag>{record.new_status}</Tag></td>
                  <td><Moment style={{ fontWeight: 'bold' }} date={record.changed_at} format="HH:mm" locale="ru" /> <Moment date={record.changed_at} format="L" locale="ru" /></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }

  function renderContent(items) {
    return (
      <>
        <table>
          <thead>
            <tr style={{ borderBottomWidth: 2, borderBottomColor: '#9FBE34', borderBottomStyle: 'dashed' }}>
              <th>Наименование</th>
              <th>Кол-во</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, i) => {
              let itemname = item.name;
              item.selectedOptions.map((option, y) => {
                if (option.name) {
                  if (!option.hide) {
                    itemname += ' ';
                    itemname += option.name + ': ';
                    itemname += option.label;
                  }
                }

              })
              let quantity = item.quantity + ' шт.';
              return (
                <tr key={itemname} style={{ borderBottomWidth: 1, borderBottomColor: '#eeeeee', borderBottomStyle: 'solid' }}>
                  <td><Text style={{}}>{itemname}</Text></td>
                  <td><Text style={{}}>{quantity}</Text></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </>
    )
  }


  function row_SaveStatus(row, val) {
    console.log('Сохраняем status');
    console.log('Новый статус - ' + val);
    console.log('В заказе - ' + row._id);
    dispatch(changeOrderStatus(row._id, val));
  }

  function row_SaveCourier(row, val) {
    console.log('Сохраняем courier_id');
    console.log('Новый id - ' + val);
    console.log('В заказе - ' + row._id);
    dispatch(changeOrderCourier(row._id, courierList[val]));
  }

  function row_SavePayment(row, val) {
    console.log('Сохраняем paymenttype');
    console.log('Новый способ - ' + val);
    console.log('В заказе - ' + row._id);
    var paymentObj = payments.filter(obj => {
      return obj._id === val
    })

    if (paymentObj[0].online) {
      return;
    } else {
      dispatch(changeOrderPayment(row._id, val));
    }
  }

  function row_SaveDeliveryDate(row, val) {
    console.log('Получили дату и время "Доставить к"');
    console.log(val);
    dispatch(changeOrderDeliveryDate(row._id, val));
  }

  function row_SavePaid(row, val) {
    console.log('Получили смену статуса оплаты');
    dispatch(changeOrderPaidStatus(row._id, val));
  }

  function row_SaveDeliveryPrice(row, val) {
    console.log('Получили смену стоимости доставки');
    dispatch(changeOrderDeliveryPrice(row._id, val));
  }

  const handleSavePaid = (row, val) => {
    if (val.paid !== "Заказ оплачен?") {
      if (user.role != 'operator') {
        row_SavePaid(row, val.paid);
      } else {
        console.log('ой')
      }
    }
  }
  const handleSaveDeliveryPrice = (row, val) => {
    row_SaveDeliveryPrice(row, val.deliveryprice);
  }
  const handleSaveStatus = (row, val) => {
    if (Number.isInteger(val.status) || val.status == 0) {
      row_SaveStatus(row, val.status);
    }
  }
  const handleSavePaymentType = (row, val) => {
    if (val.paymenttype != 'Выберите способ оплаты') {
      row_SavePayment(row, val.paymenttype);
    }
  }
  const handleSaveDeliveryDate = (row, val) => {
    row_SaveDeliveryDate(row, val.deliveryDate);
  }
  const handleSaveCourier = (row, val) => {
    if (Number.isInteger(val.courier_id) || val.courier_id == 0) {
      row_SaveCourier(row, val.courier_id);
    }
  }




  //НОВАЯ ВЕРСИЯ СОЗДАНИЯ ЗАКАЗА
  const [showNewOrderModal, setShowNewOrderModal] = React.useState(false);
  const [newOrderContent, setNewOrderContent] = React.useState({
    name: '',
    phone: '',
    email: '',
    street: '',
    comment: '',
    building: '',
    building_extra: '',
    appartments: '',
    appartments_level: '',
    appartments_block: '',
    deliverytype: '',
    paymenttype: '',
    cartitems: [],
    summa: 0
  });


  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [newOrderItemOptions, setNewOrderItemOptions] = React.useState([]);
  const [itemToAddNewOrderTmpQuantity, setItemToAddNewOrderTmpQuantity] = React.useState(1);
  const [itemNewOrderTmpPrice, setItemNewOrderTmpPrice] = React.useState(0);
  const [itemToAddNewOrderTmp, setItemToAddNewOrderTmp] = React.useState({});
  const [newOrderDelivery, setNewOrderDelivery] = React.useState('');
  const [newOrderPaymentErrors, setNewOrderPaymentErrors] = React.useState(false);
  const [newOrderPayment, setNewOrderPayment] = React.useState('');
  const [minDeliveryPrice, setMinDeliveryPrice] = React.useState(0);
  const [freeDeliveryPrice, setFreeDeliveryPrice] = React.useState(null);
  const [newOrderSumm, setNewOrderSumm] = React.useState(0);
  const [newOrderClientData, setNewOrderClientData] = React.useState({});


  const onChangeNewOrderToggle = (e, i) => {
    //пробежимся по опциям и выставим выбранную
    let tmp_itemOptions = newOrderItemOptions;
    for (var y = 0; y < newOrderItemOptions.length; y++) {
      if (y == i) {
        console.log('нашли ' + y)
        tmp_itemOptions[y].selectedOption = e;
        // console.log(tmp_itemOptions[i].name)
      }
    }
    setNewOrderItemOptions(tmp_itemOptions);
    tmp_itemOptions = [];
    // console.log(itemOptions);

    //Составим массив товара с выбранными опциями

    let tmp_selected = [];

    for (var x = 0; x < newOrderItemOptions.length; x++) {
      if (newOrderItemOptions[x].type == 'radio') {
        for (var z = 0; z < newOrderItemOptions[x].availableOptions.length; z++) {
          if (newOrderItemOptions[x].selectedOption == newOrderItemOptions[x].availableOptions[z].name) {
            // console.log('совпадение');
            // console.log(itemOptions[x].selectedOption);
            let value = z;
            let hide = newOrderItemOptions[x].availableOptions[z].hide;
            let name = newOrderItemOptions[x].name;
            let label = newOrderItemOptions[x].selectedOption;
            let prefix = newOrderItemOptions[x].availableOptions[z].prefix;
            let optionprice = newOrderItemOptions[x].availableOptions[z].optionprice;
            let radio = null;
            if (newOrderItemOptions[x].type == 'radio') {
              radio = x;
            }
            let tmp_option = {
              value: value,
              name: name,
              hide: hide,
              label: label,
              prefix: prefix,
              optionprice: optionprice,
              radio: radio
            }
            tmp_selected.push(tmp_option);
          }
        }
      }
      if (newOrderItemOptions[x].type == 'simple_toggle') {
        if (newOrderItemOptions[x].selectedOption == newOrderItemOptions[x].base_name) {
          console.log('Выбрана первая опция');
          //делаем нулевую опцию
          let label = newOrderItemOptions[x].base_name;
          let name = newOrderItemOptions[x].name;
          let prefix = newOrderItemOptions[x].prefix;
          let tmp_option = {
            value: 1,
            name: name,
            label: label,
            prefix: prefix,
            optionprice: 0,
            toggle: x
          }
          tmp_selected.push(tmp_option);
        }
        if (newOrderItemOptions[x].selectedOption == newOrderItemOptions[x].second_name) {
          console.log('Выбрана вторая опция');
          let label = newOrderItemOptions[x].second_name;
          let name = newOrderItemOptions[x].name;
          let prefix = newOrderItemOptions[x].prefix;
          let optionprice = newOrderItemOptions[x].optionprice;
          let extraOptionId = newOrderItemOptions[x].extraOptionId;
          let tmp_option = {
            value: 2,
            name: name,
            label: label,
            prefix: prefix,
            optionprice: optionprice,
            toggle: x,
            extraOptionId: extraOptionId,
          }
          tmp_selected.push(tmp_option);
        }
      }
    }

    let tmp_price = itemToAddNewOrderTmp.base_price;

    for (var i = 0; i < tmp_selected.length; i++) {
      if (tmp_selected[i].prefix == "+") {
        tmp_price += parseInt(tmp_selected[i].optionprice, 10);
      } else {
        tmp_price -= parseInt(tmp_selected[i].optionprice, 10);
      }
    }

    let tmp_item = itemToAddNewOrderTmp;

    tmp_item = {
      id: tmp_item.id,
      name: tmp_item.name,
      price: tmp_price,
      base_price: tmp_item.base_price,
      quantity: itemToAddNewOrderTmpQuantity,
      image1: tmp_item.image1,
      selectedOptions: tmp_selected,
      sale: tmp_item.sale
    }
    setItemNewOrderTmpPrice(tmp_price);
    setItemToAddNewOrderTmp(tmp_item);
  }

  const handleSelectItemToAddNewOrder = (item) => {
    //Все доступные опции товара
    let optionarray = [];

    for (var i = 0; i < item.options.length; i++) {
      console.log('заполняем опции');
      let tmp_option = {};
      // тут проверять тип опции
      if (item.options[i].type == 'radio') {
        tmp_option = {
          optionNumber: i,
          name: item.options[i].label,
          selectedOption: item.options[i].availableOptions[0].name,
          availableOptions: item.options[i].availableOptions,
          type: item.options[i].type,
        };
      }

      if (item.options[i].type == 'simple_toggle') {
        tmp_option = {
          optionNumber: i,
          name: item.options[i].label,
          base_name: item.options[i].base_name,
          second_name: item.options[i].second_name,
          prefix: item.options[i].prefix,
          optionprice: item.options[i].optionprice,
          type: item.options[i].type,
          extraOptionId: item.options[i].extraOptionId,
          selectedOption: item.options[i].base_name,
        };
      }
      optionarray.push(tmp_option);
    }

    //Назначили массив из всех опций
    setNewOrderItemOptions(optionarray);

    //Массив выбранных опций у товара
    let tmp_selected = [];

    //Перебираем доступные опции товара
    for (var x = 0; x < optionarray.length; x++) {

      //Если опция "Радио"
      if (optionarray[x].type == 'radio') {
        //Перебираем доступные опции у опции "Радио"
        for (var z = 0; z < optionarray[x].availableOptions.length; z++) {
          //Не понятно что блять я тут делал
          //Нашли в массиве всех опций товара выбранную под-опцию
          if (optionarray[x].selectedOption == optionarray[x].availableOptions[z].name) {
            let value = z;
            let name = optionarray[x].name;
            let hide = optionarray[x].availableOptions[z].hide;
            let label = optionarray[x].selectedOption;
            let prefix = optionarray[x].availableOptions[z].prefix;
            let optionprice = optionarray[x].availableOptions[z].optionprice;
            let radio = null;
            if (optionarray[x].type == 'radio') {
              radio = x;
            }
            let tmp_option = {
              type: 'radio',
              value: value,
              hide: hide,
              name: name,
              label: label,
              prefix: prefix,
              optionprice: optionprice,
              radio: radio
            }
            // console.log('push');
            // console.log(tmp_option);
            tmp_selected.push(tmp_option);
          }
        }
      }

      //Если опция "Переключатель"
      if (optionarray[x].type == 'simple_toggle') {

        let value = 1;
        let name = optionarray[x].name;
        let label = optionarray[x].base_name;
        let prefix = optionarray[x].prefix;
        let optionprice = optionarray[x].optionprice;

        let tmp_option = {
          type: 'simple_toggle',
          togglenum: x,
          toggle: x,
          label: label,
          value: value,
          name: name,
          prefix: prefix,
          optionprice: 0,
        }

        // console.log('push toggle');
        // console.log(tmp_option);
        tmp_selected.push(tmp_option);

      }

    }

    let tmp_price = item.base_price;

    for (var i = 0; i < tmp_selected.length; i++) {
      if (tmp_selected[i].prefix == "+") {
        tmp_price += parseInt(tmp_selected[i].optionprice, 10);
      } else {
        tmp_price -= parseInt(tmp_selected[i].optionprice, 10);
      }
    }

    let tmp_item = item;

    tmp_item = {
      id: tmp_item._id,
      name: tmp_item.name,
      price: tmp_price,
      base_price: tmp_item.base_price,
      quantity: itemToAddNewOrderTmpQuantity,
      image1: tmp_item.image1,
      selectedOptions: tmp_selected,
      sale: tmp_item.sale
    }

    // console.log('tmp_item');
    // console.log(tmp_item);

    setItemNewOrderTmpPrice(tmp_price);
    setItemToAddNewOrderTmp(tmp_item);
  }



  const onChangeNewOrderOption = (e, i) => {
    //пробежимся по опциям и выставим выбранную
    let tmp_itemOptions = newOrderItemOptions;
    for (var y = 0; y < newOrderItemOptions.length; y++) {
      if (y == i) {
        console.log('нашли ' + y)
        tmp_itemOptions[y].selectedOption = e;
        // console.log(tmp_itemOptions[i].name)
      }
    }
    setNewOrderItemOptions(tmp_itemOptions);
    tmp_itemOptions = [];
    // console.log(itemOptions);

    //Составим массив товара с выбранными опциями

    let tmp_selected = [];

    for (var x = 0; x < newOrderItemOptions.length; x++) {
      if (newOrderItemOptions[x].type == 'radio') {
        for (var z = 0; z < newOrderItemOptions[x].availableOptions.length; z++) {
          if (newOrderItemOptions[x].selectedOption == newOrderItemOptions[x].availableOptions[z].name) {
            // console.log('совпадение');
            // console.log(itemOptions[x].selectedOption);
            let value = z;
            let hide = newOrderItemOptions[x].availableOptions[z].hide;
            let name = newOrderItemOptions[x].name;
            let label = newOrderItemOptions[x].selectedOption;
            let prefix = newOrderItemOptions[x].availableOptions[z].prefix;
            let optionprice = newOrderItemOptions[x].availableOptions[z].optionprice;
            let radio = null;
            if (newOrderItemOptions[x].type == 'radio') {
              radio = x;
            }
            let tmp_option = {
              value: value,
              name: name,
              hide: hide,
              label: label,
              prefix: prefix,
              optionprice: optionprice,
              radio: radio
            }
            tmp_selected.push(tmp_option);
          }
        }
      }
      if (newOrderItemOptions[x].type == 'simple_toggle') {
        if (newOrderItemOptions[x].selectedOption == newOrderItemOptions[x].base_name) {
          console.log('Выбрана первая опция');
          //делаем нулевую опцию
          let label = newOrderItemOptions[x].base_name;
          let name = newOrderItemOptions[x].name;
          let prefix = newOrderItemOptions[x].prefix;
          let tmp_option = {
            value: 1,
            name: name,
            label: label,
            prefix: prefix,
            optionprice: 0,
            toggle: x
          }
          tmp_selected.push(tmp_option);
        }
        if (newOrderItemOptions[x].selectedOption == newOrderItemOptions[x].second_name) {
          console.log('Выбрана вторая опция');
          let label = newOrderItemOptions[x].second_name;
          let name = newOrderItemOptions[x].name;
          let prefix = newOrderItemOptions[x].prefix;
          let optionprice = newOrderItemOptions[x].optionprice;
          let extraOptionId = newOrderItemOptions[x].extraOptionId;
          let tmp_option = {
            value: 2,
            name: name,
            label: label,
            prefix: prefix,
            optionprice: optionprice,
            toggle: x,
            extraOptionId: extraOptionId,
          }
          tmp_selected.push(tmp_option);
        }
      }
    }

    let tmp_price = itemToAddNewOrderTmp.base_price;

    for (var i = 0; i < tmp_selected.length; i++) {
      if (tmp_selected[i].prefix == "+") {
        tmp_price += parseInt(tmp_selected[i].optionprice, 10);
      } else {
        tmp_price -= parseInt(tmp_selected[i].optionprice, 10);
      }
    }

    let tmp_item = itemToAddNewOrderTmp;

    tmp_item = {
      id: tmp_item.id,
      name: tmp_item.name,
      price: tmp_price,
      base_price: tmp_item.base_price,
      quantity: itemToAddNewOrderTmpQuantity,
      image1: tmp_item.image1,
      selectedOptions: tmp_selected,
      sale: tmp_item.sale
    }
    setItemNewOrderTmpPrice(tmp_price);
    setItemToAddNewOrderTmp(tmp_item);
  }

  // function renderAddToNewOrderOption(i) {
  //   return (
  //     <div>
  //       2
  //     </div>
  //   );
  // }

  function renderAddToNewOrderOption(i) {
    return (
      <div className="radio-new-order">
        <RadioGroup onChange={(e) => onChangeNewOrderOption(e, i)} value={newOrderItemOptions[i].selectedOption}>
          {newOrderItemOptions[i].availableOptions.map((childoption, y) => (
            <ReversedRadioButton rootColor={'#949494'} pointColor={'#00ab11'} padding={8} iconSize={20} value={childoption.name}>{childoption.name}</ReversedRadioButton>
          ))}
        </RadioGroup>
      </div>
    );
  }

  function renderAddToNewOrderOptionToggle(i) {
    return (
      <div>
        <RadioGroup onChange={(e) => onChangeNewOrderToggle(e, i)} value={newOrderItemOptions[i].selectedOption}>
          <ReversedRadioButton rootColor={'#bdbdbd'} pointColor={'#9FBE34'} padding={8} iconSize={20} value={newOrderItemOptions[i].base_name}>{newOrderItemOptions[i].base_name}</ReversedRadioButton>
          <ReversedRadioButton rootColor={'#bdbdbd'} pointColor={'#9FBE34'} padding={8} iconSize={20} value={newOrderItemOptions[i].second_name}>{newOrderItemOptions[i].second_name}</ReversedRadioButton>
        </RadioGroup>
      </div>
    );
  }

  function renderCreateNewOrder() {

    return (
      <>
        {renderNewOrderContentSelect()}
        <br />
        {renderNewOrderContent()}
        <br />
        {renderNewOrderInfo()}
        <br />
        {renderNewOrderClient()}
        <br />
        {renderNewOrderBonuses()}
        <br />
        {renderNewOrderTotal()}
        <br />
        {renderNewOrderSend()}
      </>
    )

  }

  function renderNewOrderContentSelect() {

    if (selectedCategory == null) {
      return (
        <>
          <Col span={24}>
            <Divider className="header-div" style={{ marginTop: 10 }} orientation="center">Выбор товара</Divider>
          </Col>
          {itemCategoriesToAdd.map(category => (
            <>
              <Button onClick={() => { setSelectedCategory(category) }} style={{ marginBottom: 5, marginRight: 5 }}>{category.name}</Button>
            </>
          ))}
        </>
      )
    } else {

      var newitemsToAdd = itemsToAdd.reduce(function (res, item) {
        if (item.category.includes(selectedCategory.name)) {
          res.push(item);
        }
        return res;
      }, []);


      if (selectedItem == null) {
        return (
          <>
            <Col span={24}>
              <Divider className="header-div" style={{ marginTop: 10 }} orientation="center">Выбор товара</Divider>
            </Col>
            <div><Button style={{ marginBottom: 10 }} icon="left" type="primary" onClick={() => setSelectedCategory(null)}>Категории</Button></div>
            {newitemsToAdd.map(item => (
              <>
                <Button onClick={() => { setSelectedItem(item); handleSelectItemToAddNewOrder(item); }} style={{ marginBottom: 5, marginRight: 5 }}>{item.name}</Button>
              </>
            ))}
          </>
        )
      } else {
        return (
          <>
            <Col span={24}>
              <Divider className="header-div" style={{ marginTop: 10 }} orientation="center">Выбор товара</Divider>
            </Col>
            <div><Button style={{ marginBottom: 10 }} icon="left" type="primary" onClick={() => setSelectedItem(null)}>Товары</Button></div>
            <Col span={24}>Название: {selectedItem.name}</Col>
            <Col span={24}>Базовая цена: {selectedItem.price}</Col>
            <div>
              <Row gutter={16}>

                {selectedItem.options.map((parentoption, i) => {
                  if (parentoption.type == 'radio') {
                    return (
                      <Col span={12} style={{ marginTop: 10 }}>
                        <Typography>{parentoption.label}</Typography>
                        {/* {renderAddToOrderOption(i)} */}
                        {renderAddToNewOrderOption(i)}

                      </Col>
                    )
                  } else if (parentoption.type == 'simple_toggle') {
                    return (
                      <Col span={12} style={{ marginTop: 10 }}>
                        <Typography><b>{parentoption.label}</b></Typography>
                        {renderAddToNewOrderOptionToggle(i)}
                      </Col>
                    )
                  }
                })}

                <Col span={24}><Divider style={{ marginTop: 5, marginBottom: 5 }}></Divider></Col>
                <Col span={12} style={{ textAlign: 'center', marginBottom: 10, marginTop: 10 }}>
                  <Text style={{ marginRight: 10 }}>Кол-во:</Text>
                  <InputNumber min={1} value={itemToAddNewOrderTmpQuantity} onChange={(e) => setItemToAddNewOrderTmpQuantity(e)} /><br />
                </Col>
                <Col span={12} style={{ textAlign: 'center', marginBottom: 10, marginTop: 10, height: 32, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>

                  {itemToAddNewOrderTmp.sale > 0 ? (
                    <>
                      <Text>Цена: {(parseInt(itemNewOrderTmpPrice - (itemNewOrderTmpPrice / 100) * itemToAddNewOrderTmp.sale, 10)) * itemToAddNewOrderTmpQuantity} (скидка {itemToAddNewOrderTmp.sale}%)</Text>
                    </>
                  ) : (<Text>Цена: {itemNewOrderTmpPrice * itemToAddNewOrderTmpQuantity}</Text>)}

                </Col>
                <Col span={24} style={{ justifyContent: 'flex-start', textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
                  <Button onClick={() => addItemToNewOrder(itemToAddNewOrderTmp)} type="primary">Добавить к заказу</Button>
                </Col>
              </Row>
            </div>
          </>
        )
      }


    }

  }

  function calcNewOrderPrice() {
    console.log('calc');
    console.log(newOrderContent);
    var newordersumm = 0;
    newOrderContent.cartitems.map(cartitem => {
      console.log('1');
      console.log(cartitem);
      newordersumm += parseInt(cartitem.price, 10) * parseInt(cartitem.quantity, 10);
    })
    console.log('setting');
    console.log(newordersumm)
    setNewOrderSumm(newordersumm);
  }

  const addItemToNewOrder = (item) => {
    item.quantity = itemToAddNewOrderTmpQuantity;

    if (itemToAddNewOrderTmp.sale > 0) {
      item.price = (parseInt(itemNewOrderTmpPrice - (itemNewOrderTmpPrice / 100) * itemToAddNewOrderTmp.sale, 10))
    }
    setNewOrderContent({ ...newOrderContent, cartitems: [...newOrderContent.cartitems, item] });
    setSelectedItem(null);
    setItemToAddNewOrderTmpQuantity(1);

  };

  const deleteItemFromNewOrder = (itemId, row, index) => {
    const temp = [...newOrderContent.cartitems];
    temp.splice(index, 1);
    setNewOrderContent({ ...newOrderContent, cartitems: temp });
  };
  const plusItemFromNewOrder = (itemId, row, index) => {
    const temp = [...newOrderContent.cartitems];
    temp[index].quantity += 1;
    setNewOrderContent({ ...newOrderContent, cartitems: temp });
  };
  const minusItemFromNewOrder = (itemId, row, index) => {
    const temp = [...newOrderContent.cartitems];
    if (temp[index].quantity > 1) {
      temp[index].quantity -= 1;
      setNewOrderContent({ ...newOrderContent, cartitems: temp });
    }
  };

  useEffect(() => {
    calcNewOrderPrice();
  }, [newOrderContent.cartitems]);

  const handleDeliveryNewOrder = (e) => {
    var deliveryObj = deliveries.filter(obj => {
      return obj.name === e
    })
    console.log(deliveryObj[0]);
    setNewOrderDelivery(deliveryObj[0]._id);
    setMinDeliveryPrice(deliveryObj[0].minDeliveryPrice);
    setFreeDeliveryPrice(deliveryObj[0].freeDeliveryPrice);
  }
  const handlePaymentNewOrder = (e) => {
    console.log(e);
    var paymentObj = payments.filter(obj => {
      return obj.name === e
    })
    console.log(paymentObj[0]);
    if (paymentObj[0].online) {
      setNewOrderPaymentErrors(true);
      return;
    } else {
      setNewOrderPaymentErrors(false);
      setNewOrderPayment(paymentObj[0]._id);
    }
  }


  function renderNewOrderBonuses() {
    return (
      <>
        <Row>
          <Col span={24}>
            <Divider className="header-div new-order-header" style={{ marginTop: 10 }} orientation="center">Бонусные балы</Divider>
          </Col>
          <Col span={24}>
            {newOrderClientData._id != null ? (
              <>
                {renderNewOrderUseBonus()}
              </>
            ) : (<></>)}
          </Col>
        </Row>
      </>
    )
  }

  const [newOrderBonusToUse, setNewOrderBonusToUse] = React.useState(0);


  const changeNewOrderBonusToUse = (e) => {
    if (e <= newOrderSumm) {
      if (e <= newOrderClientData.bonus) {
        setNewOrderBonusToUse(e);
      }
    }
  }

  const newOrderSetBonusToUse = () => {
    setNewOrderContent({
      ...newOrderContent,
      usedbonus: newOrderBonusToUse,
    })
  }

  function renderNewOrderUseBonus() {
    return (
      <>

        <Col span={12} style={{ textAlign: 'left', marginBottom: 10, marginTop: 10, padding: 4 }}>
          <Alert style={{ height: 32, padding: 3, marginTop: 0 }} message={<>
            <div style={{ fontSize: 14, display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed', textAlign: 'center', justifyContent: 'center' }}>
              <span><b>{newOrderClientData.bonus}</b> бонусов.</span>
            </div>
          </>} type="success" />
        </Col>
        <Col span={12} style={{ textAlign: 'left', marginBottom: 10, marginTop: 10, padding: 4 }}>
          <InputNumber style={{ width: '100%' }} min={0} value={newOrderBonusToUse} onChange={(e) => changeNewOrderBonusToUse(e)} />
        </Col>
        <Col span={24} style={{ textAlign: 'left', marginBottom: 10, marginTop: 0, padding: 4 }}>
          {newOrderBonusToUse > 0 ? (<>
            <Button style={{ width: '100%' }} type="dashed" onClick={() => newOrderSetBonusToUse()}>Списать</Button>
          </>) : (<></>)}

        </Col>
      </>
    )
  }

  function renderNewOrderInfo() {
    return (
      <>
        <Col span={24}>
          <Divider className="header-div" style={{ marginTop: 10 }} orientation="center">Оплата и Доставка</Divider>
        </Col>
        <Text style={{ width: '100%' }}>Доставка</Text>
        <Select style={{ width: '100%' }} placeholder="Способ доставки" onChange={handleDeliveryNewOrder}>
          {deliveries.map((delivery, i) => {
            return (
              <Option key={delivery.name.toString()}>{delivery.name}</Option>
            )
          })}
        </Select>
        <Text style={{ width: '100%', marginTop: 10 }}>Оплата</Text>
        <Select style={{ width: '100%' }} placeholder="Способ оплаты" onChange={handlePaymentNewOrder}>
          {payments.map((payment, i) => {
            return (
              <Option key={payment.name.toString()}>{payment.name}</Option>
            )
          })}
        </Select>
        {newOrderPaymentErrors ? (<Typography style={{ color: 'red' }}>Онлайн оплата запрещена при создании заказа</Typography>) : (<></>)}
      </>
    )
  }

  function renderNewOrderTotal() {
    return (
      <>
        <Row>
          <Col span={24}>
            <Divider className="header-div new-order-header" style={{ marginBottom: 0 }} orientation="center">Итоговая стоимость</Divider>
          </Col>
          <Col style={{ fontSize: 18 }} span={24}><Text>Сумма: <b>{newOrderSumm}</b></Text></Col>
          {newOrderDelivery != '' ? (<><Col style={{ fontSize: 18 }} span={24}><Text>Доставка: <b>{newOrderSumm > freeDeliveryPrice ? (<>Бесплатно</>) : (<>{minDeliveryPrice}</>)}</b></Text></Col></>) : (<></>)}
          {newOrderContent.usedbonus != null && newOrderContent.usedbonus > 0 ? (<><Col style={{ fontSize: 18 }} span={24}><Text>Использовано бонусов: <b>{newOrderContent.usedbonus}</b></Text></Col></>) : (<></>)}
          <Col style={{ fontSize: 18 }} span={24}><Text>Итого: <b>{calcNewOrderTotal()}</b></Text></Col>
        </Row>
      </>
    )
  }

  function calcNewOrderTotal() {

    if (newOrderSumm > freeDeliveryPrice) {

      if (newOrderContent.usedbonus > 0) {
        return newOrderSumm - newOrderContent.usedbonus;
      } else {
        return newOrderSumm;
      }

    } else {
      if (newOrderContent.usedbonus > 0) {
        return newOrderSumm + minDeliveryPrice - newOrderContent.usedbonus;
      } else {
        return newOrderSumm + minDeliveryPrice;
      }
    }
  }

  const newOrderSend = () => {
    let ordersumm = newOrderSumm;
    let orderdelivery = null;
    if (newOrderSumm > freeDeliveryPrice) {
      orderdelivery = 0;
    } else {
      orderdelivery = minDeliveryPrice;
    }

    var orderdata = {
      clientname: newOrderContent.name,
      clientphone: newOrderContent.phone,
      clientemail: newOrderContent.email,
      clientstreet: newOrderContent.street,
      clientbuilding: newOrderContent.building,
      clientbuildingextra: newOrderContent.building_extra,
      clientappartmentslevel: newOrderContent.appartments_level,
      clientappartments: newOrderContent.appartments,
      clientcomment: newOrderContent.comment,
      cartitems: newOrderContent.cartitems,
      usedbonus: newOrderContent.usedbonus,
      summa: ordersumm,
      deliverytype: newOrderDelivery,
      deliveryprice: orderdelivery,
      paymenttype: newOrderPayment,
    };
    dispatch(createNewOrder(orderdata));

    setSelectedCategory(null);
    setSelectedItem(null);
    setNewOrderItemOptions([]);
    setItemToAddNewOrderTmpQuantity(1);
    setItemNewOrderTmpPrice(0);
    setItemToAddNewOrderTmp({});
    setNewOrderDelivery('');
    setNewOrderPaymentErrors(false);
    setNewOrderPayment('');
    setMinDeliveryPrice(0);
    setFreeDeliveryPrice(null);
    setNewOrderSumm(0);
    setNewOrderClientData({});
    setNewOrderContent({
      name: '',
      phone: '',
      email: '',
      street: '',
      comment: '',
      building: '',
      building_extra: '',
      appartments: '',
      appartments_level: '',
      appartments_block: '',
      deliverytype: '',
      paymenttype: '',
      cartitems: [],
      summa: 0
    });
  }

  function renderNewOrderSend() {
    return (
      <>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Button type="primary" onClick={() => { newOrderSend(); setShowNewOrderModal(false); }}>Создать</Button>
          </Col>
        </Row>
      </>
    )
  }



  const handleClientNewOrder = (client) => {
    newOrderResetSearch();
    setNewOrderClientSearch(false);
    console.log(client);
    setNewOrderClientData(client);
    setNewOrderContent({
      ...newOrderContent,
      name: client.name,
      phone: client.phone,
      email: client.email,
      street: client.delivery_address.street,
      building: client.delivery_address.building,
      building_extra: client.delivery_address.building_extra,
      appartments: client.delivery_address.appartments,
      appartments_level: client.delivery_address.appartments_level,
      appartments_block: client.delivery_address.appartments_block,
      usedbonus: 0,
    })
  }

  function renderNewOrderClient() {

    const columns_searched = [
      {
        title: 'Имя', dataIndex: 'name', key: 'name'
      },
      {
        title: 'Телефон', dataIndex: 'phone', key: 'phone'
      },
      {
        title: 'E-mail', dataIndex: 'email', key: 'email'
      },
      {
        render: (client, row) => {
          return (
            <Button icon="check" onClick={() => handleClientNewOrder(row)}></Button>
          );
        }
      },
    ]



    return (
      <>
        <Col span={24}>
          <Divider className="header-div new-order-header" style={{ marginTop: 10 }} orientation="center">Клиент</Divider>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}><Button type="primary" onClick={() => setNewOrderClientSearch(!newOrderClientSearch)}>Поиск клиента</Button><br /><br /></Col>

        {newOrderClientSearch ? (
          <>
            <Row gutter={16} style={{ marginTop: 20 }}>
              <Col xs={24} sm={24}>
                <Row type="flex">
                  <Input placeholder="Поиск по email" style={{ maxWidth: 200 }} value={newOrderClientEmailSearch} onChange={(e) => setNewOrderClientEmailSearch(e.target.value)} />
                  <Button icon="search" onClick={newOrderSearchClientEmail}></Button>
                  <Button icon="close-circle" type="dashed" onClick={newOrderResetSearch}></Button>
                </Row>

              </Col>
              <Col xs={24} sm={24} style={{ marginTop: 10 }}>
                <Row type="flex">
                  <MaskedInput style={{ maxWidth: 200 }} icon="search" placeholder="Поиск по телефону" mask="+7 (111) 111-11-11" size="20" value={newOrderClientPhoneSearch} onChange={(e) => { setNewOrderClientPhoneSearch(e.target.value); console.log(e) }} />
                  <Button icon="search" onClick={newOrderSearchClientPhone}></Button>
                  <Button icon="close-circle" type="dashed" onClick={newOrderResetSearch}></Button>
                </Row>
              </Col>
            </Row>
            {searchedClients.length > 0 ? (
              <>
                <Table
                  style={{ marginTop: 20 }}
                  rowKey={record => record._id}
                  pagination={false}
                  size="small"
                  columns={columns_searched}
                  scroll={{ x: true }}
                  dataSource={searchedClients}
                />
              </>
            ) : (<></>)}
            <Divider />
          </>
        ) : (<></>)}
        <br />
        <Row type="flex" gutter={16}>
          <Col xs={24} sm={12}>
            <Typography>Данные клиента:</Typography>
            <Input placeholder="Имя клиента" style={{ marginTop: 10 }} value={newOrderContent.name} onChange={(e) => { setNewOrderContent({ ...newOrderContent, "name": e.target.value, "usedbonus": null }); setNewOrderClientData({}); }} /><br />

            <MaskedInput icon="phone" style={{ marginTop: 10 }} placeholder="Телефон клиента" mask="+7 (111) 111-11-11" size="20" value={newOrderContent.phone} onChange={(e) => { setNewOrderContent({ ...newOrderContent, "phone": e.target.value, "usedbonus": null }); setNewOrderClientData({}); }} />

            {/* <Input placeholder="Телефон клиента" style={{ marginTop: 10 }} value={newOrderContent.phone} onChange={(e) => { setNewOrderContent({ ...newOrderContent, "phone": e.target.value, "usedbonus": null }); setNewOrderClientData({}); }} /><br /> */}

            <Input placeholder="Email клиента" style={{ marginTop: 10 }} value={newOrderContent.email} onChange={(e) => { setNewOrderContent({ ...newOrderContent, "email": e.target.value, "usedbonus": null }); setNewOrderClientData({}); }} /><br />
            <Input placeholder="Комментарий" style={{ marginTop: 10 }} value={newOrderContent.comment} onChange={(e) => { setNewOrderContent({ ...newOrderContent, "comment": e.target.value, "usedbonus": null }); setNewOrderClientData({}); }} /><br />
          </Col>
          <Col xs={24} sm={12}>
            <Typography>Адрес доставки:</Typography>
            <Input placeholder="Улица" style={{ marginTop: 10 }} value={newOrderContent.street} onChange={(e) => setNewOrderContent({ ...newOrderContent, "street": e.target.value })} /><br />
            <Input placeholder="Номер дома" style={{ marginTop: 10 }} value={newOrderContent.building} onChange={(e) => setNewOrderContent({ ...newOrderContent, "building": e.target.value })} /><br />
            <Input placeholder="Строение / Корпус" style={{ marginTop: 10 }} value={newOrderContent.building_extra} onChange={(e) => setNewOrderContent({ ...newOrderContent, "building_extra": e.target.value })} /><br />
            <Input placeholder="Квартира / Офис" style={{ marginTop: 10 }} value={newOrderContent.appartments} onChange={(e) => setNewOrderContent({ ...newOrderContent, "appartments": e.target.value })} /><br />
            <Input placeholder="Этаж" style={{ marginTop: 10 }} value={newOrderContent.appartments_level} onChange={(e) => setNewOrderContent({ ...newOrderContent, "appartments_level": e.target.value })} /><br />
            <Input placeholder="Подъезд" style={{ marginTop: 10 }} value={newOrderContent.appartments_block} onChange={(e) => setNewOrderContent({ ...newOrderContent, "appartments_block": e.target.value })} /><br />
          </Col>
        </Row>
      </>
    )
  }

  function renderNewOrderContent() {

    const order_cartitems = [
      {
        title: 'Название', dataIndex: 'name', key: 'name',
        render: (itemName, row) => {
          return (
            <Text>{itemName}</Text>
          );
        }
      },
      {
        title: 'Опции', dataIndex: 'selectedOptions', key: 'selectedOptions',
        render: (selectedOptions, row) => {
          console.log(selectedOptions);
          return (
            <>
              {selectedOptions.map(selectedOption => (
                <>
                  <Text style={{ fontSize: 10 }}>{selectedOption.name}: {selectedOption.label}</Text><br />
                </>
              ))}
            </>
          );

        }
      },
      {
        title: 'Кол-во', dataIndex: 'quantity', key: 'quantity',
        render: (itemQuantity, row, index) => {
          return (
            <>
              <Button onClick={() => minusItemFromNewOrder(itemQuantity, row, index)} type="dashed" shape="circle" icon="minus" size="small" style={{ marginRight: 5 }} /><Text>{itemQuantity}</Text><Button onClick={() => plusItemFromNewOrder(itemQuantity, row, index)} type="dashed" shape="circle" icon="plus" size="small" style={{ marginLeft: 5 }} />
            </>
          );
        }
      },
      {
        title: 'Сумма', dataIndex: 'price', key: 'price',
        render: (itemPrice, row) => {
          console.log(itemPrice);
          console.log(row.itemQuantity);
          return (
            <Text>{parseInt(itemPrice, 10) * parseInt(row.quantity, 10)}</Text>
          );
        }
      },
      {
        title: '', dataIndex: 'id', key: 'id', width: 30,
        render: (itemId, row, index) => {
          return (
            <Button onClick={() => deleteItemFromNewOrder(itemId, row, index)} type="danger" shape="circle" icon="delete" size="small" />
          );
        }
      },
    ]

    if (newOrderContent.cartitems.length == 0) {
      return (
        <></>
      )
    } else {
      return (
        <>
          <Col span={24}>
            <Divider className="header-div new-order-header" style={{ marginTop: 10 }} orientation="center">Состав заказа</Divider>
          </Col>
          <Table
            rowKey={record => record._id}
            style={{ marginTop: 20 }}
            size="small"
            columns={order_cartitems}
            scroll={{ x: true }}
            dataSource={newOrderContent.cartitems}
            pagination={false}
          />
        </>
      )
    }
  }


  const [mobileFilters, setMobileFilters] = React.useState(false);


  function renderFilters() {

    return (
      <>
        <Row type="flex" justify="end">



          {user.role == 'operator' ? (<></>) : (
            <div style={{ marginBottom: 10, marginRight: 20, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#eeeeee', paddingRight: 20 }}>
              {/* <Popover onMouseEnter={() => onOpenSmallReport()} placement="bottomLeft" content={renderSmallReport()} trigger="hover"> */}
              <Button type="dashed" onClick={() => onOpenSmallReport()}>краткий отчет</Button>
              {/* </Popover> */}
            </div>
          )}

          <div style={{ marginBottom: 10, marginRight: 20, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#eeeeee', paddingRight: 20 }}>
            {/* <Popover onMouseEnter={() => onOpenSmallReport()} placement="bottomLeft" content={renderSmallReport()} trigger="hover"> */}
            {kiteringShow ? (<><Button type="dashed" onClick={() => resetKitering()}>ВСЕ ЗАКАЗЫ</Button></>) : (<><Button type="dashed" onClick={() => showKitering()}>КАЙТЕРИНГ</Button></>)}

            {/* </Popover> */}
          </div>




          <div style={{ marginBottom: 10, marginRight: 20, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#eeeeee', paddingRight: 20 }}>
            <Select defaultValue={null} size="default" style={{ width: 150 }} onChange={handleCourierFilterChange}>
              <Option value={null}>Курьер не выбран</Option>
              {courierList.map((courier, i) => {
                return (
                  <Option key={i.toString()} value={courier._id}>{courier.name}</Option>
                )
              })}
            </Select>
          </div>

          <div style={{ marginBottom: 10, marginRight: 20, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#eeeeee', paddingRight: 20 }}>
            <Select defaultValue={null} size="default" style={{ width: 150 }} onChange={handlePaidFilterChange}>
              <Option value={null}>Статус не выбран</Option>
              <Option value={true}>Оплачено</Option>
              <Option value={false}>Не оплачено</Option>
            </Select>
          </div>

          <div style={{ marginBottom: 10, marginRight: 20, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#eeeeee', paddingRight: 20 }}>
            <Select defaultValue={null} size="default" style={{ width: 150 }} onChange={handleDeliveryDateFilterChange}>
              <Option value={null}>Без фильтра</Option>
              <Option value={-2}>Позавчера</Option>
              <Option value={-1}>Вчера</Option>
              <Option value={0}>Сегодня</Option>
              <Option value={1}>Завтра</Option>
              <Option value={2}>Послезавтра</Option>
              <Option value={999}>Указать день</Option>
            </Select>
          </div>

          <div style={{ marginBottom: 10, marginRight: 20, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#eeeeee', paddingRight: 20 }}>
            <Input icon="search" style={{ width: 170 }} placeholder="Номер заказа" value={numberSearch} onChange={(e) => setNumberSearch(e.target.value)} />
            <Button icon="search" type="dashed" onClick={filterNumber}></Button>
            <Button icon="close-circle" type="dashed" onClick={resetNumber}></Button>
          </div>
          <div style={{ marginBottom: 10, marginRight: 20, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#eeeeee', paddingRight: 20 }}>
            <MaskedInput icon="search" style={{ width: 170 }} placeholder="Поиск по телефону" mask="+7 (111) 111-11-11" size="20" value={phoneSearch} onChange={(e) => setPhoneSearch(e.target.value)} />
            {/* <Button icon="search" type="dashed" onClick={showKitering}></Button> */}
            <Button icon="search" type="dashed" onClick={filterPhone}></Button> 
            <Button icon="close-circle" type="dashed" onClick={resetPhone}></Button>
          </div>
          <div style={{ marginBottom: 10, marginRight: 20, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#eeeeee', paddingRight: 20 }}>
            <Input style={{ width: 170 }} placeholder="Поиск по E-mail" value={emailSearch} onChange={(e) => setEmailSearch(e.target.value)} />
            <Button icon="search" type="dashed" onClick={filterEmail}></Button>
            <Button icon="close-circle" type="dashed" onClick={resetEmail}></Button>
          </div>

          <div style={{ marginBottom: 10 }} className="header-center-row">
            <Select defaultValue={10} size="default" style={{ width: 70 }} onChange={handlePaginationChange}>
              <Option value={10}>10</Option>
              <Option value={25}>25</Option>
            </Select>
          </div>

        </Row>
      </>
    )

  }



  function renderListItem(item) {
    return (
      <div className="v-order-main">
        <Popover content={renderHistory(item)} trigger="hover">
          <Text code className="v-order-main-num">#{item.id}</Text>
        </Popover>
      </div>
    )
  }



  function actionCheckPayment(order) {

    dispatch(checkPayment(order.id));

  }

  const newordercreation = useSelector(state => state.orders.newordercreation);

  function renderSort() {

    const columns_base = [
      {
        title: 'Заказ', dataIndex: 'id', key: 'id',
        render: (orderid, row) => {
          return (
            <Popover content={renderHistory(row)} trigger="hover">
              <Text code>#{orderid}</Text>
            </Popover>
          );
        }
      },
      {
        title: 'Доставить', dataIndex: 'deliveryDate', key: 'deliveryDate',
        editable: true,
        render: (date, row) => {
          if (date != null) {
            return (
              <>
                <Tag color="magenta"><Moment component={Text} style={{ fontSize: '1.1em', fontWeight: 'bold' }} date={row.deliveryTime} format="HH:mm" locale="ru" /></Tag><br />
                <Moment component={Text} style={{ fontSize: '0.8em' }} date={date} format="L" locale="ru" />
              </>
            )
          } else {
            return (
              <>
                <Tag>указать время</Tag>
              </>
            )
          }
        }
      },

      {
        title: 'Бонусы', dataIndex: 'customerId', key: 'customerId',
        render: (client, row) => {
          if (client != '') {
            if (row.bonustoadd && row.bonustoadd.bonusrate != undefined) {
              return (
                <Popover content={renderAddBonusPopup(row)} trigger="hover">
                  <Button icon="dollar" style={{ marginRight: 5, backgroundColor: '#9FBE34', border: 0 }} type="primary" ></Button>
                </Popover>
              );
            } else {
              return (
                <></>
              )
            }

          } else {
            return (
              <></>
            );
          }
        }
      },
      {
        title: 'Статус', dataIndex: 'status', key: 'status',
        editable: true,
        render: tags => {
          if (initcrmstatus.name == tags) {
            return (
              <Tag color={initcrmstatus.color}>{tags}</Tag>
            );
          } else {
            var picked = orderStatuses.find(o => o.apiname === tags);
            if (picked) {
              return (
                <Tag color={picked.apicolor}>{tags}</Tag>
              );
            } else {
              return (
                <Tag>устаревший статус</Tag>
              );
            }
          }
        }
      },
      {
        title: 'Курьер', dataIndex: 'courier_id', key: 'courier_id',
        editable: true,
        render: (courier, row) => {
          if (courier == '') {
            return (
              <>
                <Button type="dashed" size="small" shape="circle" icon="user-add" />
              </>
            )
          } else {
            return (
              <>
                {getCourierId(courier, row)}
                {/* <Text style={{ fontSize: '0.9em', lineHeight: '1 !important' }}>назначен</Text><br /> */}
              </>
            )
          }

        }
      },
      {
        title: 'Состав'//() => (<Button type="dashed" size="small" shape="circle" icon="shopping-cart" />) 
        , dataIndex: 'cartitems', key: 'cartitems',
        render: (item, row) => {
          return (
            <>
              <Popover content={renderContent(item)} trigger="hover">
                <Button type="dashed" size="small" shape="circle" icon="shopping-cart" />
              </Popover>
            </>
          )
        }
      },
      {
        title: 'Клиент', dataIndex: 'clientname', key: 'clientname',
        render: (client, row) => {
          return (
            <>
              {row.customerId != "" ? (
                <>
                  <Text style={{ fontSize: '0.9em', lineHeight: '1 !important', color: 'green' }}>{client}</Text><br />
                  <Text style={{ fontSize: '0.9em', lineHeight: '1 !important', color: 'green' }}>{row.clientphone}</Text><br />
                  {row.clientstreet != '' ? (<><Text style={{ fontSize: '0.7em', color: 'green' }}>{row.clientstreet},</Text><Text style={{ fontSize: '0.7em', color: 'green' }}>{row.clientbuilding}</Text></>) : (<Text style={{ fontSize: '0.7em', color: 'green' }}>САМОВЫВОЗ</Text>)}
                </>
              ) : (
                <>
                  <Text style={{ fontSize: '0.9em', lineHeight: '1 !important' }}>{client}</Text><br />
                  <Text style={{ fontSize: '0.9em', lineHeight: '1 !important' }}>{row.clientphone}</Text><br />
                  {row.clientstreet != '' ? (<><Text style={{ fontSize: '0.7em' }}>{row.clientstreet},</Text><Text style={{ fontSize: '0.7em' }}>{row.clientbuilding}</Text></>) : (<Text style={{ fontSize: '0.7em' }}>САМОВЫВОЗ</Text>)}
                </>
              )}
            </>
          )
        }
      },
      {
        title: 'Цена', dataIndex: 'summa', key: 'summa',
        render: (sum, row) => {
          return (
            <>
              <Text code style={{ alignSelf: 'center' }}>{parseInt(sum, 10)}</Text>
            </>
          )
        }
      },
      {
        title: 'Доставка', dataIndex: 'deliveryprice', key: 'deliveryprice',
        editable: true,
        render: (deliveryprice, row) => {
          return (
            <>
              <Text code style={{ alignSelf: 'center' }}>{parseInt(deliveryprice, 10)}</Text>
            </>
          )
        }
      },
      {
        title: 'Итого', dataIndex: 'summa', key: 'itogo',
        render: (summa, row) => {
          return (
            <>
              <Text code style={{ alignSelf: 'center' }}>{parseInt(summa, 10) + parseInt(row.deliveryprice, 10)}</Text>
            </>
          )
        }
      },
      {

      },
      {
        title: 'Оплата', dataIndex: 'paymenttype', key: 'paymenttype',
        editable: true,
        render: (paymenttype, row) => {
          //Оплачено
          return (
            <>
              <Tag>{getPaymentType(paymenttype)}</Tag>
            </>
          )
        }
      },
      {
        title: '',
        dataIndex: '',
        key: 'x',
        render: (record) => {
          if (record.onlinePayment) {
            return (
              <>
                <Button style={{ marginRight: 5 }} size="small" type="dashed" shape="circle" icon="sync" onClick={() => actionCheckPayment(record)} />
              </>
            );
          }
        }

      },
      {
        title: 'Оплачено', dataIndex: 'paid', key: 'paid',
        editable: true,
        render: (isPaid, row) => {
          if (isPaid == true) {
            return (
              <>
                <Tag color="green">оплачено</Tag>
              </>
            );
          } else {
            if (row.paymentProcessing) {
              return (
                <>
                  <Tag color="blue">обработка</Tag>
                </>
              );
            } else {
              if (row.onlinePayment == true && row.canceled == true) {
                return (
                  <>
                    <Tag color="volcano">ошибка оплаты</Tag>
                  </>
                );
              } else {
                return (
                  <>
                    <Tag color="volcano">не оплачено</Tag>
                  </>
                );
              }
            }
          }
        }
      },
      {
        title: '',
        dataIndex: '',
        key: 'x',
        render: (record) => {
          return (
            <>
              {record.kitering ? (<><Button style={{ marginRight: 5 }} size="small" type="dashed" shape="circle" icon="car"  /></>) : (<></>)}<Button style={{ marginRight: 5 }} size="small" type="dashed" shape="circle" icon="printer" onClick={() => openChequePrint(record)} />
            </>
          )
        }


      }
    ];

    const components = {
      body: {
        row: EditableFormRow,
        cell: ConnectedEditableCell,
      },
    };
    const columns = columns_base.map(col => {
      if (!col.editable) {
        return col;
      } else {
        return {
          ...col,
          onCell: record => ({
            record,
            editable: col.editable,
            dataIndex: col.dataIndex,
            title: col.title,
            handleSavePaid: handleSavePaid,
            handleSaveDeliveryPrice: handleSaveDeliveryPrice,
            handleSaveStatus: handleSaveStatus,
            handleSavePaymentType: handleSavePaymentType,
            handleSaveDeliveryDate: handleSaveDeliveryDate,
            handleSaveCourier: handleSaveCourier,
          }),
        };
      }
    });

    if (order.length > 0) {

      return (
        <>


          {/* <List
            dataSource={order}
            renderItem={item => renderListItem(item)}
            pagination={pagination}
            onChange={handleTableChange}
          >
          </List> */}

          <Table
            rowKey={record => record._id}
            pagination={pagination}
            style={{ marginTop: 20 }}
            size="middle"
            columns={columns}
            scroll={{ x: true }}
            components={components}
            rowClassName={(record) => record.status === initcrmstatus.name ? 'new-row editable-row' : 'editable-row'}
            //rowClassName={() => 'editable-row'}
            expandedRowRender={record => renderOrderInfo(record)}
            dataSource={order}
            onChange={handleTableChange}
            loading={pageLoading}
          />
          {/* <Button icon="plus" style={{ marginRight: 5, backgroundColor: '#9FBE34', border: 0 }} type="primary" onClick={() => openCreateOrderModal()}>СОЗДАТЬ ЗАКАЗ</Button> */}
          <Button onClick={() => { setShowNewOrderModal(true); dispatch(loadItemsWithCategories()); }} icon="plus" style={{ marginRight: 5, backgroundColor: '#9FBE34', border: 0 }} type="primary" >СОЗДАТЬ ЗАКАЗ</Button>
          {/* onClick={() => { setShowNewOrderModal(true); dispatch(loadItemsWithCategories()); }}
          {/* <div style={{ display: 'none' }}><OrdersToPrint orders={ddFilter != null ? order : null} ref={orderPrintRef} /></div> */}
          {/* <button onClick={handlePrint}>Print this out!</button> */}
          <Button disabled={ddFilter == null} icon="printer" style={{ marginRight: 5, border: 0 }} type="primary" onClick={() => openPrintCurrentFilterDay()}>РАСПЕЧАТАТЬ ПОЗИЦИИ</Button>
        </>
      );
    } else {
      return (
        <div>
          <Card title="Продаж не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
            <div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
          </Card>
        </div>
      );
    }
  }

  if (loading) {
    return (
      <div className="content-div">
        <Divider className="header-div" orientation="left"><Spin /></Divider>
      </div>
    );
  } else {
    return (
      <>
        <QueueAnim className="anim-content"
          type={['right', 'left']}
          ease={['easeOutQuart', 'easeInOutQuart']}>
          {!loading ? [
            <div key="a" className="content-div">
              <Divider className="header-div" orientation="left">Продажи</Divider>

              {window.innerWidth > 600 ?
                (<>
                  {renderFilters()}
                </>) : (<>
                  <Button type="dashed" onClick={() => setMobileFilters(!mobileFilters)}>Показать фильтры</Button>
                  {mobileFilters ? (<>{renderFilters()}</>) : (<></>)}
                </>)}




              {renderSort()}
            </div>
          ] : null}
        </QueueAnim>

        <>


          <Modal
            width={600}
            centered
            title={"История бонусов"}
            style={{ textAlign: 'center' }}
            visible={showCustomerBonus}
            onCancel={() => handleCloseShowHistoryBonus()}
            footer={[
            ]}
          >
            <>
              {renderCustomerBonus()}
            </>
          </Modal>


          <Modal
            width={600}
            centered
            title={"Создаем заказ"}
            style={{ textAlign: 'center' }}
            visible={newordercreation}
            footer={[
            ]}
          >
            <>
              <Spin />
            </>
          </Modal>

          <Modal
            width={600}
            centered
            title={"Создание заказа"}
            style={{ textAlign: 'left' }}
            visible={showNewOrderModal}
            onCancel={() => setShowNewOrderModal(false)}
            footer={[
              <Button
                key="back"
                onClick={() => setShowNewOrderModal(false)}
              >Закрыть</Button>,
            ]}
          >
            <>
              {renderCreateNewOrder()}
            </>
          </Modal>

          <Modal
            width={350}
            centered
            title={"Краткий отчет"}
            style={{ textAlign: 'left' }}
            visible={smallReportOpened}
            onCancel={onCloseSmallReport}
            footer={[
              <Icon style={{ textAlign: 'center', marginRight: 20 }} type="like" theme="twoTone" />,
              <Button
                key="back"
                onClick={onCloseSmallReport}
              >Закрыть</Button>,
            ]}
          >
            <>
              {renderSmallReport()}
            </>
          </Modal>

          <Modal
            width={'100%'}
            centered
            title="Укажите дату"
            maskClosable={false}
            style={{ textAlign: 'center', maxWidth: 400 }}
            visible={showDDFilterTimeSelect}
            onCancel={() => setShowDDFilterTimeSelect(false)}
            footer={[
              <Row flex style={{ flexDirection: 'row' }}>
                <Button
                  key="back"
                  onClick={() => setShowDDFilterTimeSelect(false)}
                >
                  Закрыть
                </Button>

              </Row>


            ]}
          >
            <div>
              <DatePicker locale={locale} onChange={(e) => changeDDFilterTimeSelect(e)} placeholder="Укажите дату" style={{ width: '100%' }} />
            </div>
          </Modal>

          <Modal
            width={'100%'}
            centered
            title="Создание заказа"
            maskClosable={false}
            style={{ textAlign: 'left', maxWidth: 600 }}
            visible={showAddOrder}
            onCancel={closeCreateOrderModal}
            footer={[
              <Row flex style={{ flexDirection: 'row' }}>
                <Button
                  key="back"
                  onClick={closeCreateOrderModal}
                >
                  Закрыть
                </Button>

              </Row>


            ]}
          >
            <div>
              {renderCreateOrder()}
            </div>
          </Modal>



          <Modal
            width={'100%'}
            centered
            title={`Редактирование адреса - Заказ №${orderAddressContent.id}`}
            style={{ maxWidth: 500 }}
            visible={isShowAddressModal}
            onCancel={handleCloseAddressModal}
            footer={[
              <Row flex style={{ flexDirection: 'row' }}>
                <Button
                  key="back"
                  onClick={handleCloseAddressModal}
                  disabled={isAddressEditing}
                >
                  Закрыть
                </Button>

              </Row>


            ]}
          >

            {renderEditAddress()}

          </Modal>


          <Modal
            width={'100%'}
            centered
            title={`Заказ №${orderContent.id}`}
            style={{ textAlign: 'center', maxWidth: 900 }}
            visible={showContent}
            onCancel={handleCloseContentModal}
            footer={[
              <Row flex style={{ flexDirection: 'row' }}>
                <Button
                  key="back"
                  onClick={handleCloseContentModal}
                >
                  Закрыть
                </Button>

              </Row>


            ]}
          >
            <div>
              <Table pagination={false} rowKey={record => record._id} dataSource={orderContent.cartitems} columns={orderListColumns} footer={() => renderOrderInfoFooter()} />
              {addingToOrder ? (<><Spin /><p>Добавляем товар</p></>) : (<></>)}
            </div>
          </Modal>

          <Modal
            width={'100%'}
            centered
            title="Редактирование заметки"
            style={{ textAlign: 'left', maxWidth: 500 }}
            visible={isShowEditComment}
            onCancel={handleCloseEditComment}
            footer={[
              <Button key="yes" type="primary" onClick={handleEditComment}>Сохранить в заметках</Button>,
              <Button
                key="back"
                onClick={handleCloseEditComment}
              >Закрыть</Button>
            ]}
          >
            <>
              {renderEditComment()}
            </>
          </Modal>

          <Modal
            width={'100%'}
            centered
            title="Расчет сдачи"
            style={{ textAlign: 'left', maxWidth: 240 }}
            visible={isShowCalcChange}
            onCancel={handleCloseCalcChange}
            footer={[
              <Button
                key="back"
                onClick={handleCloseCalcChange}
              >Закрыть</Button>,
            ]}
          >
            <>
              {renderCalcChange()}
            </>
          </Modal>

          <Modal
            width={'100%'}
            centered
            title="Добавление позиции"
            style={{ textAlign: 'center', maxWidth: 500 }}
            visible={showAddItem}
            onCancel={handleCloseAddToOrder}
            footer={[
              <Button
                key="back"
                onClick={handleCloseAddToOrder}
                disabled={addingToOrder}
              >Закрыть</Button>,
              <Button
                key="yes"
                type="primary"
                disabled={addingToOrder || !itemToAddSelected}
                onClick={handleAddToOrder}
              >Добавить</Button>
            ]}
          >
            <>
              {renderAddToOrder()}
            </>
          </Modal>


          <Modal
            width={'90mm'}
            centered
            title="Сформированный чек"
            visible={showCheque}
            onCancel={closeChequePrint}
            footer={[
              <Button
                key="back"
                onClick={closeChequePrint}
              >
                Закрыть
              </Button>,
              <ReactToPrint
                trigger={() => <Button type="primary">Печатать</Button>}
                content={() => componentRef.current}
              />
            ]}
          >
            <div>
              <ComponentToPrint
                user={user.name}
                chequeToPrint={chequeToPrint}
                cheque_title={cheque_title}
                cheque_field_1={cheque_field_1}
                cheque_field_2={cheque_field_2}
                cheque_field_3={cheque_field_3}
                cheque_field_4={cheque_field_4}
                paymentName={getPaymentType(chequeToPrint.paymenttype)}
                cheque_courier_name={getCourierName(chequeToPrint.courier_id)}
                cheque_customer_data={cheque_customer_data}
                cheque_user_name={cheque_user_name}
                ref={componentRef} />
            </div>
          </Modal>

          <Modal
            width={'90mm'}
            centered
            style={{ textAlign: 'center', maxWidth: 500 }}
            title="Назначение курьера"
            visible={isShowAddCourier}
            onCancel={handleCloseAddCourier}
            footer={[
              <Button
                key="back"
                onClick={handleCloseAddCourier}
              >
                Закрыть
              </Button>,
              <Button
                key="yes"
                type="primary"
                disabled={!isCourierSelected}
                onClick={handleAddCourierToOrder}
              >
                Сохранить
              </Button>
            ]}
          >
            <div>
              {renderAddCourierToOrder()}
            </div>
          </Modal>

          <Modal
            centered
            className="list-print"
            style={{ textAlign: 'left', maxWidth: 1000 }}
            title="Печать списка"
            visible={isShowPrintCurrentFilterDay}
            onCancel={closePrintCurrentFilterDay}
            footer={[
              <Button
                key="back"
                onClick={closePrintCurrentFilterDay}
              >
                Закрыть
              </Button>,
              <ReactToPrint
                trigger={() => <Button type="primary">Печатать</Button>}
                content={() => orderPrintRef.current}
              />
            ]}
          >
            <div>
              <OrdersToPrint
                orderstoPrint={ddFilter != null ? order : null}
                ref={orderPrintRef}
              />
            </div>
          </Modal>

        </>

      </>
    );
  }


};

const useFetching = (getOrderList, dispatch, rows) => {
  useEffect(() => {
    dispatch(getOrderList(1, rows, false));
    //console.log('useFetching');
  }, []);
};
export default CPSales;



  // function renderCheque() {
  //   return (
  //     <>
  //       <div className="cheque-80mm-no-border">
  //         <div className="cheque-title-80mm">
  //           <span>{cheque_title}</span>
  //         </div>
  //         <div className="cheque-field_1-80mm">
  //           <span>{cheque_field_1}</span>
  //         </div>
  //         <div className="cheque-field_2-80mm">
  //           <span>{cheque_field_2}</span>
  //         </div>
  //         <div className="cheque-field_3-80mm">
  //           <span>{cheque_field_3}</span>
  //         </div>

  //         <div className="cheque-date-80mm">
  //           <span>20.01.2020</span>
  //         </div>

  //         <div className="cheque-number-80mm">
  //           <span>##{chequeToPrint.id}</span>
  //         </div>

  //         {cheque_user_name ? (<div className="cheque-user-80mm">
  //           <span>Сотрудник: Иванов Иван</span>
  //         </div>) : (<></>)}

  //         <div className="cheque-table-80mm">
  //           <table border="0">
  //             <tr className="cheque-table-header-80mm">
  //               <th>Наименование</th>
  //               <th style={{ textAlign: 'right' }}>Кол.</th>
  //               <th style={{ textAlign: 'right' }}>Сумма</th>
  //             </tr>

  //             {chequeToPrint.cartitems.map(cartitem => (
  //               <tr className="cheque-table-product-80mm">
  //                 <td>{cartitem.name}</td>
  //                 <td className="cheque-table-product-quantity-80mm">{cartitem.quantity}</td>
  //                 <td className="cheque-table-product-price-80mm">{cartitem.price}</td>
  //               </tr>
  //             ))}

  //             <tr className="cheque-table-sum-80mm">
  //               <td>ИТОГО</td>
  //               <td></td>
  //               <td className="cheque-table-product-price-80mm">{chequeToPrint.summa}</td>
  //             </tr>

  //             {chequeToPrint.usedbonus != 0 ? (
  //               <tr>
  //                 <td>Скидка</td>
  //                 <td></td>
  //                 <td className="cheque-table-product-price-80mm">{chequeToPrint.usedbonus}</td>
  //               </tr>
  //             ) : (<></>)}

  //             {chequeToPrint.deliveryprice != 0 ? (
  //               <tr>
  //                 <td>Доставка</td>
  //                 <td></td>
  //                 <td className="cheque-table-product-price-80mm">{chequeToPrint.deliveryprice}</td>
  //               </tr>
  //             ) : (<></>)}

  //             <tr className="cheque-table-total-80mm">
  //               <td>К ОПЛАТЕ</td>
  //               <td></td>
  //               <td className="cheque-table-product-price-80mm">
  //                 {parseInt(chequeToPrint.summa, 10) + parseInt(chequeToPrint.deliveryprice, 10) - parseInt(chequeToPrint.usedbonus, 10)}
  //               </td>
  //             </tr>
  //           </table>
  //         </div>
  //         <div className="cheque-field_4-80mm">
  //           <span>{cheque_field_4}</span>
  //         </div>
  //         {cheque_customer_data ? (
  //           <div className="cheque-customer-80mm">
  //             <table border="0">
  //               <tr className="cheque-customer-row-80mm">
  //                 <td>Клиент:</td>
  //                 <td>{chequeToPrint.clientname}</td>
  //               </tr>
  //               <tr className="cheque-customer-row-80mm">
  //                 <td>Улица:</td>
  //                 <td>{chequeToPrint.clientstreet}</td>
  //               </tr>
  //               <tr className="cheque-customer-row-80mm">
  //                 <td>Дом:</td>
  //                 <td>{chequeToPrint.clientbuilding}|{chequeToPrint.clientbuildingextra}</td>
  //               </tr>
  //               <tr className="cheque-customer-row-80mm">
  //                 <td>Этаж:</td>
  //                 <td>{chequeToPrint.clientappartmentslevel}</td>
  //               </tr>
  //               <tr className="cheque-customer-row-80mm">
  //                 <td>Кв. (офис):</td>
  //                 <td>{chequeToPrint.clientappartments}</td>
  //               </tr>
  //               <tr className="cheque-customer-row-80mm">
  //                 <td>Телефон:</td>
  //                 <td>{chequeToPrint.clientphone}</td>
  //               </tr>
  //               <tr className="cheque-customer-row-80mm">
  //                 <td>Примечание:</td>
  //                 <td>{chequeToPrint.clientcomment}</td>
  //               </tr>
  //             </table>
  //           </div>
  //         ) : (<></>)}
  //       </div>
  //     </>
  //   );

  // }