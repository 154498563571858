import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Row, Col, Divider, Spin, Card, Icon, Layout, Table, Button, Modal, Typography, Popconfirm, Popover, Select } from 'antd';
import QueueAnim from 'rc-queue-anim';

import Moment from 'react-moment';
import 'moment/locale/ru';
import {
    getNomenclatureList,
    addNomenclature
} from "../../actions/nomenclatureActions";

const { Option } = Select;

const CPNomenclature = props => {
    useFetching(getNomenclatureList, useDispatch());
    const dispatch = useDispatch();
    const nomenclatures = useSelector(state => state.nomenclature.nomenclatures);
    const loading = useSelector(state => state.nomenclature.isLoading);
    const adding = useSelector(state => state.nomenclature.isAdding);

    const [showAdd, setShowAdd] = React.useState(false)
    const [newNomenclature, setNewNomenclature] = React.useState({ name: '', unit: '' })

    useEffect(() => {
        if (adding == false) {
            setShowAdd(false);
            setNewNomenclature({ name: '', unit: '' })
        }
    }, [adding]);

    const handleAddNomenclature = () => {
        if (newNomenclature.name.length > 0 && newNomenclature.unit != '') {
            dispatch(addNomenclature(newNomenclature))
        }
    };

    function renderSort() {

        const columns = [
            { title: 'Наименование', dataIndex: 'name', key: 'name' },
            {
                title: 'Единица измерения', dataIndex: 'unit', key: 'unit',
                render: (unit, row) => {
                    switch (unit) {
                        case 'KG':
                            return (
                                <>кг.</>
                            )
                        case 'LITRES':
                            return (
                                <>л.</>
                            )
                        default:
                            return(
                                <>упс.</>
                            )
                    }
                }
            },
        ];

        if (nomenclatures.length > 0) {
            return (
                <Table
                    rowKey={record => record._id}
                    style={{ marginTop: 20 }}
                    columns={columns}
                    dataSource={nomenclatures}
                    size="small"
                    pagination={false}
                />
            );
        } else {
            return (
                <div>
                    <Card title="Наименований не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
                        <div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
                    </Card>
                </div>
            );
        }
    }

    if (loading) {
        return (
            <div className="content-div">
                <Divider className="header-div" orientation="left"><Spin /></Divider>
            </div>
        );
    } else {
        return (
            <QueueAnim className="anim-content"
                type={['right', 'left']}
                ease={['easeOutQuart', 'easeInOutQuart']}>
                {!loading ? [
                    <div key="a" className="content-div">
                        <Divider className="header-div" orientation="left">Список наименований</Divider>
                        <Row type="flex" justify="end">
                        </Row>
                        {renderSort()}
                        <Divider />
                        <Card style={{ width: '100%', marginBottom: 20 }} size="small">
                            <Button type="primary" icon="plus" onClick={() => { setShowAdd(true); setNewNomenclature({ name: '', unit: '' }) }}>
                                Добавить
                            </Button>
                        </Card>
                    </div>
                ] : null}
                <Modal
                    centered
                    title={"Добавление номенклатуры"}
                    visible={showAdd}
                    closable={false}
                    footer={[
                        <Button
                            key="back"
                            disabled={adding}
                            onClick={() => { setShowAdd(false); setNewNomenclature({ name: '', unit: '' }) }}
                        >
                            Отмена
                        </Button>,
                        <Button
                            key="yes"
                            type="primary"
                            disabled={adding}
                            onClick={handleAddNomenclature}
                        >
                            Добавить
                        </Button>,
                    ]}
                >
                    <Row gutter={16}>
                        <Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
                            <Typography style={{ textAlign: 'left' }}>Название:</Typography>
                            <Input
                                value={newNomenclature.name}
                                name="name"
                                style={{ marginBottom: 10 }}
                                onChange={(e) => setNewNomenclature({ ...newNomenclature, name: e.target.value })}
                            />
                        </Col>
                        <Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
                            <Typography style={{ textAlign: 'left' }}>Единица измерения:</Typography>
                            <Select
                                value={newNomenclature.unit}
                                onChange={(e) => setNewNomenclature({ ...newNomenclature, unit: e })}
                                placeholder=""
                                style={{ width: '100%', marginBottom: 10 }}
                            >
                                <Option value={"KG"}>Килограмм</Option>
                                <Option value={"LITRES"}>Литр</Option>
                            </Select>
                        </Col>
                    </Row>
                </Modal>
            </QueueAnim>


        );
    }
};

const useFetching = (getNomenclatureList, dispatch) => {
    useEffect(() => {
        dispatch(getNomenclatureList());
    }, []);
};

export default CPNomenclature;
