import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    getUsersList,
    showAddUserModal,
    closeAddUserModal,
    addUser,
    deleteUser,
    showEditUserModal,
    closeEditUserModal,
    editUser
} from '../../actions/userActions'
import { bindActionCreators } from 'redux';
import { Select, Input, Button, Row, Col, Typography, Divider, Spin, Card, Icon, Modal, Layout, List, Table, Tag, Popconfirm } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { GET_ITEM_CHECKBOX_OPTION_LIST } from "../../actions/types";
const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;
const { TextArea } = Input;
const { Text } = Typography;

const CPUsers = props => {
    useFetching(getUsersList, useDispatch());
    //const order = useSelector(state => state.orders.orders);
    const loading = useSelector(state => state.users.isLoading);
    const users = useSelector(state => state.users.users);
    const adding = useSelector(state => state.users.adding);
    const showAdd = useSelector(state => state.users.showAdd);
    const showEdit = useSelector(state => state.users.showEdit);
    const showDel = useSelector(state => state.users.showDel);
    const userDel = useSelector(state => state.users.userDel);
    const editing = useSelector(state => state.users.editing);
    

    const dispatch = useDispatch();

    const [values, setValues] = React.useState({
        name: '',
        phone: '',
        role: '',
        email: '',
        password: '',
    });

    const [isPasswordChange, setIsPasswordChange] = React.useState(false);

    const handleOpenAddModal = useCallback(() => dispatch(showAddUserModal()), [
        dispatch
    ]);
    const handleCloseAddModal = useCallback(
        () => dispatch(closeAddUserModal()),
        [dispatch]
    );

    const handleCloseEditModal = () => {
        dispatch(closeEditUserModal());
        setIsPasswordChange(false);
    }

    const handleDelUser = (user) => {
        dispatch(deleteUser(user));
    };
    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };
    const handleAddUser = () => {
        dispatch(addUser(values));
        setValues({
            name: '',
            phone: '',
            role: '',
            email: '',
            password: '',
        });
    };

    const [valueItemsPerPage, setItemsPerPage] = React.useState({
        count: 5
    });
    const handlePaginationChange = (value) => {
        setItemsPerPage({ count: value });
    }
    const handleAddRoleChange = (value) => {
        setValues({ ...values, role: value });
    }

    const [editvalues, setEditValues] = React.useState({
        _id: '',
        name: '',
        phone: '',
        role: '',
        email: '',
        password: '',
    });


    const handleShowEditUser = (user) => {
        setEditValues({
            ...editvalues,
            _id: user._id,
            name: user.name,
            phone: user.phone,
            role: user.role,
            email: user.email,
            password: '',
        });
        dispatch(showEditUserModal(user));
    }
    const handleEditChange = name => event => {
        setEditValues({ ...editvalues, [name]: event.target.value });
    };
    const handleEditRoleChange = (value) => {
        setEditValues({ ...editvalues, role: value });
    }
    const handleEditUser = () => {
        if(isPasswordChange){
            dispatch(editUser(editvalues, true));
            setIsPasswordChange(false);
        } else {
            dispatch(editUser(editvalues, false));
        }
    }

    function renderUserInfo(user) {
        return (
            <>
                <div key={user.id} className="customer-info-box">
                    <div className="customer-info-section">
                    </div>
                </div>
                <div className="customer-buttons">
                    <Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleShowEditUser(user)}>РЕДАКТИРОВАТЬ</Button>
                    <Popconfirm title="Удалить пользователя?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelUser(user)}>
                        <Button style={{ marginRight: 5 }} type="danger">УДАЛИТЬ</Button>
                    </Popconfirm>
                </div>
            </>
        );
    }

    function renderSort() {

        const columns = [
            { title: 'Имя', dataIndex: 'name', key: 'name' },
            {
                title: 'Должность', dataIndex: 'role', key: 'role',
                render: role => {
                    if (role == 'admin') {
                        return (
                            <Tag>Администратор</Tag>
                        )
                    }
                    if (role == 'owner') {
                        return (
                            <Tag>Владелец</Tag>
                        )
                    }
                    if (role == 'manager') {
                        return (
                            <Tag>Менеджер</Tag>
                        )
                    }
                    if (role == 'cashier') {
                        return (
                            <Tag>Кассир</Tag>
                        )
                    }
                    if (role == 'courier') {
                        return (
                            <Tag>Курьер</Tag>
                        )
                    }
                    if (role == 'operator') {
                        return (
                            <Tag>Оператор</Tag>
                        )
                    }
                }
            },
            { title: 'Телефон', dataIndex: 'phone', key: 'phone' },
            { title: 'E-mail', dataIndex: 'email', key: 'email' },
        ];


        if (users.length > 0) {
            return (
                <Table
                    rowKey={record => record._id}
                    pagination={{
                        pageSize: valueItemsPerPage.count,
                    }}
                    style={{ marginTop: 20 }}
                    columns={columns}
                    expandedRowRender={record => renderUserInfo(record)}
                    dataSource={users}
                />
            );
        } else {
            return (
                <div className="content-div">
                    <Card title="Пользователей не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
                        <div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
                    </Card>
                </div>
            );
        }
    }

    if (loading) {
        return (
                <div className="content-div">
                    <Divider className="header-div" orientation="left"><Spin /></Divider>
                </div>
        );
    } else {
        return (
                <QueueAnim className="anim-content"
                    type={['right', 'left']}
                    ease={['easeOutQuart', 'easeInOutQuart']}>
                    {!loading ? [
                        <div key="a" className="content-div">
                            <div>
                                <Divider className="header-div" orientation="left">Пользователи</Divider>
                            </div>
                            <Row type="flex" justify="end">
                                <div className="header-center-row-valign">
                                    <span>Объектов на странице:</span>
                                </div>
                                <div className="header-center-row">
                                    <Select defaultValue={5} size="default" style={{ width: 70 }} onChange={handlePaginationChange}>
                                        <Option value={5}>5</Option>
                                        <Option value={10}>10</Option>
                                        <Option value={25}>25</Option>
                                    </Select>
                                </div>
                            </Row>
                            <Divider />
                            {renderSort()}

                            <Divider />
                            <Card style={{ width: '100%', marginBottom: 20 }} size="small">
                                <Button type="primary" icon="plus" onClick={handleOpenAddModal}>
                                    Добавить
        </Button>
                            </Card>

                            <Modal
                                centered
                                title={"Добавление пользователя"}
                                visible={showAdd}
                                onCancel={handleCloseAddModal}
                                footer={[
                                    <Button key="back" onClick={handleCloseAddModal}>Отмена</Button>,
                                    <Button key="yes" type="primary" disabled={adding} onClick={handleAddUser}>Сохранить</Button>,
                                ]}
                            >
                                <Row gutter={16}>
                                    <Col className="gutter-row" style={{ textAlign: 'center' }} xs={24} sm={24}>
                                        <Typography style={{ textAlign: 'left' }}>Имя:</Typography>
                                        <Input
                                            value={values.name}
                                            name="name"
                                            style={{ marginBottom: 10 }}
                                            onChange={handleChange("name")}
                                        />
                                        <Typography style={{ textAlign: 'left' }}>Email:</Typography>
                                        <Input
                                            value={values.email}
                                            name="email"
                                            style={{ marginBottom: 10 }}
                                            onChange={handleChange("email")}
                                        />
                                        <Typography style={{ textAlign: 'left' }}>Телефон:</Typography>
                                        <Input
                                            value={values.phone}
                                            name="phone"
                                            style={{ marginBottom: 10 }}
                                            onChange={handleChange("phone")}
                                        />
                                        <Typography style={{ textAlign: 'left' }}>Пароль:</Typography>
                                        <Input
                                            value={values.password}
                                            name="password"
                                            style={{ marginBottom: 10 }}
                                            onChange={handleChange("password")}
                                        />
                                        <Typography style={{ textAlign: 'left' }}>Должность:</Typography>
                                        <Select defaultValue="courier" style={{ marginBottom: 10, width: '100%', textAlign: 'left' }} onChange={handleAddRoleChange}>
                                            <Option value="owner">Владелец</Option>
                                            <Option value="manager">Менеджер</Option>
                                            <Option value="operator">Оператор</Option>
                                            <Option value="cashier">Кассир</Option>
                                            <Option value="courier">Курьер</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Modal>

                            <Modal
                                centered
                                title={"Редактирование пользователя"}
                                visible={showEdit}
                                onCancel={handleCloseEditModal}
                                footer={[
                                    <Button key="back" onClick={handleCloseEditModal}>Отмена</Button>,
                                    <Button key="yes" type="primary" disabled={editing} onClick={handleEditUser}>Сохранить</Button>,
                                ]}
                            >
                                <Row gutter={16}>
                                    <Col className="gutter-row" style={{ textAlign: 'center' }} xs={24} sm={24}>
                                        <Typography style={{ textAlign: 'left' }}>Имя:</Typography>
                                        <Input
                                            value={editvalues.name}
                                            name="name"
                                            style={{ marginBottom: 10 }}
                                            onChange={handleEditChange("name")}
                                        />
                                        <Typography style={{ textAlign: 'left' }}>Email:</Typography>
                                        <Input
                                            value={editvalues.email}
                                            name="email"
                                            style={{ marginBottom: 10 }}
                                            onChange={handleEditChange("email")}
                                        />
                                        <Typography style={{ textAlign: 'left' }}>Телефон:</Typography>
                                        <Input
                                            value={editvalues.phone}
                                            name="phone"
                                            style={{ marginBottom: 10 }}
                                            onChange={handleEditChange("phone")}
                                        />
                                        <Typography style={{ textAlign: 'left' }}>Должность:</Typography>
                                        <Select defaultValue={editvalues.role} style={{ marginBottom: 15, width: '100%', textAlign: 'left' }} onChange={handleEditRoleChange}>
                                            <Option value="owner">Владелец</Option>
                                            <Option value="manager">Менеджер</Option>
                                            <Option value="operator">Оператор</Option>
                                            <Option value="cashier">Кассир</Option>
                                            <Option value="courier">Курьер</Option>
                                        </Select>

                                        <Button type="primary" style={{ marginBottom: 10, textAlign: 'left' }} onClick={() => setIsPasswordChange(!isPasswordChange)}>Сменить пароль</Button>

                                        {isPasswordChange ? (
                                            <>
                                            <Typography style={{ textAlign: 'left' }}>Укажите новый пароль:</Typography>
                                            <Input
                                                value={editvalues.password}
                                                name="password"
                                                style={{ marginBottom: 10 }}
                                                onChange={handleEditChange("password")}
                                            />
                                            </>
                                        ) : (<></>)}
                                    </Col>
                                </Row>
                            </Modal>

                            <Divider />
                        </div>
                    ] : null}
                </QueueAnim>
        );
    }

};

const useFetching = (getUsersList, dispatch) => {
    useEffect(() => {
        dispatch(getUsersList());
    }, []);
};

export default CPUsers;
