import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import { Form, Icon, Input, Button, Checkbox } from 'antd';

class Login extends Component {

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.loginUser(values, this.props.history);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="login-background">
        <Form onSubmit={this.handleSubmit} className="login-form">
          <div className="login-logo">
            <img src={'https://hungrygator.ru/pics/logo.png'} />
          </div>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [{ required: true, message: 'Введите свой логин!' }],
            })(
              <Input
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Логин"
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: 'Введите свой пароль!' }],
            })(
              <Input
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                type="password"
                placeholder="Пароль"
              />,
            )}
          </Form.Item>
          <Button
            type="primary" htmlType="submit" className="login-form-button"
          >
            Войти
          </Button>
          <div style={{marginTop:20, textAlign:'center'}}>Сборка:<br/>0.5.9.14</div>
        </Form>

      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(Form.create({ name: 'normal_login' })(Login));

