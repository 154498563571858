import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  GET_INTEGRATIONS,
  LOADING_INTEGRATION_SETTINGS,
  GET_INTEGRATION_SETTINGS_ERRORS,
  EDIT_ARCHI_INTEGRATION,
  SAVE_ARCHI_INTEGRATION,
  EDIT_DELIVERYCLUB_INTEGRATION,
  SAVE_DELIVERYCLUB_INTEGRATION,
} from "./types";

// получаем настройки
export const getIntegrationSettings = () => dispatch => {
  dispatch(setIntegrationSettingsLoading());
  axios
    .get(serverIp + "api/integrations/getintegrations")
    .then(res => {
      dispatch({
        type: GET_INTEGRATIONS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_INTEGRATION_SETTINGS_ERRORS,
        payload: err.response
      })
    );
};

export const editArchiSettings = (data) => dispatch => {
  dispatch({
    type: EDIT_ARCHI_INTEGRATION,
    payload: data
  });
};
export const editDeliveryclubSettings = (data) => dispatch => {
  dispatch({
    type: EDIT_DELIVERYCLUB_INTEGRATION,
    payload: data
  });
};



export const saveArchiSettings = (setdata) => dispatch => {
  axios
    .put(serverIp + "api/integrations/savearchi", setdata)
    .then(res => {
      dispatch({
        type: SAVE_ARCHI_INTEGRATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_INTEGRATION_SETTINGS_ERRORS,
        payload: err.response
      })
    );
};

export const saveDeliveryclubSettings = (setdata) => dispatch => {
  axios
    .put(serverIp + "api/integrations/savedeliveryclub", setdata)
    .then(res => {
      dispatch({
        type: SAVE_DELIVERYCLUB_INTEGRATION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_INTEGRATION_SETTINGS_ERRORS,
        payload: err.response
      })
    );
};
// Загружаются настройки
export const setIntegrationSettingsLoading = () => {
  return {
    type: LOADING_INTEGRATION_SETTINGS
  };
};
