import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Input, Button, Row, Col, Typography, Divider, Spin, Card, Icon, Layout, Table, Modal, Checkbox, Popconfirm } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
	getDeliveryServices
} from "../../actions/deliveryServicesActions";

const CPDeliveryServices = props => {
	useFetching(getDeliveryServices, useDispatch());
	const dispatch = useDispatch();

	const deliveryservices = useSelector(state => state.deliveryservices.deliveryservices);
	const loading = useSelector(state => state.deliveryservices.isLoading);

	function renderSort() {
		if (deliveryservices.length > 0) {
			return (
                <div>
                    <span>сервис</span>
                </div>
			);
		} else {
			return (
				<div>
					<Card title="Сервисы временно отсутствуют" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	}

	if (loading) {
		return (
				<div className="content-div">
					<Divider className="header-div" orientation="left"><Spin /></Divider>
				</div>
		);
	} else {
		return (
				<QueueAnim className="anim-content"
					type={['right', 'left']}
					ease={['easeOutQuart', 'easeInOutQuart']}>
					{!loading ? [
						<div key="a" className="content-div">
							<Divider className="header-div" orientation="left">Сервисы по доставке</Divider>
							<Row type="flex" justify="end">
							</Row>
							{renderSort()}
							<Divider />
							<Card style={{ width: '100%', marginBottom: 20 }} size="small">

							</Card>
						</div>
					] : null}
				</QueueAnim>
		);
	}
};

const useFetching = (getDeliveryServices, dispatch) => {
	useEffect(() => {
		dispatch(getDeliveryServices());
	}, []);
};

export default CPDeliveryServices;
