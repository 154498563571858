import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Row, Col, Divider, Spin, Card, Icon, Layout, Table, Button, Modal, Typography, Popconfirm, Checkbox } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
	getEmailSettings,
	editSettings,
	saveSettings
} from "../../actions/emailSettingsActions";

const CPEmailSettings = props => {
	useFetching(getEmailSettings, useDispatch());
	const dispatch = useDispatch();

	const settings = useSelector(state => state.emailsettings.settings);
	const loading = useSelector(state => state.emailsettings.isLoading);
	const needSave = useSelector(state => state.emailsettings.needSave);

	// const adding = useSelector(state => state.delivery.adding);
	// const showAdd = useSelector(state => state.delivery.showAdd);
	// const showEdit = useSelector(state => state.delivery.showEdit);
	// const editing = useSelector(state => state.delivery.editing);

	// const handleOpenAddModal = useCallback(() => dispatch(showAddDeliveryModal()), [dispatch]);
	// const handleCloseAddModal = useCallback(() => dispatch(closeAddDeliveryModal()), [dispatch]);

	// const handleDelDelivery = (delivery) => {
	// 	dispatch(deleteDelivery(delivery));
	// };

	// const handleOpenEditDelivery = (delivery) => {
	// 	setEditValues({
	// 		...editvalues,
	// 		_id: delivery._id,
	// 		name: delivery.name,
	// 		freeDeliveryPrice: delivery.freeDeliveryPrice,
	// 		minAvailableSumm: delivery.minAvailableSumm,
	// 		minDeliveryPrice: delivery.minDeliveryPrice,
	// 		sortorder: delivery.sortorder,
	// 		info: delivery.info,
	// 		pickup: delivery.pickup,
	// 	});
	// 	dispatch(showEditDeliveryModal(delivery));
	// };

	// const handleCloseEditDelivery = useCallback(() => dispatch(closeEditDeliveryModal()), [dispatch]);

	// const handleEditChange = name => event => {
	// 	if(name == "pickup"){
	// 		setEditValues({ ...editvalues, pickup: event.target.checked });
	// 	} else {
	// 		setEditValues({ ...editvalues, [name]: event.target.value });
	// 	}
	// };

	// const handleEditDelivery = () => {
	// 	dispatch(editDelivery(editvalues));
	// };

	// const handleAddDelivery = () => {
	// 	dispatch(addDelivery(values));
	// 	setValues({
	// 		name: '',
	// 		freeDeliveryPrice: '',
	// 		minAvailableSumm: '',
	// 		minDeliveryPrice: '',
	// 		sortorder: '',
	// 		info: '',
	// 		pickup: false,
	// 	});
	// }

	// const handleChange = name => event => {
	// 	if(name == "pickup"){
	// 		setValues({ ...values, pickup: event.target.checked });
	// 	} else {
	// 		setValues({ ...values, [name]: event.target.value });
	// 	}

	// };

	// function renderDeliveryInfo(delivery) {
	// 	return (
	// 		<>
	// 			<div key={delivery.id}>
	// 				<div >
	// 					<span><b>Сумма корзины с которой доступен способ доставки:</b></span><br />
	// 					<span>{delivery.minAvailableSumm}</span><br />

	// 					<br />
	// 					<span><b>Минимальная стоимость доставки:</b></span><br />
	// 					<span>{delivery.minDeliveryPrice}</span><br />

	// 					<br />
	// 					<span><b>Сумма покупки для бесплатной доставки:</b></span><br />
	// 					<span>{delivery.freeDeliveryPrice}</span><br />

	// 					<br />
	// 					<span><b>Является самовывозом:</b></span><br />
	// 					<span>{delivery.pickup ? (<span>Да</span>):(<span>Нет</span>)}</span><br />

	// 					<br />
	// 					<span><b>Дополнительная инсформация:</b></span><br />
	// 					<span>{delivery.info}</span><br />

	// 				</div>
	// 			</div>
	// 			<div className="customer-buttons">
	// 				<Popconfirm title="Удалить способ доставки?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelDelivery(delivery)}>
	// 					<Button style={{ marginRight: 5 }} type="dashed">УДАЛИТЬ</Button>
	// 				</Popconfirm>
	// 				<Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleOpenEditDelivery(delivery)}>РЕДАКТИРОВАТЬ</Button>
	// 			</div>
	// 		</>
	// 	);
	// }

	const handleEditChange = (e, from) => {

		console.log(e);
		console.log(from);

		var admin_email = settings.admin_email;
		var admin_email_host = settings.admin_email_host;
		var admin_email_port = settings.admin_email_port;
		var admin_email_user = settings.admin_email_user;
		var admin_email_password = settings.admin_email_password;
		var admin_email_secure = settings.admin_email_secure;
		var admin_order_alert = settings.admin_order_alert;
		var customer_order_alert = settings.customer_order_alert;
		var customer_order_alert_field_1 = settings.customer_order_alert_field_1;
		var customer_order_alert_field_2 = settings.customer_order_alert_field_2;

		if (from == 'admin_email') {
			admin_email = e.target.value;
		}
		if (from == 'admin_email_host') {
			admin_email_host = e.target.value;
		}
		if (from == 'admin_email_port') {
			admin_email_port = e.target.value;
		}
		if (from == 'admin_email_user') {
			admin_email_user = e.target.value;
		}
		if (from == 'admin_email_password') {
			admin_email_password = e.target.value;
		}

		if (from == 'admin_email_secure') {
			admin_email_secure = e.target.checked;
		}
		if (from == 'admin_order_alert') {
			admin_order_alert = e.target.checked;
		}
		if (from == 'customer_order_alert') {
			customer_order_alert = e.target.checked;
		}

		if (from == 'customer_order_alert_field_1') {
			customer_order_alert_field_1 = e.target.value;
		}
		if (from == 'customer_order_alert_field_2') {
			customer_order_alert_field_2 = e.target.value;
		}

		const data = {
			admin_email,
			admin_email_host,
			admin_email_port,
			admin_email_user,
			admin_email_password,
			admin_email_secure,
			admin_order_alert,
			customer_order_alert,
			customer_order_alert_field_1,
			customer_order_alert_field_2,
		}
		dispatch(editSettings(data));

	};

	const handleSaveButton = () => {
		const data = {
			admin_email: settings.admin_email,
			admin_email_host: settings.admin_email_host,
			admin_email_port: settings.admin_email_port,
			admin_email_user: settings.admin_email_user,
			admin_email_password: settings.admin_email_password,
			admin_email_secure: settings.admin_email_secure,
			admin_order_alert: settings.admin_order_alert,
			customer_order_alert: settings.customer_order_alert,
			customer_order_alert_field_1: settings.customer_order_alert_field_1,
			customer_order_alert_field_2: settings.customer_order_alert_field_2,
		}
		dispatch(saveSettings(data));
	};

	function renderSort() {

		return (
			<>
				<Typography>Имя ящика администратора:</Typography>
				<Input
					value={settings.admin_email}
					name="admin_email"
					style={{ width: 220, marginBottom: 10 }}
					onChange={(e) => handleEditChange(e, "admin_email")}
				/>
				<Typography>Хост:</Typography>
				<Input
					value={settings.admin_email_host}
					name="admin_email_host"
					style={{ width: 220, marginBottom: 10 }}
					onChange={(e) => handleEditChange(e, "admin_email_host")}
				/>
				<Typography>Порт:</Typography>
				<Input
					value={settings.admin_email_port}
					name="admin_email_port"
					style={{ width: 220, marginBottom: 10 }}
					onChange={(e) => handleEditChange(e, "admin_email_port")}
				/>
				<Typography>Имя пользователя:</Typography>
				<Input
					value={settings.admin_email_user}
					name="admin_email_user"
					style={{ width: 220, marginBottom: 10 }}
					onChange={(e) => handleEditChange(e, "admin_email_user")}
				/>
				<Typography>Пароль пользователя:</Typography>
				<Input.Password
					visibilityToggle={false}
					password
					value={settings.admin_email_password}
					name="admin_email_password"
					style={{ width: 220, marginBottom: 10 }}
					onChange={(e) => handleEditChange(e, "admin_email_password")}
				/><br />

				<Checkbox checked={settings.admin_email_secure} onChange={(e) => handleEditChange(e, "admin_email_secure")}>Использует SSL?</Checkbox><br />
				<Divider />
				<Checkbox checked={settings.admin_order_alert} onChange={(e) => handleEditChange(e, "admin_order_alert")}>Уведомлять о новых заказах?</Checkbox><br />

				<Checkbox checked={settings.customer_order_alert} onChange={(e) => handleEditChange(e, "customer_order_alert")}>Уведомлять покупателя о заказе?</Checkbox><br />

				<Divider />
				<Typography>Текстовое поле №1 в письме с заказом клиенту</Typography>
				<Input
					value={settings.customer_order_alert_field_1}
					name="customer_order_alert_field_1"
					style={{ width: 220, marginBottom: 10 }}
					onChange={(e) => handleEditChange(e, "customer_order_alert_field_1")}
				/>
				<Typography>Текстовое поле №2 в письме с заказом клиенту</Typography>
				<Input
					value={settings.customer_order_alert_field_2}
					name="customer_order_alert_field_2"
					style={{ width: 220, marginBottom: 10 }}
					onChange={(e) => handleEditChange(e, "customer_order_alert_field_2")}
				/>
				<Divider />
				<Button disabled={!needSave} type="primary" icon="save" onClick={handleSaveButton}>Сохранить</Button>

			</>
		)
		// const columns = [
		// 	{ title: 'Название', dataIndex: 'name', key: 'name' },
		// 	{ title: 'Порядок сортировки', dataIndex: 'sortorder', key: 'sortorder' },
		// ];

		// if (delivery.length > 0) {
		// 	return (
		// 		<Table
		// 			rowKey={record => record._id}
		// 			style={{ marginTop: 20 }}
		// 			columns={columns}
		// 			expandedRowRender={record => renderDeliveryInfo(record)}
		// 			dataSource={delivery}
		// 		/>
		// 	);
		// } else {
		// 	return (
		// 		<div>
		// 			<Card title="Способов доставки не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
		// 				<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
		// 			</Card>
		// 		</div>
		// 	);
		// }
	}

	if (loading) {
		return (
			<div className="content-div">
				<Divider className="header-div" orientation="left"><Spin /></Divider>
			</div>
		);
	} else {
		return (
			<QueueAnim className="anim-content"
				type={['right', 'left']}
				ease={['easeOutQuart', 'easeInOutQuart']}>
				{!loading ? [
					<div key="a" className="content-div">
						<Divider className="header-div" orientation="left">Настройки Email</Divider>
						<Row type="flex" justify="end">
						</Row>
						{renderSort()}
						<Divider />
					</div>
				] : null}
			</QueueAnim>
		);
	}
};

const useFetching = (getEmailSettings, dispatch) => {
	useEffect(() => {
		dispatch(getEmailSettings());
	}, []);
};

export default CPEmailSettings;
