import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMenuFromIiko, getIikoSettings, editIikoSettings, saveIikoSettings, checkAuth } from '../../actions/iikoActions'
import { Select, Checkbox, Button, Typography, Divider, Spin, Layout, Card, Input } from 'antd';
import QueueAnim from 'rc-queue-anim';
const { Option } = Select;

const CPiiko = props => {
    useFetching(getIikoSettings, useDispatch());
    const dispatch = useDispatch();

    const loading = useSelector(state => state.iiko.isLoading);

    const menuLoading = useSelector(state => state.iiko.menuLoading);


    const enabled = useSelector(state => state.iiko.enabled);
    const iikoSettings = useSelector(state => state.iiko.iikoSettings);
    const needSave = useSelector(state => state.iiko.needSave);

    const mainUserLogin = useSelector(state => state.iiko.mainUserLogin);
    const mainUserSecret = useSelector(state => state.iiko.mainUserSecret);

    const handleIikoChange = (e, from) => {
        var newIikoEnabled = enabled;
        var newMainUserLogin = mainUserLogin;
        var newMainUserSecret = mainUserSecret;

        console.log("handleIikoChange");
        if (from == 'enabled') {
            newIikoEnabled = e;
        }
        if (e.target != null) {
            if (e.target.name == 'mainUserLogin') {
                newMainUserLogin = e.target.value;
            }
            if (e.target.name == 'mainUserSecret') {
                newMainUserSecret = e.target.value;
            }
        }
        const data = {
            enabled: newIikoEnabled,
            mainUserLogin: newMainUserLogin,
            mainUserSecret: newMainUserSecret
        }
        dispatch(editIikoSettings(data));
    }

    const handleSaveIikoButton = (e) => {
        const setsettings = {
            enabled: enabled,
            mainUserLogin:mainUserLogin,
            mainUserSecret:mainUserSecret,
            //settings: iikoSettings,
        }
        dispatch(saveIikoSettings(setsettings));
    };

    const checkAuthPress = () => {
        console.log('checkAuth btn')
        dispatch(checkAuth());
    }

    function renderIiko() {
        return (
            <div>
                <Card title={'iiko'} style={{ width: '100%', marginBottom: 20 }} size="small">
                    <Typography>Включить:</Typography>
                    <Select
                        defaultValue={enabled}
                        style={{ width: 220, marginBottom: 10 }}
                        onChange={(e) => handleIikoChange(e, "enabled")}
                    >
                        <Option value={false}>Нет</Option>
                        <Option value={true}>Да</Option>
                    </Select>

                    <Typography>Основной логин:</Typography>
                    <Input
                        value={mainUserLogin}
                        name="mainUserLogin"
                        style={{ width: 220, marginBottom: 10 }}
                        onChange={(e) => handleIikoChange(e, "mainUserLogin")}
                    />
                    <Typography>Основной секретный ключ:</Typography>
                    <Input
                        value={mainUserSecret}
                        name="mainUserSecret"
                        style={{ width: 220, marginBottom: 10 }}
                        onChange={(e) => handleIikoChange(e, "mainUserSecret")}
                    />


                    <br />
                    <Button disabled={!needSave} type="primary" icon="save" onClick={handleSaveIikoButton}>Сохранить</Button>
                    <br /><br />
                    <Button type="primary" icon="save" onClick={checkAuthPress}>Проверить данные</Button>
                    <br /><br />
                    <Button type="primary" disabled={menuLoading} icon="save" onClick={() => dispatch(getMenuFromIiko())}>Загрузить меню</Button> {menuLoading ? (<Spin />):(<></>)}
                </Card>
            </div>
        )
    }


    if (loading) {
        return (
            <div className="content-div">
                <Divider className="header-div" orientation="left"><Spin /></Divider>
            </div>
        );
    } else {
        return (
            <QueueAnim className="anim-content"
                type={['right', 'left']}
                ease={['easeOutQuart', 'easeInOutQuart']}>
                {!loading ? [
                    <div key="a" className="content-div">
                        <div>
                            <Divider className="header-div" orientation="left">Настройки интеграций</Divider>
                        </div>
                        {renderIiko()}
                    </div>
                ] : null}
            </QueueAnim>
        );
    }

};

const useFetching = (getIikoSettings, dispatch) => {
    useEffect(() => {
        dispatch(getIikoSettings());
    }, []);
};

export default CPiiko;
