import {
    REPORT_BASE_LOADED,
    REPORT_BASE_LOADING,
    REPORT_ITEMS_LOADED,
    REPORT_ITEMS_LOADING,
    REPORT_GET_ERRORS
} from "../actions/types";

const initialState = {
    isLoading: true,
    isLoadingItems: false,
    baseReport: {},
    itemReport: [],
    itemReportLoaded: false,
};
  
export default function (state = initialState, action) {
    switch (action.type) {
        case REPORT_ITEMS_LOADED:
            console.log('REPORT_ITEMS_LOADED');
            console.log(action.payload)
            return {
                ...state,
                itemReport: action.payload.tmp_items,
                isLoadingItems: false,
                itemReportLoaded: true,
            }
        case REPORT_ITEMS_LOADING:
            return {
                ...state,
                isLoadingItems: true,
            }
        case REPORT_BASE_LOADED:
            console.log('REPORT_BASE_LOADED');
            return {
                ...state,
                baseReport: action.payload.baseReport,
                isLoading: false,
            }
        case REPORT_BASE_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        default:
            return state;
    }
}
