import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Row, Col, Divider, Spin, Card, Icon, Layout, Table, Button, Modal, Typography, Popconfirm, Checkbox, Select } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
	getDeliveryList,
	showAddDeliveryModal,
	closeAddDeliveryModal,
	showEditDeliveryModal,
	closeEditDeliveryModal,
	addDelivery,
	deleteDelivery,
	editDelivery,
	sendStopSell
} from "../../actions/deliveryActions";
const { Option } = Select;
const CPDelivery = props => {
	useFetching(getDeliveryList, useDispatch());
	const dispatch = useDispatch();
	const [values, setValues] = React.useState({
		name: '',
		freeDeliveryPrice: '',
		minAvailableSumm: '',
		minDeliveryPrice: '',
		sortorder: '',
		info: '',
		pickup: false,
	});
	const [editvalues, setEditValues] = React.useState({
		_id: '',
		name: '',
		freeDeliveryPrice: '',
		minAvailableSumm: '',
		minDeliveryPrice: '',
		sortorder: '',
		info: '',
		pickup: false,
	});
	const delivery = useSelector(state => state.delivery.deliveries);
	const loading = useSelector(state => state.delivery.isLoading);
	const adding = useSelector(state => state.delivery.adding);
	const showAdd = useSelector(state => state.delivery.showAdd);
	const showEdit = useSelector(state => state.delivery.showEdit);
	const editing = useSelector(state => state.delivery.editing);

	const canBeOrdered = useSelector(state => state.delivery.canBeOrdered);
	const disableOrderTime = useSelector(state => state.delivery.disableOrderTime);


	const handleOpenAddModal = useCallback(() => dispatch(showAddDeliveryModal()), [dispatch]);
	const handleCloseAddModal = useCallback(() => dispatch(closeAddDeliveryModal()), [dispatch]);

	const handleDelDelivery = (delivery) => {
		dispatch(deleteDelivery(delivery));
	};

	const handleOpenEditDelivery = (delivery) => {
		setEditValues({
			...editvalues,
			_id: delivery._id,
			name: delivery.name,
			freeDeliveryPrice: delivery.freeDeliveryPrice,
			minAvailableSumm: delivery.minAvailableSumm,
			minDeliveryPrice: delivery.minDeliveryPrice,
			sortorder: delivery.sortorder,
			info: delivery.info,
			pickup: delivery.pickup,
		});
		dispatch(showEditDeliveryModal(delivery));
	};

	const handleCloseEditDelivery = useCallback(() => dispatch(closeEditDeliveryModal()), [dispatch]);

	const handleEditChange = name => event => {
		if (name == "pickup") {
			setEditValues({ ...editvalues, pickup: event.target.checked });
		} else {
			setEditValues({ ...editvalues, [name]: event.target.value });
		}
	};

	const handleEditDelivery = () => {
		dispatch(editDelivery(editvalues));
	};

	const handleAddDelivery = () => {
		dispatch(addDelivery(values));
		setValues({
			name: '',
			freeDeliveryPrice: '',
			minAvailableSumm: '',
			minDeliveryPrice: '',
			sortorder: '',
			info: '',
			pickup: false,
		});
	}

	const handleChange = name => event => {
		if (name == "pickup") {
			setValues({ ...values, pickup: event.target.checked });
		} else {
			setValues({ ...values, [name]: event.target.value });
		}

	};


	const [stopvalues, setStopvalues] = React.useState({
		canBeOrdered: canBeOrdered,
		disableOrderTime: disableOrderTime,
	});

	const [stopvaluessave, setStopvaluesSave] = React.useState(false);

	const handleСanBeOrderedChange = (e) => {
		setStopvalues({ ...stopvalues, canBeOrdered: e });
		setStopvaluesSave(true)
	}

	const handleDisableOrderTimeChange = (e) => {
		setStopvalues({ ...stopvalues, disableOrderTime: e.target.value });
		setStopvaluesSave(true)
	}

	const handleSaveStop = () => {
		setStopvaluesSave(false);
		dispatch(sendStopSell(stopvalues.canBeOrdered, stopvalues.disableOrderTime));
	}

	function renderDeliveryInfo(delivery) {
		return (
			<>
				<div key={delivery.id}>
					<div >
						<span><b>Сумма корзины с которой доступен способ доставки:</b></span><br />
						<span>{delivery.minAvailableSumm}</span><br />

						<br />
						<span><b>Минимальная стоимость доставки:</b></span><br />
						<span>{delivery.minDeliveryPrice}</span><br />

						<br />
						<span><b>Сумма покупки для бесплатной доставки:</b></span><br />
						<span>{delivery.freeDeliveryPrice}</span><br />

						<br />
						<span><b>Является самовывозом:</b></span><br />
						<span>{delivery.pickup ? (<span>Да</span>) : (<span>Нет</span>)}</span><br />

						<br />
						<span><b>Дополнительная инсформация:</b></span><br />
						<span>{delivery.info}</span><br />

					</div>
				</div>
				<div className="customer-buttons">
					<Popconfirm title="Удалить способ доставки?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelDelivery(delivery)}>
						<Button style={{ marginRight: 5 }} type="dashed">УДАЛИТЬ</Button>
					</Popconfirm>
					<Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleOpenEditDelivery(delivery)}>РЕДАКТИРОВАТЬ</Button>
				</div>
			</>
		);
	}

	function renderSort() {

		const columns = [
			{ title: 'Название', dataIndex: 'name', key: 'name' },
			{ title: 'Порядок сортировки', dataIndex: 'sortorder', key: 'sortorder' },
		];

		if (delivery.length > 0) {
			return (
				<Table
					rowKey={record => record._id}
					style={{ marginTop: 20 }}
					columns={columns}
					expandedRowRender={record => renderDeliveryInfo(record)}
					dataSource={delivery}
				/>
			);
		} else {
			return (
				<div>
					<Card title="Способов доставки не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	}

	if (loading) {
		return (
			<div className="content-div">
				<Divider className="header-div" orientation="left"><Spin /></Divider>
			</div>
		);
	} else {
		return (
			<QueueAnim className="anim-content"
				type={['right', 'left']}
				ease={['easeOutQuart', 'easeInOutQuart']}>
				{!loading ? [
					<div key="a" className="content-div">
						<Divider className="header-div" orientation="left">Доставка</Divider>
						<Row type="flex" justify="end">
						</Row>
						{renderSort()}
						<Divider />
						<Card style={{ width: '100%', marginBottom: 20 }} size="small">
							<Button type="primary" icon="plus" onClick={handleOpenAddModal}>
								Добавить
							</Button>
						</Card>

						Продажи: 

						<Select
							defaultValue={stopvalues.canBeOrdered}
							style={{ width: 220, marginBottom: 10, marginLeft:10, marginRight:10}}
							onChange={(e) => handleСanBeOrderedChange(e)}
						>
							<Option value={true}>Включено</Option>
							<Option value={false}>Отключено</Option>
						</Select>
						Текст для отображения: 
						<Input
							value={stopvalues.disableOrderTime}
							name="disableOrderTime"
							style={{ width: 220, marginBottom: 10, marginLeft:10 }}
							onChange={(e) => handleDisableOrderTimeChange(e)}
						/>

						<Button style={{ marginRight: 5 }} type="dashed" disabled={!stopvaluessave} onClick={() => handleSaveStop()}>СОХРАНИТЬ</Button>


						<Modal
							centered
							title={"Редактирование способа доставки"}
							visible={showEdit}
							onCancel={handleCloseEditDelivery}
							footer={[
								<Button
									key="back"
									onClick={handleCloseEditDelivery}
								>
									Отмена
								</Button>,
								<Button
									key="yes"
									type="primary"
									disabled={editing}
									onClick={handleEditDelivery}
								>
									Сохранить
								</Button>,
							]}
						>
							<div>

								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'center' }} xs={24} sm={24}>
										<Typography style={{ textAlign: 'left' }}>Название:</Typography>
										<Input
											value={editvalues.name}
											name="name"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("name")}
										/>
										<Typography style={{ textAlign: 'left' }}>Сумма покупки для бесплатной доставки:</Typography>
										<Input
											value={editvalues.freeDeliveryPrice}
											name="freeDeliveryPrice"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("freeDeliveryPrice")}
										/>
										<Typography style={{ textAlign: 'left' }}>Сумма корзины с которой доступен способ доставки:</Typography>
										<Input
											value={editvalues.minAvailableSumm}
											name="minAvailableSumm"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("minAvailableSumm")}
										/>
										<Typography style={{ textAlign: 'left' }}>Минимальная стоимость доставки:</Typography>
										<Input
											value={editvalues.minDeliveryPrice}
											name="minDeliveryPrice"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("minDeliveryPrice")}
										/>
										<Typography style={{ textAlign: 'left' }}>Порядок сортировки:</Typography>
										<Input
											value={editvalues.sortorder}
											name="sortorder"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("sortorder")}
										/>
										<Typography style={{ textAlign: 'left' }}>Дополнительная информация (адрес самовывоза):</Typography>
										<Input
											value={editvalues.info}
											name="info"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("info")}
										/>
										<Checkbox checked={editvalues.pickup} onChange={handleEditChange("pickup")}>Является самовывозом?</Checkbox>
									</Col>
								</Row>
							</div>
						</Modal>



						<Modal
							centered
							title={"Добавление способа доставки"}
							visible={showAdd}
							onCancel={handleCloseAddModal}
							footer={[
								<Button
									key="back"
									onClick={handleCloseAddModal}
								>
									Отмена
								</Button>,
								<Button
									key="yes"
									type="primary"
									disabled={adding}
									onClick={handleAddDelivery}
								>
									Добавить
								</Button>,
							]}
						>
							<div>

								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'center' }} xs={24} sm={24}>
										<Typography style={{ textAlign: 'left' }}>Название:</Typography>
										<Input
											value={values.name}
											name="name"
											style={{ marginBottom: 10 }}
											onChange={handleChange("name")}
										/>
										<Typography style={{ textAlign: 'left' }}>Сумма покупки для бесплатной доставки:</Typography>
										<Input
											value={values.freeDeliveryPrice}
											name="freeDeliveryPrice"
											style={{ marginBottom: 10 }}
											onChange={handleChange("freeDeliveryPrice")}
										/>
										<Typography style={{ textAlign: 'left' }}>Сумма корзины с которой доступен способ доставки:</Typography>
										<Input
											value={values.minAvailableSumm}
											name="minAvailableSumm"
											style={{ marginBottom: 10 }}
											onChange={handleChange("minAvailableSumm")}
										/>
										<Typography style={{ textAlign: 'left' }}>Минимальная стоимость доставки:</Typography>
										<Input
											value={values.minDeliveryPrice}
											name="minDeliveryPrice"
											style={{ marginBottom: 10 }}
											onChange={handleChange("minDeliveryPrice")}
										/>
										<Typography style={{ textAlign: 'left' }}>Порядок сортировки:</Typography>
										<Input
											value={values.sortorder}
											name="sortorder"
											style={{ marginBottom: 10 }}
											onChange={handleChange("sortorder")}
										/>
										<Typography style={{ textAlign: 'left' }}>Дополнительная информация (адрес самовывоза):</Typography>
										<Input
											value={values.info}
											name="info"
											style={{ marginBottom: 10 }}
											onChange={handleChange("info")}
										/>
										<Checkbox checked={values.pickup} onChange={handleChange("pickup")}>Является самовывозом?</Checkbox>
									</Col>
								</Row>
							</div>
						</Modal>

					</div>
				] : null}
			</QueueAnim>
		);
	}
};

const useFetching = (getDeliveryList, dispatch) => {
	useEffect(() => {
		dispatch(getDeliveryList());
	}, []);
};

export default CPDelivery;
