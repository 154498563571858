import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Input, Button, Row, Col, Typography, Divider, Spin, Card, Icon, Layout, Table, Modal, Checkbox, Popconfirm, Rate, Tag, DatePicker } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
	getReviewList,
	deleteReview,
	editReview,
	closeEditReviewModal,
	showEditReviewModal,
	showAddReviewModal,
	closeAddReviewModal,
	addReview,
} from "../../actions/reviewActions";
import moment from 'moment';

const { TextArea } = Input;

const CPReviews = props => {
	useFetching(getReviewList, useDispatch());
	const dispatch = useDispatch();

	const reviews = useSelector(state => state.review.reviews);
	const loading = useSelector(state => state.review.isLoading);

	const dateFormat = 'YYYY.MM.DD';

	function addScores(runningTotal, popularity) {
		return runningTotal + popularity;
	}
	function getRatings(item) {
		return item.rating;
	}
	function calcOverallRating() {
		const ratingsAll = reviews.map(getRatings);
		const ratingsTotal = ratingsAll.reduce(addScores, 0);
		console.log(ratingsTotal);
		const averageRating = ratingsTotal / ratingsAll.length;
		return averageRating;
	}

	const adding = useSelector(state => state.review.adding);
	const showAdd = useSelector(state => state.review.showAdd);
	const showEdit = useSelector(state => state.review.showEdit);
	const editing = useSelector(state => state.review.editing);

	const handleOpenAddModal = useCallback(() => dispatch(showAddReviewModal()), [dispatch]);
	const handleCloseAddModal = useCallback(() => dispatch(closeAddReviewModal()), [dispatch]);

	const [values, setValues] = React.useState({
		name: '',
		email: '',
		phone: '',
		comment: '',
		plus: '',
		minus: '',
		rating: 0,
		orderNum: 0,
		status: false,
		date: '',
		title: '',
		answer: '',
		created_at: '',
	});
	const [editvalues, setEditValues] = React.useState({
		_id: '',
		name: '',
		email: '',
		phone: '',
		comment: '',
		plus: '',
		minus: '',
		rating: 0,
		orderNum: 0,
		status: false,
		date: '',
		title: '',
		answer: '',
		created_at: '',
	});

	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};
	const handleEditChange = name => event => {
		setEditValues({ ...editvalues, [name]: event.target.value });
	};

	const handleEditChangeRating = value => {
		setEditValues({ ...editvalues, "rating": value });
	};

	const handleEditReviewStatusChange = (e, from) => {
		if (e.target.name == 'status') {
			setEditValues({ ...editvalues, "status": e.target.checked });
		}
	}
	const handleReviewStatusChange = (e, from) => {
		if (e.target.name == 'status') {
			setValues({ ...values, "status": e.target.checked });
		}
	}
	const handleChangeRating = value => {
		setValues({ ...values, "rating": value });
	};
	const handleAddReview = () => {
		dispatch(addReview(values));
		setValues({
			name: '',
			email: '',
			phone: '',
			comment: '',
			plus: '',
			minus: '',
			rating: 0,
			orderNum: 0,
			status: false,
			date: '',
			title: '',
			answer: '',
			created_at: '',
		});
	}

	const handleDelReview = (review) => {
		dispatch(deleteReview(review));
	};

	const handleOpenEditReview = (review) => {
		setEditValues({
			...editvalues,
			_id: review._id,
			name: review.name,
			email: review.email,
			phone: review.phone,
			comment: review.comment,
			plus: review.plus,
			minus: review.minus,
			rating: review.rating,
			orderNum: review.orderNum,
			status: review.status,
			date: review.date,
			title: review.title,
			answer: review.answer,
			created_at: review.created_at
		});
		dispatch(showEditReviewModal(review));
	};

	const handleCloseEditReview = useCallback(() => dispatch(closeEditReviewModal()), [dispatch]);

	const handleEditReview = () => {
		dispatch(editReview(editvalues));
	};
	function renderReviewInfo(review) {
		return (
			<>
				<div key={review.id}>
					<Row gutter={16}>
						<Col className="gutter-row" xs={24} sm={12}>
							<span><b>Текст отзыва:</b></span><br />
							<span>{review.comment}</span><br />
							<br />
							<span><b>Заголовок отзыва:</b></span><br />
							<span>{review.title}</span><br />
							<br />
							<span><b>Дата отзыва:</b></span><br />
							<span>{moment(review.created_at).format('LL')}</span><br />

							<br />
							<span><b>Плюсы:</b></span><br />
							<span>{review.plus}</span><br />

							<br />
							<span><b>Минус:</b></span><br />
							<span>{review.minus}</span><br />

							<br />
							<span><b>Рейтинг:</b></span><br />
							<span><Rate disabled value={review.rating} /></span><br />

							<br />
							<span><b>Статус:</b></span><br />
							<span>{review.status ? (<><Icon style={{ color: 'green' }} type="check" /></>) : (<><Icon style={{ color: 'red' }} type="stop" /></>)}</span><br />
						</Col>
					</Row>
					<Row gutter={16}>
						<Col className="gutter-row" xs={24} sm={24}>
							<Divider style={{ marginTop: 15, marginBottom: 15 }} />
						</Col>
						<Col className="gutter-row" xs={24}>
							<Button style={{ marginRight: 5 }} size="small" type="dashed" icon="edit" onClick={() => handleOpenEditReview(review)}>РЕДАКТИРОВАТЬ</Button>
							<Popconfirm title="Удалить отзыв?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelReview(review)}>
								<Button style={{ marginRight: 5 }} size="small" type="danger" ghost icon="delete">УДАЛИТЬ</Button>
							</Popconfirm>
						</Col>
					</Row>
				</div>
			</>
		);
	}

	function changeTimeSelect(e) {
		console.log(e.format())
		console.log(moment(e));
		setEditValues({ ...editvalues, created_at: e.format() });
	}

	function addTimeSelect(e) {
		console.log(e.format())
		console.log(moment(e));
		setValues({ ...values, created_at: e.format() });
	}
	// const handleChange = name => event => {
	// 	setValues({ ...values, [name]: event.target.value });
	// };
	// const handleEditChange = name => event => {
	// 	setEditValues({ ...editvalues, [name]: event.target.value });
	// };
	function renderSort() {

		const columns = [
			{ title: 'Имя клиента', dataIndex: 'name', key: 'name' },
			{ title: 'E-mail', dataIndex: 'email', key: 'email' },
			{ title: 'Телефон', dataIndex: 'phone', key: 'phone' },
			{ title: 'Номер заказа', dataIndex: 'orderNum', key: 'orderNum' },
			{
				title: 'Оценка', dataIndex: 'rating', key: 'rating',
				render: rating => {
					return (
						<>
							<Rate disabled value={rating} />
						</>
					);
				}
			},
			{
				title: 'Статус', dataIndex: 'status', key: 'status',
				render: statusvalue => {
					if (statusvalue) {
						return (
							<>
								<Icon style={{ color: 'green' }} type="check" />
							</>
						);
					} else {
						return (
							<>
								<Icon style={{ color: 'red' }} type="stop" />
							</>
						);
					}
				}
			},
			{
				title: 'Создан', dataIndex: 'created_at', key: 'created_at',
				render: time => {
					let timestamp = Date.parse(time);
					var date = new Date(timestamp);
					var formattedDate = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ' ' + ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
					return (
						<Tag>{formattedDate}</Tag>
					);
				}
			}
		];

		if (reviews.length > 0) {
			return (
				<Table
					rowKey={record => record._id}
					style={{ marginTop: 20 }}
					columns={columns}
					expandedRowRender={record => renderReviewInfo(record)}
					dataSource={reviews}
				/>
			);
		} else {
			return (
				<div>
					<Card title="Отзывов не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	}

	if (loading) {
		return (
			<div className="content-div">
				<Divider className="header-div" orientation="left"><Spin /></Divider>
			</div>
		);
	} else {
		return (
			<QueueAnim className="anim-content"
				type={['right', 'left']}
				ease={['easeOutQuart', 'easeInOutQuart']}>
				{!loading ? [
					<div key="a" className="content-div">
						<Divider className="header-div" orientation="left">Отзывы</Divider>
						<Row type="flex" justify="end">
						</Row>
						<Row type="flex" justify="flex-start">
							<Typography style={{ alignSelf: 'center' }}>Средний рейтинг:</Typography>
							<Rate style={{ marginLeft: 10 }} disabled allowHalf value={calcOverallRating()} />
							<Typography style={{ alignSelf: 'center', marginLeft: 10 }}>({calcOverallRating()})</Typography>

						</Row>
						{renderSort()}
						<Divider />
						<Card style={{ width: '100%', marginBottom: 20 }} size="small">
							<Button type="primary" icon="plus" onClick={handleOpenAddModal}>
								Добавить
        						</Button>
						</Card>

						<Modal
							centered
							title={"Редактирование отзыва"}
							visible={showEdit}
							onCancel={handleCloseEditReview}
							footer={[
								<Button
									key="back"
									onClick={handleCloseEditReview}
								>
									Отмена
          							</Button>,
								<Button
									key="yes"
									type="primary"
									disabled={editing}
									onClick={handleEditReview}
								>
									Сохранить
          							</Button>,
							]}
						>
							<div>
								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
										<Typography style={{ textAlign: 'left' }}>Имя клиента:</Typography>
										<Input
											value={editvalues.name}
											name="name"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("name")}
										/>
										<Typography style={{ textAlign: 'left' }}>E-mail клиента:</Typography>
										<Input
											value={editvalues.email}
											name="email"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("email")}
										/>
										<Typography style={{ textAlign: 'left' }}>Телефон клиента:</Typography>
										<Input
											value={editvalues.phone}
											name="phone"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("phone")}
										/>
										<Typography style={{ textAlign: 'left' }}>Заголовок отзыва:</Typography>
										<Input
											value={editvalues.title}
											name="title"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("title")}
										/>
										<Typography style={{ textAlign: 'left' }}>Дата отзыва:</Typography>
										{/* <Input
											value={editvalues.created_at}
											name="created_at"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("created_at")}
										/> */}
										<DatePicker onChange={(e) => changeTimeSelect(e)} value={moment(editvalues.created_at)} format={dateFormat} />

										<Typography style={{ textAlign: 'left' }}>Текст отзыва:</Typography>
										<Input
											value={editvalues.comment}
											name="comment"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("comment")}
										/>
										<Typography style={{ textAlign: 'left' }}>Плюсы:</Typography>
										<Input
											value={editvalues.plus}
											name="plus"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("plus")}
										/>
										<Typography style={{ textAlign: 'left' }}>Минусы:</Typography>
										<Input
											value={editvalues.minus}
											name="minus"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("minus")}
										/>
										<Typography style={{ textAlign: 'left' }}>Номер заказа:</Typography>
										<Input
											value={editvalues.orderNum}
											name="orderNum"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("orderNum")}
										/>

										<Typography>Ответ на отзыв:</Typography>
										<TextArea autosize={{ minRows: 2 }} label="Ответ на отзыв" value={editvalues.answer} onChange={handleEditChange("answer")} style={{ marginBottom: 10 }} />

										<Typography style={{ textAlign: 'left' }}>Рейтинг:</Typography>
										<Rate name="rating" defaultValue={editvalues.rating} value={editvalues.rating} onChange={handleEditChangeRating} />

										<br /><br />
										<Checkbox name={"status"} checked={editvalues.status} onChange={(e) => handleEditReviewStatusChange(e, "status")}>Опубликовать</Checkbox>
									</Col>
								</Row>
							</div>
						</Modal>

						<Modal
							centered
							title={"Добавление отзыва"}
							visible={showAdd}
							onCancel={handleCloseAddModal}
							footer={[
								<Button
									key="back"
									onClick={handleCloseAddModal}
								>
									Отмена
          							</Button>,
								<Button
									key="yes"
									type="primary"
									disabled={adding}
									onClick={handleAddReview}
								>
									Добавить
          							</Button>,
							]}
						>
							<div>
								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
										<Typography style={{ textAlign: 'left' }}>Имя клиента:</Typography>
										<Input
											value={values.name}
											name="name"
											style={{ marginBottom: 10 }}
											onChange={handleChange("name")}
										/>
										<Typography style={{ textAlign: 'left' }}>E-mail клиента:</Typography>
										<Input
											value={values.email}
											name="email"
											style={{ marginBottom: 10 }}
											onChange={handleChange("email")}
										/>
										<Typography style={{ textAlign: 'left' }}>Телефон клиента:</Typography>
										<Input
											value={values.phone}
											name="phone"
											style={{ marginBottom: 10 }}
											onChange={handleChange("phone")}
										/>
										<Typography style={{ textAlign: 'left' }}>Заголовок отзыва:</Typography>
										<Input
											value={values.title}
											name="title"
											style={{ marginBottom: 10 }}
											onChange={handleChange("title")}
										/>
										<Typography style={{ textAlign: 'left' }}>Дата:</Typography>
										<DatePicker onChange={(e) => addTimeSelect(e)} defaultValue={moment('2021.01.01', dateFormat)} format={dateFormat} />
										{/* <Input
											value={values.date}
											name="date"
											style={{ marginBottom: 10 }}
											onChange={handleChange("date")}
										/> */}
										<Typography style={{ textAlign: 'left' }}>Текст отзыва:</Typography>
										<Input
											value={values.comment}
											name="comment"
											style={{ marginBottom: 10 }}
											onChange={handleChange("comment")}
										/>
										<Typography style={{ textAlign: 'left' }}>Плюсы:</Typography>
										<Input
											value={values.plus}
											name="plus"
											style={{ marginBottom: 10 }}
											onChange={handleChange("plus")}
										/>
										<Typography style={{ textAlign: 'left' }}>Минусы:</Typography>
										<Input
											value={values.minus}
											name="minus"
											style={{ marginBottom: 10 }}
											onChange={handleChange("minus")}
										/>
										<Typography style={{ textAlign: 'left' }}>Номер заказа:</Typography>
										<Input
											value={values.orderNum}
											name="orderNum"
											style={{ marginBottom: 10 }}
											onChange={handleChange("orderNum")}
										/>
										<Typography style={{ textAlign: 'left' }}>Рейтинг:</Typography>
										<Rate name="rating" defaultValue={values.rating} value={values.rating} onChange={handleChangeRating} />
										<br /><br />
										<Checkbox name={"status"} checked={values.status} onChange={(e) => handleReviewStatusChange(e, "status")}>Опубликовать</Checkbox>
									</Col>
								</Row>
							</div>
						</Modal>
					</div>
				] : null}
			</QueueAnim>
		);
	}
};

const useFetching = (getReviewList, dispatch) => {
	useEffect(() => {
		dispatch(getReviewList());
	}, []);
};

export default CPReviews;
