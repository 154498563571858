import {
  OPTION_ADDED,
  OPTION_DELETED,
  ADDING_OPTIONS,
  LOADING_OPTIONS,
  GET_OPTION_LIST,
  SHOW_ADD_OPTION_MODAL,
  CLOSE_ADD_OPTION_MODAL,
  SHOW_DEL_OPTION_MODAL,
  CLOSE_DEL_OPTION_MODAL,
  GET_OPTION_ERRORS,
  SHOW_ADD_OPTION_CHECKBOX_MODAL,
  CLOSE_ADD_OPTION_CHECKBOX_MODAL,
  ADDING_CHECKBOX_OPTIONS,
  CHECKBOX_OPTION_ADDED,
  CHECKBOX_OPTION_DELETED,
  CHECKBOX_OPTION_DELETING,
  CHECKBOX_OPTION_DELETING_CONFIRM,
  SHOW_DEL_CHECKBOX_OPTION_MODAL,
  CLOSE_DEL_CHECKBOX_OPTION_MODAL,
  SHOW_ADD_OPTION_RADIO_MODAL,
  CLOSE_ADD_OPTION_RADIO_MODAL,
  RADIO_OPTION_ADDED,
  ADDING_RADIO_OPTIONS,
  RADIO_OPTION_DELETED
} from "../actions/types";

const initialState = {
  isLoading: true,
  showAdd: false,
  showAddCheckbox: false,
  showAddRadio: false,
  showDel: false,
  showDelCheckbox: false,
  availableTypes: [],
  addBtnDisabled: false,
  delCheckboxBtnDisabled: false,
  optionDel: {},
  checkboxOptionDel: {},
  checkboxes: [],
  options: [],
  radio: [],
  addRadioBtnDisabled: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RADIO_OPTION_DELETED:
      const radioid = action.payload.deletedradio._id;
      return {
        ...state,
        radio: state.radio.filter((radio) => radio._id !== radioid),
      };
    case RADIO_OPTION_ADDED:
      return{
        ...state,
        addRadioBtnDisabled: false,
        showAddRadio: false,
        radio: [...state.radio, action.payload.newradio],
      }
    case ADDING_RADIO_OPTIONS:
      return{
        ...state,
        addRadioBtnDisabled: true,
      }
    case CLOSE_ADD_OPTION_RADIO_MODAL:
      return{
        ...state,
        showAddRadio: false,
      }
    case SHOW_ADD_OPTION_RADIO_MODAL:
      return {
        ...state,
        showAddRadio: true,
      };    
    case CHECKBOX_OPTION_DELETING:
      return {
        ...state,
        delCheckboxBtnDisabled: true,
      }; 
    case GET_OPTION_LIST:
      console.log('Получили типы');
      console.log(action.payload.availableTypes);
      console.log('Получили опции');
      console.log(action.payload.options);
      return {
        ...state,
        options: action.payload.options,
        checkboxes: action.payload.checkboxes,
        radio: action.payload.radio,
        availableTypes: action.payload.availableTypes,
        isLoading: false
      };
    case LOADING_OPTIONS:
      return {
        ...state,
        isLoading: true
      };
    case ADDING_OPTIONS:
      return {
        ...state,
        addBtnDisabled: true
      };
    case ADDING_CHECKBOX_OPTIONS:
      return {
        ...state,
        addCheckboxBtnDisabled: true
      };
    case CHECKBOX_OPTION_ADDED:
      return {
        ...state,
        checkboxes: [...state.checkboxes, action.payload.newcheckbox],
        showAddCheckbox: false,
        addCheckboxBtnDisabled: false,
      };
    case CHECKBOX_OPTION_DELETED:
      const checkboxid = action.payload.deletedcheckbox._id;
      return {
        ...state,
        delCheckboxBtnDisabled: false,
        checkboxes: state.checkboxes.filter((checkbox) => checkbox._id !== checkboxid),
        showDelCheckbox: false,
        checkboxOptionDel: {},
      };
    case OPTION_ADDED:
      return {
        ...state,
        options: [...state.options, action.payload.newoption],
        //checkboxes: [...state.options, action.payload.newcheckbox],
        showAdd: false,
        addBtnDisabled: false,
      };
    case OPTION_DELETED:
      const id = action.payload.deletedoption._id;
      return {
        ...state,
        options: state.options.filter((option) => option._id !== id),
        showDel: false,
        optionDel: {},
      };
    case SHOW_ADD_OPTION_MODAL:
      return {
        ...state,
        isLoading: false,
        showAdd: true
      };
    case CLOSE_ADD_OPTION_MODAL:
      return {
        ...state,
        isLoading: false,
        showAdd: false,
      };
    case SHOW_ADD_OPTION_CHECKBOX_MODAL:
      return {
        ...state,
        isLoading: false,
        showAddCheckbox: true
      };
    case CLOSE_ADD_OPTION_CHECKBOX_MODAL:
      return {
        ...state,
        isLoading: false,
        showAddCheckbox: false,
      };
    case SHOW_DEL_OPTION_MODAL:
      return {
        ...state,
        isLoading: false,
        showDel: true,
        optionDel: action.payload
      };
    case CLOSE_DEL_OPTION_MODAL:
      return {
        ...state,
        isLoading: false,
        showDel: false
      };
    case SHOW_DEL_CHECKBOX_OPTION_MODAL:
      return {
        ...state,
        isLoading: false,
        showDelCheckbox: true,
        checkboxOptionDel: action.payload
      };
    case CLOSE_DEL_CHECKBOX_OPTION_MODAL:
      return {
        ...state,
        isLoading: false,
        showDelCheckbox: false
      };
    default:
      return state;
  }
}
