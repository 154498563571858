import axios from "axios";
import serverIp from "../utils/serverIp";

import {
    GET_WEB_SETTINGS,
    LOADING_WEB_SETTINGS,
    EDIT_WEB_SETTINGS,
    SAVED_WEB_SETTINGS,
    SHOW_ADD_BANNER_WEB_SETTINGS,
    CLOSE_ADD_BANNER_WEB_SETTINGS,
    BANNER_ADDING_WEB_SETTINGS,
    BANNER_ADDED_WEB_SETTINGS,
    BANNER_DELETED_WEB_SETTINGS,
    SHOW_ADD_BANNER_IMG_MODAL_WEB_SETTINGS,
    CLOSE_ADD_BANNER_IMG_MODAL_WEB_SETTINGS,
    BANNER_IMG_ADDING_WEB_SETTINGS,
    BANNER_IMG_ADDED_WEB_SETTINGS,
    BANNER_IMG_DELETED_WEB_SETTINGS,
    SHOW_BANNER_EDIT_WEB_SETTINGS,
    CLOSE_BANNER_EDIT_WEB_SETTINGS,
    BANNER_EDITED_WEB_SETTINGS,
    BANNER_EDITING_WEB_SETTINGS,
} from "./types";




export const editBanner = (data) => dispatch => {
    dispatch(setBannerEditing());
    axios
        .put(serverIp + "api/website/editbanner", data)
        .then(res => {
            dispatch({
                type: BANNER_EDITED_WEB_SETTINGS,
                payload: res.data
            });
        })
        .catch(err => { });
};

export const setBannerEditing = () => dispatch => {
    dispatch({
        type: BANNER_EDITING_WEB_SETTINGS
    });
};

export const getWebSettings = () => dispatch => {
    dispatch(setWebSettingsLoading());
    axios
        .get(serverIp + "api/website/getsettings")
        .then(res => {
            dispatch({
                type: GET_WEB_SETTINGS,
                payload: res.data
            });
        })
        .catch(err => { });
};

export const editWebSettings = (data) => dispatch => {
    dispatch({
        type: EDIT_WEB_SETTINGS,
        payload: data
    });
};

export const saveWebSettings = (data) => dispatch => {
    axios
        .put(serverIp + "api/website/savesettings", data)
        .then(res => {
            dispatch({
                type: SAVED_WEB_SETTINGS,
                payload: res.data
            });
        })
        .catch(err => { });
};

export const addWebBanner = (data) => dispatch => {
    dispatch(setBannerAdding());
    axios
        .put(serverIp + "api/website/addbanner", data)
        .then(res => {
            dispatch({
                type: BANNER_ADDED_WEB_SETTINGS,
                payload: res.data
            });
        })
        .catch(err => { });
};

export const delWebBanner = (data) => dispatch => {
    axios
        .put(serverIp + "api/website/delbanner", data)
        .then(res => {
            dispatch({
                type: BANNER_DELETED_WEB_SETTINGS,
                payload: res.data
            });
        })
        .catch(err => { });
};

export const showAddBannerImgModal = (banner, index) => dispatch => {
    dispatch({
        type: SHOW_ADD_BANNER_IMG_MODAL_WEB_SETTINGS,
        payload: banner,
        index: index
    });
};
export const closeAddBannerImgModal = () => dispatch => {
    dispatch({
        type: CLOSE_ADD_BANNER_IMG_MODAL_WEB_SETTINGS
    });
};


export const deleteBannerImg = (index) => dispatch => {
    dispatch(setBannerImgAdding());
    axios
        .post(serverIp + "api/website/delbannerimg", {"index":index})
        .then(res => {
            dispatch({
                type: BANNER_IMG_DELETED_WEB_SETTINGS,
                payload: res.data
            });
        })
        .catch(err =>{});
};



export const showEditBannerModal = (banner) => dispatch => {
    dispatch({
      type: SHOW_BANNER_EDIT_WEB_SETTINGS,
      payload: banner
    });
  };
  export const closeEditBannerModal = () => dispatch => {
    dispatch({
      type: CLOSE_BANNER_EDIT_WEB_SETTINGS
    });
  };


  


export const addBannerImg = (data) => dispatch => {
    dispatch(setBannerImgAdding());
    axios
        .post(serverIp + "api/website/addbannerimg", data)
        .then(res => {
            dispatch({
                type: BANNER_IMG_ADDED_WEB_SETTINGS,
                payload: res.data
            });
        })
        .catch(err =>{});
};

export const setBannerImgAdding = () => {
    return {
        type: BANNER_IMG_ADDING_WEB_SETTINGS
    };
};

export const setBannerAdding = () => {
    return {
        type: BANNER_ADDING_WEB_SETTINGS
    };
};


export const showAddBanner = () => {
    return {
        type: SHOW_ADD_BANNER_WEB_SETTINGS
    };
};
export const closeAddBanner = () => {
    return {
        type: CLOSE_ADD_BANNER_WEB_SETTINGS
    };
};


export const setWebSettingsLoading = () => {
    return {
        type: LOADING_WEB_SETTINGS
    };
};