import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  GET_BONUS_SETTINGS,
  LOADING_BONUS,
  EDIT_BONUS_SETTINGS,
  GET_BONUS_ERRORS,
  SAVED_BONUS_SETTINGS,
  SAVED_BONUS_SETTINGS_ERRORS
} from "./types";

export const getBonusSettings = () => dispatch => {
  console.log('getPaymentList');
  dispatch(setBonusLoading());
  axios
    .get(serverIp+"api/bonus/getsettings")
    .then(res => {
      dispatch({
        type: GET_BONUS_SETTINGS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_BONUS_ERRORS,
        payload: err.response
      })
    );
};


export const saveBonusSettings = (setdata) => dispatch => {
  axios
    .put(serverIp + "api/bonus/setsettings", setdata)
    .then(res => {
      dispatch({
        type: SAVED_BONUS_SETTINGS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: SAVED_BONUS_SETTINGS_ERRORS,
        payload: err.response
      })
    );
};

export const editBonusSettings = (data) => dispatch => {
  dispatch({
    type: EDIT_BONUS_SETTINGS,
    payload: data
  });
};

export const setBonusLoading = () => {
  return {
    type: LOADING_BONUS
  };
};
