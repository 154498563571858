import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Input, Button, Row, Col, Typography, Divider, Spin, Card, Icon, Layout, Table, Modal, Checkbox, Popconfirm } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
	getPaymentList,
	showAddPaymentModal,
	closeAddPaymentModal,
	showEditPaymentModal,
	closeEditPaymentModal,
	addPayment,
	deletePayment,
	editPayment
} from "../../actions/paymentActions";

const CPPayment = props => {
	useFetching(getPaymentList, useDispatch());
	const dispatch = useDispatch();

	const [values, setValues] = React.useState({
		name: '',
		minAvailableSumm: '',
		sortorder: '',
		info: '',
		online: false,
	});
	const [editvalues, setEditValues] = React.useState({
		_id: '',
		name: '',
		minAvailableSumm: '',
		sortorder: '',
		info: '',
		online: false,
	});

	const payment = useSelector(state => state.payment.payments);
	const loading = useSelector(state => state.payment.isLoading);

	const adding = useSelector(state => state.payment.adding);
	const showAdd = useSelector(state => state.payment.showAdd);
	const showEdit = useSelector(state => state.payment.showEdit);
	const editing = useSelector(state => state.payment.editing);


	const handleOpenAddModal = useCallback(() => dispatch(showAddPaymentModal()), [dispatch]);
	const handleCloseAddModal = useCallback(() => dispatch(closeAddPaymentModal()), [dispatch]);

	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};

	const handleAddPayment = () => {
		dispatch(addPayment(values));
		setValues({
			name: '',
			minAvailableSumm: '',
			sortorder: '',
			info: '',
			online: false,
		});
	}

	const handleDelPayment = (payment) => {
		dispatch(deletePayment(payment));
	};
	
	const handleAddPaymentOnlineChange = (e, from) => {
		if (e.target.name == 'online') {
			setValues({ ...values, "online": e.target.checked });
		}
	}
	const handleEditPaymentOnlineChange = (e, from) => {
		if (e.target.name == 'online') {
			setEditValues({ ...editvalues, "online": e.target.checked });
		}
	}
	
	const handleOpenEditPayment = (payment) => {
		setEditValues({
			...editvalues,
			_id: payment._id,
			name: payment.name,
			minAvailableSumm: payment.minAvailableSumm,
			online: payment.online,
			sortorder: payment.sortorder,
			info: payment.info,
		});
		dispatch(showEditPaymentModal(payment));
	};

	const handleCloseEditPayment = useCallback(() => dispatch(closeEditPaymentModal()), [dispatch]);

	const handleEditChange = name => event => {
		setEditValues({ ...editvalues, [name]: event.target.value });
	};

	const handleEditPayment = () => {
		dispatch(editPayment(editvalues));
	};

	function renderPaymentInfo(payment) {
		return (
			<>
				<div key={payment.id}>
					<div >
						<span><b>Сумма корзины с которой доступен способ оплаты:</b></span><br />
						<span>{payment.minAvailableSumm}</span><br />

						<br />
						<span><b>Online:</b></span><br />
						<span>{payment.online ? (<div><p>Да</p></div>) : (<div><p>Нет</p></div>)}</span><br />
					</div>
				</div>
				<div className="customer-buttons">
					<Popconfirm title="Удалить способ оплаты?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelPayment(payment)}>
						<Button style={{ marginRight: 5 }} type="dashed">УДАЛИТЬ</Button>
					</Popconfirm>
					<Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleOpenEditPayment(payment)}>РЕДАКТИРОВАТЬ</Button>
				</div>
			</>
		);
	}

	function renderSort() {

		const columns = [
			{ title: 'Название', dataIndex: 'name', key: 'name' },
			{ title: 'Порядок сортировки', dataIndex: 'sortorder', key: 'sortorder' },
		];

		if (payment.length > 0) {
			return (
				<Table
					rowKey={record => record._id}
					style={{ marginTop: 20 }}
					columns={columns}
					expandedRowRender={record => renderPaymentInfo(record)}
					dataSource={payment}
				/>
			);
		} else {
			return (
				<div>
					<Card title="Способов оплаты не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	}

	if (loading) {
		return (
				<div className="content-div">
					<Divider className="header-div" orientation="left"><Spin /></Divider>
				</div>
		);
	} else {
		return (
				<QueueAnim className="anim-content"
					type={['right', 'left']}
					ease={['easeOutQuart', 'easeInOutQuart']}>
					{!loading ? [
						<div key="a" className="content-div">
							<Divider className="header-div" orientation="left">Оплата</Divider>
							<Row type="flex" justify="end">
							</Row>

							{renderSort()}
							<Divider />
							<Card style={{ width: '100%', marginBottom: 20 }} size="small">
								<Button type="primary" icon="plus" onClick={handleOpenAddModal}>
									Добавить
        						</Button>
							</Card>

							<Modal
								centered
								title={"Редактирование способа оплаты"}
								visible={showEdit}
								onCancel={handleCloseEditPayment}
								footer={[
									<Button
										key="back"
										onClick={handleCloseEditPayment}
									>
										Отмена
          							</Button>,
									<Button
										key="yes"
										type="primary"
										disabled={editing}
										onClick={handleEditPayment}
									>
										Сохранить
          							</Button>,
								]}
							>
								<div>

									<Row gutter={16}>
										<Col className="gutter-row" style={{ textAlign: 'center' }} xs={24} sm={24}>
											<Typography style={{ textAlign: 'left' }}>Название:</Typography>
											<Input
												value={editvalues.name}
												name="name"
												style={{ marginBottom: 10 }}
												onChange={handleEditChange("name")}
											/>
											<Typography style={{ textAlign: 'left' }}>Сумма корзины с которой доступен способ доставки:</Typography>
											<Input
												value={editvalues.minAvailableSumm}
												name="minAvailableSumm"
												style={{ marginBottom: 10 }}
												onChange={handleEditChange("minAvailableSumm")}
											/>
											<Typography style={{ textAlign: 'left' }}>Порядок сортировки:</Typography>
											<Input
												value={editvalues.sortorder}
												name="sortorder"
												style={{ marginBottom: 10 }}
												onChange={handleEditChange("sortorder")}
											/>
											<Typography style={{ textAlign: 'left' }}>Дополнительная информация (адрес самовывоза):</Typography>
											<Input
												value={editvalues.info}
												name="info"
												style={{ marginBottom: 10 }}
												onChange={handleEditChange("info")}
											/>
											<Checkbox name={"online"} checked={editvalues.online} onChange={(e) => handleEditPaymentOnlineChange(e, "online")}>Этот способ является "Онлайн" оплатой.</Checkbox>
										</Col>
									</Row>
								</div>
							</Modal>

							<Modal
								centered
								title={"Добавление способа оплаты"}
								visible={showAdd}
								onCancel={handleCloseAddModal}
								footer={[
									<Button
										key="back"
										onClick={handleCloseAddModal}
									>
										Отмена
          							</Button>,
									<Button
										key="yes"
										type="primary"
										disabled={adding}
										onClick={handleAddPayment}
									>
										Добавить
          							</Button>,
								]}
							>
								<div>

									<Row gutter={16}>
										<Col className="gutter-row" style={{ textAlign: 'center' }} xs={24} sm={24}>
											<Typography style={{ textAlign: 'left' }}>Название:</Typography>
											<Input
												value={values.name}
												name="name"
												style={{ marginBottom: 10 }}
												onChange={handleChange("name")}
											/>
											<Typography style={{ textAlign: 'left' }}>Сумма корзины с которой доступен способ оплаты:</Typography>
											<Input
												value={values.minAvailableSumm}
												name="minAvailableSumm"
												style={{ marginBottom: 10 }}
												onChange={handleChange("minAvailableSumm")}
											/>
											<Typography style={{ textAlign: 'left' }}>Порядок сортировки:</Typography>
											<Input
												value={values.sortorder}
												name="sortorder"
												style={{ marginBottom: 10 }}
												onChange={handleChange("sortorder")}
											/>
											<Typography style={{ textAlign: 'left' }}>Дополнительная информация (подробности оплаты):</Typography>
											<Input
												value={values.info}
												name="info"
												style={{ marginBottom: 10 }}
												onChange={handleChange("info")}
											/>
											<Checkbox name={"online"} checked={values.online} onChange={(e) => handleAddPaymentOnlineChange(e, "online")}>Этот способ является "Онлайн" оплатой.</Checkbox>
										</Col>
									</Row>
								</div>
							</Modal>

						</div>
					] : null}
				</QueueAnim>
		);
	}
};

const useFetching = (getPaymentList, dispatch) => {
	useEffect(() => {
		dispatch(getPaymentList());
	}, []);
};

export default CPPayment;
