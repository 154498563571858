import {
    GET_ARTICLE_LIST,
    LOADING_ARTICLE,
    GET_ARTICLE_ERRORS,
    SHOW_ADD_ARTICLE_MODAL,
    CLOSE_ADD_ARTICLE_MODAL,
    ARTICLE_ADDED,
    ADDING_ARTICLE,
    ARTICLE_EDITING,
    ARTICLE_EDITED,
    CLOSE_EDIT_ARTICLE_MODAL,
    SHOW_EDIT_ARTICLE_MODAL,
    ARTICLE_DELETED
} from "../actions/types";

const initialState = {
    isLoading: true,
    articles: [],
    editing: false,
    showAdd: false,
    adding: false,
    showEdit: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ARTICLE_DELETED:
            const id = action.payload.deletedarticle._id;
            return {
              ...state,
              articles: state.articles.filter((article) => article._id !== id),
            };
        case ARTICLE_EDITED:
            const updatedState = state.articles.map((article) => {
              if (article._id === action.payload.editedarticle._id) {
                return { ...article, ...action.payload.editedarticle };
              } else {
              }
              return article;
            });
            return {
              ...state,
              articles: updatedState,
              showEdit: false,
              editing: false,
            };
        case ARTICLE_EDITING:
            return {
                ...state,
                editing: true,
            };
        case CLOSE_EDIT_ARTICLE_MODAL:
            return {
                ...state,
                editing: false,
                showEdit: false,
            }
        case SHOW_EDIT_ARTICLE_MODAL:
            return {
                ...state,
                showEdit: true,
            }
        case GET_ARTICLE_LIST:
            return {
                ...state,
                isLoading: false,
                articles: action.payload.articles,
            };
        case LOADING_ARTICLE:
            return {
                ...state,
                isLoading: true,
            };
        case ADDING_ARTICLE:
            return {
                ...state,
                adding: true,
            };
        case ARTICLE_ADDED:
            return {
                ...state,
                articles: [...state.articles, action.payload.newarticle],
                isLoading: false,
                showAdd: false,
                adding: false,
            };
        case SHOW_ADD_ARTICLE_MODAL:
            return {
                ...state,
                isLoading: false,
                showAdd: true,
            };
        case CLOSE_ADD_ARTICLE_MODAL:
            return {
                ...state,
                isLoading: false,
                showAdd: false,
            };
        default:
            return state;
    }
}
