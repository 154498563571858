import axios from "axios";
import serverIp from "../utils/serverIp";

import {
    TIMESHEET_LOADED,
    TIMESHEET_LOADING,
    EMPLOYLIST_LOADED,
    EMPLOYLIST_LOADING,
    EMPLOYLIST_HIDE,
    GET_TIMESHEET_ERRORS,
    EMPLOY_SAVED,
    MONTH_LOADING,
    MONTH_LOADED,
    ADD_WORKDAY,
    ADD_PREPAID,
    ADD_PREM
} from "./types";




export const addPrem = (user, prem, year, month, indexuser) => dispatch => {
    axios
    .post(serverIp + "api/timesheet/addprem", { user, prem, year, month })
    .then(res => {
        dispatch({
            type: ADD_PREM,
            payload: { user, prem, year, month, indexuser }
        });
    })
    .catch(err =>
        dispatch({
            type: GET_TIMESHEET_ERRORS,
            payload: err.response
        })
    );
}
export const deletePrem = (user, index, year, month, indexuser) => dispatch => {
    axios
    .post(serverIp + "api/timesheet/deleteprem", { user, index, year, month })
    .then(res => {
        dispatch({
            type: ADD_PREM,
            payload: { user, index, year, month, indexuser }
        });
    })
    .catch(err =>
        dispatch({
            type: GET_TIMESHEET_ERRORS,
            payload: err.response
        })
    );
}

export const addPrepaid = (user, prepaid, year, month, indexuser) => dispatch => {
    axios
    .post(serverIp + "api/timesheet/addprepaid", { user, prepaid, year, month })
    .then(res => {
        dispatch({
            type: ADD_PREPAID,
            payload: { user, prepaid, year, month, indexuser }
        });
    })
    .catch(err =>
        dispatch({
            type: GET_TIMESHEET_ERRORS,
            payload: err.response
        })
    );
}
export const deletePrepaid = (user, index, year, month, indexuser) => dispatch => {
    axios
    .post(serverIp + "api/timesheet/deleteprepaid", { user, index, year, month })
    .then(res => {
        dispatch({
            type: ADD_PREPAID,
            payload: { user, index, year, month, indexuser }
        });
    })
    .catch(err =>
        dispatch({
            type: GET_TIMESHEET_ERRORS,
            payload: err.response
        })
    );
}


export const addWorkdayAction = (userindex, dayindex, userid, total, arrive, departure, selectedYear, selectedMonth) => dispatch => {
    // dispatch(setMonthTimesheetLoadnig());
    // return {
    //     type: ADD_WORKDAY,
    //     payload: {userindex, dayindex}
    // };
    axios
        .post(serverIp + "api/timesheet/setworktime", { userid, dayindex, total, arrive, departure, selectedYear, selectedMonth })
        .then(res => {
            dispatch({
                type: ADD_WORKDAY,
                payload: { userindex, dayindex, userid, total, arrive, departure }
            });
        })
        .catch(err =>
            dispatch({
                type: GET_TIMESHEET_ERRORS,
                payload: err.response
            })
        );

}

export const getMonthTimesheet = (year, month) => dispatch => {
    dispatch(setMonthTimesheetLoadnig());
    axios
        .post(serverIp + "api/timesheet/getmonth", { year, month })
        .then(res => {
            dispatch({
                type: MONTH_LOADED,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch({
                type: GET_TIMESHEET_ERRORS,
                payload: err.response
            })
        );
}
export const setMonthTimesheetLoadnig = () => {
    return {
        type: MONTH_LOADING
    };
};
export const saveEmploy = (employ) => dispatch => {
    axios
        .post(serverIp + "api/timesheet/saveemploy", { employ })
        .then(res => {
            dispatch({
                type: EMPLOY_SAVED,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch({
                type: GET_TIMESHEET_ERRORS,
                payload: err.response
            })
        );
}

export const hideEmployList = () => dispatch => {
    dispatch(setEmployHide());

};
export const setEmployHide = () => {
    return {
        type: EMPLOYLIST_HIDE
    };
};

export const getEmployList = () => dispatch => {
    dispatch(setEmployLoading());
    axios
        .get(serverIp + "api/timesheet/getemploylist")
        .then(res => {
            dispatch({
                type: EMPLOYLIST_LOADED,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch({
                type: GET_TIMESHEET_ERRORS,
                payload: err.response
            })
        );
};

export const setEmployLoading = () => {
    return {
        type: EMPLOYLIST_LOADING
    };
};


export const getTimesheets = () => dispatch => {
    dispatch(setTimesheetsLoading());
    axios
        .get(serverIp + "api/timesheet/gettimesheets")
        .then(res => {
            dispatch({
                type: TIMESHEET_LOADED,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch({
                type: GET_TIMESHEET_ERRORS,
                payload: err.response
            })
        );
};

export const setTimesheetsLoading = () => {
    return {
        type: TIMESHEET_LOADING
    };
};
