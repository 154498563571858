import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Row, Col, Divider, Spin, Card, Icon, Layout, Table, Button, Modal, Typography, Popconfirm, Tag, Checkbox } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
	getPrintSettings,
	setPrintSettings,
	editPrintSettings
} from "../../actions/printSettingsActions";

const CPPrintSettings = props => {
	useFetching(getPrintSettings, useDispatch());
	const dispatch = useDispatch();

	const loading = useSelector(state => state.printsettings.isLoading);
	const settings = useSelector(state => state.printsettings.settings);
	const sending = useSelector(state => state.printsettings.sending);
	const needSave = useSelector(state => state.printsettings.needSave);

	const title = useSelector(state => state.printsettings.title);
	const field_1 = useSelector(state => state.printsettings.field_1);
	const field_2 = useSelector(state => state.printsettings.field_2);
	const field_3 = useSelector(state => state.printsettings.field_3);
	const field_4 = useSelector(state => state.printsettings.field_4);
	const user_name = useSelector(state => state.printsettings.user_name);
	const customer_data = useSelector(state => state.printsettings.customer_data);

	const handleSaveButton = () => {
		const data = {
			title,
			field_1,
			field_2,
			field_3,
			field_4,
			user_name,
			customer_data
		}
		dispatch(setPrintSettings(data));
	};

	const handleEditChange = (e, from) => {

		var newTitle = title;
		var newField_1 = field_1;
		var newField_2 = field_2;
		var newField_3 = field_3;
		var newField_4 = field_4;
		var newUserName = user_name;
		var newCustomerData = customer_data;

		if (e.target != null) {
			if (e.target.name == 'title') {
				newTitle = e.target.value;
			}
			if (e.target.name == 'field_1') {
				newField_1 = e.target.value;
			}
			if (e.target.name == 'field_2') {
				newField_2 = e.target.value;
			}
			if (e.target.name == 'field_3') {
				newField_3 = e.target.value;
			}
			if (e.target.name == 'field_4') {
				newField_4 = e.target.value;
			}
			if (e.target.name == 'user_name') {
				newUserName = e.target.checked;
			}
			if (e.target.name == 'customer_data') {
				newCustomerData = e.target.checked;
			}
		}

		const data = {
			newTitle,
			newField_1,
			newField_2,
			newField_3,
			newField_4,
			newUserName,
			newCustomerData
		}
		dispatch(editPrintSettings(data));
	};

	if (loading) {
		return (
				<div className="content-div">
					<Divider className="header-div" orientation="left"><Spin /></Divider>
				</div>
		);
	} else {
		return (
				<QueueAnim className="anim-content"
					type={['right', 'left']}
					ease={['easeOutQuart', 'easeInOutQuart']}>
					{!loading ? [
						<div key="a" className="content-div">
							<Divider className="header-div" orientation="left">Настройки печати</Divider>
							<Row type="flex" justify="end">
							</Row>
							<Row gutter={16}>
								<Col className="gutter-row" xs={24} sm={12}>
									<Typography>Название:</Typography>
									<Input
										value={title}
										name="title"
										style={{ width: 220, marginBottom: 10 }}
										onChange={(e) => handleEditChange(e, "title")}
									/>
									<Typography>Поле №1:</Typography>
									<Input
										value={field_1}
										name="field_1"
										style={{ width: 220, marginBottom: 10 }}
										onChange={(e) => handleEditChange(e, "field_1")}
									/>
									<Typography>Поле №2:</Typography>
									<Input
										value={field_2}
										name="field_2"
										style={{ width: 220, marginBottom: 10 }}
										onChange={(e) => handleEditChange(e, "field_2")}
									/>
									<Typography>Поле №3:</Typography>
									<Input
										value={field_3}
										name="field_3"
										style={{ width: 220, marginBottom: 10 }}
										onChange={(e) => handleEditChange(e, "field_3")}
									/>
									<Typography>Поле №4:</Typography>
									<Input
										value={field_4}
										name="field_4"
										style={{ width: 220, marginBottom: 10 }}
										onChange={(e) => handleEditChange(e, "field_4")}
									/>
									<br />
									<Checkbox
										checked={user_name}
										name="user_name"
										onChange={(e) => handleEditChange(e, "user_name")}
									>
										Печатать имя сотрудника?
									</Checkbox>
									<br />
									<Checkbox
										checked={customer_data}
										name="customer_data"
										onChange={(e) => handleEditChange(e, "customer_data")}
									>
										Печатать данные покупателя?
									</Checkbox>
								</Col>
								<Col className="gutter-row" xs={24} sm={12}>

									<div className="cheque-80mm">
										<div className="cheque-title-80mm">
											<span>{title}</span>
										</div>
										<div className="cheque-field_1-80mm">
											<span>{field_1}</span>
										</div>
										<div className="cheque-field_2-80mm">
											<span>{field_2}</span>
										</div>
										<div className="cheque-field_3-80mm">
											<span>{field_3}</span>
										</div>

										<div className="cheque-date-80mm">
											<span>20.01.2020</span>
										</div>

										<div className="cheque-number-80mm">
											<span>##31</span>
										</div>

										{user_name ? (<div className="cheque-user-80mm">
											<span>Сотрудник: Иванов Иван</span>
										</div>) : (<></>)}

										<div className="cheque-table-80mm">
											<table border="0">
												<tr className="cheque-table-header-80mm">
													<th>Наименование</th>
													<th style={{ textAlign: 'right' }}>Кол.</th>
													<th style={{ textAlign: 'right' }}>Сумма</th>
												</tr>
												{/* ПОКУПКИ */}
												<tr className="cheque-table-product-80mm">
													<td>Супер вкусная штука</td>
													<td className="cheque-table-product-quantity-80mm">1</td>
													<td className="cheque-table-product-price-80mm">500</td>
												</tr>
												<tr className="cheque-table-product-80mm">
													<td>Супер вкусная штука c длинным названием</td>
													<td className="cheque-table-product-quantity-80mm">1</td>
													<td className="cheque-table-product-price-80mm">1500</td>
												</tr>

												<tr className="cheque-table-sum-80mm">
													<td>ИТОГО</td>
													<td></td>
													<td className="cheque-table-product-price-80mm">2000</td>
												</tr>
												<tr>
													<td>Скидка</td>
													<td></td>
													<td className="cheque-table-product-price-80mm">150</td>
												</tr>
												<tr className="cheque-table-total-80mm">
													<td>К ОПЛАТЕ</td>
													<td></td>
													<td className="cheque-table-product-price-80mm">1850</td>
												</tr>
											</table>
										</div>
										<div className="cheque-field_4-80mm">
											<span>{field_4}</span>
										</div>
										{customer_data ? (
										<div className="cheque-customer-80mm">
											<table border="0">
												<tr className="cheque-customer-row-80mm">
													<td>Клиент:</td>
													<td>Александр Аксенов</td>
												</tr>
												<tr className="cheque-customer-row-80mm">
													<td>Улица:</td>
													<td>Краснобогатырская застава</td>
												</tr>
												<tr className="cheque-customer-row-80mm">
													<td>Дом:</td>
													<td>самый красивый</td>
												</tr>
												<tr className="cheque-customer-row-80mm">
													<td>Этаж:</td>
													<td>5</td>
												</tr>
												<tr className="cheque-customer-row-80mm">
													<td>Кв. (офис):</td>
													<td>10</td>
												</tr>
												<tr className="cheque-customer-row-80mm">
													<td>Телефон:</td>
													<td>7(999)999-99-99</td>
												</tr>
												<tr className="cheque-customer-row-80mm">
													<td>Примечание:</td>
													<td>Пожелания клиента</td>
												</tr>
											</table>
										</div>
										) : (<></>)}
									</div>


								</Col>
							</Row>
							<Divider />
							<Button disabled={!needSave} type="primary" icon="save" onClick={handleSaveButton}>
								Сохранить
      						</Button>
						</div>
					] : null}
				</QueueAnim>
		);
	}
};

const useFetching = (getPrintSettings, dispatch) => {
	useEffect(() => {
		dispatch(getPrintSettings());
	}, []);
};

export default CPPrintSettings;
