import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Input, Button, Row, Col, Typography, Divider, Spin, Card, Icon, Layout, Table, Modal, Checkbox, Popconfirm } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
    getFilterList,
    addFilter,
    deleteFilter,
    editFilter,
    showAddFilterModal,
    closeAddFilterModal,
    showEditFilterModal,
    closeEditFilterModal,
} from "../../actions/itemFilterActions";

const CPItemFilters = props => {
	useFetching(getFilterList, useDispatch());
	const dispatch = useDispatch();


	const filters = useSelector(state => state.itemfilters.filters);
	const loading = useSelector(state => state.itemfilters.isLoading);

	const adding = useSelector(state => state.itemfilters.adding);
	const showAdd = useSelector(state => state.itemfilters.showAdd);
	const showEdit = useSelector(state => state.itemfilters.showEdit);
	const editing = useSelector(state => state.itemfilters.editing);

    const [values, setValues] = React.useState({
		name: '',
		sortorder: '',
	});
	const [editvalues, setEditValues] = React.useState({
		_id: '',
		name: '',
		sortorder: '',
    });
    
	const handleOpenAddModal = useCallback(() => dispatch(showAddFilterModal()), [dispatch]);
	const handleCloseAddModal = useCallback(() => dispatch(closeAddFilterModal()), [dispatch]);

	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};

	const handleAddFilter = () => {
		dispatch(addFilter(values));
		setValues({
			name: '',
			sortorder: '',
		});
	}

	const handleDelFilter = (filter) => {
		dispatch(deleteFilter(filter));
	};
	

	const handleOpenEditFilter = (filter) => {
		setEditValues({
			...editvalues,
			_id: filter._id,
			name: filter.name,
			sortorder: filter.sortorder,
		});
		dispatch(showEditFilterModal(filter));
	};

    const handleCloseEditFilter = useCallback(() => dispatch(closeEditFilterModal()), [dispatch]);

	const handleEditChange = name => event => {
		setEditValues({ ...editvalues, [name]: event.target.value });
	};

	const handleEditFilter = () => {
		dispatch(editFilter(editvalues));
	};

	function renderFilterInfo(filter) {
		return (
			<>
				<div key={filter.id}>
				<div className="customer-buttons">
					<Popconfirm title="Удалить фильтр?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelFilter(filter)}>
						<Button style={{ marginRight: 5 }} type="dashed">УДАЛИТЬ</Button>
					</Popconfirm>
					<Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleOpenEditFilter(filter)}>РЕДАКТИРОВАТЬ</Button>
				</div>
                </div>
			</>
		);
	}

	function renderSort() {

		const columns = [
			{ title: 'Название', dataIndex: 'name', key: 'name' },
            { title: 'Порядок сортировки', dataIndex: 'sortorder', key: 'sortorder' },
		];

		if (filters.length > 0) {
			return (
				<Table
					rowKey={record => record._id}
					style={{ marginTop: 20 }}
                    columns={columns}
                    expandedRowRender={record => renderFilterInfo(record)}
					dataSource={filters}
				/>
			);
		} else {
			return (
				<div>
					<Card title="Фильтров не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	}

	if (loading) {
		return (
				<div className="content-div">
					<Divider className="header-div" orientation="left"><Spin /></Divider>
				</div>
		);
	} else {
		return (
				<QueueAnim className="anim-content"
					type={['right', 'left']}
					ease={['easeOutQuart', 'easeInOutQuart']}>
					{!loading ? [
						<div key="a" className="content-div">
							<Divider className="header-div" orientation="left">Фильтры товаров</Divider>
							<Row type="flex" justify="end">
							</Row>

							{renderSort()}
							<Divider />
							<Card style={{ width: '100%', marginBottom: 20 }} size="small">
								<Button type="primary" icon="plus" onClick={handleOpenAddModal}>
									Добавить
        						</Button>
							</Card>

							<Modal
								centered
								title={"Редактирование фильтра"}
								visible={showEdit}
								onCancel={handleCloseEditFilter}
								footer={[
									<Button
										key="back"
										onClick={handleCloseEditFilter}
									>
										Отмена
          							</Button>,
									<Button
										key="yes"
										type="primary"
										disabled={editing}
										onClick={handleEditFilter}
									>
										Сохранить
          							</Button>,
								]}
							>
								<div>

									<Row gutter={16}>
										<Col className="gutter-row" style={{ textAlign: 'center' }} xs={24} sm={24}>
											<Typography style={{ textAlign: 'left' }}>Название:</Typography>
											<Input
												value={editvalues.name}
												name="name"
												style={{ marginBottom: 10 }}
												onChange={handleEditChange("name")}
											/>
											<Typography style={{ textAlign: 'left' }}>Порядок сортировки:</Typography>
											<Input
												value={editvalues.sortorder}
												name="sortorder"
												style={{ marginBottom: 10 }}
												onChange={handleEditChange("sortorder")}
											/>
										</Col>
									</Row>
								</div>
							</Modal>

							<Modal
								centered
								title={"Добавление фильтра"}
								visible={showAdd}
								onCancel={handleCloseAddModal}
								footer={[
									<Button
										key="back"
										onClick={handleCloseAddModal}
									>
										Отмена
          							</Button>,
									<Button
										key="yes"
										type="primary"
										disabled={adding}
										onClick={handleAddFilter}
									>
										Добавить
          							</Button>,
								]}
							>
								<div>

									<Row gutter={16}>
										<Col className="gutter-row" style={{ textAlign: 'center' }} xs={24} sm={24}>
											<Typography style={{ textAlign: 'left' }}>Название:</Typography>
											<Input
												value={values.name}
												name="name"
												style={{ marginBottom: 10 }}
												onChange={handleChange("name")}
											/>
											<Typography style={{ textAlign: 'left' }}>Порядок сортировки:</Typography>
											<Input
												value={values.sortorder}
												name="sortorder"
												style={{ marginBottom: 10 }}
												onChange={handleChange("sortorder")}
											/>
										</Col>
									</Row>
								</div>
							</Modal>

						</div>
					] : null}
				</QueueAnim>
		);
	}
};

const useFetching = (getFilterList, dispatch) => {
	useEffect(() => {
		dispatch(getFilterList());
	}, []);
};

export default CPItemFilters;
