import {
    GET_WEB_SETTINGS,
    LOADING_WEB_SETTINGS,
    EDIT_WEB_SETTINGS,
    SAVED_WEB_SETTINGS,
    SHOW_ADD_BANNER_WEB_SETTINGS,
    CLOSE_ADD_BANNER_WEB_SETTINGS,
    BANNER_ADDING_WEB_SETTINGS,
    BANNER_ADDED_WEB_SETTINGS,
    BANNER_DELETED_WEB_SETTINGS,
    SHOW_ADD_BANNER_IMG_MODAL_WEB_SETTINGS,
    CLOSE_ADD_BANNER_IMG_MODAL_WEB_SETTINGS,
    BANNER_IMG_ADDING_WEB_SETTINGS,
    BANNER_IMG_ADDED_WEB_SETTINGS,
    BANNER_IMG_DELETED_WEB_SETTINGS,
    SHOW_BANNER_EDIT_WEB_SETTINGS,
    CLOSE_BANNER_EDIT_WEB_SETTINGS,
    BANNER_EDITED_WEB_SETTINGS,
    BANNER_EDITING_WEB_SETTINGS,
} from "../actions/types";

const initialState = {
    isLoading: true,
    settings: {},
    needSave: false,
    isShowAddBanner: false,
    bannerAdding: false,
    bannerToAddImg: {},
    isShowAddImgBanner: false,
    bannerIndexAddImg: null,
    bannerImgAdding: false,
    bannerToEdit: {},
    isShowBannerEdit: false,
    bannerEditing: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case BANNER_EDITING_WEB_SETTINGS:
            return{
                ...state,
                bannerEditing: true,
            }
        case BANNER_EDITED_WEB_SETTINGS:
            return{
                ...state,
                bannerEditing: false,
                settings: action.payload.settings,
                bannerToEdit: {},
                isShowBannerEdit: false,
            }
        case SHOW_BANNER_EDIT_WEB_SETTINGS:
            return{
                ...state,
                isShowBannerEdit: true,
                bannerToEdit: action.payload,
            }
        case CLOSE_BANNER_EDIT_WEB_SETTINGS:
            return{
                ...state,
                isShowBannerEdit: false,
                bannerToEdit: {}
            }

        case BANNER_IMG_DELETED_WEB_SETTINGS:
            return{
                ...state,
                settings: action.payload.settings,
                bannerImgAdding: false,
                bannerToAddImg: {},
                isShowAddImgBanner: false,
                bannerIndexAddImg: null,     
            }
        case BANNER_IMG_ADDING_WEB_SETTINGS:
            return{
                ...state,
                bannerImgAdding: true,
            }
        case BANNER_IMG_ADDED_WEB_SETTINGS:
            return{
                ...state,
                settings: action.payload.settings,
                bannerImgAdding: false,
                bannerToAddImg: {},
                isShowAddImgBanner: false,
                bannerIndexAddImg: null,
            }
        case SHOW_ADD_BANNER_IMG_MODAL_WEB_SETTINGS:
            return{
                ...state,
                bannerToAddImg: action.payload,
                isShowAddImgBanner: true,
                bannerIndexAddImg: action.index,
            }
        case CLOSE_ADD_BANNER_IMG_MODAL_WEB_SETTINGS:
            return{
                ...state,
                bannerToAddImg: {},
                isShowAddImgBanner: false,
                bannerIndexAddImg: null
            }
        case BANNER_DELETED_WEB_SETTINGS:
            return{
                ...state,
                settings: action.payload.settings,
            }
        case BANNER_ADDED_WEB_SETTINGS:
            return{
                ...state,
                settings: action.payload,
                bannerAdding: false,
                isShowAddBanner: false,
            }
        case BANNER_ADDING_WEB_SETTINGS:
            return{
                ...state,
                bannerAdding: true,
            }
        case SHOW_ADD_BANNER_WEB_SETTINGS:
            return{
                ...state,
                isShowAddBanner: true,
            }
        case CLOSE_ADD_BANNER_WEB_SETTINGS:
            return{
                ...state,
                isShowAddBanner: false,
            }
        case SAVED_WEB_SETTINGS:
            return{
                ...state,
                needSave: false
            }
        case EDIT_WEB_SETTINGS:
            return{
                ...state,
                settings: action.payload,
                needSave: true,
            }
        case GET_WEB_SETTINGS:
            return{
                ...state,
                isLoading: false,
                settings: action.payload.settings
            }

        case LOADING_WEB_SETTINGS:
            return{
                ...state,
                isLoading: true,
            }
        default:
            return state;
    }
}