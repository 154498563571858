import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  GET_REVIEW_LIST,
  LOADING_REVIEW,
  GET_REVIEW_ERRORS,
  REVIEW_EDITING,
  SHOW_ADD_REVIEW_MODAL,
  CLOSE_ADD_REVIEW_MODAL,
  SHOW_EDIT_REVIEW_MODAL,
  CLOSE_EDIT_REVIEW_MODAL,
  REVIEW_EDITED,
  REVIEW_ADDED,
  REVIEW_ADDING,
  REVIEW_DELETED,
} from "./types";


export const showAddReviewModal = () => {
  return {
    type: SHOW_ADD_REVIEW_MODAL
  };
};

export const closeAddReviewModal = () => {
  return {
    type: CLOSE_ADD_REVIEW_MODAL
  };
};

export const showEditReviewModal = (review) => {
  return {
    type: SHOW_EDIT_REVIEW_MODAL,
    payload: review
  };
};

export const closeEditReviewModal = () => {
  return {
    type: CLOSE_EDIT_REVIEW_MODAL
  };
};

export const setReviewEditing = () => {
  return {
    type: REVIEW_EDITING
  };
};

export const setReviewAdding = () => {
  return {
    type: REVIEW_ADDING
  };
};

export const addReview = (review) => dispatch => {
  dispatch(setReviewAdding());
  axios
    .post(serverIp + "api/review/addreview", review)
    .then(res => {
      dispatch({
        type: REVIEW_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_REVIEW_ERRORS,
        payload: err.response
      })
    );
};

export const deleteReview = (review) => dispatch => {
  axios
    .post(serverIp + "api/review/deletereview", review)
    .then(res => {
      dispatch({
        type: REVIEW_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_REVIEW_ERRORS,
        payload: err.response
      })
    );
};

export const editReview = (review) => dispatch => {
  dispatch(setReviewEditing());
  axios
    .post(serverIp + "api/review/editreview", review)
    .then(res => {
      dispatch({
        type: REVIEW_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_REVIEW_ERRORS,
        payload: err.response
      })
    );
};

// получаем отзывы
export const getReviewList = () => dispatch => {
  dispatch(setReviewLoading());
  axios
    .get(serverIp + "api/review/getreviewlist")
    .then(res => {
      dispatch({
        type: GET_REVIEW_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_REVIEW_ERRORS,
        payload: err.response
      })
    );
};

// Загружаются отзывы
export const setReviewLoading = () => {
  return {
    type: LOADING_REVIEW
  };
};
