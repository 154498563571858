import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  GET_ITEM_RADIO_OPTION_LIST,
  ITEM_RADIO_OPTION_LIST_LOADING,
  CLOSE_OP_RADIO_ITEM_MODAL,
  SHOW_OP_RADIO_ITEM_MODAL,
  ITEM_RADIO_ADDED,
  ITEM_RADIO_DELETED,

  GET_ITEM_CHECKBOX_OPTION_LIST,
  ITEM_CHECKBOX_OPTION_LIST_LOADING,
  SHOW_OP_CHECKBOX_ITEM_MODAL,
  CLOSE_OP_CHECKBOX_ITEM_MODAL,


  GET_ITEM_OPTION_LIST,
  ITEM_OPTION_LIST_LOADING,
  SHOW_OP_ITEM_MODAL,
  CLOSE_OP_ITEM_MODAL,
  GET_ITEM_LIST,
  LOADING_ITEMS,
  GET_ITEM_ERRORS,
  SHOW_ADD_ITEM_MODAL,
  CLOSE_ADD_ITEM_MODAL,
  SHOW_DEL_ITEM_MODAL,
  CLOSE_DEL_ITEM_MODAL,
  ITEM_DELETED,
  SHOW_ED_ITEM_MODAL,
  CLOSE_ED_ITEM_MODAL,
  ITEM_EDITED,
  ITEM_ADDED,
  DELETING_ITEM,
  ADDING_ITEM,
  SHOW_ADD_IMG_MODAL,
  CLOSE_ADD_IMG_MODAL,
  ITEM_IMG_ADDED,
  ITEM_IMG_DELETED,
  IMG_BTN_DISABLE,
  CLOSE_OP_ITEM_MODAL_DEL,
  SHOW_OP_ITEM_MODAL_DEL,
  ITEM_OPTION_ADDED,
  ITEM_OPTION_DELETED,
  OPTION_ADDING,
  OPTION_DELETING,
  SHOW_EDIT_FILTERITEM_MODAL,
  CLOSE_EDIT_FILTERITEM_MODAL,
  EDITED_FILTERITEM,
  GET_ITEM_LIST_SORTED,
  ITEM_TABLE_LOADING
} from "./types";

export const sortItems = (drag, hover) => dispatch => {
  dispatch(setTableLoading());
  axios
    .post(serverIp+"api/items/sortitemlist", {drag:drag, hover:hover})
    .then(res => {
      dispatch({
        type: GET_ITEM_LIST_SORTED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ITEM_ERRORS,
        payload: err.response
      })
    );
}
export const setTableLoading = () => {
  return {
    type: ITEM_TABLE_LOADING
  };
};

// получаем товары
export const getItemList = () => dispatch => {
  dispatch(setItemsLoading());
  axios
    .get(serverIp+"api/items/getitemslist")
    .then(res => {
      dispatch({
        type: GET_ITEM_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ITEM_ERRORS,
        payload: err.response
      })
    );
};

export const deleteItem = (item) => dispatch => {
  axios
    .post(serverIp+"api/items/deleteitem", item)
    .then(res => {
      dispatch({
        type: ITEM_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ITEM_ERRORS,
        payload: err.response
      })
    );
};

export const addItem = (item) => dispatch => {
  console.log(item);
  item.base_price = item.price;
  dispatch(setItemsAdding());
  axios
    .post(serverIp+"api/items/additem", item)
    .then(res => {
      dispatch({
        type: ITEM_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ITEM_ERRORS,
        payload: err.response
      })
    );
  };

export const addItemOption = (option) => dispatch => {
  console.log(option);
  console.log('addItemOption');
  dispatch(setOptionAdding());
  axios
    .post(serverIp+"api/items/additemoption", option)
    .then(res => {
      dispatch({
        type: ITEM_OPTION_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ITEM_ERRORS,
        payload: err.response
      })
    );
};
export const delItemOption = (optiontodelete) => dispatch => {
  dispatch(setOptionDeleting());
  axios
    .post(serverIp+"api/items/deleteitemoption", optiontodelete)
    .then(res => {
      dispatch({
        type: ITEM_OPTION_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ITEM_ERRORS,
        payload: err.response
      })
    );
};
export const editItem = (item) => dispatch => {
  item.base_price = item.price;
  axios
    .post(serverIp+"api/items/edititem", item)
    .then(res => {
      dispatch({
        type: ITEM_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ITEM_ERRORS,
        payload: err.response
      })
    );
  };

export const sendItemImageDelete = (data) => dispatch => {
  dispatch(setImgDisable());
  axios
    .post(serverIp+"api/items/deleteitemimg", data)
    .then(res => {
      dispatch({
        type: ITEM_IMG_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ITEM_ERRORS,
        payload: err.response
      })
    );
}

export const sendItemImage = (data) => dispatch => {
  dispatch(setImgDisable());
  console.log(data);
  axios
    .post(serverIp+"api/items/additemimg", data)
    .then(res => {
      dispatch({
        type: ITEM_IMG_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ITEM_ERRORS,
        payload: err.response
      })
    );

}

export const showOpItemModal = (item) => dispatch => {
  dispatch(setItemsOptionLoading());
  dispatch(showOpItemModalShow(item));
  axios
    .get(serverIp+"api/items/getitemsoptionlist")
    .then(res => {
      dispatch({
        type: GET_ITEM_OPTION_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ITEM_ERRORS,
        payload: err.response
      })
    );
};

// ЧЕКБОКСЫ
export const showOpCheckboxItemModal = (item) => dispatch => {
  dispatch(setItemsOptionCheckboxLoading());
  dispatch(showOpCheckboxItemModalShow(item));
  axios
    .get(serverIp+"api/items/getitemscheckboxoptions")
    .then(res => {
      dispatch({
        type: GET_ITEM_CHECKBOX_OPTION_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ITEM_ERRORS,
        payload: err.response
      })
    );
};
export const closeOpCheckboxItemModal = () => {
  return {
    type: CLOSE_OP_CHECKBOX_ITEM_MODAL
  };
};
export const setItemsOptionCheckboxLoading = () => {
  return {
    type: ITEM_CHECKBOX_OPTION_LIST_LOADING
  };
};
export const showOpCheckboxItemModalShow = (item) => {
  return {
    type: SHOW_OP_CHECKBOX_ITEM_MODAL,
    payload: item
  };
};
//////////////

// RADIO
export const delRowToCurrent = (item, optiongroup, optionrow) => dispatch => {
  axios
  .post(serverIp+"api/items/delrowitemradio", {"item_id":item._id, "option":optiongroup.option_parent_id, "rowtodel":optionrow.name})
  .then(res => {
    dispatch({
      type: ITEM_RADIO_DELETED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_ITEM_ERRORS,
      payload: err.response
    })
  );
};

export const addRowToCurrent = (item, option) => dispatch => {
  console.log(option);
  axios
  .post(serverIp+"api/items/addrowitemradio", {"item_id":item._id, "option":option})
  .then(res => {
    dispatch({
      type: ITEM_RADIO_ADDED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_ITEM_ERRORS,
      payload: err.response
    })
  );
};

export const deleteRadioGroupFromItem = (item, option) => dispatch => {
  axios
  .post(serverIp+"api/items/delitemradio", {"item_id":item._id, "option":option})
  .then(res => {
    dispatch({
      type: ITEM_RADIO_DELETED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_ITEM_ERRORS,
      payload: err.response
    })
  );
};

export const addRadioToItem = (item, option) => dispatch => {
  console.log('adding radio to');
  console.log(item);
  console.log(option);
  axios
  .post(serverIp+"api/items/additemradio", {"item":item, "option":option})
  .then(res => {
    dispatch({
      type: ITEM_RADIO_ADDED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_ITEM_ERRORS,
      payload: err.response
    })
  );
};

export const showOpRadioItemModal = (item) => dispatch => {
  dispatch(setItemsOptionRadioLoading());
  dispatch(showOpRadioItemModalShow(item));
  axios
    .get(serverIp+"api/items/getitemsradiooptions")
    .then(res => {
      dispatch({
        type: GET_ITEM_RADIO_OPTION_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ITEM_ERRORS,
        payload: err.response
      })
    );
};
export const closeOpRadioItemModal = () => {
  return {
    type: CLOSE_OP_RADIO_ITEM_MODAL
  };
};
export const setItemsOptionRadioLoading = () => {
  return {
    type: ITEM_RADIO_OPTION_LIST_LOADING
  };
};
export const showOpRadioItemModalShow = (item) => {
  return {
    type: SHOW_OP_RADIO_ITEM_MODAL,
    payload: item
  };
};
//////////////

// СТОП ЛИСТЫ / КОЛ_ВО
export const setStopList = (item) => dispatch => {
  console.log(item);
  axios
    .post(serverIp+"api/items/setstoplist", {id:item})
    .then(res => {
      dispatch({
        type: ITEM_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ITEM_ERRORS,
        payload: err.response
      })
    );
};
///////////////


export const setOptionAdding = () => {
  return {
    type: OPTION_ADDING
  };
};
export const setOptionDeleting = () => {
  return {
    type: OPTION_DELETING
  };
};
export const closeOpItemModalDel = () => {
  return {
    type: CLOSE_OP_ITEM_MODAL_DEL
  };
};
export const showOpItemModalDel = (item) => {
  return {
    type: SHOW_OP_ITEM_MODAL_DEL,
    payload: item
  };
};

export const showOpItemModalShow = (item) => {
  return {
    type: SHOW_OP_ITEM_MODAL,
    payload: item
  };
};
export const setItemsOptionLoading = () => {
  return {
    type: ITEM_OPTION_LIST_LOADING
  };
};
export const closeOpItemModal = () => {
  return {
    type: CLOSE_OP_ITEM_MODAL
  };
};
export const showAddImgItemModal = (item) => {
  return {
    type: SHOW_ADD_IMG_MODAL,
    payload: item
  };
};
export const closeAddImgItemModal = () => {
  return {
    type: CLOSE_ADD_IMG_MODAL
  };
};

export const setItemsLoading = () => {
  return {
    type: LOADING_ITEMS
  };
};
export const setItemsAdding = () => {
  return {
    type: ADDING_ITEM
  };
};
export const setItemsDeleting = () => {
  return {
    type: DELETING_ITEM
  };
};

export const showAddItemModal = () => {
  return {
    type: SHOW_ADD_ITEM_MODAL
  };
};
export const closeAddItemModal = () => {
  return {
    type: CLOSE_ADD_ITEM_MODAL
  };
};


export const showDelItemModal = (item) => {
  return {
    type: SHOW_DEL_ITEM_MODAL,
    payload: item
  };
};

export const closeDelItemModal = () => {
  return {
    type: CLOSE_DEL_ITEM_MODAL
  };
};

export const showEdItemModal = (item) => {
  return {
    type: SHOW_ED_ITEM_MODAL,
    payload: item
  };
};

export const closeEdItemModal = () => {
  return {
    type: CLOSE_ED_ITEM_MODAL
  };
};

export const setImgDisable = () => {
  return {
    type: IMG_BTN_DISABLE
  };
};

// ФИЛЬТРЫ

export const showEditFilter = (item) => {
  return {
    type: SHOW_EDIT_FILTERITEM_MODAL,
    payload: item
  };
};
export const closeEditFilter = () => {
  return {
    type: CLOSE_EDIT_FILTERITEM_MODAL
  };
};
export const editFilter = (item) => {
//EDITED_FILTERITEM
};
