import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  GET_API_SETTINGS,
  LOADING_API_SETTINGS,
  GET_API_SETTINGS_ERRORS,
  EDIT_API_SETTINGS,
  SAVED_API_SETTINGS,
  SAVED_API_SETTINGS_ERRORS,
  EDIT_API_SETTINGS_CLIENT_STATUS,
  EDIT_API_SETTINGS_CRM_STATUS,
  EDIT_API_SETTINGS_ORDER_STATUS
} from "./types";

// получаем настройки
export const getApiSettings = () => dispatch => {
  dispatch(setApiSettingsLoading());
  axios
    .get(serverIp + "api/apisettings/getsettings")
    .then(res => {
      dispatch({
        type: GET_API_SETTINGS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_API_SETTINGS_ERRORS,
        payload: err.response
      })
    );
};

export const editApiSettings = (data) => dispatch => {
  dispatch({
    type: EDIT_API_SETTINGS,
    payload: data
  });
};

export const editClientStatus = (array) => dispatch => {
  dispatch({
    type: EDIT_API_SETTINGS_CLIENT_STATUS,
    payload: array
  });
};
export const editCrmStatus = (array) => dispatch => {
  dispatch({
    type: EDIT_API_SETTINGS_CRM_STATUS,
    payload: array
  });
};
export const editOrderStatus = (array) => dispatch => {
  dispatch({
    type: EDIT_API_SETTINGS_ORDER_STATUS,
    payload: array
  });
};

export const saveApiSettings = (setdata) => dispatch => {
  axios
    .put(serverIp + "api/apisettings/setsettings", setdata)
    .then(res => {
      dispatch({
        type: SAVED_API_SETTINGS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: SAVED_API_SETTINGS_ERRORS,
        payload: err.response
      })
    );
};

// Загружаются настройки
export const setApiSettingsLoading = () => {
  return {
    type: LOADING_API_SETTINGS
  };
};
