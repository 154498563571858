import {
  GET_DELIVERY_LIST,
  LOADING_DELIVERY,
  GET_DELIVERY_ERRORS,
  DELIVERY_EDITING,
  SHOW_ADD_DELIVERY_MODAL,
  CLOSE_ADD_DELIVERY_MODAL,
  DELIVERY_ADDED,
  DELIVERY_ADDING,
  DELIVERY_DELETED,
  DELIVERY_EDITED,
  SHOW_EDIT_DELIVERY_MODAL,
  CLOSE_EDIT_DELIVERY_MODAL,
} from "../actions/types";

const initialState = {
  isLoading: true,
  deliveries: [],
  editing:false,
  showAdd: false,
  showEdit: false,
  adding:false,
  canBeOrdered: true,
  disableOrderTime: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CLOSE_EDIT_DELIVERY_MODAL:
      return{
        ...state,
        editing: false,
        showEdit: false,
      }
    case SHOW_EDIT_DELIVERY_MODAL:
      return{
        ...state,
        showEdit: true,
      }
    case DELIVERY_EDITED:
        const updatedState = state.deliveries.map((delivery) => {
          if(delivery._id === action.payload.editeddelivery._id){
            return {...delivery, ...action.payload.editeddelivery};
          } else {
          }
          return delivery;
        });
        return {
          ...state, 
          deliveries: updatedState,
          showEdit: false,
          editing: false,
        };
    case DELIVERY_EDITING:
      return{
        ...state,
        editing: true,
      };
    case DELIVERY_DELETED:
      const id = action.payload.deleteddelivery._id;
      return {
        ...state,
        deliveries: state.deliveries.filter((delivery) => delivery._id !== id),
      };
    case DELIVERY_ADDING:
      return{
        ...state,
        adding:true,
      };    
    case DELIVERY_ADDED:
      return{
        ...state,
        deliveries: [...state.deliveries, action.payload.newdelivery],
        showAdd:false,
        adding:false,
      };
    case SHOW_ADD_DELIVERY_MODAL:
      return{
        ...state,
        showAdd: true,
      };
    case CLOSE_ADD_DELIVERY_MODAL:
      return{
        ...state,
        showAdd: false
      };
    case GET_DELIVERY_LIST:
      return {
      ...state,
      isLoading: false,
      deliveries: action.payload.delivery,
      canBeOrdered: action.payload.stopsettings.canBeOrdered,
      disableOrderTime: action.payload.stopsettings.disableOrderTime,
      };
    case LOADING_DELIVERY:
      return {
      ...state,
      isLoading: true,
      };
    default:
      return state;
  }
}
