import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";
 
// guards 
import NotAuth from "./components/guards/NotAuth";
import IsAuth from "./components/guards/IsAuth";
import IsModerator from "./components/guards/IsModerator";
import IsAdmin from "./components/guards/IsAdmin";
import IsManager from "./components/guards/IsManager";

import Login from './components/sys-pages/Login';
import CPSettings from './components/cp-pages/CPSettings';
import CPDashboard from './components/cp-pages/CPDashboard';
import CPItemList from './components/cp-pages/CPItemList';
import CPItemOptions from './components/cp-pages/CPItemOptions';
import CPItemFilters from './components/cp-pages/CPItemFilters';
import CPCategoryList from './components/cp-pages/CPCategoryList';
import CPSales from './components/cp-pages/CPSales';
import CPDelivery from './components/cp-pages/CPDelivery';
import CPPayment from './components/cp-pages/CPPayment';
import CPIntegrations from './components/cp-pages/CPIntegrations';
import CPCustomers from './components/cp-pages/CPCustomers';
import CPUsers from './components/cp-pages/CPUsers';
import CPApiSettings from './components/cp-pages/CPApiSettings';
import CPBonusSystem from './components/cp-pages/CPBonusSystem';
import CPReviews from './components/cp-pages/CPReviews';
import CPReport from './components/cp-pages/CPReport';

import CPTimesheet from './components/cp-pages/CPTimesheet';

import CPNews from './components/cp-pages/CPNews';
import CPPromotions from './components/cp-pages/CPPromotions';

import CPEmailSettings from './components/cp-pages/CPEmailSettings';

import CPFeedback from './components/cp-pages/CPFeedback';
import CPReserve from './components/cp-pages/CPReserve';

import CPPrintSettings from './components/cp-pages/CPPrintSettings';

import CPArticles from './components/cp-pages/CPArticles';
import CPSeoPages from './components/cp-pages/CPSeoPages';
import CPWebStaticPage from './components/cp-pages/CPWebStaticPage';
import CPWebSettings from './components/cp-pages/CPWebSettings';

import CPCouriers from './components/cp-pages/CPCouriers';

import CPCourierInfo from './components/cp-pages/CPCourierInfo';

import CPCartGifts from './components/cp-pages/CPCartGifts';
import CPDeliveryServices from './components/cp-pages/CPDeliveryServices';

import CPPushSend from './components/cp-pages/CPPushSend';

import CPCoupons from './components/cp-pages/CPCoupons';

import CPiiko from './components/cp-pages/CPiiko';
import CPSumoIiko from './components/cp-pages/CPSumoIiko';

import CPNomenclature from './components/cp-pages/CPNomenclature';
import CPComplect from './components/cp-pages/CPComplect';

import SideMenu from './components/menu/SideMenu';
import TopMenu from './components/menu/TopMenu';
import { Layout } from 'antd';
import SocketComponent from './utils/socket'

import "./App.css";

const { Header, Footer, Sider, Content } = Layout;

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
  }
}

 
function App() {
  return (
    <Provider store={store}>
      <SocketComponent />
      <Router>

        <Layout style={{ minHeight: "100vh" }} className="baseSection">
          <Switch>
            <IsAuth path="/" component={SideMenu} />
          </Switch>
          <Switch>
            <NotAuth path="/" component={Login} />
          </Switch>
          <Layout className="baseWindow">
              <Switch>
                <IsAuth path="/" component={TopMenu} />
              </Switch>
              <Content className="content-layout">
              <Switch>
                <IsAuth path="/cp/dashboard" component={CPDashboard} />
                <IsAuth path="/cp/report" component={CPReport} />
                

                <IsAuth path="/cp/reviews" component={CPReviews} />
                <IsAuth path="/cp/timesheet" component={CPTimesheet} />
                <IsAuth path="/cp/sales" component={CPSales} />
                <IsAuth path="/cp/itemlist" component={CPItemList} />
                <IsAuth path="/cp/itemoptions" component={CPItemOptions} />
                <IsAuth path="/cp/itemfilters" component={CPItemFilters} />
                <IsAuth path="/cp/caterogylist" component={CPCategoryList} />
                <IsAuth path="/cp/settings" component={CPSettings} />
                <IsAuth path="/cp/delivery" component={CPDelivery} />
                <IsAuth path="/cp/payment" component={CPPayment} />
                <IsAuth path="/cp/integrations" component={CPIntegrations} />
                <IsAuth path="/cp/customers" component={CPCustomers} />
                <IsAuth path="/cp/users" component={CPUsers} />
                <IsAuth path="/cp/feedback" component={CPFeedback} />
                <IsAuth path="/cp/reserve" component={CPReserve} />
                <IsAuth path="/cp/printsettings" component={CPPrintSettings} />
                <IsAuth path="/cp/apisettings" component={CPApiSettings} />
                <IsAuth path="/cp/articles" component={CPArticles} />
                <IsAuth path="/cp/seopages" component={CPSeoPages} />
                
                <IsAuth path="/cp/bonus" component={CPBonusSystem} />
                <IsAuth path="/cp/webstaticpages" component={CPWebStaticPage} />
                <IsAuth path="/cp/websettings" component={CPWebSettings} />
                <IsAuth path="/cp/news" component={CPNews} />
                <IsAuth path="/cp/promotions" component={CPPromotions} />
                <IsAuth path="/cp/emailsettings" component={CPEmailSettings} />
                <IsAuth path="/cp/couriers" component={CPCouriers} />
                <IsAuth path="/cp/courier-info" component={CPCourierInfo} />
                <IsAuth path="/cp/cartgifts" component={CPCartGifts} />
                <IsAuth path="/cp/deliveryservices" component={CPDeliveryServices} />
                <IsAuth path="/cp/pushsend" component={CPPushSend} />
                <IsAuth path="/cp/coupons" component={CPCoupons} /> 
                <IsAuth path="/cp/iiko" component={CPiiko} />
                <IsAuth path="/cp/sumoiiko" component={CPSumoIiko} />   
                <IsAuth path="/cp/nomenclature" component={CPNomenclature} />     
                <IsAuth path="/cp/complects" component={CPComplect} /> 
              </Switch>
              </Content>
            <Footer style={{ textAlign: 'center' }}>Версия - 0.5.9.14</Footer>
          </Layout>

        </Layout>

      </Router>
    </Provider>
  );
}

export default App;
