import {
    GET_PROMOTION_LIST,
    LOADING_PROMOTION,
    GET_PROMOTION_ERRORS,
    PROMOTION_EDITING,
    SHOW_ADD_PROMOTION_MODAL,
    CLOSE_ADD_PROMOTION_MODAL,
    SHOW_EDIT_PROMOTION_MODAL,
    CLOSE_EDIT_PROMOTION_MODAL,
    PROMOTION_EDITED,
    PROMOTION_ADDED,
    PROMOTION_ADDING,
    PROMOTION_DELETED,
    SHOW_ADD_IMG_PROMOTION_MODAL,
    CLOSE_ADD_IMG_PROMOTION_MODAL,
    PROMOTION_IMG_DELETED,
    PROMOTION_IMG_ADDED
  } from "../actions/types";
  
  const initialState = {
    isLoading: true,
    promotions: [],
    isLoading: true,
    editing: false,
    showAdd: false,
    showEdit: false,
    adding: false,
    showAddImg: false,
    imgUploading: false,
    imgBtnDisable: false,
    promotionsAddPhoto: {}
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
  
      case PROMOTION_IMG_DELETED:
        const updatedDelImgState = state.promotions.map((promotions) => {
          if (promotions._id === action.payload.promotions._id) {
            return { ...promotions, ...action.payload.promotions };
          } else {
          }
          return promotions;
        });
        return {
          ...state,
          promotions: updatedDelImgState,
          imgBtnDisable: false
        };
      case PROMOTION_IMG_ADDED:
        const updatedAddImgState = state.promotions.map((promotions) => {
          if (promotions._id === action.payload.promotions._id) {
            return { ...promotions, ...action.payload.promotions };
          } else {
          }
          return promotions;
        });
        return {
          ...state,
          promotions: updatedAddImgState,
          imgBtnDisable: false
        };
  
      case SHOW_ADD_IMG_PROMOTION_MODAL:
        return {
          ...state,
          isLoading: false,
          showAddImg: true,
          promotionsAddPhoto: action.payload
        };
      case CLOSE_ADD_IMG_PROMOTION_MODAL:
        return {
          ...state,
          isLoading: false,
          showAddImg: false,
          promotionsAddPhoto: {},
        };
      case PROMOTION_EDITED:
        const updatedState = state.promotions.map((promotions) => {
          if (promotions._id === action.payload.editedpromotions._id) {
            return { ...promotions, ...action.payload.editedpromotions };
          } else {
          }
          return promotions;
        });
        return {
          ...state,
          promotions: updatedState,
          showEdit: false,
          editing: false,
        };
      case PROMOTION_EDITING:
        return {
          ...state,
          editing: true,
        };
  
      case CLOSE_EDIT_PROMOTION_MODAL:
        return {
          ...state,
          editing: false,
          showEdit: false,
        }
      case SHOW_EDIT_PROMOTION_MODAL:
        return {
          ...state,
          showEdit: true,
        }
  
      case PROMOTION_DELETED:
        const id = action.payload.deletedpromotions._id;
        return {
          ...state,
          promotions: state.promotions.filter((promotions) => promotions._id !== id),
        };
      case PROMOTION_ADDING:
        return {
          ...state,
          adding: true,
        };
      case PROMOTION_ADDED:
        return {
          ...state,
          promotions: [...state.promotions, action.payload.newpromotions],
          showAdd: false,
          adding: false,
        };
      case SHOW_ADD_PROMOTION_MODAL:
        return {
          ...state,
          showAdd: true,
        };
      case CLOSE_ADD_PROMOTION_MODAL:
        return {
          ...state,
          showAdd: false
        };
      case GET_PROMOTION_LIST:
        return {
          ...state,
          isLoading: false,
          promotions: action.payload.promotions,
        };
      case LOADING_PROMOTION:
        return {
          ...state,
          isLoading: true,
        };
      default:
        return state;
    }
  }
  