import axios from "axios";
import serverIp from "../utils/serverIp";

import {
    RESERVE_LIST_LOADED,
    RESERVE_LIST_LOADING,
    GET_RESERVE_ERRORS,
    NEW_RESERVE_NEED_TO_BE_LOAD,
    GET_NEW_RESERVE,
    RESERVE_EDITED
} from "./types";


export const confirmReserve = (rowId) => dispatch => {
  axios
  .post(serverIp+"api/reserve/confirmreserve", {reserveId: rowId})
  .then(res => {
    dispatch({
      type: RESERVE_EDITED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_RESERVE_ERRORS,
      payload: err.response
    })
  );
}

export const getReserveList = () => dispatch => {
    dispatch(setReserveLoading());
    axios
      .get(serverIp+"api/reserve/getreservelist")
      .then(res => {
        dispatch({
          type: RESERVE_LIST_LOADED,
          payload: res.data
        });
      })
      .catch(err =>
        dispatch({
          type: GET_RESERVE_ERRORS,
          payload: err.response
        })
      );
  };


export const setReserveLoading = () => {
    return {
        type: RESERVE_LIST_LOADING
    };
};

export const updateReserveList = (reserveId) => dispatch => {
  dispatch({
    type: NEW_RESERVE_NEED_TO_BE_LOAD,
    payload: reserveId
  })
};

export const getNewReserve = (reserveId) => dispatch => {
  axios
    .post(serverIp + "api/reserve/getnewreserve", { reserveId: reserveId })
    .then(res => {
      dispatch({
        type: GET_NEW_RESERVE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_RESERVE_ERRORS,
        payload: err.response
      })
    );
};