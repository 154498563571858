import {
    REGISTERED_PUSH_SENDED,
    ANONYMOUS_PUSH_SENDED,
    PUSH_MESSAGES_SENDING,
  } from "../actions/types";
  
  const initialState = {
    sending: false,
    reg_accepted:0,
    reg_rejected:0,
    unreg_accepted:0,
    unreg_rejected:0
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case REGISTERED_PUSH_SENDED:
        console.log('REGISTERED_PUSH_SENDED');
        console.log(action.payload)
        return {
          ...state,
          sending: false,
          reg_accepted:action.payload.accepted,
          reg_rejected:action.payload.rejected
        };
      case ANONYMOUS_PUSH_SENDED:
        console.log('ANONYMOUS_PUSH_SENDED');
        console.log(action.payload)
        return {
            ...state,
            sending: false,
            unreg_accepted:action.payload.accepted,
            unreg_rejected:action.payload.rejected
          };
    case PUSH_MESSAGES_SENDING:
        return{
            ...state,
            sending: true,
            reg_accepted:0,
            reg_rejected:0,
            unreg_accepted:0,
            unreg_rejected:0
        }
      default:
        return state;
    }
  }
  