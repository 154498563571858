import axios from "axios";
import serverIp from "../utils/serverIp";

import {
  REPORT_BASE_LOADED,
  REPORT_BASE_LOADING,
  REPORT_ITEMS_LOADED,
  REPORT_ITEMS_LOADING,
  REPORT_GET_ERRORS
} from "./types";


export const getItemReport = () => dispatch => {
  dispatch(setItemReportLoading());
  axios
    .get(serverIp + "api/report/getbaseitemreport")
    .then(res => {
      dispatch({
        type: REPORT_ITEMS_LOADED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: REPORT_GET_ERRORS,
        payload: err.response
      })
    );
};

export const setItemReportLoading = () => {
  return {
    type: REPORT_ITEMS_LOADING
  };
};

export const getBaseReport = () => dispatch => {
  dispatch(setBaseReportLoading());
  axios
    .get(serverIp + "api/report/getbasereport")
    .then(res => {
      dispatch({
        type: REPORT_BASE_LOADED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: REPORT_GET_ERRORS,
        payload: err.response
      })
    );
};

export const setBaseReportLoading = () => {
  return {
    type: REPORT_BASE_LOADING
  };
};
