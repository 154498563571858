import {
    RESERVE_LIST_LOADED,
    RESERVE_LIST_LOADING,
    NEW_RESERVE_NEED_TO_BE_LOAD,
    GET_NEW_RESERVE,
    RESERVE_EDITED
} from "../actions/types";

const initialState = {
    isLoading: true,
    reserves: [],
    needToUpdate: false,
    reserveToLoad:'',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case RESERVE_EDITED:
            console.log('RESERVE_EDITED');

              return{
                ...state,
                needToUpdate: false,
                reserveToLoad: null,
                reserves: state.reserves.map(reserve => reserve._id === action.payload.newreserve._id ?
                    { ...reserve, ...action.payload.newreserve } :
                    reserve
                  )
              }

        case GET_NEW_RESERVE:
            console.log('GET_NEW_RESERVE');
            console.log(action.payload);
            return{
              ...state,
              reserves: [action.payload.newreserve, ...state.reserves],
              needToUpdate: false,
              reserveToLoad: null,
            }
        case NEW_RESERVE_NEED_TO_BE_LOAD:
            console.log('NEW_RESERVE_NEED_TO_BE_LOAD');
            console.log(action.payload);
            return{
              ...state,
              needToUpdate: true,
              reserveToLoad: action.payload,
            }
        case RESERVE_LIST_LOADED:
            return {
                ...state,
                reserves: action.payload.reserves,
                isLoading: false,
            }
        case RESERVE_LIST_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        default:
            return state;
    }
}
