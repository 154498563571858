import axios from "axios";
import serverIp from "../utils/serverIp";

import {
    GET_COURIER_LIST,
    GET_COURIER_ORDER_LIST,
    GET_COURIERS_ORDERS,
    SET_ORDER_DELIVERED,
    UPDATE_COURIER_ORDER
} from "./types";

export const getCourierList = () => dispatch => {
    axios
        .get(serverIp + "api/couriers/getcourierlist")
        .then(res => {
            dispatch({
                type: GET_COURIER_LIST,
                payload: res.data
            });
        })
};

export const getOrderList = (courier) => dispatch => {
    axios
        .post(serverIp + "api/couriers/getorderlist", {courier:courier})
        .then(res => {
            dispatch({
                type: GET_COURIER_ORDER_LIST,
                payload: res.data
            });
        })
};


export const updateCourierOrder = (order_id) => dispatch => {
    axios
        .post(serverIp + "api/couriers/getupdatedorder", {order_id:order_id})
        .then(res => {
            dispatch({
                type: UPDATE_COURIER_ORDER,
                payload: res.data
            });
        })
};

export const updateCourierOrderList = (courier_id) => dispatch => {
    console.log('updateCourierOrderList');
    dispatch(getCourierOrders(courier_id, "today"));
}

export const getCourierOrders = (courier, period) => dispatch => {
    console.log(courier);
    console.log(period);
    axios
        .post(serverIp + "api/couriers/getcourierorders", {courier:courier, period:period})
        .then(res => {
            dispatch({
                type: GET_COURIERS_ORDERS,
                payload: res.data
            });
        })
};

export const setOrderDelivered = (order, courier) => dispatch => {
    axios
        .post(serverIp + "api/couriers/setorderdelivered", {order:order._id, courier:courier})
        .then(res => {
            dispatch({
                type: SET_ORDER_DELIVERED,
                payload: res.data
            });
        })
};

