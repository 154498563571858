import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Row, Col, Divider, Spin, Card, Icon, Layout, Table, Button, Modal, Typography, Popconfirm, Tag, Select } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
    getCourierList,
    getOrderList,
    //deleteFeedback
} from "../../actions/couriersActions";
import Moment from 'react-moment';
import 'moment/locale/ru';

const { Option } = Select;
const CPCouriers = props => {
    useFetching(getCourierList, useDispatch());
    const dispatch = useDispatch();

    const courierList = useSelector(state => state.couriers.courierList);
    const orderList = useSelector(state => state.couriers.orderList);
    const loading = useSelector(state => state.couriers.isLoading);

    // const handleDelFeedback = (feedback) => {
    // 	dispatch(deleteFeedback(feedback._id));
    // };

    // function renderFeedbackInfo(feedback) {
    // 	return (
    // 		<>
    // 			<Row gutter={16}>
    // 				<Col className="gutter-row" xs={24}>
    // 				<div key={feedback.id}>
    // 					<div>
    // 						<span><b>Имя:</b></span><br />
    // 						<span>{feedback.name}</span><br />

    // 						<br />
    // 						<span><b>Телефон:</b></span><br />
    // 						<span>{feedback.phone}</span><br />

    // 						<br />
    // 						<span><b>E-mail:</b></span><br />
    // 						<span>{feedback.email}</span><br />

    // 						<br />
    // 						<span><b>Текст:</b></span><br />
    // 						<span>{feedback.text}</span><br />

    // 					</div>
    // 				</div>
    // 				</Col>
    // 			</Row>
    // 			<Divider/>
    // 			<Row gutter={16}>
    // 				<Col className="gutter-row" xs={24}>
    // 					<Popconfirm title="Удалить обращение?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelFeedback(feedback)}>
    // 						<Button style={{ marginRight: 5 }} size="small" type="danger" ghost icon="delete">УДАЛИТЬ</Button>
    // 					</Popconfirm>
    // 				</Col>
    // 			</Row>
    // 		</>
    // 	);
    // }

    function renderSort() {

        // const columns = [
        // 	{ title: 'Имя', dataIndex: 'name', key: 'name' },
        // 	{ title: 'Телефон', dataIndex: 'phone', key: 'phone' },
        // 	{ title: 'E-mail', dataIndex: 'email', key: 'email' },
        // 	{
        // 		title: 'Дата', dataIndex: 'created_at', key: 'created_at',
        // 		render: time => {
        // 			let timestamp = Date.parse(time);
        // 			var date = new Date(timestamp);
        // 			var formattedDate = ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ' ' + ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
        // 			return (
        // 				<Tag>{formattedDate}</Tag>
        // 			);
        // 		}
        // 	}
        // ];

        // if (feedbacks.length > 0) {
        // 	return (
        // 		<Table
        // 			rowKey={record => record._id}
        // 			style={{ marginTop: 20 }}
        // 			columns={columns}
        // 			expandedRowRender={record => renderFeedbackInfo(record)}
        // 			dataSource={feedbacks}
        // 		/>
        // 	);
        // } else {
        // 	return (
        // 		<div>
        // 			<Card title="Обращений не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
        // 				<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
        // 			</Card>
        // 		</div>
        // 	);
        // }
    }

    //const [orderToAddCourier, setOrderToAddCourier] = React.useState({});
    const [courierToShow, setCourierToShow] = React.useState({});
    const [isCourierSelected, setIsCourierSelected] = React.useState(false);

    const handleSelectCourierToShow = (value) => {
        setIsCourierSelected(true);
        setCourierToShow(value);
    }

    const handleShowOrders = () => {
        dispatch(getOrderList(courierToShow));
    }

    function renderSelection() {
        return (
            <>
                <span>Выберите курьера:</span><br />
                <Select size="default" style={{ width: 220 }} onChange={handleSelectCourierToShow}>
                    {courierList.map(courier => {
                        return (
                            <Option key={courier.name} value={courier._id}>{courier.name}</Option>
                        );
                    })}
                </Select>
                <br />
                <Button onClick={() => handleShowOrders()} disabled={!isCourierSelected} style={{ marginTop: 10 }} type="primary">ОТОБРАЗИТЬ / ОБНОВИТЬ</Button>
                <br /><Divider />
            </>
        );
    }

    function renderOrderList() {
        if (orderList.length > 0) {
            return (
                <>
                    {orderList.map(order => {
                        return (
                            <>
                                <span><b>Заказ №{order.id}</b></span><br />
                                <span>Клиент: {order.clientname}</span><br />
                                <span>Телефон клиента: {order.clientphone}</span><br />
                                <span>Адрес доставки: ул. {order.clientstreet}, дом. {order.clientbuilding}, стр./корп. {order.clientbuildingextra}, этаж {order.clientappartmentslevel}, кв./офис {order.clientappartments}</span><br />
                                <span>Время назначения: </span> <Moment date={order.courier_assigned_at} format="lll" locale="ru" /><br />
                                <span><b>Доставить к <Moment date={order.deliveryDate} format="lll" locale="ru" /></b></span><br />
                                {order.courier_delivered_at ? (<><span>Статус: <span style={{ fontWeight: 'bold', color: 'green' }}>Доставлено</span> <Moment date={order.courier_delivered_at} format="lll" locale="ru" /></span><br /></>) : (<><span>Статус: <span style={{ fontWeight: 'bold', color: 'orange' }}>В процессе доставки</span></span><br /></>)}
                                <span>Стоимость: {order.summa}</span><br />
                                <span>Стоимость доставки: {order.deliveryprice}</span><br />
                                <span>Итого: {parseInt(order.summa, 10) + parseInt(order.deliveryprice, 10)}</span><br />
                                <span><b>Состав заказа:</b></span><br />
                                {order.cartitems.map(item => {
                                    return (
                                        <>
                                            <Tag style={{ marginRight: 10 }}>{item.name} - {item.quantity}шт.</Tag>
                                        </>
                                    )
                                })}
                                <div style={{ height: 20 }}></div>
                                {order.comment != '' ? (
                                    <>
                                        <span><b>Заметки:</b></span><br />
                                        <span>{order.comment}</span><br />
                                    </>
                                ) : (<></>)}

                                <Divider />
                            </>
                        )
                    })}
                </>
            );
        } else {
            return (
                <div>
                    <Card title="Заказов не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
                        <div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
                    </Card>
                </div>
            )
        }
    }


    if (loading) {
        return (
            <div className="content-div">
                <Divider className="header-div" orientation="left"><Spin /></Divider>
            </div>
        );
    } else {
        return (
            <QueueAnim className="anim-content"
                type={['right', 'left']}
                ease={['easeOutQuart', 'easeInOutQuart']}>
                {!loading ? [
                    <div key="a" className="content-div">
                        <Divider className="header-div" orientation="left">Заказы курьеров</Divider>
                        <Row type="flex" justify="end">
                        </Row>
                        {renderSelection()}
                        {renderOrderList()}
                        <Divider />
                    </div>
                ] : null}
            </QueueAnim>
        );
    }
};

const useFetching = (getCourierList, dispatch) => {
    useEffect(() => {
        dispatch(getCourierList());
    }, []);
};

export default CPCouriers;
