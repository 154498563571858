import {
    GET_SUMO_IIKO_SETTINGS,
    LOADING_SUMO_IIKO_SETTINGS,
    SUMO_IIKO_CHECK,
    SUMO_IIKO_MENU_LOADED,
    SUMO_IIKO_MENU_LOADING
} from "../actions/types";

const initialState = {
    isLoading: true,
    iikoSettings: {},
    menuLoading: false,
    settings: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SUMO_IIKO_MENU_LOADED:
            return{
                ...state,
                menuLoading: false,
            }
        case SUMO_IIKO_MENU_LOADING:
            return{
                ...state,
                menuLoading: true,
            }
        case GET_SUMO_IIKO_SETTINGS:
            console.log('GET_SUMO_IIKO_SETTINGS');
            console.log(action.payload.settings);
            return {
                ...state,
                isLoading: false,
                settings: action.payload.settings.settings,
                // enabled: action.payload.settings.enabled,
                // mainUserLogin: action.payload.settings.mainUserLogin,
                // mainUserSecret: action.payload.settings.mainUserSecret,
                //iikoSettings: action.payload.settings.iikoSettings,
            };
        case LOADING_SUMO_IIKO_SETTINGS:
            return {
                ...state,
                isLoading: true,
            }

        case SUMO_IIKO_CHECK:
            return{
                ...state
            }

        default:
            return state;
    }
}
