import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  GET_PAYMENT_LIST,
  LOADING_PAYMENT,
  GET_PAYMENT_ERRORS,
  PAYMENT_EDITING,
  SHOW_ADD_PAYMENT_MODAL,
  CLOSE_ADD_PAYMENT_MODAL,
  SHOW_EDIT_PAYMENT_MODAL,
  CLOSE_EDIT_PAYMENT_MODAL,
  PAYMENT_EDITED,
  PAYMENT_ADDED,
  PAYMENT_ADDING,
  PAYMENT_DELETED,
} from "./types";


export const showAddPaymentModal = () => {
  return {
    type: SHOW_ADD_PAYMENT_MODAL
  };
};

export const closeAddPaymentModal = () => {
  return {
    type: CLOSE_ADD_PAYMENT_MODAL
  };
};

export const showEditPaymentModal = (payment) => {
  return {
    type: SHOW_EDIT_PAYMENT_MODAL,
    payload: payment
  };
};

export const closeEditPaymentModal = () => {
  return {
    type: CLOSE_EDIT_PAYMENT_MODAL
  };
};

export const setPaymentEditing = () => {
  return {
    type: PAYMENT_EDITING
  };
};

export const setPaymentAdding = () => {
  return {
    type: PAYMENT_ADDING
  };
};

export const addPayment = (payment) => dispatch => {
  dispatch(setPaymentAdding());
  axios
    .post(serverIp + "api/payment/addpayment", payment)
    .then(res => {
      dispatch({
        type: PAYMENT_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_PAYMENT_ERRORS,
        payload: err.response
      })
    );
};

export const deletePayment = (payment) => dispatch => {
  axios
    .post(serverIp + "api/payment/deletepayment", payment)
    .then(res => {
      dispatch({
        type: PAYMENT_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_PAYMENT_ERRORS,
        payload: err.response
      })
    );
};

export const editPayment = (payment) => dispatch => {
  dispatch(setPaymentEditing());
  axios
    .post(serverIp + "api/payment/editpayment", payment)
    .then(res => {
      dispatch({
        type: PAYMENT_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_PAYMENT_ERRORS,
        payload: err.response
      })
    );
};

// получаем способы оплаты
export const getPaymentList = () => dispatch => {
  dispatch(setPaymentLoading());
  axios
    .get(serverIp + "api/payment/getpaymentlist")
    .then(res => {
      dispatch({
        type: GET_PAYMENT_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_PAYMENT_ERRORS,
        payload: err.response
      })
    );
};

// Загружаются способы оплаты
export const setPaymentLoading = () => {
  return {
    type: LOADING_PAYMENT
  };
};
