import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Divider, Spin, Layout, Tabs, Input, Typography, Checkbox, Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
    getStaticPages,
    editStaticPage,
    saveStaticPage,
    contentPopulated
} from "../../actions/webStaticPageActions";
import RichTextEditor from 'react-rte';

const { TabPane } = Tabs;

const CPWebStaticPage = props => {
    useFetching(getStaticPages, useDispatch());
    const dispatch = useDispatch();

    const deliveryPage = useSelector(state => state.webstaticpage.delivery);
    const contactPage = useSelector(state => state.webstaticpage.contact);
    const aboutPage = useSelector(state => state.webstaticpage.about);
    const paymentPage = useSelector(state => state.webstaticpage.payment);

    const deliverySaveNeeded = useSelector(state => state.webstaticpage.deliverySaveNeeded);
    const contactSaveNeeded = useSelector(state => state.webstaticpage.contactSaveNeeded);
    const aboutSaveNeeded = useSelector(state => state.webstaticpage.aboutSaveNeeded);
    const paymentSaveNeeded = useSelector(state => state.webstaticpage.paymentSaveNeeded);

    const loading = useSelector(state => state.webstaticpage.isLoading);
    const popuplatedContent = useSelector(state => state.webstaticpage.popuplatedContent);



    // let source = event.target.value;
    // let oldValue = values.data;
    // setValues({ ...values, [name]: oldValue.setContentFromString(source, 'html') });

    const [contactvalues, setContactValues] = React.useState({
        data: RichTextEditor.createEmptyValue(),
    });
    const [deliveryvalues, setDeliveryValues] = React.useState({
        data: RichTextEditor.createEmptyValue(),
    });
    const [aboutvalues, setAboutValues] = React.useState({
        data: RichTextEditor.createEmptyValue(),
    });
    const [paymentvalues, setPaymentValues] = React.useState({
        data: RichTextEditor.createEmptyValue(),
    });

    useEffect(() => {
        console.log('useEffect');
        if (!popuplatedContent && !loading) {
            let oldValueContact = contactvalues.data;
            setContactValues({ ...contactvalues, data: oldValueContact.setContentFromString(contactPage.content, 'html') })
            let oldValueAbout = aboutvalues.data;
            setAboutValues({ ...aboutvalues, data: oldValueAbout.setContentFromString(aboutPage.content, 'html') })
            let oldValueDelivery = deliveryvalues.data;
            setDeliveryValues({ ...deliveryvalues, data: oldValueDelivery.setContentFromString(deliveryPage.content, 'html') })
            let oldValuePayment = paymentvalues.data;
            setPaymentValues({ ...paymentvalues, data: oldValuePayment.setContentFromString(paymentPage.content, 'html') })
            dispatch(contentPopulated());
        }
    }, [deliveryPage, contactPage, aboutPage, paymentPage]);


    const handleEditPayment = (e, from) => {
        var newid = paymentPage._id;
        var newenabled = paymentPage.enabled;
        var newlink = paymentPage.link;
        var newtitle = paymentPage.title;
        var newh1 = paymentPage.h1;
        var newdescription = paymentPage.description;
        var newcontent = paymentPage.content;
        var newurl = paymentPage.url;
        var newtype = paymentPage.type;
        if (from == "content") {
            setPaymentValues({ ...paymentvalues, data: e });
            newcontent = paymentvalues.data;
        }
        if (e.target != null) {
            if (e.target.name == 'enabled') { newenabled = e.target.checked; }
            if (e.target.name == 'link') { newlink = e.target.value; }
            if (e.target.name == 'title') { newtitle = e.target.value; }
            if (e.target.name == 'h1') { newh1 = e.target.value; }
            if (e.target.name == 'description') { newdescription = e.target.value; }
        }
        const data = { newid, newenabled, newlink, newtitle, newh1, newdescription, newcontent, newurl, newtype }
        dispatch(editStaticPage(data, 'payment'));
    }

    const handleEditDelivery = (e, from) => {
        var newid = deliveryPage._id;
        var newenabled = deliveryPage.enabled;
        var newlink = deliveryPage.link;
        var newtitle = deliveryPage.title;
        var newh1 = deliveryPage.h1;
        var newdescription = deliveryPage.description;
        var newcontent = deliveryPage.content;
        var newurl = deliveryPage.url;
        var newtype = deliveryPage.type;
        if (from == "content") {
            setDeliveryValues({ ...deliveryvalues, data: e });
            newcontent = deliveryvalues.data;
        }
        if (e.target != null) {
            if (e.target.name == 'enabled') { newenabled = e.target.checked; }
            if (e.target.name == 'link') { newlink = e.target.value; }
            if (e.target.name == 'title') { newtitle = e.target.value; }
            if (e.target.name == 'h1') { newh1 = e.target.value; }
            if (e.target.name == 'description') { newdescription = e.target.value; }
        }
        const data = { newid, newenabled, newlink, newtitle, newh1, newdescription, newcontent, newurl, newtype }
        dispatch(editStaticPage(data, 'delivery'));
    }

    const handleEditAbout = (e, from) => {
        var newid = aboutPage._id;
        var newenabled = aboutPage.enabled;
        var newlink = aboutPage.link;
        var newtitle = aboutPage.title;
        var newh1 = aboutPage.h1;
        var newdescription = aboutPage.description;
        var newcontent = aboutPage.content;
        var newurl = aboutPage.url;
        var newtype = aboutPage.type;
        if (from == "content") {
            setAboutValues({ ...aboutvalues, data: e });
            newcontent = aboutvalues.data;
        }
        if (e.target != null) {
            if (e.target.name == 'enabled') { newenabled = e.target.checked; }
            if (e.target.name == 'link') { newlink = e.target.value; }
            if (e.target.name == 'title') { newtitle = e.target.value; }
            if (e.target.name == 'h1') { newh1 = e.target.value; }
            if (e.target.name == 'description') { newdescription = e.target.value; }
        }
        const data = { newid, newenabled, newlink, newtitle, newh1, newdescription, newcontent, newurl, newtype }
        dispatch(editStaticPage(data, 'about'));
    }

    const handleEditContact = (e, from) => {
        var newid = contactPage._id;
        var newenabled = contactPage.enabled;
        var newlink = contactPage.link;
        var newtitle = contactPage.title;
        var newh1 = contactPage.h1;
        var newdescription = contactPage.description;
        var newcontent = contactPage.content;
        var newurl = contactPage.url;
        var newtype = contactPage.type;
        if (from == "content") {
            setContactValues({ ...contactvalues, data: e });
            newcontent = contactvalues.data;
        }
        if (e.target != null) {
            if (e.target.name == 'enabled') { newenabled = e.target.checked; }
            if (e.target.name == 'link') { newlink = e.target.value; }
            if (e.target.name == 'title') { newtitle = e.target.value; }
            if (e.target.name == 'h1') { newh1 = e.target.value; }
            if (e.target.name == 'description') { newdescription = e.target.value; }
        }
        const data = { newid, newenabled, newlink, newtitle, newh1, newdescription, newcontent, newurl, newtype }
        dispatch(editStaticPage(data, 'contact'));
    }

    const saveContactPage = (e) => {
        dispatch(saveStaticPage(contactPage));
    };

    const saveAboutPage = (e) => {
        dispatch(saveStaticPage(aboutPage));
    };

    const saveDeliveryPage = (e) => {
        dispatch(saveStaticPage(deliveryPage));
    };

    const savePaymentPage = (e) => {
        dispatch(saveStaticPage(paymentPage));
    };

    if (loading) {
        return (
                <div className="content-div">
                    <Divider className="header-div" orientation="left"><Spin /></Divider>
                </div>
        );
    } else {
        return (
                <QueueAnim className="anim-content"
                    type={['right', 'left']}
                    ease={['easeOutQuart', 'easeInOutQuart']}>
                    {!loading ? [
                        <div key="a" className="content-div">
                            <Divider className="header-div" orientation="left">Страницы сайта</Divider>
                            <Row type="flex" justify="end">
                            </Row>

                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Контакты" key="1">
                                    {renderContact()}
                                </TabPane>
                                <TabPane tab="О нас" key="2">
                                    {renderAbout()}
                                </TabPane>
                                <TabPane tab="Доставка" key="3">
                                    {renderDelivery()}
                                </TabPane>
                                <TabPane tab="Оплата" key="4">
                                    {renderPayment()}
                                </TabPane>
                            </Tabs>
                        </div>
                    ] : null}
                </QueueAnim>
        );
    }

    function renderContact() {
        return (
            <div>
                <Divider/>
                <Checkbox name={"enabled"} checked={contactPage.enabled} onChange={(e) => handleEditContact(e, "enabled")}>Страница включена?</Checkbox>
                {contactPage.enabled ? (
                    <>
                        <Typography style={{marginTop:15}}>Текст ссылки:</Typography>
                        <Input name="link" value={contactPage.link} onChange={(e) => handleEditContact(e, "link")} />
                        <Typography style={{marginTop:15}}>Title:</Typography>
                        <Input name="title" value={contactPage.title} onChange={(e) => handleEditContact(e, "title")} />
                        <Typography style={{marginTop:15}}>H1:</Typography>
                        <Input name="h1" value={contactPage.h1} onChange={(e) => handleEditContact(e, "h1")} />
                        <Typography style={{marginTop:15}}>META Description:</Typography>
                        <Input name="description" value={contactPage.description} onChange={(e) => handleEditContact(e, "description")} />
                        <Typography style={{marginTop:15}}>Контент:</Typography>
                        <RichTextEditor
                            name="content"
                            className="rte-height"
                            value={contactvalues.data}
                            onChange={(value) => handleEditContact(value, "content")}
                        />
                    </>
                ) : (<div></div>)}
                <Divider/>
                <Button disabled={!contactSaveNeeded} type="primary" icon="save" onClick={saveContactPage}>Сохранить</Button>
            </div>
        )
    }

    function renderAbout() {
        return (
            <div>
                <Divider/>
                <Checkbox name={"enabled"} checked={aboutPage.enabled} onChange={(e) => handleEditAbout(e, "enabled")}>Страница включена?</Checkbox>
                {aboutPage.enabled ? (
                    <>
                        <Typography style={{marginTop:15}}>Текст ссылки:</Typography>
                        <Input name="link" value={aboutPage.link} onChange={(e) => handleEditAbout(e, "link")} />
                        <Typography style={{marginTop:15}}>Title:</Typography>
                        <Input name="title" value={aboutPage.title} onChange={(e) => handleEditAbout(e, "title")} />
                        <Typography style={{marginTop:15}}>H1:</Typography>
                        <Input name="h1" value={aboutPage.h1} onChange={(e) => handleEditAbout(e, "h1")} />
                        <Typography style={{marginTop:15}}>META Description:</Typography>
                        <Input name="description" value={aboutPage.description} onChange={(e) => handleEditAbout(e, "description")} />
                        <Typography style={{marginTop:15}}>Контент:</Typography>
                        <RichTextEditor
                            name="content"
                            className="rte-height"
                            value={aboutvalues.data}
                            onChange={(value) => handleEditAbout(value, "content")}
                        />
                    </>
                ) : (<div></div>)}
                <Divider/>
                <Button disabled={!aboutSaveNeeded} type="primary" icon="save" onClick={saveAboutPage}>Сохранить</Button>
            </div>
        )
    }

    function renderDelivery() {
        return (
            <div>
                <Divider/>
                <Checkbox name={"enabled"} checked={deliveryPage.enabled} onChange={(e) => handleEditDelivery(e, "enabled")}>Страница включена?</Checkbox>
                {deliveryPage.enabled ? (
                    <>
                        <Typography style={{marginTop:15}}>Текст ссылки:</Typography>
                        <Input name="link" value={deliveryPage.link} onChange={(e) => handleEditDelivery(e, "link")} />
                        <Typography style={{marginTop:15}}>Title:</Typography>
                        <Input name="title" value={deliveryPage.title} onChange={(e) => handleEditDelivery(e, "title")} />
                        <Typography style={{marginTop:15}}>H1:</Typography>
                        <Input name="h1" value={deliveryPage.h1} onChange={(e) => handleEditDelivery(e, "h1")} />
                        <Typography style={{marginTop:15}}>META Description:</Typography>
                        <Input name="description" value={deliveryPage.description} onChange={(e) => handleEditDelivery(e, "description")} />
                        <Typography style={{marginTop:15}}>Контент:</Typography>
                        <RichTextEditor
                            name="content"
                            className="rte-height"
                            value={deliveryvalues.data}
                            onChange={(value) => handleEditDelivery(value, "content")}
                        />
                    </>
                ) : (<div></div>)}
                <Divider/>
                <Button disabled={!deliverySaveNeeded} type="primary" icon="save" onClick={saveDeliveryPage}>Сохранить</Button>
            </div>
        )
    }

    function renderPayment() {
        return (
            <div>
                <Divider/>
                <Checkbox name={"enabled"} checked={paymentPage.enabled} onChange={(e) => handleEditPayment(e, "enabled")}>Страница включена?</Checkbox>
                {paymentPage.enabled ? (
                    <>
                        <Typography style={{marginTop:15}}>Текст ссылки:</Typography>
                        <Input name="link" value={paymentPage.link} onChange={(e) => handleEditPayment(e, "link")} />
                        <Typography style={{marginTop:15}}>Title:</Typography>
                        <Input name="title" value={paymentPage.title} onChange={(e) => handleEditPayment(e, "title")} />
                        <Typography style={{marginTop:15}}>H1:</Typography>
                        <Input name="h1" value={paymentPage.h1} onChange={(e) => handleEditPayment(e, "h1")} />
                        <Typography style={{marginTop:15}}>META Description:</Typography>
                        <Input name="description" value={paymentPage.description} onChange={(e) => handleEditPayment(e, "description")} />
                        <Typography style={{marginTop:15}}>Контент:</Typography>
                        <RichTextEditor
                            name="content"
                            className="rte-height"
                            value={paymentvalues.data}
                            onChange={(value) => handleEditPayment(value, "content")}
                        />
                    </>
                ) : (<div></div>)}
                <Divider/>
                <Button disabled={!paymentSaveNeeded} type="primary" icon="save" onClick={savePaymentPage}>Сохранить</Button>
            </div>
        )
    }

};

const useFetching = (getStaticPages, dispatch) => {
    useEffect(() => {
        dispatch(getStaticPages());
    }, []);
};

export default CPWebStaticPage;
