import axios from "axios";
import serverIp from "../utils/serverIp";

import {
    COMPLECT_LIST_LOADED,
    COMPLECT_LIST_LOADING,
    COMPLECT_ADDED,
    COMPLECT_ADDING,
    COMPLECT_DELETED,
    COMPLECT_EDITED
} from "./types";

export const getComplectList = () => dispatch => {
    dispatch(setComplectLoading());
    console.log('getComplectList')
    axios
        .get(serverIp + "warehouse/complect/getcomplectlist")
        .then(res => {
            dispatch({
                type: COMPLECT_LIST_LOADED,
                payload: res.data
            });
        })
        .catch(err => { });
};

export const setComplectLoading = () => {
    return {
        type: COMPLECT_LIST_LOADING
    };
};

export const editComplect = (complect) => dispatch => {
    axios
    .post(serverIp + "warehouse/complect/editcomplect", complect)
    .then(res => {
        dispatch({
            type: COMPLECT_EDITED,
            payload: res.data
        });
    })
    .catch(err => { });
}

export const deleteComplect = (complect) => dispatch => {
    axios
    .post(serverIp + "warehouse/complect/deletecomplect", complect)
    .then(res => {
        dispatch({
            type: COMPLECT_DELETED,
            payload: res.data
        });
    })
    .catch(err => { });
}

export const addComplect = (complect) => dispatch => {
    dispatch(setComplectAdding());
    axios
        .post(serverIp + "warehouse/complect/addcomplect", complect)
        .then(res => {
            dispatch({
                type: COMPLECT_ADDED,
                payload: res.data
            });
        })
        .catch(err => { });
};

export const setComplectAdding = () => {
    return {
        type: COMPLECT_ADDING
    };
};