import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  GET_USERS_LIST,
  LOADING_USERS,
  GET_USERS_ERRORS,
  SHOW_ADD_USER_MODAL,
  CLOSE_ADD_USER_MODAL,
  USER_ADDED,
  ADDING_USER,
  SHOW_EDIT_USER_MODAL,
  CLOSE_EDIT_USER_MODAL,
  USER_DELETED,
  USER_EDITED,
  USER_EDITING
} from "./types";

// получаем
export const getUsersList = () => dispatch => {
  console.log('getUsersList');
  dispatch(setUsersLoading());
  axios
    .get(serverIp+"api/users/getuserslist")
    .then(res => {
      dispatch({
        type: GET_USERS_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_USERS_ERRORS,
        payload: err.response
      })
    );
};

export const addUser = (user) => dispatch => {
  dispatch(setUserAdding());
  axios
    .post(serverIp+"api/users/adduser", user)
    .then(res => {
      dispatch({
        type: USER_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_USERS_ERRORS,
        payload: err.response
      })
    );
  };

  export const deleteUser = (user) => dispatch => {
    axios
      .post(serverIp+"api/users/deleteuser", user)
      .then(res => {
        dispatch({
          type: USER_DELETED,
          payload: res.data
        });
      })
      .catch(err =>
        dispatch({
          type: GET_USERS_ERRORS,
          payload: err.response
        })
      );
  };

  export const editUser = (user, ispw) => dispatch => {
    dispatch(setUserEditing());
    axios
    .post(serverIp+"api/users/edituser", {user:user,psw:ispw})
    .then(res => {
      dispatch({
        type: USER_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_USERS_ERRORS,
        payload: err.response
      })
    );
  };

export const showEditUserModal = (user) => {
  return {
    type: SHOW_EDIT_USER_MODAL,
    payload: user
  };
};
export const closeEditUserModal = () => {
  return {
    type: CLOSE_EDIT_USER_MODAL
  };
};


export const setUserEditing = () => {
  return {
    type: USER_EDITING
  };
};

export const setUserAdding = () => {
  return {
    type: ADDING_USER
  };
};

export const setUsersLoading = () => {
  return {
    type: LOADING_USERS
  };
};
export const showAddUserModal = () => {
  return {
    type: SHOW_ADD_USER_MODAL
  };
};
export const closeAddUserModal = () => {
  return {
    type: CLOSE_ADD_USER_MODAL
  };
};
