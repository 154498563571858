import { 
  GET_CATEGORY_LIST,
  LOADING_CATEGORY,
  ADD_CATEGORY,
  CATEGORY_ADDED,
  SHOW_ADD_CATEGORY_MODAL,
  CLOSE_ADD_CATEGORY_MODAL,
  SHOW_DEL_CATEGORY_MODAL,
  CLOSE_DEL_CATEGORY_MODAL,
  CATEGORY_DELETED,
  SHOW_ED_CATEGORY_MODAL,
  CLOSE_ED_CATEGORY_MODAL,
  CATEGORY_EDITED,
  SHOW_CATEGORY_IMG,
  CLOSE_CATEGORY_IMG,
  CATEGORY_IMG_ADDING,
  CATEGORY_IMG_ADDED,
  CATEGORY_IMG_DELETED
} from "../actions/types";
//import {hideModal} from "../components/cp-pages/CPCategoryList";

const initialState = {
  isLoading: true,
  categories: [],
  showAdd: false,
  showDel: false,
  showEdit: false,
  categoryDel: {},
  categoryEdit: {},
  categoryToAddImg: {},
  categoryImgAdding: false,
  isShowAddImgCategory: false,
};

export default function(state = initialState, action) {
  switch (action.type) {

    case CATEGORY_IMG_DELETED:
      const updatedStateCategoryImgDeleted = state.categories.map((category) => {
        if (category._id === action.payload.category._id) {
          return { ...category, ...action.payload.category };
        } else {
        }
        return category;
      });
      return {
        ...state,
        categories: updatedStateCategoryImgDeleted,
        categoryImgAdding: false,
        categoryToAddImg: {},
        isShowAddImgCategory: false,
      };

    case CATEGORY_IMG_ADDED:
      const updatedStateCategory = state.categories.map((category) => {
        if (category._id === action.payload.category._id) {
          return { ...category, ...action.payload.category };
        } else {
        }
        return category;
      });
      return {
        ...state,
        categoryImgAdding: false,
        categoryToAddImg: {},
        isShowAddImgCategory: false,
        categories: updatedStateCategory,
      };
    case CATEGORY_IMG_ADDING:
      return{
        ...state,
        categoryImgAdding: true,
      }
    case SHOW_CATEGORY_IMG:
      return{
        ...state,
        isShowAddImgCategory: true,
        categoryToAddImg: action.payload
      }
    case CLOSE_CATEGORY_IMG:
      return{
        ...state,
        isShowAddImgCategory: false,
        categoryToAddImg: {}
      }
    case GET_CATEGORY_LIST:
      return {
        ...state,
        categories: action.payload.categories,
        isLoading: false
      };
    case CATEGORY_ADDED:
      return {
        ...state,
        categories: [...state.categories, action.payload.newcategory],
        isLoading: false,
        showAdd: false,
      };
    case CATEGORY_DELETED:
      const id = action.payload.deletedcategory._id;
      return {
        ...state,
        categories: state.categories.filter((category) => category._id !== id),
        //categories: state.categories.filter(category => category !== action.payload),
        //[...state.categories, action.payload.newcategory],
        showDel: false,
        categoryDel: {}, 
      };
    case CATEGORY_EDITED:
      const updatedState = state.categories.map((category) => {
        if(category._id === action.payload.editedcategory._id){
          return {...category, ...action.payload.editedcategory};
        } else {
        }
        return category;
      });
      return {
        ...state, 
        categories: updatedState,
        showEdit: false,
        categoryDel: {}
      };
    case LOADING_CATEGORY:
      return {
        ...state,
        isLoading: true
      };
    case SHOW_ADD_CATEGORY_MODAL:
      return {
      ...state,
      isLoading: false,
      showAdd: true,
      };
    case CLOSE_ADD_CATEGORY_MODAL:
      return {
      ...state,
      isLoading: false,
      showAdd: false,
      };
    case SHOW_ED_CATEGORY_MODAL:
      return {
      ...state,
      isLoading: false,
      showEdit: true,
      categoryEdit: action.payload
      };
    case CLOSE_ED_CATEGORY_MODAL:
      return {
      ...state,
      isLoading: false,
      showEdit: false,
      categoryEdit: {}
      };
    case SHOW_DEL_CATEGORY_MODAL:
      return {
      ...state,
      isLoading: false,
      showDel: true,
      categoryDel: action.payload
      };
    case CLOSE_DEL_CATEGORY_MODAL:
      return {
      ...state,
      isLoading: false,
      showDel: false,
      categoryDel: {}
      };  
    default:
      return state;
  }
}
