import {
    FEEDBACK_LIST_LOADED,
    FEEDBACK_LIST_LOADING,
    FEEDBACK_LIST_DELETED,
    FEEDBACK_EDITED
} from "../actions/types";

const initialState = {
    isLoading: true,
    feedbacks: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FEEDBACK_EDITED:
            console.log('FEEDBACK_EDITED');
              return{
                ...state,
                feedbacks: state.feedbacks.map(feedback => feedback._id === action.payload.newfeedback._id ?
                    { ...feedback, ...action.payload.newfeedback } :
                    feedback
                  )
              }
        case FEEDBACK_LIST_LOADED:
            return {
                ...state,
                feedbacks: action.payload.feedbacks,
                isLoading: false,
            }
        case FEEDBACK_LIST_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case FEEDBACK_LIST_DELETED:
            const id = action.payload.deletedfeedback._id;
            return {
                ...state,
                feedbacks: state.feedbacks.filter((feedback) => feedback._id !== id),
            }
        default:
            return state;
    }
}