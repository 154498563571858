import {
  GET_BONUS_SETTINGS,
  LOADING_BONUS,
  EDIT_BONUS_SETTINGS,
  GET_BONUS_ERRORS,
  SAVED_BONUS_SETTINGS
} from "../actions/types";

const initialState = {
  isLoading: true,
  needSave: false,
  enabled: 0,
  percentToPay: 0,
  autoAdd: 0,
  autoRemove: 0,
  bonusRate:1,
  autoAddRate:0,
  bonusSettings: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BONUS_SETTINGS:
      console.log(action.payload);
      return {
        ...state,
        isLoading: false,
        enabled: action.payload.settings[0].enabled,
        percentToPay: action.payload.settings[0].percentToPay,
        autoAdd: action.payload.settings[0].autoAdd,
        autoRemove: action.payload.settings[0].autoRemove,
        bonusRate: action.payload.settings[0].bonusRate,
        autoAddRate: action.payload.settings[0].autoAddRate,
      };
    case LOADING_BONUS:
      return {
        ...state,
        isLoading: true,
      };
    case EDIT_BONUS_SETTINGS:
      return {
        ...state,
        enabled: action.payload.newEnabled,
        percentToPay: action.payload.newPercentToPay,
        autoAdd: action.payload.newAutoAdd,
        autoRemove: action.payload.newAutoRemove,
        bonusRate: action.payload.newBonusRate,
        autoAddRate: action.payload.newAutoAddRate,
        needSave: true,
      };
    case SAVED_BONUS_SETTINGS:
      return {
        ...state,
        needSave: false,
      };
    default:
      return state;
  }
}
