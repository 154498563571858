import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
    GET_DELIVERY_SERVICES_LIST,
    LOADING_DELIVERY_SERVICES,
    GET_DELIVERY_SERVICES_ERRORS,
} from "./types";

export const getDeliveryServices = () => dispatch => {
    dispatch({
        type: LOADING_DELIVERY_SERVICES
    });
    axios
      .get(serverIp + "api/deliveryservices/getdeliveryserviceslist")
      .then(res => {
        dispatch({
          type: GET_DELIVERY_SERVICES_LIST,
          payload: res.data
        });
      })
      .catch(err =>
        dispatch({
          type: GET_DELIVERY_SERVICES_ERRORS,
          payload: err.response
        })
      );
  };
