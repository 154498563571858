import {
    GET_ITEMFILTER_LIST,
    LOADING_ITEMFILTER,
    GET_ITEMFILTER_ERRORS,
    ITEMFILTER_EDITING,
    SHOW_ADD_ITEMFILTER_MODAL,
    CLOSE_ADD_ITEMFILTER_MODAL,
    ITEMFILTER_ADDED,
    ITEMFILTER_ADDING,
    ITEMFILTER_DELETED,
    SHOW_EDIT_ITEMFILTER_MODAL,
    CLOSE_EDIT_ITEMFILTER_MODAL,
    ITEMFILTER_EDITED
} from "../actions/types";

const initialState = {
    isLoading: true,
    filters: [],
    editing: false,
    showAdd: false,
    showEdit: false,
    adding: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLOSE_EDIT_ITEMFILTER_MODAL:
            return {
                ...state,
                editing: false,
                showEdit: false,
            }
        case SHOW_EDIT_ITEMFILTER_MODAL:
            return {
                ...state,
                showEdit: true,
            }
        case ITEMFILTER_EDITED:
            const updatedState = state.filters.map((filter) => {
                if (filter._id === action.payload.editedfilter._id) {
                    return { ...filter, ...action.payload.editedfilter };
                } else {
                }
                return filter;
            });
            return {
                ...state,
                filters: updatedState,
                showEdit: false,
                editing: false,
            };
        case ITEMFILTER_EDITING:
            return {
                ...state,
                editing: true,
            };
        case ITEMFILTER_DELETED:
            const id = action.payload.deletedfilter._id;
            return {
                ...state,
                filters: state.filters.filter((filter) => filter._id !== id),
            };
        case ITEMFILTER_ADDING:
            return {
                ...state,
                adding: true,
            };
        case ITEMFILTER_ADDED:
            return {
                ...state,
                filters: [...state.filters, action.payload.newfilter],
                showAdd: false,
                adding: false,
            };
        case SHOW_ADD_ITEMFILTER_MODAL:
            return {
                ...state,
                showAdd: true,
            };
        case CLOSE_ADD_ITEMFILTER_MODAL:
            return {
                ...state,
                showAdd: false
            };
        case GET_ITEMFILTER_LIST:
            return {
                ...state,
                isLoading: false,
                filters: action.payload.filters,
            };
        case LOADING_ITEMFILTER:
            return {
                ...state,
                isLoading: true,
            };
        default:
            return state;
    }
}
