import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  downloadAllCustomers,
  getCustomersList,
  showAddCustomerModal,
  closeAddCustomerModal,
  addCustomer,
  showDelCustomerModal,
  closeDelCustomerModal,
  deleteCustomer,
  openOrderHistoryModal,
  closeOrderHistoryModal,
  openBonusHistoryModal,
  closeBonusHistoryModal,
  getCustomersOrder,
  openAddBonusModal,
  closeAddBonusModal,
  openDelBonusModal,
  closeDelBonusModal,
  delBonus,
  addBonus,
  editCustomer,
  openEditCustomerModal,
  closeEditCustomerModal,
  getPhoneFiltered,
  getEmailFiltered,
} from '../../actions/customerActions'
import { bindActionCreators } from 'redux';
import { Select, Input, Button, Row, Col, Typography, Divider, Spin, Card, Icon, Modal, Layout, List, Table, Tag } from 'antd';
import QueueAnim from 'rc-queue-anim';
import Moment from 'react-moment';
import 'moment/locale/ru';
import MaskedInput from 'antd-mask-input'

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const { Title } = Typography;
const { Option } = Select;
const { Search } = Input;
const { TextArea } = Input;
const { Text } = Typography;



const CPCustomers = props => {


  const [pagination, setPagination] = React.useState({
    pageSize: 5,
    total: null
  });

  useFetching(getCustomersList, useDispatch(), pagination.pageSize);
  //const order = useSelector(state => state.orders.orders);
  const loading = useSelector(state => state.customers.isLoading);
  const customers = useSelector(state => state.customers.customers);
  const total = useSelector(state => state.customers.total);
  const adding = useSelector(state => state.customers.adding);
  const showAdd = useSelector(state => state.customers.showAdd);
  const showDel = useSelector(state => state.customers.showDel);
  const customerDel = useSelector(state => state.customers.customerDel);

  const fullListLoading = useSelector(state => state.customers.fullListLoading);
  const allCustomersList = useSelector(state => state.customers.allCustomersList);

  const pageLoading = useSelector(state => state.customers.pageLoading);

  const showOrderHistory = useSelector(state => state.customers.showOrderHistory);
  const showBonusHistory = useSelector(state => state.customers.showBonusHistory);
  const customerOrders = useSelector(state => state.customers.customerOrders);
  const orderHistoryLoading = useSelector(state => state.customers.orderHistoryLoading);

  const showAddBonus = useSelector(state => state.customers.showAddBonus);
  const showDelBonus = useSelector(state => state.customers.showDelBonus);
  const showEdit = useSelector(state => state.customers.showEdit);
  const editing = useSelector(state => state.customers.editing);

  const [customerBonusHistory, setCustomerBonusHistory] = React.useState({
    bonusHistory: []
  });

  const [customerAddBonus, setCustomerAddBonus] = React.useState({
  });

  const [customerDelBonus, setCustomerDelBonus] = React.useState({
  });

  const [customerEdit, setCustomerEdit] = React.useState({
    delivery_address: {}
  });

  const [bonusToDel, setBonusToDel] = React.useState(0);

  const [bonusToAdd, setBonusToAdd] = React.useState(0);

  const [phoneSearch, setPhoneSearch] = React.useState('');
  const [emailSearch, setEmailSearch] = React.useState('');





  const dispatch = useDispatch();

  useEffect(() => {
    let paginationTmp = pagination;
    paginationTmp.total = total;
    setPagination(paginationTmp);
  }, [total])

  useEffect(() => {
    if (!fullListLoading) {
      if (allCustomersList != null) {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const ws = XLSX.utils.json_to_sheet(allCustomersList);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'all_users' + fileExtension);

      }
    }
  }, [fullListLoading])

  const [values, setValues] = React.useState({
    name: '',
    phone: '',
    email: '',
    password: '',
  });

  const handleOpenAddModal = useCallback(() => dispatch(showAddCustomerModal()), [
    dispatch
  ]);
  const handleCloseAddModal = useCallback(
    () => dispatch(closeAddCustomerModal()),
    [dispatch]
  );
  const showDeleteCustomerModal = customer => {
    dispatch(showDelCustomerModal(customer));
  };
  const handleCloseDelModal = useCallback(
    () => dispatch(closeDelCustomerModal()),
    [dispatch]
  );

  const showOrderHistoryModal = customer => {
    dispatch(openOrderHistoryModal(customer));
    dispatch(getCustomersOrder(customer));
  };
  const handleCloseOrderHistoryModal = useCallback(
    () => dispatch(closeOrderHistoryModal()),
    [dispatch]
  );

  const showBonusHistoryModal = customer => {
    setCustomerBonusHistory(customer);
    dispatch(openBonusHistoryModal());
  };
  const handleCloseBonusHistoryModal = () => {
    setCustomerBonusHistory({ bonusHistory: [] });
    dispatch(closeBonusHistoryModal());
  }

  const showAddBonusModal = customer => {
    setCustomerAddBonus(customer);
    dispatch(openAddBonusModal());
  };
  const handleCloseAddBonusModal = () => {
    setCustomerAddBonus({});
    dispatch(closeAddBonusModal());
  }

  const showDelBonusModal = customer => {
    setCustomerDelBonus(customer);
    dispatch(openDelBonusModal());
  };
  const handleCloseDelBonusModal = () => {
    setCustomerDelBonus({});
    dispatch(closeDelBonusModal());
  }

  const showEditModal = customer => {
    setCustomerEdit(customer);
    dispatch(openEditCustomerModal());
  };
  const handleCloseEditModal = () => {
    setCustomerEdit({ delivery_address: {} });
    dispatch(closeEditCustomerModal());
  }

  const handleEdit = () => {
    dispatch(editCustomer(customerEdit));
    setCustomerEdit({ delivery_address: {} })
  }

  const handleAddBonus = () => {
    dispatch(addBonus(customerAddBonus, bonusToAdd));
    setCustomerAddBonus({});
    setBonusToAdd(0);
  }

  const handleDelBonus = () => {
    dispatch(delBonus(customerDelBonus, bonusToDel));
    setCustomerDelBonus({});
    setBonusToDel(0);
  }

  const handleDelCustomer = () => {
    dispatch(deleteCustomer(customerDel));
  };
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleAddCustomer = () => {
    dispatch(addCustomer(values));
    setValues({
      name: '',
      phone: '',
      email: '',
      password: '',
    });
  };

  const handlePaginationChange = (value) => {
    let paginationTmp = pagination;
    paginationTmp.pageSize = value;
    paginationTmp.current = pagination.current;
    dispatch(getCustomersList(pagination.current, pagination.pageSize, true));
    setPagination(paginationTmp);
  }

  const downloadFullList = () => {
    dispatch(downloadAllCustomers());
  }

  const filterPhone = () => {
    setPagination({
      pageSize: null,
      total: null
    });
    dispatch(getPhoneFiltered(phoneSearch));
  }
  const resetPhone = () => {
    setPagination({
      pageSize: 5,
      total: null
    });
    setPhoneSearch('');
    dispatch(getCustomersList(1, 5, true));
  }
  const filterEmail = () => {
    setPagination({
      pageSize: null,
      total: null
    });
    dispatch(getEmailFiltered(emailSearch));
  }
  const resetEmail = () => {
    setPagination({
      pageSize: 5,
      total: null
    });
    setEmailSearch('');
    dispatch(getCustomersList(1, 5, true));
  }

  function renderBonusStatGranted(customer) {
    let grantedTotal = 0;
    for (let index = 0; index < customer.bonusHistory.length; index++) {
      if (customer.bonusHistory[index].granted) {
        grantedTotal += customer.bonusHistory[index].granted;
      }
    }
    return grantedTotal;
  }

  function renderBonusStatUsed(customer) {
    let usedTotal = 0;
    for (let index = 0; index < customer.bonusHistory.length; index++) {

      if (customer.bonusHistory[index].granted) {
      } else {
        usedTotal += customer.bonusHistory[index].removed;
      }
    }
    return usedTotal;
  }

  function renderCustomerInfo(customer) {
    return (
      <>
        <div key={customer.id} className="customer-info-box">
          <div className="customer-info-section">
            <span><b>Баланс бонусных балов:</b></span><br />
            <span>{customer.bonus}</span><br /><br />
            <span>Начислено: {renderBonusStatGranted(customer)}</span><br />
            <span>Списано: {renderBonusStatUsed(customer)}</span><br /><br />
            <span><b>Адрес доставки:</b></span><br />
            <span>гор.: {customer.delivery_address.city}</span><br />
            <span>ул.: {customer.delivery_address.street}</span><br />
            <span>д.: {customer.delivery_address.building}</span><br />
            <span>стр./корп.: {customer.delivery_address.building_extra}</span><br />
            <span>кв./оф.: {customer.delivery_address.appartments}</span><br />
            <span>этаж: {customer.delivery_address.appartments_level}</span><br />
            <span>подъезд: {customer.delivery_address.appartments_block}</span><br />
          </div>
        </div>
        <div className="customer-buttons">
          <Button style={{ marginRight: 5 }} icon="history" type="dashed" onClick={() => showOrderHistoryModal(customer)}>ИСТОРИЯ ЗАКАЗОВ</Button>
          <Button style={{ marginRight: 5 }} icon="history" type="dashed" onClick={() => showBonusHistoryModal(customer)}>ИСТОРИЯ БОНУСОВ</Button>
          <Button style={{ marginRight: 5 }} icon="plus-circle" type="dashed" onClick={() => showAddBonusModal(customer)}>НАЧИСЛИТЬ БОНУСЫ</Button>
          <Button style={{ marginRight: 5 }} icon="minus-circle" type="dashed" onClick={() => showDelBonusModal(customer)}>СПИСАТЬ БОНУСЫ</Button>
          <Button style={{ marginRight: 5 }} icon="edit" type="primary" onClick={() => showEditModal(customer)}>РЕДАКТИРОВАТЬ</Button>
          <Button style={{ marginRight: 5 }} icon="delete" type="danger" onClick={() => showDeleteCustomerModal(customer)}>УДАЛИТЬ</Button>
        </div>
      </>
    );
  }

  const handleTableChange = (pagination, filters, sorter) => {
    dispatch(getCustomersList(pagination.current, pagination.pageSize, true));
    let paginationTmp = pagination;
    paginationTmp.current = pagination.current;
    setPagination(paginationTmp);
  }

  function renderSort() {

    const columns = [
      {
        title: 'Valid', dataIndex: 'id', key: 'id',
        render: (customerRow, row) => {
          if (parseInt(renderBonusStatGranted(row), 10) > parseInt(renderBonusStatUsed(row), 10)) {
            return (
              <>+</>
            )
          } else {
            return (
              <>-</>
            )
          }

        }
      },
      { title: 'Имя', dataIndex: 'name', key: 'name' },
      { title: 'Телефон', dataIndex: 'phone', key: 'phone' },
      { title: 'E-mail', dataIndex: 'email', key: 'email' },
      { title: 'Бонусы', dataIndex: 'bonus', key: 'bonus' },
    ];


    if (customers.length > 0) {
      return (
        <Table
          rowKey={record => record._id}
          pagination={pagination}
          style={{ marginTop: 20 }}
          columns={columns}
          expandedRowRender={record => renderCustomerInfo(record)}
          onChange={handleTableChange}
          dataSource={customers}
          loading={pageLoading}
        />
      );
    } else {
      return (
        <div className="content-div">
          <Card title="Покупателей не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
            <div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
          </Card>
        </div>
      );
    }
  }

  if (loading) {
    return (
      <div className="content-div">
        <Divider className="header-div" orientation="left"><Spin /></Divider>
      </div>
    );
  } else {
    return (
      <QueueAnim className="anim-content"
        type={['right', 'left']}
        ease={['easeOutQuart', 'easeInOutQuart']}>
        {!loading ? [
          <div key="a" className="content-div">
            <div>
              <Divider className="header-div" orientation="left">Покупатели</Divider>
            </div>
            <Row type="flex" justify="end">

              <div style={{ marginRight: 20, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#eeeeee', paddingRight: 20 }}>
                {fullListLoading ? (<><Spin /></>) : (<></>)}<Button icon="download" type="dashed" onClick={downloadFullList}>Скачать весь список</Button>
              </div>

              <div style={{ marginRight: 20, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#eeeeee', paddingRight: 20 }}>
                <MaskedInput icon="search" style={{ width: 170 }} placeholder="Поиск по телефону" mask="+7 (111) 111-11-11" name="card" size="20" value={phoneSearch} onChange={(e) => setPhoneSearch(e.target.value)} />
                <Button icon="search" type="dashed" onClick={filterPhone}></Button>
                <Button icon="close-circle" type="dashed" onClick={resetPhone}></Button>
              </div>
              <div style={{ marginRight: 20, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#eeeeee', paddingRight: 20 }}>
                <Input style={{ width: 170 }} placeholder="Поиск по E-mail" name="card" value={emailSearch} onChange={(e) => setEmailSearch(e.target.value)} />
                <Button icon="search" type="dashed" onClick={filterEmail}></Button>
                <Button icon="close-circle" type="dashed" onClick={resetEmail}></Button>
              </div>
              <div className="header-center-row">
                <Select defaultValue={5} size="default" style={{ width: 70 }} onChange={handlePaginationChange}>
                  <Option value={5}>5</Option>
                  <Option value={10}>10</Option>
                  <Option value={25}>25</Option>
                </Select>
              </div>
            </Row>
            <Divider />
            {renderSort()}

            <Divider />
            <Card style={{ width: '100%', marginBottom: 20 }} size="small">
              <Button type="primary" icon="plus" onClick={handleOpenAddModal}>
                Добавить
              </Button>
            </Card>

            <Modal
              centered
              title={"Редактировать покупателя"}
              style={{ textAlign: 'left' }}
              visible={showEdit}
              onCancel={handleCloseEditModal}
              footer={[
                <Button key="back" onClick={handleCloseEditModal}>Закрыть</Button>
              ]}
            >
              <div>
                <Typography style={{ textAlign: 'left' }}>Имя:</Typography>
                <Input
                  value={customerEdit.name}
                  name="name"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => setCustomerEdit({ ...customerEdit, "name": e.target.value })}
                />
                <Typography style={{ textAlign: 'left' }}>Пароль:</Typography>
                <Input
                  value={customerEdit.password}
                  name="password"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => setCustomerEdit({ ...customerEdit, "password": e.target.value })}
                />
                <Typography style={{ textAlign: 'left' }}>Город:</Typography>
                <Input
                  value={customerEdit.delivery_address.city}
                  name="city"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => setCustomerEdit({ ...customerEdit, delivery_address: { ...customerEdit.delivery_address, "city": e.target.value } })}
                />
                <Typography style={{ textAlign: 'left' }}>Улица:</Typography>
                <Input
                  value={customerEdit.delivery_address.street}
                  name="street"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => setCustomerEdit({ ...customerEdit, delivery_address: { ...customerEdit.delivery_address, "street": e.target.value } })}
                />
                <Typography style={{ textAlign: 'left' }}>Дом:</Typography>
                <Input
                  value={customerEdit.delivery_address.building}
                  name="building"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => setCustomerEdit({ ...customerEdit, delivery_address: { ...customerEdit.delivery_address, "building": e.target.value } })}
                />
                <Typography style={{ textAlign: 'left' }}>Строение / Корпус:</Typography>
                <Input
                  value={customerEdit.delivery_address.building_extra}
                  name="building_extra"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => setCustomerEdit({ ...customerEdit, delivery_address: { ...customerEdit.delivery_address, "building_extra": e.target.value } })}
                />
                <Typography style={{ textAlign: 'left' }}>Квартира / Офис:</Typography>
                <Input
                  value={customerEdit.delivery_address.appartments}
                  name="appartments"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => setCustomerEdit({ ...customerEdit, delivery_address: { ...customerEdit.delivery_address, "appartments": e.target.value } })}
                />
                <Typography style={{ textAlign: 'left' }}>Этаж:</Typography>
                <Input
                  value={customerEdit.delivery_address.appartments_level}
                  name="appartments_level"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => setCustomerEdit({ ...customerEdit, delivery_address: { ...customerEdit.delivery_address, "appartments_level": e.target.value } })}
                />
                <Typography style={{ textAlign: 'left' }}>Подъезд:</Typography>
                <Input
                  value={customerEdit.delivery_address.appartments_block}
                  name="appartments_block"
                  style={{ marginBottom: 10 }}
                  onChange={(e) => setCustomerEdit({ ...customerEdit, delivery_address: { ...customerEdit.delivery_address, "appartments_block": e.target.value } })}
                />
                <Button type="primary" onClick={() => handleEdit()}>Сохранить</Button>
              </div>
            </Modal>

            <Modal
              centered
              title={"Добавить бонусы"}
              style={{ textAlign: 'center' }}
              visible={showAddBonus}
              onCancel={handleCloseAddBonusModal}
              footer={[
                <Button key="back" onClick={handleCloseAddBonusModal}>Закрыть</Button>
              ]}
            >
              <div>
                {editing ? (<><Spin /><br />Ожидайте</>) : (
                  <>
                    <div style={{ textAlign: 'left' }}>
                      <span><b>Имя клиента:</b></span><br />
                      <span>{customerAddBonus.name}</span><br /><br />
                    </div>
                    <Typography style={{ textAlign: 'left' }}>Сколько добавить баллов:</Typography>
                    <Input
                      value={bonusToAdd}
                      name="bonusToAdd"
                      style={{ marginBottom: 10 }}
                      onChange={(e) => setBonusToAdd(e.target.value)}
                    />
                    <Button type="primary" onClick={() => handleAddBonus()}>Добавить</Button>
                  </>
                )}

              </div>
            </Modal>

            <Modal
              centered
              title={"Списать бонусы"}
              style={{ textAlign: 'center' }}
              visible={showDelBonus}
              onCancel={handleCloseDelBonusModal}
              footer={[
                <Button key="back" onClick={handleCloseDelBonusModal}>Закрыть</Button>
              ]}
            >
              <div>
                {editing ? (<><Spin /><br />Ожидайте</>) : (
                  <>
                    <div style={{ textAlign: 'left' }}>
                      <span><b>Имя клиента:</b></span><br />
                      <span>{customerDelBonus.name}</span><br /><br />
                    </div>
                    <Typography style={{ textAlign: 'left' }}>Сколько списать баллов:</Typography>
                    <Input
                      value={bonusToDel}
                      name="bonusToDel"
                      style={{ marginBottom: 10 }}
                      onChange={(e) => setBonusToDel(e.target.value)}
                    />
                    <Button type="primary" onClick={() => handleDelBonus()}>Списать</Button>
                  </>
                )}
              </div>
            </Modal>

            <Modal
              centered
              title={"История заказов"}
              style={{ textAlign: 'center' }}
              visible={showOrderHistory}
              onCancel={handleCloseOrderHistoryModal}
              footer={[
                <Button key="back" onClick={handleCloseOrderHistoryModal}>Закрыть</Button>
              ]}
            >
              <div>
                {orderHistoryLoading ? (<><Spin /><br />Загружаем список заказов</>) : (
                  <>
                    <table border="0" style={{ width: '100%', textAlign: 'center' }}>
                      <thead>
                        <tr style={{ backgroundColor: '#eeeeee' }}>
                          <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Заказ</th>
                          <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Сумма</th>
                          <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Дата</th>
                          <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Статус</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customerOrders.map((order, i) => {
                          return (
                            <tr style={{ borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'solid' }} key={i.toString()}>
                              <td style={{ borderRightColor: '#eeeeee', borderRightWidth: 1, borderRightStyle: 'solid' }}>{order.id}</td>
                              <td style={{ borderRightColor: '#eeeeee', borderRightWidth: 1, borderRightStyle: 'solid' }}>{parseInt(order.summa, 10) + parseInt(order.deliveryprice, 10)}</td>
                              <td style={{ borderRightColor: '#eeeeee', borderRightWidth: 1, borderRightStyle: 'solid' }}><Moment date={order.created_at} format="lll" locale="ru" /></td>
                              <td style={{ borderRightColor: '#eeeeee', borderRightWidth: 1, borderRightStyle: 'solid' }}>
                                {order.completed ? (<span style={{ color: 'green' }}>Завершен</span>) : order.canceled ? (<span style={{ color: 'red' }}>Отменен</span>) : (<span style={{ color: 'blue' }}>В работе</span>)}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </Modal>

            <Modal
              centered
              title={"История бонусных балов"}
              style={{ textAlign: 'left' }}
              visible={showBonusHistory}
              onCancel={handleCloseBonusHistoryModal}
              footer={[
                <Button key="back" onClick={handleCloseBonusHistoryModal}>Закрыть</Button>
              ]}
            >
              <div>
                <span><b>Имя клиента:</b></span><br />
                <span>{customerBonusHistory.name}</span><br /><br />

                <table border="0" style={{ width: '100%', textAlign: 'center' }}>
                  <thead>
                    <tr style={{ backgroundColor: '#eeeeee' }}>
                      <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Заказ</th>
                      <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Начислено</th>
                      <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Списано</th>
                      <th style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>Дата</th>
                      <th>Кем</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerBonusHistory.bonusHistory.map((history, i) => {
                      return (
                        <tr style={history.granted ? { backgroundColor: '#e0ffe9' } : { backgroundColor: '#ffe1e0' }} key={i.toString()}>
                          <td style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>{history.order_id}</td>
                          <td style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>{history.granted}</td>
                          <td style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>{history.removed}</td>
                          <td style={{ borderRightColor: '#ffffff', borderRightWidth: 1, borderRightStyle: 'solid' }}>{history.granted_at ? (<Moment date={history.granted_at} format="lll" locale="ru" />) : (<Moment date={history.removed_at} format="lll" locale="ru" />)}</td>
                          <td>{history.granted_by}{history.removed_by}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </Modal>

            <Modal
              centered
              title={"Добавление покупателя"}
              visible={showAdd}
              onCancel={handleCloseAddModal}
              footer={[
                <Button
                  key="back"
                  onClick={handleCloseAddModal}
                >
                  Отмена
                </Button>,
                <Button
                  key="yes"
                  type="primary"
                  disabled={adding}
                  onClick={handleAddCustomer}
                >
                  Сохранить
                </Button>,
              ]}
            >
              <div>
                <Row gutter={16}>
                  <Col className="gutter-row" style={{ textAlign: 'center' }} xs={24} sm={24}>
                    <Typography style={{ textAlign: 'left' }}>Имя:</Typography>
                    <Input
                      value={values.name}
                      name="name"
                      style={{ marginBottom: 10 }}
                      onChange={handleChange("name")}
                    />
                    <Typography style={{ textAlign: 'left' }}>Email:</Typography>
                    <Input
                      value={values.email}
                      name="email"
                      style={{ marginBottom: 10 }}
                      onChange={handleChange("email")}
                    />
                    <Typography style={{ textAlign: 'left' }}>Телефон:</Typography>
                    <Input
                      value={values.phone}
                      name="phone"
                      style={{ marginBottom: 10 }}
                      onChange={handleChange("phone")}
                    />
                    <Typography style={{ textAlign: 'left' }}>Пароль:</Typography>
                    <Input
                      value={values.password}
                      name="password"
                      style={{ marginBottom: 10 }}
                      onChange={handleChange("password")}
                    />
                  </Col>
                </Row>
              </div>
            </Modal>
            <Modal
              centered
              title={"Удалить покупателя?"}
              style={{ textAlign: 'center' }}
              visible={showDel}
              onCancel={handleCloseDelModal}
              footer={[
                <Button
                  key="back"
                  onClick={handleCloseDelModal}
                >
                  Отмена
                </Button>,
                <Button
                  key="yes"
                  type="danger"
                  onClick={handleDelCustomer}
                >
                  Удалить
                </Button>,
              ]}
            >
              <div>
                <p>{customerDel.name}</p>
              </div>
            </Modal>
            <Divider />
          </div>
        ] : null}
      </QueueAnim>
    );
  }

};

const useFetching = (getCustomersList, dispatch, rows) => {
  useEffect(() => {
    dispatch(getCustomersList(1, rows, false));
  }, []);
};

export default CPCustomers;
