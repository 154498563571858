import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  GET_CATEGORY_LIST,
  LOADING_CATEGORY,
  CATEGORY_ADDED,
  GET_CATEGORY_ERRORS,
  SHOW_ADD_CATEGORY_MODAL,
  CLOSE_ADD_CATEGORY_MODAL,
  SHOW_DEL_CATEGORY_MODAL,
  CLOSE_DEL_CATEGORY_MODAL,
  CATEGORY_DELETED,
  SHOW_ED_CATEGORY_MODAL,
  CLOSE_ED_CATEGORY_MODAL,
  CATEGORY_EDITED,
  SHOW_CATEGORY_IMG,
  CLOSE_CATEGORY_IMG,
  CATEGORY_IMG_ADDING,
  CATEGORY_IMG_ADDED,
  CATEGORY_IMG_DELETED
} from "./types";


export const addCategoryImg = (data) => dispatch => {
  console.log(data);
  dispatch(setCategoryImgEditing());
  axios
  .post(serverIp + "api/category/addimg", data)
  .then(res => {
      dispatch({
          type: CATEGORY_IMG_ADDED,
          payload: res.data
      });
  })
  .catch(err =>{});
}

export const deleteCategoryImg = (data) => dispatch => {
  dispatch(setCategoryImgEditing());
  console.log(data._id);
  axios
      .post(serverIp + "api/category/delimg", {"id": data._id})
      .then(res => {
          dispatch({
              type: CATEGORY_IMG_DELETED,
              payload: res.data
          });
      })
      .catch(err =>{});
}

export const closeCategoryImg = () => dispatch => {
  dispatch({
    type: CLOSE_CATEGORY_IMG
  });
}

export const showCategoryImg = (category) => dispatch => {
  dispatch({
    type: SHOW_CATEGORY_IMG,
    payload: category
  });
}

export const setCategoryImgEditing = () => dispatch => {
  dispatch({
    type: CATEGORY_IMG_ADDING
  });
};

// получаем товары
export const getCategoryList = () => dispatch => {
  console.log('getCategoryList');
  dispatch(setCategoryLoading());
  axios
    .get(serverIp + "api/category/getcategorylist")
    .then(res => {
      dispatch({
        type: GET_CATEGORY_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CATEGORY_ERRORS,
        payload: err.response
      })
    );
};

export const addCategory = (category) => dispatch => {
  axios
    .post(serverIp + "api/category/addcategory", category)
    .then(res => {
      dispatch({
        type: CATEGORY_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CATEGORY_ERRORS,
        payload: err.response
      })
    );
};

export const deleteCategory = (category) => dispatch => {
  axios
    .post(serverIp + "api/category/deletecategory", category)
    .then(res => {
      dispatch({
        type: CATEGORY_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CATEGORY_ERRORS,
        payload: err.response
      })
    );
};

export const editCategory = (category) => dispatch => {
  axios
    .post(serverIp + "api/category/editcategory", category)
    .then(res => {
      dispatch({
        type: CATEGORY_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CATEGORY_ERRORS,
        payload: err.response
      })
    );
};

// Загружаются корзины
export const setCategoryLoading = () => {
  return {
    type: LOADING_CATEGORY
  };
};

export const showAddCategoryModal = () => {
  return {
    type: SHOW_ADD_CATEGORY_MODAL
  };
};

export const closeAddCategoryModal = () => {
  return {
    type: CLOSE_ADD_CATEGORY_MODAL
  };
};

export const showDelCategoryModal = (category) => {
  return {
    type: SHOW_DEL_CATEGORY_MODAL,
    payload: category
  };
};

export const closeDelCategoryModal = () => {
  return {
    type: CLOSE_DEL_CATEGORY_MODAL
  };
};

export const showEdCategoryModal = (category) => {
  return {
    type: SHOW_ED_CATEGORY_MODAL,
    payload: category
  };
};

export const closeEdCategoryModal = () => {
  return {
    type: CLOSE_ED_CATEGORY_MODAL
  };
};
