import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Input, Button, Row, Col, Typography, Divider, Spin, Card, Icon, Layout, Table, Modal, Checkbox, Popconfirm, Tabs } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
    sendAnonymous,
    sendRegistered,
    sendClear
} from "../../actions/pushSendActions";

const { TabPane } = Tabs;

const CPPushSend = props => {
    //useFetching(getPaymentList, useDispatch());
    const dispatch = useDispatch();
    const sending = useSelector(state => state.pushsend.sending);

    const reg_accepted = useSelector(state => state.pushsend.reg_accepted);
    const reg_rejected = useSelector(state => state.pushsend.reg_rejected);
    const unreg_accepted = useSelector(state => state.pushsend.unreg_accepted);
    const unreg_rejected = useSelector(state => state.pushsend.unreg_rejected);

    const [registeredMSG, setRegisteredMSG] = React.useState({
        title: '',
        text: '',
    });
    const [anonymousMSG, setAnonymousMSG] = React.useState({
        title: '',
        text: '',
    });

    const handleSendRegistered = () => {
        dispatch(sendRegistered(registeredMSG));
    }
    const handleSendAnonymous = () => {
        dispatch(sendAnonymous(anonymousMSG));
    }

    const handleSendClearDubles = () => {
        dispatch(sendClear());
    }

    return (
        <QueueAnim className="anim-content"
            type={['right', 'left']}
            ease={['easeOutQuart', 'easeInOutQuart']}>
            <div key="a" className="content-div">
                <Divider className="header-div" orientation="left">Push - рассылка</Divider>
                <Row type="flex" justify="end">
                <Button disabled={sending} onClick={handleSendClearDubles}>УДАЛИТЬ ДУБЛИ</Button>
                </Row>

                <Tabs defaultActiveKey="1">
                    <TabPane tab="Зарегистрированные пользователи" key="1">
                        <Typography style={{ textAlign: 'left' }}>Заголовок:</Typography>
                        <Input
                            value={registeredMSG.title}
                            name="title"
                            style={{ marginBottom: 10 }}
                            onChange={(e) => setRegisteredMSG({...registeredMSG, "title": e.target.value})}
                        />
                        <Typography style={{ textAlign: 'left' }}>Сообщение:</Typography>
                        <Input
                            value={registeredMSG.text}
                            name="text"
                            style={{ marginBottom: 10 }}
                            onChange={(e) => setRegisteredMSG({...registeredMSG, "text": e.target.value})}
                        />
                        <Button disabled={sending} onClick={handleSendRegistered}>ОТПРАВИТЬ</Button>
                        
                        {sending ? (<Spin/>):(
                        <>
                        <br/><br/>
                            Доставлено: {reg_accepted}<br/>
                            Не доставлено: {reg_rejected}
                        </>
                        )}
                    </TabPane>
                    <TabPane tab="Анонимные пользователи" key="2">
                    <Typography style={{ textAlign: 'left' }}>Заголовок:</Typography>
                        <Input
                            value={anonymousMSG.title}
                            name="title"
                            style={{ marginBottom: 10 }}
                            onChange={(e) => setAnonymousMSG({...anonymousMSG, "title": e.target.value})}
                        />
                        <Typography style={{ textAlign: 'left' }}>Сообщение:</Typography>
                        <Input
                            value={anonymousMSG.text}
                            name="text"
                            style={{ marginBottom: 10 }}
                            onChange={(e) => setAnonymousMSG({...anonymousMSG, "text": e.target.value})}
                        />
                        <Button disabled={sending} onClick={handleSendAnonymous}>ОТПРАВИТЬ</Button>
                        
                        {sending ? (<Spin/>):(
                        <>
                        <br/><br/>
                            Доставлено: {unreg_accepted}<br/>
                            Не доставлено: {unreg_rejected}
                        </>
                        )}
                    </TabPane>
                </Tabs>


            </div>
        </QueueAnim>
    );

};

// const useFetching = (getPaymentList, dispatch) => {
// 	useEffect(() => {
// 		dispatch(getPaymentList());
// 	}, []);
// };

export default CPPushSend;
