import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Select, Typography, Divider, Button, Modal, Card, Row, Col, Spin, Input, Icon, Popover, Layout, Tabs, Popconfirm, List, Tag } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
	addOption,
	deleteOption,
	getOptionList,
	showAddOptionModal,
	closeAddOptionModal,
	showDelOptionModal,
	closeDelOptionModal,
	showAddOptionCheckboxModal,
	closeAddOptionCheckboxModal,
	addCheckboxOption,
	deleteCheckboxOption,
	showDelCheckboxOptionModal,
	closeDelCheckboxOptionModal,
	showAddOptionRadioModal,
	closeAddOptionRadioModal,
	addRadioOption,
	deleteRadioOption
} from "../../actions/optionActions";
const { Title, Text } = Typography;
const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const CPItemOptions = () => {

	useFetching(getOptionList, useDispatch());
	const options = useSelector(state => state.options.options);
	const checkboxes = useSelector(state => state.options.checkboxes);
	const radio = useSelector(state => state.options.radio);

	const availableTypes = useSelector(state => state.options.availableTypes);
	const loading = useSelector(state => state.options.isLoading);
	const showAdd = useSelector(state => state.options.showAdd);
	const showAddCheckbox = useSelector(state => state.options.showAddCheckbox);
	const showAddRadio = useSelector(state => state.options.showAddRadio);

	const showDel = useSelector(state => state.options.showDel);
	const showDelCheckbox = useSelector(state => state.options.showDelCheckbox);

	const optionDel = useSelector(state => state.options.optionDel);
	const checkboxOptionDel = useSelector(state => state.options.checkboxOptionDel);

	const addCheckboxBtnDisabled = useSelector(state => state.options.addCheckboxBtnDisabled);
	const delCheckboxBtnDisabled = useSelector(state => state.options.delCheckboxBtnDisabled);

	const addRadioBtnDisabled = useSelector(state => state.options.addRadioBtnDisabled);

	const addBtnDisabled = useSelector(state => state.options.addBtnDisabled);
	const dispatch = useDispatch();

	const handleSearchChange = name => event => {
		setSearchValue({ ...searchvalue, [name]: event.target.value });
	};

	const handleSearchChangeCheckbox = name => event => {
		setSearchCheckboxValue({ ...searchcheckboxvalue, [name]: event.target.value });
	};

	const handleOpenAddModal = useCallback(
		() => dispatch(showAddOptionModal()),
		[dispatch]
	);
	const handleOpenAddCheckboxModal = useCallback(
		() => dispatch(showAddOptionCheckboxModal()),
		[dispatch]
	);

	const handleOpenAddRadioModal = useCallback(
		() => dispatch(showAddOptionRadioModal()),
		[dispatch]
	);
	const handleCloseAddRadioModal = () => {
		dispatch(closeAddOptionRadioModal());
		setAddOptionRadioList({ show: false });
		setAddRadioValues({ "name": '', "options": [] });
		setAddRadioTmpValues({ "name": '' });
	};


	const handleDeleteRadio = (radio) => {
		//dispatch(closeAddOptionRadioModal());
		// setAddOptionCheckboxList({ show: false });
		// setAddCheckboxValues({ "name": '', "options": [] });
		// setAddCheckboxTmpValues({ "name": '' });

		dispatch(deleteRadioOption(radio));

	};



	const handleCloseAddModal = useCallback(
		() => dispatch(closeAddOptionModal()),
		[dispatch]
	);

	const handleCloseAddCheckboxModal = () => {
		dispatch(closeAddOptionCheckboxModal());
		setAddOptionCheckboxList({ show: false });
		setAddCheckboxValues({ "name": '', "options": [] });
		setAddCheckboxTmpValues({ "name": '' });
	};


	const [addbutton, setAddBtnValue] = React.useState({
		disabled: true
	});
	const [searchvalue, setSearchValue] = React.useState({
		search: ""
	});
	const [searchcheckboxvalue, setSearchCheckboxValue] = React.useState({
		search: ""
	});

	const [addvalues, setAddValues] = React.useState({
		type: '',
		name: '',
	});


	const [addradiovalues, setAddRadioValues] = React.useState({
		name: '',
		options: []
	});

	const [addcheckboxvalues, setAddCheckboxValues] = React.useState({
		name: '',
		options: []
	});

	const [addoptioncheckboxlist, setAddOptionCheckboxList] = React.useState({
		show: false
	});

	const [addoptionradiolist, setAddOptionRadioList] = React.useState({
		show: false
	});

	const [addcheckboxtmpvalues, setAddCheckboxTmpValues] = React.useState({
		name: '',
	});

	const [addradiotmpvalues, setAddRadioTmpValues] = React.useState({
		name: '',
	});

	const [nameerror, setNameError] = React.useState({
		show: false,
	});

	const handleChangeAddTmpCheckbox = name => event => {
		setAddCheckboxTmpValues({ ...addcheckboxtmpvalues, [name]: event.target.value });
	};
	const handleChangeAddTmpRadio = name => event => {
		setAddRadioTmpValues({ ...addradiotmpvalues, [name]: event.target.value });
	};


	function addTmpRadio() {
		let tmpRadio = {
			name: ''
		}
		tmpRadio.name = addradiotmpvalues.name;
		let optionsArr = [];
		optionsArr = addradiovalues.options;
		if (!optionsArr.some(item => addradiotmpvalues.name === item.name) && addradiotmpvalues.name !== '') {
			setNameError({ show: false })
			optionsArr.push(tmpRadio);
			console.log(optionsArr);
			setAddRadioValues({ ...addradiovalues, "options": optionsArr });
			setAddOptionRadioList({ show: false });
			setAddRadioTmpValues({ "name": '' });
		} else {
			setNameError({ show: true })
		}
	};

	function addTmpCheckbox() {
		let tmpCheckbox = {
			name: ''
		}
		tmpCheckbox.name = addcheckboxtmpvalues.name;
		let optionsArr = [];
		optionsArr = addcheckboxvalues.options;
		if (!optionsArr.some(item => addcheckboxtmpvalues.name === item.name) && addcheckboxtmpvalues.name !== '') {
			setNameError({ show: false })
			optionsArr.push(tmpCheckbox);
			console.log(optionsArr);
			setAddCheckboxValues({ ...addcheckboxvalues, "options": optionsArr });
			setAddOptionCheckboxList({ show: false });
			setAddCheckboxTmpValues({ "name": '' });
		} else {
			setNameError({ show: true })
		}
	};

	function handleDeleteTmpCheckbox(optionToDelete) {
		setAddCheckboxValues(addcheckboxvalues => ({
			...addcheckboxvalues,
			options: addcheckboxvalues.options.filter(option => option.name !== optionToDelete.name)
		}));
	};
	function handleDeleteTmpRadio(optionToDelete) {
		setAddRadioValues(addradiovalues => ({
			...addradiovalues,
			options: addradiovalues.options.filter(option => option.name !== optionToDelete.name)
		}));
	};

	const handleChangeAddCheckbox = name => event => {
		setAddCheckboxValues({ ...addcheckboxvalues, [name]: event.target.value });
	};
	const handleChangeAddRadio = name => event => {
		setAddRadioValues({ ...addradiovalues, [name]: event.target.value });
	};

	function handleChangeAddType(value) {
		if (value == 1) {
			setAddValues({ ...addvalues, "type_id": value, "type": "toggle" });
		}
		if (value == 2) {
			setAddValues({ ...addvalues, "type_id": value, "type": "checkbox" });
		}
		console.log(`selected ${value}`);
	}
	const handleChangeAdd = name => event => {
		setAddValues({ ...addvalues, [name]: event.target.value });
	};
	const showDeleteOptionModal = option => {
		dispatch(showDelOptionModal(option));
	};

	const showDeleteCheckboxOptionModal = option => {
		dispatch(showDelCheckboxOptionModal(option));
	};


	const handleCloseDelCheckboxModal = useCallback(
		() => dispatch(closeDelCheckboxOptionModal()),
		[dispatch]
	);

	const handleCloseDelModal = useCallback(
		() => dispatch(closeDelOptionModal()),
		[dispatch]
	);

	const handleDelCheckboxOption = () => {
		dispatch(deleteCheckboxOption(checkboxOptionDel));
	};

	const handleDelOption = () => {
		dispatch(deleteOption(optionDel));
	};

	function handleAddCheckboxOption() {
		if (addcheckboxvalues.name != '') {
			dispatch(addCheckboxOption(addcheckboxvalues));
		}
	};

	function handleAddRadioOption() {
		if (addradiovalues.name != '') {
			dispatch(addRadioOption(addradiovalues));
		}

	};

	function handleAddOption() {
		if (addvalues.type != '' && addvalues.name != '') {
			dispatch(addOption(addvalues));
			setAddValues({ name: '', type: '', type_id: '' });
		} else {
			console.log('Не заполнены поля');
		}
	};

	const toggleinfo = (
		<div>
			<p>Опция "Переключатель" имеет 2 позиции и позволяет пользователю переключаться между ними.</p>
			<p style={{ fontWeight: 600 }}>Значения присваиваются к каждому товару отдельно на странице "Товары"</p>
			<p>1 позиция - это состояние товара по умолчанию.</p>
			<p>2 позиция - это то, как изменится товар, если пользователь выберет эту опцию.</p>
			<p style={{ fontWeight: 600 }}>К примеру: Пирог 500гр ( по умолчанию ) / Пирог 1000гр ( можно выбрать и увеличится стоимость )</p>
		</div>
	);

	function renderInfo() {
		switch (addvalues.type) {
			case "toggle":
				return (
					<div>
						<Popover content={toggleinfo} title="Переключатель" trigger="click">
							<Button icon="info-circle" type="primary" style={{ width: '100%' }}>Описание опции</Button>
						</Popover>
					</div>
				);
				break;
			default:
				return (<div></div>);
		}
	};
	function renderAdd() {
		switch (addvalues.type) {
			case "toggle":
				return (
					<div>
						<Divider className="header-div" />
						<Input style={{ marginBottom: 10, marginTop: 10 }} addonBefore="Название" value={addvalues.name} onChange={handleChangeAdd("name")} />
					</div>
				);
				break;
			default:
				return (<div></div>);
		}

	};
	function renderSort() {
		var updatedList = options;
		updatedList = updatedList.filter(function (optionfilter) {
			return (
				optionfilter.name
					.toLowerCase()
					.search(searchvalue.search.toLowerCase()) !== -1
			);
		});

		if (updatedList.length > 0) {
			return (
				<div>
					{updatedList.map((option, i) => (
						<div key={i} style={{ border: '1px solid #e8e8e8', padding: 10, marginBottom: 20 }}>
							<Row gutter={16} style={{ marginBottom: 10 }}>
								<Col className="gutter-row" sm={23} xs={23}>
									<div style={{ float: 'left', paddingLeft: 10, paddingRight: 10, }}>
										<p style={{ fontWeight: 600 }}>Название:</p>
										<p>{option.name}</p>
									</div>
									<div style={{ float: 'left', paddingLeft: 10, paddingRight: 10, }}>
										<p style={{ fontWeight: 600 }}>Тип:</p>
										<p>{option.type}</p>
									</div>
								</Col>
								<Col style={{ textAlign: 'right' }} className="gutter-row" sm={1} xs={1}>
									<Button style={{ marginRight: 5 }} size="small" type="danger" shape="circle" icon="delete" onClick={() => showDeleteOptionModal(option)} />
								</Col>
							</Row>
						</div>
					))}
				</div>
			);
		} else {
			return (
				<div>
					<Card title="Опций не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	};


	function renderListAddCheckbox() {
		if (addcheckboxvalues.options.length > 0) {
			return (
				<List
					itemLayout="horizontal"
					dataSource={addcheckboxvalues.options}
					renderItem={item => (
						<List.Item
							actions={[
								<Popconfirm title="Удалить опцию из списка?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDeleteTmpCheckbox(item)}>
									<Button style={{ marginRight: 5 }} size="small" type="danger" shape="circle" icon="cross" />
								</Popconfirm>

							]}
						>
							<List.Item.Meta
								title={item.name}
							/>
						</List.Item>
					)}
				/>
			);

		} else {
			return (
				<div>
					<p style={{ textAlign: 'center' }}>Добавьте доступные опции.</p>
				</div>
			);
		}
	}
	function renderAddCheckbox() {
		if (addoptioncheckboxlist.show) {
			return (
				<div>
					{nameerror.show ? (<p style={{ textAlign: 'center', color: 'red' }}>Такая опция уже есть в списке, или "Название" пустое.</p>) : (<div></div>)}
					<Input style={{ marginBottom: 10, marginTop: 10 }} addonBefore="Название" value={addcheckboxtmpvalues.name} onChange={handleChangeAddTmpCheckbox("name")} />
					<Button type="primary" icon="plus" onClick={() => addTmpCheckbox()}>Добавить в список</Button>
				</div>
			);
		} else {
			return (
				<div style={{ textAlign: 'center' }}>
					<Button type="primary" icon="plus" onClick={() => setAddOptionCheckboxList({ show: true })}>Добавить</Button>
				</div>
			);
		}
	}


	function renderListAddRadio() {
		if (addradiovalues.options.length > 0) {
			return (
				<List
					itemLayout="horizontal"
					dataSource={addradiovalues.options}
					renderItem={item => (
						<List.Item
							actions={[
								<Popconfirm title="Удалить опцию из списка?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDeleteTmpRadio(item)}>
									<Button style={{ marginRight: 5 }} size="small" type="danger" shape="circle" icon="cross" />
								</Popconfirm>

							]}
						>
							<List.Item.Meta
								title={item.name}
							/>

						</List.Item>
					)}
				/>
			);

		} else {
			return (
				<div>
					<p style={{ textAlign: 'center' }}>Добавьте доступные опции.</p>
				</div>
			);
		}
	}
	function renderAddRadio() {
		if (addoptionradiolist.show) {
			return (
				<div style={{ textAlign: 'center' }}>
					{nameerror.show ? (<p style={{ textAlign: 'center', color: 'red' }}>Такая опция уже есть в списке, или "Название" пустое.</p>) : (<div></div>)}
					<Input style={{ marginBottom: 10, marginTop: 10 }} addonBefore="Название" value={addradiotmpvalues.name} onChange={handleChangeAddTmpRadio("name")} />
					<Button type="primary" icon="plus" onClick={() => addTmpRadio()}>Добавить в список</Button>
				</div>
			);
		} else {
			return (
				<div style={{ textAlign: 'center' }}>
					<Button type="primary" icon="plus" onClick={() => setAddOptionRadioList({ show: true })}>Добавить</Button>
				</div>
			);
		}
	}
	function renderSortRadio() {
		if (radio.length > 0) {
			return (
				<div>
					{radio.map((option, i) => (
						<div key={i} style={{ border: '1px solid #e8e8e8', padding: 10, marginBottom: 20 }}>
							<Row gutter={16} style={{ marginBottom: 10 }}>
								<Col className="gutter-row" sm={23} xs={23}>
									<div style={{ float: 'left', paddingLeft: 10, paddingRight: 10, }}>
										<p style={{ fontWeight: 600 }}>Название:</p>
										<p>{option.name}</p>
									</div>
									<p style={{ fontWeight: 600 }}>Список опций:</p>
									{option.options.map((tag, i) => (
										<Tag>{tag.name}</Tag>
									))}
								</Col>
								<Col style={{ textAlign: 'right' }} className="gutter-row" sm={1} xs={1}>
									<Popconfirm title="Удалить группу?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDeleteRadio(option)}>
										<Button style={{ marginRight: 5 }} size="small" type="danger" shape="circle" icon="cross" />
									</Popconfirm>

								</Col>
							</Row>
						</div>
					))}
				</div>
			)
		} else {
			return (
				<div>
					<Card title="Опций не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	}

	function renderSortCheckbox() {
		var updatedCheckboxList = checkboxes;
		updatedCheckboxList = updatedCheckboxList.filter(function (optionfilter) {
			return (
				optionfilter.name
					.toLowerCase()
					.search(searchcheckboxvalue.search.toLowerCase()) !== -1
			);
		});

		if (updatedCheckboxList.length > 0) {
			return (
				<div>
					{updatedCheckboxList.map((option, i) => (
						<div key={i} style={{ border: '1px solid #e8e8e8', padding: 10, marginBottom: 20 }}>
							<Row gutter={16} style={{ marginBottom: 10 }}>
								<Col className="gutter-row" sm={23} xs={23}>
									<div style={{ float: 'left', paddingLeft: 10, paddingRight: 10, }}>
										<p style={{ fontWeight: 600 }}>Название:</p>
										<p>{option.name}</p>
									</div>
									<p style={{ fontWeight: 600 }}>Список опций:</p>
									{option.options.map((tag, i) => (
										<Tag>{tag.name}</Tag>
									))}
								</Col>
								<Col style={{ textAlign: 'right' }} className="gutter-row" sm={1} xs={1}>
									<Button style={{ marginRight: 5 }} size="small" type="danger" shape="circle" icon="delete" onClick={() => showDeleteCheckboxOptionModal(option)} />
								</Col>
							</Row>
						</div>
					))}
				</div>
			);
		} else {
			return (
				<div>
					<Card title="Опций не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	};

	if (loading) {
		return (
			<div className="content-div">
				<Divider className="header-div" orientation="left"><Spin /></Divider>
			</div>
		);
	} else {
		return (
			<QueueAnim className="anim-content"
				type={['right', 'left']}
				ease={['easeOutQuart', 'easeInOutQuart']}>
				{!loading ? [
					<div key="a" className="content-div">
						<div>
							<Divider className="header-div" orientation="left">Опции товаров</Divider>
						</div>

						<Tabs defaultActiveKey="1">
							<TabPane tab="Группы радио опций" key="1">
								<Divider />
								{renderSortRadio()}
								<Card style={{ width: '100%', marginBottom: 20 }} size="small">
									<Button type="primary" icon="plus" onClick={handleOpenAddRadioModal}>Добавить</Button>
								</Card>
							</TabPane>
							<TabPane tab="Переключатели" key="2">
								<Row type="flex" justify="end">
									<Search
										placeholder="Поиск по опциям"
										onChange={handleSearchChange("search")}
										style={{ width: 200 }}
									/>
								</Row>
								<Divider />
								{renderSort()}
								<Card style={{ width: '100%', marginBottom: 20 }} size="small">
									<Button type="primary" icon="plus" onClick={handleOpenAddModal}>Добавить</Button>
								</Card>
							</TabPane>

							{/* <TabPane tab="Группы чекбоксов" key="2">
									<Row type="flex" justify="end">
										<Search
											placeholder="Поиск по опциям"
											onChange={handleSearchChangeCheckbox("search")}
											style={{ width: 200 }}
										/>
									</Row>
									<Divider />
									{renderSortCheckbox()}
									<Card style={{ width: '100%', marginBottom: 20 }} size="small">
										<Button type="primary" icon="plus" onClick={handleOpenAddCheckboxModal}>Добавить</Button>
									</Card>
								</TabPane> */}
						</Tabs>

						<Modal
							closable={false}
							maskClosable={false}
							centered
							title={"Добавление radio опции"}
							visible={showAddRadio}
							onCancel={handleCloseAddRadioModal}
							footer={[<Button key="back" onClick={handleCloseAddRadioModal} disabled={addRadioBtnDisabled}>Отмена</Button>, <Button key="yes" disabled={addRadioBtnDisabled} type="primary" onClick={handleAddRadioOption}>Сохранить</Button>]}
						>
							<Row gutter={16} style={{ marginBottom: 10 }}>
								<Typography style={{ textAlign: 'left' }}>Название группы:</Typography>
								<Input style={{ marginBottom: 10, marginTop: 10 }} value={addradiovalues.name} onChange={handleChangeAddRadio("name")} />
							</Row>
							{renderListAddRadio()}
							{renderAddRadio()}
							{/* {renderListAddCheckbox()}
								{renderAddCheckbox()} */}
						</Modal>

						<Modal
							closable={false}
							maskClosable={false}
							centered
							title={"Добавление чекбокса"}
							visible={showAddCheckbox}
							onCancel={handleCloseAddCheckboxModal}
							footer={[<Button key="back" onClick={handleCloseAddCheckboxModal} disabled={addCheckboxBtnDisabled}>Отмена</Button>, <Button key="yes" disabled={addCheckboxBtnDisabled} type="primary" onClick={handleAddCheckboxOption}>Сохранить</Button>]}
						>
							<Row gutter={16} style={{ marginBottom: 10 }}>
								<Typography>Название группы:</Typography>
								<Input style={{ marginBottom: 10, marginTop: 10 }} value={addcheckboxvalues.name} onChange={handleChangeAddCheckbox("name")} />
							</Row>
							{renderListAddCheckbox()}
							{renderAddCheckbox()}
						</Modal>

						<Modal
							closable={false}
							maskClosable={false}
							centered
							title={"Добавление опции"}
							visible={showAdd}
							onCancel={handleCloseAddModal}
							footer={[<Button key="back" onClick={handleCloseAddModal} disabled={addBtnDisabled}>Отмена</Button>, <Button key="yes" disabled={addBtnDisabled} type="primary" onClick={handleAddOption}>Сохранить</Button>]}
						>
							<Row gutter={16} style={{ marginBottom: 10 }}>
								<Col className="gutter-row" sm={12} xs={12} style={{ marginBottom: 10 }}>
									<Select defaultValue={addvalues.type} style={{ width: '100%' }} placeholder="Выберите тип добавляемой опции" onChange={handleChangeAddType}>
										{availableTypes.map((avtype, i) => (
											<Option key={i} value={avtype.id}>{avtype.name}</Option>
										))}
									</Select>
								</Col>
								<Col className="gutter-row" sm={12} xs={12}>
									{renderInfo()}
								</Col>
								<Col className="gutter-row" sm={24} xs={24}>
									{renderAdd()}
								</Col>
							</Row>
						</Modal>

						<Modal
							centered
							closable={false}
							maskClosable={false}
							title={"Удалить группу?"}
							style={{ textAlign: 'center' }}
							visible={showDelCheckbox}
							onCancel={handleCloseDelCheckboxModal}
							footer={[
								<Button
									key="back"
									disabled={delCheckboxBtnDisabled}
									onClick={handleCloseDelCheckboxModal}
								>
									Отмена
									</Button>,
								<Button
									key="yes"
									type="danger"
									disabled={delCheckboxBtnDisabled}
									onClick={handleDelCheckboxOption}
								>
									Удалить
									</Button>,
							]}
						>
							<div>
								<p>{checkboxOptionDel.name}</p>
								<p style={{ textAlign: 'center', color: 'red' }}>Это действие удалит данную группу у всех товаров, где она встречается.</p>
							</div>
						</Modal>

						<Modal
							centered
							title={"Удалить опцию?"}
							style={{ textAlign: 'center' }}
							visible={showDel}
							onCancel={handleCloseDelModal}
							footer={[
								<Button
									key="back"
									onClick={handleCloseDelModal}
								>
									Отмена
									</Button>,
								<Button
									key="yes"
									type="danger"
									onClick={handleDelOption}
								>
									Удалить
									</Button>,
							]}
						>
							<div>
								<p>{optionDel.name}</p>
								<p style={{ textAlign: 'center', color: 'red' }}>Это действие удалит данную опцию у всех товаров, где она встречается.</p>
							</div>
						</Modal>

					</div>
				] : null}
			</QueueAnim>
		);
	}
};

const useFetching = (getOptionList, dispatch) => {
	useEffect(() => {
		dispatch(getOptionList());
	}, []);
};

export default CPItemOptions;
