import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { editOrderStatus, getApiSettings, editApiSettings, saveApiSettings, editClientStatus, editCrmStatus } from '../../actions/apiSettingActions'
import { bindActionCreators } from 'redux';
import { Row, Col, Select, Input, Button, Typography, Divider, Spin, Tabs, Layout, Table, Tag, Checkbox } from 'antd';
import QueueAnim from 'rc-queue-anim';
import ColorPicker from 'rc-color-picker';
import 'rc-color-picker/assets/index.css';

const { Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const orderStatusesColumn = [
	{
		title: 'API',
		dataIndex: 'apiname',
		key: 'apiname',
	},
	{
		title: 'Цвет',
		dataIndex: 'apicolor',
		key: 'apicolor',
		render: apicolor => {
			return (
				<>
					<Tag color={apicolor}>ЦВЕТ МЕТКИ</Tag>
				</>
			)
		}
	},
	{
		title: 'Клиент',
		dataIndex: 'clientname',
		key: 'clientname',
	},
	{
		title: 'Цвет',
		dataIndex: 'clientcolor',
		key: 'clientcolor',
		render: color => {
			return (
				<>
					<Tag color={color}>ЦВЕТ МЕТКИ</Tag>
				</>
			)
		}
	},
	{
		title: 'Начислить бонусы',
		dataIndex: 'grantbonuses',
		key: 'grantbonuses',
		render: bool => {
			if(bool){
				return (
					<>
						<Tag color="#5b8c00">Да</Tag>
					</>
				)
			} else {
				return (
					<>
						<Tag>Нет</Tag>
					</>
				)
			}
		}
	},
	{
		title: 'Оплата',
		dataIndex: 'grantcashpay',
		key: 'grantcashpay',
		render: bool => {
			if(bool){
				return (
					<>
						<Tag color="#5b8c00">Да</Tag>
					</>
				)
			} else {
				return (
					<>
						<Tag>Нет</Tag>
					</>
				)
			}
		}
	},
	{
		title: 'Отмена',
		dataIndex: 'ordercancel',
		key: 'ordercancel',
		render: bool => {
			if(bool){
				return (
					<>
						<Tag color="#5b8c00">Да</Tag>
					</>
				)
			} else {
				return (
					<>
						<Tag>Нет</Tag>
					</>
				)
			}
		}
	},
	{
		title: 'Выполнен',
		dataIndex: 'ordercomplete',
		key: 'ordercomplete',
		render: bool => {
			if(bool){
				return (
					<>
						<Tag color="#5b8c00">Да</Tag>
					</>
				)
			} else {
				return (
					<>
						<Tag>Нет</Tag>
					</>
				)
			}
		}
	},
	{
		title: 'Используется курьером?',
		dataIndex: 'iscourier',
		key: 'iscourier',
		render: bool => {
			if(bool){
				return (
					<>
						<Tag color="#5b8c00">Да</Tag>
					</>
				)
			} else {
				return (
					<>
						<Tag>Нет</Tag>
					</>
				)
			}
		}
	},
	{
		title: 'Удалять факт доставки?',
		dataIndex: 'cleardeliver',
		key: 'cleardeliver',
		render: bool => {
			if(bool){
				return (
					<>
						<Tag color="#5b8c00">Да</Tag>
					</>
				)
			} else {
				return (
					<>
						<Tag>Нет</Tag>
					</>
				)
			}
		}
	},
]

class CPApiSettings extends Component {
	constructor(props) {
		super(props);
		this.handleEditChange = this.handleEditChange.bind(this);
	}
	componentDidMount() {
		this.props.getApiSettings();
	}

	state = {
		canAddClientStatus: false,
		canAddCrmStatus: false,
		newClientStatus: '',
		newClientStatusColor: '#ff0000',
		newCrmStatus: '',
		newCrmStatusColor: '#ff0000',
		newapiname: '',
		newapicolor: '#ff0000',
		newclientname: '',
		newclientcolor: '#ff0000',
		newgrantbonuses:false,
		newgrantcashpay:false,
		newcleardeliver:false,
		newordercancel:false,
		newordercomplete:false,
		newiscourier:false,
		canAddOrderStatus:false,
	};

	handleEditChange = (e, from) => {
		//СМС
		var newSmscAuth = this.props.smscAuth;
		var newSmscLogin = this.props.smscLogin;
		var newSmscPassword = this.props.smscPassword;
		//Оплата
		var newOnlinePaymentType = this.props.onlinePaymentType;
		var newFondyMerchantId = this.props.fondyMerchantId;

		var newYandexShopId = this.props.yandexShopId;
		var newYandexKey = this.props.yandexKey;
		
		var newCloudShopId = this.props.cloudShopId;
		var newCloudKey = this.props.cloudKey;

		var newApplePay = this.props.applePay;
		var newGooglePay = this.props.googlePay;

		var newCurrencySymbol = this.props.currencySymbol;
		var newCurrencyCode = this.props.currencyCode;

		var newClientInitStatus = this.props.clientInitStatus;
		var newCrmInitStatus = this.props.crmInitStatus;

		var newClientInitStatusColor = this.props.clientInitStatusColor;
		var newCrmInitStatusColor = this.props.crmInitStatusColor;

		var newDadataEnabled = this.props.dadataEnabled;
		var newDadataAPI = this.props.dadataAPI;

		//Селекторы
		if (from == 'smscAuth') {
			newSmscAuth = e;
		}
		if (from == 'onlinePaymentType') {
			newOnlinePaymentType = e;
		}

		if (from == 'applePay') {
			newApplePay = e;
		}
		if (from == 'googlePay') {
			newGooglePay = e;
		}
		if (from == 'dadataEnabled') {
			newDadataEnabled = e;
		}
		if (from == 'currencySymbol') {
			newCurrencySymbol = e;
		}
		if (from == 'currencyCode') {
			newCurrencyCode = e;
		}
		if (from == 'clientInitStatusColor') {
			newClientInitStatusColor = e.color;
		}
		if (from == 'crmInitStatusColor') {
			newCrmInitStatusColor = e.color;
		}

		if (e.target != null) {
			//Смс
			if (e.target.name == 'smscAuth') {
				newSmscAuth = e.target.value;
			}
			if (e.target.name == 'smscLogin') {
				newSmscLogin = e.target.value;
			}
			if (e.target.name == 'smscPassword') {
				newSmscPassword = e.target.value;
			}
			//Оплата
			if (e.target.name == 'fondyMerchantId') {
				console.log('fondyMerchantId');
				newFondyMerchantId = Number(e.target.value);
			}
			if (e.target.name == 'yandexShopId') {
				newYandexShopId = Number(e.target.value);
			}
			if (e.target.name == 'yandexKey') {
				newYandexKey = e.target.value;
			}
			if (e.target.name == 'cloudShopId') {
				newCloudShopId = e.target.value;
			}
			if (e.target.name == 'cloudKey') {
				newCloudKey = e.target.value;
			}

			//Статусы
			if (e.target.name == 'clientInitStatus') {
				newClientInitStatus = e.target.value;
			}
			if (e.target.name == 'crmInitStatus') {
				newCrmInitStatus = e.target.value;
			}
			//DAdata
			if (e.target.name == 'dadataAPI') {
				newDadataAPI = e.target.value;
			}

		}
		const data = {
			newSmscAuth,
			newSmscLogin,
			newSmscPassword,
			newOnlinePaymentType,
			newFondyMerchantId,
			newYandexShopId,
			newYandexKey,
			newCloudShopId,
			newCloudKey,
			newApplePay,
			newGooglePay,
			newCurrencySymbol,
			newCurrencyCode,
			newClientInitStatus,
			newClientInitStatusColor,
			newCrmInitStatus,
			newCrmInitStatusColor,
			newDadataEnabled,
			newDadataAPI,
		}
		this.props.editApiSettings(data);
	};

	handleSaveButton = (e) => {
		//Смс
		var smscAuth = this.props.smscAuth;
		var smscLogin = this.props.smscLogin;
		var smscPassword = this.props.smscPassword;

		//Оплата
		var onlinePaymentType = this.props.onlinePaymentType;
		var fondyMerchantId = this.props.fondyMerchantId;

		var yandexShopId = this.props.yandexShopId;
		var yandexKey = this.props.yandexKey;

		var cloudShopId = this.props.cloudShopId;
		var cloudKey = this.props.cloudKey;

		var applePay = this.props.applePay;
		var googlePay = this.props.googlePay;

		var currencySymbol = this.props.currencySymbol;
		var currencyCode = this.props.currencyCode;

		var clientInitStatus = this.props.clientInitStatus;
		var crmInitStatus = this.props.crmInitStatus;
		var clientInitStatusColor = this.props.clientInitStatusColor;
		var crmInitStatusColor = this.props.crmInitStatusColor;

		var customClientStatuses = this.props.customClientStatuses;
		var customCrmStatuses = this.props.customCrmStatuses;

		var dadataEnabled = this.props.dadataEnabled;
		var dadataAPI = this.props.dadataAPI;
		var orderStatuses = this.props.orderStatuses;

		const setsettings = {
			smscAuth,
			smscLogin,
			smscPassword,
			onlinePaymentType,
			fondyMerchantId,
			yandexShopId,
			yandexKey,
			cloudShopId,
			cloudKey,
			applePay,
			googlePay,
			currencySymbol,
			currencyCode,
			clientInitStatus,
			crmInitStatus,
			clientInitStatusColor,
			crmInitStatusColor,
			customClientStatuses,
			customCrmStatuses,
			dadataEnabled,
			dadataAPI,
			orderStatuses
		}
		this.props.saveApiSettings(setsettings);
	};



	handleChangeNewOrderStatus = (e, from) => {

		if (from == 'newapicolor') {
			this.setState({ newapicolor: e.color })
		}
		if (from == 'newclientcolor') {
			this.setState({ newclientcolor: e.color })
		}

		if (e.target != null) {
			if (e.target.name == 'newapiname') {
				this.setState({ newapiname: e.target.value })
			}
			if (e.target.name == 'newclientname') {
				this.setState({ newclientname: e.target.value })
			}
			if (e.target.name == 'newgrantbonuses') {
				this.setState({ newgrantbonuses: e.target.checked })
			}
			if (e.target.name == 'newgrantcashpay') {
				this.setState({ newgrantcashpay: e.target.checked })
			}
			if (e.target.name == 'newordercancel') {
				this.setState({ newordercancel: e.target.checked })
			}
			if (e.target.name == 'newordercomplete') {
				this.setState({ newordercomplete: e.target.checked })
			}
			if (e.target.name == 'newiscourier') {
				this.setState({ newiscourier: e.target.checked })
			}
			if (e.target.name == 'newcleardeliver') {
				this.setState({ newcleardeliver: e.target.checked })
			}
		}
		if (this.state.newapiname.length > 3 && this.state.newclientname.length > 3) {
			this.setState({ canAddOrderStatus: true })
		} else {
			this.setState({ canAddOrderStatus: false })
		}
	}

	
	handleAddNewOrderStatus = () => {
		if (this.state.newapiname.length > 3 && this.state.newclientname.length > 3) {
			let tempNewStatus = {
				apiname: this.state.newapiname,
				clientname: this.state.newclientname,
				apicolor: this.state.newapicolor,
				clientcolor: this.state.newclientcolor,
				grantbonuses: this.state.newgrantbonuses,
				grantcashpay: this.state.newgrantcashpay,
				ordercancel: this.state.newordercancel,
				ordercomplete: this.state.newordercomplete,
				iscourier: this.state.newiscourier,
				cleardeliver: this.state.newcleardeliver
			}
			let newOrderStatuses = this.props.orderStatuses;
			newOrderStatuses.push(tempNewStatus);
			console.log(newOrderStatuses);
			this.props.editOrderStatus(newOrderStatuses);
			this.setState({ canAddOrderStatus: false });
			this.setState({ newapiname: '' });
			this.setState({ newclientname: '' });
			this.setState({ newapicolor: '' });
			this.setState({ newclientcolor: '' });
			this.setState({ newgrantbonuses:false });
			this.setState({ newgrantcashpay: false });
			this.setState({ newordercancel: false });
			this.setState({ newordercomplete: false });
			this.setState({ newiscourier: false });
			this.setState({ newcleardeliver: false });
		}
	};




	render() {
		const loading = this.props.isLoading;
		const needSave = this.props.needSave;
		//Смс
		const smscAuth = this.props.smscAuth;
		const smscLogin = this.props.smscLogin;
		const smscPassword = this.props.smscPassword;
		//Оплата
		const onlinePaymentType = this.props.onlinePaymentType;
		const fondyMerchantId = this.props.fondyMerchantId;
		const yandexShopId = this.props.yandexShopId;
		const yandexKey = this.props.yandexKey;
		const cloudShopId = this.props.cloudShopId;
		const cloudKey = this.props.cloudKey;

		const applePay = this.props.applePay;
		const googlePay = this.props.googlePay;

		const currencySymbol = this.props.currencySymbol;
		const currencyCode = this.props.currencyCode;

		const clientInitStatus = this.props.clientInitStatus;
		const crmInitStatus = this.props.crmInitStatus;

		const clientInitStatusColor = this.props.clientInitStatusColor;
		const crmInitStatusColor = this.props.crmInitStatusColor;

		const customClientStatuses = this.props.customClientStatuses;
		const customCrmStatuses = this.props.customCrmStatuses;

		const orderStatuses = this.props.orderStatuses;

		const dadataEnabled = this.props.dadataEnabled;
		const dadataAPI = this.props.dadataAPI;

		// console.log('onlinePaymentType ' + onlinePaymentType);

		if (loading) {
			return (
					<div className="content-div">
						<Divider className="header-div" orientation="left"><Spin /></Divider>
					</div>
			);
		} else {
			return (
					<QueueAnim className="anim-content"
						type={['right', 'left']}
						ease={['easeOutQuart', 'easeInOutQuart']}>
						{!loading ? [
							<div key="a" className="content-div">
								<div>
									<Divider className="header-div" orientation="left">Настройки API</Divider>
								</div>
								<Tabs defaultActiveKey="2">

									<TabPane tab="Авторизация" key="2">

										<Typography>Тип авторизации:</Typography>
										<Select
											defaultValue={smscAuth}
											style={{ width: 220, marginBottom: 10 }}
											onChange={(e) => this.handleEditChange(e, "smscAuth")}
										>
											<Option value={0}>Email</Option>
											<Option value={1}>Номер телефона</Option>
											<Option value={2}>smsc.ru</Option>
										</Select>
										{smscAuth === 2 ? (
											<>
												<Typography>Логин smsc.ru:</Typography>
												<Input
													value={smscLogin}
													name="smscLogin"
													style={{ width: 220, marginBottom: 10 }}
													onChange={(e) => this.handleEditChange(e, "smscLogin")}
												/>
												<Typography>Пароль smsc.ru:</Typography>
												<Input
													value={smscPassword}
													name="smscPassword"
													style={{ width: 220, marginBottom: 10 }}
													onChange={(e) => this.handleEditChange(e, "smscPassword")}
												/>
											</>
										) : (<></>)}


									</TabPane>

									<TabPane tab="Подсказки адреса" key="3">
										<Typography>DADATA.RU:</Typography>
										<Select
											defaultValue={dadataEnabled}
											style={{ width: 220, marginBottom: 10 }}
											onChange={(e) => this.handleEditChange(e, "dadataEnabled")}
										>
											<Option value={false}>Выключено</Option>
											<Option value={true}>Включено</Option>
										</Select>
										<Typography>Ключ API:</Typography>
										<Input
											value={dadataAPI}
											name="dadataAPI"
											style={{ width: 220, marginBottom: 10 }}
											onChange={(e) => this.handleEditChange(e, "dadataAPI")}
										/>
									</TabPane>

									<TabPane tab="Валюта" key="4">
										<Typography>Валюта:</Typography>
										<Select
											defaultValue={currencyCode}
											style={{ width: 220, marginBottom: 10 }}
											onChange={(e) => this.handleEditChange(e, "currencyCode")}
										>
											<Option value={'RUB'}>Российский Рубль</Option>
											<Option value={'KZT'}>Казахстанский Тенге</Option>
										</Select>
										<Typography>Символ валюты:</Typography>
										<Select
											defaultValue={currencySymbol}
											style={{ width: 220, marginBottom: 10 }}
											onChange={(e) => this.handleEditChange(e, "currencySymbol")}
										>
											<Option value={'₽'}>₽</Option>
											<Option value={'₸'}>₸</Option>
										</Select>
									</TabPane>

									<TabPane tab="Методы онлайн оплаты" key="4">

										<Typography>Apple Pay:</Typography>
										<Select
											defaultValue={applePay}
											style={{ width: 220, marginBottom: 10 }}
											onChange={(e) => this.handleEditChange(e, "applePay")}
										>
											<Option value={0}>Нет</Option>
											<Option value={1}>Да</Option>
										</Select>

										<Typography>Google Pay:</Typography>
										<Select
											defaultValue={googlePay}
											style={{ width: 220, marginBottom: 10 }}
											onChange={(e) => this.handleEditChange(e, "googlePay")}
										>
											<Option value={0}>Нет</Option>
											<Option value={1}>Да</Option>
										</Select>

										<Typography>Обработчик платежей картами:</Typography>
										<Select
											defaultValue={onlinePaymentType}
											style={{ width: 220, marginBottom: 10 }}
											onChange={(e) => this.handleEditChange(e, "onlinePaymentType")}
										>
											<Option value={0}>Нет</Option>
											<Option value={1}>FONDY</Option>
											<Option value={2}>Yandex.Kassa</Option>
											<Option value={3}>CloudPayments</Option>
										</Select>

										{this.props.onlinePaymentType == 1 ? (<div><Typography>Merchant ID:</Typography><Input value={fondyMerchantId} name="fondyMerchantId" style={{ width: 220, marginBottom: 10 }} onChange={(e) => this.handleEditChange(e, "fondyMerchantId")} /></div>) : null}

										{this.props.onlinePaymentType == 2 ? (<div><Typography>Shop ID:</Typography><Input value={yandexShopId} name="yandexShopId" style={{ width: 220, marginBottom: 10 }} onChange={(e) => this.handleEditChange(e, "yandexShopId")} /><Typography>Yandex Key:</Typography><Input value={yandexKey} name="yandexKey" style={{ width: 220, marginBottom: 10 }} onChange={(e) => this.handleEditChange(e, "yandexKey")} /></div>) : null}

										{/* const cloudShopId = this.props.cloudShopId;
		const cloudKey = this.props.cloudKey; */}

										{this.props.onlinePaymentType == 3 ? (<div><Typography>cloudShopId:</Typography><Input value={cloudShopId} name="cloudShopId" style={{ width: 220, marginBottom: 10 }} onChange={(e) => this.handleEditChange(e, "cloudShopId")} /><Typography>cloudKey:</Typography><Input value={cloudKey} name="cloudKey" style={{ width: 220, marginBottom: 10 }} onChange={(e) => this.handleEditChange(e, "cloudKey")} /></div>) : null}

									</TabPane>
									<TabPane tab="Статусы заказов" key="5">
										<Row gutter={16}>
											<Col>
												<>
													<Title level={4}>Первичный статус:</Title>


													<Row type="flex">
														<Col xs={24} xl={4}>
															<Typography>API:</Typography>
															<Input
																value={crmInitStatus}
																name="crmInitStatus"
																style={{ width: 220 }}
																onChange={(e) => this.handleEditChange(e, "crmInitStatus")}
															/>
															<ColorPicker
																animation="slide-up"
																color={crmInitStatusColor}
																onChange={(e) => this.handleEditChange(e, "crmInitStatusColor")}
															>
																<Tag color={crmInitStatusColor}>ВЫБРАТЬ ЦВЕТ МЕТКИ</Tag>
															</ColorPicker>
														</Col>

														<Col xs={24} xl={4}>
															<Typography>Клиент:</Typography>
															<Input
																value={clientInitStatus}
																name="clientInitStatus"
																style={{ width: 220 }}
																onChange={(e) => this.handleEditChange(e, "clientInitStatus")}
															/>
															<ColorPicker
																animation="slide-up"
																color={clientInitStatusColor}
																onChange={(e) => this.handleEditChange(e, "clientInitStatusColor")}
															>
																<Tag color={clientInitStatusColor}>ВЫБРАТЬ ЦВЕТ МЕТКИ</Tag>
															</ColorPicker>
														</Col>
													</Row>
													<Divider />

													<Table
														size="middle"
														rowKey={record => record._id}
														pagination={false}
														style={{ marginTop: 20 }}
														columns={orderStatusesColumn}
														dataSource={orderStatuses}
													/>

													<div style={{ flex: 1, marginTop: 20 }}>
														<Row>
															<Input
																placeholder="Название для API"
																value={this.state.newapiname}
																name="newapiname"
																style={{ width: 220 }}
																onChange={(e) => this.handleChangeNewOrderStatus(e, "newapiname")}
															/>
															<br /><br />
															<ColorPicker
																animation="slide-up"
																color={this.state.newapicolor}
																onChange={(e) => this.handleChangeNewOrderStatus(e, "newapicolor")}
															>
																<Tag color={this.state.newapicolor}>ЦВЕТ ДЛЯ API</Tag>
															</ColorPicker>
															<br /><br />

															<Input
																placeholder="Название для Клиента"
																value={this.state.newclientname}
																name="newclientname"
																style={{ width: 220 }}
																onChange={(e) => this.handleChangeNewOrderStatus(e, "newclientname")}
															/>
															<br /><br />
															<ColorPicker
																animation="slide-up"
																color={this.state.newclientcolor}
																onChange={(e) => this.handleChangeNewOrderStatus(e, "newclientcolor")}
															>
																<Tag color={this.state.newclientcolor}>ЦВЕТ ДЛЯ КЛИЕНТА</Tag>
															</ColorPicker>
															<br /><br />

															<Checkbox
																checked={this.state.newgrantbonuses}
																name="newgrantbonuses"
																onChange={(e) => this.handleChangeNewOrderStatus(e, "newgrantbonuses")}
															>
																Начислять бонусы?
															</Checkbox>
															<br /><br />
															<Checkbox
																checked={this.state.newgrantcashpay}
																name="newgrantcashpay"
																onChange={(e) => this.handleChangeNewOrderStatus(e, "newgrantcashpay")}
															>
																Переводить в статус "Оплачено"?
															</Checkbox>
															<br /><br />
															<Checkbox
																checked={this.state.newordercancel}
																name="newordercancel"
																onChange={(e) => this.handleChangeNewOrderStatus(e, "newordercancel")}
															>
																Отменять заказ?
															</Checkbox>
															<br /><br />
															<Checkbox
																checked={this.state.newordercomplete}
																name="newordercomplete"
																onChange={(e) => this.handleChangeNewOrderStatus(e, "newordercomplete")}
															>
																Завершать заказ?
															</Checkbox>
															<br /><br />
															<Checkbox
																checked={this.state.newiscourier}
																name="newiscourier"
																onChange={(e) => this.handleChangeNewOrderStatus(e, "newiscourier")}
															>
																Этот статус используется курьером?
															</Checkbox>
															<br /><br />
															<Checkbox
																checked={this.state.newcleardeliver}
																name="newcleardeliver"
																onChange={(e) => this.handleChangeNewOrderStatus(e, "newcleardeliver")}
															>
																При смене на этот статус удалять факт доставки?
															</Checkbox>
															<br /><br />
															<Button disabled={!this.state.canAddOrderStatus} type="primary" icon="plus" onClick={() => this.handleAddNewOrderStatus()}>
																Добавить
      														</Button>


														</Row>
													</div>
												</>
											</Col>
										</Row>
									</TabPane>
								</Tabs>


								<Divider />
								<Button disabled={!needSave} type="primary" icon="save" onClick={this.handleSaveButton}>
									Сохранить
      </Button>
							</div>
						] : null}
					</QueueAnim>
			);
		}
	}
};


CPApiSettings.propTypes = {
	getApiSettings: PropTypes.func.isRequired,
	editApiSettings: PropTypes.func.isRequired,
	saveApiSettings: PropTypes.func.isRequired,
	editClientStatus: PropTypes.func.isRequired,
	editCrmStatus: PropTypes.func.isRequired,
	smscAuth: PropTypes.number.isRequired,
	smscLogin: PropTypes.string.isRequired,
	smscPassword: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	needSave: PropTypes.bool.isRequired,
	onlinePaymentType: PropTypes.number.isRequired,
	fondyMerchantId: PropTypes.number.isRequired,
	yandexShopId: PropTypes.number.isRequired,
	yandexKey: PropTypes.string.isRequired,
	googlePay: PropTypes.number.isRequired,
	applePay: PropTypes.number.isRequired,
	currencySymbol: PropTypes.string.isRequired,
	currencyCode: PropTypes.string.isRequired,
	clientInitStatus: PropTypes.string.isRequired,
	crmInitStatus: PropTypes.string.isRequired,
	clientInitStatusColor: PropTypes.string.isRequired,
	crmInitStatusColor: PropTypes.string.isRequired,
	customClientStatuses: PropTypes.array.isRequired,
	customCrmStatuses: PropTypes.array.isRequired,
	dadataEnabled: PropTypes.bool.isRequired,
	dadataAPI: PropTypes.string.isRequired,
	orderStatuses: PropTypes.array.isRequired,
};


const mapStateToProps = state => ({
	smscAuth: state.apisettings.smscAuth,
	smscLogin: state.apisettings.smscLogin,
	smscPassword: state.apisettings.smscPassword,
	isLoading: state.apisettings.isLoading,
	needSave: state.apisettings.needSave,
	onlinePaymentType: state.apisettings.onlinePaymentType,
	fondyMerchantId: state.apisettings.fondyMerchantId,
	yandexShopId: state.apisettings.yandexShopId,
	yandexKey: state.apisettings.yandexKey,
	cloudShopId: state.apisettings.cloudShopId,
	cloudKey: state.apisettings.cloudKey,

	googlePay: state.apisettings.googlePay,
	applePay: state.apisettings.applePay,
	currencySymbol: state.apisettings.currencySymbol,
	currencyCode: state.apisettings.currencyCode,
	clientInitStatus: state.apisettings.clientInitStatus,
	crmInitStatus: state.apisettings.crmInitStatus,
	clientInitStatusColor: state.apisettings.clientInitStatusColor,
	crmInitStatusColor: state.apisettings.crmInitStatusColor,
	customClientStatuses: state.apisettings.customClientStatuses,
	customCrmStatuses: state.apisettings.customCrmStatuses,
	dadataEnabled: state.apisettings.dadataEnabled,
	dadataAPI: state.apisettings.dadataAPI,
	orderStatuses: state.apisettings.orderStatuses,
});

export default connect(mapStateToProps, { editOrderStatus, getApiSettings, editApiSettings, saveApiSettings, editClientStatus, editCrmStatus })(CPApiSettings);
