import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Button, Row, Col, Typography, Divider, Spin, Card, Icon, Layout, Modal, Popconfirm, Table } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
    getArticleList,
    showAddArticleModal,
    closeAddArticleModal,
    addArticle,
    editArticle,
    showEditArticleModal,
    closeEditArticleModal,
    deleteArticle
} from "../../actions/articlesActions";
import RichTextEditor from 'react-rte';

const { Search } = Input;
const { TextArea } = Input;
const CPArticles = props => {
    const dispatch = useDispatch();
    useFetching(getArticleList, useDispatch());
    const articles = useSelector(state => state.articles.articles);
    const loading = useSelector(state => state.articles.isLoading);
    const showAdd = useSelector(state => state.articles.showAdd);
    const adding = useSelector(state => state.articles.adding);

    const showEdit = useSelector(state => state.articles.showEdit);
    const editing = useSelector(state => state.articles.editing);

    const [values, setValues] = React.useState({
        name: "",
        data: RichTextEditor.createEmptyValue(),
        meta_title: "",
        meta_h1: "",
        meta_description: "",
        url: ""
    });

    const [editvalues, setEditValues] = React.useState({
        name: "",
        data: RichTextEditor.createEmptyValue(),
        meta_title: "",
        meta_h1: "",
        meta_description: "",
        url: ""
    });


    const [searchvalue, setSearchValue] = React.useState({
        search: ""
    });

    const handleSearchChange = name => event => {
        setSearchValue({ ...searchvalue, [name]: event.target.value });
    };
    const handleOpenAddModal = useCallback(
        () => dispatch(showAddArticleModal()),
        [dispatch]
    );
    const handleCloseAddModal = useCallback(
        () => {
            setValues({ data: RichTextEditor.createEmptyValue() });
            dispatch(closeAddArticleModal());
        },
        [dispatch]
    );

    const handleAddArticle = () => {
        console.log('set');
        console.log(values);
        dispatch(addArticle(values));
        setValues({ data: RichTextEditor.createEmptyValue() });
    };

    const handleEditArticle = () => {
        dispatch(editArticle(editvalues));
        setEditValues({
            name: '',
            data: RichTextEditor.createEmptyValue(),
        });
    };

    const handleDelArticle = (article) => {
		dispatch(deleteArticle(article));
    };
    
    const handleOpenEditArticle = (article) => {
        let oldValueArticle = editvalues.data;
        setEditValues({
            ...editvalues,
            _id: article._id,
            name: article.name,
            data: oldValueArticle.setContentFromString(article.data, 'html'),
        });
        dispatch(showEditArticleModal(article));
    };

    const handleCloseEditArticle = useCallback(() => dispatch(closeEditArticleModal()), [dispatch]);

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
        console.log(values);
    };

    const handleEditChange = name => event => {
        setEditValues({ ...editvalues, [name]: event.target.value });
    };

    const handleEditorAddChange = name => event => {
        let source = event.target.value;
        let oldValue = values.data;
        setValues({ ...values, [name]: oldValue.setContentFromString(source, 'html') });
    };

    const handleEditorEditChange = name => event => {
        let source = event.target.value;
        let oldValue = editvalues.data;
        setEditValues({ ...editvalues, [name]: oldValue.setContentFromString(source, 'html') });
    };


    function renderArticleInfo(article) {
		return (
			<>
				<div key={article.id}>
					<Row gutter={16}>
						<Col className="gutter-row" xs={24} sm={12}>
							<div>
								<span><b>Название:</b></span><br />
								<span>{article.name}</span><br />
							</div>
						</Col>
					</Row>


				</div>
				<div className="customer-buttons">
					<Popconfirm title="Удалить статью?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelArticle(article)}>
						<Button style={{ marginRight: 5 }} type="dashed">УДАЛИТЬ</Button>
					</Popconfirm>
					<Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleOpenEditArticle(article)}>РЕДАКТИРОВАТЬ</Button>
				</div>
			</>
		);
	}

    function renderSort() {

        const columns = [
            { name: 'Заголовок', dataIndex: 'name', key: 'name' }
        ];
        //console.log(RichTextEditor.createValueFromString('<b>1</b>', 'html'));
        //console.log(articles);
        var updatedList = articles;
        updatedList = updatedList.filter(function (articlefilter) {
            return (
                articlefilter.name
                    .toLowerCase()
                    .search(searchvalue.search.toLowerCase()) !== -1
            );
        });

        if (updatedList.length > 0) {
            return (
				<Table
					rowKey={record => record._id}
					style={{ marginTop: 20 }}
					columns={columns}
					expandedRowRender={record => renderArticleInfo(record)}
					dataSource={articles}
				/>
            );
        } else {
            return (
                <div>
                    <Card title="Статей не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
                        <div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
                    </Card>
                </div>
            );
        }
    }

    if (loading) {
        return (
                <div className="content-div">
                    <Divider className="header-div" orientation="left"><Spin /></Divider>
                </div>
        );
    } else {
        return (
                <QueueAnim className="anim-content"
                    type={['right', 'left']}
                    ease={['easeOutQuart', 'easeInOutQuart']}>
                    {!loading ? [
                        <div key="a" className="content-div">
                            <Divider className="header-div" orientation="left">Статьи</Divider>
                            <Row type="flex" justify="end">
                                <Search
                                    placeholder="Поиск по статьям"
                                    onChange={handleSearchChange("search")}
                                    style={{ width: 200 }}
                                />
                            </Row>
                            <Divider />
                            {renderSort()}
                            <Card style={{ width: '100%', marginBottom: 20 }} size="small">
                                <Button type="primary" icon="plus" onClick={handleOpenAddModal}>
                                    Добавить
						</Button>
                            </Card>


                            <Modal
                                centered
                                title={"Редактирование статьи"}
                                visible={showEdit}
                                onCancel={handleCloseEditArticle}
                                footer={[
                                    <Button
                                        key="back"
                                        onClick={handleCloseEditArticle}
                                    >
                                        Отмена
          							</Button>,
                                    <Button
                                        key="yes"
                                        type="primary"
                                        disabled={editing}
                                        onClick={handleEditArticle}
                                    >
                                        Сохранить
          							</Button>,
                                ]}
                            >
                                <div>
                                    <Row gutter={6} style={{ marginBottom: 5 }}>
                                        <Col className="gutter-row" span={24} sm={24} xs={24}>
                                            <Typography>Название:</Typography>
                                            <Input value={editvalues.name} onChange={handleEditChange("name")} />
                                        </Col>
                                    </Row>


                                    <RichTextEditor
                                        className="rte-height"
                                        value={editvalues.data}
                                        onChange={value => setEditValues({ ...editvalues, data: value })}
                                    />

                                    <TextArea style={{ marginTop: 5, marginBottom: 10 }} label="HTML" value={editvalues.data.toString('html')} onChange={handleEditorEditChange("data")} rows={5} />

                                    <Row gutter={6} style={{ marginBottom: 5 }}>
                                        <Col className="gutter-row" span={12} sm={12} xs={24}>
                                            <Typography>META H1:</Typography>
                                            <Input value={editvalues.meta_h1} onChange={handleEditChange("meta_h1")} />
                                        </Col>
                                        <Col className="gutter-row" span={12} sm={12} xs={24}>
                                            <Typography>META Description:</Typography>
                                            <Input value={editvalues.meta_description} onChange={handleEditChange("meta_description")} />
                                        </Col>
                                    </Row>
                                    <Row gutter={6} style={{ marginBottom: 5 }}>
                                        <Col className="gutter-row" span={12} sm={12} xs={24}>
                                            <Typography>SEO Url:</Typography>
                                            <Input value={editvalues.url} onChange={handleEditChange("url")} />
                                        </Col>
                                        <Col className="gutter-row" span={12} sm={12} xs={24}>
                                            <Typography>META Title:</Typography>
                                            <Input value={editvalues.meta_title} onChange={handleEditChange("meta_title")} />
                                        </Col>
                                    </Row>
                                </div>
                            </Modal>

                            <Modal
                                centered
                                title="Добавление статьи"
                                visible={showAdd}
                                style={{}}
                                onCancel={handleCloseAddModal}
                                footer={[
                                    <Button
                                        onClick={handleCloseAddModal}
                                        key="back"
                                        type="danger"
                                    >
                                        Отмена
						</Button>,
                                    <Button
                                        color="secondary"
                                        onClick={handleAddArticle}
                                        key="yes"
                                        type="primary"
                                        disabled={adding}
                                    >
                                        Добавить
						</Button>,
                                ]}
                            >
                                <Row gutter={6} style={{ marginBottom: 5 }}>
                                    <Col className="gutter-row" span={24} sm={24} xs={24}>
                                        <Typography>Название:</Typography>
                                        <Input value={values.name} onChange={handleChange("name")} />
                                    </Col>
                                </Row>


                                <RichTextEditor
                                    className="rte-height"
                                    value={values.data}
                                    onChange={value => setValues({ ...values, data: value })}
                                />

                                <TextArea style={{ marginTop: 5, marginBottom: 10 }} label="HTML" value={values.data.toString('html')} onChange={handleEditorAddChange("data")} rows={5} />

                                <Row gutter={6} style={{ marginBottom: 5 }}>
                                    <Col className="gutter-row" span={12} sm={12} xs={24}>
                                        <Typography>META H1:</Typography>
                                        <Input value={values.meta_h1} onChange={handleChange("meta_h1")} />
                                    </Col>
                                    <Col className="gutter-row" span={12} sm={12} xs={24}>
                                        <Typography>META Description:</Typography>
                                        <Input value={values.meta_description} onChange={handleChange("meta_description")} />
                                    </Col>
                                </Row>
                                <Row gutter={6} style={{ marginBottom: 5 }}>
                                    <Col className="gutter-row" span={12} sm={12} xs={24}>
                                        <Typography>SEO Url:</Typography>
                                        <Input value={values.url} onChange={handleChange("url")} />
                                    </Col>
                                    <Col className="gutter-row" span={12} sm={12} xs={24}>
                                        <Typography>META Title:</Typography>
                                        <Input value={values.meta_title} onChange={handleChange("meta_title")} />
                                    </Col>
                                </Row>
                            </Modal>

                        </div>
                    ] : null}
                </QueueAnim>
        );
    }
};

const useFetching = (getArticleList, dispatch) => {
    useEffect(() => {
        dispatch(getArticleList());
    }, []);
};

export default CPArticles;
