import axios from "axios";
import serverIp from "../utils/serverIp";

import {
    GET_ITEMFILTER_LIST,
    LOADING_ITEMFILTER,
    GET_ITEMFILTER_ERRORS,
    ITEMFILTER_EDITING,
    SHOW_ADD_ITEMFILTER_MODAL,
    CLOSE_ADD_ITEMFILTER_MODAL,
    ITEMFILTER_ADDED,
    ITEMFILTER_ADDING,
    ITEMFILTER_DELETED,
    SHOW_EDIT_ITEMFILTER_MODAL,
    CLOSE_EDIT_ITEMFILTER_MODAL,
    ITEMFILTER_EDITED
} from "./types";


// получаем фильтры
export const getFilterList = () => dispatch => {
    dispatch(setFiltersLoading());
    axios
        .get(serverIp + "api/filter/getfilterlist")
        .then(res => {
            dispatch({
                type: GET_ITEMFILTER_LIST,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch({
                type: GET_ITEMFILTER_ERRORS,
                payload: err.response
            })
        );
};

export const setFiltersLoading = () => {
    return {
        type: LOADING_ITEMFILTER
    };
};

export const addFilter = (filter) => dispatch => {
    dispatch(setFilterAdding());
    axios
        .post(serverIp + "api/filter/addfilter", filter)
        .then(res => {
            dispatch({
                type: ITEMFILTER_ADDED,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch({
                type: GET_ITEMFILTER_ERRORS,
                payload: err.response
            })
        );
};

export const setFilterAdding = () => {
    return {
        type: ITEMFILTER_ADDING
    };
};


export const deleteFilter = (filter) => dispatch => {
    axios
        .post(serverIp + "api/filter/deletefilter", filter)
        .then(res => {
            dispatch({
                type: ITEMFILTER_DELETED,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch({
                type: GET_ITEMFILTER_ERRORS,
                payload: err.response
            })
        );
};

export const showAddFilterModal = () => {
    return {
        type: SHOW_ADD_ITEMFILTER_MODAL
    };
};

export const closeAddFilterModal = () => {
    return {
        type: CLOSE_ADD_ITEMFILTER_MODAL
    };
};

export const editFilter = (filter) => dispatch => {
    dispatch(setFilterEditing());
    axios
        .post(serverIp + "api/filter/editfilter", filter)
        .then(res => {
            dispatch({
                type: ITEMFILTER_EDITED,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch({
                type: GET_ITEMFILTER_ERRORS,
                payload: err.response
            })
        );
};

export const setFilterEditing = () => {
    return {
        type: ITEMFILTER_EDITING
    };
};
export const showEditFilterModal = (filter) => {
    return {
        type: SHOW_EDIT_ITEMFILTER_MODAL,
        payload: filter
    };
};

export const closeEditFilterModal = () => {
    return {
        type: CLOSE_EDIT_ITEMFILTER_MODAL
    };
};