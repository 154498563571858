import axios from "axios";
import serverIp from "../utils/serverIp";

import {
    GET_WEB_STATICPAGES,
    LOADING_WEB_STATICPAGES,
    EDIT_WEB_STATICPAGES,
    SAVED_WEB_STATICPAGES,
    SAVING_WEB_STATICPAGES,
    POPULATED_CONTENT_WEB_STATICPAGES
} from "./types";

export const getStaticPages = () => dispatch => {
    dispatch(setStaticPageLoading());
    axios
        .get(serverIp + "api/website/getstaticpages")
        .then(res => {
            dispatch({
                type: GET_WEB_STATICPAGES,
                payload: res.data
            });
        })
        .catch(err => {});
};

export const saveStaticPage = (data) => dispatch => {
    console.log(data);
    data.content = data.content.toString('html');
    axios
    .put(serverIp + "api/website/savestaticpage", data)
    .then(res => {
      dispatch({
        type: SAVED_WEB_STATICPAGES,
        payload: res.data
      });
    })
    .catch(err => {});
};

export const editStaticPage = (data, type) => dispatch => {
    dispatch({
        type: EDIT_WEB_STATICPAGES,
        payload: data,
        page: type
    });
};


export const setStaticPageLoading = () => {
    return {
        type: LOADING_WEB_STATICPAGES
    };
};

export const contentPopulated = () => {
    return {
        type: POPULATED_CONTENT_WEB_STATICPAGES
    };  
}