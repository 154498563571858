import {
  GET_NEWS_LIST,
  LOADING_NEWS,
  GET_NEWS_ERRORS,
  NEWS_EDITING,
  SHOW_ADD_NEWS_MODAL,
  CLOSE_ADD_NEWS_MODAL,
  SHOW_EDIT_NEWS_MODAL,
  CLOSE_EDIT_NEWS_MODAL,
  NEWS_EDITED,
  NEWS_ADDED,
  NEWS_ADDING,
  NEWS_DELETED,
  SHOW_ADD_IMG_NEWS_MODAL,
  CLOSE_ADD_IMG_NEWS_MODAL,
  NEWS_IMG_DELETED,
  NEWS_IMG_ADDED
} from "../actions/types";

const initialState = {
  isLoading: true,
  news: [],
  isLoading: true,
  editing: false,
  showAdd: false,
  showEdit: false,
  adding: false,
  showAddImg: false,
  imgUploading: false,
  imgBtnDisable: false,
  newsAddPhoto: {}
};

export default function (state = initialState, action) {
  switch (action.type) {

    case NEWS_IMG_DELETED:
      const updatedDelImgState = state.news.map((news) => {
        if (news._id === action.payload.news._id) {
          return { ...news, ...action.payload.news };
        } else {
        }
        return news;
      });
      return {
        ...state,
        news: updatedDelImgState,
        imgBtnDisable: false
      };
    case NEWS_IMG_ADDED:
      const updatedAddImgState = state.news.map((news) => {
        if (news._id === action.payload.news._id) {
          return { ...news, ...action.payload.news };
        } else {
        }
        return news;
      });
      return {
        ...state,
        news: updatedAddImgState,
        imgBtnDisable: false
      };

    case SHOW_ADD_IMG_NEWS_MODAL:
      return {
        ...state,
        isLoading: false,
        showAddImg: true,
        newsAddPhoto: action.payload
      };
    case CLOSE_ADD_IMG_NEWS_MODAL:
      return {
        ...state,
        isLoading: false,
        showAddImg: false,
        newsAddPhoto: {},
      };
    case NEWS_EDITED:
      const updatedState = state.news.map((news) => {
        if (news._id === action.payload.editednews._id) {
          return { ...news, ...action.payload.editednews };
        } else {
        }
        return news;
      });
      return {
        ...state,
        news: updatedState,
        showEdit: false,
        editing: false,
      };
    case NEWS_EDITING:
      return {
        ...state,
        editing: true,
      };

    case CLOSE_EDIT_NEWS_MODAL:
      return {
        ...state,
        editing: false,
        showEdit: false,
      }
    case SHOW_EDIT_NEWS_MODAL:
      return {
        ...state,
        showEdit: true,
      }

    case NEWS_DELETED:
      const id = action.payload.deletednews._id;
      return {
        ...state,
        news: state.news.filter((news) => news._id !== id),
      };
    case NEWS_ADDING:
      return {
        ...state,
        adding: true,
      };
    case NEWS_ADDED:
      return {
        ...state,
        news: [...state.news, action.payload.newnews],
        showAdd: false,
        adding: false,
      };
    case SHOW_ADD_NEWS_MODAL:
      return {
        ...state,
        showAdd: true,
      };
    case CLOSE_ADD_NEWS_MODAL:
      return {
        ...state,
        showAdd: false
      };
    case GET_NEWS_LIST:
      return {
        ...state,
        isLoading: false,
        news: action.payload.news,
      };
    case LOADING_NEWS:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
}
