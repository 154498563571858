import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Row, Col, Divider, Spin, Card, Icon, Layout, Table, Button, Modal, Typography, Popconfirm, Popover, Select } from 'antd';
import QueueAnim from 'rc-queue-anim';

import Moment from 'react-moment';
import 'moment/locale/ru';
import {
    getComplectList,
    addComplect,
    deleteComplect,
    editComplect
} from "../../actions/complectActions";
import { openEditComment } from "../../actions/orderActions";

const { Option } = Select;

const CPComplect = props => {
    useFetching(getComplectList, useDispatch());
    const dispatch = useDispatch();
    const complects = useSelector(state => state.complect.complects);
    const nomenclatures = useSelector(state => state.complect.nomenclatures);
    const loading = useSelector(state => state.complect.isLoading);
    const adding = useSelector(state => state.complect.isAdding);

    const [showAdd, setShowAdd] = React.useState(false);
    const [newComplect, setNewComplect] = React.useState({ name: '', nomenclatures: [] });
    const [newComplectNomenclature, setNewComplectNomenclature] = React.useState({ id: '', value: '' });

    const [showAddNomenclature, setShowAddNomenclature] = React.useState(false);

    useEffect(() => {
        if (adding == false) {
            setShowAdd(false);
            setNewComplect({ name: '', nomenclatures: [] })
        }
    }, [adding]);

    const handleAddComplect = () => {
        if (newComplect.name.length > 0) {
            dispatch(addComplect(newComplect))
        }
        console.log(newComplect)
    };

    function onSearch(val) {
        console.log('search:', val);
    }

    function getNomenclatureName(id) {
        var name = nomenclatures.filter(obj => {
            return obj._id === id
        })
        if (name[0]) {
            return name[0].name;
        }
    }

    function getNomenclatureUnit(id) {
        var name = nomenclatures.filter(obj => {
            return obj._id === id
        })
        if (name[0]) {
            switch (name[0].unit) {
                case 'KG':
                    return 'кг.'
                case 'LITRES':
                    return 'л.'
            }
        }
    }

    const handleAddNomenclature = (e) => {
        var same = newComplect.nomenclatures.filter(obj => {
            return obj.id === e
        })
        if (same.length == 0) {
            setNewComplect({ ...newComplect, nomenclatures: [...newComplect.nomenclatures, { id: e, value: 0.0 }] })
        }
    }



    

    const changeNomenclatureValue = (e, i) => {
        let val = e.target.value;
        val = val.replace(/[^0-9.,-]/g, '');
        val = val.replace(",", ".");
        let array = [...newComplect.nomenclatures];
        array[i].value = val;
        setNewComplect({ ...newComplect, nomenclatures: [...array] })
    }





    const handleDeleteNomenclature = (i) => {
        let array = [...newComplect.nomenclatures];
        array.splice(i, 1);
        setNewComplect({ ...newComplect, nomenclatures: [...array] })
    }

    const handleDelComplect = (complect) => {
        dispatch(deleteComplect(complect))
    }

    const [showEditComplect, setShowEditComplect] = React.useState(false);
    const [editedComplect, setEditedComplect] = React.useState({});

    const openEditComplect = (complect) => {
        setEditedComplect(complect);
        setShowEditComplect(true);
    }
    const closeEditComplect = () => {
        setEditedComplect({});
        setShowEditComplect(false);
    }

    const changeEditNomenclatureValue = (e, i) => {
        let val = e.target.value;
        val = val.replace(/[^0-9.,-]/g, '');
        val = val.replace(",", ".");
        let array = [...editedComplect.nomenclatures];
        array[i].value = val;
        setEditedComplect({ ...editedComplect, nomenclatures: [...array] })
    }

    const handleDeleteEditNomenclature = (i) => {
        let array = [...editedComplect.nomenclatures];
        array.splice(i, 1);
        setEditedComplect({ ...editedComplect, nomenclatures: [...array] })
    }

    const handleAddEditNomenclature = (e) => {
        var same = editedComplect.nomenclatures.filter(obj => {
            return obj.id === e
        })
        if (same.length == 0) {
            setEditedComplect({ ...editedComplect, nomenclatures: [...editedComplect.nomenclatures, { id: e, value: 0.0 }] })
        }
    }

    const handleEditComplect = () => {
        dispatch(editComplect(editedComplect));
        setShowEditComplect(false);
        setEditedComplect({});
    }

    function renderComplectInfo(complect) {
        return (
            <>
                <span><b>Состав:</b></span><br />
                {complect.nomenclatures.map((nomenclature, i) => (
                    <>
                        <span>{getNomenclatureName(nomenclature.id)}</span> - <span>{nomenclature.value} {getNomenclatureUnit(nomenclature.id)}</span><br />
                    </>
                ))}
                <br />

                <Button style={{ marginRight: 5 }} size="small" type="dashed" icon="edit" onClick={() => { openEditComplect(complect) }}>РЕДАКТИРОВАТЬ</Button>
                <Popconfirm title="Удалить комплект?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelComplect(complect)}>
                    <Button style={{ marginRight: 5 }} size="small" type="danger" ghost icon="delete">УДАЛИТЬ КОМПЛЕКТ</Button>
                </Popconfirm>

            </>
        )
    }

    function renderSort() {

        const columns = [
            { title: 'Наименование', dataIndex: 'name', key: 'name' },
            // {
            //     title: 'Единица измерения', dataIndex: 'unit', key: 'unit',
            //     render: (unit, row) => {
            //         switch (unit) {
            //             case 'KG':
            //                 return (
            //                     <>кг.</>
            //                 )
            //             case 'LITRES':
            //                 return (
            //                     <>л.</>
            //                 )
            //             default:
            //                 return(
            //                     <>упс.</>
            //                 )
            //         }
            //     }
            // },
        ];

        if (complects.length > 0) {
            return (
                <Table
                    rowKey={record => record._id}
                    style={{ marginTop: 20 }}
                    columns={columns}
                    dataSource={complects}
                    size="small"
                    pagination={false}
                    expandedRowRender={record => renderComplectInfo(record)}
                />
            );
        } else {
            return (
                <div>
                    <Card title="Комплектов не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
                        <div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
                    </Card>
                </div>
            );
        }
    }

    if (loading) {
        return (
            <div className="content-div">
                <Divider className="header-div" orientation="left"><Spin /></Divider>
            </div>
        );
    } else {
        return (
            <QueueAnim className="anim-content"
                type={['right', 'left']}
                ease={['easeOutQuart', 'easeInOutQuart']}>
                {!loading ? [
                    <div key="a" className="content-div">
                        <Divider className="header-div" orientation="left">Список комплектов</Divider>
                        <Row type="flex" justify="end">
                        </Row>
                        {renderSort()}
                        <Divider />
                        <Card style={{ width: '100%', marginBottom: 20 }} size="small">
                            <Button type="primary" icon="plus" onClick={() => { setShowAdd(true); setNewComplect({ name: '', nomenclatures: [] }) }}>
                                Добавить
                            </Button>
                        </Card>
                    </div>
                ] : null}

                <Modal
                    centered
                    title={"Добавление комлекта"}
                    visible={showAdd}
                    closable={false}
                    footer={[
                        <Button
                            key="back"
                            disabled={adding}
                            onClick={() => { setShowAdd(false); setNewComplect({ name: '', nomenclatures: [] }) }}
                        >
                            Отмена
                        </Button>,
                        <Button
                            key="yes"
                            type="primary"
                            disabled={adding}
                            onClick={handleAddComplect}
                        >
                            Сохранить
                        </Button>,
                    ]}
                >
                    <Row gutter={16}>
                        <Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
                            <Typography style={{ textAlign: 'left' }}>Название комплекта:</Typography>
                            <Input
                                value={newComplect.name}
                                name="name"
                                style={{ marginBottom: 10 }}
                                onChange={(e) => setNewComplect({ ...newComplect, name: e.target.value })}
                            />
                        </Col>

                        {newComplect.nomenclatures.length > 0 ? (
                            <>
                                <Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
                                    <Divider size="small">Состав комплекта</Divider>
                                </Col>
                                <Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
                                    {newComplect.nomenclatures.map((nomenclature, i) => (
                                        <>
                                            <Row gutter={16} style={{ borderBottom: '1px dashed #d4d4d4', paddingBottom: 7, marginBottom: 7 }}>
                                                <Col className="gutter-row" style={{ textAlign: 'left', justifyContent: 'flex-start', display: 'flex', minHeight: 32, alignItems: 'center' }} xs={12} sm={12}>
                                                    <span>{getNomenclatureName(nomenclature.id)}</span>
                                                </Col>
                                                <Col className="gutter-row" style={{ textAlign: 'left' }} xs={10} sm={10}>
                                                    <Input addonAfter={getNomenclatureUnit(nomenclature.id)} value={nomenclature.value} placeholder="0.0" defaultValue={0.0} onChange={(e) => changeNomenclatureValue(e, i)} />
                                                </Col>
                                                <Col className="gutter-row" style={{ textAlign: 'right', justifyContent: 'flex-start', display: 'flex', minHeight: 32, alignItems: 'center' }} xs={2} sm={2}>
                                                    <Button size="small" type="danger" shape="circle" icon="delete" onClick={() => handleDeleteNomenclature(i)} />
                                                </Col>
                                            </Row>
                                        </>
                                    ))}
                                </Col>
                            </>
                        ) : null}

                        <Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
                            <Divider size="small">Выбор номенклатуры</Divider>
                        </Col>

                        <Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
                            <Typography style={{ textAlign: 'left' }}>Выберите из списка:</Typography>
                            <Select
                                showSearch
                                // value={newComplectNomenclature.id}
                                // onChange={(e) => handleAddNomenclature(e)}
                                onSelect={(e) => handleAddNomenclature(e)}
                                placeholder=""
                                style={{ width: '100%', marginBottom: 10 }}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {nomenclatures.map((nomenclature, i) => (
                                    <Option key={i} value={nomenclature._id}>{nomenclature.name}</Option>
                                ))}
                            </Select>
                        </Col>


                    </Row>
                </Modal>

                <Modal
                    centered
                    title={"Редактирование комлекта"}
                    visible={showEditComplect}
                    closable={false}
                    footer={[
                        <Button
                            key="back"
                            disabled={adding}
                            onClick={() => { closeEditComplect() }}
                        >
                            Отмена
                        </Button>,
                        <Button
                            key="yes"
                            type="primary"
                            onClick={handleEditComplect}
                        >
                            Сохранить
                        </Button>,
                    ]}
                >
                    <Row gutter={16}>
                        <Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
                            <Typography style={{ textAlign: 'left' }}>Название комплекта:</Typography>
                            <Input
                                value={editedComplect.name}
                                name="name"
                                style={{ marginBottom: 10 }}
                                onChange={(e) => setEditedComplect({ ...editedComplect, name: e.target.value })}
                            />
                        </Col>

                        {editedComplect.nomenclatures ? (<>
                            {editedComplect.nomenclatures.length > 0 ? (
                                <>
                                    <Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
                                        <Divider size="small">Состав комплекта</Divider>
                                    </Col>
                                    <Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
                                        {editedComplect.nomenclatures.map((nomenclature, i) => (
                                            <>
                                                <Row gutter={16} style={{ borderBottom: '1px dashed #d4d4d4', paddingBottom: 7, marginBottom: 7 }}>
                                                    <Col className="gutter-row" style={{ textAlign: 'left', justifyContent: 'flex-start', display: 'flex', minHeight: 32, alignItems: 'center' }} xs={12} sm={12}>
                                                        <span>{getNomenclatureName(nomenclature.id)}</span>
                                                    </Col>
                                                    <Col className="gutter-row" style={{ textAlign: 'left' }} xs={10} sm={10}>
                                                        <Input addonAfter={getNomenclatureUnit(nomenclature.id)} value={nomenclature.value} placeholder="0.0" defaultValue={0.0} onChange={(e) => changeEditNomenclatureValue(e, i)} />
                                                    </Col>
                                                    <Col className="gutter-row" style={{ textAlign: 'right', justifyContent: 'flex-start', display: 'flex', minHeight: 32, alignItems: 'center' }} xs={2} sm={2}>
                                                        <Button size="small" type="danger" shape="circle" icon="delete" onClick={() => handleDeleteEditNomenclature(i)} />
                                                    </Col>
                                                </Row>
                                            </>
                                        ))}
                                    </Col>
                                </>
                            ) : null}
                        </>) : null}


                        <Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
                            <Divider size="small">Выбор номенклатуры</Divider>
                        </Col>

                        <Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
                            <Typography style={{ textAlign: 'left' }}>Выберите из списка:</Typography>
                            <Select
                                showSearch
                                // value={newComplectNomenclature.id}
                                // onChange={(e) => handleAddNomenclature(e)}
                                onSelect={(e) => handleAddEditNomenclature(e)}
                                placeholder=""
                                style={{ width: '100%', marginBottom: 10 }}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {nomenclatures.map((nomenclature, i) => (
                                    <Option key={i} value={nomenclature._id}>{nomenclature.name}</Option>
                                ))}
                            </Select>
                        </Col>


                    </Row>
                </Modal>
            </QueueAnim>


        );
    }
};

const useFetching = (getComplectList, dispatch) => {
    useEffect(() => {
        dispatch(getComplectList());
    }, []);
};

export default CPComplect;
