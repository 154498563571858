import {
    GET_PRINT_SETTINGS,
    EDIT_PRINT_SETTINGS,
    SET_PRINT_SETTINGS,
    PRINT_SETTINGS_LOADING,
    PRINT_SETTINGS_SENDING
} from "../actions/types";

const initialState = {
    isLoading: true,
    settings: [],
    sending: false,
    needSave: false,

    title: '',
	field_1: '',
	field_2: '',
	field_3: '',
	field_4: '',
	user_name: false,
    customer_data: false,
    
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRINT_SETTINGS:
            console.log(action.payload.settings)
            return {
                ...state,
                title: action.payload.settings.title,
                field_1: action.payload.settings.field_1,
                field_2: action.payload.settings.field_2,
                field_3: action.payload.settings.field_3,
                field_4: action.payload.settings.field_4,
                user_name: action.payload.settings.user_name,
                customer_data: action.payload.settings.customer_data,
                isLoading: false,
            }
        case SET_PRINT_SETTINGS:
            return {
                ...state,
                sending: false,
                needSave: false,
            }
        case PRINT_SETTINGS_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case PRINT_SETTINGS_SENDING:
            return {
                ...state,
                sending: true,
            }   
        case EDIT_PRINT_SETTINGS:
            return {
                ...state,
                needSave: true,
                title: action.payload.newTitle,
                field_1: action.payload.newField_1,
                field_2: action.payload.newField_2,
                field_3: action.payload.newField_3,
                field_4: action.payload.newField_4,
                user_name: action.payload.newUserName,
                customer_data: action.payload.newCustomerData,
                
            }
        default:
            return state;
    }
}