import {
    GET_SUMO_IIKO_SETTINGS,
    LOADING_SUMO_IIKO_SETTINGS,
    SUMO_IIKO_CHECK,
    SUMO_IIKO_MENU_LOADED,
    SUMO_IIKO_MENU_LOADING
} from "../actions/types";
import axios from "axios";
import serverIp from "../utils/serverIp";

// ПОЛУЧАЕМ НАСТРОЙКИ
export const getSumoIikoSettings = () => dispatch => {
    console.log('getSumoIikoSettings');
    dispatch(setSumoIikoSettingsLoading());
    axios
        .get(serverIp + "api/sumoiiko/getsettings")
        .then(res => {
            dispatch({
                type: GET_SUMO_IIKO_SETTINGS,
                payload: res.data
            });
        })
        .catch(err => { });
};
export const setSumoIikoSettingsLoading = () => {
    return {
        type: LOADING_SUMO_IIKO_SETTINGS
    };
};


// ПРОВЕРЯЕМ НАСТРОЙКИ
export const checkSumoIiko = (fId) => dispatch => {
    axios
        .post(serverIp + "api/sumoiiko/checkiiko", {fId:fId})
        .then(res => {
            dispatch({
                type: SUMO_IIKO_CHECK,
                payload: res.data
            });
        })
        .catch(err => { });
};

// ПОДГРУЖАЕМ МЕНЮ
export const getMenuFromSumoIiko = (fId) => dispatch => {
    dispatch(setSumoIikoMenuLoading());
    axios
        .post(serverIp + "api/sumoiiko/getmenufromiiko", {fId:fId})
        .then(res => {
            dispatch({
                type: SUMO_IIKO_MENU_LOADED,
                payload: res.data
            });
        })
        .catch(err => { });
};
export const setSumoIikoMenuLoading = () => {
    return {
        type: SUMO_IIKO_MENU_LOADING
    };
};