import axios from "axios";
import serverIp from "../utils/serverIp";

import {
    GET_SEO_PAGES_LIST,
    LOADING_SEO_PAGES,
    GET_SEO_PAGES_ERRORS,
    SHOW_ADD_SEO_PAGES_MODAL,
    CLOSE_ADD_SEO_PAGES_MODAL,
    SEO_PAGES_ADDED,
    ADDING_SEO_PAGES,
    SEO_PAGES_EDITING,
    SEO_PAGES_EDITED,
    CLOSE_EDIT_SEO_PAGES_MODAL,
    SHOW_EDIT_SEO_PAGES_MODAL,
    SEO_PAGES_DELETED
} from "./types";

export const getSeoPageList = () => dispatch => {
  console.log('getSeoPageList');
  dispatch(setSeoPagesLoading());
  axios
    .get(serverIp + "api/seopage/getseopagelist")
    .then(res => {
      dispatch({
        type: GET_SEO_PAGES_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_SEO_PAGES_ERRORS,
        payload: err.response
      })
    );
};

export const addSeoPage = (seopage) => dispatch => {
  console.log('addSeoPage');
  seopage.data = seopage.data.toString('html');
  console.log(seopage);
  dispatch(setSeoPagesAdding());
  axios
    .post(serverIp + "api/seopage/addseopage", seopage)
    .then(res => {
      dispatch({
        type: SEO_PAGES_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_SEO_PAGES_ERRORS,
        payload: err.response
      })
    );
};

export const showEditSeoPageModal = (seopage) => {
  return {
    type: SHOW_EDIT_SEO_PAGES_MODAL,
    payload: seopage
  };
};

export const closeEditSeoPageModal = () => {
  return {
    type: CLOSE_EDIT_SEO_PAGES_MODAL
  };
};

export const editSeoPage = (seopage) => dispatch => {
  dispatch({
    type: SEO_PAGES_EDITING
  })
  seopage.data = seopage.data.toString('html');
  axios
    .post(serverIp + "api/seopage/editseopage", seopage)
    .then(res => {
      dispatch({
        type: SEO_PAGES_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_SEO_PAGES_ERRORS,
        payload: err.response
      })
    );
};

export const deleteSeoPage = (seopage) => dispatch => {
  axios
    .post(serverIp + "api/seopage/deleteseopage", seopage)
    .then(res => {
      dispatch({
        type: SEO_PAGES_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_SEO_PAGES_ERRORS,
        payload: err.response
      })
    );
};

export const showAddSeoPageModal = () => {
  return {
    type: SHOW_ADD_SEO_PAGES_MODAL
  };
};

export const closeAddSeoPageModal = () => {
  return {
    type: CLOSE_ADD_SEO_PAGES_MODAL
  };
};

export const setSeoPagesLoading = () => {
  return {
    type: LOADING_SEO_PAGES
  };
};
export const setSeoPagesAdding = () => {
  return {
    type: ADDING_SEO_PAGES
  };
};
