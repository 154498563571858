import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  OPTION_ADDED,
  OPTION_DELETED,
  ADDING_OPTIONS,
  LOADING_OPTIONS,
  GET_OPTION_LIST,
  SHOW_ADD_OPTION_MODAL,
  CLOSE_ADD_OPTION_MODAL,
  SHOW_DEL_OPTION_MODAL,
  CLOSE_DEL_OPTION_MODAL,
  GET_OPTION_ERRORS,
  SHOW_ADD_OPTION_CHECKBOX_MODAL,
  CLOSE_ADD_OPTION_CHECKBOX_MODAL,
  ADDING_CHECKBOX_OPTIONS,
  CHECKBOX_OPTION_ADDED,
  CHECKBOX_OPTION_DELETED,
  SHOW_DEL_CHECKBOX_OPTION_MODAL,
  CLOSE_DEL_CHECKBOX_OPTION_MODAL,
  CHECKBOX_OPTION_DELETING,
  CHECKBOX_OPTION_DELETING_CONFIRM,
  SHOW_ADD_OPTION_RADIO_MODAL,
  CLOSE_ADD_OPTION_RADIO_MODAL,
  RADIO_OPTION_ADDED,
  ADDING_RADIO_OPTIONS,
  RADIO_OPTION_DELETED
} from "./types";

// получаем товары
export const getOptionList = () => dispatch => {
  dispatch(setOptionsLoading());
  axios
    .get(serverIp+"api/options/getoptionlist")
    .then(res => {
      dispatch({
        type: GET_OPTION_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_OPTION_ERRORS,
        payload: err.response
      })
    );
};

export const addOption = (option) => dispatch => {
  console.log(option);
  dispatch(setOptionAdding());
  axios
    .post(serverIp+"api/options/addoption", option)
    .then(res => {
      dispatch({
        type: OPTION_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_OPTION_ERRORS,
        payload: err.response
      })
    );
  };

  export const addCheckboxOption = (option) => dispatch => {
    dispatch(setCheckboxOptionAdding());
    axios
    .post(serverIp+"api/options/addcheckboxoption", option)
    .then(res => {
      dispatch({
        type: CHECKBOX_OPTION_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_OPTION_ERRORS,
        payload: err.response
      })
    );
  };

  export const deleteCheckboxOption = (option) => dispatch => {
    dispatch(setCheckboxDeleting());
    axios
      .post(serverIp+"api/options/deletecheckboxoption", option)
      .then(res => {
        dispatch({
          type: CHECKBOX_OPTION_DELETED,
          payload: res.data
        });
      })
      .catch(err =>
        dispatch({
          type: GET_OPTION_ERRORS,
          payload: err.response
        })
      );
  };

  export const setCheckboxDeleting = () => {
    return {
      type: CHECKBOX_OPTION_DELETING
    };
  };

  export const showDelCheckboxOptionModal = (option) => {
    return {
      type: SHOW_DEL_CHECKBOX_OPTION_MODAL,
      payload: option
    };
  };
  export const closeDelCheckboxOptionModal = () => {
    return {
      type: CLOSE_DEL_CHECKBOX_OPTION_MODAL
    };
  };

  export const deleteOption = (option) => dispatch => {
    axios
      .post(serverIp+"api/options/deleteoption", option)
      .then(res => {
        dispatch({
          type: OPTION_DELETED,
          payload: res.data
        });
      })
      .catch(err =>
        dispatch({
          type: GET_OPTION_ERRORS,
          payload: err.response
        })
      );
  };

  
  export const showAddOptionCheckboxModal = () => {
    return {
      type: SHOW_ADD_OPTION_CHECKBOX_MODAL
    };
  };
  export const closeAddOptionCheckboxModal = () => {
    return {
      type: CLOSE_ADD_OPTION_CHECKBOX_MODAL
    };
  };

  //РАДИО
  export const showAddOptionRadioModal = () => {
    return {
      type: SHOW_ADD_OPTION_RADIO_MODAL
    };
  };
  export const closeAddOptionRadioModal = () => {
    return {
      type: CLOSE_ADD_OPTION_RADIO_MODAL
    };
  };
  export const addRadioOption = (option) => dispatch => {
    dispatch(setRadioOptionAdding());
    axios
    .post(serverIp+"api/options/addradiooption", option)
    .then(res => {
      dispatch({
        type: RADIO_OPTION_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_OPTION_ERRORS,
        payload: err.response
      })
    );
  };
  export const setRadioOptionAdding = () => {
    return {
      type: ADDING_RADIO_OPTIONS
    };
  };
  export const deleteRadioOption = (option) => dispatch => {
    axios
      .post(serverIp+"api/options/deleteradiooption", option)
      .then(res => {
        dispatch({
          type: RADIO_OPTION_DELETED,
          payload: res.data
        });
      })
      .catch(err =>
        dispatch({
          type: GET_OPTION_ERRORS,
          payload: err.response
        })
      );
  };

  //

export const setOptionAdding = () => {
  return {
    type: ADDING_OPTIONS
  };
};

export const setCheckboxOptionAdding = () => {
  return {
    type: ADDING_CHECKBOX_OPTIONS
  };
};


export const setOptionsLoading = () => {
  return {
    type: LOADING_OPTIONS
  };
};
export const showAddOptionModal = () => {
  return {
    type: SHOW_ADD_OPTION_MODAL
  };
};
export const closeAddOptionModal = () => {
  return {
    type: CLOSE_ADD_OPTION_MODAL
  };
};
export const showDelOptionModal = (option) => {
  return {
    type: SHOW_DEL_OPTION_MODAL,
    payload: option
  };
};
export const closeDelOptionModal = () => {
  return {
    type: CLOSE_DEL_OPTION_MODAL
  };
};
