import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Row, Col, Divider, Spin, Card, Icon, Layout, Table, Button, Modal, Typography, Popconfirm, Popover } from 'antd';
import QueueAnim from 'rc-queue-anim';

import Moment from 'react-moment';
import 'moment/locale/ru';
import {
	getReserveList,
	getNewReserve,
	confirmReserve
} from "../../actions/reserveActions";

const CPReserve = props => {
	useFetching(getReserveList, useDispatch());
	const dispatch = useDispatch();
	const reserves = useSelector(state => state.reserve.reserves);
	const loading = useSelector(state => state.reserve.isLoading);
	const needToUpdate = useSelector(state => state.reserve.needToUpdate);
	const reserveToLoad = useSelector(state => state.reserve.reserveToLoad);

	useEffect(() => {
		if (needToUpdate) {
			let tmpReserves = reserves;
			const index = tmpReserves.indexOf(needToUpdate);
			if (index > -1) {
				return;
			}
			dispatch(getNewReserve(reserveToLoad));
		}
	}, [needToUpdate]);

	function renderReserveInfo(reserve) {
		return (
			<>
				<div key={reserve.id}>
					<div >
						<span><b>Имя:</b></span><br />
						<span>{reserve.name}</span><br />

						<br />
						<span><b>Телефон:</b></span><br />
						<span>{reserve.phone}</span><br />

						<br />
						<span><b>E-mail:</b></span><br />
						<span>{reserve.email}</span><br />

						<br />
						<span><b>Количество гостей:</b></span><br />
						<span>{reserve.count}</span><br />

						<br />
						<span><b>Пожелания к брони:</b></span><br />
						<span>{reserve.text}</span><br />

						<br />

						<span><b>Дата:</b></span><br />
						<span><Moment date={reserve.date} format="L" locale="ru" /></span><br />

						<br />
						<span><b>Время:</b></span><br />
						<span><Moment date={reserve.time} format="HH:mm" locale="ru" /></span><br />

					</div>
				</div>
			</>
		);
	}


	function renderConfirmReservePopup(row) {

		console.log(row._id)

		return (
			<>

				<Button type="primary" onClick={() => dispatch(confirmReserve(row._id))}>Обработать</Button>
			</>
		)

	}

	function renderSort() {

		const columns = [
			{
				title: '', dataIndex: 'created_at', key: 'created_at',
				render: (created_at, row) => {
					return (
						<>
							{row.confirmed_at != null ? (<></>) : (<>
								<Popover content={renderConfirmReservePopup(row)} trigger="hover">
									<Button icon="check" style={{ marginRight: 5, backgroundColor: '#9FBE34', border: 0 }} type="primary" ></Button>
								</Popover></>)}
							Поступило - <Moment date={created_at} format="L" locale="ru" /> - <Moment date={created_at} format="LT" locale="ru" /><br />
							{row.confirmed_at != null ? (<>Подтверждено - <Moment date={row.confirmed_at} format="L" locale="ru" /> - <Moment date={row.confirmed_at} format="LT" locale="ru" /></>) : (<></>)}
						</>
					)
				}
			},
			{ title: 'Имя', dataIndex: 'name', key: 'name' },
			{ title: 'Телефон', dataIndex: 'phone', key: 'phone' },
			{ title: 'E-mail', dataIndex: 'email', key: 'email' },
			{
				title: 'Дата поступления', dataIndex: 'created_at', key: 'created_at',
				render: (created_at, row) => {
					return (
						<>
							<Moment date={created_at} format="L" locale="ru" />
						</>
					)
				}
			},
			{
				title: 'Дата', dataIndex: 'date', key: 'date',
				render: (date, row) => {
					return (
						<>
							<Moment date={date} format="L" locale="ru" />
						</>
					)
				}
			},
			{
				title: 'Время', dataIndex: 'time', key: 'time',
				render: (time, row) => {
					return (
						<>
							<Moment date={time} format="HH:mm" locale="ru" />
						</>
					)
				}
			},
		];

		if (reserves.length > 0) {
			return (
				<Table
					rowKey={record => record._id}
					style={{ marginTop: 20 }}
					columns={columns}
					expandedRowRender={record => renderReserveInfo(record)}
					dataSource={reserves}
				/>
			);
		} else {
			return (
				<div>
					<Card title="Бронирований не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	}

	if (loading) {
		return (
			<div className="content-div">
				<Divider className="header-div" orientation="left"><Spin /></Divider>
			</div>
		);
	} else {
		return (
			<QueueAnim className="anim-content"
				type={['right', 'left']}
				ease={['easeOutQuart', 'easeInOutQuart']}>
				{!loading ? [
					<div key="a" className="content-div">
						<Divider className="header-div" orientation="left">Список бронирований</Divider>
						<Row type="flex" justify="end">
						</Row>
						{renderSort()}
						<Divider />
					</div>
				] : null}
			</QueueAnim>
		);
	}
};

const useFetching = (getReserveList, dispatch) => {
	useEffect(() => {
		dispatch(getReserveList());
	}, []);
};

export default CPReserve;
