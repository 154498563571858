import {
  GET_COUPONS_LIST,
  LOADING_COUPONS,
  GET_COUPONS_ERRORS,
  SET_COUPONS_ADDING,
  COUPONS_ADDED,
  COUPONS_DELETED,
  COUPONS_EDITED_ENABLED,
  COUPON_REPORT_LOADING,
  COUPON_REPORT_LOADED,
  EDITED_COUPON,
  EDITING_COUPON
} from "../actions/types";

const initialState = {
  isLoading: true,
  coupons: [],
  adding: false,
  couponReportLoading: false,
  couponReport: null,
  couponEditing: false,
};




export default function (state = initialState, action) {
  switch (action.type) {

    case EDITING_COUPON:
      return {
        ...state,
        couponEditing: true,
      }
    case EDITED_COUPON:
      const updatedCouponState = state.coupons.map((coupon) => {
        if (coupon._id === action.payload.editedcoupon._id) {
          return { ...coupon, ...action.payload.editedcoupon };
        } else {
        }
        return coupon;
      });
      return {
        ...state,
        coupons: updatedCouponState,
        couponEditing: false,
      };
    case COUPON_REPORT_LOADING:
      return {
        ...state,
        couponReportLoading: true,
      }
    case COUPON_REPORT_LOADED:
      console.log('COUPON_REPORT_LOADED');
      console.log(action.payload);
      return {
        ...state,
        couponReport: action.payload.orders,
        couponReportLoading: false,
      }

    //   case CART_GIFT_IMG_DELETED:
    //     const updatedDelImgState = state.cartgifts.map((cartgift) => {
    //       if (cartgift._id === action.payload.cartgift._id) {
    //         return { ...cartgift, ...action.payload.cartgift };
    //       } else {
    //       }
    //       return cartgift;
    //     });
    //     return {
    //       ...state,
    //       cartgifts: updatedDelImgState,
    //     };

    //   case CART_GIFT_IMG_ADDED:
    //     const updatedAddImgState = state.cartgifts.map((cartgift) => {
    //       if (cartgift._id === action.payload.cartgift._id) {
    //         return { ...cartgift, ...action.payload.cartgift };
    //       } else {
    //       }
    //       return cartgift;
    //     });
    //     return {
    //       ...state,
    //       cartgifts: updatedAddImgState,
    //     };

    //   case CLOSE_CART_GIFT_ADD_IMG_MODAL:
    //     return{
    //       ...state,
    //       showAddImg: false,
    //       itemAddPhoto: {},
    //     }

    //   case SHOW_CART_GIFT_ADD_IMG_MODAL:
    //     return{
    //       ...state,
    //       showAddImg: true,
    //       itemAddPhoto: action.payload
    //     }

    case COUPONS_DELETED:
      const id = action.payload.deletedcoupon._id;
      return {
        ...state,
        coupons: state.coupons.filter((coupon) => coupon._id !== id),
        adding: false,
      };

    case COUPONS_EDITED_ENABLED:
      const updatedState = state.coupons.map((coupon) => {
        if (coupon._id === action.payload.editedcoupon._id) {
          return { ...coupon, ...action.payload.editedcoupon };
        } else {
        }
        return coupon;
      });
      return {
        ...state,
        coupons: updatedState,
        adding: false,
      };

    case COUPONS_ADDED:
      return {
        ...state,
        adding: false,
        coupons: [...state.coupons, action.payload.newcoupon],
      }

    case SET_COUPONS_ADDING:
      return {
        ...state,
        adding: true
      }

    case GET_COUPONS_LIST:
      return {
        ...state,
        isLoading: false,
        coupons: action.payload.coupons,
      };

    case LOADING_COUPONS:
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
}
