import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select, Input, Button, Row, Col, Typography, Divider, Spin, Card, Icon, Layout, Table, Modal, Checkbox, Popconfirm, Upload, Avatar, DatePicker } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
	getPromotionList,
	showAddPromotionModal,
	closeAddPromotionModal,
	showEditPromotionModal,
	closeEditPromotionModal,
	addPromotion,
	deletePromotion,
	editPromotion,
	showAddImgPromotionModal,
	closeAddImgPromotionModal,
	sendPromotionImage,
	sendPromotionImageDelete,
} from "../../actions/promotionActions";
import moment from 'moment';
import RichTextEditor from 'react-rte';
import serverIp from "../../utils/serverIp";
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import Moment from 'react-moment';
const { TextArea } = Input;

const CPPromotions = props => {
	useFetching(getPromotionList, useDispatch());
	const dispatch = useDispatch();

	const dateFormat = 'YYYY/MM/DD';

	const [values, setValues] = React.useState({
		title: '',
		date: '',
		text: RichTextEditor.createEmptyValue(),
		image1: '',
		public: false,
		meta_h1: '',
		meta_title: '',
		meta_description: '',
		url: ''
	});
	const [editvalues, setEditValues] = React.useState({
		_id: '',
		title: '',
		date: '',
		text: RichTextEditor.createEmptyValue(),
		image1: '',
		public: false,
		meta_h1: '',
		meta_title: '',
		meta_description: '',
		url: ''
	});
	const [valuesImg, setValuesImg] = React.useState({
		image1: ''
	});

	const promotions = useSelector(state => state.promotions.promotions);
	const loading = useSelector(state => state.promotions.isLoading);

	const adding = useSelector(state => state.promotions.adding);
	const showAdd = useSelector(state => state.promotions.showAdd);
	const showEdit = useSelector(state => state.promotions.showEdit);
	const showAddImg = useSelector(state => state.promotions.showAddImg);
	const promotionsAddPhoto = useSelector(state => state.promotions.promotionsAddPhoto);
	const imgBtnDisable = useSelector(state => state.promotions.imgBtnDisable);
	const imgUploading = useSelector(state => state.promotions.imgUploading);

	const editing = useSelector(state => state.promotions.editing);


	const handleOpenAddModal = useCallback(() => dispatch(showAddPromotionModal()), [dispatch]);

	const handleCloseAddModal = useCallback(
		() => {
			setValues({ text: RichTextEditor.createEmptyValue() });
			dispatch(closeAddPromotionModal());
		},
		[dispatch]
	);


	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};

	function handleChangeTime(date, dateString) {
		setValues({ ...values, "date": dateString });
	}
	function handleChangeTimeEdit(date, dateString) {
		setEditValues({ ...editvalues, "date": dateString });
	}

	const handleAddPromotion = () => {
		dispatch(addPromotion(values));
		setValues({
			title: '',
			date: '',
			text: RichTextEditor.createEmptyValue(),
			public: false,
			meta_h1: '',
			meta_title: '',
			meta_description: '',
			url: ''
		});
	}

	const handleDelPromotion = (promotion) => {
		dispatch(deletePromotion(promotion));
	};

	const handleAddPromotionPublicChange = (e, from) => {
		if (e.target.name == 'public') {
			setValues({ ...values, "public": e.target.checked });
		}
	}
	const handleEditPromotionPublicChange = (e, from) => {
		if (e.target.name == 'public') {
			setEditValues({ ...editvalues, "public": e.target.checked });
		}
	}

	const handleOpenEditPromotion = (promotion) => {
		let oldValuePromotion = editvalues.text;
		setEditValues({
			...editvalues,
			_id: promotion._id,
			title: promotion.title,
			date: promotion.date,
			text: oldValuePromotion.setContentFromString(promotion.text, 'html'),
			image1: promotion.image1,
			public: promotion.public,
			meta_h1: promotion.meta_h1,
			meta_title: promotion.meta_title,
			meta_description: promotion.meta_description,
			url: promotion.url,
		});
		dispatch(showEditPromotionModal(promotion));
	};

	const handleCloseEditPromotion = useCallback(() => dispatch(closeEditPromotionModal()), [dispatch]);

	const handleEditChange = name => event => {
		setEditValues({ ...editvalues, [name]: event.target.value });
	};

	const handleEditPromotion = () => {
		dispatch(editPromotion(editvalues));
		setEditValues({
			title: '',
			date: '',
			text: RichTextEditor.createEmptyValue(),
			image1: '',
			public: false,
			url: ''
		});
	};

	const handleEditorAddChange = name => event => {
		let source = event.target.value;
		let oldValue = values.data;
		setValues({ ...values, [name]: oldValue.setContentFromString(source, 'html') });
	};

	const handleEditorEditChange = name => event => {
		let source = event.target.value;
		let oldValue = editvalues.text;
		setEditValues({ ...editvalues, [name]: oldValue.setContentFromString(source, 'html') });
	};

	const showAddImagePromotionModal = promotions => {
		if (promotions.image1 != '') {
			setValuesImg(prevState => ({ ...prevState, ['image1']: serverIp + promotions.image1 }));
		}
		dispatch(showAddImgPromotionModal(promotions));
	};
	const handleCloseAddImageModal = () => {
		dispatch(closeAddImgPromotionModal());
		setValuesImg({
			image1: ''
		});
	};

	const uploadButton = (
		<div>
			<Icon type='plus' />
			<div className="ant-upload-text">Загрузить</div>
		</div>
	);

	const dummyRequest = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	const handleChangeImage1 = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, imageUrl => {
				let imageFormObj = new FormData();
				var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", promotionsAddPhoto._id);
				imageFormObj.append("imgNum", "image1");
				imageFormObj.append("imageData", info.file.originFileObj);
				setValuesImg({ ...valuesImg, "image1": imageToShow });
				dispatch(sendPromotionImage(imageFormObj));
			});
		}
	};

	const handleImageDelete = (img, promotions) => event => {
		if (img == 'image1') {
			setValuesImg(prevState => ({ ...prevState, ['image1']: '' }));
		}
		console.log(promotions);
		var imageObj = {
			"id": promotions,
			"imgNum": img
		}
		dispatch(sendPromotionImageDelete(imageObj));
	};

	function renderPromotionInfo(promotions) {
		return (
			<>
				<div key={promotions.id}>
					<Row gutter={16}>
						<Col className="gutter-row" xs={24} sm={12}>
							<div>
								<span><b>Заголовок:</b></span><br />
								<span>{promotions.title}</span><br />
								<br />
								<span><b>Дата:</b></span><br />
								<span><Moment date={promotions.date} format="ll" locale="ru" /></span><br />
								<br />
								<span><b>Текст:</b></span><br />
								<span>{promotions.text}</span><br />
								<br />

								<span><b>META H1:</b></span><br />
								<span>{promotions.meta_h1}</span><br />
								<br />

								<span><b>META Title:</b></span><br />
								<span>{promotions.meta_title}</span><br />
								<br />

								<span><b>META Description:</b></span><br />
								<span>{promotions.meta_description}</span><br />
								<br />

								<span><b>SEO Url:</b></span><br />
								<span>{promotions.url}</span><br />
								<br />

								<span><b>Опубликовано:</b></span><br />
								<span>{promotions.public ? (<div><p>Да</p></div>) : (<div><p>Нет</p></div>)}</span><br />
							</div>
						</Col>
						<Col className="gutter-row" xs={24} sm={12}>
							<div>
								<span><b>Изображение:</b></span><br />
								{(promotions.image1 != '') ? (
									<Avatar size={72} alt={promotions.title} src={serverIp + promotions.image1} shape="square" style={{ borderRadius: 4, marginRight: 10, marginBottom: 10 }} />
								) : (
										<div></div>
									)}
								<br />
							</div>
						</Col>
					</Row>


				</div>
				<div className="customer-buttons">
					<Popconfirm title="Удалить акцию?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelPromotion(promotions)}>
						<Button style={{ marginRight: 5 }} type="dashed">УДАЛИТЬ</Button>
					</Popconfirm>
					<Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleOpenEditPromotion(promotions)}>РЕДАКТИРОВАТЬ</Button>
					<Button style={{ marginRight: 5 }} type="dashed" icon="picture" onClick={() => showAddImagePromotionModal(promotions)}>РЕДАКТИРОВАТЬ ИЗОБРАЖЕНИЕ</Button>
				</div>
			</>
		);
	}

	function renderSort() {

		const columns = [
			{ title: 'Заголовок', dataIndex: 'title', key: 'title' },
			{
				title: 'Дата', dataIndex: 'date', key: 'date',
				render: date => {
					return (
						<><Moment date={date} format="ll" locale="ru" /></>
					)
				}
			},
			{
				title: 'Опубликовано', dataIndex: 'public', key: 'public',
				render: statusvalue => {
					if (statusvalue) {
						return (
							<>
								<Icon style={{ color: 'green' }} type="check" />
							</>
						);
					} else {
						return (
							<>
								<Icon style={{ color: 'red' }} type="stop" />
							</>
						);
					}
				}
			},
		];

		if (promotions.length > 0) {
			return (
				<Table
					rowKey={record => record._id}
					style={{ marginTop: 20 }}
					columns={columns}
					expandedRowRender={record => renderPromotionInfo(record)}
					dataSource={promotions}
				/>
			);
		} else {
			return (
				<div>
					<Card title="Акций не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	}

	if (loading) {
		return (
			<div className="content-div">
				<Divider className="header-div" orientation="left"><Spin /></Divider>
			</div>
		);
	} else {
		return (
			<QueueAnim className="anim-content"
				type={['right', 'left']}
				ease={['easeOutQuart', 'easeInOutQuart']}>
				{!loading ? [
					<div key="a" className="content-div">
						<Divider className="header-div" orientation="left">Акции</Divider>
						<Row type="flex" justify="end">
						</Row>
						{renderSort()}
						<Divider />
						<Card style={{ width: '100%', marginBottom: 20 }} size="small">
							<Button type="primary" icon="plus" onClick={handleOpenAddModal}>
								Добавить
        						</Button>
						</Card>

						<Modal
							centered
							title={"Редактирование изображения"}
							style={{ textAlign: 'center' }}
							visible={showAddImg}
							onCancel={handleCloseAddImageModal}
							footer={[
								<Button
									key="yes"
									type="primary"
									disabled={imgUploading}
									onClick={handleCloseAddImageModal}
								>
									Готово
							</Button>,
							]}
						>

							<Row gutter={16}>
								<Col className="gutter-row" style={{ textAlign: 'center' }} xs={24} sm={24}>
									<Upload
										style={{ margin: '0 auto' }}
										name="avatar"
										listType="picture-card"
										className="avatar-uploader"
										showUploadList={false}
										customRequest={dummyRequest}
										onChange={handleChangeImage1}
									>
										{valuesImg.image1 ? <img src={valuesImg.image1} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
									</Upload>
									{(valuesImg.image1 != '') ? (<Button type="danger" onClick={handleImageDelete('image1', promotionsAddPhoto._id)} disabled={imgBtnDisable}>Очистить</Button>) : (<div></div>)}
								</Col>
							</Row>
						</Modal>


						<Modal
							centered
							title={"Редактирование акции"}
							visible={showEdit}
							onCancel={handleCloseEditPromotion}
							footer={[
								<Button
									key="back"
									onClick={handleCloseEditPromotion}
								>
									Отмена
          							</Button>,
								<Button
									key="yes"
									type="primary"
									disabled={editing}
									onClick={handleEditPromotion}
								>
									Сохранить
          							</Button>,
							]}
						>
							<div>

								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
										<Typography style={{ textAlign: 'left' }}>Заголовок:</Typography>
										<Input
											value={editvalues.title}
											name="title"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("title")}
										/>
										<Typography style={{ textAlign: 'left' }}>Дата:</Typography>
										<DatePicker style={{ marginBottom: 10 }} name="date" locale="ru-RU" defaultValue={moment(editvalues.date)} onChange={handleChangeTimeEdit} />
										<Typography style={{ textAlign: 'left' }}>Текст:</Typography>
										<RichTextEditor
											className="rte-height"
											value={editvalues.text}
											onChange={value => setEditValues({ ...editvalues, text: value })}
										/>
										<TextArea style={{ marginTop: 5, marginBottom: 10 }} label="HTML" value={editvalues.text.toString('html')} onChange={handleEditorEditChange("text")} rows={5} />

										<Checkbox name={"public"} style={{ marginTop: 10, marginBottom: 10 }} checked={editvalues.public} onChange={(e) => handleEditPromotionPublicChange(e, "public")}>Опубликовано?</Checkbox>

										<Typography style={{ textAlign: 'left' }}>META H1:</Typography>
										<Input
											value={editvalues.meta_h1}
											name="meta_h1"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("meta_h1")}
										/>

										<Typography style={{ textAlign: 'left' }}>META Title:</Typography>
										<Input
											value={editvalues.meta_title}
											name="meta_title"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("meta_title")}
										/>

										<Typography style={{ textAlign: 'left' }}>META Description:</Typography>
										<Input
											value={editvalues.meta_description}
											name="meta_description"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("meta_description")}
										/>

										<Typography style={{ textAlign: 'left' }}>SEO Url:</Typography>
										<Input
											value={editvalues.url}
											name="url"
											style={{ marginBottom: 10 }}
											onChange={handleEditChange("url")}
										/>

									</Col>
								</Row>
							</div>
						</Modal>

						<Modal
							centered
							title={"Добавление акции"}
							visible={showAdd}
							onCancel={handleCloseAddModal}
							footer={[
								<Button
									key="back"
									onClick={handleCloseAddModal}
								>
									Отмена
          							</Button>,
								<Button
									key="yes"
									type="primary"
									disabled={adding}
									onClick={handleAddPromotion}
								>
									Добавить
          							</Button>,
							]}
						>
							<div>

								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
										<Typography style={{ textAlign: 'left' }}>Заголовок:</Typography>
										<Input
											value={values.title}
											name="title"
											style={{ marginBottom: 10 }}
											onChange={handleChange("title")}
										/>
										<Typography style={{ textAlign: 'left' }}>Дата:</Typography>
										<DatePicker style={{ marginBottom: 10 }} name="date" locale={locale} defaultValue={moment('2020/01/01', dateFormat)} format={dateFormat} onChange={handleChangeTime} />
										<Typography style={{ textAlign: 'left' }}>Текст:</Typography>
										<RichTextEditor
											className="rte-height"
											value={values.text}
											onChange={value => setValues({ ...values, text: value })}
										/>
										<TextArea style={{ marginTop: 5, marginBottom: 10 }} label="HTML" value={values.text.toString('html')} onChange={handleEditorAddChange("text")} rows={5} />
										<Checkbox name={"public"} style={{ marginTop: 10, marginBottom: 10}} checked={values.public} onChange={(e) => handleAddPromotionPublicChange(e, "public")}>Опубликовано?</Checkbox>

										<Typography style={{ textAlign: 'left' }}>META H1:</Typography>
										<Input
											value={values.meta_h1}
											name="meta_h1"
											style={{ marginBottom: 10 }}
											onChange={handleChange("meta_h1")}
										/>

										<Typography style={{ textAlign: 'left' }}>META Title:</Typography>
										<Input
											value={values.meta_title}
											name="meta_title"
											style={{ marginBottom: 10 }}
											onChange={handleChange("meta_title")}
										/>

										<Typography style={{ textAlign: 'left' }}>META Description:</Typography>
										<Input
											value={values.meta_description}
											name="meta_description"
											style={{ marginBottom: 10 }}
											onChange={handleChange("meta_description")}
										/>

										<Typography style={{ textAlign: 'left' }}>SEO Url:</Typography>
										<Input
											value={values.url}
											name="url"
											style={{ marginBottom: 10 }}
											onChange={handleChange("url")}
										/>

									</Col>
								</Row>
							</div>
						</Modal>

					</div>
				] : null}
			</QueueAnim>
		);
	}
};

const useFetching = (getPromotionList, dispatch) => {
	useEffect(() => {
		dispatch(getPromotionList());
	}, []);
};

export default CPPromotions;
