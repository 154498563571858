import {
    GET_PRINT_SETTINGS,
    EDIT_PRINT_SETTINGS,
    SET_PRINT_SETTINGS,
    PRINT_SETTINGS_LOADING,
    PRINT_SETTINGS_SENDING,
    GET_ERRORS
} from "./types";

import axios from "axios";
import serverIp from "../utils/serverIp";

export const getPrintSettings = () => dispatch => {
    dispatch(setPrintSettingsLoading());
    axios
        .get(serverIp + "api/printer/getprintersettings")
        .then(res => {
            dispatch({
                type: GET_PRINT_SETTINGS,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        );
};

export const setPrintSettings = (setdata) => dispatch => {
    dispatch(setPrintSettingsSending());
    axios
        .put(serverIp + "api/printer/setprintersettings", setdata)
        .then(res => {
            dispatch({
                type: SET_PRINT_SETTINGS,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        );
};

export const editPrintSettings = (data) => dispatch => {
    dispatch({
        type: EDIT_PRINT_SETTINGS,
        payload: data
    });
};

export const setPrintSettingsSending = () => {
    return {
        type: PRINT_SETTINGS_SENDING
    };
};
export const setPrintSettingsLoading = () => {
    return {
        type: PRINT_SETTINGS_LOADING
    };
};

