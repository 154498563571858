import {
    GET_IIKO_SETTINGS,
    EDIT_IIKO_SETTINGS,
    SAVE_IIKO_SETTINGS,
    LOADING_IIKO_SETTINGS,
    IIKO_MENU_LOADED,
    IIKO_MENU_LOADING
} from "../actions/types";

const initialState = {
    isLoading: true,
    needSave: false,
    enabled: 0,
    iikoSettings: {},
    mainUserLogin: '',
    mainUserSecret: '',
    menuLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case IIKO_MENU_LOADED:
            return{
                ...state,
                menuLoading: false,
            }
        case IIKO_MENU_LOADING:
            return{
                ...state,
                menuLoading: true,
            }
        case GET_IIKO_SETTINGS:
            console.log('GET_IIKO_SETTINGS');
            console.log(action.payload.settings.enabled);
            return {
                ...state,
                isLoading: false,
                enabled: action.payload.settings.enabled,
                mainUserLogin: action.payload.settings.mainUserLogin,
                mainUserSecret: action.payload.settings.mainUserSecret,
                //iikoSettings: action.payload.settings.iikoSettings,
            };
        case EDIT_IIKO_SETTINGS:
            return {
                ...state,
                enabled: action.payload.enabled,
                mainUserLogin: action.payload.mainUserLogin,
                mainUserSecret: action.payload.mainUserSecret,
                //iikoSettings: action.payload.newIikoSettings,
                needSave: true,
            };
        case SAVE_IIKO_SETTINGS:
            return {
                ...state,
                needSave: false,
            };
        case LOADING_IIKO_SETTINGS:
            return {
                ...state,
                isLoading: true,
            }
        default:
            return state;
    }
}
