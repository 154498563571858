import axios from "axios";
import serverIp from "../utils/serverIp";

import {
    FEEDBACK_LIST_LOADED,
    FEEDBACK_LIST_LOADING,
    FEEDBACK_LIST_DELETED,
    GET_FEEDBACK_ERRORS,
    FEEDBACK_EDITED
} from "./types";

export const confirmFeedback = (rowId) => dispatch => {
  axios
  .post(serverIp+"api/feedback/confirmfeedback", {feedbackId: rowId})
  .then(res => {
    dispatch({
      type: FEEDBACK_EDITED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_FEEDBACK_ERRORS,
      payload: err.response
    })
  );
}

export const getFeedbackList = () => dispatch => {
    dispatch(setFeedbackLoading());
    axios
      .get(serverIp+"api/feedback/getfeedbacklist")
      .then(res => {
        dispatch({
          type: FEEDBACK_LIST_LOADED,
          payload: res.data
        });
      })
      .catch(err =>
        dispatch({
          type: GET_FEEDBACK_ERRORS,
          payload: err.response
        })
      );
  };

export const setFeedbackLoading = () => {
    return {
        type: FEEDBACK_LIST_LOADING
    };
};

export const deleteFeedback = (feedback) => dispatch => {
  axios
    .post(serverIp + "api/feedback/deletefeedback", { id: feedback })
    .then(res => {
      dispatch({
        type: FEEDBACK_LIST_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_FEEDBACK_ERRORS,
        payload: err.response
      })
    );
};