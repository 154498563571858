import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InputNumber, Select, Input, Button, Row, Col, Avatar, Typography, Divider, Upload, Spin, Card, Icon, Layout, Table, Modal, Checkbox, Popconfirm } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
	getCartGiftsList,
	addGift,
	deleteGift,
	changeEnabled,
	showAddImgItemModal,
	closeAddImgItemModal,
	sendItemImage,
	sendItemImageDelete
} from "../../actions/cartGiftsActions";
import {
	loadItems,
	loadGiftItems
} from "../../actions/orderActions";
import serverIp from "../../utils/serverIp";

const { Option } = Select;

const CPCartGifts = props => {
	useFetching(getCartGiftsList, useDispatch());
	const dispatch = useDispatch();

	const cartgifts = useSelector(state => state.cartgifts.cartgifts);
	const loading = useSelector(state => state.cartgifts.isLoading);
	const adding = useSelector(state => state.cartgifts.adding);
	const showAddImg = useSelector(state => state.cartgifts.showAddImg);
	const imgUploading = useSelector(state => state.cartgifts.imgUploading);
	const itemAddPhoto = useSelector(state => state.cartgifts.itemAddPhoto);

	const [showAdd, setShowAdd] = React.useState(false);

	const [valuesImg, setValuesImg] = React.useState({
		image1: ''
	});

	const dummyRequest = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

	const uploadButton = (
		<div>
			<Icon type='plus' />
			<div className="ant-upload-text">Загрузить</div>
		</div>
	);

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}
	const handleChangeImage1 = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, imageUrl => {
				let imageFormObj = new FormData();
				var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", itemAddPhoto._id);
				imageFormObj.append("imgNum", "image1");
				imageFormObj.append("imageData", info.file.originFileObj);
				setValuesImg({ ...valuesImg, "image1": imageToShow });
				dispatch(sendItemImage(imageFormObj));
			});
		}
	};
	const handleImageDelete = (img, gift) => event => {
		if (img == 'image1') {
			setValuesImg(prevState => ({ ...prevState, ['image1']: '' }));
		}
		var imageObj = {
			"id": gift,
			"imgNum": img
		}
		dispatch(sendItemImageDelete(imageObj));
	};

	const setHideImg = () => {
		dispatch(closeAddImgItemModal());
		setValuesImg({
			image1: ''
		});
	};

	const [values, setValues] = React.useState({
		name: '',
		min_price: 0,
		max_price: 0,
		gift: {},
		info: '',
		image1: '',
	});

	const itemsToAdd = useSelector(state => state.orders.giftitemsToAdd);

	const handleSelectItemToAdd = (e) => {
		var result = itemsToAdd.filter(obj => {
			return obj._id === e
		})
		setValues({ ...values, "gift": { "name": result[0].name, "itemId": result[0]._id, "image": result[0].image1} });
		console.log(values);
	}

	const handleAddGift = () => {
		console.log(values);
	}



	function renderGiftInfo(gift) {
		return (
			<Row gutter={16} key={gift.id}>
				<Col className="gutter-row" xs={24} sm={24}>
					<div >
						<span><b>Название подарка:</b></span><br />
						<span>{gift.name}</span><br />

						<br />
						<span><b>Название товара:</b></span><br />
						<span>{gift.gift.name}</span><br />

						<br />
						<span><b>Минимальная сумма корзины для добавления:</b></span><br />
						<span>{gift.min_price}</span><br />

						<br />
						<span><b>Максимальная сумма корзины:</b></span><br />
						<span>{gift.max_price}</span><br />

						<br />
						<span><b>Включено:</b></span><br />
						<span>{gift.enabled ? (<span>Да</span>) : (<span>Нет</span>)}</span><br />

						<br />
						<span><b>Дополнительная информация:</b></span><br />
						<span>{gift.info}</span><br /><br />

						<span><b>Изображения:</b></span><br />
						{(gift.image != '') ? (
							<Avatar size={72} alt={gift.name} src={serverIp + gift.gift.image} shape="square" style={{ borderRadius: 4, marginRight: 10, marginBottom: 10 }} />
						) : (
								<div></div>
							)}

					</div>
				</Col>
				<Col className="gutter-row" xs={24} sm={24}>
					<div className="customer-buttons">
						<Popconfirm title="Удалить подарок?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => dispatch(deleteGift(gift._id))}>
							<Button style={{ marginRight: 5 }} type="dashed">УДАЛИТЬ</Button>
						</Popconfirm>
						{gift.enabled ? (<><Button style={{ marginLeft: 5 }} type="dashed" onClick={() => dispatch(changeEnabled(gift._id, false))}>ВЫКЛЮЧИТЬ</Button></>) : (<><Button style={{ marginLeft: 5 }} type="dashed" onClick={() => dispatch(changeEnabled(gift._id, true))}>ВКЛЮЧИТЬ</Button></>)}
					</div>
				</Col>
			</Row>
		);
	}

	function renderSort() {

		const columns = [
			{ title: 'Название', dataIndex: 'name', key: 'name' },
			{ title: 'Мин. цена', dataIndex: 'min_price', key: 'min_price' },
			{ title: 'Макс. цена', dataIndex: 'max_price', key: 'max_price' },
			{
				title: 'Включено', dataIndex: 'enabled', key: 'enabled',
				render: enabled => {
					if (enabled) {
						return (
							<>
								<Icon style={{ color: 'green' }} type="check" />
							</>
						);
					} else {
						return (
							<>
								<Icon style={{ color: 'red' }} type="stop" />
							</>
						);
					}
				}
			},
			//
		];

		if (cartgifts.length > 0) {
			return (
				<Table
					rowKey={record => record._id}
					style={{ marginTop: 20 }}
					columns={columns}
					expandedRowRender={record => renderGiftInfo(record)}
					dataSource={cartgifts}
				/>
			);
		} else {
			return (
				<div>
					<Card title="Подарков не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	}

	if (loading) {
		return (
			<div className="content-div">
				<Divider className="header-div" orientation="left"><Spin /></Divider>
			</div>
		);
	} else {
		return (
			<QueueAnim className="anim-content"
				type={['right', 'left']}
				ease={['easeOutQuart', 'easeInOutQuart']}>
				{!loading ? [
					<div key="a" className="content-div">
						<Divider className="header-div" orientation="left">Подарки в корзине</Divider>
						<Row type="flex" justify="end">
						</Row>

						{renderSort()}
						<Card style={{ width: '100%', marginBottom: 20 }} size="small">
							<Button type="primary" icon="plus" disabled={adding} onClick={() => { setShowAdd(true); dispatch(loadGiftItems()) }}>
								Добавить
        						</Button>{adding ? <Spin style={{ marginLeft: 20 }} /> : (<></>)}
						</Card>
						<Divider />

						<Modal
							centered
							title={"Добавление подарка"}
							visible={showAdd}
							onCancel={() => setShowAdd(false)}
							footer={[
								<Button key="back" disabled={adding} onClick={() => setShowAdd(false)}>Отмена</Button>,
								<Button key="yes" type="primary" disabled={adding} onClick={() => { dispatch(addGift(values)); setShowAdd(false) }}>Добавить</Button>
							]}
						>
							<>
								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'center' }} xs={24} sm={24}>
										<Typography style={{ textAlign: 'left' }}>Название:</Typography>
										<Input
											value={values.name}
											name="name"
											style={{ marginBottom: 10 }}
											onChange={(e) => setValues({ ...values, "name": e.target.value })}
										/>
										<Typography style={{ textAlign: 'left' }}>Минимальная сумма корзины для добавления:</Typography>
										<InputNumber style={{ marginBottom: 10, width: '100%' }} min={0} max={99999999} defaultValue={0} value={values.min_price} onChange={(e) => setValues({ ...values, "min_price": e })} />
										<Typography style={{ textAlign: 'left' }}>Максимальная сумма корзины:</Typography>
										<InputNumber style={{ marginBottom: 10, width: '100%' }} min={0} max={99999999} defaultValue={0} value={values.max_price} onChange={(e) => setValues({ ...values, "max_price": e })} />

										<Typography>Выберите товар из списка</Typography>

										<Select
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											size="default"
											style={{ marginBottom: 10, width: '100%' }}
											onChange={handleSelectItemToAdd}
										>
											{itemsToAdd.map(item => {
												return (
													<Option key={item.name} value={item._id}>{item.name}</Option>
												);
											})}
										</Select>

										<Typography style={{ textAlign: 'left' }}>Информация для покупателя:</Typography>
										<Input
											value={values.info}
											name="info"
											style={{ marginBottom: 10 }}
											onChange={(e) => setValues({ ...values, "info": e.target.value })}
										/>
									</Col>
								</Row>
							</>
						</Modal>

						<Modal
							centered
							title={"Реактирование изображений"}
							style={{ textAlign: 'center' }}
							visible={showAddImg}
							onCancel={setHideImg}
							footer={[
								<Button
									key="yes"
									type="primary"
									disabled={imgUploading}
									onClick={setHideImg}
								>
									Готово
							</Button>,
							]}
						>
							<>
								<div>
									<Divider className="header-div" orientation="left">{itemAddPhoto.name}</Divider>
								</div>
								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'center' }} xs={12} sm={6}>
										<p>Основное</p>
										<Upload
											style={{ margin: '0 auto' }}
											name="avatar"
											listType="picture-card"
											className="avatar-uploader"
											showUploadList={false}
											customRequest={dummyRequest}
											onChange={handleChangeImage1}
										>
											{valuesImg.image1 ? <img src={valuesImg.image1} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
										</Upload>
										{(valuesImg.image1 != '') ? (<Button type="danger" onClick={handleImageDelete('image1', itemAddPhoto._id)}>Очистить</Button>) : (<div></div>)}
									</Col>
								</Row>
							</>
						</Modal>
					</div>
				] : null}
			</QueueAnim>
		);
	}
};

const useFetching = (getCartGiftsList, dispatch) => {
	useEffect(() => {
		dispatch(getCartGiftsList());
	}, []);
};

export default CPCartGifts;
