import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  GET_DELIVERY_LIST,
  LOADING_DELIVERY,
  GET_DELIVERY_ERRORS,
  SHOW_ADD_DELIVERY_MODAL,
  CLOSE_ADD_DELIVERY_MODAL,
  DELIVERY_ADDED,
  DELIVERY_ADDING,
  DELIVERY_DELETED,
  SHOW_EDIT_DELIVERY_MODAL,
  CLOSE_EDIT_DELIVERY_MODAL,
  DELIVERY_EDITING,
  DELIVERY_EDITED,
  STOP_ADDED
} from "./types";

export const sendStopSell = (canBeOrdered, disableOrderTime) => dispatch => {
  axios
  .post(serverIp+"api/delivery/stopsell", {canBeOrdered:canBeOrdered, disableOrderTime:disableOrderTime})
  .then(res => {
      dispatch({
        type: STOP_ADDED
      });
  })
  .catch(err =>
    dispatch({
      type: GET_DELIVERY_ERRORS,
      payload: err.response
    })
  );
}

export const editDelivery = (delivery) => dispatch => {
  dispatch(setDeliveryEditing());
  axios
    .post(serverIp+"api/delivery/editdelivery", delivery)
    .then(res => {
      dispatch({
        type: DELIVERY_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_DELIVERY_ERRORS,
        payload: err.response
      })
    );};

export const showEditDeliveryModal = (delivery) => {
  return {
    type: SHOW_EDIT_DELIVERY_MODAL,
    payload: delivery
  };
};

export const closeEditDeliveryModal = () => {
  return {
    type: CLOSE_EDIT_DELIVERY_MODAL
  };
};

export const deleteDelivery = (delivery) => dispatch => {
  axios
    .post(serverIp + "api/delivery/deletedelivery", delivery)
    .then(res => {
      dispatch({
        type: DELIVERY_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_DELIVERY_ERRORS,
        payload: err.response
      })
    );
};

export const addDelivery = (delivery) => dispatch => {
  dispatch(setDeliveryAdding());
  axios
    .post(serverIp + "api/delivery/adddelivery", delivery)
    .then(res => {
      dispatch({
        type: DELIVERY_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_DELIVERY_ERRORS,
        payload: err.response
      })
    );
};

export const setDeliveryEditing = () => {
  return {
    type: DELIVERY_EDITING
  };
};

export const setDeliveryAdding = () => {
  return {
    type: DELIVERY_ADDING
  };
};

export const showAddDeliveryModal = () => {
  return {
    type: SHOW_ADD_DELIVERY_MODAL
  };
};

export const closeAddDeliveryModal = () => {
  return {
    type: CLOSE_ADD_DELIVERY_MODAL
  };
};

// получаем доставки
export const getDeliveryList = () => dispatch => {
  //console.log('getDeliveryList');
  dispatch(setDeliveryLoading());
  axios
    .get(serverIp + "api/delivery/getdeliverylist")
    .then(res => {
      dispatch({
        type: GET_DELIVERY_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_DELIVERY_ERRORS,
        payload: err.response
      })
    );
};

// Загружаются способы доставки
export const setDeliveryLoading = () => {
  return {
    type: LOADING_DELIVERY
  };
};
