import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getWebSettings, editWebSettings, saveWebSettings, showAddBanner, closeAddBanner, addWebBanner, delWebBanner, showAddBannerImgModal, closeAddBannerImgModal, addBannerImg, deleteBannerImg, showEditBannerModal, closeEditBannerModal, editBanner } from '../../actions/webSettingActions'
import { Select, Input, Button, Typography, Divider, Spin, Layout, Tabs, Modal, Card, Upload, Popconfirm, Row, Col, InputNumber, Icon, Checkbox } from 'antd';
import QueueAnim from 'rc-queue-anim';
import serverIp from "../../utils/serverIp";
const { Option } = Select;
const { TabPane } = Tabs;


const CPWebSettings = props => {
    useFetching(getWebSettings, useDispatch());
    const dispatch = useDispatch();
    const loading = useSelector(state => state.websettings.isLoading);
    const needSave = useSelector(state => state.websettings.needSave);
    const settings = useSelector(state => state.websettings.settings);
    const isShowAddBanner = useSelector(state => state.websettings.isShowAddBanner);
    const bannerAdding = useSelector(state => state.websettings.bannerAdding);
    const bannerToAddImg = useSelector(state => state.websettings.bannerToAddImg);
    const isShowAddImgBanner = useSelector(state => state.websettings.isShowAddImgBanner);
    const bannerIndexAddImg = useSelector(state => state.websettings.bannerIndexAddImg);
    const bannerImgAdding = useSelector(state => state.websettings.bannerImgAdding);
    const bannerToEdit = useSelector(state => state.websettings.bannerToEdit);
    const isShowBannerEdit = useSelector(state => state.websettings.isShowBannerEdit);
    const bannerEditing = useSelector(state => state.websettings.bannerEditing);


    const handleSaveButton = (e) => {
        dispatch(saveWebSettings(settings));
    };

    const handleShowAddBanner = () => {
        dispatch(showAddBanner());
    }
    const handleCloseAddBanner = () => {
        dispatch(closeAddBanner());
    }

    const handleEditSettings = (e, from) => {
        let mainTelephone = settings.mainTelephone;
        let mainEmail = settings.mainEmail;
        let streetAddress = settings.streetAddress;
        let headerSlogan = settings.headerSlogan;
        let banners = settings.banners;
        let bannersEnabled = settings.bannersEnabled;
        let extraField1Title = settings.extraField1Title;
        let extraField2Title = settings.extraField2Title;

        if (e.target != null) {
            if (e.target.name == 'extraField1Title') {
                extraField1Title = e.target.value;
            }
            if (e.target.name == 'extraField2Title') {
                extraField2Title = e.target.value;
            }            
            if (e.target.name == 'mainTelephone') {
                mainTelephone = e.target.value;
            }
            if (e.target.name == 'mainEmail') {
                mainEmail = e.target.value;
            }
            if (e.target.name == 'streetAddress') {
                streetAddress = e.target.value;
            }
            if (e.target.name == 'headerSlogan') {
                headerSlogan = e.target.value;
            }
            if (e.target.name == 'bannersEnabled') {
                bannersEnabled = e.target.checked;
            }
            const data = {
                mainTelephone,
                mainEmail,
                streetAddress,
                headerSlogan,
                bannersEnabled,
                extraField1Title,
                extraField2Title,
                banners
            }
            dispatch(editWebSettings(data));
        }
    }

    const [bannerAddingValues, setBannerAddingValues] = React.useState({
        title: "",
        sortorder: 0,
        image: "",
    });

    const handleAddBannerChange = name => event => {
        setBannerAddingValues({ ...bannerAddingValues, [name]: event.target.value });
    };

    function handleAddBannerSort(value) {
        setBannerAddingValues({ ...bannerAddingValues, "sortorder": value });
    }

    const handleAddBanner = () => {
        let newbanners = settings.banners;
        newbanners.push(bannerAddingValues);
        dispatch(addWebBanner(newbanners));
        setBannerAddingValues({
            title: "",
            sortorder: 0,
            image: "",
        });
    };

    const handleDeleteBanner = (banner, index) => {
        let newbanners = settings.banners;
        newbanners.splice(index, 1);
        dispatch(delWebBanner(newbanners));
    }

    const showAddBannerImg = (banner, index) => {
        dispatch(showAddBannerImgModal(banner, index));
    };
    const closeAddBannerImg = () => {
        dispatch(closeAddBannerImgModal());
    };

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    const uploadButton = (
        <div >
            <div className="ant-upload-text">Загрузить</div>
        </div>
    );

    const handleChangeBannerImage = info => {
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, image => {
                let imageFormObj = new FormData();
                var imageToShow = URL.createObjectURL(info.file.originFileObj);
                imageFormObj.append("index", bannerIndexAddImg);
                imageFormObj.append("imageData", info.file.originFileObj);
                dispatch(addBannerImg(imageFormObj));
            });
        }
    };

    const handleBannerImgDelete = () => {
        dispatch(deleteBannerImg(bannerIndexAddImg));
    };

    const [editbannervalues, setEditBannerValues] = React.useState({
        index: null,
        title: bannerToEdit.title,
        sortorder: bannerToEdit.sortorder,
    });

    const showEditBanner = (banner, index) => {
        setEditBannerValues({
            ...editbannervalues,
            index: index,
            title: banner.title,
            sortorder: banner.sortorder
        });
        dispatch(showEditBannerModal(banner));
    };

    const closeEditBanner = () => {
        setEditBannerValues({
            ...editbannervalues,
            index: null,
            title: bannerToEdit.title,
            sortorder: bannerToEdit.sortorder,
        });
        dispatch(closeEditBannerModal());
    };

    const handleEditBanner = () => {
        let newbanners = settings.banners;
        newbanners.forEach((element, i) => {
            if (i == editbannervalues.index) {
                element.title = editbannervalues.title;
                element.sortorder = editbannervalues.sortorder;
            }
        });
        setEditBannerValues({
            ...editbannervalues,
            index: null,
            title: "",
            sortorder: null,
        });

        dispatch(editBanner(newbanners));
    };

    const handleEditBannerChange = name => event => {
        setEditBannerValues({ ...editbannervalues, [name]: event.target.value });
    };

    function handleEditBannerSort(value) {
        setEditBannerValues({ ...editbannervalues, "sortorder": value });
    }

    function ControlButtons(banner, index) {
        return (
            <div>
                <Button style={{ marginRight: 5 }} size="small" type="primary" shape="circle" icon="picture" onClick={() => showAddBannerImg(banner, index)} />
                <Popconfirm title="Удалить баннер?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDeleteBanner(banner, index)}>
                    <Button style={{ marginRight: 5 }} size="small" type="danger" shape="circle" icon="delete" />
                </Popconfirm>
                <Button style={{ marginRight: 5 }} size="small" type="primary" shape="circle" icon="edit" onClick={() => showEditBanner(banner, index)} />
            </div>
        );
    }

    function renderBanners() {
        if (settings.banners.length > -1) {
            return (
                <>
                    {settings.banners.map((banner, i) => (
                        <Card title={banner.title} style={{ width: '100%', marginBottom: 20 }} key={i} size="small" extra={ControlButtons(banner, i)}>
                            <Row>
                                <Col>
                                    {banner.image != '' ?
                                        <img src={serverIp + banner.image} alt="avatar" style={{ maxWidth: 400, width: '100%' }} />
                                        : (
                                            <div></div>
                                        )}
                                    <br /><br />
                                </Col>
                                <Col>
                                    <p>Порядок сортировки: {banner.sortorder}</p>
                                </Col>
                            </Row>
                        </Card>
                    ))}

                    <Modal
                        centered
                        title="Редактирование баннера"
                        visible={isShowBannerEdit}
                        style={{}}
                        onCancel={closeEditBanner}
                        footer={[
                            <Button
                                onClick={closeEditBanner}
                                key="back"
                                type="danger"
                                disabled={bannerEditing}
                            >
                                Отмена
									</Button>,
                            <Button
                                color="secondary"
                                onClick={handleEditBanner}
                                key="yes"
                                type="primary"
                                disabled={bannerEditing}
                            >
                                Сохранить
							</Button>,
                        ]}
                    >
                        <Typography>Название баннера:</Typography>
                        <Input
                            value={editbannervalues.title}
                            name="title"
                            onChange={handleEditBannerChange("title")}
                        />
                        <Typography style={{ marginTop: 15 }}>Порядок сортировки:</Typography>
                        <InputNumber min={0} max={999} value={editbannervalues.sortorder} onChange={handleEditBannerSort} />

                    </Modal>

                    <Modal
                        centered
                        title="Добавление изображения баннера"
                        visible={isShowAddImgBanner}
                        style={{}}
                        onCancel={closeAddBannerImg}
                        footer={[
                            <Button
                                onClick={closeAddBannerImg}
                                key="back"
                                type="danger"
                                disabled={bannerImgAdding}
                            >
                                Отмена
									</Button>,
                        ]}
                    >
                        <Typography style={{ marginBottom: 15 }}>Название баннера: {bannerToAddImg.title}</Typography>

                        <Upload
                            style={{ margin: '0 auto' }}
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            customRequest={dummyRequest}
                            onChange={handleChangeBannerImage}
                        >
                            {bannerToAddImg.image ? <img src={serverIp + bannerToAddImg.image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                        {(bannerToAddImg.image != '') ? (<Button type="danger" style={{ width: '100%' }} disabled={bannerImgAdding} onClick={handleBannerImgDelete}>Очистить</Button>) : (<div></div>)}
                    </Modal>

                    <Modal
                        centered
                        title="Добавление баннера"
                        visible={isShowAddBanner}
                        style={{}}
                        onCancel={handleCloseAddBanner}
                        footer={[
                            <Button
                                onClick={handleCloseAddBanner}
                                key="back"
                                type="danger"
                                disabled={bannerAdding}
                            >
                                Отмена
									</Button>,
                            <Button
                                color="secondary"
                                onClick={handleAddBanner}
                                key="yes"
                                type="primary"
                                disabled={bannerAdding}
                            >
                                Добавить
							</Button>,
                        ]}
                    >
                        <Typography>Название баннера:</Typography>
                        <Input
                            value={bannerAddingValues.title}
                            name="addBannerTitle"
                            onChange={handleAddBannerChange("title")}
                        />
                        <Typography style={{ marginTop: 15 }}>Порядок сортировки:</Typography>
                        <InputNumber min={0} max={999} defaultValue={0} onChange={handleAddBannerSort} />
                        <Typography style={{ marginTop: 15 }}>Изображение можно добавить только после создания баннера.</Typography>
                    </Modal>

                </>
            );
        } else {
            return (
                <div>
                    <Card title="Баннеров не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
                        <div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
                    </Card>
                </div>
            );
        }

    }

    function renderSettings() {
        return (
            <div>
                <Typography style={{ marginTop: 15 }}>Основной телефон:</Typography>
                <Input name="mainTelephone" value={settings.mainTelephone} onChange={(e) => handleEditSettings(e, "mainTelephone")} />
                <Typography style={{ marginTop: 15 }}>Основной E-mail:</Typography>
                <Input name="mainEmail" value={settings.mainEmail} onChange={(e) => handleEditSettings(e, "mainEmail")} />
                <Typography style={{ marginTop: 15 }}>Основной адрес:</Typography>
                <Input name="streetAddress" value={settings.streetAddress} onChange={(e) => handleEditSettings(e, "streetAddress")} />
                <Typography style={{ marginTop: 15 }}>Заголовок в шапке сайта:</Typography>
                <Input name="headerSlogan" value={settings.headerSlogan} onChange={(e) => handleEditSettings(e, "headerSlogan")} />

                <Typography style={{ marginTop: 15 }}>Заголовок дополнительного поля №1:</Typography>
                <Input name="extraField1Title" value={settings.extraField1Title} onChange={(e) => handleEditSettings(e, "extraField1Title")} />

                <Typography style={{ marginTop: 15 }}>Заголовок дополнительного поля №2:</Typography>
                <Input name="extraField2Title" value={settings.extraField2Title} onChange={(e) => handleEditSettings(e, "extraField2Title")} />

                <Checkbox style={{ marginTop: 15 }} name="bannersEnabled" checked={settings.bannersEnabled} onChange={(e) => handleEditSettings(e, "bannersEnabled")}>Включить баннеры на главной странице?</Checkbox>
            </div>
        );
    }

    if (loading) {
        return (
                <div className="content-div">
                    <Divider className="header-div" orientation="left"><Spin /></Divider>
                </div>
        );
    } else {
        return (
                <QueueAnim className="anim-content"
                    type={['right', 'left']}
                    ease={['easeOutQuart', 'easeInOutQuart']}>
                    {!loading ? [
                        <div key="a" className="content-div">
                            <div>
                                <Divider className="header-div" orientation="left">Настройки сайта</Divider>
                            </div>
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Основные настройки" key="1">
                                    {renderSettings()}
                                </TabPane>
                                {settings.bannersEnabled ? (
                                        <TabPane tab="Баннеры на главной" key="2">
                                            {renderBanners()}
                                            <Button type="primary" icon="plus" onClick={handleShowAddBanner}>
                                                Добавить баннер
      								        </Button>
                                        </TabPane>
                                ) : (<></>)}
                            </Tabs>

                            <Divider />
                            <Button disabled={!needSave} type="primary" icon="save" onClick={handleSaveButton}>
                                Сохранить
      						</Button>

                        </div>
                    ] : null}
                </QueueAnim>
        );
    }

};


const useFetching = (getWebSettings, dispatch) => {
    useEffect(() => {
        dispatch(getWebSettings());
    }, []);
};

export default CPWebSettings;
