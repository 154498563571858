import {
    TIMESHEET_LOADED,
    TIMESHEET_LOADING,
    EMPLOYLIST_LOADED,
    EMPLOYLIST_LOADING,
    EMPLOYLIST_HIDE,
    EMPLOY_SAVED,
    MONTH_LOADING,
    MONTH_LOADED,
    ADD_WORKDAY,
    ADD_PREPAID,
    ADD_PREM
} from "../actions/types";

const initialState = {
    isLoading: true,
    employLoading: false,
    employShow: false,
    employList: [],
    monthLoading: false,
    monthData: [],
    formedNew: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case ADD_PREPAID:
            console.log('ADD_PREPAID');

            const updateCopyPrepaid = state.monthData;
            if (updateCopyPrepaid[action.payload.indexuser].id == action.payload.userid) {
                updateCopyPrepaid[action.payload.indexuser].prepaid = action.payload.user.prepaid;
                  return {
                      ...state,
                      monthData: [...updateCopyPrepaid]
                  }

            }

            case ADD_PREM:
                console.log('ADD_PREM');
    
                const updateCopyPrem = state.monthData;
                if (updateCopyPrem[action.payload.indexuser].id == action.payload.userid) {
                    updateCopyPrem[action.payload.indexuser].prem = action.payload.user.prem;
                      return {
                          ...state,
                          monthData: [...updateCopyPrem]
                      }
    
                }


        case ADD_WORKDAY:
            console.log('ADD_WORKDAY');
            //userindex, dayindex, userid, total, arrive, departure
            // console.log(action.payload);
            const updateCopy = state.monthData;
            if (updateCopy[action.payload.userindex].id == action.payload.userid) {
                updateCopy[action.payload.userindex].days[action.payload.dayindex].arrival = action.payload.arrive;
                updateCopy[action.payload.userindex].days[action.payload.dayindex].departure = action.payload.departure;
                updateCopy[action.payload.userindex].days[action.payload.dayindex].total = action.payload.total;

                  return {
                      ...state,
                      monthData: [...updateCopy]
                  }

            }

        case MONTH_LOADING:
            return {
                ...state,
                monthLoading: true,
            }
        case MONTH_LOADED:
            console.log('MONTH_LOADED')
            console.log(action.payload)
            if (action.payload.success) {
                return {
                    ...state,
                    monthLoading: false,
                    monthData: action.payload.monthdata,
                    formedNew: false,
                }
            } else {
                return {
                    ...state,
                    monthLoading: false,
                    formedNew: true,
                }
            }

        case EMPLOY_SAVED:
            const updatedState = state.employList.map((employ) => {
                if (employ._id === action.payload.editedemploy._id) {
                    return { ...employ, ...action.payload.editedemploy };
                } else {
                }
                return employ;
            });
            return {
                ...state,
                employList: updatedState,
                monthData: [],
            };

        case EMPLOYLIST_LOADED:
            console.log('EMPLOYLIST_LOADED');
            console.log(action.payload)
            return {
                ...state,
                employLoading: false,
                employShow: true,
                employList: action.payload.employs
            }
        case EMPLOYLIST_LOADING:
            console.log('EMPLOYLIST_LOADING')
            return {
                ...state,
                employLoading: true,
            }
        case EMPLOYLIST_HIDE:
            console.log('EMPLOYLIST_LOADING')
            return {
                ...state,
                employShow: false,
            }
        case TIMESHEET_LOADED:
            console.log('TIMESHEET_LOADED')
            return {
                ...state,
                isLoading: false,
            }
        case TIMESHEET_LOADING:
            console.log('TIMESHEET_LOADING')
            return {
                ...state,
                isLoading: true,
            }

        default:
            return state;
    }
}