import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  NEW_ORDER_CREATING,
  NEW_ORDER_CREATED,
  GET_ORDER_LIST,
  LOADING_ORDERS,
  GET_ORDERS_ERRORS,
  SHOW_CONTENT_MODAL,
  CLOSE_CONTENT_MODAL,
  GET_ORDER_LIST_UPDATE,
  SHOW_ORDER_STATUS_MODAL,
  CLOSE_ORDER_STATUS_MODAL,
  ORDER_STATUS_UPDATE,
  ORDER_ITEM_DELETED,
  SHOW_ADD_TO_ORDER_MODAL,
  CLOSE_ADD_TO_ORDER_MODAL,
  ORDER_ITEMS_TO_ADD_LOADING,
  ORDER_ITEMS_TO_ADD_LOADED,
  ORDER_ITEMS_WITH_CATEGORIES_TO_ADD_LOADED,
  ORDER_ADD_ITEM,
  ORDER_ADD_ITEM_ADDING,
  ORDER_OPEN_CHEQUE_MODAL,
  ORDER_CLOSE_CHEQUE_MODAL,
  SHOW_ADD_COURIER_ORDER,
  CLOSE_ADD_COURIER_ORDER,
  ORDER_COURIER_LIST_LOADED,
  ORDER_COURIER_ADDED,
  ORDER_COURIER_ADDING,
  LOADING_ORDER_PAGE,
  NEW_ORDER_NEED_TO_BE_LOAD,
  GET_NEW_ORDER,
  GET_FILTERED_ORDERS,
  SHOW_ORDER_CREATE_MODAL,
  HIDE_ORDER_CREATE_MODAL,
  GET_SEARCHED_CLIENTS,
  ORDER_RESET_CLIENT_SEARCH,
  GET_CREATED_ORDER,
  UPDATE_ORDER_DELIVERED,
  OPEN_ORDER_CALC_CHANGE,
  CLOSE_ORDER_CALC_CHANGE,
  ORDER_CALC_CHANGED,
  OPEN_ORDER_EDIT_COMMENT,
  CLOSE_ORDER_EDIT_COMMENT,
  ORDER_EDITED_COMMENT,
  ORDER_SMALL_REPORT_RECIEVED,
  ORDER_SMALL_REPORT_LOADING,
  ORDER_UPDATED,
  ORDER_OPEN_ADDRESS_MODAL,
  ORDER_CLOSE_ADDRESS_MODAL,
  ORDER_SET_NEW_ADDRESS,
  ORDER_NEW_ADDRESS_EDITING,
  OPEN_ORDER_PRINT_FILTERED,
  CLOSE_ORDER_PRINT_FILTERED,
  ORDER_GIFT_ITEMS_TO_ADD_LOADED,
  ORDER_CHECK_PAYMENT_ANSWER,
  LOADING_CUSTOMER_BONUS,
  LOADED_CUSTOMER_BONUS,
  KITERING_TOGGLED,
  SHOW_HIDE_KITERING
} from "./types";

export const toggleKitering = (orderId) => dispatch => {
  axios
    .post(serverIp + "api/order/setkitering", { order: orderId })
    .then(res => {
      dispatch({
        type: KITERING_TOGGLED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}

export const showHideKitering = () => dispatch => {
  
  console.log('1111')
  axios
    .post(serverIp + "api/order/getkiteringlist", {})
    .then(res => {
      dispatch({
        type: SHOW_HIDE_KITERING,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}


export const applyBonus = (orderId) => dispatch => {
  axios
    .post(serverIp + "api/order/applybonus", { order: orderId })
    .then(res => {
      dispatch({
        type: ORDER_STATUS_UPDATE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}


// export const changeOrderCourier = (orderId, courierId) => dispatch => {
//   dispatch({
//     type: LOADING_ORDER_PAGE
//   })
//   axios
//     .post(serverIp + "api/order/updateordercourier", { order: orderId, courier: courierId })
//     .then(res => {
//       dispatch({
//         type: ORDER_STATUS_UPDATE,
//         payload: res.data
//       });
//     })
//     .catch(err =>
//       dispatch({
//         type: GET_ORDERS_ERRORS,
//         payload: err.response
//       })
//     );
// }



export const loadClientBonus = (customerId) => dispatch => {
  console.log('loadClientBonus');

  dispatch({
    type: LOADING_CUSTOMER_BONUS,
  })
  axios
    .post(serverIp + "api/order/getcustomerbonus", { customerId: customerId })
    .then(res => {
      dispatch({
        type: LOADED_CUSTOMER_BONUS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}

export const checkPayment = (orderNum) => dispatch => {
  axios
    .post(serverIp + "api/order/checkpayment", { orderNum: orderNum })
    .then(res => {
      dispatch({
        type: ORDER_CHECK_PAYMENT_ANSWER,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}

export const showPrintCurrentFilterDay = () => dispatch => {
  dispatch({
    type: OPEN_ORDER_PRINT_FILTERED
  })
}
export const hidePrintCurrentFilterDay = () => dispatch => {
  dispatch({
    type: CLOSE_ORDER_PRINT_FILTERED
  })
}

export const getSmallReport = (ddate, courier, time) => dispatch => {
  dispatch({
    type: ORDER_SMALL_REPORT_LOADING,
  })
  axios
    .post(serverIp + "api/order/getsmallreport", { ddate: ddate, courier: courier, time: time })
    .then(res => {
      dispatch({
        type: ORDER_SMALL_REPORT_RECIEVED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}

export const openEditComment = () => dispatch => {
  dispatch({
    type: OPEN_ORDER_EDIT_COMMENT
  })
}
export const closeEditComment = () => dispatch => {
  dispatch({
    type: CLOSE_ORDER_EDIT_COMMENT
  })
}
export const sendEditComment = (order, result) => dispatch => {
  dispatch({
    type: CLOSE_ORDER_EDIT_COMMENT
  })
  axios
    .post(serverIp + "api/order/editcomment", { orderId: order, comment: result })
    .then(res => {
      dispatch({
        type: ORDER_EDITED_COMMENT,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}

export const openCalcChange = () => dispatch => {
  dispatch({
    type: OPEN_ORDER_CALC_CHANGE
  })
}
export const closeCalcChange = () => dispatch => {
  dispatch({
    type: CLOSE_ORDER_CALC_CHANGE
  })
}
export const sendCalcChange = (order, result) => dispatch => {
  dispatch({
    type: CLOSE_ORDER_CALC_CHANGE
  })
  axios
    .post(serverIp + "api/order/addcalcchange", { orderId: order, calc: result })
    .then(res => {
      dispatch({
        type: ORDER_CALC_CHANGED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}

export const changeOrderDeliveryPrice = (orderId, deliveryprice) => dispatch => {
  dispatch({
    type: LOADING_ORDER_PAGE
  })
  axios
    .post(serverIp + "api/order/updateorderdeliveryprice", { order: orderId, deliveryprice: deliveryprice })
    .then(res => {
      dispatch({
        type: ORDER_STATUS_UPDATE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}

export const updateOrderPayments = (orderNum) => dispatch => {
  dispatch({
    type: LOADING_ORDER_PAGE
  })
  axios
    .post(serverIp + "api/order/neworderdetails", { orderNum: orderNum })
    .then(res => {
      dispatch({
        type: ORDER_UPDATED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );

}

export const updateOrderDelivered = (data) => dispatch => {
  dispatch({
    type: UPDATE_ORDER_DELIVERED,
    payload: data
  })
}

export const createOrder = (order) => dispatch => {
  axios
    .post(serverIp + "api/order/create", { order: order })
    .then(res => {
      dispatch({
        type: GET_CREATED_ORDER,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}

export const createNewOrder = (order) => dispatch => {
  dispatch({
    type: NEW_ORDER_CREATING
  })
  axios
    .post(serverIp + "app/order/sendorder", order)
    .then(res => {
      dispatch({
        type: NEW_ORDER_CREATED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}

export const resetNewOrderSearch = () => dispatch => {
  dispatch({
    type: ORDER_RESET_CLIENT_SEARCH
  })
}

export const searchClientEmail = (email) => dispatch => {
  axios
    .post(serverIp + "api/order/searchemail", { email: email })
    .then(res => {
      dispatch({
        type: GET_SEARCHED_CLIENTS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}
export const searchClientPhone = (phone) => dispatch => {
  axios
    .post(serverIp + "api/order/searchphone", { phone: phone })
    .then(res => {
      dispatch({
        type: GET_SEARCHED_CLIENTS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}
export const showCreateOrderModal = () => dispatch => {
  dispatch({
    type: SHOW_ORDER_CREATE_MODAL
  })
}
export const hideCreateOrderModal = () => dispatch => {
  dispatch({
    type: HIDE_ORDER_CREATE_MODAL
  })
}

export const getPhoneFiltered = (phone) => dispatch => {
  dispatch(setPageLoading());
  axios
    .post(serverIp + "api/order/filterphone", { phone: phone })
    .then(res => {
      dispatch({
        type: GET_FILTERED_ORDERS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};
export const getEmailFiltered = (email) => dispatch => {
  dispatch(setPageLoading());
  axios
    .post(serverIp + "api/order/filteremail", { email: email })
    .then(res => {
      dispatch({
        type: GET_FILTERED_ORDERS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};
export const getNumberFiltered = (number) => dispatch => {
  dispatch(setPageLoading());
  axios
    .post(serverIp + "api/order/filternumber", { num: number })
    .then(res => {
      dispatch({
        type: GET_FILTERED_ORDERS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};

export const getSmartFiltered = (ddate, courier, paid, time) => dispatch => {

  // console.log('ddate '+ddate);
  // console.log('courier '+courier);
  // console.log('paid '+paid);
  dispatch(setPageLoading());
  axios
    .post(serverIp + "api/order/filtersmart", { ddate: ddate, courier: courier, paid: paid, time: time })
    .then(res => {
      dispatch({
        type: GET_FILTERED_ORDERS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};
export const getCourierFiltered = (number) => dispatch => {
  dispatch(setPageLoading());
  // axios
  //   .post(serverIp + "api/order/filterdeliverydate", { num: number })
  //   .then(res => {
  //     dispatch({
  //       type: GET_FILTERED_ORDERS,
  //       payload: res.data
  //     });
  //   })
  //   .catch(err =>
  //     dispatch({
  //       type: GET_ORDERS_ERRORS,
  //       payload: err.response
  //     })
  //   );
};



export const addCourierToOrder = (order, courier) => dispatch => {

  dispatch(setCourierAdding());
  console.log('addCourierToOrder');
  console.log(order);
  console.log(courier);
  axios
    .post(serverIp + "api/order/addcourier", { order: order, courier: courier })
    .then(res => {
      dispatch({
        type: ORDER_COURIER_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};

export const showCourierAdd = () => {
  console.log('showCourierAdd');
  return {
    type: SHOW_ADD_COURIER_ORDER
  };
};
export const closeCourierAdd = () => {
  return {
    type: CLOSE_ADD_COURIER_ORDER
  };
};
export const getCourierList = () => dispatch => {
  axios
    .get(serverIp + "api/order/getcourierlist")
    .then(res => {
      dispatch({
        type: ORDER_COURIER_LIST_LOADED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};

export const setCourierAdding = () => {
  return {
    type: ORDER_COURIER_ADDING
  };
};

export const showAddressModal = () => {
  return {
    type: ORDER_OPEN_ADDRESS_MODAL,
  };
};

export const closeAddressModal = () => {
  return {
    type: ORDER_CLOSE_ADDRESS_MODAL
  };
};

export const saveAddress = (data) => dispatch => {
  dispatch(setOrderEditingAddress());
  axios
    .post(serverIp + "api/order/editaddress", { data: data })
    .then(res => {
      dispatch({
        type: ORDER_SET_NEW_ADDRESS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}
export const setOrderEditingAddress = () => {
  return {
    type: ORDER_NEW_ADDRESS_EDITING
  };
};



export const showChequeModal = (order) => {
  return {
    type: ORDER_OPEN_CHEQUE_MODAL,
    payload: order
  };
};

export const closeChequeModal = () => {
  return {
    type: ORDER_CLOSE_CHEQUE_MODAL
  };
};

export const addAddItem = (orderid, item, quantity) => dispatch => {
  console.log(orderid);
  console.log(item);
  console.log(quantity);

  item.quantity = quantity;

  dispatch(setItemToOrderAdding());
  axios
    .post(serverIp + "api/order/additemtoorder", { orderid: orderid, item: item })
    .then(res => {
      dispatch({
        type: ORDER_ADD_ITEM,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};

export const setItemToOrderAdding = () => {
  return {
    type: ORDER_ADD_ITEM_ADDING
  };
};

export const loadGiftItems = () => dispatch => {
  axios
    .get(serverIp + "api/order/getitemgiftlist")
    .then(res => {
      dispatch({
        type: ORDER_GIFT_ITEMS_TO_ADD_LOADED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};

export const loadItems = () => dispatch => {
  dispatch(setItemsLoading());
  axios
    .get(serverIp + "api/order/getitemlist")
    .then(res => {
      dispatch({
        type: ORDER_ITEMS_TO_ADD_LOADED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};

export const loadItemsWithCategories = () => dispatch => {
  dispatch(setItemsLoading());
  axios
    .get(serverIp + "api/order/getitemcategorylist")
    .then(res => {
      dispatch({
        type: ORDER_ITEMS_WITH_CATEGORIES_TO_ADD_LOADED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};


export const setItemsLoading = () => {
  return {
    type: ORDER_ITEMS_TO_ADD_LOADING
  };
};

export const showAddToOrder = (order) => {
  return {
    type: SHOW_ADD_TO_ORDER_MODAL
  };
};

export const closeAddToOrder = () => {
  return {
    type: CLOSE_ADD_TO_ORDER_MODAL
  };
};

export const delItemFromOrder = (orderid, item, itemIndex) => dispatch => {
  axios
    .post(serverIp + "api/order/deleteitemfromorder", { orderid: orderid, item: item, itemIndex: itemIndex })
    .then(res => {
      dispatch({
        type: ORDER_ITEM_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};

export const showStatusModal = () => {
  return {
    type: SHOW_ORDER_STATUS_MODAL,
  };
};
export const closeStatusModal = () => {
  return {
    type: CLOSE_ORDER_STATUS_MODAL
  };
};

export const changeOrderPaidStatus = (orderId, paid) => dispatch => {
  dispatch({
    type: LOADING_ORDER_PAGE
  })
  axios
    .post(serverIp + "api/order/updateorderpaid", { order: orderId, status: paid })
    .then(res => {
      dispatch({
        type: ORDER_STATUS_UPDATE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}

export const changeOrderCourier = (orderId, courierId) => dispatch => {
  dispatch({
    type: LOADING_ORDER_PAGE
  })
  axios
    .post(serverIp + "api/order/updateordercourier", { order: orderId, courier: courierId })
    .then(res => {
      dispatch({
        type: ORDER_STATUS_UPDATE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}

export const changeOrderStatus = (orderId, statusId) => dispatch => {
  console.log('changeOrderStatus')

  dispatch({
    type: LOADING_ORDER_PAGE
  })
  axios
    .post(serverIp + "api/order/updateorderstatus", { order: orderId, status: statusId })
    .then(res => {
      dispatch({
        type: ORDER_STATUS_UPDATE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}

export const changeOrderPayment = (orderId, paymentId) => dispatch => {
  dispatch({
    type: LOADING_ORDER_PAGE
  })
  axios
    .post(serverIp + "api/order/updateorderpayment", { order: orderId, payment: paymentId })
    .then(res => {
      dispatch({
        type: ORDER_STATUS_UPDATE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}

export const changeOrderDeliveryDate = (orderId, deliveryDate) => dispatch => {
  dispatch({
    type: LOADING_ORDER_PAGE
  })
  axios
    .post(serverIp + "api/order/updateorderdeliverydate", { order: orderId, datetime: deliveryDate })
    .then(res => {
      dispatch({
        type: ORDER_STATUS_UPDATE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
}



export const saveOrderStatus = (order) => dispatch => {
  console.log('saveOrderStatus')
  axios
    .post(serverIp + "api/order/updateorderstatus", { order: order._id, status: order.status, crmstatus: order.crmstatus })
    .then(res => {
      dispatch({
        type: ORDER_STATUS_UPDATE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};

export const updateOrderList = (orderNum) => dispatch => {
  dispatch({
    type: NEW_ORDER_NEED_TO_BE_LOAD,
    payload: orderNum
  })
  // axios
  //   .get(serverIp+"api/order/updateorderslist")
  //   .then(res => {
  //     dispatch({
  //       type: GET_ORDER_LIST_UPDATE,
  //       payload: res.data
  //     });
  //   })
  //   .catch(err =>
  //     dispatch({
  //       type: GET_ORDERS_ERRORS,
  //       payload: err.response
  //     })
  //   );
};


export const getNewOrder = (orderNum) => dispatch => {
  axios
    .post(serverIp + "api/order/getneworder", { orderNum: orderNum })
    .then(res => {
      dispatch({
        type: GET_NEW_ORDER,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};


export const getOrderList = (page, rowsCount, pageLoad) => dispatch => {
  if (!pageLoad) {
    dispatch(setOrdersLoading());
  } else {
    dispatch(setPageLoading());
  }
  axios
    .post(serverIp + "api/order/getorderslist", { page: page, rowsCount: rowsCount })
    .then(res => {
      dispatch({
        type: GET_ORDER_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ORDERS_ERRORS,
        payload: err.response
      })
    );
};

export const setPageLoading = () => {
  return {
    type: LOADING_ORDER_PAGE
  };
};

// Загружаются заказы
export const setOrdersLoading = () => {
  return {
    type: LOADING_ORDERS
  };
};

export const showContentModal = (item) => {
  return {
    type: SHOW_CONTENT_MODAL,
    payload: item
  };
};

export const closeContentModal = () => {
  return {
    type: CLOSE_CONTENT_MODAL
  };
};
