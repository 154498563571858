import {
    GET_COURIER_LIST,
    GET_COURIER_ORDER_LIST,
    GET_COURIERS_ORDERS,
    SET_ORDER_DELIVERED,
    UPDATE_COURIER_ORDER
} from "../actions/types";

const initialState = {
    isLoading: true,
    courierList: [],
    orderList: [],
    courierOrderList: [],
    courierOrderIsLoading: true,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_COURIER_ORDER:
            const updatedOrderCourier = state.courierOrderList.map((order) => {
                if (order.id === action.payload.order.id) {
                  return { ...order, ...action.payload.order };
                } else {
                }
                return order;
              });
              return {
                ...state,
                courierOrderList: updatedOrderCourier
              };
        case SET_ORDER_DELIVERED:

            const updatedOrderCourierState = state.courierOrderList.map((order) => {
                if (order.id === action.payload.updatedorder.id) {
                  return { ...order, ...action.payload.updatedorder };
                } else {
                }
                return order;
              });
              return {
                ...state,
                courierOrderList: updatedOrderCourierState
              };
        case GET_COURIERS_ORDERS:
            return {
                ...state,
                courierOrderIsLoading: false,
                courierOrderList: action.payload.orders,
            }
        case GET_COURIER_LIST:
            return {
                ...state,
                courierList: action.payload.couriers,
                isLoading: false,
            }
        case GET_COURIER_ORDER_LIST:
            return{
                ...state,
                orderList: action.payload.orders,
            }
        default:
            return state;
    }
}