import {
    GET_WEB_STATICPAGES,
    LOADING_WEB_STATICPAGES,
    EDIT_WEB_STATICPAGES,
    SAVED_WEB_STATICPAGES,
    SAVING_WEB_STATICPAGES,
    POPULATED_CONTENT_WEB_STATICPAGES
} from "../actions/types";

import RichTextEditor from 'react-rte';

const initialState = {
    isLoading: true,
    delivery: {},
    contact: {},
    about: {},
    payment: {},
    deliverySaveNeeded: false,
    contactSaveNeeded: false,
    aboutSaveNeeded: false,
    paymentSaveNeeded: false,
    popuplatedContent: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case POPULATED_CONTENT_WEB_STATICPAGES:
            return{
                ...state,
                popuplatedContent: true
            }
        case GET_WEB_STATICPAGES:
            //получили все страницы в пачке
            //разберем массив на объекты и присвоим к стейту
            let deliverypage = {};
            let contactpage = {};
            let aboutpage = {};
            let paymentpage = {};

            action.payload.staticpage.forEach(element => {
                switch (element.type) {
                    case 'delivery':
                        deliverypage = element;
                    case 'contact':
                        contactpage = element;
                    case 'about':
                        aboutpage = element;
                    case 'payment':
                        paymentpage = element;
                }
            });
            return {
                ...state,
                isLoading: false,
                delivery: deliverypage,
                contact: {
                    ...state.contact,
                    _id: contactpage._id,
                    link: contactpage.link,
                    enabled: contactpage.enabled,
                    title: contactpage.title,
                    h1: contactpage.h1,
                    description: contactpage.description,
                    type: contactpage.type,
                    url: contactpage.url,
                    content: contactpage.content,
                },
                about: aboutpage,
                payment: paymentpage,
            }

        case SAVED_WEB_STATICPAGES:
            switch (action.payload.editedpage) {
                case "contact":
                    console.log('saved contact');
                    return{
                        ...state,
                        contactSaveNeeded: false,
                    }
                case "delivery":
                    console.log('saved delivery');
                    return{
                        ...state,
                        deliverySaveNeeded: false,
                    }
                case "about":
                    console.log('saved about');
                    return{
                        ...state,
                        aboutSaveNeeded: false,
                    }
                case "payment":
                    console.log('saved payment');
                    return{
                        ...state,
                        paymentSaveNeeded: false,
                    }
                default:
                    return{...state}
            }

        case EDIT_WEB_STATICPAGES:
            console.log(action.page);
            console.log(action.payload);
            switch (action.page) {
                case 'contact':
                    return {
                        ...state,
                        contactSaveNeeded: true,
                        contact: {
                            ...state.contact,
                            enabled: action.payload.newenabled,
                            link: action.payload.newlink,
                            title: action.payload.newtitle,
                            h1: action.payload.newh1,
                            description: action.payload.newdescription,
                            content: action.payload.newcontent,
                        }
                    }
                case 'delivery':
                    return {
                        ...state,
                        deliverySaveNeeded: true,
                        delivery: {
                            ...state.delivery,
                            enabled: action.payload.newenabled,
                            link: action.payload.newlink,
                            title: action.payload.newtitle,
                            h1: action.payload.newh1,
                            description: action.payload.newdescription,
                            content: action.payload.newcontent,
                        }
                    }
                case 'about':
                    return {
                        ...state,
                        aboutSaveNeeded: true,
                        about: {
                            ...state.about,
                            enabled: action.payload.newenabled,
                            link: action.payload.newlink,
                            title: action.payload.newtitle,
                            h1: action.payload.newh1,
                            description: action.payload.newdescription,
                            content: action.payload.newcontent,
                        }
                    }
                case 'payment':
                    return {
                        ...state,
                        paymentSaveNeeded: true,
                        payment: {
                            ...state.payment,
                            enabled: action.payload.newenabled,
                            link: action.payload.newlink,
                            title: action.payload.newtitle,
                            h1: action.payload.newh1,
                            description: action.payload.newdescription,
                            content: action.payload.newcontent,
                        }
                    }
                default:
                    return {
                        ...state
                    }
            }
        case LOADING_WEB_STATICPAGES:
            return {
                ...state,
                isLoading: true,
            }
        default:
            return state;
    }
}