import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
    GET_PROMOTION_LIST,
    LOADING_PROMOTION,
    GET_PROMOTION_ERRORS,
    PROMOTION_EDITING,
    SHOW_ADD_PROMOTION_MODAL,
    CLOSE_ADD_PROMOTION_MODAL,
    SHOW_EDIT_PROMOTION_MODAL,
    CLOSE_EDIT_PROMOTION_MODAL,
    PROMOTION_EDITED,
    PROMOTION_ADDED,
    PROMOTION_ADDING,
    PROMOTION_DELETED,
    SHOW_ADD_IMG_PROMOTION_MODAL,
    CLOSE_ADD_IMG_PROMOTION_MODAL,
    PROMOTION_IMG_DELETED,
    PROMOTION_IMG_ADDED,
    IMG_PROMOTION_BTN_DISABLE,


} from "./types";

export const sendPromotionImageDelete = (data) => dispatch => {
  dispatch(setImgDisable());
  axios
    .post(serverIp+"api/promotions/deletepromotionimg", data)
    .then(res => {
      dispatch({
        type: PROMOTION_IMG_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_PROMOTION_ERRORS,
        payload: err.response
      })
    );
}

export const sendPromotionImage = (data) => dispatch => {
  dispatch(setImgDisable());
  console.log(data);
  axios
    .post(serverIp+"api/promotions/addpromotionimg", data)
    .then(res => {
      dispatch({
        type: PROMOTION_IMG_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_PROMOTION_ERRORS,
        payload: err.response
      })
    );
}

export const setImgDisable = () => {
  return {
    type: IMG_PROMOTION_BTN_DISABLE
  };
};

export const showAddImgPromotionModal = (promotions) => {
  return {
    type: SHOW_ADD_IMG_PROMOTION_MODAL,
    payload: promotions
  };
};
export const closeAddImgPromotionModal = () => {
  return {
    type: CLOSE_ADD_IMG_PROMOTION_MODAL
  };
};


export const showAddPromotionModal = () => {
  return {
    type: SHOW_ADD_PROMOTION_MODAL
  };
};

export const closeAddPromotionModal = () => {
  return {
    type: CLOSE_ADD_PROMOTION_MODAL
  };
};

export const showEditPromotionModal = (promotions) => {
  return {
    type: SHOW_EDIT_PROMOTION_MODAL,
    payload: promotions
  };
};

export const closeEditPromotionModal = () => {
  return {
    type: CLOSE_EDIT_PROMOTION_MODAL
  };
};

export const setPromotionEditing = () => {
  return {
    type: PROMOTION_EDITING
  };
};

export const setPromotionAdding = () => {
  return {
    type: PROMOTION_ADDING
  };
};

export const addPromotion = (promotions) => dispatch => {
  dispatch(setPromotionAdding());
  promotions.text = promotions.text.toString('html');
  axios
    .post(serverIp + "api/promotions/addpromotion", promotions)
    .then(res => {
      dispatch({
        type: PROMOTION_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_PROMOTION_ERRORS,
        payload: err.response
      })
    );
};

export const deletePromotion = (promotions) => dispatch => {
  axios
    .post(serverIp + "api/promotions/deletepromotion", promotions)
    .then(res => {
      dispatch({
        type: PROMOTION_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_PROMOTION_ERRORS,
        payload: err.response
      })
    );
};

export const editPromotion = (promotions) => dispatch => {
  dispatch(setPromotionEditing());
  promotions.text = promotions.text.toString('html');
  axios
    .post(serverIp + "api/promotions/editpromotion", promotions)
    .then(res => {
      dispatch({
        type: PROMOTION_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_PROMOTION_ERRORS,
        payload: err.response
      })
    );
};

// получаем способы оплаты
export const getPromotionList = () => dispatch => {
  dispatch(setPromotionLoading());
  axios
    .get(serverIp + "api/promotions/getpromotionlist")
    .then(res => {
      dispatch({
        type: GET_PROMOTION_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_PROMOTION_ERRORS,
        payload: err.response
      })
    );
};

// Загружаются способы оплаты
export const setPromotionLoading = () => {
  return {
    type: LOADING_PROMOTION
  };
};
