import {
  GET_CUSTOMERS_LIST,
  LOADING_CUSTOMERS,
  GET_CUSTOMERS_ERRORS,
  SHOW_ADD_CUSTOMER_MODAL,
  CLOSE_ADD_CUSTOMER_MODAL,
  ADDING_CUSTOMER,
  CUSTOMER_ADDED,
  SHOW_DEL_CUSTOMER_MODAL,
  CLOSE_DEL_CUSTOMER_MODAL,
  CUSTOMER_DELETED,
  LOADING_CUSTOMERS_PAGE,
  SHOW_ORDER_HISTORY_CUSTOMER_MODAL,
  CLOSE_ORDER_HISTORY_CUSTOMER_MODAL,
  SHOW_BONUS_HISTORY_CUSTOMER_MODAL,
  CLOSE_BONUS_HISTORY_CUSTOMER_MODAL,
  ORDER_HISTORY_CUSTOMER_LOADED,
  ORDER_HISTORY_CUSTOMER_LOADING,

  CUSTOMER_EDITING,
  CUSTOMER_EDITED,
  SHOW_EDIT_CUSTOMER_MODAL,
  CLOSE_EDIT_CUSTOMER_MODAL,
  SHOW_ADD_BONUS_CUSTOMER_MODAL,
  CLOSE_ADD_BONUS_CUSTOMER_MODAL,
  SHOW_DEL_BONUS_CUSTOMER_MODAL,
  CLOSE_DEL_BONUS_CUSTOMER_MODAL,
  CUSTOMER_BONUS_ADDED,
  CUSTOMER_BONUS_DELETED,

  ALL_CUSTOMERS_LOADED,
  ALL_CUSTOMERS_LOADING
  
} from "../actions/types";

const initialState = {
  isLoading: true,
  customers: [],
  showAdd: false,
  adding: false,
  customerDel: {},
  total: null,
  pageLoading: false,
  showOrderHistory: false,
  showBonusHistory: false,
  customerOrders: [],
  orderHistoryLoading: true,
  showAddBonus: false,
  showDelBonus: false,
  showEdit: false,
  editing: false,
  fullListLoading: false,
  allCustomersList: null,

};

export default function (state = initialState, action) {
  switch (action.type) {
    case ALL_CUSTOMERS_LOADED:
      return{
        ...state,
        fullListLoading: false,
        allCustomersList: action.payload.allcustomers
      }
    case ALL_CUSTOMERS_LOADING: 
      return{
        ...state,
        allCustomersList: null,
        fullListLoading: true,
      }
    case SHOW_EDIT_CUSTOMER_MODAL:
      return{
        ...state,
        showEdit:true,
      }
    case CLOSE_EDIT_CUSTOMER_MODAL:
      return{
        ...state,
        showEdit:false,
      }
    case CUSTOMER_EDITING:
      return {
        ...state,
        editing: true,
      }
    case CUSTOMER_EDITED:
      const updatedState_edited = state.customers.map((customer) => {
        if (customer._id === action.payload.editedcustomer._id) {
          return { ...customer, ...action.payload.editedcustomer };
        } else {
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedState_edited,
        showEdit: false,
        editing: false,
      };
    case CUSTOMER_BONUS_ADDED:
      const updatedState_bonusAdd = state.customers.map((customer) => {
        if (customer._id === action.payload.editedcustomer._id) {
          return { ...customer, ...action.payload.editedcustomer };
        } else {
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedState_bonusAdd,
        showAddBonus: false,
        editing: false,
      };
    case CUSTOMER_BONUS_DELETED:
      const updatedState_bonusDel = state.customers.map((customer) => {
        if (customer._id === action.payload.editedcustomer._id) {
          return { ...customer, ...action.payload.editedcustomer };
        } else {
        }
        return customer;
      });
      return {
        ...state,
        customers: updatedState_bonusDel,
        showDelBonus: false,
        editing: false,
      };


    case SHOW_DEL_BONUS_CUSTOMER_MODAL:
      return {
        ...state,
        showDelBonus: true,
      }
    case CLOSE_DEL_BONUS_CUSTOMER_MODAL:
      return {
        ...state,
        showDelBonus: false,
      }
    case SHOW_ADD_BONUS_CUSTOMER_MODAL:
      return {
        ...state,
        showAddBonus: true,
      }
    case CLOSE_ADD_BONUS_CUSTOMER_MODAL:
      return {
        ...state,
        showAddBonus: false,
      }
    case ORDER_HISTORY_CUSTOMER_LOADING:
      return {
        ...state,
        orderHistoryLoading: true,
      }
    case ORDER_HISTORY_CUSTOMER_LOADED:
      console.log(action.payload.orders);
      return {
        ...state,
        customerOrders: action.payload.orders,
        orderHistoryLoading: false,
      }
    case SHOW_ORDER_HISTORY_CUSTOMER_MODAL:
      return {
        ...state,
        showOrderHistory: true,
      }
    case CLOSE_ORDER_HISTORY_CUSTOMER_MODAL:
      return {
        ...state,
        showOrderHistory: false,
        customerOrders: [],
      }
    case SHOW_BONUS_HISTORY_CUSTOMER_MODAL:
      return {
        ...state,
        showBonusHistory: true,
      }
    case CLOSE_BONUS_HISTORY_CUSTOMER_MODAL:
      return {
        ...state,
        showBonusHistory: false,
      }

    case LOADING_CUSTOMERS_PAGE:
      return {
        ...state,
        pageLoading: true,
      }
    case GET_CUSTOMERS_LIST:
      return {
        ...state,
        isLoading: false,
        pageLoading: false,
        customers: action.payload.customers,
        total: action.payload.total,
      };
    case LOADING_CUSTOMERS:
      return {
        ...state,
        isLoading: true,
      };
    case SHOW_ADD_CUSTOMER_MODAL:
      return {
        ...state,
        isLoading: false,
        showAdd: true,
      };
    case CLOSE_ADD_CUSTOMER_MODAL:
      return {
        ...state,
        isLoading: false,
        showAdd: false,
      };
    case ADDING_CUSTOMER:
      return {
        ...state,
        adding: true,
      };
    case CUSTOMER_ADDED:
      return {
        ...state,
        customers: [...state.customers, action.payload.newcustomer],
        isLoading: false,
        showAdd: false,
        adding: false,
      };
    case CUSTOMER_DELETED:
      const id = action.payload.deletedcustomer._id;
      return {
        ...state,
        customers: state.customers.filter((customer) => customer._id !== id),
        showDel: false,
        customerDel: {},
      };
    case SHOW_DEL_CUSTOMER_MODAL:
      return {
        ...state,
        isLoading: false,
        showDel: true,
        customerDel: action.payload
      };
    case CLOSE_DEL_CUSTOMER_MODAL:
      return {
        ...state,
        isLoading: false,
        showDel: false,
        customerDel: {}
      };

    default:
      return state;
  }
}
