import axios from "axios";
import serverIp from "../utils/serverIp";

import {
  GET_ARTICLE_LIST,
  LOADING_ARTICLE,
  GET_ARTICLE_ERRORS,
  SHOW_ADD_ARTICLE_MODAL,
  CLOSE_ADD_ARTICLE_MODAL,
  ARTICLE_ADDED,
  ADDING_ARTICLE,
  ARTICLE_EDITING,
  ARTICLE_EDITED,
  CLOSE_EDIT_ARTICLE_MODAL,
  SHOW_EDIT_ARTICLE_MODAL,
  ARTICLE_DELETED
} from "./types";

export const getArticleList = () => dispatch => {
  console.log('getArticleList');
  dispatch(setArticlesLoading());
  axios
    .get(serverIp + "api/article/getarticlelist")
    .then(res => {
      dispatch({
        type: GET_ARTICLE_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ARTICLE_ERRORS,
        payload: err.response
      })
    );
};

export const addArticle = (article) => dispatch => {
  console.log('addArticle');
  article.data = article.data.toString('html');
  console.log(article);
  dispatch(setArticlesAdding());
  axios
    .post(serverIp + "api/article/addarticle", article)
    .then(res => {
      dispatch({
        type: ARTICLE_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ARTICLE_ERRORS,
        payload: err.response
      })
    );
};

export const showEditArticleModal = (article) => {
  return {
    type: SHOW_EDIT_ARTICLE_MODAL,
    payload: article
  };
};

export const closeEditArticleModal = () => {
  return {
    type: CLOSE_EDIT_ARTICLE_MODAL
  };
};

export const editArticle = (article) => dispatch => {
  dispatch({
    type: ARTICLE_EDITING
  })
  article.data = article.data.toString('html');
  axios
    .post(serverIp + "api/article/editarticle", article)
    .then(res => {
      dispatch({
        type: ARTICLE_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ARTICLE_ERRORS,
        payload: err.response
      })
    );
};

export const deleteArticle = (article) => dispatch => {
  axios
    .post(serverIp + "api/article/deletearticle", article)
    .then(res => {
      dispatch({
        type: ARTICLE_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ARTICLE_ERRORS,
        payload: err.response
      })
    );
};

export const showAddArticleModal = () => {
  return {
    type: SHOW_ADD_ARTICLE_MODAL
  };
};

export const closeAddArticleModal = () => {
  return {
    type: CLOSE_ADD_ARTICLE_MODAL
  };
};

export const setArticlesLoading = () => {
  return {
    type: LOADING_ARTICLE
  };
};
export const setArticlesAdding = () => {
  return {
    type: ADDING_ARTICLE
  };
};
