import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
    GET_CART_GIFTS_LIST,
    LOADING_CART_GIFTS,
    GET_CART_GIFTS_ERRORS,
    SET_CART_GIFT_ADDING,
    CART_GIFT_ADDED,
    CART_GIFT_DELETED,
    CART_GIFT_EDITED,
    SHOW_CART_GIFT_ADD_IMG_MODAL,
    CLOSE_CART_GIFT_ADD_IMG_MODAL,
    CART_GIFT_IMG_ADDED,
    CART_GIFT_IMG_DELETED
} from "./types";

export const getCartGiftsList = () => dispatch => {
    dispatch({
        type: LOADING_CART_GIFTS
    });
    axios
      .get(serverIp + "api/cartgifts/getcartgiftslist")
      .then(res => {
        dispatch({
          type: GET_CART_GIFTS_LIST,
          payload: res.data
        });
      })
      .catch(err =>
        dispatch({
          type: GET_CART_GIFTS_ERRORS,
          payload: err.response
        })
      );
  };

  export const sendItemImage = (data) => dispatch => {
    axios
      .post(serverIp+"api/cartgifts/addgiftimg", data)
      .then(res => {
        dispatch({
          type: CART_GIFT_IMG_ADDED,
          payload: res.data
        });
      })
      .catch(err =>
        dispatch({
          type: GET_CART_GIFTS_ERRORS,
          payload: err.response
        })
      );
  
  }

  export const sendItemImageDelete = (data) => dispatch => {
    axios
      .post(serverIp+"api/cartgifts/deletegiftimg", data)
      .then(res => {
        dispatch({
          type: CART_GIFT_IMG_DELETED,
          payload: res.data
        });
      })
      .catch(err =>
        dispatch({
          type: GET_CART_GIFTS_ERRORS,
          payload: err.response
        })
      );
  }

  export const addGift = (gift) => dispatch => {
    dispatch({
      type: SET_CART_GIFT_ADDING
    });
    axios
    .post(serverIp + "api/cartgifts/addgift", {gift:gift})
    .then(res => {
      dispatch({
        type: CART_GIFT_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CART_GIFTS_ERRORS,
        payload: err.response
      })
    );
  }

  export const deleteGift = (gift) => dispatch => {
    dispatch({
      type: SET_CART_GIFT_ADDING
    });
    axios
    .post(serverIp + "api/cartgifts/deletegift", {gift:gift})
    .then(res => {
      dispatch({
        type: CART_GIFT_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CART_GIFTS_ERRORS,
        payload: err.response
      })
    );
  }
  export const changeEnabled = (gift, enabled) => dispatch => {
    dispatch({
      type: SET_CART_GIFT_ADDING
    });
    axios
    .post(serverIp + "api/cartgifts/editgift", {gift:gift, enabled:enabled})
    .then(res => {
      dispatch({
        type: CART_GIFT_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CART_GIFTS_ERRORS,
        payload: err.response
      })
    );
  }

  export const showAddImgItemModal = (gift) => {
    return {
      type: SHOW_CART_GIFT_ADD_IMG_MODAL,
      payload: gift
    };
  };

  export const closeAddImgItemModal = () => {
    return {
      type: CLOSE_CART_GIFT_ADD_IMG_MODAL
    };
  };