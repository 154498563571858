import {
  GET_CART_GIFTS_LIST,
  LOADING_CART_GIFTS,
  GET_CART_GIFTS_ERRORS,
  SET_CART_GIFT_ADDING,
  CART_GIFT_ADDED,
  CART_GIFT_DELETED,
  CART_GIFT_EDITED,
  SHOW_CART_GIFT_ADD_IMG_MODAL,
  CLOSE_CART_GIFT_ADD_IMG_MODAL,
  CART_GIFT_IMG_DELETED,
  CART_GIFT_IMG_ADDED
} from "../actions/types";

const initialState = {
  isLoading: true,
  cartgifts: [],
  adding: false,
  showAddImg: false,
  imgUploading: false,
  itemAddPhoto: {}
};

export default function (state = initialState, action) {
  switch (action.type) {

    case CART_GIFT_IMG_DELETED:
      const updatedDelImgState = state.cartgifts.map((cartgift) => {
        if (cartgift._id === action.payload.cartgift._id) {
          return { ...cartgift, ...action.payload.cartgift };
        } else {
        }
        return cartgift;
      });
      return {
        ...state,
        cartgifts: updatedDelImgState,
      };

    case CART_GIFT_IMG_ADDED:
      const updatedAddImgState = state.cartgifts.map((cartgift) => {
        if (cartgift._id === action.payload.cartgift._id) {
          return { ...cartgift, ...action.payload.cartgift };
        } else {
        }
        return cartgift;
      });
      return {
        ...state,
        cartgifts: updatedAddImgState,
      };

    case CLOSE_CART_GIFT_ADD_IMG_MODAL:
      return{
        ...state,
        showAddImg: false,
        itemAddPhoto: {},
      }

    case SHOW_CART_GIFT_ADD_IMG_MODAL:
      return{
        ...state,
        showAddImg: true,
        itemAddPhoto: action.payload
      }

    case CART_GIFT_DELETED:
      const id = action.payload.deletedgift._id;
      return {
        ...state,
        cartgifts: state.cartgifts.filter((cartgift) => cartgift._id !== id),
        adding: false,
      };

    case CART_GIFT_EDITED:
      const updatedState = state.cartgifts.map((cartgift) => {
        if (cartgift._id === action.payload.editedgift._id) {
          return { ...cartgift, ...action.payload.editedgift };
        } else {
        }
        return cartgift;
      });
      return {
        ...state,
        cartgifts: updatedState,
        adding: false,
      };

    case CART_GIFT_ADDED:
      return {
        ...state,
        adding: false,
        cartgifts: [...state.cartgifts, action.payload.newcartgift],
      }

    case SET_CART_GIFT_ADDING:
      return {
        ...state,
        adding: true
      }

    case GET_CART_GIFTS_LIST:
      return {
        ...state,
        isLoading: false,
        cartgifts: action.payload.cartgifts,
      };

    case LOADING_CART_GIFTS:
      return {
        ...state,
        isLoading: true,
      };
      
    default:
      return state;
  }
}
