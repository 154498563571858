import {
    GET_SEO_PAGES_LIST,
    LOADING_SEO_PAGES,
    GET_SEO_PAGES_ERRORS,
    SHOW_ADD_SEO_PAGES_MODAL,
    CLOSE_ADD_SEO_PAGES_MODAL,
    SEO_PAGES_ADDED,
    ADDING_SEO_PAGES,
    SEO_PAGES_EDITING,
    SEO_PAGES_EDITED,
    CLOSE_EDIT_SEO_PAGES_MODAL,
    SHOW_EDIT_SEO_PAGES_MODAL,
    SEO_PAGES_DELETED
} from "../actions/types";

const initialState = {
    isLoading: true,
    seopages: [],
    editing: false,
    showAdd: false,
    adding: false,
    showEdit: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SEO_PAGES_DELETED:
            const id = action.payload.deletedseopage._id;
            return {
              ...state,
              seopages: state.seopages.filter((seopage) => seopage._id !== id),
            };
        case SEO_PAGES_EDITED:
            const updatedState = state.seopages.map((seopage) => {
              if (seopage._id === action.payload.editedseopage._id) {
                return { ...seopage, ...action.payload.editedseopage };
              } else {
              }
              return seopage;
            });
            return {
              ...state,
              seopages: updatedState,
              showEdit: false,
              editing: false,
            };
        case SEO_PAGES_EDITING:
            return {
                ...state,
                editing: true,
            };
        case CLOSE_EDIT_SEO_PAGES_MODAL:
            return {
                ...state,
                editing: false,
                showEdit: false,
            }
        case SHOW_EDIT_SEO_PAGES_MODAL:
            return {
                ...state,
                showEdit: true,
            }
        case GET_SEO_PAGES_LIST:
            return {
                ...state,
                isLoading: false,
                seopages: action.payload.seopages,
            };
        case LOADING_SEO_PAGES:
            return {
                ...state,
                isLoading: true,
            };
        case ADDING_SEO_PAGES:
            return {
                ...state,
                adding: true,
            };
        case SEO_PAGES_ADDED:
            return {
                ...state,
                seopages: [...state.seopages, action.payload.newseopage],
                isLoading: false,
                showAdd: false,
                adding: false,
            };
        case SHOW_ADD_SEO_PAGES_MODAL:
            return {
                ...state,
                isLoading: false,
                showAdd: true,
            };
        case CLOSE_ADD_SEO_PAGES_MODAL:
            return {
                ...state,
                isLoading: false,
                showAdd: false,
            };
        default:
            return state;
    }
}
