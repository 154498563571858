import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Button, Row, Col, Typography, Divider, Spin, Card, Icon, Layout, Modal, Popconfirm, Table } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
    getSeoPageList,
    showAddSeoPageModal,
    closeAddSeoPageModal,
    addSeoPage,
    editSeoPage,
    showEditSeoPageModal,
    closeEditSeoPageModal,
    deleteSeoPage
} from "../../actions/seoPagesActions";
import RichTextEditor from 'react-rte';

const { Search } = Input;
const { TextArea } = Input;
const CPSeoPages = props => {
    const dispatch = useDispatch();
    useFetching(getSeoPageList, useDispatch());
    const seopages = useSelector(state => state.seopages.seopages);
    const loading = useSelector(state => state.seopages.isLoading);
    const showAdd = useSelector(state => state.seopages.showAdd);
    const adding = useSelector(state => state.seopages.adding);

    const showEdit = useSelector(state => state.seopages.showEdit);
    const editing = useSelector(state => state.seopages.editing);

    const [values, setValues] = React.useState({
        name: "",
        name_extra: "",
        data: "",
        data_2: "",
        data_3: "",
        data_4: "",
        data_5: "",
        meta_title: "",
        meta_h1: "",
        meta_description: "",
        url: ""
    });

    const [editvalues, setEditValues] = React.useState({
        name: "",
        name_extra: "",
        data: "",
        data_2: "",
        data_3: "",
        data_4: "",
        data_5: "",
        meta_title: "",
        meta_h1: "",
        meta_description: "",
        url: ""
    });


    const [searchvalue, setSearchValue] = React.useState({
        search: ""
    });

    const handleSearchChange = name => event => {
        setSearchValue({ ...searchvalue, [name]: event.target.value });
    };
    const handleOpenAddModal = useCallback(
        () => dispatch(showAddSeoPageModal()),
        [dispatch]
    );
    const handleCloseAddModal = useCallback(
        () => {
            setValues({ data: RichTextEditor.createEmptyValue() });
            dispatch(closeAddSeoPageModal());
        },
        [dispatch]
    );

    const handleAddSeoPage = () => {
        console.log('set');
        console.log(values);
        dispatch(addSeoPage(values));
    };

    const handleEditSeoPage = () => {
        dispatch(editSeoPage(editvalues));
        // setEditValues({
        //     name: '',
        // });
    };

    const handleDelSeoPage = (seopage) => {
        dispatch(deleteSeoPage(seopage));
    };

    const handleOpenEditSeoPage = (seopage) => {
        setEditValues({
            ...editvalues,
            _id: seopage._id,
            name: seopage.name,
            name_extra: seopage.name_extra,
            data: seopage.data,
            data_2: seopage.data_2,
            data_3: seopage.data_3,
            data_4: seopage.data_4,
            data_5: seopage.data_5,
            meta_title: seopage.meta_title,
            meta_h1: seopage.meta_h1,
            meta_description: seopage.meta_description,
            url: seopage.url,
        });
        dispatch(showEditSeoPageModal(seopage));
    };

    const handleCloseEditSeoPage = useCallback(() => dispatch(closeEditSeoPageModal()), [dispatch]);

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
        console.log(values);
    };

    const handleEditChange = name => event => {
        setEditValues({ ...editvalues, [name]: event.target.value });
    };


    function renderSeoPageInfo(seopage) {
        return (
            <>
                <div key={seopage.id}>
                    <Row gutter={16}>
                        <Col className="gutter-row" xs={24} sm={12}>
                            <div>
                                <span><b>Название:</b></span><br />
                                <span>{seopage.name}</span><br />
                            </div>
                        </Col>
                    </Row>


                </div>
                <div className="customer-buttons">
                    <Popconfirm title="Удалить статью?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelSeoPage(seopage)}>
                        <Button style={{ marginRight: 5 }} type="dashed">УДАЛИТЬ</Button>
                    </Popconfirm>
                    <Button style={{ marginRight: 5 }} type="dashed" onClick={() => handleOpenEditSeoPage(seopage)}>РЕДАКТИРОВАТЬ</Button>
                </div>
            </>
        );
    }

    function renderSort() {

        const columns = [
            { name: 'Заголовок', dataIndex: 'name', key: 'name' }
        ];
        //console.log(RichTextEditor.createValueFromString('<b>1</b>', 'html'));
        //console.log(articles);
        var updatedList = seopages;
        updatedList = updatedList.filter(function (seopagefilter) {
            return (
                seopagefilter.name
                    .toLowerCase()
                    .search(searchvalue.search.toLowerCase()) !== -1
            );
        });

        if (updatedList.length > 0) {
            return (
                <Table
                    rowKey={record => record._id}
                    style={{ marginTop: 20 }}
                    columns={columns}
                    expandedRowRender={record => renderSeoPageInfo(record)}
                    dataSource={seopages}
                />
            );
        } else {
            return (
                <div>
                    <Card title="SEO страниц не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
                        <div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
                    </Card>
                </div>
            );
        }
    }

    if (loading) {
        return (
            <div className="content-div">
                <Divider className="header-div" orientation="left"><Spin /></Divider>
            </div>
        );
    } else {
        return (
            <QueueAnim className="anim-content"
                type={['right', 'left']}
                ease={['easeOutQuart', 'easeInOutQuart']}>
                {!loading ? [
                    <div key="a" className="content-div">
                        <Divider className="header-div" orientation="left">SEO страницы</Divider>
                        <Row type="flex" justify="end">
                            <Search
                                placeholder="Поиск по SEO страницам"
                                onChange={handleSearchChange("search")}
                                style={{ width: 200 }}
                            />
                        </Row>
                        <Divider />
                        {renderSort()}
                        <Card style={{ width: '100%', marginBottom: 20 }} size="small">
                            <Button type="primary" icon="plus" onClick={handleOpenAddModal}>
                                Добавить
						</Button>
                        </Card>


                        <Modal
                            centered
                            title={"Редактирование SEO страницы"}
                            visible={showEdit}
                            onCancel={handleCloseEditSeoPage}
                            footer={[
                                <Button
                                    key="back"
                                    onClick={handleCloseEditSeoPage}
                                >
                                    Отмена
          							</Button>,
                                <Button
                                    key="yes"
                                    type="primary"
                                    disabled={editing}
                                    onClick={handleEditSeoPage}
                                >
                                    Сохранить
          							</Button>,
                            ]}
                        >
                            <div>
                                <Row gutter={6} style={{ marginBottom: 5 }}>
                                    <Col className="gutter-row" span={24} sm={24} xs={24}>
                                        <Typography>Название:</Typography>
                                        <Input value={editvalues.name} onChange={handleEditChange("name")} />
                                    </Col>
                                </Row>

                                <Row gutter={6} style={{ marginBottom: 5 }}>
                                    <Col className="gutter-row" span={24} sm={24} xs={24}>
                                        <Typography>Доп. название:</Typography>
                                        <Input value={editvalues.name_extra} onChange={handleEditChange("name_extra")} />
                                    </Col>
                                </Row>

                                <Row gutter={6} style={{ marginBottom: 5 }}>
                                    <Col className="gutter-row" span={24} sm={24} xs={24}>
                                        <Typography>Data #1:</Typography>
                                        <TextArea style={{ marginBottom: 10 }} value={editvalues.data} onChange={handleEditChange("data")} rows={3} />
                                    </Col>
                                </Row>

                                <Row gutter={6} style={{ marginBottom: 5 }}>
                                    <Col className="gutter-row" span={24} sm={24} xs={24}>
                                        <Typography>Data #2:</Typography>
                                        <TextArea style={{ marginBottom: 10 }} value={editvalues.data_2} onChange={handleEditChange("data_2")} rows={3} />
                                    </Col>
                                </Row>

                                <Row gutter={6} style={{ marginBottom: 5 }}>
                                    <Col className="gutter-row" span={24} sm={24} xs={24}>
                                        <Typography>Data #3:</Typography>
                                        <TextArea style={{ marginBottom: 10 }} value={editvalues.data_3} onChange={handleEditChange("data_3")} rows={3} />
                                    </Col>
                                </Row>

                                <Row gutter={6} style={{ marginBottom: 5 }}>
                                    <Col className="gutter-row" span={24} sm={24} xs={24}>
                                        <Typography>Data #4:</Typography>
                                        <TextArea style={{ marginBottom: 10 }} value={editvalues.data_4} onChange={handleEditChange("data_4")} rows={3} />
                                    </Col>
                                </Row>

                                <Row gutter={6} style={{ marginBottom: 5 }}>
                                    <Col className="gutter-row" span={24} sm={24} xs={24}>
                                        <Typography>Data #5:</Typography>
                                        <TextArea style={{ marginBottom: 10 }} value={editvalues.data_5} onChange={handleEditChange("data_5")} rows={3} />
                                    </Col>
                                </Row>

                                <Row gutter={6} style={{ marginBottom: 5 }}>
                                    <Col className="gutter-row" span={12} sm={12} xs={24}>
                                        <Typography>META H1:</Typography>
                                        <Input value={editvalues.meta_h1} onChange={handleEditChange("meta_h1")} />
                                    </Col>
                                    <Col className="gutter-row" span={12} sm={12} xs={24}>
                                        <Typography>META Description:</Typography>
                                        <Input value={editvalues.meta_description} onChange={handleEditChange("meta_description")} />
                                    </Col>
                                </Row>
                                <Row gutter={6} style={{ marginBottom: 5 }}>
                                    <Col className="gutter-row" span={12} sm={12} xs={24}>
                                        <Typography>SEO Url:</Typography>
                                        <Input value={editvalues.url} onChange={handleEditChange("url")} />
                                    </Col>
                                    <Col className="gutter-row" span={12} sm={12} xs={24}>
                                        <Typography>META Title:</Typography>
                                        <Input value={editvalues.meta_title} onChange={handleEditChange("meta_title")} />
                                    </Col>
                                </Row>
                            </div>
                        </Modal>

                        <Modal
                            centered
                            title="Добавление SEO страницы"
                            visible={showAdd}
                            style={{}}
                            onCancel={handleCloseAddModal}
                            footer={[
                                <Button
                                    onClick={handleCloseAddModal}
                                    key="back"
                                    type="danger"
                                >
                                    Отмена
						</Button>,
                                <Button
                                    color="secondary"
                                    onClick={handleAddSeoPage}
                                    key="yes"
                                    type="primary"
                                    disabled={adding}
                                >
                                    Добавить
						</Button>,
                            ]}
                        >
                            <Row gutter={6} style={{ marginBottom: 5 }}>
                                <Col className="gutter-row" span={24} sm={24} xs={24}>
                                    <Typography>Название:</Typography>
                                    <Input value={values.name} onChange={handleChange("name")} />
                                </Col>
                            </Row>
                            <Row gutter={6} style={{ marginBottom: 5 }}>
                                    <Col className="gutter-row" span={24} sm={24} xs={24}>
                                        <Typography>Доп. название:</Typography>
                                        <Input value={values.name_extra} onChange={handleChange("name_extra")} />
                                    </Col>
                                </Row>
                            <Row gutter={6} style={{ marginBottom: 5 }}>
                                <Col className="gutter-row" span={24} sm={24} xs={24}>
                                    <Typography>Data #1:</Typography>
                                    <TextArea style={{ marginBottom: 10 }} value={values.data} onChange={handleChange("data")} rows={3} />
                                </Col>
                            </Row>

                            <Row gutter={6} style={{ marginBottom: 5 }}>
                                <Col className="gutter-row" span={24} sm={24} xs={24}>
                                    <Typography>Data #2:</Typography>
                                    <TextArea style={{ marginBottom: 10 }} value={values.data_2} onChange={handleChange("data_2")} rows={3} />
                                </Col>
                            </Row>

                            <Row gutter={6} style={{ marginBottom: 5 }}>
                                <Col className="gutter-row" span={24} sm={24} xs={24}>
                                    <Typography>Data #3:</Typography>
                                    <TextArea style={{ marginBottom: 10 }} value={values.data_3} onChange={handleChange("data_3")} rows={3} />
                                </Col>
                            </Row>

                            <Row gutter={6} style={{ marginBottom: 5 }}>
                                <Col className="gutter-row" span={24} sm={24} xs={24}>
                                    <Typography>Data #4:</Typography>
                                    <TextArea style={{ marginBottom: 10 }} value={values.data_4} onChange={handleChange("data_4")} rows={3} />
                                </Col>
                            </Row>

                            <Row gutter={6} style={{ marginBottom: 5 }}>
                                <Col className="gutter-row" span={24} sm={24} xs={24}>
                                    <Typography>Data #5:</Typography>
                                    <TextArea style={{ marginBottom: 10 }} value={values.data_5} onChange={handleChange("data_5")} rows={3} />
                                </Col>
                            </Row>

                            <Row gutter={6} style={{ marginBottom: 5 }}>
                                <Col className="gutter-row" span={12} sm={12} xs={24}>
                                    <Typography>META H1:</Typography>
                                    <Input value={values.meta_h1} onChange={handleChange("meta_h1")} />
                                </Col>
                                <Col className="gutter-row" span={12} sm={12} xs={24}>
                                    <Typography>META Description:</Typography>
                                    <Input value={values.meta_description} onChange={handleChange("meta_description")} />
                                </Col>
                            </Row>
                            <Row gutter={6} style={{ marginBottom: 5 }}>
                                <Col className="gutter-row" span={12} sm={12} xs={24}>
                                    <Typography>SEO Url:</Typography>
                                    <Input value={values.url} onChange={handleChange("url")} />
                                </Col>
                                <Col className="gutter-row" span={12} sm={12} xs={24}>
                                    <Typography>META Title:</Typography>
                                    <Input value={values.meta_title} onChange={handleChange("meta_title")} />
                                </Col>
                            </Row>
                        </Modal>

                    </div>
                ] : null}
            </QueueAnim>
        );
    }
};

const useFetching = (getSeoPageList, dispatch) => {
    useEffect(() => {
        dispatch(getSeoPageList());
    }, []);
};

export default CPSeoPages;
