
import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
	getCategoryList,
	addCategory,
	deleteCategory,
	editCategory,
	showAddCategoryModal,
	closeAddCategoryModal,
	showDelCategoryModal,
	showEdCategoryModal,
	closeDelCategoryModal,
	closeEdCategoryModal,
	addCategoryImg,
	deleteCategoryImg,
	closeCategoryImg,
	showCategoryImg,
} from "../../actions/categoryActions";

import { orderBy } from "lodash";

import QueueAnim from 'rc-queue-anim';
import { Input, Spin, Divider, Row, Col, Card, Typography, Button, Icon, Modal, Select, Layout, Table, Upload, Tag } from 'antd';
import serverIp from "../../utils/serverIp";

const { Search } = Input;
const { TextArea } = Input;
const { Option } = Select;
const CPCategoryList = props => {
	const [creating, setCreating] = React.useState(false);
	useFetching(getCategoryList, useDispatch());
	const categories = useSelector(state => state.categories.categories);
	const loading = useSelector(state => state.categories.isLoading);
	const showAdd = useSelector(state => state.categories.showAdd);
	const showDel = useSelector(state => state.categories.showDel);
	const showEdit = useSelector(state => state.categories.showEdit);
	const categoryDel = useSelector(state => state.categories.categoryDel);
	const categoryEdit = useSelector(state => state.categories.categoryEdit);
	const items = useSelector(state => state.items.items);
	const isShowAddImgCategory = useSelector(state => state.categories.isShowAddImgCategory);
	const categoryImgAdding = useSelector(state => state.categories.categoryImgAdding);
	const categoryToAddImg = useSelector(state => state.categories.categoryToAddImg);

	const [values, setValues] = React.useState({
		name: "",
		description: "",
		sortorder: 0,
		web: 0,
		meta_description: "",
		meta_title: "",
		meta_h1: "",
		url: "",
		indexpage: 0,
		image: "",
		canEdit: 0,
		recommended: []
	});

	const [searchvalue, setSearchValue] = React.useState({
		search: ""
	});

	const [editvalues, setEditValues] = React.useState({
		_id: categoryEdit._id,
		name: categoryEdit.name,
		description: categoryEdit.description,
		sortorder: categoryEdit.sortorder,
		web: categoryEdit.web,
		meta_description: categoryEdit.meta_description,
		meta_title: categoryEdit.meta_title,
		meta_h1: categoryEdit.meta_h1,
		url: categoryEdit.url,
		indexpage: categoryEdit.indexpage,
		image: categoryEdit.image,
		canEdit: categoryEdit.canEdit,
		recommended: categoryEdit.recommended
	});

	const dispatch = useDispatch();

	const handleAddCategory = () => {
		dispatch(addCategory(values));
	};

	const handleDelCategory = () => {
		dispatch(deleteCategory(categoryDel));
	};

	const handleEditCategory = () => {
		dispatch(editCategory(editvalues));
	};

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	const dummyRequest = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};
	const uploadButton = (
		<div>
			<div className="ant-upload-text">Загрузить</div>
		</div>
	);

	const handleChangeCategoryImage = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, image => {
				let imageFormObj = new FormData();
				//var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", categoryToAddImg._id);
				imageFormObj.append("imageData", info.file.originFileObj);
				dispatch(addCategoryImg(imageFormObj));
			});
		}
	};

	const handleCategoryImgDelete = () => {
		dispatch(deleteCategoryImg(categoryToAddImg));
	};

	const showEditImgCategoryModal = category => {
		dispatch(showCategoryImg(category));
	};
	const closeEditImgCategoryModal = () => {
		dispatch(closeCategoryImg());
	};

	const showEditCategoryModal = category => {
		setEditValues({
			...editvalues,
			_id: category._id,
			name: category.name,
			description: category.description,
			sortorder: category.sortorder,
			web: category.web,
			meta_description: category.meta_description,
			meta_title: category.meta_title,
			meta_h1: category.meta_h1,
			url: category.url,
			indexpage: category.indexpage,
			image: category.image,
			canEdit: category.canEdit,
			recommended: category.recommended
		});
		dispatch(showEdCategoryModal(category));
	};
	const showDeleteCategoryModal = category => {
		dispatch(showDelCategoryModal(category));
	};

	const handleCloseEditModal = useCallback(
		() => dispatch(closeEdCategoryModal()),
		[dispatch]
	);
	const handleCloseDelModal = useCallback(
		() => dispatch(closeDelCategoryModal()),
		[dispatch]
	);
	const handleOpenAddModal = useCallback(
		() => dispatch(showAddCategoryModal()),
		[dispatch]
	);
	const handleCloseAddModal = useCallback(
		() => dispatch(closeAddCategoryModal()),
		[dispatch]
	);

	const handleSearchChange = name => event => {
		setSearchValue({ ...searchvalue, [name]: event.target.value });
	};

	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};

	const handleEditChange = name => event => {
		setEditValues({ ...editvalues, [name]: event.target.value });
	};

	const handleEditChangeSelect = (e, from) => {
		if (from == 'web') {
			console.log(e);
			setEditValues({ ...editvalues, web: e });
		}
		if (from == 'canEdit') {
			console.log(e);
			setEditValues({ ...editvalues, canEdit: e });
		}
	};
	const handleChangeSelect = (e, from) => {
		if (from == 'web') {
			console.log(e);
			setValues({ ...values, web: e });
		}
		if (from == 'canEdit') {
			console.log(e);
			setValues({ ...values, canEdit: e });
		}
		if (from == 'indexpage') {
			console.log(e);
			setValues({ ...values, indexpage: e });
		}
	};
	function ControlButtons(cat) {
		return (
			<div>
				<Button style={{ marginRight: 10 }} size="small" type="primary" shape="circle" icon="edit" onClick={() => showEditCategoryModal(cat)} />
				<Button style={{ marginRight: 0 }} size="small" type="danger" shape="circle" icon="delete" onClick={() => showDeleteCategoryModal(cat)} />
			</div>
		);
	}

	function renderCategoryInfo(category) {
		return (
			<>
				<div key={category.id} className="category-info-box">
					<div className="category-info-section">
						<span><b>Описание:</b></span><br />
						<span>{category.description}</span><br />

						<br />
						<span><b>Отображение на сайте:</b></span><br />
						{category.web == 1 ? (<><span>Да</span><br /></>) : (<><span>Нет</span><br /></>)}

						<br />
						<span><b>МЕТА Title:</b></span><br />
						<span>{category.meta_title}</span><br />

						<br />
						<span><b>META H1:</b></span><br />
						<span>{category.meta_h1}</span><br />

						<br />
						<span><b>META Description:</b></span><br />
						<span>{category.meta_description}</span><br />

						<br />
						<span><b>SEO URL:</b></span><br />
						<span>{category.url}</span><br />

						<br />
						<span><b>Является категорией для отображения на главной странице?</b></span><br />
						<span>{category.indexpage == 1 ? (<span>Да</span>) : (<span>Нет</span>)}</span><br /><br />

						<br />
						<span><b>Может ли менеджер редактировать товары в этой категории?</b></span><br />
						<span>{category.canEdit == 1 ? (<span>Да</span>) : (<span>Нет</span>)}</span><br /><br />

						<br />

						<span><b>Рекомендованные товары:</b></span><br />
						{category.recommended ? (<>
							{category.recommended.map((rec,i)=>(
								<Tag key={i}>{rec.name}</Tag>
							))}
						</>):(<></>)}

						<br /><br />
						{category.image != '' ?
							<img src={serverIp + category.image} alt="avatar" style={{ maxWidth: 400, width: '100%' }} />
							: (
								<div></div>
							)}

					</div>
				</div>
				<div className="category-buttons">
					<Button style={{ marginRight: 5 }} type="dashed" onClick={() => showEditImgCategoryModal(category)}>ИЗОБРАЖЕНИЕ</Button>
					<Button style={{ marginRight: 5 }} type="dashed" onClick={() => showEditCategoryModal(category)}>РЕДАКТИРОВАТЬ</Button>
					<Button style={{ marginRight: 5 }} type="dashed" onClick={() => showDeleteCategoryModal(category)}>УДАЛИТЬ</Button>
				</div>
			</>
		);
	}


	function onChangeRecommended(value) {
		console.log('onChangeRecommended')
		console.log(value);
		let obj = items.find(obj => obj._id === value);
		let rec = {
			id: obj._id,
			name: obj.name,
			price: obj.base_price,
			image: obj.image1,
			url: obj.url
		}
		setEditValues({ ...editvalues, "recommended": [...editvalues.recommended, rec] });
	}

	function onBlurRecommended() {
		console.log('blur');
	}

	function onFocusRecommended() {
		console.log('focus');
	}

	function onSearchRecommended(val) {
		console.log('search:', val);
	}

	function deleteRecTag(val) {
		console.log(val);
		let filteredArray = editvalues.recommended.filter(item => item.id !== val);
		console.log(filteredArray);
		setEditValues({ ...editvalues, "recommended": filteredArray });

	}

	function SortArray(x, y){
		return x.category[0].localeCompare(y.category[0]);
	}

	// {
    //     location: "Nashville, TN",
    //     description: "Clear",
    //     current: { temp: 75, wind: 5 },
    //     temps: [
    //         { type: "low", value: 68 },
    //         { type: "high", value: 88 }
    //     ],
    // },

	let sortedArray = [...items];

	console.log(sortedArray.sort((a, b) => {
		// if (a.category === b.category){
		  return a.category < b.category ? -1 : 1
		// } else {
		//   return a.name < b.name ? -1 : 1
		// }
	  }))

	// console.log(orderBy( sortedArray, ["category[0]", "name"], ["asc", "asc"]).map(({ name }) => name));

	// sortedArray = sortedArray.sort(SortArray);


	function renderSort() {

		const columns = [
			{ title: 'Название', dataIndex: 'name', key: 'name' },
			{ title: 'Порядок сортировки', dataIndex: 'sortorder', key: 'sortorder' },
		];

		if (categories.length > 0) {
			return (
				<Table
					rowKey={record => record._id}
					style={{ marginTop: 20 }}
					columns={columns}
					expandedRowRender={record => renderCategoryInfo(record)}
					dataSource={categories}
				/>
			);
		} else {
			return (
				<div>
					<Card title="Категорий не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	}

	if (loading) {
		return (
				<div className="content-div">
					<Divider className="header-div" orientation="left"><Spin /></Divider>
				</div>
		);
	} else {
		return (
				<QueueAnim className="anim-content"
					type={['right', 'left']}
					ease={['easeOutQuart', 'easeInOutQuart']}>
					{!loading ? [
						<div key="a" className="content-div">
							<div>
								<Divider className="header-div" orientation="left">Список категорий</Divider>
							</div>
							{renderSort()}
							<Card style={{ width: '100%', marginBottom: 20 }} size="small">
								<Button type="primary" icon="plus" onClick={handleOpenAddModal}>
									Добавить
								</Button>
							</Card>


							<Modal
								centered
								title="Редактирование изображения для категории"
								visible={isShowAddImgCategory}
								style={{}}
								onCancel={closeEditImgCategoryModal}
								footer={[
									<Button
										onClick={closeEditImgCategoryModal}
										key="back"
										type="danger"
										disabled={categoryImgAdding}
									>
										Отмена
									</Button>,
								]}
							>
								<Typography style={{ marginBottom: 15 }}>Название категории: {categoryToAddImg.name}</Typography>

								<Upload
									style={{ margin: '0 auto' }}
									name="avatar"
									listType="picture-card"
									className="avatar-uploader"
									showUploadList={false}
									customRequest={dummyRequest}
									onChange={handleChangeCategoryImage}
								>
									{categoryToAddImg.image ? <img src={serverIp + categoryToAddImg.image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
								</Upload>
								{(categoryToAddImg.image != '') ? (<Button type="danger" style={{ width: '100%' }} disabled={categoryImgAdding} onClick={handleCategoryImgDelete}>Очистить</Button>) : (<div></div>)}
							</Modal>


							<Modal
								centered
								title="Удалить категорию?"
								visible={showDel}
								style={{ textAlign: 'center' }}
								onCancel={handleCloseDelModal}
								footer={[
									<Button
										onClick={handleCloseDelModal}
										key="back"
										type="primary"
									>
										Отмена
						</Button>,
									<Button
										color="secondary"
										onClick={handleDelCategory}
										key="yes"
										type="danger"
									>
										Удалить
						</Button>,
								]}
							>
								<p>{categoryDel.name}</p>
							</Modal>

							<Modal
								centered
								title={"Редактирование категории"}
								visible={showEdit}
								onCancel={handleCloseEditModal}
								footer={[
									<Button
										key="back"
										type="primary"
										onClick={handleCloseEditModal}
									>
										Отмена
							</Button>,
									<Button
										key="yes"
										type="danger"
										onClick={handleEditCategory}
									>
										Сохранить
							</Button>,
								]}
							>
								<Row gutter={16} style={{ marginBottom: 10 }}>
									<Col className="gutter-row" span={12} sm={12} xs={24}>
										<Input addonBefore="Название" value={editvalues.name} onChange={handleEditChange("name")} />
									</Col>
									<Col className="gutter-row" span={12} sm={12} xs={24}>
										<Input type="number" addonBefore="Порядок сортировки" value={editvalues.sortorder} onChange={handleEditChange("sortorder")} />
									</Col>
								</Row>
								<Row gutter={24}>
									<Col className="gutter-row" span={24}>
										<Typography>Описание категории:</Typography>
										<TextArea label="Описание категории" value={editvalues.description} onChange={handleEditChange("description")} rows={4} />
									</Col>
								</Row>
								<Divider />
								<Row gutter={16}>
									<Col className="gutter-row" span={12} sm={12} xs={24}>
										<Typography>Показывать на сайте:</Typography>
										<Select
											defaultValue={editvalues.web}
											style={{ width: 220, marginBottom: 10 }}
											onChange={(e) => handleEditChangeSelect(e, "web")}
										>
											<Option value={0}>Нет</Option>
											<Option value={1}>Да</Option>
										</Select>
									</Col>
								</Row>
								<Row gutter={16} style={{ marginBottom: 10 }}>
									<Col className="gutter-row" span={12} sm={12} xs={24}>
										<Typography>META Title:</Typography>
										<Input value={editvalues.meta_title} onChange={handleEditChange("meta_title")} />
									</Col>
								</Row>
								<Row gutter={16} style={{ marginBottom: 10 }}>
									<Col className="gutter-row" span={12} sm={12} xs={24}>
										<Typography>META H1:</Typography>
										<Input value={editvalues.meta_h1} onChange={handleEditChange("meta_h1")} />
									</Col>
								</Row>
								<Row gutter={16} style={{ marginBottom: 10 }}>
									<Col className="gutter-row" span={12} sm={12} xs={24}>
										<Typography>SEO url:</Typography>
										<Input value={editvalues.url} onChange={handleEditChange("url")} />
									</Col>
								</Row>
								<Row gutter={16}>
									<Col className="gutter-row" span={24}>
										<Typography>META description:</Typography>
										<TextArea label="META description" value={editvalues.meta_description} onChange={handleEditChange("meta_description")} rows={4} />
									</Col>
								</Row>
								<Row gutter={16}>
									<Col className="gutter-row" span={12} sm={12} xs={24}>
										<Typography>Менеджер может редактировать товары из этой категории:</Typography>
										<Select
											defaultValue={editvalues.canEdit}
											style={{ width: 220, marginBottom: 10 }}
											onChange={(e) => handleEditChangeSelect(e, "canEdit")}
										>
											<Option value={0}>Нет</Option>
											<Option value={1}>Да</Option>
										</Select>
									</Col>

									<Col className="gutter-row" style={{ textAlign: 'left' }} sm={24}>
										<Typography>Рекомендованные товары:</Typography>
										{console.log(items)}
										<Select
											showSearch
											style={{ width: '100%' }}
											placeholder="Выберите рекомендуемый товар"
											optionFilterProp="children"
											onChange={onChangeRecommended}
											onFocus={onFocusRecommended}
											onBlur={onBlurRecommended}
											onSearch={onSearchRecommended}
											// filterOption={(input, option) =>
											// 	option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											// }
										>
											{sortedArray.map((itemtorec, i) => {
												if(itemtorec.category.length > 0){
													return(
														<Option value={itemtorec._id} key={itemtorec._id.toString()+'_rec'}><Tag>{itemtorec.category[0]}</Tag> - {itemtorec.name}</Option>
													)
												}

												
											})}

										</Select>
										<br />
										<br />
										{editvalues.recommended ? (<>
											{editvalues.recommended.map((recitem, i) => (
												<Tag key={recitem.id} closable={true} onClose={() => deleteRecTag(recitem.id)}>{recitem.name}</Tag>
											))}
										</>) : (<></>)}
										<br />
										<br />
									</Col>


								</Row>
							</Modal>

							<Modal
								centered
								title={"Добавление категории"}
								visible={showAdd}
								onCancel={handleCloseAddModal}
								footer={[
									<Button
										key="back"
										onClick={handleCloseAddModal}
									>
										Отмена
							</Button>,
									<Button
										key="yes"
										type="primary"
										disabled={creating}
										onClick={handleAddCategory}
									>
										Сохранить
							</Button>,
								]}
							>

								<Row gutter={16} style={{ marginBottom: 10 }}>
									<Col className="gutter-row" span={12} sm={12} xs={24}>
										<Input addonBefore="Название" value={values.name} onChange={handleChange("name")} />
									</Col>
									<Col className="gutter-row" span={12} sm={12} xs={24}>
										<Input type="number" addonBefore="Порядок сортировки" value={values.sortorder} onChange={handleChange("sortorder")} />
									</Col>
								</Row>
								<Row gutter={24}>
									<Col className="gutter-row" span={24}>
										<Typography>Описание категории:</Typography>
										<TextArea label="Описание категории" value={values.description} onChange={handleChange("description")} rows={4} />
									</Col>
								</Row>
								<Divider />
								<Row gutter={16}>
									<Col className="gutter-row" span={12} sm={12} xs={24}>
										<Typography>Менеджер может редактировать товары из этой категории:</Typography>
										<Select
											defaultValue={values.canEdit}
											style={{ width: 220, marginBottom: 10 }}
											onChange={(e) => handleChangeSelect(e, "canEdit")}
										>
											<Option value={0}>Нет</Option>
											<Option value={1}>Да</Option>
										</Select>
									</Col>
								</Row>
								<Row gutter={24}>
									<Col className="gutter-row" span={24}>
										<Typography>Показывать на сайте:</Typography>
										<Select
											defaultValue={values.web}
											style={{ width: 220, marginBottom: 10 }}
											onChange={(e) => handleChangeSelect(e, "web")}
										>
											<Option value={0}>Нет</Option>
											<Option value={1}>Да</Option>
										</Select>
									</Col>
								</Row>
								{values.web == 1 ? (
									<>
										<Row gutter={16} style={{ marginBottom: 10 }}>
											<Col className="gutter-row" span={12} sm={12} xs={24}>
												<Typography>META H1:</Typography>
												<Input value={values.meta_h1} onChange={handleChange("meta_h1")} />

												<Typography>Показывать только на главной:</Typography>
												<Select
													defaultValue={values.indexpage}
													style={{ width: 220, marginBottom: 10 }}
													onChange={(e) => handleChangeSelect(e, "indexpage")}
												>
													<Option value={0}>Нет</Option>
													<Option value={1}>Да</Option>
												</Select>
											</Col>
										</Row>
										<Row gutter={16} style={{ marginBottom: 10 }}>
											<Col className="gutter-row" span={12} sm={12} xs={24}>
												<Typography>META Title:</Typography>
												<Input value={values.meta_title} onChange={handleChange("meta_title")} />
											</Col>
										</Row>
										<Row gutter={16} style={{ marginBottom: 10 }}>
											<Col className="gutter-row" span={12} sm={12} xs={24}>
												<Typography>SEO url:</Typography>
												<Input value={values.url} onChange={handleChange("url")} />
											</Col>
										</Row>
										<Row gutter={24}>
											<Col className="gutter-row" span={24}>
												<Typography>META description:</Typography>
												<TextArea value={values.meta_description} onChange={handleChange("meta_description")} rows={4} />
												<Typography style={{ textAlign: 'center', marginTop: 15 }}>Изображение можно добавить только после создания категории.</Typography>
											</Col>
										</Row>
									</>
								) : (
										<></>
									)}


							</Modal>
						</div>
					] : null}
				</QueueAnim>
		);
	}
};

const useFetching = (getCategoryList, dispatch) => {
	useEffect(() => {
		dispatch(getCategoryList());
	}, []);
};

export default CPCategoryList;
