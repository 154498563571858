import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  GET_SETTINGS,
  LOADING_SETTINGS,
  GET_SETTINGS_ERRORS,
  EDIT_SETTINGS,
  SAVED_SETTINGS,
  SAVED_SETTINGS_ERRORS,
  SHOW_ADD_PROMO_MODAL,
  CLOSE_ADD_PROMO_MODAL,
  PROMO_ADDING,
  PROMO_ADDED,
  SHOW_ADD_PROMO_IMG_MODAL,
  CLOSE_ADD_PROMO_IMG_MODAL,
  PROMO_IMG_ADDED,
  PROMO_IMG_ADDING,
  PROMO_IMG_DELETED,
  SHOW_PROMO_EDIT,
  CLOSE_PROMO_EDIT,
  PROMO_EDITED,
  PROMO_EDITING,
  PROMO_DELETED,
  GET_SIDE_MENU,
  EDIT_SIDE_MENU,
  SAVED_SIDE_MENU,
  SIDE_MENU_BLOCK_IMG_ADDED,
  SIDE_MENU_BLOCK_IMG_ADDING,
  TAB_LOGO_IMG_ADDED,
  TAB_LOGO_IMG_ADDING
} from "./types";

export const delSMLogo = (promo) => dispatch => {
  dispatch(setSMBlockImgAdding());
  let data = { id: promo }
  axios
  .post(serverIp+"api/settings/delsmlogo", data)
  .then(res => {
    dispatch({
      type: SIDE_MENU_BLOCK_IMG_ADDED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_SETTINGS_ERRORS,
      payload: err.response
    })
  );
};


export const delTabLogo = () => dispatch => {
  dispatch({
    type: TAB_LOGO_IMG_ADDING
  })  
  axios
  .post(serverIp+"api/settings/deltablogo")
  .then(res => {
    dispatch({
      type: TAB_LOGO_IMG_ADDED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_SETTINGS_ERRORS,
      payload: err.response
    })
  );
};

export const addTabLogo = (data) => dispatch => {
  dispatch({
    type: TAB_LOGO_IMG_ADDING
  })  
  axios
  .post(serverIp+"api/settings/addtablogo", data)
  .then(res => {
    dispatch({
      type: TAB_LOGO_IMG_ADDED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_SETTINGS_ERRORS,
      payload: err.response
    })
  );
};

export const addSMLogo = (data) => dispatch => {
  dispatch(setSMBlockImgAdding());
  axios
  .post(serverIp+"api/settings/addsmlogo", data)
  .then(res => {
    dispatch({
      type: SIDE_MENU_BLOCK_IMG_ADDED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_SETTINGS_ERRORS,
      payload: err.response
    })
  );
};

export const delSMBlockImg = (promo) => dispatch => {
  dispatch(setSMBlockImgAdding());
  let data = { id: promo }
  axios
  .post(serverIp+"api/settings/delsmimg", data)
  .then(res => {
    dispatch({
      type: SIDE_MENU_BLOCK_IMG_ADDED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_SETTINGS_ERRORS,
      payload: err.response
    })
  );
};

export const addSMBlockImg = (data) => dispatch => {
  dispatch(setSMBlockImgAdding());
  axios
  .post(serverIp+"api/settings/addsmimg", data)
  .then(res => {
    dispatch({
      type: SIDE_MENU_BLOCK_IMG_ADDED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_SETTINGS_ERRORS,
      payload: err.response
    })
  );
};

export const setSMBlockImgAdding = () => {
  return {
    type: SIDE_MENU_BLOCK_IMG_ADDING
  };
};

export const getSideMenu = () => dispatch => {
  axios
    .get(serverIp + "api/settings/getsidemenu")
    .then(res => {
      dispatch({
        type: GET_SIDE_MENU,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_SETTINGS_ERRORS,
        payload: err.response
      })
    );
};

export const editSideMenu = (data) => dispatch => {
  dispatch({
    type: EDIT_SIDE_MENU,
    payload: data
  });
};

export const saveSideMenu = (setdata) => dispatch => {
  axios
    .put(serverIp + "api/settings/setsidemenu", setdata)
    .then(res => {
      dispatch({
        type: SAVED_SIDE_MENU,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: SAVED_SETTINGS_ERRORS,
        payload: err.response
      })
    );
};



// получаем настройки
export const getSettings = () => dispatch => {
  dispatch(setSettingsLoading());
  axios
    .get(serverIp + "api/settings/getsettings")
    .then(res => {
      dispatch({
        type: GET_SETTINGS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_SETTINGS_ERRORS,
        payload: err.response
      })
    );
};

export const deletePromo = (data) => dispatch =>{
  axios
    .post(serverIp+"api/settings/deletepromo", data)
    .then(res => {
      dispatch({
        type: PROMO_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_SETTINGS_ERRORS,
        payload: err.response
      })
    );
};

export const addPromoImg = (data) => dispatch => {
  dispatch(setPromoImgAdding());
  axios
  .post(serverIp+"api/settings/addpromoimg", data)
  .then(res => {
    dispatch({
      type: PROMO_IMG_ADDED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_SETTINGS_ERRORS,
      payload: err.response
    })
  );

};

export const editPromo = (promo) => dispatch => {
  dispatch(setPromoEditing());
  axios
    .post(serverIp+"api/settings/editpromo", promo)
    .then(res => {
      dispatch({
        type: PROMO_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_SETTINGS_ERRORS,
        payload: err.response
      })
    );
};

export const setPromoEditing = () => {
  return {
    type: PROMO_EDITING
  };
};

export const showEditPromoModal = (promo) => dispatch => {
  dispatch({
    type: SHOW_PROMO_EDIT,
    payload: promo
  });
};

export const closeEditPromoModal = () => dispatch => {
  dispatch({
    type: CLOSE_PROMO_EDIT
  });
};

export const deletePromoImg = (promo) => dispatch => {
  dispatch(setPromoImgAdding());
  let data = { id: promo }
  axios
  .post(serverIp+"api/settings/delpromoimg", data)
  .then(res => {
    dispatch({
      type: PROMO_IMG_DELETED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: GET_SETTINGS_ERRORS,
      payload: err.response
    })
  );
};

export const showAddPromoImgModal = (promo) => dispatch => {
  dispatch({
    type: SHOW_ADD_PROMO_IMG_MODAL,
    payload: promo
  });
};
export const closeAddPromoImgModal = () => dispatch => {
  dispatch({
    type: CLOSE_ADD_PROMO_IMG_MODAL
  });
};

export const editSettings = (data) => dispatch => {
  dispatch({
    type: EDIT_SETTINGS,
    payload: data
  });
};

export const setPromoImgAdding = () => {
  return {
    type: PROMO_IMG_ADDING
  };
};

export const saveSettings = (setdata) => dispatch => {
  axios
    .put(serverIp + "api/settings/setsettings", setdata)
    .then(res => {
      dispatch({
        type: SAVED_SETTINGS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: SAVED_SETTINGS_ERRORS,
        payload: err.response
      })
    );
};

export const addPromo = (promo) => dispatch => {
  dispatch(setPromoAdding());
  axios
  .post(serverIp+"api/settings/addpromo", promo)
  .then(res => {
    dispatch({
      type: PROMO_ADDED,
      payload: res.data
    });
  })
  .catch(err =>
    dispatch({
      type: SAVED_SETTINGS_ERRORS,
      payload: err.response
    })
  );
}

export const showAddPromoModal = () => {
  return {
    type: SHOW_ADD_PROMO_MODAL
  };
};
export const closeAddPromoModal = () => {
  return {
    type: CLOSE_ADD_PROMO_MODAL
  };
};

// Создается промо
export const setPromoAdding = () => {
  return {
    type: PROMO_ADDING
  };
};

// Загружаются настройки
export const setSettingsLoading = () => {
  return {
    type: LOADING_SETTINGS
  };
};
