import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  GET_CUSTOMERS_LIST,
  LOADING_CUSTOMERS,
  GET_CUSTOMERS_ERRORS,
  SHOW_ADD_CUSTOMER_MODAL,
  CLOSE_ADD_CUSTOMER_MODAL,
  CUSTOMER_ADDED,
  ADDING_CUSTOMER,
  SHOW_DEL_CUSTOMER_MODAL,
  CLOSE_DEL_CUSTOMER_MODAL,
  CUSTOMER_DELETED,
  LOADING_CUSTOMERS_PAGE,
  SHOW_ORDER_HISTORY_CUSTOMER_MODAL,
  CLOSE_ORDER_HISTORY_CUSTOMER_MODAL,
  SHOW_BONUS_HISTORY_CUSTOMER_MODAL,
  CLOSE_BONUS_HISTORY_CUSTOMER_MODAL,
  ORDER_HISTORY_CUSTOMER_LOADED,
  ORDER_HISTORY_CUSTOMER_LOADING,
  SHOW_ADD_BONUS_CUSTOMER_MODAL,
  CLOSE_ADD_BONUS_CUSTOMER_MODAL,
  SHOW_DEL_BONUS_CUSTOMER_MODAL,
  CLOSE_DEL_BONUS_CUSTOMER_MODAL,
  CUSTOMER_BONUS_ADDED,
  CUSTOMER_BONUS_DELETED,
  SHOW_EDIT_CUSTOMER_MODAL,
  CLOSE_EDIT_CUSTOMER_MODAL,
  CUSTOMER_EDITING,
  CUSTOMER_EDITED,
  ALL_CUSTOMERS_LOADED,
  ALL_CUSTOMERS_LOADING
} from "./types";



export const downloadAllCustomers = () => dispatch => {
  dispatch(setAllCustomersLoading());
  axios
    .post(serverIp + "api/customers/getallcustomers", {})
    .then(res => {
      dispatch({
        type: ALL_CUSTOMERS_LOADED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CUSTOMERS_ERRORS,
        payload: err.response
      })
    );
};
export const setAllCustomersLoading = () => {
  return {
    type: ALL_CUSTOMERS_LOADING
  };
};

export const getPhoneFiltered = (phone) => dispatch => {
  dispatch(setPageLoading());
  axios
    .post(serverIp + "api/customers/filterphone", { phone: phone })
    .then(res => {
      dispatch({
        type: GET_CUSTOMERS_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CUSTOMERS_ERRORS,
        payload: err.response
      })
    );
};
export const getEmailFiltered = (email) => dispatch => {
  dispatch(setPageLoading());
  axios
    .post(serverIp + "api/customers/filteremail", { email: email })
    .then(res => {
      dispatch({
        type: GET_CUSTOMERS_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CUSTOMERS_ERRORS,
        payload: err.response
      })
    );
};

export const editCustomer = (customer) => dispatch => {
  dispatch({
    type: CUSTOMER_EDITING
  })
  axios
    .post(serverIp + "api/customers/edit", { customer: customer })
    .then(res => {
      dispatch({
        type: CUSTOMER_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CUSTOMERS_ERRORS,
        payload: err.response
      })
    );
};

export const openEditCustomerModal = () => {
  return {
    type: SHOW_EDIT_CUSTOMER_MODAL
  };
};
export const closeEditCustomerModal = () => {
  return {
    type: CLOSE_EDIT_CUSTOMER_MODAL
  };
};

export const openAddBonusModal = (customer) => {
  return {
    type: SHOW_ADD_BONUS_CUSTOMER_MODAL,
    payload: customer
  };
};
export const closeAddBonusModal = () => {
  return {
    type: CLOSE_ADD_BONUS_CUSTOMER_MODAL
  };
};

export const openDelBonusModal = (customer) => {
  return {
    type: SHOW_DEL_BONUS_CUSTOMER_MODAL,
    payload: customer
  };
};
export const closeDelBonusModal = () => {
  return {
    type: CLOSE_DEL_BONUS_CUSTOMER_MODAL
  };
};

export const addBonus = (customer, count) => dispatch => {
  dispatch({
    type: CUSTOMER_EDITING
  })
  axios
    .post(serverIp + "api/customers/addbonus", { customer: customer._id, count: count })
    .then(res => {
      dispatch({
        type: CUSTOMER_BONUS_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CUSTOMERS_ERRORS,
        payload: err.response
      })
    );
};

export const delBonus = (customer, count) => dispatch => {
  dispatch({
    type: CUSTOMER_EDITING
  })
  axios
    .post(serverIp + "api/customers/delbonus", { customer: customer._id, count: count })
    .then(res => {
      dispatch({
        type: CUSTOMER_BONUS_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CUSTOMERS_ERRORS,
        payload: err.response
      })
    );
};

export const getCustomersOrder = (customer) => dispatch => {
  dispatch({
    type: ORDER_HISTORY_CUSTOMER_LOADING
  })
  axios
    .post(serverIp + "api/customers/getcustomerorders", { customer: customer._id })
    .then(res => {
      dispatch({
        type: ORDER_HISTORY_CUSTOMER_LOADED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CUSTOMERS_ERRORS,
        payload: err.response
      })
    );
};

export const openOrderHistoryModal = (customer) => {
  return {
    type: SHOW_ORDER_HISTORY_CUSTOMER_MODAL,
    payload: customer
  };
};
export const closeOrderHistoryModal = () => {
  return {
    type: CLOSE_ORDER_HISTORY_CUSTOMER_MODAL
  };
};
export const openBonusHistoryModal = () => {
  return {
    type: SHOW_BONUS_HISTORY_CUSTOMER_MODAL
  };
};
export const closeBonusHistoryModal = () => {
  return {
    type: CLOSE_BONUS_HISTORY_CUSTOMER_MODAL
  };
};

// получаем доставки
export const getCustomersList = (page, rowsCount, pageLoad) => dispatch => {
  if (!pageLoad) {
    dispatch(setCustomersLoading());
  } else {
    dispatch(setPageLoading());
  }
  axios
    .post(serverIp + "api/customers/getcustomerlist", { page: page, rowsCount: rowsCount })
    .then(res => {
      dispatch({
        type: GET_CUSTOMERS_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CUSTOMERS_ERRORS,
        payload: err.response
      })
    );
};

export const addCustomer = (customer) => dispatch => {
  dispatch(setCustomerAdding());
  axios
    .post(serverIp + "api/customers/addcustomer", customer)
    .then(res => {
      dispatch({
        type: CUSTOMER_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CUSTOMERS_ERRORS,
        payload: err.response
      })
    );
};

export const deleteCustomer = (customer) => dispatch => {
  axios
    .post(serverIp + "api/customers/deletecustomer", customer)
    .then(res => {
      dispatch({
        type: CUSTOMER_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_CUSTOMERS_ERRORS,
        payload: err.response
      })
    );
};

export const showDelCustomerModal = (customer) => {
  return {
    type: SHOW_DEL_CUSTOMER_MODAL,
    payload: customer
  };
};

export const closeDelCustomerModal = () => {
  return {
    type: CLOSE_DEL_CUSTOMER_MODAL
  };
};

export const setCustomerAdding = () => {
  return {
    type: ADDING_CUSTOMER
  };
};

export const setCustomersLoading = () => {
  return {
    type: LOADING_CUSTOMERS
  };
};

export const setPageLoading = () => {
  return {
    type: LOADING_CUSTOMERS_PAGE
  };
};

export const showAddCustomerModal = () => {
  return {
    type: SHOW_ADD_CUSTOMER_MODAL
  };
};
export const closeAddCustomerModal = () => {
  return {
    type: CLOSE_ADD_CUSTOMER_MODAL
  };
};
