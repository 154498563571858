import axios from "axios";
import serverIp from "../utils/serverIp";

import {
    NOMENCLATURE_LIST_LOADED,
    NOMENCLATURE_LIST_LOADING,
    NOMENCLATURE_ADDED,
    NOMENCLATURE_ADDING
} from "./types";

export const getNomenclatureList = () => dispatch => {
    dispatch(setNomenclatureLoading());
    console.log('getNomenclatureList')
    axios
        .get(serverIp + "warehouse/nomenclature/getnomenclaturelist")
        .then(res => {
            dispatch({
                type: NOMENCLATURE_LIST_LOADED,
                payload: res.data
            });
        })
        .catch(err => { });
};

export const setNomenclatureLoading = () => {
    return {
        type: NOMENCLATURE_LIST_LOADING
    };
};


export const addNomenclature = (nomenclature) => dispatch => {
    dispatch(setNomenclatureAdding());
    axios
        .post(serverIp + "warehouse/nomenclature/addnomenclature", nomenclature)
        .then(res => {
            dispatch({
                type: NOMENCLATURE_ADDED,
                payload: res.data
            });
        })
        .catch(err => { });
};

export const setNomenclatureAdding = () => {
    return {
        type: NOMENCLATURE_ADDING
    };
};