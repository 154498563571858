import {
  GET_SETTINGS,
  LOADING_SETTINGS,
  EDIT_SETTINGS,
  SAVED_SETTINGS,
  SAVED_SETTINGS_ERRORS,
  SHOW_ADD_PROMO_MODAL,
  CLOSE_ADD_PROMO_MODAL,
  PROMO_ADDING,
  PROMO_ADDED,
  SHOW_ADD_PROMO_IMG_MODAL,
  CLOSE_ADD_PROMO_IMG_MODAL,
  PROMO_IMG_ADDED,
  PROMO_IMG_ADDING,
  PROMO_IMG_DELETED,
  SHOW_PROMO_EDIT,
  CLOSE_PROMO_EDIT,
  PROMO_EDITED,
  PROMO_EDITING,
  PROMO_DELETED,
  GET_SIDE_MENU,
  EDIT_SIDE_MENU,
  SAVED_SIDE_MENU,
  SIDE_MENU_BLOCK_IMG_ADDED,
  SIDE_MENU_BLOCK_IMG_ADDING,
  TAB_LOGO_IMG_ADDED,
  TAB_LOGO_IMG_ADDING
} from "../actions/types";

const initialState = {
  isLoading: true,
  androidVersion: 0,
  appleVersion: 0,
  androidStoreLink: '',
  appleStoreLink: '',
  viewStyle: 0,
  productStyle: 0,
  mainHeader: '',
  headerBackground: '',
  headerTextColor: '',
  bottomBackground: '',
  bottomTextColor: '',
  bottomTextColorActive: '',
  parallaxHeight: 0,
  optionTint: '',
  optionSelectedTint: '',
  optionBackTint: '',
  productInfoStyle: 0,
  mainElementsColor: '',
  mainElementsTextColor: '',
  extraField1Name: '',
  extraField2Name: '',
  contactPhone: '',
  contactEmail: '',
  contactWorkTime: '',
  contactAdress: '',
  contactMapLa: '',
  contactMapLo: '',
  contactMapZoom: '',
  contactFieldName: '',
  contactFieldContent: '',
  needSave: false,
  showMsg: false,
  isAddPromoModal: false,
  promoAdding: false,
  promoBlocks: [],
  isAddPromoImgModal: false,
  promoBlockToAddImg: {},
  promoBlockToEdit: {},
  promoImgAdding: false,
  isEditPromoModal: false,
  promoEditing: false,
  mainBackgroundColor: '',
  secondBackgroundColor: '',
  textHeaderColor: '',
  headerSliderBackgroundColor: '',
  baseTextColor: '',
  smallCart: 0,
  returnToMenu: 0,
  baseStyle: 0,
  tabLogoAdding: false,
  tabLogo: '',
  // Side menu

  needSaveSM: false,

  salesDisabled: false,
  salesDisabledTitle: '',
  salesDisabledText: '',

  smHeader1: '',
  smHeader1_2: '',
  smHeader1Image: '',
  smHeader2: '',
  smHeader2_2: '',
  smHeader2Image: '',
  smHeader3: '',
  smHeader3_2: '',
  smHeader3Image: '',
  smHeader4: '',
  smHeader4_2: '',
  smHeader4Image: '',
  smHeaderLogo: '',
  smBlockImgAdding: false,
  smArticles: [],
  smDeliveryArticle: {
    name: '',
    id: ''
  },
  smAboutArticle: {
    name: '',
    id: ''
  },
  smBlockArticle1: {
    name: '',
    id: ''
  },
  smBlockArticle2: {
    name: '',
    id: ''
  },
  smBlockArticle3: {
    name: '',
    id: ''
  },
  smBlockArticle4: {
    name: '',
    id: ''
  },
  smBlockPath1: '',
  smBlockPath2: '',
  smBlockPath3: '',
  smBlockPath4: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TAB_LOGO_IMG_ADDING:
      return {
        ...state,
        tabLogoAdding: true,
      }
    case TAB_LOGO_IMG_ADDED:
      return {
        ...state,
        tabLogo: action.payload.settings.tabLogo,
        tabLogoAdding: false,
      }
    case SIDE_MENU_BLOCK_IMG_ADDED:
      console.log(action.payload)
      return {
        ...state,
        smHeader1Image: action.payload.appsm.smHeader1Image,
        smHeader2Image: action.payload.appsm.smHeader2Image,
        smHeader3Image: action.payload.appsm.smHeader3Image,
        smHeader4Image: action.payload.appsm.smHeader4Image,
        smHeaderLogo: action.payload.appsm.smHeaderLogo,
        smBlockImgAdding: false,
      }
    case SIDE_MENU_BLOCK_IMG_ADDING:
      return {
        ...state,
        smBlockImgAdding: true,
      }
    case GET_SIDE_MENU:
      console.log(action.payload.article)
      return {
        ...state,
        needSaveSM: false,
        salesDisabled: action.payload.sidemenu[0].salesDisabled,
        salesDisabledTitle: action.payload.sidemenu[0].salesDisabledTitle,
        salesDisabledText: action.payload.sidemenu[0].salesDisabledText,
        smHeader1: action.payload.sidemenu[0].smHeader1,
        smHeader2: action.payload.sidemenu[0].smHeader2,
        smHeader3: action.payload.sidemenu[0].smHeader3,
        smHeader4: action.payload.sidemenu[0].smHeader4,
        smHeader1_2: action.payload.sidemenu[0].smHeader1_2,
        smHeader2_2: action.payload.sidemenu[0].smHeader2_2,
        smHeader3_2: action.payload.sidemenu[0].smHeader3_2,
        smHeader4_2: action.payload.sidemenu[0].smHeader4_2,
        smHeader1Image: action.payload.sidemenu[0].smHeader1Image,
        smHeader2Image: action.payload.sidemenu[0].smHeader2Image,
        smHeader3Image: action.payload.sidemenu[0].smHeader3Image,
        smHeader4Image: action.payload.sidemenu[0].smHeader4Image,
        smHeaderLogo: action.payload.sidemenu[0].smHeaderLogo,

        smArticles: action.payload.article,
        smDeliveryArticle: action.payload.sidemenu[0].smDeliveryArticle,
        smAboutArticle: action.payload.sidemenu[0].smAboutArticle,
        smBlockArticle1: action.payload.sidemenu[0].smBlockArticle1,
        smBlockArticle2: action.payload.sidemenu[0].smBlockArticle2,
        smBlockArticle3: action.payload.sidemenu[0].smBlockArticle3,
        smBlockArticle4: action.payload.sidemenu[0].smBlockArticle4,
        smBlockPath1: action.payload.sidemenu[0].smBlockPath1,
        smBlockPath2: action.payload.sidemenu[0].smBlockPath2,
        smBlockPath3: action.payload.sidemenu[0].smBlockPath3,
        smBlockPath4: action.payload.sidemenu[0].smBlockPath4,

      };
    case EDIT_SIDE_MENU:
      return {
        ...state,

        salesDisabled: action.payload.newSalesDisabled,
        salesDisabledTitle: action.payload.newSalesDisabledTitle,
        salesDisabledText: action.payload.newSalesDisabledText,

        smHeader1: action.payload.newSmHeader1,
        smHeader2: action.payload.newSmHeader2,
        smHeader3: action.payload.newSmHeader3,
        smHeader4: action.payload.newSmHeader4,
        smHeader1_2: action.payload.newSmHeader1_2,
        smHeader2_2: action.payload.newSmHeader2_2,
        smHeader3_2: action.payload.newSmHeader3_2,
        smHeader4_2: action.payload.newSmHeader4_2,

        smDeliveryArticle: {
          name: action.payload.newSmDeliveryArticle.name,
          id: action.payload.newSmDeliveryArticle.id
        },
        smAboutArticle: {
          name: action.payload.newSmAboutArticle.name,
          id: action.payload.newSmAboutArticle.id
        },
        smBlockArticle1: {
          name: action.payload.newSmBlockArticle1.name,
          id: action.payload.newSmBlockArticle1.id
        },
        smBlockArticle2: {
          name: action.payload.newSmBlockArticle2.name,
          id: action.payload.newSmBlockArticle2.id
        },
        smBlockArticle3: {
          name: action.payload.newSmBlockArticle3.name,
          id: action.payload.newSmBlockArticle3.id
        },
        smBlockArticle4: {
          name: action.payload.newSmBlockArticle4.name,
          id: action.payload.newSmBlockArticle4.id
        },
        smBlockPath1: action.payload.newSmBlockPath1,
        smBlockPath2: action.payload.newSmBlockPath2,
        smBlockPath3: action.payload.newSmBlockPath3,
        smBlockPath4: action.payload.newSmBlockPath4,
        needSaveSM: true,
      };
    case SAVED_SIDE_MENU:
      return {
        ...state,
        needSaveSM: false,
      };

    case PROMO_DELETED:
      const id = action.payload.deletedpromo._id;
      return {
        ...state,
        promoBlocks: state.promoBlocks.filter((promo) => promo._id !== id),
      };
    case PROMO_EDITING:
      return {
        ...state,
        promoEditing: true,
      }
    case PROMO_EDITED:
      const updatedState = state.promoBlocks.map((promo) => {
        if (promo._id === action.payload.editedpromo._id) {
          return { ...promo, ...action.payload.editedpromo };
        } else {
        }
        return promo;
      });
      return {
        ...state,
        promoBlocks: updatedState,
        isEditPromoModal: false,
        promoEditing: false,
        promoBlockToEdit: {},
      };
    case SHOW_PROMO_EDIT:
      return {
        ...state,
        isEditPromoModal: true,
        promoBlockToEdit: action.payload
      }
    case CLOSE_PROMO_EDIT:
      return {
        ...state,
        isEditPromoModal: false,
        promoBlockToEdit: {}
      }

    case PROMO_IMG_DELETED:
      const updatedDelImgState = state.promoBlocks.map((promo) => {
        if (promo._id === action.payload.promo._id) {
          return { ...promo, ...action.payload.promo };
        } else {
        }
        return promo;
      });
      return {
        ...state,
        promoBlocks: updatedDelImgState,
        isAddPromoImgModal: false,
        promoImgAdding: false,
      }
    case PROMO_IMG_ADDING:
      return {
        ...state,
        promoImgAdding: true,
      }
    case PROMO_IMG_ADDED:
      const updatedAddImgState = state.promoBlocks.map((promo) => {
        if (promo._id === action.payload.promo._id) {
          return { ...promo, ...action.payload.promo };
        } else {
        }
        return promo;
      });

      return {
        ...state,
        promoBlocks: updatedAddImgState,
        isAddPromoImgModal: false,
        promoImgAdding: false,
      };
    case SHOW_ADD_PROMO_IMG_MODAL:
      return {
        ...state,
        isAddPromoImgModal: true,
        promoBlockToAddImg: action.payload,
      }
    case CLOSE_ADD_PROMO_IMG_MODAL:
      return {
        ...state,
        isAddPromoImgModal: false,
        promoBlockToAddImg: {},
      }
    case PROMO_ADDING:
      return {
        ...state,
        promoAdding: true,
      }
    case PROMO_ADDED:
      return {
        ...state,
        promoAdding: false,
        promoBlocks: [...state.promoBlocks, action.payload.newpromo],
        isAddPromoModal: false,
      }
    case SHOW_ADD_PROMO_MODAL:
      return {
        ...state,
        isAddPromoModal: true,
      }
    case CLOSE_ADD_PROMO_MODAL:
      return {
        ...state,
        isAddPromoModal: false,
      }
    case GET_SETTINGS:
      return {
        ...state,
        androidVersion: action.payload.settings[0].androidVersion,
        appleVersion: action.payload.settings[0].appleVersion,

        androidStoreLink: action.payload.settings[0].androidStoreLink,
        appleStoreLink: action.payload.settings[0].appleStoreLink,

        baseStyle: action.payload.settings[0].baseStyle,
        viewStyle: action.payload.settings[0].viewStyle,
        productStyle: action.payload.settings[0].productStyle,
        mainHeader: action.payload.settings[0].mainHeader,
        headerBackground: action.payload.settings[0].headerBackground,
        headerTextColor: action.payload.settings[0].headerTextColor,
        bottomBackground: action.payload.settings[0].bottomBackground,
        bottomTextColor: action.payload.settings[0].bottomTextColor,
        bottomTextColorActive: action.payload.settings[0].bottomTextColorActive,

        mainBackgroundColor: action.payload.settings[0].mainBackgroundColor,
        secondBackgroundColor: action.payload.settings[0].secondBackgroundColor,
        baseTextColor: action.payload.settings[0].baseTextColor,
        textHeaderColor: action.payload.settings[0].textHeaderColor,
        headerSliderBackgroundColor: action.payload.settings[0].headerSliderBackgroundColor,

        smallCart: action.payload.settings[0].smallCart,
        returnToMenu: action.payload.settings[0].returnToMenu,

        parallaxHeight: action.payload.settings[0].parallaxHeight,
        optionTint: action.payload.settings[0].optionTint,
        optionSelectedTint: action.payload.settings[0].optionSelectedTint,
        optionBackTint: action.payload.settings[0].optionBackTint,
        productInfoStyle: action.payload.settings[0].productInfoStyle,

        mainElementsColor: action.payload.settings[0].mainElementsColor,
        mainElementsTextColor: action.payload.settings[0].mainElementsTextColor,
        extraField1Name: action.payload.settings[0].extraField1Name,
        extraField2Name: action.payload.settings[0].extraField2Name,
        contactPhone: action.payload.settings[0].contactPhone,
        contactEmail: action.payload.settings[0].contactEmail,
        contactWorkTime: action.payload.settings[0].contactWorkTime,
        contactAdress: action.payload.settings[0].contactAdress,
        contactMapLa: action.payload.settings[0].contactMapLa,
        contactMapLo: action.payload.settings[0].contactMapLo,
        contactMapZoom: action.payload.settings[0].contactMapZoom,
        contactFieldName: action.payload.settings[0].contactFieldName,
        contactFieldContent: action.payload.settings[0].contactFieldContent,
        promoBlocks: action.payload.promoBlocks,
        isLoading: false,
        needSave: false,
        tabLogo: action.payload.settings[0].tabLogo,
      };
    case EDIT_SETTINGS:
      return {
        ...state,
        androidVersion: action.payload.newAndroidVersion,
        appleVersion: action.payload.newAppleVersion,
        androidStoreLink: action.payload.newAndroidStoreLink,
        appleStoreLink: action.payload.newAppleStoreLink,

        baseStyle: action.payload.newBaseStyle,
        viewStyle: action.payload.newViewStyle,
        productStyle: action.payload.newProductStyle,
        mainHeader: action.payload.newMainHeader,
        headerBackground: action.payload.newHeaderBackground,
        headerTextColor: action.payload.newHeaderTextColor,
        bottomBackground: action.payload.newBottomBackground,
        bottomTextColor: action.payload.newBottomTextColor,
        bottomTextColorActive: action.payload.newBottomTextColorActive,

        mainBackgroundColor: action.payload.newMainBackgroundColor,
        secondBackgroundColor: action.payload.newSecondBackgroundColor,
        baseTextColor: action.payload.newBaseTextColor,
        textHeaderColor: action.payload.newTextHeaderColor,
        headerSliderBackgroundColor: action.payload.newHeaderSliderBackgroundColor,

        smallCart: action.payload.newSmallCart,
        returnToMenu: action.payload.newReturnToMenu,

        parallaxHeight: action.payload.newParallaxHeight,
        optionTint: action.payload.newOptionTint,
        optionSelectedTint: action.payload.newOptionSelectedTint,
        optionBackTint: action.payload.newOptionBackTint,
        productInfoStyle: action.payload.newProductInfoStyle,
        mainElementsColor: action.payload.newMainElementsColor,
        mainElementsTextColor: action.payload.newMainElementsTextColor,
        extraField1Name: action.payload.newExtraField1Name,
        extraField2Name: action.payload.newExtraField2Name,
        contactPhone: action.payload.newContactPhone,
        contactEmail: action.payload.newContactEmail,
        contactWorkTime: action.payload.newContactWorkTime,
        contactAdress: action.payload.newContactAdress,
        contactMapLa: action.payload.newContactMapLa,
        contactMapLo: action.payload.newContactMapLo,
        contactMapZoom: action.payload.newContactMapZoom,
        contactFieldName: action.payload.newContactFieldName,
        contactFieldContent: action.payload.newContactFieldContent,
        needSave: true,
      };
    case SAVED_SETTINGS:
      return {
        ...state,
        needSave: false,
        showMsg: true
      };
    case LOADING_SETTINGS:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
}
