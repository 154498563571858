import {
    COMPLECT_LIST_LOADED,
    COMPLECT_LIST_LOADING,
    COMPLECT_ADDED,
    COMPLECT_ADDING,
    COMPLECT_DELETED,
    COMPLECT_EDITED
} from "../actions/types";

const initialState = {
    isLoading: true,
    isAdding: false,
    complects: [],
    nomenclatures: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case COMPLECT_EDITED:
            console.log('COMPLECT_EDITED')
            console.log(action.payload)
            const updatedState = state.complects.map((complect) => {
                if (complect._id === action.payload.editedcomplect._id) {
                    return { ...complect, ...action.payload.editedcomplect };
                } else {
                }
                return complect;
            });
            return {
                ...state,
                complects: updatedState,
            };

        case COMPLECT_DELETED:
            const id = action.payload.deletedcomplect._id;
            return {
                ...state,
                complects: state.complects.filter((complect) => complect._id !== id),
            };

        case COMPLECT_LIST_LOADED:
            return {
                ...state,
                complects: action.payload.complects,
                nomenclatures: action.payload.nomenclatures,
                isLoading: false,
            }
        case COMPLECT_LIST_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case COMPLECT_ADDING:
            return {
                ...state,
                isAdding: true
            }
        case COMPLECT_ADDED:
            return {
                ...state,
                complects: [...state.complects, action.payload.complect],
                isAdding: false
            }
        default:
            return state;
    }
}
