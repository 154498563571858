import {
  NEW_ORDER_CREATING,
  NEW_ORDER_CREATED,
  GET_ORDER_LIST,
  LOADING_ORDERS,
  GET_ORDERS_ERRORS,
  SHOW_CONTENT_MODAL,
  CLOSE_CONTENT_MODAL,
  GET_ORDER_LIST_UPDATE,
  SHOW_ORDER_STATUS_MODAL,
  CLOSE_ORDER_STATUS_MODAL,
  ORDER_STATUS_UPDATE,
  ORDER_PAYMENT_UPDATE,
  ORDER_ITEM_DELETED,
  SHOW_ADD_TO_ORDER_MODAL,
  CLOSE_ADD_TO_ORDER_MODAL,
  ORDER_ITEMS_TO_ADD_LOADING,
  ORDER_ITEMS_TO_ADD_LOADED,
  ORDER_ITEMS_WITH_CATEGORIES_TO_ADD_LOADED,
  ORDER_ADD_ITEM,
  ORDER_ADD_ITEM_ADDING,
  ORDER_OPEN_CHEQUE_MODAL,
  ORDER_CLOSE_CHEQUE_MODAL,
  SHOW_ADD_COURIER_ORDER,
  CLOSE_ADD_COURIER_ORDER,
  ORDER_COURIER_LIST_LOADED,
  ORDER_COURIER_ADDED,
  ORDER_COURIER_ADDING,
  LOADING_ORDER_PAGE,
  NEW_ORDER_NEED_TO_BE_LOAD,
  GET_NEW_ORDER,
  GET_FILTERED_ORDERS,
  SHOW_ORDER_CREATE_MODAL,
  HIDE_ORDER_CREATE_MODAL,
  GET_SEARCHED_CLIENTS,
  ORDER_RESET_CLIENT_SEARCH,
  GET_CREATED_ORDER,
  UPDATE_ORDER_DELIVERED,
  OPEN_ORDER_CALC_CHANGE,
  CLOSE_ORDER_CALC_CHANGE,
  ORDER_CALC_CHANGED,
  OPEN_ORDER_EDIT_COMMENT,
  CLOSE_ORDER_EDIT_COMMENT,
  ORDER_EDITED_COMMENT,
  ORDER_SMALL_REPORT_RECIEVED,
  ORDER_SMALL_REPORT_LOADING,
  ORDER_UPDATED,
  ORDER_OPEN_ADDRESS_MODAL,
  ORDER_CLOSE_ADDRESS_MODAL,
  ORDER_SET_NEW_ADDRESS,
  ORDER_NEW_ADDRESS_EDITING,
  OPEN_ORDER_PRINT_FILTERED,
  CLOSE_ORDER_PRINT_FILTERED,
  ORDER_GIFT_ITEMS_TO_ADD_LOADED,
  ORDER_CHECK_PAYMENT_ANSWER,
  KITERING_TOGGLED,
  SHOW_HIDE_KITERING,
  LOADING_CUSTOMER_BONUS,
  LOADED_CUSTOMER_BONUS
} from "../actions/types";
import { sendEditComment } from "../actions/orderActions";

const initialState = {
  kiteringShow: false,
  isLoading: true,
  showAddOrder: false,
  orders: [],
  showContent: false,
  orderContent: [],
  initclientstatus: {},
  // clientstatuses: [],
  initcrmstatus: {},
  // crmstatuses: [],
  isShowStatusModal: false,
  showAddItem: false,
  itemsToAdd: [],
  itemCategoriesToAdd: [],
  itemsToAddLoading: false,
  addingToOrder: false,
  deliveries: [],
  payments: [],
  chequeToPrint: {},
  showCheque: false,
  chequesettings: {
    cheque_title: '',
    cheque_field_1: '',
    cheque_field_2: '',
    cheque_field_3: '',
    cheque_field_4: '',
    cheque_user_name: true,
    cheque_customer_data: true,
  },
  isShowAddCourier: false,
  isCourierLoading: false,
  isCourierAdding: false,
  courierList: [],
  total: null,
  pageLoading: false,
  needToUpdate: false,
  orderToLoad: null,
  orderStatuses: [],
  searchedClients: [],
  isShowCalcChange: false,
  isShowEditComment: false,
  smallReport: null,
  smallReportLoading: true,
  isShowAddressModal: false,
  isAddressEditing: false,
  isShowPrintCurrentFilterDay: false,
  giftitemsToAdd: [],

  newordercreation: false,

  customerBonusHistory: null,
  orderCustomerBonusLoading: true,
  customerOrderHistory: null,
};

export default function (state = initialState, action) {
  switch (action.type) {

    case LOADING_CUSTOMER_BONUS:
      return {
        ...state,
        orderCustomerBonusLoading: true,
      }

    case LOADED_CUSTOMER_BONUS:
      return {
        ...state,
        orderCustomerBonusLoading: false,
        customerBonusHistory: action.payload.bonushistory,
        customerOrderHistory: action.payload.orderhistory
      }

    case NEW_ORDER_CREATED:
      return {
        ...state,
        newordercreation: false,
      }
    case NEW_ORDER_CREATING:
      return {
        ...state,
        newordercreation: true,
      }
    case OPEN_ORDER_PRINT_FILTERED:
      return {
        ...state,
        isShowPrintCurrentFilterDay: true,
      }
    case CLOSE_ORDER_PRINT_FILTERED:
      return {
        ...state,
        isShowPrintCurrentFilterDay: false,
      }
    case ORDER_SET_NEW_ADDRESS:

      return {
        ...state,
        isAddressEditing: false,
        isShowAddressModal: false,
        orders: state.orders.map(order => order._id === action.payload.order._id ?
          { ...order, ...action.payload.order } :
          order
        )
      }
    case ORDER_NEW_ADDRESS_EDITING:
      return {
        ...state,
        isAddressEditing: true
      }
    case ORDER_OPEN_ADDRESS_MODAL:
      return {
        ...state,
        isShowAddressModal: true,
      }
    case ORDER_CLOSE_ADDRESS_MODAL:
      return {
        ...state,
        isShowAddressModal: false,
      }

    case ORDER_UPDATED:
      console.log('ORDER_UPDATED');
      return {
        ...state,
        orders: state.orders.map(order => order._id === action.payload.order._id ?
          { ...order, ...action.payload.order } :
          order
        )
      }
    case ORDER_SMALL_REPORT_LOADING:
      return {
        ...state,
        smallReportLoading: true,
      }
    case ORDER_SMALL_REPORT_RECIEVED:
      console.log('ORDER_SMALL_REPORT_RECIEVED');
      return {
        ...state,
        smallReport: action.payload.data,
        smallReportLoading: false,
      }
    case OPEN_ORDER_EDIT_COMMENT:
      return {
        ...state,
        isShowEditComment: true
      }
    case CLOSE_ORDER_EDIT_COMMENT:
      return {
        ...state,
        isShowEditComment: false,
      }
    case ORDER_EDITED_COMMENT:
      return {
        ...state,
        isShowEditComment: false,
        orders: state.orders.map(order => order._id === action.payload.id ?
          { ...order, comment: action.payload.comment } :
          order
        )
      }
    case OPEN_ORDER_CALC_CHANGE:
      return {
        ...state,
        isShowCalcChange: true,
      }
    case CLOSE_ORDER_CALC_CHANGE:
      return {
        ...state,
        isShowCalcChange: false,
      }
    case ORDER_CALC_CHANGED:
      return {
        ...state,
        isShowCalcChange: false,
        orders: state.orders.map(order => order._id === action.payload.id ?
          { ...order, comment: action.payload.comment } :
          order
        )
      }
    case UPDATE_ORDER_DELIVERED:
      console.log('UPDATE_ORDER_DELIVERED')
      console.log(action.payload)
      return {
        ...state,
        orders: state.orders.map(order => order._id === action.payload.id ?
          { ...order, paid: action.payload.paid, courier_delivered_at: action.payload.time, status: action.payload.apistatus, clientstatus: action.payload.clientstatus, statusHistory: action.payload.statushistory } :
          order
        )
      }
    case GET_CREATED_ORDER:
      return {
        ...state,
        orders: [action.payload.neworder, ...state.orders],
        showAddOrder: false,
        searchedClients: [],
      }
    case ORDER_RESET_CLIENT_SEARCH:
      return {
        ...state,
        searchedClients: [],
      }
    case GET_SEARCHED_CLIENTS:
      return {
        ...state,
        searchedClients: action.payload.clients,
      }
    case SHOW_ORDER_CREATE_MODAL:
      return {
        ...state,
        showAddOrder: true,
      }
    case HIDE_ORDER_CREATE_MODAL:
      return {
        ...state,
        showAddOrder: false,
        searchedClients: [],
      }
    case GET_FILTERED_ORDERS:
      console.log(action.payload.orders);
      return {
        ...state,
        orders: action.payload.orders,
        pageLoading: false,
        total: null,
      }

    case SHOW_HIDE_KITERING:
      // console.log(action.payload.orders);
      return {
        ...state,
        kiteringShow: true,
        orders: action.payload.orders,
        pageLoading: false,
        total: null,
      }

    case KITERING_TOGGLED:
      const updatedKiteringState = state.orders.map((order) => {
        if (order.id === action.payload.updatedorder.id) {
          return { ...order, ...action.payload.updatedorder };
        } else {
        }
        return order;
      });
      return {
        ...state,
        orders: updatedKiteringState,
        isShowAddCourier: false,
        isCourierAdding: false,
      };


    case GET_NEW_ORDER:
      return {
        ...state,
        total: action.payload.total,
        orders: [action.payload.neworder, ...state.orders],
        needToUpdate: false,
        orderToLoad: null,
      }

    case ORDER_CHECK_PAYMENT_ANSWER:
      return {
        ...state,
        orders: state.orders.map(order => order._id === action.payload.order._id ?
          { ...order, ...action.payload.order } :
          order
        )
      }

    case NEW_ORDER_NEED_TO_BE_LOAD:
      return {
        ...state,
        needToUpdate: true,
        orderToLoad: action.payload,
      }
    case SHOW_ADD_COURIER_ORDER:
      return {
        ...state,
        isShowAddCourier: true,
        isCourierLoading: true,
      }
    case CLOSE_ADD_COURIER_ORDER:
      return {
        ...state,
        isShowAddCourier: false,
        courierList: []
      }
    case ORDER_COURIER_LIST_LOADED:
      return {
        ...state,
        isCourierLoading: false,
        courierList: action.payload.couriers,
      }
    case ORDER_COURIER_ADDED:
      const updatedOrderCourierState = state.orders.map((order) => {
        if (order.id === action.payload.updatedorder.id) {
          return { ...order, ...action.payload.updatedorder };
        } else {
        }
        return order;
      });
      return {
        ...state,
        orders: updatedOrderCourierState,
        isShowAddCourier: false,
        isCourierAdding: false,
      };
    case ORDER_COURIER_ADDING:
      return {
        ...state,
        isCourierAdding: true,
      }
    case ORDER_OPEN_CHEQUE_MODAL:
      return {
        ...state,
        chequeToPrint: action.payload.order,
        showCheque: true,
      }
    case ORDER_CLOSE_CHEQUE_MODAL:
      return {
        ...state,
        chequeToPrint: {},
        showCheque: false,
      }
    case ORDER_ADD_ITEM_ADDING:
      return {
        ...state,
        addingToOrder: true,
      }
    case ORDER_ADD_ITEM:
      const updatedOrderStateAfterAddItem = state.orders.map((order) => {
        if (order.id === action.payload.order.id) {
          return { ...order, ...action.payload.order };
        } else {
        }
        return order;
      });
      return {
        ...state,
        orders: updatedOrderStateAfterAddItem,
        addingToOrder: false,
        orderContent: action.payload.order
      };
    case ORDER_ITEMS_TO_ADD_LOADING:
      return {
        ...state,
        itemsToAddLoading: true
      }
    case ORDER_ITEMS_WITH_CATEGORIES_TO_ADD_LOADED:
      //console.log(action.payload);
      return {
        ...state,
        itemsToAddLoading: false,
        itemsToAdd: action.payload.items,
        itemCategoriesToAdd: action.payload.categories,
      }
    case ORDER_ITEMS_TO_ADD_LOADED:
      return {
        ...state,
        itemsToAddLoading: false,
        itemsToAdd: action.payload.items,
      }
    case ORDER_GIFT_ITEMS_TO_ADD_LOADED:
      return {
        ...state,
        giftitemsToAdd: action.payload.items,
      }
    case SHOW_ADD_TO_ORDER_MODAL:
      return {
        ...state,
        showAddItem: true
      }
    case CLOSE_ADD_TO_ORDER_MODAL:
      return {
        ...state,
        itemsToAdd: [],
        showAddItem: false
      }

    case ORDER_STATUS_UPDATE:
      console.log('ORDER_STATUS_UPDATE');
      // /console.log(action.payload);
      console.log(state.orders);
      const updatedOrderState = state.orders.map((order) => {
        console.log('map +1');
        if (order.id === action.payload.updatedorder.id) {
          return { ...order, ...action.payload.updatedorder };
        } else {
        }
        return order;
      });
      console.log('updatedOrderState');
      console.log(updatedOrderState);


      return {
        ...state,
        // needToUpdate: true,
        // orderToLoad: action.payload.updatedorder,
        pageLoading: false,
        orders: updatedOrderState,
        isShowStatusModal: false,
      };

    case SHOW_ORDER_STATUS_MODAL:
      return {
        ...state,
        isShowStatusModal: true,
      }
    case CLOSE_ORDER_STATUS_MODAL:
      return {
        ...state,
        isShowStatusModal: false,
      }
    case ORDER_ITEM_DELETED:
      const updatedOrderStateAfterDelItem = state.orders.map((order) => {
        if (order.id === action.payload.order.id) {
          return { ...order, ...action.payload.order };
        } else {
        }
        return order;
      });
      return {
        ...state,
        orders: updatedOrderStateAfterDelItem,

        orderContent: action.payload.order
      };
    case SHOW_CONTENT_MODAL:
      return {
        ...state,
        showContent: true,
        orderContent: action.payload
      }
    case CLOSE_CONTENT_MODAL:
      return {
        ...state,
        showContent: false,
        orderContent: []
      }
    case GET_ORDER_LIST_UPDATE:
      return {
        ...state,
        isLoading: false,
        orders: action.payload.orders,
        total: action.payload.total,
      };
    case LOADING_ORDER_PAGE:
      return {
        ...state,
        pageLoading: true,
        needToUpdate: false,
        orderToLoad: null
        //orders: []
      }
    case GET_ORDER_LIST:
      console.log(action.payload);
      return {
        ...state,
        isLoading: false,
        kiteringShow: false,
        orders: action.payload.orders,
        total: action.payload.total,
        pageLoading: false,
        initclientstatus: action.payload.initclientstatus,
        // clientstatuses: action.payload.clientstatuses,
        initcrmstatus: action.payload.initcrmstatus,
        // crmstatuses: action.payload.crmstatuses,
        orderStatuses: action.payload.orderstatuses,
        deliveries: action.payload.delivery,
        payments: action.payload.payment,
        courierList: action.payload.courierlist,
        chequesettings: {
          cheque_title: action.payload.printer[0].title,
          cheque_field_1: action.payload.printer[0].field_1,
          cheque_field_2: action.payload.printer[0].field_2,
          cheque_field_3: action.payload.printer[0].field_3,
          cheque_field_4: action.payload.printer[0].field_4,
          cheque_user_name: action.payload.printer[0].user_name,
          cheque_customer_data: action.payload.printer[0].customer_data,
        },
      };
    case LOADING_ORDERS:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
}
