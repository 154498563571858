import React from "react";
import { Layout } from 'antd';
import TopButtons from '../sys-pages/TopButtons';

function TopMenu() {
  const { Header } = Layout;
  return (
    <Header style={{ background: '#fff', padding: 0 }}>
      <TopButtons className="top-buttons" />
    </Header>
  );
};

export default TopMenu;
