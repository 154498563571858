import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
	addItemOption,
	showOpItemModal,
	closeOpItemModal,
	getItemList,
	showAddItemModal,
	closeAddItemModal,
	showDelItemModal,
	showEdItemModal,
	closeDelItemModal,
	closeEdItemModal,
	deleteItem,
	editItem,
	addItem,
	showAddImgItemModal,
	closeAddImgItemModal,
	sendItemImage,
	sendItemImageDelete,
	closeOpItemModalDel,
	showOpItemModalDel,
	delItemOption,
	showOpCheckboxItemModal,
	closeOpCheckboxItemModal,
	showOpRadioItemModal,
	closeOpRadioItemModal,
	addRadioToItem,
	closeEditFilter,
	showEditFilter,
	deleteRadioGroupFromItem,
	addRowToCurrent,
	delRowToCurrent,
	setStopList,
	sortItems
} from "../../actions/itemActions";

import { Select, Input, Button, Row, Col, Typography, Divider, Spin, Card, Icon, Avatar, Modal, Upload, Layout, List, Table, Tag, Popconfirm, Checkbox } from 'antd';
import QueueAnim from 'rc-queue-anim';

import serverIp from "../../utils/serverIp";

import { DndProvider, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

const { Search } = Input;
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

//const apiServ = 'http://localhost:5000/';

let dragingIndex = -1;

class BodyRow extends React.Component {
	render() {
		const { isOver, connectDragSource, connectDropTarget, moveRow, ...restProps } = this.props;
		const style = { ...restProps.style, cursor: 'move' };

		let { className } = restProps;
		if (isOver) {
			if (restProps.index > dragingIndex) {
				className += ' drop-over-downward';
			}
			if (restProps.index < dragingIndex) {
				className += ' drop-over-upward';
			}
		}

		return connectDragSource(
			connectDropTarget(<tr {...restProps} className={className} style={style} />),
		);
	}
}

const rowSource = {
	beginDrag(props) {
		dragingIndex = props.index;
		return {
			index: props.index,
		};
	},
};

const rowTarget = {
	drop(props, monitor) {
		const dragIndex = monitor.getItem().index;
		const hoverIndex = props.index;

		// Don't replace items with themselves
		if (dragIndex === hoverIndex) {
			return;
		}

		// Time to actually perform the action

		console.log('action ! time')

		props.moveRow(dragIndex, hoverIndex);
		// Note: we're mutating the monitor item here!
		// Generally it's better to avoid mutations,
		// but it's good here for the sake of performance
		// to avoid expensive index searches.
		monitor.getItem().index = hoverIndex;
	},
};

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
}))(
	DragSource('row', rowSource, connect => ({
		connectDragSource: connect.dragSource(),
	}))(BodyRow),
);


const CPItemList = props => {
	useFetching(getItemList, useDispatch());
	const items = useSelector(state => state.items.items);
	const loading = useSelector(state => state.items.isLoading);
	const showAdd = useSelector(state => state.items.showAdd);
	const showDel = useSelector(state => state.items.showDel);
	const showEdit = useSelector(state => state.items.showEdit);
	const showAddImg = useSelector(state => state.items.showAddImg);

	// ЧЕКБОКСЫ
	const optionscheckbox = useSelector(state => state.items.optionscheckboxlist);
	const showCheckboxOptionAdd = useSelector(state => state.items.showCheckboxOptionAdd);
	const itemCheckboxOptionsLoading = useSelector(state => state.items.itemCheckboxOptionsLoading);
	const itemEditCheckboxOption = useSelector(state => state.items.itemEditCheckboxOption);

	// RADIO
	const optionsradio = useSelector(state => state.items.optionsradiolist);
	const showRadioOptionAdd = useSelector(state => state.items.showRadioOptionAdd);
	const itemRadioOptionsLoading = useSelector(state => state.items.itemRadioOptionsLoading);
	const itemEditRadioOption = useSelector(state => state.items.itemEditRadioOption);

	// ФИЛЬТРЫ
	const showEditFilterModal = useSelector(state => state.items.showEditFilterModal);
	const itemEditFilter = useSelector(state => state.items.itemEditFilter);
	const filterList = useSelector(state => state.items.filterList);
	// ОПЦИИ 
	const showOptionAdd = useSelector(state => state.items.showOptionAdd);
	const showOptionDel = useSelector(state => state.items.showOptionDel);
	const itemOptionsLoading = useSelector(state => state.items.itemOptionsLoading);
	const optionlist = useSelector(state => state.items.optionlist);
	const availableTypes = useSelector(state => state.items.availableTypes);
	const optionDelList = useSelector(state => state.items.optionDelList);
	const optionDelItem = useSelector(state => state.items.optionDelItem);
	const itemEditOption = useSelector(state => state.items.itemEditOption);

	const itemAddPhoto = useSelector(state => state.items.itemAddPhoto);
	const itemDel = useSelector(state => state.items.itemDel);
	const itemEdit = useSelector(state => state.items.itemEdit);

	const categoryList = useSelector(state => state.items.categoryList);
	const deleting = useSelector(state => state.items.deleting);
	const adding = useSelector(state => state.items.adding);
	const editing = useSelector(state => state.items.editing);

	const imgBtnDisable = useSelector(state => state.items.imgBtnDisable);
	const imgUploading = useSelector(state => state.items.imgUploading);

	const user = useSelector(state => state.auth.user);

	const itemTableLoading = useSelector(state => state.items.itemTableLoading);

	//RADIO STATES
	const [addradiovalues, setAddRadioValues] = React.useState({
		name: '',
		options: []
	});
	const [addoptionradiolist, setAddOptionRadioList] = React.useState({
		show: false
	});
	const [addradiotmpvalues, setAddRadioTmpValues] = React.useState({
		name: '',
	});


	const [valueItemsPerPage, setItemsPerPage] = React.useState({
		count: 5
	});
	const handlePaginationChange = (value) => {
		setItemsPerPage({ count: value });
	}

	const initialOptionState = {
		dummy: ""
	};
	const [valuesItemOption, setValuesItemOption] = React.useState({
		initialOptionState
	});


	const [optionError, setOptionError] = React.useState({
		show: false
	});

	const [editvalues, setEditValues] = React.useState({
		_id: itemEdit._id,
		name: itemEdit.name,
		sortorder: itemEdit.sortorder,
		sdescription: itemEdit.sdescription,
		description: itemEdit.description,
		category: [],
		price: itemEdit.price,
		base_price: itemEdit.price,
		extraId: itemEdit.extraId,
		extraField1: itemEdit.extraField1,
		extraField2: itemEdit.extraField2,
		image1: itemEdit.image1,
		image2: itemEdit.image2,
		image3: itemEdit.image3,
		image4: itemEdit.image4,
		url: itemEdit.url,
		hideprice: itemEdit.hideprice,
		minimum_options: itemEdit.minimum_options,
		meta_title: itemEdit.meta_title,
		meta_description: itemEdit.meta_description,
		canPayBonus: itemEdit.canPayBonus,
		gift: itemEdit.gift,
		sale: itemEdit.sale,
		excludeGift: itemEdit.excludeGift,

		energyAmount: itemEdit.energyAmount,
		carbohydrateAmount: itemEdit.carbohydrateAmount,
		fatAmount: itemEdit.fatAmount,
		fiberAmount: itemEdit.fiberAmount,
		recommended: itemEdit.recommended,
		useTemplate: itemEdit.useTemplate,
	});

	const [values, setValues] = React.useState({
		name: '',
		sortorder: 0,
		sdescription: '',
		description: '',
		category: [],
		price: '',
		base_price: '',
		extraId: '',
		extraField1: '',
		extraField2: '',
		image1: '',
		image2: '',
		image3: '',
		image4: '',
		url: '',
		hideprice: false,
		minimum_options: 0,
		canPayBonus: true,
		gift: false,
		meta_title: '',
		meta_description: '',
		sale: 0,
		excludeGift: false,


		energyAmount: 0,
		carbohydrateAmount: 0,
		fatAmount: 0,
		fiberAmount: 0,
		useTemplate: true,
	});

	const [valuesImg, setValuesImg] = React.useState({
		image1: '',
		image2: '',
		image3: '',
		image4: ''
	});

	const [filterStopList, setFilterStopList] = React.useState(false);

	const dispatch = useDispatch();


	useEffect(() => {
	}, [itemEditRadioOption]);


	const handleOpenAddModal = useCallback(() => dispatch(showAddItemModal()), [
		dispatch
	]);


	const handleCloseAddModal = item => {
		dispatch(closeAddItemModal());
		setValues({
			name: '',
			sortorder: 0,
			sdescription: '',
			description: '',
			category: [],
			price: '',
			base_price: '',
			extraId: '',
			extraField1: '',
			extraField2: '',
			image1: '',
			image2: '',
			image3: '',
			image4: '',
			url: '',
			hideprice: false,
			minimum_options: 0,
			canPayBonus: true,
			gift: false,
			meta_title: '',
			meta_description: '',
			sale: 0,
			excludeGift: false,
			energyAmount: 0,
			carbohydrateAmount: 0,
			fatAmount: 0,
			fiberAmount: 0,
			useTemplate: true,
		});
	};

	const showDeleteItemModal = item => {
		dispatch(showDelItemModal(item));
	};
	const showAddImageItemModal = item => {
		if (item.image1 != '') {
			setValuesImg(prevState => ({ ...prevState, ['image1']: serverIp + item.image1 }));
		}
		if (item.image2 != '') {
			setValuesImg(prevState => ({ ...prevState, ['image2']: serverIp + item.image2 }));
		}
		if (item.image3 != '') {
			setValuesImg(prevState => ({ ...prevState, ['image3']: serverIp + item.image3 }));
		}
		if (item.image4 != '') {
			setValuesImg(prevState => ({ ...prevState, ['image4']: serverIp + item.image4 }));
		}
		dispatch(showAddImgItemModal(item));
	};

	const handleCloseAddImageModal = () => {
		console.log(values.image1);
		dispatch(closeAddImgItemModal());
		setValuesImg({
			image1: '',
			image2: '',
			image3: '',
			image4: ''
		});
	};
	const handleCloseDelModal = useCallback(
		() => dispatch(closeDelItemModal()),
		[dispatch]
	);
	const handleCloseEditModal = useCallback(
		() => dispatch(closeEdItemModal()),
		[dispatch]
	);
	const handleAddItemOption = () => {
		switch (valuesItemOption.type) {
			case "toggle":
				if (!valuesItemOption.base_name || !valuesItemOption.second_name || !valuesItemOption.optionprice || !valuesItemOption.prefix) { //!valuesItemOption.extraOptionId
					setOptionError({ show: true });
				} else {
					setOptionError({ show: false });
					dispatch(addItemOption(valuesItemOption));
					resetOptions();
				}
				break;
			default:
		}
	};

	function resetOptions() {
		setValuesItemOption({ ...initialOptionState });
	};

	const handleDelItem = () => {
		dispatch(deleteItem(itemDel));
	};
	const handleEditItem = () => {
		// if (items.some(e => e.url === editvalues.url)) {
		// 	if (items.some(e => e._id === editvalues._id)) {
		// 		dispatch(editItem(editvalues));
		// 		setUrlError(false);
		// 	} else {
		// 		setUrlError(true);
		// 	}
		// } else {
		dispatch(editItem(editvalues));
		setUrlError(false);
		// }
	};

	const [urlError, setUrlError] = React.useState(false);

	const handleAddItem = () => {

		// if (items.some(e => e.url === values.url)) {

		// 	console.log('ЕСТЬ ТАКОЙ URL');
		// 	setUrlError(true);

		// } else {
		dispatch(addItem(values));
		setUrlError(false);
		setValues({
			name: '',
			sortorder: 0,
			sdescription: '',
			description: '',
			category: [],
			price: '',
			base_price: '',
			extraId: '',
			extraField1: '',
			extraField2: '',
			image1: '',
			image2: '',
			image3: '',
			image4: '',
			url: '',
			hideprice: false,
			minimum_options: 0,
			canPayBonus: true,
			gift: false,
			sale: 0,
			excludeGift: false,
			energyAmount: 0,
			carbohydrateAmount: 0,
			fatAmount: 0,
			fiberAmount: 0,
			useTemplate: true,
		});
		// }


	};

	// ЧЕКБОКСЫ 
	const [valuesItemOptionCheckbox, setValuesItemOptionCheckbox] = React.useState({
		initialOptionState
	});
	const showCheckboxOptionItemModal = item => {
		dispatch(showOpCheckboxItemModal(item));
	};
	const handleCloseOptCheckboxModal = () => {
		dispatch(closeOpCheckboxItemModal());
		setValuesItemOptionCheckbox({ ...initialOptionState });
	};
	// ----------------------------------------- //

	// RADIO 
	const [valuesItemOptionRadio, setValuesItemOptionRadio] = React.useState({
		initialOptionState
	});
	const [valuesRadioTmp, setValuesRadioTmp] = React.useState({
		name: "",
		prefix: "",
		optionprice: "",
		extraOptionId: "",
	});
	const [valuesRadioTmpShowInputs, setValuesRadioTmpShowInputs] = React.useState(false);
	const [showAddRadioChain, setShowAddRadioChain] = React.useState(false);

	const showRadioOptionItemModal = item => {
		dispatch(showOpRadioItemModal(item));
	};
	const handleCloseOptRadioModal = () => {
		dispatch(closeOpRadioItemModal());
		setValuesRadioTmpShowInputs(false);
		setShowAddRadioChain(false);
		setValuesItemOptionRadio({ ...initialOptionState });
	};

	function handleChangeRadioGroup(value) {
		var option_parent_id;
		var availableOptions;
		for (var i = 0; i < optionsradio.length; i++) {
			if (optionsradio[i].name == value) {
				option_parent_id = optionsradio[i]._id;
				availableOptions = optionsradio[i].options;
			}
		}
		//Сделать проверку на повторяющуюся опцию

		if (itemEditRadioOption.options.some(e => e.label === value)) {
			setValuesItemOptionRadio({ ...initialOptionState });
		} else {
			setValuesItemOptionRadio({ ...valuesItemOptionRadio, "is_minimum": false, "type": "radio", "label": value, "option_parent_id": option_parent_id, "type_name_selected": true, "availableOptions": availableOptions });
		}

	}
	function handleChangeRadioOption(value) {
		console.log(value);
		//добавим оъект во временный массив и покажем поля для этой опции в окне добавления
		setValuesRadioTmp({ ...valuesRadioTmp, "name": value })
		//Добавился
		setValuesRadioTmpShowInputs(true);
		console.log(valuesRadioTmp);
	}
	function handleChangeAddTmpRadioPrefix(value) {
		setValuesRadioTmp({ ...valuesRadioTmp, "prefix": value });
		console.log(valuesRadioTmp);
	}
	function handleChangeAddTmpRadioHide(value) {
		setValuesRadioTmp({ ...valuesRadioTmp, "hide": value });
		console.log(valuesRadioTmp);
	}
	const handleChangeAddTmpRadioPrice = name => event => {
		setValuesRadioTmp({ ...valuesRadioTmp, "optionprice": event.target.value });
		console.log(valuesRadioTmp);
	}
	const handleChangeAddTmpRadioExtraID = name => event => {
		setValuesRadioTmp({ ...valuesRadioTmp, "extraOptionId": event.target.value });
		console.log(valuesRadioTmp);
	}

	function handleAddRadioToItem() {
		// ТУТ КОМПАНУЕМ НОВУЮ ОПЦИЮ ДЛЯ ТЕКУЩЕГО ТОВАРА

		var newRadio = {
			option_parent_id: valuesItemOptionRadio.option_parent_id,
			type: "radio",
			label: valuesItemOptionRadio.label,
			is_minimum: valuesItemOptionRadio.is_minimum,
			availableOptions: [{
				name: valuesRadioTmp.name,
				prefix: valuesRadioTmp.prefix,
				optionprice: valuesRadioTmp.optionprice,
			}]
		};
		//itemEditRadioOption
		console.log('new radio');
		console.log(newRadio);
		//Чистим стейты
		setValuesRadioTmp({ name: "", prefix: "", optionprice: "" });
		setShowAddRadioChain(false);
		setValuesRadioTmpShowInputs(false);
		setValuesItemOptionRadio({ ...initialOptionState });
		dispatch(addRadioToItem(itemEditRadioOption._id, newRadio));
	}

	const handleDelRadioGroup = (optiongroup) => {
		console.log(optiongroup);
		console.log(itemEditRadioOption);
		dispatch(deleteRadioGroupFromItem(itemEditRadioOption, optiongroup));
	}
	// ----------------------------------------- //


	const showOptionItemModal = item => {
		dispatch(showOpItemModal(item));
	};
	const handleCloseOptModal = () => {
		dispatch(closeOpItemModal());
		setValuesItemOption({ ...initialOptionState });
	};
	const handleDeleteOption = (option) => {
		var optiontodelete = { "option_label": option.label, "option_parent_id": option.option_parent_id, "item_id": optionDelItem._id, "type": option.type };
		console.log(option);
		dispatch(delItemOption(optiontodelete));
	};
	const showOptionItemModalDel = item => {
		dispatch(showOpItemModalDel(item));
	};
	const handleCloseOptModalDel = () => {
		dispatch(closeOpItemModalDel());
	};
	const showEditItemModal = item => {
		setEditValues({
			...editvalues,
			_id: item._id,
			name: item.name,
			sortorder: item.sortorder,
			sdescription: item.sdescription,
			description: item.description,
			category: item.category,
			filters: item.filters,
			price: item.price,
			base_price: item.price,
			extraId: item.extraId,
			extraField1: item.extraField1,
			extraField2: item.extraField2,
			image: item.image,
			url: item.url,
			hideprice: item.hideprice,
			minimum_options: item.minimum_options,
			canPayBonus: item.canPayBonus,
			gift: item.gift,
			meta_title: item.meta_title,
			meta_description: item.meta_description,
			sale: item.sale,
			excludeGift: item.excludeGift,
			energyAmount: item.energyAmount,
			carbohydrateAmount: item.carbohydrateAmount,
			fatAmount: item.fatAmount,
			fiberAmount: item.fiberAmount,
			recommended: item.recommended
		});
		dispatch(showEdItemModal(item));
	};

	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};

	function handleChangeCatAdd(value) {
		setValues({ ...values, "category": value });
	}


	function handleChangeCatEdit(value) {
		setEditValues({ ...editvalues, "category": value });
	}

	//СТОП ЛИСТ
	const handleStopList = (item) => {
		dispatch(setStopList(item._id));
	};
	//Работа с изображениями
	const handleChangeImage = name => event => {
		let imageFormObj = new FormData();
		var imageToShow = URL.createObjectURL(event.target.files[0]);
		imageFormObj.append("id", itemAddPhoto._id);
		imageFormObj.append("imgNum", name);
		imageFormObj.append("imageData", event.target.files[0]);
		console.log(event.target.files[0]);
		console.log(imageFormObj);
		setValuesImg({ ...valuesImg, [name]: imageToShow });
		dispatch(sendItemImage(imageFormObj));
		//	setValues({ ...values, [name]: imageFormObj });
	};

	const dummyRequest = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}
	const handleChangeImage1 = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, imageUrl => {
				let imageFormObj = new FormData();
				var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", itemAddPhoto._id);
				imageFormObj.append("imgNum", "image1");
				imageFormObj.append("imageData", info.file.originFileObj);
				setValuesImg({ ...valuesImg, "image1": imageToShow });
				dispatch(sendItemImage(imageFormObj));
			});
		}
	};
	const handleChangeImage2 = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, imageUrl => {
				let imageFormObj = new FormData();
				var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", itemAddPhoto._id);
				imageFormObj.append("imgNum", "image2");
				imageFormObj.append("imageData", info.file.originFileObj);
				setValuesImg({ ...valuesImg, "image2": imageToShow });
				dispatch(sendItemImage(imageFormObj));
			});
		}
	};
	const handleChangeImage3 = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, imageUrl => {
				let imageFormObj = new FormData();
				var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", itemAddPhoto._id);
				imageFormObj.append("imgNum", "image3");
				imageFormObj.append("imageData", info.file.originFileObj);
				setValuesImg({ ...valuesImg, "image3": imageToShow });
				dispatch(sendItemImage(imageFormObj));
			});
		}
	};
	const handleChangeImage4 = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, imageUrl => {
				let imageFormObj = new FormData();
				var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", itemAddPhoto._id);
				imageFormObj.append("imgNum", "image4");
				imageFormObj.append("imageData", info.file.originFileObj);
				setValuesImg({ ...valuesImg, "image4": imageToShow });
				dispatch(sendItemImage(imageFormObj));
			});
		}
	};

	const handleImageDelete = (img, item) => event => {
		if (img == 'image1') {
			setValuesImg(prevState => ({ ...prevState, ['image1']: '' }));
		}
		if (img == 'image2') {
			setValuesImg(prevState => ({ ...prevState, ['image2']: '' }));
		}
		if (img == 'image3') {
			setValuesImg(prevState => ({ ...prevState, ['image3']: '' }));
		}
		if (img == 'image4') {
			setValuesImg(prevState => ({ ...prevState, ['image4']: '' }));
		}
		console.log(item);
		var imageObj = {
			"id": item,
			"imgNum": img
		}
		dispatch(sendItemImageDelete(imageObj));
	};

	const handleEditChange = name => event => {
		setEditValues({ ...editvalues, [name]: event.target.value });
	};

	function handleEditChangeSale(value) {
		setEditValues({ ...editvalues, "sale": value });
	}

	function handleChangeSale(value) {
		setValues({ ...values, "sale": value });
	}


	const handleEditHidePriceChange = (e, from) => {
		if (e.target.name == 'hideprice') {
			setEditValues({ ...editvalues, "hideprice": e.target.checked });
		}
	}
	const handleEditBonusPayChange = (e, from) => {
		if (e.target.name == 'canPayBonus') {
			setEditValues({ ...editvalues, "canPayBonus": e.target.checked });
		}
	}




	const handleAddHidePriceChange = (e, from) => {
		if (e.target.name == 'hideprice') {
			setValues({ ...values, "hideprice": e.target.checked });
		}
	}

	const handleAddBonusPayChange = (e, from) => {
		if (e.target.name == 'canPayBonus') {
			setValues({ ...values, "canPayBonus": e.target.checked });
		}
	}

	const handleAddGiftChange = (e, from) => {
		if (e.target.name == 'gift') {
			setValues({ ...values, "gift": e.target.checked });
		}
	}

	const handleAddExcludeGiftChange = (e, from) => {
		if (e.target.name == 'excludeGift') {
			setValues({ ...values, "excludeGift": e.target.checked });
		}
	}

	const handleAddUseTemplateChange = (e, from) => {
		if (e.target.name == 'useTemplate') {
			setValues({ ...values, "useTemplate": e.target.checked });
		}
	}




	const handleEditGiftChange = (e, from) => {
		if (e.target.name == 'gift') {
			setEditValues({ ...editvalues, "gift": e.target.checked });
		}
	}

	const handleEditExcludeGiftChange = (e, from) => {
		if (e.target.name == 'excludeGift') {
			setEditValues({ ...editvalues, "excludeGift": e.target.checked });
		}
	}

	const handleEditUseTemplateChange = (e, from) => {
		if (e.target.name == 'useTemplate') {
			setEditValues({ ...editvalues, "useTemplate": e.target.checked });
		}
	}




	function handleChangeAddType(value) {
		if (value == 1) {
			setValuesItemOption({ ...valuesItemOption, "type_id": 1, "type": "toggle", "item_id": itemEditOption._id });
		}
	}
	function handleChangeAddTypeName(value) {
		var option_parent_id;
		for (var i = 0; i < optionlist.length; i++) {
			if (optionlist[i].name == value) {
				option_parent_id = optionlist[i]._id;
			}
		}
		setValuesItemOption({ ...valuesItemOption, "label": value, "option_parent_id": option_parent_id, "type_name_selected": true });
	}
	function handleChangeAddTogglePrefix(value) {
		setValuesItemOption({ ...valuesItemOption, "prefix": value });
	}

	const handleChangeAddToggleName = name => event => {
		setValuesItemOption({ ...valuesItemOption, [name]: event.target.value });
	};

	const uploadButton = (
		<div>
			<Icon type='plus' />
			<div className="ant-upload-text">Загрузить</div>
		</div>
	);
	function selectValues() {
		return (
			values.category.map((cat, index) => (
				<span key={index} >{cat.name}</span>
			))
		);
	}


	const showEditFilterItemModal = item => {
		setEditValues({
			...editvalues,
			_id: item._id,
			name: item.name,
			sdescription: item.sdescription,
			description: item.description,
			category: item.category,
			filters: item.filters,
			price: item.price,
			base_price: item.price,
			extraId: item.extraId,
			extraField1: item.extraField1,
			extraField2: item.extraField2,
			image: item.image,
			url: item.url,
			hideprice: item.hideprice,
			minimum_options: item.minimum_options,
			canPayBonus: item.canPayBonus,
			gift: item.gift,
			meta_title: item.meta_title,
			meta_description: item.meta_description,
			sale: item.sale,
			excludeGift: item.excludeGift,
			energyAmount: item.energyAmount,
			carbohydrateAmount: item.carbohydrateAmount,
			fatAmount: item.fatAmount,
			fiberAmount: item.fiberAmount,

		});
		dispatch(showEditFilter(item));
	};
	const closeEditFilterItemModal = () => {
		dispatch(closeEditFilter());
	};
	function handleChangeFilterEdit(value) {
		setEditValues({ ...editvalues, "filters": value });
	}
	const handleEditFilter = () => {
		dispatch(editItem(editvalues));
	};

	function renderEditFilter() {
		return (
			<>
				<Typography style={{ textAlign: 'left' }}>Выберите по каким фильтрам можно найти товар - {itemEditFilter.name}:</Typography>
				<Select
					mode="multiple"
					value={editvalues.filters}
					onChange={handleChangeFilterEdit}
					style={{ width: '100%', marginBottom: 10 }}
				>
					{filterList.map((filter, i) => (
						<Option key={filter.name} value={filter.name}>{filter.name}</Option>
					))}
				</Select>
			</>
		)
	}

	function renderOptionsDel() {
		return (
			<div>
				{optionDelList.map((optionlist, i) => (
					<div key={i} style={{ marginBottom: 20, }}>
						<Row gutter={16} style={{ borderBottom: '1px solid #eeeeee' }}>
							<Col className="gutter-row" xs={12}>
								<p>{optionlist.label}</p>
							</Col>
							<Col className="gutter-row" xs={12}>
								<Button type="danger" disabled={deleting} icon="delete" onClick={() => handleDeleteOption(optionlist)}>
									Удалить
								</Button>
							</Col>
						</Row>
					</div>
				))}
			</div>
		);
	}


	///////////////////	ЦЕПОЧКА RADIO
	function renderOptionsRadioAdd() {
		if (itemRadioOptionsLoading) {
			return (
				<div>
					<p style={{ textAlign: 'center' }}>Загружаем актуальные группы опций товаров</p>
					<Divider className="header-div" orientation="center"><Spin /></Divider>
				</div>
			)
		} else {
			return (
				// 
				<div>
					{renderCurrentRadios()}
					{renderAddRadios()}
				</div>
			)
		}
	}
	function renderCurrentRadios() {
		var currentList = itemEditRadioOption.options;
		//console.log(currentList);
		if (currentList) {
			currentList = currentList.filter(function (optionfilter) {
				return (
					optionfilter.type
						.toLowerCase()
						.search('radio') !== -1
				);
			});
			if (currentList.length > 0) {
				return (
					<div>
						{renderCurrentAddedRadios(currentList)}
					</div>
				)
			} else {
				return (
					<div style={{ marginTop: 10, marginBottom: 10 }}>
						<span>У данного товара нет подключенных опций данного вида</span>
					</div>
				)
			}
		}

	}

	const [valuesRadioToCurrent, setValuesRadioToCurrent] = React.useState({
		show: false,
		optiongroup: {},
		rowtoadd: {},
	});
	const [valuesRadioToCurrentRow, setValuesRadioToCurrentRow] = React.useState(false);

	const setShowAddRadioToCurrent = (option) => {
		setValuesRadioToCurrent({
			show: true,
			optiongroup: option,
			rowtoadd: {
				hide: false,
			},
		})
	}

	function handleChangeRadioAddToCurrent(value) {
		setValuesRadioToCurrentRow(true);
		setValuesRadioToCurrent({ ...valuesRadioToCurrent, rowtoadd: { name: value } })
	}

	function handleChangeAddToCurrentPrefix(value) {
		setValuesRadioToCurrent({ ...valuesRadioToCurrent, rowtoadd: { ...valuesRadioToCurrent.rowtoadd, prefix: value } });
	}

	function handleChangeAddToCurrentHide(value) {
		setValuesRadioToCurrent({ ...valuesRadioToCurrent, rowtoadd: { ...valuesRadioToCurrent.rowtoadd, hide: value } });
	}

	const handleAddRowToCurrent = () => {
		setValuesRadioToCurrentRow(false);
		dispatch(addRowToCurrent(itemEditRadioOption, valuesRadioToCurrent));
	}

	const handleDelRadioGroupRow = (optiongroup, optionrow) => {
		console.log(optiongroup);
		console.log(optionrow);
		setValuesRadioToCurrentRow(false);
		dispatch(delRowToCurrent(itemEditRadioOption, optiongroup, optionrow));
	}

	function renderCurrentAddedRadios(optionsArr) {
		return (
			<>
				<p>Добавленные группы и их опции:</p>
				<div style={{ textAlign: 'left' }}>
					{optionsArr.map((optiongroup, i) => (
						<>
							<span style={{ fontWeight: 'bold' }}>Группа: {optiongroup.label}</span>
							<Popconfirm title="Удалить группу?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelRadioGroup(optiongroup)}>
								<span style={{ float: 'right', textDecoration: 'underline' }}>удалить группу</span>
							</Popconfirm>
							<br />
							{optiongroup.availableOptions.map((option, x) => (
								<div key={x} style={{ width: '100%', borderBottom: '1px solid grey' }}>
									<span>- {option.name}</span>
									<span> {option.prefix} </span>
									<span> {option.optionprice} </span>
									<Popconfirm title="Удалить?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDelRadioGroupRow(optiongroup, option)}>
										<span style={{ float: 'right' }}>удалить</span>
									</Popconfirm>
									<br />
								</div>
							))}
							<div style={{ textAlign: 'center' }}><span onClick={() => setShowAddRadioToCurrent(optiongroup)}>добавить опцию к группе</span></div>
							{valuesRadioToCurrent.show && valuesRadioToCurrent.optiongroup == optiongroup ? renderAddToCurrent() : (<></>)}
							<br /><br />
						</>
					))}
				</div>
			</>
		);
	}



	function renderAddToCurrent() {

		console.log(valuesRadioToCurrent.optiongroup);
		var selectedGroup = {};
		for (var i = 0; i < optionsradio.length; i++) {
			if (optionsradio[i]._id == valuesRadioToCurrent.optiongroup.option_parent_id) {
				selectedGroup = optionsradio[i];
			}
		}
		console.log('selected');
		console.log(selectedGroup);

		return (
			<>
				<p style={{ textAlign: 'center' }}>Выберите доступную опцию:</p>
				<Select defaultValue="" style={{ width: '100%', marginBottom: '1em' }} onChange={handleChangeRadioAddToCurrent}>
					{selectedGroup.options.map(op => {
						if (!valuesRadioToCurrent.optiongroup.availableOptions.some(e => e.name === op.name)) {
							return (
								<Option key={op.name} value={op.name}>{op.name}</Option>
							);
						} else {
						}
					}
					)}
				</Select>
				{valuesRadioToCurrentRow ? renderAddToCurrentInputs()
					: (
						<></>
					)}
			</>
		)

	}

	function renderAddToCurrentInputs() {
		return (
			<>
				<p style={{ textAlign: 'center' }}>Укажите параметры опции:</p>
				<p style={{ textAlign: 'left', fontWeight: 600 }}>Скрывать опцию в панели управления? ( пустая опция )</p>
				<Select defaultValue={false} value={valuesRadioToCurrent.rowtoadd.hide} style={{ width: '100%', marginBottom: '1em' }} placeholder="Скрывать" onChange={handleChangeAddToCurrentHide}>
					<Option value={false}>Нет</Option>
					<Option value={true}>Да</Option>
				</Select>
				<p style={{ textAlign: 'left', fontWeight: 600 }}>Префикс цены</p>
				<Select defaultValue="" value={valuesRadioToCurrent.rowtoadd.prefix} style={{ width: '100%', marginBottom: '1em' }} placeholder="Префикс" onChange={handleChangeAddToCurrentPrefix}>
					<Option value="+">+</Option>
					<Option value="-">-</Option>
				</Select>
				<p style={{ textAlign: 'left', fontWeight: 600 }}>Стоимость опции:</p>
				<Input style={{ marginBottom: 10 }} type="number" value={valuesRadioToCurrent.rowtoadd.optionprice} onChange={(e) => setValuesRadioToCurrent({ ...valuesRadioToCurrent, rowtoadd: { ...valuesRadioToCurrent.rowtoadd, optionprice: e.target.value } })} />
				<p style={{ textAlign: 'left', fontWeight: 600 }}>ID для интеграции:</p>
				<Input style={{ marginBottom: 10 }} addonBefore="ID опции" value={valuesRadioTmp.extraOptionId} onChange={(e) => setValuesRadioToCurrent({ ...valuesRadioToCurrent, rowtoadd: { ...valuesRadioToCurrent.rowtoadd, extraOptionId: e.target.value } })} />
				{valuesRadioToCurrent.rowtoadd.prefix && valuesRadioToCurrent.rowtoadd.optionprice ? (<div style={{ width: '100%', textAlign: 'center' }}><Button style={{ marginTop: 10 }} type="primary" onClick={() => handleAddRowToCurrent()}>Добавить</Button></div>) : (<></>)}
			</>
		)
	}

	function renderAddRadios() {
		if (showAddRadioChain) {
			return (
				<>
					<p style={{ textAlign: 'center' }}>Выберите доступную группу:</p>
					<Select defaultValue="" style={{ width: '100%', marginBottom: '1em' }} placeholder="Выберите добавляемую группу" onChange={handleChangeRadioGroup}>
						{optionsradio.map((radiogroup, i) => (
							<Option key={i} value={radiogroup.name}>{radiogroup.name}</Option>
						))}
					</Select>
					{renderAddRadiosGroup()}
				</>
			);
		} else {
			return (
				<>
					<Button style={{ marginTop: 20 }} type="primary" onClick={() => setShowAddRadioChain(true)}>Добавить новую группу</Button>
				</>
			);
		}

	}

	function renderAddRadiosGroup() {
		if (valuesItemOptionRadio.availableOptions) {
			return (
				<>
					<Checkbox name={"is_minimum"} checked={valuesItemOptionRadio.is_minimum} onChange={(e) => setValuesItemOptionRadio({ ...valuesItemOptionRadio, is_minimum: e.target.checked })}>Эта группа учитывается при минимальном кол-ве опций для заказа?</Checkbox>
					<p style={{ textAlign: 'center' }}>Выберите доступную опцию:</p>
					<Select defaultValue="" style={{ width: '100%', marginBottom: '1em' }} onChange={handleChangeRadioOption}>
						{valuesItemOptionRadio.availableOptions.map(op => (
							<Option key={op.name} value={op.name}>{op.name}</Option>
						))}
					</Select>
					{valuesRadioTmpShowInputs ? renderAddRadiosInputs()
						: (
							<></>
						)}
				</>
			)

		}

	}

	function renderAddRadiosInputs() {
		return (
			<>

				{/* <Checkbox name={"is_minimum"} checked={valuesItemOptionRadio.is_minimum} onChange={(e) => setValuesItemOptionRadio({is_minimum: e.target.checked})}>Эта группа учитывается при минимальном кол-ве опций для заказа?</Checkbox> */}

				<p style={{ textAlign: 'center' }}>Укажите параметры опции:</p>
				<p style={{ textAlign: 'left', fontWeight: 600 }}>Скрывать опцию в панели управления? ( пустая опция )</p>
				<Select defaultValue={false} value={valuesRadioTmp.hide} style={{ width: '100%', marginBottom: '1em' }} placeholder="Скрывать" onChange={handleChangeAddTmpRadioHide}>
					<Option value={false}>Нет</Option>
					<Option value={true}>Да</Option>
				</Select>
				<p style={{ textAlign: 'left', fontWeight: 600 }}>Префикс цены</p>
				<Select defaultValue="" value={valuesRadioTmp.prefix} style={{ width: '100%', marginBottom: '1em' }} placeholder="Префикс" onChange={handleChangeAddTmpRadioPrefix}>
					<Option value="+">+</Option>
					<Option value="-">-</Option>
				</Select>
				<p style={{ textAlign: 'left', fontWeight: 600 }}>Стоимость опции:</p>
				<Input style={{ marginBottom: 10 }} type="number" value={valuesRadioTmp.optionprice} onChange={handleChangeAddTmpRadioPrice("optionprice")} />
				<p style={{ textAlign: 'left', fontWeight: 600 }}>ID для интеграции:</p>
				<Input style={{ marginBottom: 10 }} addonBefore="ID опции" value={valuesRadioTmp.extraOptionId} onChange={handleChangeAddTmpRadioExtraID("extraOptionId")} />
				{valuesRadioTmp.optionprice != "" ? (<><Button type="primary" onClick={handleAddRadioToItem}>Добавить</Button></>) : (<></>)}
			</>
		)
	}
	///////////////////	ЦЕПОЧКА ЧЕКБОКСОВ
	function renderOptionsCheckboxAdd() {

		if (itemCheckboxOptionsLoading) {
			return (
				<div>
					<p style={{ textAlign: 'center' }}>Загружаем актуальные группы опций товаров</p>
					<Divider className="header-div" orientation="center"><Spin /></Divider>
				</div>
			)
		} else {
			return (
				<div>

					{optionscheckbox.map((checkbox, i) => (
						<div key={i}>{checkbox.name}</div>
					))}
				</div>
			)
		}
	}

	///////////////////	ЦЕПОЧКА ОПЦИЙ
	function renderOptionsAdd() {
		if (itemOptionsLoading) {
			return (
				<div>
					<p style={{ textAlign: 'center' }}>Загружаем актуальные опции товаров</p>
					<Divider className="header-div" orientation="center"><Spin /></Divider>
				</div>
			);
		} else {
			return (
				<div>
					<p style={{ textAlign: 'center', fontWeight: 600 }}>{itemEditOption.name}</p>
					<p style={{ textAlign: 'center' }}>Выберите тип добавляемой опции:</p>
					<Select defaultValue="" style={{ width: '100%', marginBottom: '1em' }} placeholder="Выберите тип добавляемой опции" onChange={handleChangeAddType}>
						{availableTypes.map((avtype, i) => (
							<Option key={i} value={avtype.id}>{avtype.name}</Option>
						))}
					</Select>
					{renderOptionsTypeAdd()}
				</div>
			);
		}
	}

	function renderOptionsTypeAdd() {
		switch (valuesItemOption.type) {
			case "toggle":
				var availableListToggle = optionlist;
				availableListToggle = availableListToggle.filter(function (availableToggle) {
					return (
						availableToggle.type === "toggle"
					);
				});
				console.log(availableListToggle);
				return (
					<div>
						<p style={{ textAlign: 'center' }}>Выберите доступную опцию:</p>
						<Select defaultValue="" style={{ width: '100%', marginBottom: '1em' }} placeholder="Выберите добавляемую опцию" onChange={handleChangeAddTypeName}>
							{availableListToggle.map((avtoggle, y) => (
								<Option key={avtoggle._id} value={avtoggle.name}>{avtoggle.name}</Option>
							))}
						</Select>
						{renderOptionsAddValues()}
					</div>
				);
				break;
			default:

		}
	}

	function renderOptionsAddValues() {
		if (valuesItemOption.type_name_selected === true) {
			switch (valuesItemOption.type) {
				case "toggle":
					return (
						<div>
							<Row gutter={16}>
								<Col className="gutter-row" xs={24} sm={12}>
									<p style={{ textAlign: 'center', fontWeight: 600 }}>Базовое значение</p>
									<Input style={{ marginBottom: 10 }} addonBefore="Название" value={valuesItemOption.base_name} onChange={handleChangeAddToggleName("base_name")} />
									<Input style={{ marginBottom: 10 }} addonBefore="Extra" value={valuesItemOption.base_name_extra} onChange={handleChangeAddToggleName("base_name_extra")} />
								</Col>
								<Col className="gutter-row" xs={24} sm={12}>
									<p style={{ textAlign: 'center', fontWeight: 600 }}>Выбираемое значение</p>
									<Input style={{ marginBottom: 10 }} addonBefore="Название" value={valuesItemOption.second_name} onChange={handleChangeAddToggleName("second_name")} />
									<Input style={{ marginBottom: 10 }} addonBefore="Extra" value={valuesItemOption.second_name_extra} onChange={handleChangeAddToggleName("second_name_extra")} />
									<p style={{ textAlign: 'center', fontWeight: 600 }}>Префикс цены</p>
									<Select defaultValue="+" value={valuesItemOption.prefix} style={{ width: '100%', marginBottom: '1em' }} placeholder="Префикс" onChange={handleChangeAddTogglePrefix}>
										<Option value="+">+</Option>
										<Option value="-">-</Option>
									</Select>
									<Input style={{ marginBottom: 10 }} type="number" addonBefore="Сумма" value={valuesItemOption.optionprice} onChange={handleChangeAddToggleName("optionprice")} />
									<Input style={{ marginBottom: 10 }} addonBefore="ID опции" value={valuesItemOption.extraOptionId} onChange={handleChangeAddToggleName("extraOptionId")} />

								</Col>
							</Row>

							<Col className="gutter-row" xs={24} sm={24}>
								<div>
									{optionError.show ? (
										<p style={{ color: 'red' }}>Не заполнены необходимые поля</p>
									) : (
										<p></p>
									)}
								</div>
								<Button
									key="yes"
									type="primary"
									disabled={adding}
									onClick={handleAddItemOption}
								>
									Добавить
								</Button>
							</Col>


						</div>
					);
					break;

				default:

			}
		}
	}

	///////////////////

	function checkManager(item) {
		for (var i = 0; i < item.category.length; i++) {
			for (var y = 0; y < categoryList.length; y++) {
				if (categoryList[y].name == item.category[i] && categoryList[y].canEdit == 1) {
					return true;
				}
			}
		}
		return false;
	}

	function renderItemInfo(item) {
		return (
			<div key={item.id}>
				<Row gutter={16}>
					<Col className="gutter-row" xs={24} sm={12}>
						<span><b>Базовая цена:</b></span><br />
						<span>{item.price}</span><br />

						<br />
						<span><b>ID для интеграции:</b></span><br />
						<span>{item.extraId}</span><br />

						<br />
						<span><b>Категории:</b></span><br />
						{item.category.map((itemcategory, i) => (
							<Tag key={i}>{itemcategory}</Tag>
						))}
						<br />
						<br />
						{/* "name": "27 см",
                    "prefix": "+",
                    "optionprice": NumberInt("0") */}
						<span><b>Опции "Радио":</b></span><br />
						{item.options.map(function (option_type, i) {
							if (option_type.type == "radio") {
								return (
									<div key={i} style={{ marginBottom: 10 }}>
										{option_type.availableOptions.map(avop => {
											return (
												<>
													<span>{avop.name}</span><br />
													<span>Цена: {avop.prefix}{avop.optionprice}</span><br /></>
											)
										})}

									</div>
								);
							}
						})}
						<br />

						<span><b>Опции "Переключатель":</b></span><br />
						{item.options.map(function (option_type, i) {
							if (option_type.type == "simple_toggle") {
								return (
									<div key={i} style={{ marginBottom: 10 }}>
										<span>{option_type.label}:</span><span> {option_type.base_name}</span><br />
										<span>Цена: {option_type.prefix}{option_type.optionprice}</span><br />
										<span>ID: {option_type.extraOptionId}</span>
									</div>
								);
							}
						})}
						<br />
						<br />
						<span><b>URL на сайте:</b></span><br />
						<span>{item.url}</span><br /><br />
						<span><b>META Title:</b></span><br />
						<span>{item.meta_title}</span><br /><br />
						<span><b>META Description:</b></span><br />
						<span>{item.meta_description}</span><br /><br />
						<span><b>Скидка:</b></span><br />
						<span>{item.sale}%</span><br /><br />
						<span><b>Игнорирует сумму корзины для подарка:</b></span><br />
						<span>{item.excludeGift ? (<>Да</>) : (<>Нет</>)}</span><br /><br />

						<span><b>ккал:</b></span><br />
						<span>{item.energyAmount}</span><br />
						<span><b>белки:</b></span><br />
						<span>{item.fiberAmount}</span><br />
						<span><b>жиры:</b></span><br />
						<span>{item.fatAmount}</span><br />

						<span><b>углеводы:</b></span><br />
						<span>{item.carbohydrateAmount}</span><br />
						<br />

					</Col>
					<Col className="gutter-row" xs={24} sm={12}>

						<br />
						<span><b>Краткое описание:</b></span><br />
						<span>{item.sdescription}</span><br />

						<br />
						<span><b>Полное описание:</b></span><br />
						<span>{item.description}</span><br />

						<br />
						<span><b>Изображения:</b></span><br />
						{(item.image1 != '') ? (
							<Avatar size={72} alt={item.name} src={serverIp + item.image1} shape="square" style={{ borderRadius: 4, marginRight: 10, marginBottom: 10 }} />
						) : (
							<div></div>
						)}
						{(item.image2 != '') ? (
							<Avatar size={72} alt={item.name} src={serverIp + item.image2} shape="square" size="large" style={{ borderRadius: 4, marginRight: 10, marginBottom: 10 }} />
						) : (
							<div></div>
						)}
						{(item.image3 != '') ? (
							<Avatar size={72} alt={item.name} src={serverIp + item.image3} shape="square" size="large" style={{ borderRadius: 4, marginRight: 10, marginBottom: 10 }} />
						) : (
							<div></div>
						)}
						{(item.image4 != '') ? (
							<Avatar size={72} alt={item.name} src={serverIp + item.image4} shape="square" size="large" style={{ borderRadius: 4, marginRight: 10, marginBottom: 10 }} />
						) : (
							<div></div>
						)}

						<br />
						<span><b>Фильтры:</b></span><br />
						{item.filters.map(function (filter, i) {
							return (
								<Tag key={i}>{filter}</Tag>
							);

						})}
						<br />
						<span><b>Скрыть цену:</b></span><br />
						{item.hideprice ? (<>Да</>) : (<>Нет</>)}
						<br />
						<br />
						<span><b>Подарок:</b></span><br />
						{item.gift ? (<>Да</>) : (<>Нет</>)}
						<br />
						<br />

						<span><b>Минимум опций для покупки:</b></span><br />
						{item.minimum_options == 0 ? (<>Нет</>) : (<>{item.minimum_options}</>)}
						<br />
						<br />
						<span><b>Можно платить бонусами?</b></span><br />
						{item.canPayBonus ? (<>Да</>) : (<>Нет</>)}
						<br />
						<br />
						<span><b>Рекомендованные товары:</b></span><br />
						{item.recommended ? (<>
							{item.recommended.map((rec, i) => (
								<Tag key={i}>{rec.name}</Tag>
							))}
						</>) : (<></>)}
						<br />
						<br />
						<span><b>Использует шаблонные META?</b></span><br />
						{item.useTemplate ? (<>
							<Tag>ДА</Tag>
						</>) : (<><Tag>НЕТ</Tag></>)}

						<br />
						<br />
						<br />

					</Col>
				</Row>

				{user.role == 'admin' || user.role == 'owner' || user.role == 'manager' || user.role == 'operator' ? (

					<Row gutter={16}>

						{user.role == 'admin' || user.role == 'owner' ? (
							<>
								<Col className="gutter-row" xs={24} sm={24}>
									<Divider style={{ marginTop: 2, marginBottom: 15 }} />
								</Col>
								<Col className="gutter-row" xs={24} sm={12}>
									<span><b>Опции:</b></span><br />
									{/* <Button style={{ marginRight: 5 }} size="small" type="dashed" icon="plus" onClick={() => showCheckboxOptionItemModal(item)}>ГРУППА ОПЦИЙ</Button> */}
									<Button style={{ marginRight: 5 }} size="small" type="dashed" icon="plus" onClick={() => showRadioOptionItemModal(item)}>RADIO ОПЦИИ</Button>
									<Button style={{ marginRight: 5 }} size="small" type="dashed" icon="plus" onClick={() => showOptionItemModal(item)}>ОПЦИЯ ПЕРЕКЛЮЧАТЕЛЬ</Button>
									<Button style={{ marginRight: 5 }} size="small" type="danger" ghost icon="delete" onClick={() => showOptionItemModalDel(item)}>УДАЛИТЬ ОПЦИИ</Button>
									<br />
								</Col>
								<Col className="gutter-row" xs={24} sm={12}>
									<span><b>Управление товаром:</b></span><br />
									<Button style={{ marginRight: 5 }} size="small" type="dashed" icon="edit" onClick={() => showEditItemModal(item)}>РЕДАКТИРОВАТЬ</Button>
									<Button style={{ marginRight: 5 }} size="small" type="dashed" icon="picture" onClick={() => showAddImageItemModal(item)}>ДОБАВИТЬ ИЗОБРАЖЕНИЕ</Button>
									<Button style={{ marginRight: 5 }} size="small" type="danger" ghost icon="delete" onClick={() => showDeleteItemModal(item)}>УДАЛИТЬ ТОВАР</Button>
									<br />
								</Col>
								<Col className="gutter-row" style={{ marginTop: 20 }} xs={24} sm={12}>
									<span><b>Фильтры:</b></span><br />
									<Button style={{ marginRight: 5 }} size="small" type="dashed" icon="edit" onClick={() => showEditFilterItemModal(item)}>РЕДАКТИРОВАТЬ ФИЛЬТРЫ</Button>
									<br />
								</Col>
							</>
						) : (<></>)}

						<Col className="gutter-row" style={{ marginTop: 20 }} xs={24} sm={12}>
							<span><b>Стоп лист:</b></span><br />
							{item.stoplist ? (<><Button style={{ marginRight: 5 }} size="small" type="dashed" onClick={() => handleStopList(item)}>УБРАТЬ ИЗ СТОП ЛИСТА</Button></>) : (<><Button style={{ marginRight: 5 }} size="small" type="dashed" onClick={() => handleStopList(item)}>ДОБАВИТЬ В СТОП ЛИСТ</Button></>)}
							<br />
						</Col>

						{user.role == 'manager' ? (
							<>
								{checkManager(item) ? (
									<Col className="gutter-row" style={{ marginTop: 20 }} xs={24} sm={24}>
										<span><b>Управление товаром:</b></span><br />
										<Button style={{ marginRight: 5 }} size="small" type="dashed" icon="plus" onClick={() => showRadioOptionItemModal(item)}>RADIO ОПЦИИ</Button>
										<Button style={{ marginRight: 5 }} size="small" type="dashed" icon="plus" onClick={() => showOptionItemModal(item)}>ОПЦИЯ ПЕРЕКЛЮЧАТЕЛЬ</Button>
										<Button style={{ marginRight: 5 }} size="small" type="danger" ghost icon="delete" onClick={() => showOptionItemModalDel(item)}>УДАЛИТЬ ОПЦИИ</Button>
										<Button style={{ marginRight: 5 }} size="small" type="dashed" icon="edit" onClick={() => showEditItemModal(item)}>РЕДАКТИРОВАТЬ</Button>
										<Button style={{ marginRight: 5 }} size="small" type="dashed" icon="picture" onClick={() => showAddImageItemModal(item)}>ДОБАВИТЬ ИЗОБРАЖЕНИЕ</Button>
										<Button style={{ marginRight: 5 }} size="small" type="danger" ghost icon="delete" onClick={() => showDeleteItemModal(item)}>УДАЛИТЬ ТОВАР</Button>
										<Button style={{ marginRight: 5 }} size="small" type="dashed" icon="edit" onClick={() => showEditFilterItemModal(item)}>РЕДАКТИРОВАТЬ ФИЛЬТРЫ</Button>
										<br />
									</Col>
								) : (<></>)}
							</>
						) : (<></>)}



						<Col className="gutter-row" xs={24} sm={24}>
							<Divider />
						</Col>
					</Row>

				) : (<></>)}

			</div>
		);
	}
	const [searchvalue, setSearchValue] = React.useState({
		search: ""
	});
	const [categoryFilterValue, setCategoryFilterValue] = React.useState({
		name: ""
	});
	const handleSearchChange = name => event => {
		setSearchValue({ ...searchvalue, [name]: event.target.value });
	};
	const handleCategoryFilterChange = (value) => {
		console.log(value);
		setCategoryFilterValue({ ...categoryFilterValue, "name": value });
	};


	let shownItems = [];

	const moveRow = (dragIndex, hoverIndex) => {
		console.log('moveRow');
		console.log(shownItems);
		console.log(valueItemsPerPage.count);
		console.log(dragIndex);
		console.log(hoverIndex);

		dispatch(sortItems(shownItems[dragIndex]._id, shownItems[hoverIndex]._id));

		// const tmp = shownItems[dragIndex];
		// shownItems[dragIndex] = shownItems[hoverIndex];
		// shownItems[hoverIndex] = tmp;
		// console.log(shownItems);
	};

	function renderSort() {

		const components = {
			body: {
				row: DragableBodyRow,
			},
		};


		const columns = [
			{
				title: 'Название', dataIndex: 'name', key: 'name',
				sorter: (a, b) => { return a.name.localeCompare(b.name) },
				sortDirections: ['ascend'],
			},
			{
				title: 'Категории', dataIndex: 'category', key: 'category',
				render: tags => {
					return (
						<>
							{tags.map((taga, i) => (
								<Tag key={i}>{taga}</Tag>
							))}
						</>
					);
				}
			},
			{
				title: 'Доступность', dataIndex: 'stoplist', key: 'stoplist',
				render: (stoplistvalue, row) => {
					if (stoplistvalue) {
						return (
							<>
								<Tag onClick={() => handleStopList(row)}><Icon style={{ color: 'red' }} type="stop" /></Tag>
							</>
						);
					} else {
						return (
							<>
								<Tag onClick={() => handleStopList(row)}><Icon style={{ color: 'green' }} type="check" /></Tag>
							</>
						);
					}
				}
			},
			{
				title: 'Скидка', dataIndex: 'sale', key: 'sale',
				render: sale => {

					return (
						<>
							{sale}%
						</>
					);

				}
			},
			{
				title: 'Оплата бонусами', dataIndex: 'canPayBonus', key: 'canPayBonus',
				render: canPayBonus => {
					if (canPayBonus) {
						return (
							<>
								<Icon style={{ color: 'green' }} type="check" />
							</>
						);
					} else {
						return (
							<>
								<Icon style={{ color: 'red' }} type="stop" />
							</>
						);
					}
				}
			},
			{
				title: 'Порядок сортировки', dataIndex: 'sortorder', key: 'sortorder',
				render: sortorder => {

					return (
						<>
							<Tag>{sortorder}</Tag>
						</>
					);

				}
			},
		];





		if (filterStopList) {
			let filteredShownItems = items.filter(function (e) {
				return e.stoplist == true;
			});
			console.log(filteredShownItems)
			shownItems = filteredShownItems;
		} else {
			shownItems = items;
		}

		shownItems = shownItems.filter(function (namefilter) {
			return (
				namefilter.name
					.toLowerCase()
					.search(searchvalue.search.toLowerCase()) !== -1
			);
		});

		// console.log('categoryFilterValue.name');
		// console.log(categoryFilterValue.name);

		//e => e.skills.includes(categoryFilterValue.name)



		if (categoryFilterValue.name !== '') {
			if (categoryFilterValue.name !== 'all') {
				shownItems = shownItems.filter(e => e.category.includes(categoryFilterValue.name));
			}
		}





		if (shownItems.length > 0) {
			return (
				<DndProvider backend={HTML5Backend}>
					<Table
						rowKey={record => record._id}
						// pagination={{
						// 	pageSize: valueItemsPerPage.count,
						// }}
						pagination={false}
						style={{ marginTop: 20 }}
						columns={columns}
						components={components}
						loading={itemTableLoading}
						expandedRowRender={record => renderItemInfo(record)}
						dataSource={shownItems}

						onRow={(record, index) => ({
							index,
							moveRow: moveRow,
						})}
					/>
				</DndProvider>
			);
		} else {
			return (
				<div>
					<Divider />
					<Card title="Товаров не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	}

	function onChangeRecommended(value) {
		console.log(value);
		let obj = items.find(obj => obj._id === value);
		let rec = {
			id: obj._id,
			name: obj.name,
			price: obj.base_price,
			image: obj.image1,
			url: obj.url
		}
		setEditValues({ ...editvalues, "recommended": [...editvalues.recommended, rec] });
	}

	function onBlurRecommended() {
		console.log('blur');
	}

	function onFocusRecommended() {
		console.log('focus');
	}

	function onSearchRecommended(val) {
		console.log('search:', val);
	}

	function deleteRecTag(val) {
		console.log(val);
		let filteredArray = editvalues.recommended.filter(item => item.id !== val);
		console.log(filteredArray);
		setEditValues({ ...editvalues, "recommended": filteredArray });

	}


	if (loading) {
		return (
			<div className="content-div">
				<Divider className="header-div" orientation="left"><Spin /></Divider>
			</div>
		);
	} else {
		return (
			<QueueAnim className="anim-content"
				type={['right', 'left']}
				ease={['easeOutQuart', 'easeInOutQuart']}>
				{!loading ? [
					<div key="a" className="content-div">
						<div>
							<Divider className="header-div" orientation="left">Список товаров</Divider>
						</div>

						<Row type="flex" justify="end">
							<div className="header-center-row">
								<Button type="primary" onClick={() => dispatch(getItemList())}>Обновить порядок сортировки</Button>

							</div>
							<div className="header-center-row">

								{filterStopList ? (<><Button type="primary" onClick={() => setFilterStopList(false)}>Скрыть стоп лист</Button></>) : (<><Button type="primary" onClick={() => setFilterStopList(true)}>Показать стоп лист</Button></>)}
							</div>
							<div className="header-center-row">
								<Search
									placeholder="Поиск по названию"
									onChange={handleSearchChange("search")}
									style={{ width: 200 }}
								/>
							</div>
							<div className="header-center-row">
								<Select
									placeholder="Поиск по категориям"
									onChange={handleCategoryFilterChange}
									style={{ width: 200 }}
								>
									<Option key="base" value="all">Все</Option>
									{categoryList.map((category, i) => {
										return (
											<Option key={i} value={category.name}>{category.name}</Option>
										)
									})}
								</Select>
							</div>
							{/* <div className="header-center-row">
								<Select defaultValue={5} size="default" style={{ width: 70 }} onChange={handlePaginationChange}>
									<Option value={5}>5</Option>
									<Option value={10}>10</Option>
									<Option value={25}>25</Option>
								</Select>
							</div> */}
						</Row>

						{renderSort()}

						{user.role == 'admin' || user.role == 'owner' ? (
							<Card style={{ width: '100%', marginBottom: 20 }} size="small">
								<Button type="primary" icon="plus" onClick={handleOpenAddModal}>Добавить</Button>
							</Card>
						) : (<></>)}

						<Modal
							centered
							title={"Добавление группы"}
							style={{ textAlign: 'center' }}
							visible={showRadioOptionAdd}
							onCancel={handleCloseOptRadioModal}
							footer={[
								<Button
									key="back"
									onClick={handleCloseOptRadioModal}
								>
									Отмена
								</Button>,
							]}
						>
							<div>
								{renderOptionsRadioAdd()}
							</div>
						</Modal>

						<Modal
							centered
							title={"Редактирование фильтра"}
							style={{ textAlign: 'center' }}
							visible={showEditFilterModal}
							onCancel={closeEditFilterItemModal}
							footer={[
								<Button
									key="back"
									onClick={closeEditFilterItemModal}
								>
									Отмена
								</Button>,
								<Button
									key="save"
									onClick={handleEditFilter}
								>
									Сохранить
								</Button>,
							]}
						>
							<div>
								{renderEditFilter()}
							</div>
						</Modal>


						<Modal
							centered
							title={"Удаление опции"}
							style={{ textAlign: 'center' }}
							visible={showOptionDel}
							onCancel={handleCloseOptModalDel}
							footer={[
								<Button
									key="back"
									onClick={handleCloseOptModalDel}
								>
									Закрыть
								</Button>
							]}
						>
							<div>
								<div>
									<p>{optionDelItem.name}</p>
								</div>
								{renderOptionsDel()}
							</div>
						</Modal>

						<Modal
							centered
							title={"Добавление группы"}
							style={{ textAlign: 'center' }}
							visible={showCheckboxOptionAdd}
							onCancel={handleCloseOptCheckboxModal}
							footer={[
								<Button
									key="back"
									onClick={handleCloseOptCheckboxModal}
								>
									Отмена
								</Button>,
							]}
						>
							<div>
								{renderOptionsCheckboxAdd()}
							</div>
						</Modal>

						<Modal
							centered
							title={"Добавление опции"}
							style={{ textAlign: 'center' }}
							visible={showOptionAdd}
							onCancel={handleCloseOptModal}
							footer={[
								<Button
									key="back"
									onClick={handleCloseOptModal}
								>
									Отмена
								</Button>,
							]}
						>
							<div>
								{renderOptionsAdd()}
							</div>
						</Modal>




						<Modal
							centered
							title={"Реактирование изображений"}
							style={{ textAlign: 'center' }}
							visible={showAddImg}
							onCancel={handleCloseAddImageModal}
							footer={[
								<Button
									key="yes"
									type="primary"
									disabled={imgUploading}
									onClick={handleCloseAddImageModal}
								>
									Готово
								</Button>,
							]}
						>
							<div>
								<div>
									<Divider className="header-div" orientation="left">{itemAddPhoto.name}</Divider>
								</div>
								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'center' }} xs={12} sm={6}>
										<p>Основное</p>
										<Upload
											style={{ margin: '0 auto' }}
											name="avatar"
											listType="picture-card"
											className="avatar-uploader"
											showUploadList={false}
											customRequest={dummyRequest}
											onChange={handleChangeImage1}
										>
											{valuesImg.image1 ? <img src={valuesImg.image1} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
										</Upload>
										{(valuesImg.image1 != '') ? (<Button type="danger" onClick={handleImageDelete('image1', itemAddPhoto._id)} disabled={imgBtnDisable}>Очистить</Button>) : (<div></div>)}
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'center' }} xs={12} sm={6}>
										<p>Фото №2</p>
										<Upload
											name="avatar"
											listType="picture-card"
											className="avatar-uploader"
											showUploadList={false}
											customRequest={dummyRequest}
											onChange={handleChangeImage2}
										>
											{valuesImg.image2 ? <img src={valuesImg.image2} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
										</Upload>
										{(valuesImg.image2 != '') ? (<Button type="danger" onClick={handleImageDelete('image2', itemAddPhoto._id)} disabled={imgBtnDisable}>Очистить</Button>) : (<div></div>)}
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'center' }} xs={12} sm={6}>
										<p>Фото №3</p>
										<Upload
											name="avatar"
											listType="picture-card"
											className="avatar-uploader"
											showUploadList={false}
											customRequest={dummyRequest}
											onChange={handleChangeImage3}
										>
											{valuesImg.image3 ? <img src={valuesImg.image3} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
										</Upload>
										{(valuesImg.image3 != '') ? (<Button type="danger" onClick={handleImageDelete('image3', itemAddPhoto._id)} disabled={imgBtnDisable}>Очистить</Button>) : (<div></div>)}
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'center' }} xs={12} sm={6}>
										<p>Фото №4</p>
										<Upload
											name="avatar"
											listType="picture-card"
											className="avatar-uploader"
											showUploadList={false}
											customRequest={dummyRequest}
											onChange={handleChangeImage4}
										>
											{valuesImg.image4 ? <img src={valuesImg.image4} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
										</Upload>
										{(valuesImg.image4 != '') ? (<Button type="danger" onClick={handleImageDelete('image4', itemAddPhoto._id)} disabled={imgBtnDisable}>Очистить</Button>) : (<div></div>)}
									</Col>
								</Row>
							</div>
						</Modal>

						<Modal
							centered
							title={"Удалить товар?"}
							style={{ textAlign: 'center' }}
							visible={showDel}
							onCancel={handleCloseDelModal}
							footer={[
								<Button
									key="back"
									onClick={handleCloseDelModal}
								>
									Отмена
								</Button>,
								<Button
									key="yes"
									type="danger"
									disabled={editing}
									onClick={handleDelItem}
								>
									Удалить
								</Button>,
							]}
						>
							<div>
								<p>{itemDel.name}</p>
							</div>
						</Modal>

						<Modal
							centered
							title={"Редактирование товара"}
							visible={showEdit}
							onCancel={handleCloseEditModal}
							footer={[
								<Button
									key="back"
									onClick={handleCloseEditModal}
								>
									Отмена
								</Button>,
								<Button
									key="yes"
									type="primary"
									disabled={editing}
									onClick={handleEditItem}
								>
									Сохранить
								</Button>,
							]}
						>
							<div>
								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
										<Typography>Название:</Typography>
										<Input style={{ marginBottom: 10 }} value={editvalues.name} onChange={handleEditChange("name")} />
										<Typography>Стоимость:</Typography>
										<Input style={{ marginBottom: 10 }} value={editvalues.price} onChange={handleEditChange("price")} />
										<Typography>Порядок сортировки:</Typography>
										<Input style={{ marginBottom: 10 }} value={editvalues.sortorder} onChange={handleEditChange("sortorder")} />

										<Typography>Скидка:</Typography>
										<Select
											value={editvalues.sale}
											onChange={handleEditChangeSale}
											placeholder="Размер скидки"
											style={{ width: '100%', marginBottom: 10 }}
										>
											<Option value={0}>Нет скидки</Option>
											<Option value={5}>5%</Option>
											<Option value={10}>10%</Option>
											<Option value={15}>15%</Option>
											<Option value={20}>20%</Option>
											<Option value={25}>25%</Option>
											<Option value={30}>30%</Option>
											<Option value={35}>35%</Option>
											<Option value={40}>40%</Option>
											<Option value={45}>45%</Option>
											<Option value={50}>50%</Option>
										</Select>

									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
										<Typography>Категории:</Typography>
										<Select
											mode="multiple"
											value={editvalues.category}
											onChange={handleChangeCatEdit}
											placeholder="Выберите категории"
											style={{ width: '100%', marginBottom: 10 }}
										>
											{categoryList.map((cat, i) => (
												<Option key={i} value={cat.name}>{cat.name}</Option>
											))}
										</Select>
									</Col>

									<Col className="gutter-row" style={{ textAlign: 'left' }} sm={24}>
										<Typography>Краткое описание товара:</Typography>
										<TextArea autosize={{ minRows: 2 }} label="Краткое описание товара" value={editvalues.sdescription} onChange={handleEditChange("sdescription")} style={{ marginBottom: 10 }} />
										<Typography>Полное описание товара:</Typography>
										<TextArea autosize={{ minRows: 2 }} label="Описание товара" value={editvalues.description} onChange={handleEditChange("description")} style={{ marginBottom: 10 }} />
										<Typography>Дополнительне поле №1:</Typography>
										<TextArea autosize={{ minRows: 2 }} label="Доп поле №1" value={editvalues.extraField1} onChange={handleEditChange("extraField1")} style={{ marginBottom: 10 }} />
										<Typography>Дополнительне поле №2:</Typography>
										<TextArea autosize={{ minRows: 2 }} label="Доп поле №2" value={editvalues.extraField2} onChange={handleEditChange("extraField2")} style={{ marginBottom: 10 }} />
									</Col>



									<Col className="gutter-row" style={{ textAlign: 'left' }} sm={24}>
										<Typography>Рекомендованные товары:</Typography>

										<Select
											showSearch
											style={{ width: 200 }}
											placeholder="Выберите рекомендуемый товар"
											optionFilterProp="children"
											onChange={onChangeRecommended}
											onFocus={onFocusRecommended}
											onBlur={onBlurRecommended}
											onSearch={onSearchRecommended}
											filterOption={(input, option) =>
												option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
										>
											{items.map((itemtorec, i) => {
												return (
													<Option value={itemtorec._id} key={itemtorec._id.toString() + '_rec'}>{itemtorec.name}</Option>
												)

											})}

										</Select>
										<br />
										<br />
										{editvalues.recommended ? (<>
											{editvalues.recommended.map((recitem, i) => (
												<Tag key={recitem.id} closable={true} onClose={() => deleteRecTag(recitem.id)}>{recitem.name}</Tag>
											))}
										</>) : (<></>)}
										<br />
										<br />
									</Col>



									<Col className="gutter-row" style={{ textAlign: 'left' }} sm={6}>
										<Typography>Ккал:</Typography>
										<Input style={{ marginBottom: 10 }} value={editvalues.energyAmount} onChange={handleEditChange("energyAmount")} />
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} sm={6}>
										<Typography>Белки:</Typography>
										<Input style={{ marginBottom: 10 }} value={editvalues.fiberAmount} onChange={handleEditChange("fiberAmount")} />
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} sm={6}>
										<Typography>Жиры:</Typography>
										<Input style={{ marginBottom: 10 }} value={editvalues.fatAmount} onChange={handleEditChange("fatAmount")} />
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} sm={6}>
										<Typography>Углеводы:</Typography>
										<Input style={{ marginBottom: 10 }} value={editvalues.carbohydrateAmount} onChange={handleEditChange("carbohydrateAmount")} />
									</Col>


									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Typography>ID ( для интеграции ):</Typography>
										<Input style={{ marginBottom: 10 }} value={editvalues.extraId} onChange={handleEditChange("extraId")} />
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Typography>URL ( для сайта ):</Typography>
										<Input style={{ marginBottom: 10 }} value={editvalues.url} onChange={handleEditChange("url")} />
										{urlError ? (<p style={{ color: 'red' }}>URL должен быть уникальным</p>) : (<></>)}
									</Col>

									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Typography>META Title:</Typography>
										<Input style={{ marginBottom: 10 }} value={editvalues.meta_title} onChange={handleEditChange("meta_title")} />

									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Typography>META Description:</Typography>
										<Input style={{ marginBottom: 10 }} value={editvalues.meta_description} onChange={handleEditChange("meta_description")} />

									</Col>

									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
										<Typography>Минимум опций для покупки: ( 0 - нет минимума )</Typography>
										<Input style={{ marginBottom: 10 }} value={editvalues.minimum_options} onChange={handleEditChange("minimum_options")} />
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Checkbox name={"hideprice"} checked={editvalues.hideprice} onChange={(e) => handleEditHidePriceChange(e, "hideprice")}>Скрыть цену</Checkbox>
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Checkbox name={"canPayBonus"} checked={editvalues.canPayBonus} onChange={(e) => handleEditBonusPayChange(e, "canPayBonus")}>Можно оплатить бонусами?</Checkbox>
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Checkbox name={"gift"} checked={editvalues.gift} onChange={(e) => handleEditGiftChange(e, "gift")}>Является подарком?</Checkbox>
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Checkbox name={"excludeGift"} checked={editvalues.excludeGift} onChange={(e) => handleEditExcludeGiftChange(e, "excludeGift")}>Игнорирует сумму подарка</Checkbox>
									</Col>



								</Row>
								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Checkbox name={"useTemplate"} checked={editvalues.useTemplate} onChange={(e) => handleEditUseTemplateChange(e, "useTemplate")}>Использует шаблонные META?</Checkbox>
									</Col>
								</Row>
							</div>
						</Modal>

						<Modal
							centered
							title={"Добавление товара"}
							visible={showAdd}
							onCancel={handleCloseAddModal}
							footer={[
								<Button
									key="back"
									onClick={handleCloseAddModal}
								>
									Отмена
								</Button>,
								<Button
									key="yes"
									type="primary"
									disabled={adding}
									onClick={handleAddItem}
								>
									Сохранить
								</Button>,
							]}
						>
							<div>
								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
										<Typography>Название:</Typography>
										<Input style={{ marginBottom: 10 }} value={values.name} onChange={handleChange("name")} />
										<Typography>Стоимость:</Typography>
										<Input style={{ marginBottom: 10 }} value={values.price} onChange={handleChange("price")} />
										<Typography>Порядок сортировки:</Typography>
										<Input style={{ marginBottom: 10 }} value={values.sortorder} onChange={handleChange("sortorder")} />

										<Typography>Скидка:</Typography>
										<Select
											value={values.sale}
											onChange={handleChangeSale}
											placeholder="Размер скидки"
											style={{ width: '100%', marginBottom: 10 }}
										>
											<Option value={0}>Нет скидки</Option>
											<Option value={5}>5%</Option>
											<Option value={10}>10%</Option>
											<Option value={15}>15%</Option>
											<Option value={20}>20%</Option>
											<Option value={25}>25%</Option>
											<Option value={30}>30%</Option>
											<Option value={35}>35%</Option>
											<Option value={40}>40%</Option>
											<Option value={45}>45%</Option>
											<Option value={50}>50%</Option>
										</Select>

									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
										<Typography>Категории:</Typography>
										<Select
											mode="multiple"
											value={values.category}
											onChange={handleChangeCatAdd}
											placeholder="Выберите категории"
											style={{ width: '100%', marginBottom: 10 }}
										>
											{categoryList.map((cat, i) => (
												<Option key={i} value={cat.name}>{cat.name}</Option>
											))}
										</Select>
									</Col>

									<Col className="gutter-row" style={{ textAlign: 'left' }} sm={24}>
										<Typography>Краткое описание товара:</Typography>
										<TextArea autosize={{ minRows: 2 }} label="Краткое описание товара" value={values.sdescription} onChange={handleChange("sdescription")} style={{ marginBottom: 10 }} />
										<Typography>Полное описание товара:</Typography>
										<TextArea autosize={{ minRows: 2 }} label="Описание товара" value={values.description} onChange={handleChange("description")} style={{ marginBottom: 10 }} />
										<Typography>Дополнительне поле №1:</Typography>
										<TextArea autosize={{ minRows: 2 }} label="Доп поле №1" value={values.extraField1} onChange={handleChange("extraField1")} style={{ marginBottom: 10 }} />
										<Typography>Дополнительне поле №2:</Typography>
										<TextArea autosize={{ minRows: 2 }} label="Доп поле №2" value={values.extraField2} onChange={handleChange("extraField2")} style={{ marginBottom: 10 }} />
									</Col>

									<Col className="gutter-row" style={{ textAlign: 'left' }} sm={6}>
										<Typography>Ккал:</Typography>
										<Input style={{ marginBottom: 10 }} value={values.energyAmount} onChange={handleChange("energyAmount")} />
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} sm={6}>
										<Typography>Белки:</Typography>
										<Input style={{ marginBottom: 10 }} value={values.fiberAmount} onChange={handleChange("fiberAmount")} />
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} sm={6}>
										<Typography>Жиры:</Typography>
										<Input style={{ marginBottom: 10 }} value={values.fatAmount} onChange={handleChange("fatAmount")} />
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} sm={6}>
										<Typography>Углеводы:</Typography>
										<Input style={{ marginBottom: 10 }} value={values.carbohydrateAmount} onChange={handleChange("carbohydrateAmount")} />
									</Col>

									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Typography>ID ( для интеграции ):</Typography>
										<Input style={{ marginBottom: 10 }} value={values.extraId} onChange={handleChange("extraId")} />
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Typography>URL ( для сайта ):</Typography>
										<Input style={{ marginBottom: 10 }} value={values.url} onChange={handleChange("url")} />
										{urlError ? (<p style={{ color: 'red' }}>URL должен быть уникальным</p>) : (<></>)}
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Typography>META Title:</Typography>
										<Input style={{ marginBottom: 10 }} value={values.meta_title} onChange={handleChange("meta_title")} />

									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Typography>META Description:</Typography>
										<Input style={{ marginBottom: 10 }} value={values.meta_description} onChange={handleChange("meta_description")} />

									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
										<Typography>Минимум опций для покупки: ( 0 - нет минимума )</Typography>
										<Input style={{ marginBottom: 10 }} value={values.minimum_options} onChange={handleChange("minimum_options")} />
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Checkbox name={"hideprice"} checked={values.hideprice} onChange={(e) => handleAddHidePriceChange(e, "hideprice")}>Скрыть цену</Checkbox>
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Checkbox name={"canPayBonus"} checked={values.canPayBonus} onChange={(e) => handleAddBonusPayChange(e, "canPayBonus")}>Можно оплатить бонусами?</Checkbox>
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Checkbox name={"gift"} checked={values.gift} onChange={(e) => handleAddGiftChange(e, "gift")}>Является подарком?</Checkbox>
									</Col>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={12}>
										<Checkbox name={"excludeGift"} checked={values.excludeGift} onChange={(e) => handleAddExcludeGiftChange(e, "excludeGift")}>Игнорирует сумму подарка</Checkbox>
									</Col>


								</Row>
								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'left' }} xs={24} sm={24}>
										<Checkbox name={"useTemplate"} checked={values.useTemplate} onChange={(e) => handleAddUseTemplateChange(e, "useTemplate")}>Использует шаблонные META?</Checkbox>
									</Col>
								</Row>
							</div>
						</Modal>

						<Divider />
					</div>
				] : null}
			</QueueAnim>
		);
	}
};

const useFetching = (getItemList, dispatch) => {
	useEffect(() => {
		dispatch(getItemList());
	}, []);
};

export default CPItemList;
