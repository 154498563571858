import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  REGISTERED_PUSH_SENDED,
  ANONYMOUS_PUSH_SENDED,
  PUSH_MESSAGES_SENDING,
} from "./types";


export const sendClear = () => dispatch => {
  dispatch(setPushesSending());
  axios
  .post(serverIp + "api/firebase_push/cleardubles")
  .then(res => {
    dispatch({
      type: ANONYMOUS_PUSH_SENDED,
      payload: res.data
    });
  })
  .catch(err =>{});
}

export const sendAnonymous = (data) => dispatch => {
    console.log('sendAnonymous');
  dispatch(setPushesSending());
  axios
    .post(serverIp + "api/firebase_push/sendanonymouspush", {data:data})
    .then(res => {
      dispatch({
        type: ANONYMOUS_PUSH_SENDED,
        payload: res.data
      });
    })
    .catch(err =>{});
};
export const sendRegistered = (data) => dispatch => {
    console.log('sendRegistered');
    dispatch(setPushesSending());
    axios
      .post(serverIp + "api/firebase_push/sendregisteredpush", {data:data})
      .then(res => {
        dispatch({
          type: REGISTERED_PUSH_SENDED,
          payload: res.data
        });
      })
      .catch(err =>{});
  };

// Загружаются способы оплаты
export const setPushesSending = () => {
  return {
    type: PUSH_MESSAGES_SENDING
  };
};