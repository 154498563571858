import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
    GET_EMAIL_SETTINGS,
    LOADING_EMAIL_SETTINGS,
    GET_EMAIL_ERRORS,
    EDIT_EMAIL_SETTINGS,
    EMAIL_SETTINGS_SAVED
} from "./types";

export const editSettings = (data) => dispatch => {
    dispatch({
        type: EDIT_EMAIL_SETTINGS,
        payload: data
    });
};
export const saveSettings = (settings) => dispatch => {
    axios
        .put(serverIp + "api/email/setsettings", settings)
        .then(res => {
            dispatch({
                type: EMAIL_SETTINGS_SAVED,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch({
                type: GET_EMAIL_ERRORS,
                payload: err.response
            })
        );
};
export const getEmailSettings = () => dispatch => {
    dispatch({
        type: LOADING_EMAIL_SETTINGS
    })
    axios
        .get(serverIp + "api/email/getsettings")
        .then(res => {
            dispatch({
                type: GET_EMAIL_SETTINGS,
                payload: res.data
            });
        })
        .catch(err =>
            dispatch({
                type: GET_EMAIL_ERRORS,
                payload: err.response
            })
        );
};