import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  GET_NEWS_LIST,
  LOADING_NEWS,
  GET_NEWS_ERRORS,
  NEWS_EDITING,
  SHOW_ADD_NEWS_MODAL,
  CLOSE_ADD_NEWS_MODAL,
  SHOW_EDIT_NEWS_MODAL,
  CLOSE_EDIT_NEWS_MODAL,
  NEWS_EDITED,
  NEWS_ADDED,
  NEWS_ADDING,
  NEWS_DELETED,
  SHOW_ADD_IMG_NEWS_MODAL,
  CLOSE_ADD_IMG_NEWS_MODAL,
  IMG_NEWS_BTN_DISABLE,
  NEWS_IMG_DELETED,
  NEWS_IMG_ADDED
} from "./types";

export const sendNewsImageDelete = (data) => dispatch => {
  dispatch(setImgDisable());
  axios
    .post(serverIp+"api/news/deletenewsimg", data)
    .then(res => {
      dispatch({
        type: NEWS_IMG_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_NEWS_ERRORS,
        payload: err.response
      })
    );
}

export const sendNewsImage = (data) => dispatch => {
  dispatch(setImgDisable());
  console.log(data);
  axios
    .post(serverIp+"api/news/addnewsimg", data)
    .then(res => {
      dispatch({
        type: NEWS_IMG_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_NEWS_ERRORS,
        payload: err.response
      })
    );
}

export const setImgDisable = () => {
  return {
    type: IMG_NEWS_BTN_DISABLE
  };
};

export const showAddImgNewsModal = (news) => {
  return {
    type: SHOW_ADD_IMG_NEWS_MODAL,
    payload: news
  };
};
export const closeAddImgNewsModal = () => {
  return {
    type: CLOSE_ADD_IMG_NEWS_MODAL
  };
};


export const showAddNewsModal = () => {
  return {
    type: SHOW_ADD_NEWS_MODAL
  };
};

export const closeAddNewsModal = () => {
  return {
    type: CLOSE_ADD_NEWS_MODAL
  };
};

export const showEditNewsModal = (news) => {
  return {
    type: SHOW_EDIT_NEWS_MODAL,
    payload: news
  };
};

export const closeEditNewsModal = () => {
  return {
    type: CLOSE_EDIT_NEWS_MODAL
  };
};

export const setNewsEditing = () => {
  return {
    type: NEWS_EDITING
  };
};

export const setNewsAdding = () => {
  return {
    type: NEWS_ADDING
  };
};

export const addNews = (news) => dispatch => {
  dispatch(setNewsAdding());
  news.text = news.text.toString('html');
  axios
    .post(serverIp + "api/news/addnews", news)
    .then(res => {
      dispatch({
        type: NEWS_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_NEWS_ERRORS,
        payload: err.response
      })
    );
};

export const deleteNews = (news) => dispatch => {
  axios
    .post(serverIp + "api/news/deletenews", news)
    .then(res => {
      dispatch({
        type: NEWS_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_NEWS_ERRORS,
        payload: err.response
      })
    );
};

export const editNews = (news) => dispatch => {
  dispatch(setNewsEditing());
  news.text = news.text.toString('html');
  axios
    .post(serverIp + "api/news/editnews", news)
    .then(res => {
      dispatch({
        type: NEWS_EDITED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_NEWS_ERRORS,
        payload: err.response
      })
    );
};

// получаем способы оплаты
export const getNewsList = () => dispatch => {
  dispatch(setNewsLoading());
  axios
    .get(serverIp + "api/news/getnewslist")
    .then(res => {
      dispatch({
        type: GET_NEWS_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_NEWS_ERRORS,
        payload: err.response
      })
    );
};

// Загружаются способы оплаты
export const setNewsLoading = () => {
  return {
    type: LOADING_NEWS
  };
};
