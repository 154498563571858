import axios from "axios";
import jwt_decode from "jwt-decode";
import serverIp from "../utils/serverIp";

import {
  GET_COUPONS_LIST,
  LOADING_COUPONS,
  GET_COUPONS_ERRORS,
  SET_COUPONS_ADDING,
  COUPONS_ADDED,
  COUPONS_DELETED,
  COUPONS_EDITED_ENABLED,
  COUPON_REPORT_LOADING,
  COUPON_REPORT_LOADED,
  EDITED_COUPON,
  EDITING_COUPON
  // SET_CART_GIFT_ADDING,
  // CART_GIFT_ADDED,
  // CART_GIFT_DELETED,
  // CART_GIFT_EDITED,
  // SHOW_CART_GIFT_ADD_IMG_MODAL,
  // CLOSE_CART_GIFT_ADD_IMG_MODAL,
  // CART_GIFT_IMG_ADDED,
  // CART_GIFT_IMG_DELETED
} from "./types";

//getCouponReport
export const getCouponReport = (date_start, date_end) => dispatch => {
  dispatch({
    type: COUPON_REPORT_LOADING,
  })
  axios
    .post(serverIp + "api/coupons/getcouponreport", { start: date_start, end: date_end })
    .then(res => {
      dispatch({
        type: COUPON_REPORT_LOADED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_COUPONS_ERRORS,
        payload: err.response
      })
    );
}

export const getCouponsList = () => dispatch => {
  dispatch({
    type: LOADING_COUPONS
  });
  axios
    .get(serverIp + "api/coupons/getcouponslist")
    .then(res => {
      dispatch({
        type: GET_COUPONS_LIST,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_COUPONS_ERRORS,
        payload: err.response
      })
    );
};

export const editCoupon = (coupon) => dispatch => {
  dispatch({
    type: EDITING_COUPON
  });
  axios
    .post(serverIp + "api/coupons/editcoupon", { coupon: coupon })
    .then(res => {
      dispatch({
        type: EDITED_COUPON,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_COUPONS_ERRORS,
        payload: err.response
      })
    );



}

export const addCoupon = (coupon) => dispatch => {
  console.log('addCoupon');
  console.log(coupon);
  dispatch({
    type: SET_COUPONS_ADDING
  });
  axios
    .post(serverIp + "api/coupons/addcoupon", { coupon: coupon })
    .then(res => {
      dispatch({
        type: COUPONS_ADDED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_COUPONS_ERRORS,
        payload: err.response
      })
    );
}

export const deleteCoupon = (coupon) => dispatch => {
  dispatch({
    type: SET_COUPONS_ADDING
  });
  axios
    .post(serverIp + "api/coupons/deletecoupon", { coupon: coupon })
    .then(res => {
      dispatch({
        type: COUPONS_DELETED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_COUPONS_ERRORS,
        payload: err.response
      })
    );
}
export const changeEnabled = (coupon, enabled) => dispatch => {
  dispatch({
    type: SET_COUPONS_ADDING
  });
  axios
    .post(serverIp + "api/coupons/editcouponenabled", { coupon: coupon, enabled: enabled })
    .then(res => {
      dispatch({
        type: COUPONS_EDITED_ENABLED,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_COUPONS_ERRORS,
        payload: err.response
      })
    );
}