import React, { Component } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
//import { CLEAR_CURRENT_USER } from "../../reducers/authReducers";
import * as ACTIONS from '../../actions/authActions';
import { Select, Tag, Button, Row, Col, Table, Divider, Spin, Card, Icon, Layout, Modal, List, Popconfirm, Typography, Radio } from 'antd';
const { Text } = Typography;

const TopButtons = (props) => {
	const auth = useSelector(state => state.auth);

	const dispatch = useDispatch();
	//const { logoutUser } = props;

	return (
		<Row type="flex" justify="end" gutter={16} align="middle">
			<Col xs={0} md={10} style={{textAlign:'right', paddingRight:20}}>
				<Text><Button type="primary" shape="circle" type="dashed" size="small" icon="user" />  {auth.user.name}</Text>
			</Col>
			<Col xs={24} md={2} style={{textAlign:'right', paddingRight:20}}>
				{auth.isAuthenticated ? (
					<Button onClick={() => dispatch(ACTIONS.logoutUser())} icon="logout">Выйти</Button>
				) : (
						<></>
					)}
			</Col>
		</Row>
	);
};

export default TopButtons;
