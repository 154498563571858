import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InputNumber, Select, Input, Button, Row, Col, Avatar, Typography, Divider, Upload, Spin, Card, Icon, Layout, Table, Modal, Checkbox, Popconfirm, DatePicker } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
	getCouponsList,
	addCoupon,
	deleteCoupon,
	changeEnabled,
	getCouponReport,
	editCoupon
	// showAddImgItemModal,
	// closeAddImgItemModal,
	// sendItemImage,
	// sendItemImageDelete
} from "../../actions/couponsActions";
import {
	loadGiftItems,
} from "../../actions/orderActions";
import serverIp from "../../utils/serverIp";
import locale from 'antd/es/date-picker/locale/ru_RU';
import Moment from 'react-moment';
import 'moment/locale/ru';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;

const CPCoupons = props => {
	useFetching(getCouponsList, useDispatch());
	const dispatch = useDispatch();

	const coupons = useSelector(state => state.coupons.coupons);
	const loading = useSelector(state => state.coupons.isLoading);
	const adding = useSelector(state => state.coupons.adding);

	const couponReport = useSelector(state => state.coupons.couponReport);
	const couponReportLoading = useSelector(state => state.coupons.couponReportLoading);

	const couponEditing = useSelector(state => state.coupons.couponEditing);



	const [showAdd, setShowAdd] = React.useState(false);

	const [values, setValues] = React.useState({
		name: '',
		code: '',
		clientId: '',
		percent: 0,
		freeDelivery: false,
		gifts: {},
		uses: 0,
		date_start: '',
		date_end: '',
		infinity: false,
		enabled: true,
		oncePerUser: false,
	});

	const [editValues, setEditValues] = React.useState({
		name: '',
		code: '',
		clientId: '',
		percent: 0,
		freeDelivery: false,
		gifts: {},
		uses: 0,
		date_start: '',
		date_end: '',
		infinity: false,
		enabled: true,
		oncePerUser: false,
	});

	const [reportvalues, setReportValues] = React.useState({
		date_start: '',
		date_end: '',
	});





	const giftitemsToAdd = useSelector(state => state.orders.giftitemsToAdd);



	const [showEdit, setShowEdit] = React.useState(false);

	const handleOpenEdit = (coupon) => {
		console.log(coupon)
		setEditValues(coupon);
		setShowEdit(true);
	}
	const handleCloseEdit = () => {
		setShowEdit(false);
		setEditValues({
			name: '',
			code: '',
			clientId: '',
			percent: 0,
			freeDelivery: false,
			gifts: {},
			uses: 0,
			date_start: '',
			date_end: '',
			infinity: false,
			enabled: true,
			oncePerUser: false,
		});
	}

	const handleEditCoupon = () => {
		console.log('handleEditCoupon');
		console.log(editValues);
		dispatch(editCoupon(editValues));
	}

	const handleSelectItemToEdit = (e) => {
		var result = giftitemsToAdd.filter(obj => {
			return obj._id === e
		})
		setEditValues({ ...editValues, "gifts": { "name": result[0].name, "itemId": result[0]._id, "image": result[0].image1 } });
		console.log(editValues);
	}

	function onChangeEditRange(date, dateString) {
		console.log(dateString);
		console.log(dateString[0]);
		console.log(dateString[1]);
		setEditValues({ ...editValues, "date_start": dateString[0], "date_end": dateString[1] });
	}

	const handleSelectItemToAdd = (e) => {
		var result = giftitemsToAdd.filter(obj => {
			return obj._id === e
		})
		setValues({ ...values, "gifts": { "name": result[0].name, "itemId": result[0]._id, "image": result[0].image1 } });
		console.log(values);
	}

	useEffect(() => {
		if (!couponEditing) {
			setShowEdit(false);
			setEditValues({
				name: '',
				code: '',
				clientId: '',
				percent: 0,
				freeDelivery: false,
				gifts: {},
				uses: 0,
				date_start: '',
				date_end: '',
				infinity: false,
				enabled: true,
				oncePerUser: false,
			});
		}
	}, [couponEditing])
	

	function renderCouponInfo(coupon) {
		return (
			<Row gutter={16} key={coupon.id}>
				<Col className="gutter-row" xs={24} sm={24}>
					<div >
						<span><b>Название купона:</b></span><br />
						<span>{coupon.name}</span><br /><br />

						<span><b>Код купона:</b></span><br />
						<span>{coupon.code}</span><br />

						{coupon.gifts != undefined ? (<>
							<br />
							<span><b>Название товара:</b></span><br />
							<span>{coupon.gifts.name}</span><br />
						</>) : (<></>)}


						<br />
						<span><b>Включено:</b></span><br />
						<span>{coupon.enabled ? (<span>Да</span>) : (<span>Нет</span>)}</span><br />
						<br />
						<span><b>Не более 1 раза для пользователя:</b></span><br />
						<span>{coupon.oncePerUser ? (<span>Да</span>) : (<span>Нет</span>)}</span><br />

					</div>
				</Col>
				<Col className="gutter-row" xs={24} sm={24}>
					<div className="customer-buttons">
						<Popconfirm title="Удалить купон?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => dispatch(deleteCoupon(coupon._id))}>
							<Button style={{ marginRight: 5 }} type="dashed">УДАЛИТЬ</Button>
						</Popconfirm>
						{coupon.enabled ? (<><Button style={{ marginLeft: 5 }} type="dashed" onClick={() => dispatch(changeEnabled(coupon._id, false))}>ВЫКЛЮЧИТЬ</Button></>) : (<><Button style={{ marginLeft: 5 }} type="dashed" onClick={() => dispatch(changeEnabled(coupon._id, true))}>ВКЛЮЧИТЬ</Button></>)}
						<Button onClick={() => handleOpenEdit(coupon)} style={{ marginLeft: 10 }} type="dashed">РЕДАКТИРОВАТЬ</Button>
						{/* <br />
						<Button style={{ marginLeft: 5 }} type="dashed" icon="picture" onClick={() => setShowImg(gift)}>РЕДАКТИРОВАТЬ ИЗОБРАЖЕНИЕ</Button> */}
					</div>
				</Col>
			</Row>
		);
	}

	function renderHeader() {
		return (
			<>
				<Row type="flex" justify="end">
					<div style={{ marginBottom: 10, marginRight: 20, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#eeeeee', paddingRight: 20 }}>
						<RangePicker style={{ width: '100%', marginBottom: 10, }} locale={locale} onChange={onChangeReportRange} />

					</div>
					<div style={{ marginBottom: 10, marginRight: 20, borderRightStyle: 'solid', borderRightWidth: 1, borderRightColor: '#eeeeee', paddingRight: 20 }}>

						<Button type="dashed" onClick={() => onOpenCouponReport()}>отчет</Button>
					</div>
				</Row>
			</>
		)
	}
	const [couponReportOpened, setCouponReportOpened] = React.useState(false);
	const onOpenCouponReport = () => {
		setCouponReportOpened(true);
		dispatch(getCouponReport(reportvalues.date_start, reportvalues.date_end));
	}
	const onCloseCouponReport = () => {
		setCouponReportOpened(false);
	}


	function renderCouponReport() {

		if (couponReport != null) {
			if (!couponReportLoading) {
				let coupon_table = [];
				for (var i = 0; i < couponReport.length; i++) {
					if (coupon_table.some(e => e.name === couponReport[i].usedcoupon)) {
						let index = coupon_table.findIndex(x => x.name === couponReport[i].usedcoupon);
						console.log(coupon_table[index])
						coupon_table[index].count += 1;
						coupon_table[index].total += parseInt(couponReport[i].summa);
					} else {
						let coupon = {
							name: couponReport[i].usedcoupon,
							count: 1,
							total: parseInt(couponReport[i].summa)
						}
						coupon_table.push(coupon);
					}
				}
				return (
					<>
						{coupon_table.map((coupon, i) => {
							return (
								<div key={i.toString()} style={{ display: 'flex', flexDirection: 'row', borderBottomColor: '#eeeeee', borderBottomWidth: 1, borderBottomStyle: 'dashed' }}>
									<span>{coupon.name}</span>
									<span style={{ marginLeft: 'auto', fontWeight: 'bold' }}>{coupon.count} - </span>
									<span style={{ fontWeight: 'bold' }}>({coupon.total})</span>

								</div>
							)
						})}
					</>
				)
			} else {
				return (
					<div style={{ textAlign: 'center' }}>
						<Spin /><br />
						<span>Формируем отчет</span>
					</div>
				)
			}
		}

	}



	function onChangeReportRange(date, dateString) {
		console.log(dateString);
		console.log(dateString[0]);
		console.log(dateString[1]);
		setReportValues({ ...reportvalues, "date_start": dateString[0], "date_end": dateString[1] });
	}

	function onChangeAddRange(date, dateString) {
		console.log(dateString);
		console.log(dateString[0]);
		console.log(dateString[1]);
		setValues({ ...values, "date_start": dateString[0], "date_end": dateString[1] });
	}

	function renderSort() {

		const columns = [
			{ title: 'Название', dataIndex: 'name', key: 'name' },
			{ title: 'Код', dataIndex: 'code', key: 'code' },
			// { title: 'Мин. цена', dataIndex: 'min_price', key: 'min_price' },
			{ title: 'Использований', dataIndex: 'used', key: 'used' },
			// { title: 'Макс. использований', dataIndex: 'uses', key: 'uses' },
			{
				title: 'Включено', dataIndex: 'enabled', key: 'enabled',
				render: enabled => {
					if (enabled) {
						return (
							<>
								<Icon style={{ color: 'green' }} type="check" />
							</>
						);
					} else {
						return (
							<>
								<Icon style={{ color: 'red' }} type="stop" />
							</>
						);
					}
				}
			},
			//
		];

		if (coupons.length > 0) {
			return (
				<Table
					rowKey={record => record._id}
					style={{ marginTop: 20 }}
					columns={columns}
					expandedRowRender={record => renderCouponInfo(record)}
					dataSource={coupons}
				/>
			);
		} else {
			return (
				<div>
					<Card title="Купонов не найдено" style={{ width: '100%', marginBottom: 20 }} size="small">
						<div style={{ textAlign: 'center' }}><Icon style={{ fontSize: 40 }} type="frown" /></div>
					</Card>
				</div>
			);
		}
	}

	if (loading) {
		return (
			<div className="content-div">
				<Divider className="header-div" orientation="left"><Spin /></Divider>
			</div>
		);
	} else {
		return (
			<QueueAnim className="anim-content"
				type={['right', 'left']}
				ease={['easeOutQuart', 'easeInOutQuart']}>
				{!loading ? [
					<div key="a" className="content-div">
						<Divider className="header-div" orientation="left">Купоны</Divider>
						{renderHeader()}
						<Row type="flex" justify="end">
						</Row>

						{renderSort()}
						<Card style={{ width: '100%', marginBottom: 20 }} size="small">
							<Button type="primary" icon="plus" disabled={adding} onClick={() => { setShowAdd(true); dispatch(loadGiftItems()) }}>
								Добавить
							</Button>{adding ? <Spin style={{ marginLeft: 20 }} /> : (<></>)}
						</Card>
						<Divider />

						<Modal
							centered
							title={"Редактирование купона"}
							visible={showEdit}
							onCancel={() => handleCloseEdit()}
							footer={[
								<Button key="back" onClick={() => handleCloseEdit()}>Отмена</Button>,
								<Button key="yes" type="primary" onClick={() => { handleEditCoupon(); }}>Сохранить</Button> //dispatch(addGift(values));
							]}
						>

							<Row gutter={16}>
								<Col className="gutter-row" style={{ textAlign: 'center' }} xs={24} sm={24}>
									<Typography style={{ textAlign: 'left' }}>Название купона:</Typography>
									<Input
										value={editValues.name}
										name="name"
										style={{ marginBottom: 10 }}
										onChange={(e) => setEditValues({ ...editValues, "name": e.target.value })}
									/>
									<Typography style={{ textAlign: 'left' }}>Код купона:</Typography>
									<Input
										value={editValues.code}
										name="code"
										style={{ marginBottom: 10 }}
										onChange={(e) => setEditValues({ ...editValues, "code": e.target.value })}
									/>
									<Typography style={{ textAlign: 'left' }}>ID клиента:</Typography>
									<Input
										value={editValues.clientId}
										name="clientId"
										style={{ marginBottom: 10 }}
										onChange={(e) => setEditValues({ ...editValues, "clientId": e.target.value })}
									/>

									<Typography style={{ textAlign: 'left' }}>% скидки на корзину:</Typography>
									<InputNumber style={{ marginBottom: 10, width: '100%' }} min={0} max={100} defaultValue={0} value={editValues.percent} onChange={(e) => setEditValues({ ...editValues, "percent": e })} />

									<Typography style={{ textAlign: 'left' }}>Выберите товар из списка</Typography>
									<Select
										showSearch
										optionFilterProp="children"
										filterOption={(input, option) =>
											option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										size="default"
										style={{ marginBottom: 10, width: '100%' }}
										onChange={handleSelectItemToEdit}
									>
										{giftitemsToAdd.map(item => {
											return (
												<Option key={item.name} value={item._id}>{item.name}</Option>
											);
										})}
									</Select>

									<Checkbox style={{ textAlign: 'left', marginBottom: 10, width: '100%' }} name={"freeDelivery"} checked={editValues.freeDelivery} onChange={(e) => { setEditValues({ ...editValues, "freeDelivery": e.target.checked }); }}>Дает бесплатную доставку?</Checkbox>

									<Typography style={{ textAlign: 'left' }}>Кол-во использований:</Typography>
									<InputNumber style={{ marginBottom: 10, width: '100%' }} min={0} max={999999} defaultValue={0} value={editValues.uses} onChange={(e) => setEditValues({ ...editValues, "uses": e })} />

									<Checkbox style={{ textAlign: 'left', marginBottom: 10, width: '100%' }} name={"infinity"} checked={editValues.infinity} onChange={(e) => { setEditValues({ ...editValues, "infinity": e.target.checked }); }}>Бесконечное кол-во использований?</Checkbox>

									<Typography style={{ textAlign: 'left' }}>Время действия:</Typography>

									<RangePicker style={{ width: '100%', marginBottom: 10, }} locale={locale} onChange={onChangeEditRange} defaultValue={[moment(editValues.date_start), moment(editValues.date_end)]} />

									<Checkbox style={{ textAlign: 'left', marginBottom: 10, width: '100%', marginLeft: 0 }} name={"enabled"} checked={editValues.enabled} onChange={(e) => { setEditValues({ ...editValues, "enabled": e.target.checked }); }}>Включен?</Checkbox>

									<Checkbox style={{ textAlign: 'left', marginBottom: 10, width: '100%', marginLeft: 0 }} name={"oncePerUser"} checked={editValues.oncePerUser} onChange={(e) => { setEditValues({ ...editValues, "oncePerUser": e.target.checked }); }}>Не более 1 раза для пользователя?</Checkbox>

								</Col>
							</Row>

						</Modal>

						<Modal
							centered
							title={"Добавление купона"}
							visible={showAdd}
							onCancel={() => setShowAdd(false)}
							footer={[
								<Button key="back" disabled={adding} onClick={() => setShowAdd(false)}>Отмена</Button>,
								<Button key="yes" type="primary" disabled={adding} onClick={() => { setShowAdd(false); dispatch(addCoupon(values)); }}>Добавить</Button> //dispatch(addGift(values));
							]}
						>
							<>
								{/* name: '',
								code: '',
								clientId: '',
								percent: 0,
								freeDelivery:false,
								gifts: {},
								uses: 0,
								infinity: false,
								enabled: true */}

								<Row gutter={16}>
									<Col className="gutter-row" style={{ textAlign: 'center' }} xs={24} sm={24}>
										<Typography style={{ textAlign: 'left' }}>Название купона:</Typography>
										<Input
											value={values.name}
											name="name"
											style={{ marginBottom: 10 }}
											onChange={(e) => setValues({ ...values, "name": e.target.value })}
										/>
										<Typography style={{ textAlign: 'left' }}>Код купона:</Typography>
										<Input
											value={values.code}
											name="code"
											style={{ marginBottom: 10 }}
											onChange={(e) => setValues({ ...values, "code": e.target.value })}
										/>
										<Typography style={{ textAlign: 'left' }}>ID клиента:</Typography>
										<Input
											value={values.clientId}
											name="clientId"
											style={{ marginBottom: 10 }}
											onChange={(e) => setValues({ ...values, "clientId": e.target.value })}
										/>

										<Typography style={{ textAlign: 'left' }}>% скидки на корзину:</Typography>
										<InputNumber style={{ marginBottom: 10, width: '100%' }} min={0} max={100} defaultValue={0} value={values.percent} onChange={(e) => setValues({ ...values, "percent": e })} />

										<Typography style={{ textAlign: 'left' }}>Выберите товар из списка</Typography>
										<Select
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											size="default"
											style={{ marginBottom: 10, width: '100%' }}
											onChange={handleSelectItemToAdd}
										>
											{giftitemsToAdd.map(item => {
												return (
													<Option key={item.name} value={item._id}>{item.name}</Option>
												);
											})}
										</Select>

										<Checkbox style={{ textAlign: 'left', marginBottom: 10, width: '100%' }} name={"freeDelivery"} checked={values.freeDelivery} onChange={(e) => { setValues({ ...values, "freeDelivery": e.target.checked }); }}>Дает бесплатную доставку?</Checkbox>

										<Typography style={{ textAlign: 'left' }}>Кол-во использований:</Typography>
										<InputNumber style={{ marginBottom: 10, width: '100%' }} min={0} max={999999} defaultValue={0} value={values.uses} onChange={(e) => setValues({ ...values, "uses": e })} />

										<Checkbox style={{ textAlign: 'left', marginBottom: 10, width: '100%' }} name={"infinity"} checked={values.infinity} onChange={(e) => { setValues({ ...values, "infinity": e.target.checked }); }}>Бесконечное кол-во использований?</Checkbox>

										<Typography style={{ textAlign: 'left' }}>Время действия:</Typography>
										<RangePicker style={{ width: '100%', marginBottom: 10, }} locale={locale} onChange={onChangeAddRange} />

										<Checkbox style={{ textAlign: 'left', marginBottom: 10, width: '100%', marginLeft: 0 }} name={"enabled"} checked={values.enabled} onChange={(e) => { setValues({ ...values, "enabled": e.target.checked }); }}>Включен?</Checkbox>

										<Checkbox style={{ textAlign: 'left', marginBottom: 10, width: '100%', marginLeft: 0 }} name={"oncePerUser"} checked={values.oncePerUser} onChange={(e) => { setValues({ ...values, "oncePerUser": e.target.checked }); }}>Не более 1 раза для пользователя?</Checkbox>

									</Col>
								</Row>
							</>
						</Modal>
					</div>
				] : null}

				<Modal
					width={350}
					centered
					title={"Краткий отчет"}
					style={{ textAlign: 'left' }}
					visible={couponReportOpened}
					onCancel={onCloseCouponReport}
					footer={[
						<Icon style={{ textAlign: 'center', marginRight: 20 }} type="like" theme="twoTone" />,
						<Button
							key="back"
							onClick={onCloseCouponReport}
						>Закрыть</Button>,
					]}
				>
					<>
						{renderCouponReport()}
					</>
				</Modal>

			</QueueAnim>


		);
	}
};

const useFetching = (getCartGiftsList, dispatch) => {
	useEffect(() => {
		dispatch(getCartGiftsList());
	}, []);
};

export default CPCoupons;
