import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Icon, notification } from 'antd';
import UIfx from 'uifx';
import orderSound from '../assets/order.mp3';
import orderDelivered from '../assets/order.mp3';
import orderAccured from '../assets/order.mp3';

import {
    updateOrderList,
    updateOrderDelivered,
    updateOrderPayments,
} from "../actions/orderActions";

import {
    updateCourierOrderList,
    updateCourierOrder
} from "../actions/couriersActions";

import {
    updateReserveList
} from "../actions/reserveActions";

//СОКЕТЫ
import { subscribeServer } from '../socket';
import openSocket from 'socket.io-client';
import socketIp from "../utils/socketIp";
import axios from "axios";

class SocketComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            connected: false
        }
    }

    componentDidMount() {
        console.log('SOCKET componentDidMount');
        if(this.props.auth.isAuthenticated){
            console.log('AUTHED')
            this.connectSocket();
        }
    }

    componentDidUpdate(oldProps){
        const newProps = this.props;
        if (oldProps.auth !== newProps.auth) {
            if(newProps.auth.isAuthenticated){
                this.connectSocket();
            } else {
                console.log('AUTH False');
                const { socket } = this.state;
                socket.disconnect();

            }
        }
    }


    connectSocket = () => {
        const socket = openSocket(socketIp, {
            'reconnection': true,
            'reconnectionDelay': 1000,
            'reconnectionAttempts': 1000
          });
        console.log('subscribeServer');
        socket.on("connect", () => {
            this.setState({ connected: true });
            socket.emit("source", "panel");
        });
        socket.on("message", message => this.serverResponse(null, message));
        socket.on("disconnect", (message) => {
            this.setState({ connected: false });
            console.log('disconnected');
            this.serverResponse(null, message);
        });
        this.setState({ socket });
    }

    serverResponse = (err, message) => {
        if(this.props.auth.user.role != 'courier'){
            if (message.type == "NEW_ORDER") {
                this.openNewOrderNotification(message.data);
            }
            if (message.type == "NEW_RESERVE") {
                this.openNewReserveNotification(message.data);
            }
            if (message.type == "ORDER_DELIVERED") {
                this.openNewOrderDeliveredNotification(message.data);
            }
            if (message.type == "UPDATE_ORDER_PAYMENTS") {
                this.openUpdateOrderPayments(message.data);
            }         
        }
        if (message.type == "NEW_COURIER_ORDER") {
            this.openNewCourierOrderNotification(message.data);
        }
        if (message.type == "UPDATE_COURIER_ORDER") {
            this.openUpdateCourierOrder(message.data);
        }   
    }

    openUpdateOrderPayments = (data) => {
        const orderFX = new UIfx(orderSound,{volume: 0.5,throttleMs: 100});
        orderFX.play();
        this.props.updateOrderPayments(data);
        notification.open({
            message: 'Заказ №' + data + ' был оплачен!',
            placement: 'bottomRight',
            icon: <Icon type="dollar" style={{ color: '#8cfc03' }} />,
        });
    }

    openUpdateCourierOrder = (data) => {
        if(this.props.auth.user.id == data.courier_id){
            const orderFX = new UIfx(orderAccured,{volume: 0.5,throttleMs: 100});
            orderFX.play();
            this.props.updateCourierOrder(data.order_id);
            notification.open({
                message: 'Изменения в заказе '+data.order_id,
                placement: 'bottomRight',
                icon: <Icon type="car" style={{ color: '#8cfc03' }} />,
            });
        }
    }


    openNewCourierOrderNotification = (data) => {
        if(this.props.auth.user.id == data){
            const orderFX = new UIfx(orderAccured,{volume: 0.5,throttleMs: 100});
            orderFX.play();
            this.props.updateCourierOrderList(data);
            notification.open({
                message: 'Получен новый заказ на доставку',
                placement: 'bottomRight',
                icon: <Icon type="car" style={{ color: '#8cfc03' }} />,
            });
        }
    }

    openNewOrderDeliveredNotification = (data) => {
        const orderFX = new UIfx(orderDelivered,{volume: 0.5,throttleMs: 100});
        orderFX.play();
        this.props.updateOrderDelivered(data);
        notification.open({
            message: 'Заказ №' + data.orderNum + ' доставлен!',
            placement: 'bottomRight',
            icon: <Icon type="car" style={{ color: '#8cfc03' }} />,
        });
    };

    openNewOrderNotification = (orderNum) => {
        const orderFX = new UIfx(orderSound,{volume: 0.5,throttleMs: 100});
        orderFX.play();
        this.props.updateOrderList(orderNum);
        notification.open({
            message: 'Получен новый заказ №' + orderNum,
            placement: 'bottomRight',
            icon: <Icon type="dollar" style={{ color: '#8cfc03' }} />,
        });
    };

    openNewReserveNotification = (reserveId) => {
        const orderFX = new UIfx(orderSound,{volume: 0.5,throttleMs: 100});
        console.log('openNewReserveNotification');
        console.log(reserveId);
        orderFX.play();
        this.props.updateReserveList(reserveId);
        notification.open({
            message: 'Получен новый резерв',
            placement: 'bottomRight',
            icon: <Icon type="form" style={{ color: '#8cfc03' }} />,
        });
    };

    render(){
        return(<></>);
    }

}

SocketComponent.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { updateCourierOrder, updateOrderPayments, updateCourierOrderList, updateOrderList, updateReserveList, subscribeServer, updateOrderDelivered })(SocketComponent);



