import {
    GET_EMAIL_SETTINGS,
    LOADING_EMAIL_SETTINGS,
    EDIT_EMAIL_SETTINGS,
    EMAIL_SETTINGS_SAVED,
} from "../actions/types";

const initialState = {
    isLoading: true,
    needSave: false,
    settings: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case EMAIL_SETTINGS_SAVED:
            return{
                ...state,
                needSave: false,
            }
        case EDIT_EMAIL_SETTINGS:
            return{
                ...state,
                needSave: true,
                settings:{
                    admin_email:action.payload.admin_email,
                    admin_email_host:action.payload.admin_email_host,
                    admin_email_port:action.payload.admin_email_port,
                    admin_email_secure:action.payload.admin_email_secure,
                    admin_email_password:action.payload.admin_email_password,
                    admin_email_user:action.payload.admin_email_user,
                    admin_order_alert:action.payload.admin_order_alert,
                    customer_order_alert:action.payload.customer_order_alert,
                    customer_order_alert_field_1:action.payload.customer_order_alert_field_1,
                    customer_order_alert_field_2:action.payload.customer_order_alert_field_2,
                }
            }
        case LOADING_EMAIL_SETTINGS:
            return{
                ...state,
                isLoading: true,
            }
        case GET_EMAIL_SETTINGS:
            return {
                ...state,
                isLoading: false,
                settings: action.payload.settings,
            }
        default:
            return state;
    }
}
