import openSocket from 'socket.io-client';
import socketIp from "./utils/socketIp";

const socket = openSocket(socketIp);
function subscribeServer(cb) {
    console.log('subscribeServer')
    socket.on("connect", () => {
        socket.emit("source", "panel");
    });
    socket.on("message", message => cb(null, message));
}
export { subscribeServer };