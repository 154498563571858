import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import MainMenu from './MainMenu';
import IsAuth from "../guards/IsAuth";
import { Layout, Menu, Icon } from 'antd';

function SideMenu() {
const { Header, Content, Footer, Sider } = Layout;

return(
  <Sider
  breakpoint="xxl"
  collapsedWidth="0"
  onBreakpoint={broken => {
    console.log(broken);
  }}
  onCollapse={(collapsed, type) => {
    console.log(collapsed, type);
  }}
  className="dark-menu-bg"
>
  <Switch>
    <IsAuth path="/" component={MainMenu} />
  </Switch>
</Sider>
	);
};

export default SideMenu;
