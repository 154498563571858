import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Row, Col, Divider, Spin, Card, Icon, Layout, Table, Button, Modal, Typography, Popconfirm, Tag, Popover, DatePicker } from 'antd';
import QueueAnim from 'rc-queue-anim';
import ReactToPrint from "react-to-print";
import {
	getTimesheets,
	getEmployList,
	hideEmployList,
	saveEmploy,
	getMonthTimesheet,
	addWorkdayAction,
	addPrepaid,
	addPrem,
	deletePrepaid,
	deletePrem
} from "../../actions/timesheetActions";
import MaskedInput from 'antd-mask-input'

import Moment from 'react-moment';
import 'moment/locale/ru';
import moment from "moment-timezone";

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

const CPTimesheet = props => {
	useFetching(getTimesheets, useDispatch());
	const dispatch = useDispatch();

	const componentRef = React.useRef(null);

	// const feedbacks = useSelector(state => state.feedback.feedbacks);
	const loading = useSelector(state => state.timesheet.isLoading);
	const employLoading = useSelector(state => state.timesheet.employLoading);
	const employShow = useSelector(state => state.timesheet.employShow);
	const employList = useSelector(state => state.timesheet.employList);
	const monthLoading = useSelector(state => state.timesheet.monthLoading);
	const monthData = useSelector(state => state.timesheet.monthData);
	const formedNew = useSelector(state => state.timesheet.formedNew);

	// const handleDelFeedback = (feedback) => {
	// 	dispatch(deleteFeedback(feedback._id));
	// };



	// const getEmployList = () => {

	// }


	const [selectedEmploy, setSelectedEmploy] = React.useState(null);
	const [showEmployEdit, setShowEmployEdit] = React.useState(false);
	const [needSaveEmploy, setNeedSaveEmploy] = React.useState(false);
	const openEmploy = (employ) => {
		setSelectedEmploy(employ);
		setShowEmployEdit(true);
		console.log(employ)
	}


	const onChangeSalary = (e) => {
		const { value } = e.target;
		const reg = /^-?[0-9]*(\.[0-9]*)?$/;
		if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
			setSelectedEmploy({ ...selectedEmploy, salaryPerHour: value })
			setNeedSaveEmploy(true)
		}

	}

	const onChangeDefaultArrival = (e) => {
		let tmpObj = selectedEmploy.defaultWorkTime;
		tmpObj.arrival = e.target.value;
		setSelectedEmploy({ ...selectedEmploy, defaultWorkTime: tmpObj })
		setNeedSaveEmploy(true)
	}

	const onChangeDefaultDeparture = (e) => {
		let tmpObj = selectedEmploy.defaultWorkTime;
		tmpObj.departure = e.target.value;
		setSelectedEmploy({ ...selectedEmploy, defaultWorkTime: tmpObj })
		setNeedSaveEmploy(true)
	}

	function renderEmployEdit() {
		return (
			<>
				<span>{selectedEmploy.name}</span><br /><br />
				<span>Оклад в час: </span>
				<Input
					onChange={onChangeSalary}
					value={selectedEmploy.salaryPerHour}
					placeholder="Введите сумму"
					maxLength={25}
				/><br /><br />

				<span>Начало рабочего дня: (HH:MM)</span><br />
				<MaskedInput placeholder="Введите время" mask="11:11" size="5" value={selectedEmploy.defaultWorkTime.arrival} onChange={onChangeDefaultArrival} />
				<span>Конец рабочего дня: (HH:MM)</span><br />
				<MaskedInput placeholder="Введите время" mask="11:11" size="5" value={selectedEmploy.defaultWorkTime.departure} onChange={onChangeDefaultDeparture} />
			</>
		)
	}

	const [selectedMonth, setSelectedMonth] = React.useState(null);
	const [selectedYear, setSelectedYear] = React.useState(null);


	function onChangeMonth(date, dateString) {
		console.log(date, dateString);
		console.log(moment(date).year())
		console.log(moment(date).month())
		setSelectedMonth(moment(date).month())
		setSelectedYear(moment(date).year())
		dispatch(getMonthTimesheet(moment(date).year(), moment(date).month()))
		console.log(selectedMonth)
		console.log(selectedYear)
	}




	function daysInMonth(month) {
		var count = moment().month(month).daysInMonth();
		var days = [];
		for (var i = 1; i < count + 1; i++) {
			let isWeekend = false;
			if (moment().month(month).date(i).format('ddd') == 'сб' || moment().month(month).date(i).format('ddd') == 'вс') {
				isWeekend = true;
			} else {
				isWeekend = false;
			}
			let day = {
				"date": moment().month(month).date(i).format('D'),
				"name": moment().month(month).date(i).format('ddd'),
				"weekend": isWeekend
			}

			// console.log('-' +moment().month(month).date(i).format('ddd'))

			days.push(day);

		}
		return days;
	}

	const addWorkday = (user, dayindex) => {
		// monthData.indexOf

		const indexuser = monthData.findIndex((element, index) => {
			// console.log(element)
			if (element.id === user.id) {
				console.log('+')
				return true
			}
		})

		// console.log(indexuser);
		console.log(selectedYear);
		console.log(selectedMonth);
		console.log(user);

		let arrive = null;
		let departure = null;

		// console.log(moment(selectedYear, selectedMonth))
		// console.log(moment().year(selectedYear).month(selectedMonth).day(dayindex).hours(8).format('LLL'))

		let newDate = moment("1946-05-21");
		newDate.set("year", selectedYear);
		newDate.set("month", selectedMonth);
		newDate.set("date", dayindex + 1);
		newDate.set("hour", moment(user.defaultWorkTime.arrival, 'HH:mm').hour())
		newDate.set("minute", moment(user.defaultWorkTime.arrival, 'HH:mm').minutes())
		// let minutes = moment(user.defaultWorkTime.arrival, 'HH:mm').minutes();
		// console.log(minutes);
		// newDate.set("minute", minutes)
		arrive = newDate;
		console.log(arrive)

		let endDate = moment("1946-05-21");
		endDate.set("year", selectedYear);
		endDate.set("month", selectedMonth);
		endDate.set("date", dayindex + 1);
		// endDate.set("hour", user.defaultWorkTime.departure);
		endDate.set("hour", moment(user.defaultWorkTime.departure, 'HH:mm').hour())
		endDate.set("minute", moment(user.defaultWorkTime.departure, 'HH:mm').minutes())

		// let endminutes = moment(user.defaultWorkTime.departure, 'HH:mm').minutes();
		// console.log(endminutes);
		// endDate.set("minute", endminutes);

		departure = endDate;
		console.log(departure)

		// var duration = moment.duration(departure.diff(arrive));
		// var total = duration.asHours();

		var duration = departure.diff(arrive, 'hours', true);
		var total = duration;

		// const difference = start.diff(end, 'hours', true);

		console.log('total');
		console.log(total)

		dispatch(addWorkdayAction(indexuser, dayindex, user.id, total, arrive, departure, selectedYear, selectedMonth));

		// monthData[indexuser].days[dayindex].total = 12

	}

	const deleteWorkday = (user, dayindex) => {
		// monthData.indexOf

		const indexuser = monthData.findIndex((element, index) => {
			// console.log(element)
			if (element.id === user.id) {
				console.log('+')
				return true
			}
		})

		// console.log(indexuser);
		console.log(selectedYear);
		console.log(selectedMonth);
		console.log(user);

		let arrive = null;
		let departure = null;
		var total = 0;

		dispatch(addWorkdayAction(indexuser, dayindex, user.id, total, arrive, departure, selectedYear, selectedMonth));

		// monthData[indexuser].days[dayindex].total = 12

	}


	const [showEditWorkday, setShowEditWorkday] = React.useState(false);
	const [selectedEditWorkday, setSelectedEditWorkday] = React.useState(null);
	const [selectedEditWorkdayIndex, setSelectedEditWorkdayIndex] = React.useState(null);
	const [selectedStartWorkday, setSelectedStartWorkday] = React.useState(null);
	const [selectedEndWorkday, setSelectedEndWorkday] = React.useState(null);
	const [needSaveEmployWorktime, setNeedSaveEmployWorktime] = React.useState(false);

	const editWorkday = (user, dayindex) => {
		setSelectedEditWorkday(user);
		setSelectedEditWorkdayIndex(dayindex);
		setShowEditWorkday(true);
		let timeStart = moment(user.days[dayindex].arrival).format('HH:mm')
		let timeEnd = moment(user.days[dayindex].departure).format('HH:mm')
		setSelectedStartWorkday(timeStart)
		setSelectedEndWorkday(timeEnd)
		console.log(user.days[dayindex]);
	}

	const onChangeEditWorktimeStart = (e) => {
		setSelectedStartWorkday(e.target.value);
		setNeedSaveEmployWorktime(true);
	}
	const onChangeEditWorktimeEnd = (e) => {
		setSelectedEndWorkday(e.target.value);
		setNeedSaveEmployWorktime(true);
	}

	function saveEditedWorktime() {


		const indexuser = monthData.findIndex((element, index) => {
			// console.log(element)
			if (element.id === selectedEditWorkday.id) {
				console.log('+')
				return true
			}
		})

		console.log(selectedYear);
		console.log(selectedMonth);
		console.log(selectedEditWorkday);

		let arrive = null;
		let departure = null;

		let newDate = moment("1946-05-21");
		newDate.set("year", selectedYear);
		newDate.set("month", selectedMonth);
		newDate.set("date", selectedEditWorkdayIndex + 1);
		newDate.set("hour", moment(selectedStartWorkday, 'HH:mm').hour())
		newDate.set("minute", moment(selectedStartWorkday, 'HH:mm').minutes())
		arrive = newDate;
		console.log(arrive)

		let endDate = moment("1946-05-21");
		endDate.set("year", selectedYear);
		endDate.set("month", selectedMonth);
		endDate.set("date", selectedEditWorkdayIndex + 1);
		endDate.set("hour", moment(selectedEndWorkday, 'HH:mm').hour())
		endDate.set("minute", moment(selectedEndWorkday, 'HH:mm').minutes())

		departure = endDate;
		console.log(departure)


		var duration = departure.diff(arrive, 'hours', true);
		var total = duration;

		console.log('total');
		console.log(total)

		dispatch(addWorkdayAction(indexuser, selectedEditWorkdayIndex, selectedEditWorkday.id, total, arrive, departure, selectedYear, selectedMonth));
		setNeedSaveEmployWorktime(false);
		setShowEditWorkday(false);
		setSelectedEditWorkday(null);
		setSelectedEditWorkdayIndex(null);

	}

	function renderEmployEditWorktime() {
		return (
			<>
				<span>Имя: {selectedEditWorkday.name}</span><br />
				<span>Дата: {selectedEditWorkday.days[selectedEditWorkdayIndex].date} {moment(selectedMonth + 1, 'M').format('MMMM')}</span><br /><br />
				<span>Начало рабочего дня: (HH:MM)</span><br />
				<MaskedInput placeholder="Введите время" mask="11:11" size="5" value={selectedStartWorkday} onChange={onChangeEditWorktimeStart} />
				<span>Конец рабочего дня: (HH:MM)</span><br />
				<MaskedInput placeholder="Введите время" mask="11:11" size="5" value={selectedEndWorkday} onChange={onChangeEditWorktimeEnd} />

			</>
		)

	}

	function renderEmptyWorkPopover(user, dayindex) {
		return (
			<>
				<span>Имя: {user.name}</span><br />
				<span>Дата: {user.days[dayindex].date} {moment(selectedMonth + 1, 'M').format('MMMM')}</span><br /><br />
				<Button type="primary" size="small" onClick={() => addWorkday(user, dayindex)}>Поставить смену</Button>
			</>
		)
	}

	function renderEditWorkPopover(user, dayindex) {
		return (
			<>
				<span>Имя: {user.name}</span><br />
				<span>Дата: {user.days[dayindex].date} {moment(selectedMonth + 1, 'M').format('MMMM')}</span><br /><br />
				<Button type="primary" size="small" onClick={() => deleteWorkday(user, dayindex)}>Удалить смену</Button>
				<br /><br />
				<Button type="primary" size="small" onClick={() => editWorkday(user, dayindex)}>Редактировать смену</Button>
			</>
		)
	}

	function calcTotalPrepaid(array) {
		let total = 0;
		for (let index = 0; index < array.length; index++) {
			total += parseInt(array[index].quantity);
		}
		return total
	}

	function calcTotalPrepaidPrem(array) {
		let total = 0;
		for (let index = 0; index < array.prepaid.length; index++) {
			total -= parseInt(array.prepaid[index].quantity);
		}
		if (array.prem) {
			for (let index = 0; index < array.prem.length; index++) {
				total += parseInt(array.prem[index].quantity);
			}
		}

		return total
	}



	function calcTotalPrem(array) {
		let total = 0;
		for (let index = 0; index < array.length; index++) {
			total += parseInt(array[index].quantity);
		}
		return total
	}

	function renderStats(row) {

		let isPrepaid = false;
		let isPrem = false;



	}

	function renderUserTotal(row) {

		console.log(row)
		let totalHours = 0.0;

		for (let index = 0; index < row.days.length; index++) {
			totalHours += parseFloat(row.days[index].total)
		}


		return (
			<>
				Имя: {row.name}<br />
				Оклад в час: {row.salaryPerHour}<br />
				Отработано часов: {totalHours}<br />
				{row.prepaid ? (<>
					{row.prepaid.length > 0 ? (<>
						Аванс: {calcTotalPrepaid(row.prepaid)}<br /></>) : null}
				</>) : null}
				{row.prem ? (<>
					{row.prem.length > 0 ? (<>
						Премия: {calcTotalPrem(row.prem)}<br /></>) : null}
				</>) : null}
				{renderStats(row)}
				{row.prepaid ? (<>
					{row.prepaid.length > 0 ? (<>
						Заработал с учетом аванса и премии: {(row.salaryPerHour * totalHours) + calcTotalPrepaidPrem(row)}<br /></>) : (<>Заработал: {row.salaryPerHour * totalHours}</>)}
				</>) : (<>Заработал: {row.salaryPerHour * totalHours}</>)}
			</>
		)
	}


	const [selectedPrepaid, setSelectedPrepaid] = React.useState(null);
	const [showPrepaidEdit, setShowPrepaidEdit] = React.useState(false);

	const openPrepaid = (employ) => {
		setPrepaidObj({ quantity: 0, comment: '' })
		setSelectedPrepaid(employ);
		setShowPrepaidEdit(true);
		console.log(employ);
	}

	const [prepaidObj, setPrepaidObj] = React.useState({ quantity: 0, comment: '' });

	const onChangePrepaid = (e) => {
		const { value } = e.target;
		const reg = /^-?[0-9]*(\.[0-9]*)?$/;
		if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
			setPrepaidObj({ ...prepaidObj, quantity: value })
		}
	}

	const onChangePrepaidComment = (e) => {
		setPrepaidObj({ ...prepaidObj, comment: e.target.value })
	}

	const handleAddPrepaid = () => {

		let userTmp = selectedPrepaid;

		if (userTmp.prepaid) {
			userTmp.prepaid.push(prepaidObj);
		} else {
			userTmp.prepaid = [];
			userTmp.prepaid.push(prepaidObj);
		}


		const indexuser = monthData.findIndex((element, index) => {
			// console.log(element)
			if (element.id === userTmp.id) {
				console.log('+')
				return true
			}
		})

		setSelectedPrepaid(null);
		setShowPrepaidEdit(false);
		console.log(selectedPrepaid);
		dispatch(addPrepaid(userTmp, prepaidObj, selectedYear, selectedMonth, indexuser));
	}


	const [premObj, setPremObj] = React.useState({ quantity: 0, comment: '' });

	const onChangePrem = (e) => {
		const { value } = e.target;
		const reg = /^-?[0-9]*(\.[0-9]*)?$/;
		if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
			setPremObj({ ...premObj, quantity: value })
		}
	}

	const onChangePremComment = (e) => {
		setPremObj({ ...premObj, comment: e.target.value })
	}

	const handleAddPrem = () => {

		let userTmp = selectedPrepaid;

		if (userTmp.prem) {
			userTmp.prem.push(premObj);
		} else {
			userTmp.prem = [];
			userTmp.prem.push(premObj);
		}


		const indexuser = monthData.findIndex((element, index) => {
			// console.log(element)
			if (element.id === userTmp.id) {
				console.log('+')
				return true
			}
		})

		setSelectedPrepaid(null);
		setShowPrepaidEdit(false);
		console.log(selectedPrepaid);
		dispatch(addPrem(userTmp, premObj, selectedYear, selectedMonth, indexuser));
	}

	const handleDeletePrem = (i) => {
		let userTmp = selectedPrepaid;
		userTmp.prem.splice(i,1);
		const indexuser = monthData.findIndex((element, index) => {
			// console.log(element)
			if (element.id === userTmp.id) {
				console.log('+')
				return true
			}
		})
		setSelectedPrepaid(null);
		setShowPrepaidEdit(false);
		dispatch(deletePrem(userTmp, i, selectedYear, selectedMonth, indexuser));
		// userTmp, prepaidObj, selectedYear, selectedMonth, indexuser
	}


	const [reportShow, setReportShow] = React.useState(false);
	const showReport = () => {
		setReportShow(true);
	}

	const handleDeletePrepaid = (i) => {
		let userTmp = selectedPrepaid;
		userTmp.prepaid.splice(i,1);
		const indexuser = monthData.findIndex((element, index) => {
			// console.log(element)
			if (element.id === userTmp.id) {
				console.log('+')
				return true
			}
		})
		setSelectedPrepaid(null);
		setShowPrepaidEdit(false);
		dispatch(deletePrepaid(userTmp, i, selectedYear, selectedMonth, indexuser));
		// userTmp, prepaidObj, selectedYear, selectedMonth, indexuser
	}

	function renderPrepaidEdit() {
		console.log(selectedPrepaid.prepaid)
		return (
			<>
				<span>Имя: {selectedPrepaid.name}</span><br />
				<span>Месяц: {moment(selectedMonth + 1, 'M').format('MMMM')}</span><br /><br />
				<span>Выданные деньги:</span><br /><br />

				{selectedPrepaid.prepaid ? (<>
					{selectedPrepaid.prepaid.length > 0 ? (<>
						{selectedPrepaid.prepaid.map((prepaid, i) => {
							return (
								<>
									<span>Сумма: {prepaid.quantity}</span><br />
									{prepaid.comment != '' ? (<><span>Причина: {prepaid.comment}<br /></span></>) : (<></>)}
									<span style={{color:'red', cursor:'pointer'}} onClick={() => handleDeletePrepaid(i)}>Удалить</span><br />
								</>
							)
						})}
					</>) : (<><span>Деньги не выдавались</span></>)}
				</>) : (<><span>Деньги не выдавались</span></>)}

				<Divider />

				<span>Премии:</span><br/><br/>
				{selectedPrepaid.prem ? (<>
					{selectedPrepaid.prem.length > 0 ? (<>
				{selectedPrepaid.prem.map((prem,i) => {
					return(
						<>
							<span>Сумма: {prem.quantity}</span><br />
							{prem.comment != '' ? (<><span>Причина: {prem.comment}<br /></span></>) : (<></>)}
							<span style={{color:'red', cursor:'pointer'}} onClick={() => handleDeletePrem(i)}>Удалить</span><br /><br/>
						</>
					)
				})}
					</>) : (<><span>Деньги не выдавались</span></>)}
				</>) : (<><span>Деньги не выдавались</span></>)}
				<Divider />
				<Input
					onChange={onChangePrepaid}
					value={prepaidObj.quantity}
					placeholder="Введите сумму"
					maxLength={25}
					style={{ marginBottom: 5 }}
				/>
				<Input
					onChange={onChangePrepaidComment}
					value={prepaidObj.comment}
					placeholder="Заметка"
					style={{ marginBottom: 5 }}

				/>
				<Button type="primary" onClick={() => handleAddPrepaid()}>Выдать аванс</Button>

				<Divider />
				<Input
					onChange={onChangePrem}
					value={premObj.quantity}
					placeholder="Введите сумму"
					maxLength={25}
					style={{ marginBottom: 5 }}
				/>
				<Input
					onChange={onChangePremComment}
					value={premObj.comment}
					placeholder="Заметка"
					style={{ marginBottom: 5 }}

				/>
				<Button type="primary" onClick={() => handleAddPrem()}>Выдать премию</Button>

			</>
		)
	}

	function renderMonthTable(month) {
		if (selectedMonth != null && !monthLoading) {

			if (!formedNew) {
				console.log('renderMonthTable');
				console.log(selectedMonth);
				var days = daysInMonth(selectedMonth);
				console.log(days)
				console.log('++++')
				console.log(selectedMonth)
				console.log(selectedYear)

				const columns = [
					{
						render: (user, row) => {
							return (
								<>
									<span><Icon type="more" onClick={() => openPrepaid(user)} /></span>
								</>
							)
						}
					},
					{
						title: 'Сотрудник', dataIndex: 'name', key: 'name',
						render: (user, row) => {

							return (
								<Popover content={renderUserTotal(row)} trigger="hover">
									<div>{user}</div>
								</Popover>
							);
						}
					}
				]

				for (let index = 0; index < days.length; index++) {
					if (days[index].weekend) {
						let obj = {
							title: <div className="weekend-header">{days[index].date}</div>, dataIndex: 'days', key: 'days', children: [{
								title: <div className="weekend-header">{days[index].name}</div>, render: (user) => {
									if (user.days[index].total == 0) {
										return (
											<Popover content={renderEmptyWorkPopover(user, index)} trigger="hover">
												<div className="daycell-empty">

												</div>
											</Popover>
										);
									} else {
										return (
											<Popover content={renderEditWorkPopover(user, index)} trigger="hover">
												<div className="daycell">
													<span>{user.days[index].total} </span>
												</div>
											</Popover>
										);
									}

								},
							}
							],

						}
						columns.push(obj)
					} else {
						let obj = {
							title: days[index].date, dataIndex: 'days', key: 'days', children: [{
								title: days[index].name, render: (user) => {
									// console.log('--')
									// console.log(user);
									if (user.days[index].total == 0) {
										return (
											<Popover content={renderEmptyWorkPopover(user, index)} trigger="hover">
												<div className="daycell-empty">

												</div>
											</Popover>
										);
									} else {
										return (
											<Popover content={renderEditWorkPopover(user, index)} trigger="hover">
												<div className="daycell">
													<span>{user.days[index].total} </span>
												</div>
											</Popover>
										);
									}
								},
							}
							]
						}
						columns.push(obj)
					}

				}

				return (
					<>
						<span>Год: {selectedYear}</span><br />
						<span>Месяц: {moment(selectedMonth + 1, 'M').format('MMMM')}</span>
						<Table
							className="timesheettable"
							rowKey={record => record._id}
							style={{ marginTop: 20 }}
							columns={columns}
							dataSource={monthData}
							size="small"
							pagination={false}
						/>
						<Button type="primary" style={{ marginTop: 10 }} onClick={() => showReport()}>Показать отчет</Button>
					</>
				)
			} else {
				return (
					<>
						<span>Был добавлен пользователь. Повторите выбор месяца.</span><br />
					</>
				)
			}

		} else {
			if (monthLoading) {
				return (
					<>
						<span>Загрузка...</span><br />
					</>
				)
			} else {
				return (
					<>
						<span>Выберите месяц</span><br />
						{/* <span>Месяц: {moment('01', 'M').format('MMMM')}</span> */}
					</>
				)
			}
		}

	}

	function getWorkdaysCount(array) {

		let count = 0;

		for (let index = 0; index < array.length; index++) {
			if (array[index].arrival != null) {
				count += 1;
			}

		}
		return count;

	}

	function getWorktimeCount(array) {

		let count = 0;

		for (let index = 0; index < array.length; index++) {
			count += parseFloat(array[index].total);
		}
		return count;

	}

	function getPrepaidCount(row) {
		let count = 0;
		if (row.prepaid) {
			for (let index = 0; index < row.prepaid.length; index++) {
				count += parseInt(row.prepaid[index].quantity);
			}
		}
		return count
	}

	function getPremCount(row) {
		let count = 0;
		if (row.prem) {
			for (let index = 0; index < row.prem.length; index++) {
				count += parseInt(row.prem[index].quantity);
			}
		}
		return count
	}

	function getTotalPayCount(row) {

		let hours = 0;

		for (let index = 0; index < row.days.length; index++) {
			hours += parseFloat(row.days[index].total);
		}

		let prepaid = 0;

		if (row.prepaid) {
			for (let index = 0; index < row.prepaid.length; index++) {
				prepaid += parseInt(row.prepaid[index].quantity);
			}
		}

		let prem = 0;

		if (row.prem) {
			for (let index = 0; index < row.prem.length; index++) {
				prem += parseInt(row.prem[index].quantity);
			}
		}

		let pay = 0;

		let totalByHours = row.salaryPerHour * hours;

		pay = (totalByHours - prepaid) + prem;

		return pay;
	}

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	  }, [componentRef.current]);


	const reactToPrintTrigger = React.useCallback(() => {
		return <Button type="primary" style={{ marginTop: 10 }}>ПЕЧАТЬ</Button>;
	}, []);

	function renderReport() {
		let newMonthData = [];
		for (let index = 0; index < monthData.length; index++) {
			for (let index2 = 0; index2 < monthData[index].days.length; index2++) {
				if (monthData[index].days[index2].arrival != null) {
					newMonthData.push(monthData[index]);
					break;
				}
			}
		}

		const columns = [
			{
				title: 'Сотрудник', dataIndex: 'name', key: 'name',
				render: (user, row) => {
					return (
						<div>{user}</div>
					);
				}
			},
			{
				title: 'Смены', dataIndex: 'id', key: 'id',
				render: (user, row) => {
					return (
						<div>{getWorkdaysCount(row.days)}</div>
					);
				}
			},
			{
				title: 'Часы', dataIndex: 'id', key: 'id',
				render: (user, row) => {
					return (
						<div>{getWorktimeCount(row.days)}</div>
					);
				}
			},
			{
				title: 'ЗП/Ч', dataIndex: 'salaryPerHour', key: 'salaryPerHour',
				render: (salaryPerHour, row) => {
					return (
						<div>{salaryPerHour}</div>
					);
				}
			},
			{
				title: 'Аванс', dataIndex: 'id', key: 'id',
				render: (id, row) => {
					return (
						<div>{getPrepaidCount(row)}</div>
					);
				}
			},
			{
				title: 'Премия', dataIndex: 'id', key: 'id',
				render: (id, row) => {
					return (
						<div>{getPremCount(row)}</div>
					);
				}
			},
			{
				title: 'Выдать', dataIndex: 'id', key: 'id',
				render: (id, row) => {
					return (
						<div>{getTotalPayCount(row)}</div>
					);
				}
			}
		]


		return (
			<>
				<Table
					rowKey={record => record._id}
					style={{ marginTop: 20 }}
					columns={columns}
					dataSource={newMonthData}
					size="small"
					pagination={false}
					ref={componentRef}
				/>
				

				<ReactToPrint
					content={reactToPrintContent}
					documentTitle="AwesomeFileName"
					// onAfterPrint={handleAfterPrint}
					// onBeforeGetContent={handleOnBeforeGetContent}
					// onBeforePrint={handleBeforePrint}
					removeAfterPrint
					trigger={reactToPrintTrigger}
				/>


			</>
		)
	}

	function renderSort() {


		if (employShow) {

			const columns = [
				{
					title: '', dataIndex: '_id', key: '_id',
					render: (id, row) => {
						return (
							<>
								<Button shape="circle" icon="edit" onClick={() => openEmploy(row)}></Button>
							</>
						)
					}
				},
				{ title: 'Имя', dataIndex: 'name', key: 'name' },
				{
					title: 'Должность', dataIndex: 'role', key: 'role',
					render: role => {
						if (role == 'admin') {
							return (
								<Tag>Администратор</Tag>
							)
						}
						if (role == 'owner') {
							return (
								<Tag>Владелец</Tag>
							)
						}
						if (role == 'manager') {
							return (
								<Tag>Менеджер</Tag>
							)
						}
						if (role == 'cashier') {
							return (
								<Tag>Кассир</Tag>
							)
						}
						if (role == 'courier') {
							return (
								<Tag>Курьер</Tag>
							)
						}
						if (role == 'operator') {
							return (
								<Tag>Оператор</Tag>
							)
						}
					}
				},
			]

			return (
				<>
					<Table
						rowKey={record => record._id}
						style={{ marginTop: 20 }}
						columns={columns}
						dataSource={employList}
						size="small"
						pagination={false}
					/>
				</>
			)
		} else {
			return (
				<>
					{renderMonthTable(selectedMonth)}
				</>
			)
		}


	}

	if (loading) {
		return (
			<div className="content-div">
				<Divider className="header-div" orientation="left"><Spin /></Divider>
			</div>
		);
	} else {
		return (
			<QueueAnim className="anim-content"
				type={['right', 'left']}
				ease={['easeOutQuart', 'easeInOutQuart']}>
				{!loading ? [
					<div key="a" className="content-div">
						<Divider className="header-div" orientation="left">Табель</Divider>
						<Row type="flex" justify="end">
							{employShow ? (<Button style={{ marginRight: 20 }} type="primary" onClick={() => dispatch(hideEmployList())}>Уничтожить сотрудников</Button>) : (<Button style={{ marginRight: 20 }} type="primary" onClick={() => dispatch(getEmployList())}>Показать сотрудников</Button>)}

							<MonthPicker onChange={onChangeMonth} placeholder="Месяц" />
						</Row>
						{renderSort()}
						<Divider />


						<Modal
							width={'100%'}
							centered
							title={`Аванс - ` + moment(selectedMonth + 1, 'M').format('MMMM')}
							style={{ maxWidth: 500 }}
							visible={showPrepaidEdit}
							onCancel={() => { setShowPrepaidEdit(false); setSelectedPrepaid(null) }}
							footer={[
								<Row flex style={{ flexDirection: 'row' }}>

									<Button
										key="back"
										onClick={() => { setShowPrepaidEdit(false); setSelectedPrepaid(null) }}

									>
										Закрыть
									</Button>

								</Row>


							]}
						>
							{selectedPrepaid ? renderPrepaidEdit() : null}

						</Modal>


						<Modal
							width={'100%'}
							centered
							title={`Редактирование сотрудника`}
							style={{ maxWidth: 500 }}
							visible={showEmployEdit}
							onCancel={() => { setShowEmployEdit(false); setSelectedEmploy(null) }}
							footer={[
								<Row flex style={{ flexDirection: 'row' }}>
									{needSaveEmploy ? (<Button
										key="back"
										type="primary"
										onClick={() => { dispatch(saveEmploy(selectedEmploy)); setShowEmployEdit(false); }}

									>
										Сохранить
									</Button>) : null}
									<Button
										key="back"
										onClick={() => { setShowEmployEdit(false); setSelectedEmploy(null) }}

									>
										Закрыть
									</Button>

								</Row>


							]}
						>
							{selectedEmploy ? renderEmployEdit() : null}

						</Modal>


						<Modal
							width={'100%'}
							centered
							title={`Редактирование смены`}
							style={{ maxWidth: 500 }}
							visible={showEditWorkday}
							onCancel={() => { setShowEditWorkday(false); setSelectedEditWorkday(null); setSelectedEditWorkdayIndex(null) }}
							footer={[
								<Row flex style={{ flexDirection: 'row' }}>
									{needSaveEmployWorktime ? (<Button
										key="back"
										type="primary"
										onClick={() => saveEditedWorktime()}

									>
										Сохранить
									</Button>) : null}
									<Button
										key="back"
										onClick={() => { setShowEditWorkday(false); setSelectedEditWorkday(null); setSelectedEditWorkdayIndex(null) }}

									>
										Закрыть
									</Button>

								</Row>


							]}
						>
							{selectedEditWorkday ? renderEmployEditWorktime() : null}

						</Modal>


						<Modal
							width={'100%'}
							centered
							title={`Отчет`}
							style={{ maxWidth: 1024 }}
							visible={reportShow}
							onCancel={() => { setReportShow(false) }}
							footer={[
								<Row flex style={{ flexDirection: 'row' }}>
									<Button
										key="back"
										onClick={() => { setReportShow(false) }}

									>
										Закрыть
									</Button>

								</Row>


							]}
						>

							{renderReport()}

						</Modal>



					</div>
				] : null}
			</QueueAnim>
		);
	}
};

const useFetching = (getTimesheets, dispatch) => {
	useEffect(() => {
		dispatch(getTimesheets());
	}, []);
};

export default CPTimesheet;
