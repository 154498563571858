import {
  GET_USERS_LIST,
  LOADING_USERS,
  GET_USERS_ERRORS,
  SHOW_ADD_USER_MODAL,
  CLOSE_ADD_USER_MODAL,
  USER_ADDED,
  ADDING_USER,
  SHOW_EDIT_USER_MODAL,
  CLOSE_EDIT_USER_MODAL,
  USER_DELETED,
  USER_EDITED,
  USER_EDITING
} from "../actions/types";

const initialState = {
  isLoading: true,
  users: [],
  showAdd: false,
  showEdit: false,
  adding: false,
  userDel: {},
  userEdit: {},
  editing: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS_LIST:
      return {
        ...state,
        isLoading: false,
        users: action.payload.users,
      };
    case LOADING_USERS:
      return {
        ...state,
        isLoading: true,
      };
    case SHOW_ADD_USER_MODAL:
      return {
        ...state,
        isLoading: false,
        showAdd: true,
      };
    case CLOSE_ADD_USER_MODAL:
      return {
        ...state,
        isLoading: false,
        showAdd: false,
      };
    case ADDING_USER:
      return {
        ...state,
        adding: true,
      };
    case USER_ADDED:
      return {
        ...state,
        users: [...state.users, action.payload.newuser],
        isLoading: false,
        showAdd: false,
        adding: false,
      };
    case USER_DELETED:
      const id = action.payload.deleteduser._id;
      return {
        ...state,
        users: state.users.filter((user) => user._id !== id),
        showDel: false,
        userDel: {},
      };
    case SHOW_EDIT_USER_MODAL:
      return {
        ...state,
        isLoading: false,
        showEdit: true,
        userEdit: action.payload
      };
    case CLOSE_EDIT_USER_MODAL:
      return {
        ...state,
        isLoading: false,
        showEdit: false,
        userEdit: {}
      };

    case USER_EDITING:
      return {
        ...state,
        editing: true,
      };
    case USER_EDITED:

      const updatedState = state.users.map((user) => {
        if (user._id === action.payload.editeduser._id) {
          return { ...user, ...action.payload.editeduser };
        } else {
        }
        return user;
      });
      return {
        ...state,
        users: updatedState,
        showEdit: false,
        userEdit: {},
        editing: false,
      };

    default:
      return state;
  }
}
