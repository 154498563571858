import React, { useEffect, useCallback } from "react";
import { Typography, Divider, Layout, Row, Col, Statistic, Card, Spin, Descriptions, Icon, Table, Button, Tag } from 'antd';
import { useSelector, useDispatch } from "react-redux";

import {
    getBaseReport,
    getItemReport
} from "../../actions/reportActions";


const CPReport = props => {
    useFetching(getBaseReport, useDispatch());
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.reports.isLoading);
    const isLoadingItems = useSelector(state => state.reports.isLoadingItems);
    const baseReport = useSelector(state => state.reports.baseReport);
    const itemReport = useSelector(state => state.reports.itemReport);
    const itemReportLoaded = useSelector(state => state.reports.itemReportLoaded);

    // useEffect(() => {
    //     if(!isLoading){
    //         dispatch(getItemReport());
    //     }

    // }, [baseReport])

    function getItemGrow(a, b) {
        let c = a - b;
        if (c == 0) {
            return (
                <>
                    <div>=</div>
                </>);
        } else if (c < 0) {
            return (
                <>
                    <div><Icon type="caret-down" style={{ color: "#fc0314" }} /></div>
                    {/* {Math.abs(c)} */}
                </>);
        } else if (c > 0) {
            return (
                <>
                    <div><Icon type="caret-up" style={{ color: "#84fc03" }} /></div>
                    {/* {c} */}
                </>);
        }
    }

    function getPercent(a, b) { //b - последний
        let c = a - b;
        let y = ((a / b) * 100 - 100);
        //с = сколько не хватает
        if (y < 0) {
            return (

                <Statistic
                    title="К предыдущему"
                    value={y}
                    precision={2}
                    valueStyle={{ color: '#cf1322' }}
                    prefix={<Icon type="arrow-down" />}
                    suffix="%"
                />
            )
        } else {
            return (
                <Statistic
                    title="К предыдущему"
                    value={y}
                    precision={2}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<Icon type="arrow-up" />}
                    suffix="%"
                />
            )
        }
    }

    function getPercentReverse(a, b) { //b - последний
        let c = a - b;
        let y = ((a / b) * 100 - 100);
        //с = сколько не хватает
        if (y < 0) {
            return (

                <Statistic
                    title="К предыдущему"
                    value={y}
                    precision={2}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<Icon type="arrow-down" />}
                    suffix="%"
                />
            )
        } else {
            return (
                <Statistic
                    title="К предыдущему"
                    value={y}
                    precision={2}
                    valueStyle={{ color: '#cf1322' }}
                    prefix={<Icon type="arrow-up" />}
                    suffix="%"
                />

            )
        }
    }

    const columns = [
        {
            title: 'Название',
            dataIndex: 'name',
        },
        {
            title: baseReport.name,
            dataIndex: 'current',
        },
        {
            title: baseReport.name_prev,
            dataIndex: 'prev',
        },
        {
            title: baseReport.name_prev_prev,
            dataIndex: 'prev_prev',
        },
    ];


    function renderOptions(item) {
        // console.log(item)

        let option_table = [];
        for (var i = 0; i < item.current_options.length; i++) {
            //Проверим имя
            if (!item.current_options[i].hide) {
                if (item.current_options[i].label != 'Не добавлять') {
                    if (option_table.some(e => e.name === item.current_options[i].name) && option_table.some(r => r.label === item.current_options[i].label)) {
                        //option_table уже имеет такую оплату
                        console.log('Нашли')
                        let index = option_table.findIndex(x => x.label === item.current_options[i].label);
                        option_table[index].total += 1;
                    } else {
                        // такой оплаты нет. добавляем
                        item.current_options[i].total = 1;
                        option_table.push(item.current_options[i]);
                    }
                }

            }
        }


        let option_table_prev = [];
        for (var i = 0; i < item.prev_options.length; i++) {
            //Проверим имя
            if (!item.prev_options[i].hide) {
                if (item.prev_options[i].label != 'Не добавлять') {
                    if (option_table_prev.some(e => e.name === item.prev_options[i].name) && option_table_prev.some(r => r.label === item.prev_options[i].label)) {
                        //option_table уже имеет такую оплату
                        console.log('Нашли')
                        let index = option_table_prev.findIndex(x => x.label === item.prev_options[i].label);
                        option_table_prev[index].total += 1;
                    } else {
                        // такой оплаты нет. добавляем
                        item.prev_options[i].total = 1;
                        option_table_prev.push(item.prev_options[i]);
                    }
                }

            }
        }

        let option_table_prev_prev = [];
        for (var i = 0; i < item.prev_prev_options.length; i++) {
            //Проверим имя
            if (!item.prev_prev_options[i].hide) {
                if (item.prev_prev_options[i].label != 'Не добавлять') {
                    if (option_table_prev_prev.some(e => e.name === item.prev_prev_options[i].name) && option_table_prev_prev.some(r => r.label === item.prev_prev_options[i].label)) {
                        //option_table уже имеет такую оплату
                        console.log('Нашли')
                        let index = option_table_prev_prev.findIndex(x => x.label === item.prev_prev_options[i].label);
                        option_table_prev_prev[index].total += 1;
                    } else {
                        // такой оплаты нет. добавляем
                        item.prev_prev_options[i].total = 1;
                        option_table_prev_prev.push(item.prev_prev_options[i]);
                    }
                }

            }

        }

        let max_l = [];


        console.log(item.name);
        console.log('option_table');
        console.log(option_table);
        console.log('option_table_prev');
        console.log(option_table_prev);
        console.log('option_table_prev_prev');
        console.log(option_table_prev_prev);



        if (option_table.length > option_table_prev.length) {
            //сверим с последним
            if (option_table.length > option_table_prev_prev.length) {
                max_l = [...option_table];
            } else {
                max_l = [...option_table_prev_prev];
            }
        } else {
            max_l = [...option_table_prev];
            //сверим с последним
            if (option_table_prev.length > option_table_prev_prev.length) {
                max_l = [...option_table_prev];
            } else {
                max_l = [...option_table_prev_prev];
            }
        }


        return (
            <>
                <Col span={9}>
                    {max_l.map((option, i) => {
                        return (
                            <>
                                <Tag style={{ marginLeft: 5 }}>{option.name} {option.label}</Tag><br />
                            </>
                        )
                    })}
                </Col>

                <Col span={5}>
                    {option_table.map((option, i) => {
                        if (!option.hide) {
                            if (option_table_prev[i] != undefined) {
                                return (
                                    <>
                                        {option.total}{getItemGrow(option.total, option_table_prev[i].total)}<br />
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        {option.total}{getItemGrow(option.total, 0)}<br />
                                    </>
                                )
                            }
                        }
                    })}
                </Col>

                <Col span={5}>
                    {option_table_prev.map((option, i) => {
                        if (!option.hide) {
                            if (option_table_prev_prev[i] != undefined) {
                                return (
                                    <>
                                        {option.total}{getItemGrow(option.total, option_table_prev_prev[i].total)}<br />
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        {option.total}{getItemGrow(option.total, 0)}<br />
                                    </>
                                )
                            }

                        }
                    })}
                </Col>

                <Col span={5}>
                    {option_table_prev_prev.map((option, i) => {
                        if (!option.hide) {
                            return (
                                <>
                                    <Tag>{option.total}</Tag><br />
                                </>
                            )
                        }
                    })}
                </Col>
            </>)


        // let max_l = [];

        // if (option_table.length > option_table_prev.length) {
        //     //сверим с последним
        //     if (option_table.length > option_table_prev_prev.length) {
        //         max_l = [...option_table];
        //     } else {
        //         max_l = [...option_table_prev_prev];
        //     }
        // } else {
        //     max_l = [...option_table_prev];
        //     //сверим с последним
        //     if (option_table_prev.length > option_table_prev_prev.length) {
        //         max_l = [...option_table_prev];
        //     } else {
        //         max_l = [...option_table_prev_prev];
        //     }
        // }

        // return (
        //     <>
        //         <Col span={9}>
        //             {max_l.map((option, i) => {

        //                 return (
        //                     <>
        //                         <Tag style={{ marginLeft: 5 }}>{option.name} {option.label}</Tag><br />
        //                     </>
        //                 )

        //             })}
        //         </Col>
        //         <Col span={5}>
        //             {option_table.map((option, i) => {
        //                 if (!option.hide) {
        //                     if (option_table_prev[i] != undefined) {
        //                         return (
        //                             <>
        //                                 {option.total}{getItemGrow(option.total, option_table_prev[i].total)}<br />
        //                             </>
        //                         )
        //                     } else {
        //                         return (
        //                             <>
        //                                 {option.total}{getItemGrow(option.total, option_table_prev[i].total)}<br />
        //                             </>
        //                         )
        //                     }
        //                 }
        //             })}
        //         </Col>
        //         <Col span={5}>
        //             {option_table_prev.map((option, i) => {
        //                 if (!option.hide) {
        //                     if (option_table_prev_prev[i] != undefined) {
        //                         return (
        //                             <>
        //                                 {option.total}{getItemGrow(option.total, option_table_prev_prev[i].total)}<br />
        //                             </>
        //                         )
        //                     } else {
        //                         return (
        //                             <>
        //                                 {option.total}{getItemGrow(option.total, 0)}<br />
        //                             </>
        //                         )
        //                     }

        //                 }
        //             })}
        //         </Col>
        //         <Col span={5}>
        //             {option_table_prev_prev.map((option, i) => {
        //                 if (!option.hide) {
        //                     return (
        //                         <>
        //                             <Tag>{option.total}</Tag><br />
        //                         </>
        //                     )
        //                 }
        //             })}
        //         </Col>
        //     </>)
    }

    function renderPrevOptions(item) {

    }

    function renderPrevPrevOptions(item) {

    }


    function renderItemOptions(item) {

        let option_table = [];
        for (var i = 0; i < item.current_options.length; i++) {
            //Проверим имя
            if (option_table.some(e => e.name === item.current_options[i].name) && option_table.some(r => r.label === item.current_options[i].label)) {
                //option_table уже имеет такую оплату
                // console.log('Нашли')
                let index = option_table.findIndex(x => x.label === item.current_options[i].label);
                option_table[index].total += 1;
            } else {
                // такой оплаты нет. добавляем
                item.current_options[i].total = 1;
                option_table.push(item.current_options[i]);
            }

        }


        return (
            <>
                <Row gutter={16}>{renderOptions(item)}</Row>

                {/* {option_table.map(option => {
                    if (!option.hide) {
                        return (
                            <>
                                <Row gutter={16}>
                                    <Col span={9}>
                                        <>{option.name} {option.label}</>
                                    </Col>

                                    <>{renderCurrentOptions(item)}</>


                                    <>{renderPrevOptions(item)}</>


                                    <>{renderPrevPrevOptions(item)}</>

                                </Row>
                            </>
                        )
                    }

                })} */}
            </>
        )

    }

    function renderTableBaseItemsReport() {


        return (<>
            {itemReport.map((item, item_i) => {

                let data = [];

                let allOptions = [];

                for (let index = 0; index < item.current_options.length; index++) {

                    if (allOptions.some(e => e.name === item.current_options[index].name) && allOptions.some(r => r.label === item.current_options[index].label)) {
                        //option_table уже имеет такую оплату
                        console.log('Нашли');
                    } else {
                        console.log('Не нашли');
                        let tmp = {
                            "label": item.current_options[index].label,
                            "name": item.current_options[index].name,
                            "option_name": item.current_options[index].name + ' '+item.current_options[index].label,
                            "current": 0,
                            "prev": 0,
                            "prev_prev": 0
                        }
                        allOptions.push(tmp)
                        // такой оплаты нет. добавляем
                    }

                }

                // console.log(allOptions)

                for (let index = 0; index < allOptions.length; index++) {
                    if(allOptions[index].label != 'Не добавлять'){

                        item.current_options.forEach(current_option => {
                            if (current_option.name == allOptions[index].name && current_option.label == allOptions[index].label) {
                                allOptions[index].current += 1;
                            }
                        });
                        item.prev_options.forEach(prev_option => {
                            if (prev_option.name == allOptions[index].name && prev_option.label == allOptions[index].label) {
                                allOptions[index].prev += 1;
                            }
                        });
                        item.prev_prev_options.forEach(prev_prev_option => {
                            if (prev_prev_option.name == allOptions[index].name && prev_prev_option.label == allOptions[index].label) {
                                allOptions[index].prev_prev += 1;
                            }
                        });
                    }
                }
                console.log(allOptions)

                allOptions.sort(function(a, b) {
                    var textA = a.option_name.toUpperCase();
                    var textB = b.option_name.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });

                // allOptions.forEach(alloptions => {
                //     if (alloptions.label != 'Не добавлять') {
                //         item.current_options.forEach(current_option => {
                //             if (current_option.name == alloptions.name && current_option.label == alloptions.label) {

                //             }
                //         });
                //     }
                // });

                // for (let index = 0; index < allOptions.length; index++) {
                //     if(allOptions[index].label != 'Не добавлять'){



                //     }
                // }


                // [
                //     {
                //       "label": "Не добавлять",
                //       "name": "Казы"
                //     },
                //     {
                //       "label": "Не добавлять",
                //       "name": "Доп. мясо"
                //     },
                //     {
                //       "label": "250 гр.",
                //       "name": "Казы"
                //     },
                //     {
                //       "label": "5 шт.",
                //       "name": "Яйца"
                //     },
                //     {
                //       "label": "1 шт.",
                //       "name": "Острый перец"
                //     }
                //   ]



                const columns = [
                    {
                        title: () => {
                            return(
                                <>
                                    <b>{item.name}</b>
                                </>
                            )
                            
                        },
                        dataIndex: 'option_name',
                        key: 'name',
                    },
                    {
                        title: () => {
                            return(
                                <>
                                    <div className="report-cell">{getItemGrow(item.current, item.prev)} <b>{item.current}</b></div>
                                </>
                            )
                            
                        },
                        // title: item.current,
                        dataIndex: 'current',
                        key: 'age',
                        width: 100,
                        ellipsis: true,
                        render: (current, row) => {
                            return (
                              <><div className="report-cell">{getItemGrow(current, row.prev)} {current}</div></>
                            );
                          },
                          className: 'table-report-th'
                    },
                    {
                        title: () => {
                            return(
                                <>
                                    <div className="report-cell">{getItemGrow(item.prev, item.prev_prev)} <b>{item.prev}</b></div>
                                </>
                            )
                            
                        },
                        // title: item.prev,
                        dataIndex: 'prev',
                        key: 'address',
                        width: 100,
                        ellipsis: true,
                        render: (prev, row) => {
                            return (
                                <><div className="report-cell">{getItemGrow(prev, row.prev_prev)} {prev}</div></>
                            );
                          },
                          className: 'table-report-th'
                    },
                    {
                        title: () => {
                            return(
                                <>
                                    <div className="report-cell"><b>{item.prev_prev}</b></div>
                                </>
                            )
                        },
                        dataIndex: 'prev_prev',
                        key: 'address2',
                        width: 100,
                        ellipsis: true,
                        className: 'table-report-th'
                    },
                ];

                return (
                    <>
                        <Table ellipsis={true} size="small" key={item_i.toString()} dataSource={allOptions} columns={columns} pagination={false} style={{marginBottom:30}}>

                        </Table>
                    </>
                )
            })}
        </>)

    }

    function renderBaseItemsReport() {

        return (
            <>
                <Card style={{ marginBottom: 10, backgroundColor: '#eeeeee' }} size="small">
                    <Row gutter={16}>
                        <Col span={9}>
                            <><b>Название</b></>
                        </Col>
                        <Col span={5}>
                            <><b>{baseReport.name}</b></>
                        </Col>
                        <Col span={5}>
                            <><b>{baseReport.name_prev}</b></>
                        </Col>
                        <Col span={5}>
                            <><b>{baseReport.name_prev_prev}</b></>
                        </Col>
                    </Row>
                </Card>
                {itemReport.map(item => {
                    return (
                        <Card style={{ marginBottom: 5 }} size="small">
                            <Row gutter={16}>
                                <Col span={9}>
                                    <>{item.name} (всего)</>
                                </Col>
                                <Col span={5}>
                                    <>{item.current}{getItemGrow(item.current, item.prev)}</>
                                </Col>
                                <Col span={5}>
                                    <>{item.prev}{getItemGrow(item.prev, item.prev_prev)}</>
                                </Col>
                                <Col span={5}>
                                    <>{item.prev_prev}</>
                                </Col>
                            </Row>
                            {renderItemOptions(item)}
                        </Card>
                    )
                })}

            </>
        )

    }

    return (
        <div className="content-div">
            <Divider className="header-div" orientation="left">Отчет</Divider>

            {isLoading ? (<><Spin /></>) : (<>
                <Card title="Поступившие заказы за последние 3 месяца" style={{ width: '100%', marginBottom: 20 }} size="small">
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card title={baseReport.name} style={{ marginBottom: 20 }} size="small">
                                <Descriptions title="">
                                    <Descriptions.Item label="Кол-во заказов">{baseReport.results}</Descriptions.Item>
                                </Descriptions>
                                {getPercent(baseReport.results, baseReport.results_prev)}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={baseReport.name_prev} style={{ marginBottom: 20 }} size="small">
                                <Descriptions title="">
                                    <Descriptions.Item label="Кол-во заказов">{baseReport.results_prev}</Descriptions.Item>
                                </Descriptions>
                                {getPercent(baseReport.results_prev, baseReport.results_prev_prev)}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={baseReport.name_prev_prev} style={{ marginBottom: 20 }} size="small">
                                <Descriptions title="">
                                    <Descriptions.Item label="Кол-во заказов">{baseReport.results_prev_prev}</Descriptions.Item>
                                </Descriptions>
                                <Statistic
                                    title="Отчетное число"
                                    value={baseReport.results_prev_prev}
                                    precision={0}
                                    valueStyle={{ color: '#000000' }}
                                    suffix=" заказов"
                                />
                            </Card>
                        </Col>
                    </Row>
                </Card>
                <Card title="Отмены за последние 3 месяца" style={{ width: '100%', marginBottom: 20 }} size="small">
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card title={baseReport.name} style={{ marginBottom: 20 }} size="small">
                                <Descriptions title="">
                                    <Descriptions.Item label="Кол-во отмененных заказов">{baseReport.results_canceled}</Descriptions.Item>
                                </Descriptions>
                                {getPercentReverse(baseReport.results_canceled, baseReport.results_prev_canceled)}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={baseReport.name_prev} style={{ marginBottom: 20 }} size="small">
                                <Descriptions title="">
                                    <Descriptions.Item label="Кол-во отмененных заказов">{baseReport.results_prev_canceled}</Descriptions.Item>
                                </Descriptions>
                                {getPercentReverse(baseReport.results_prev_canceled, baseReport.results_prev_prev_canceled)}
                            </Card>
                        </Col>
                        <Col span={8}>
                            <Card title={baseReport.name_prev_prev} style={{ marginBottom: 20 }} size="small">
                                <Descriptions title="">
                                    <Descriptions.Item label="Кол-во отмененных заказов">{baseReport.results_prev_prev_canceled}</Descriptions.Item>
                                </Descriptions>
                                <Statistic
                                    title="Отчетное число"
                                    value={baseReport.results_prev_prev_canceled}
                                    precision={0}
                                    valueStyle={{ color: '#000000' }}
                                    suffix=" заказов"
                                />
                            </Card>
                        </Col>
                    </Row>
                </Card>

                {itemReportLoaded ? (<>
                    <Card title="Позиции за последние 3 месяца" style={{ width: '100%', marginBottom: 20 }} size="small">
                        <Row gutter={16}>
                            {/* {renderBaseItemsReport()} */}
                            {renderTableBaseItemsReport()}
                            {/* <Table columns={columns} dataSource={itemReport} pagination={false} /> */}
                        </Row>
                    </Card>
                </>) : (<>
                    {isLoadingItems ? (<>
                        <Card title="Позиции за последние 3 месяца" style={{ width: '100%', marginBottom: 20, paddingLeft: 20 }} size="small">
                            <Row gutter={16}>
                                <Spin /> загрузка занимает очень много времени. Ожидайте.
                            </Row>
                        </Card>
                    </>) : (<>
                        <Card title="Позиции за последние 3 месяца" style={{ width: '100%', marginBottom: 20, paddingLeft: 20 }} size="small">
                            <Row gutter={16}>
                                <Button type="primary" onClick={() => dispatch(getItemReport())}>Загрузить отчет</Button>
                            </Row>
                        </Card>
                    </>)}

                </>)
                }

            </>)}
        </div >
    )
}

const useFetching = (getBaseReport, dispatch) => {
    useEffect(() => {
        dispatch(getBaseReport());
    }, []);
};

export default CPReport;