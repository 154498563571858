import React, { useEffect, useCallback } from "react";
import { Menu, Icon, Divider } from 'antd';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import IsManager from './../../components/guards/IsManager';

const { SubMenu } = Menu;

const MainMenu = () => {

  const user = useSelector(state => state.auth.user);

  return (
    <>
      <div className="menu-logo">
        <img src={'https://hungrygator.ru/pics/logo-cp.png'} />
      </div>
      <Menu theme="dark" mode="inline" className="dark-menu-bg" inlineIndent={10}>

        {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' || user.role == 'operator' ? (
          <Menu.Item key="1">
            <Icon type="appstore" />
            <Link to='/cp/dashboard'><span className="nav-text">Общая сводка</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' || user.role == 'operator' || user.role == 'cashier' ? (
          <Menu.Item key="2">
            <Icon type="fund" />
            <Link to='/cp/sales'><span className="nav-text">Продажи</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' || user.role == 'operator' || user.role == 'cashier' ? (
          <Menu.Item key="99">
            <Icon type="fund" />
            <Link to='/cp/timesheet'><span className="nav-text">Табель</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' || user.role == 'operator' || user.role == 'cashier' ? (
          <Menu.Item key="100">
            <Icon type="fund" />
            <Link to='/cp/report'><span className="nav-text">Отчет</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' || user.role == 'review' ? (
          <Menu.Item key="17">
            <Icon type="star" />
            <Link to='/cp/reviews'><span className="nav-text">Отзывы</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' ? (
          <Menu.Item key="18">
            <Icon type="align-left" />
            <Link to='/cp/news'><span className="nav-text">Новости</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' ? (
          <Menu.Item key="24">
            <Icon type="align-left" />
            <Link to='/cp/promotions'><span className="nav-text">Описания акций</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' || user.role == 'cashier' ? (
          <Menu.Item key="10">
            <Icon type="team" />
            <Link to='/cp/customers'><span className="nav-text">Покупатели</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' ? (
          <Menu.Item key="28">
            <Icon type="wifi" />
            <Link to='/cp/pushsend'><span className="nav-text">Push - рассылка</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' || user.role == 'operator' ? (
          <Menu.Item key="22">
            <Icon type="team" />
            <Link to='/cp/couriers'><span className="nav-text">Курьеры</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'courier' ? (
          <Menu.Item key="23">
            <Icon type="team" />
            <Link to='/cp/courier-info'><span className="nav-text">Список заказов</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' ? (
          <Menu.Item key="19">
            <Icon type="team" />
            <Link to='/cp/users'><span className="nav-text">Пользователи</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' ? (
          <Menu.Item key="20">
            <Icon type="message" />
            <Link to='/cp/feedback'><span className="nav-text">Обратная связь</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' ? (
          <Menu.Item key="21">
            <Icon type="form" />
            <Link to='/cp/reserve'><span className="nav-text">Бронирование</span></Link>
          </Menu.Item>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' || user.role == 'operator' ? (
          <SubMenu
            key="products"
            title={
              <span>
                <Icon type="shopping" />
                <span>Продукты</span>
              </span>
            }
          >
            <Menu.Item key="3">

              <Icon type="shopping" />
              <Link to='/cp/itemlist'><span className="nav-text">Товары</span></Link>

            </Menu.Item>


            {user.role == 'admin' || user.role == 'owner' ? (
              <Menu.Item key="5">
                <Icon type="profile" />
                <Link to='/cp/caterogylist'><span className="nav-text">Категории</span></Link>
              </Menu.Item>
            ) : (<></>)}
            {user.role == 'admin' || user.role == 'owner' || user.role == 'manager' ? (
              <Menu.Item key="4">
                <Icon type="project" />
                <Link to='/cp/itemoptions'><span className="nav-text">Опции товаров</span></Link>
              </Menu.Item>
            ) : (<></>)}
            {user.role == 'admin' || user.role == 'owner' ? (
              <Menu.Item key="16">
                <Icon type="project" />
                <Link to='/cp/itemfilters'><span className="nav-text">Фильтры товаров</span></Link>
              </Menu.Item>
            ) : (<></>)}


          </SubMenu>

        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' ? (
          <SubMenu
            key="bonus"
            title={
              <span>
                <Icon type="dollar" />
                <span>Лояльность</span>
              </span>
            }
          >
            <Menu.Item key="13">
              <Icon type="crown" />
              <Link to='/cp/bonus'><span className="nav-text">Бонусная система</span></Link>
            </Menu.Item>
            <Menu.Item key="26">
              <Icon type="gift" />
              <Link to='/cp/cartgifts'><span className="nav-text">Подарки в корзине</span></Link>
            </Menu.Item>
            <Menu.Item key="29">
              <Icon type="tags" />
              <Link to='/cp/coupons'><span className="nav-text">Купоны</span></Link>
            </Menu.Item>
          </SubMenu>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' ? (
          <SubMenu
            key="settings"
            title={
              <span>
                <Icon type="setting" />
                <span>Настройки</span>
              </span>
            }
          >
            <Menu.Item key="11">
              <Icon type="align-left" />
              <Link to='/cp/apisettings'><span className="nav-text">API</span></Link>
            </Menu.Item>
            <Menu.Item key="9">
              <Icon type="align-left" />
              <Link to='/cp/integrations'><span className="nav-text">Интеграции</span></Link>
            </Menu.Item>
            <Menu.Item key="30">
              <Icon type="align-left" />
              <Link to='/cp/iiko'><span className="nav-text">iiko</span></Link>
            </Menu.Item>
            <Menu.Item key="31">
              <Icon type="align-left" />
              <Link to='/cp/sumoiiko'><span className="nav-text">SumoEast</span></Link>
            </Menu.Item>
            <Menu.Item key="27">
              <Icon type="car" />
              <Link to='/cp/deliveryservices'><span className="nav-text">Службы доставки</span></Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Icon type="align-left" />
              <Link to='/cp/settings'><span className="nav-text">Приложение</span></Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Icon type="align-left" />
              <Link to='/cp/delivery'><span className="nav-text">Доставка</span></Link>
            </Menu.Item>
            <Menu.Item key="8">
              <Icon type="align-left" />
              <Link to='/cp/payment'><span className="nav-text">Оплата</span></Link>
            </Menu.Item>
            <Menu.Item key="23">
              <Icon type="align-left" />
              <Link to='/cp/printsettings'><span className="nav-text">Настройки печати</span></Link>
            </Menu.Item>
            <Menu.Item key="25">
              <Icon type="align-left" />
              <Link to='/cp/emailsettings'><span className="nav-text">Настройки E-mail</span></Link>
            </Menu.Item>
          </SubMenu>
        ) : (<></>)}

        {user.role == 'admin' || user.role == 'owner' ? (
          <SubMenu key="website" title={<span><Icon type="global" /><span>Сайт</span></span>}>
            <Menu.Item key="12">
              <Icon type="align-left" />
              <Link to='/cp/articles'><span className="nav-text">Статьи</span></Link>
            </Menu.Item>
            <Menu.Item key="32">
              <Icon type="align-left" />
              <Link to='/cp/seopages'><span className="nav-text">SEO Страницы</span></Link>
            </Menu.Item>
            <Menu.Item key="14">
              <Icon type="align-left" />
              <Link to='/cp/webstaticpages'><span className="nav-text">Страницы</span></Link>
            </Menu.Item>
            <Menu.Item key="15">
              <Icon type="align-left" />
              <Link to='/cp/websettings'><span className="nav-text">Настройки</span></Link>
            </Menu.Item>
          </SubMenu>
        ) : (<></>)}
        <Menu.Divider />
        <Menu.Item key="50">
          <Icon type="unordered-list" />
          <Link to='/cp/nomenclature'><span className="nav-text">Номенклатура</span></Link>
        </Menu.Item>
        <Menu.Item key="51">
          <Icon type="inbox" />
          <Link to='/cp/complects'><span className="nav-text">Комплекты</span></Link>
        </Menu.Item>
      </Menu>
    </>
  );
};

export default MainMenu;
