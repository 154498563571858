import {
  GET_PAYMENT_LIST,
  LOADING_PAYMENT,
  GET_PAYMENT_ERRORS,
  SHOW_ADD_PAYMENT_MODAL,
  CLOSE_ADD_PAYMENT_MODAL,
  SHOW_EDIT_PAYMENT_MODAL,
  CLOSE_EDIT_PAYMENT_MODAL,
  PAYMENT_ADDED,
  PAYMENT_ADDING,
  PAYMENT_EDITING,
  PAYMENT_EDITED,
  PAYMENT_DELETED
} from "../actions/types";

const initialState = {
  isLoading: true,
  payments: [],
  isLoading: true,
  editing: false,
  showAdd: false,
  showEdit: false,
  adding: false,
};

export default function (state = initialState, action) {
  switch (action.type) {

    case PAYMENT_EDITED:
      const updatedState = state.payments.map((payment) => {
        if (payment._id === action.payload.editedpayment._id) {
          return { ...payment, ...action.payload.editedpayment };
        } else {
        }
        return payment;
      });
      return {
        ...state,
        payments: updatedState,
        showEdit: false,
        editing: false,
      };
    case PAYMENT_EDITING:
      return {
        ...state,
        editing: true,
      };

    case CLOSE_EDIT_PAYMENT_MODAL:
      return {
        ...state,
        editing: false,
        showEdit: false,
      }
    case SHOW_EDIT_PAYMENT_MODAL:
      return {
        ...state,
        showEdit: true,
      }

    case PAYMENT_DELETED:
      const id = action.payload.deletedpayment._id;
      return {
        ...state,
        payments: state.payments.filter((payment) => payment._id !== id),
      };
    case PAYMENT_ADDING:
      return {
        ...state,
        adding: true,
      };
    case PAYMENT_ADDED:
      return {
        ...state,
        payments: [...state.payments, action.payload.newpayment],
        showAdd: false,
        adding: false,
      };
    case SHOW_ADD_PAYMENT_MODAL:
      return {
        ...state,
        showAdd: true,
      };
    case CLOSE_ADD_PAYMENT_MODAL:
      return {
        ...state,
        showAdd: false
      };
    case GET_PAYMENT_LIST:
      return {
        ...state,
        isLoading: false,
        payments: action.payload.payment,
      };
    case LOADING_PAYMENT:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
}
