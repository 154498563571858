import {
  GET_ITEM_CHECKBOX_OPTION_LIST,
  ITEM_CHECKBOX_OPTION_LIST_LOADING,
  SHOW_OP_CHECKBOX_ITEM_MODAL,
  CLOSE_OP_CHECKBOX_ITEM_MODAL,

  GET_ITEM_RADIO_OPTION_LIST,
  ITEM_RADIO_OPTION_LIST_LOADING,
  CLOSE_OP_RADIO_ITEM_MODAL,
  SHOW_OP_RADIO_ITEM_MODAL,

  GET_ITEM_OPTION_LIST,
  ITEM_OPTION_LIST_LOADING,
  SHOW_OP_ITEM_MODAL,
  CLOSE_OP_ITEM_MODAL,
  GET_ITEM_LIST,
  LOADING_ITEMS,
  GET_ITEM_ERRORS,
  SHOW_ADD_ITEM_MODAL,
  CLOSE_ADD_ITEM_MODAL,
  SHOW_DEL_ITEM_MODAL,
  CLOSE_DEL_ITEM_MODAL,
  ITEM_DELETED,
  SHOW_ED_ITEM_MODAL,
  CLOSE_ED_ITEM_MODAL,
  ITEM_EDITED,
  ITEM_ADDED,
  DELETING_ITEM,
  ADDING_ITEM,
  EDITING_ITEM,
  SHOW_ADD_IMG_MODAL,
  CLOSE_ADD_IMG_MODAL,
  IMG_BTN_DISABLE,
  ITEM_IMG_DELETED,
  ITEM_IMG_ADDED,
  CLOSE_OP_ITEM_MODAL_DEL,
  SHOW_OP_ITEM_MODAL_DEL,
  ITEM_OPTION_ADDED,
  ITEM_OPTION_DELETED,
  OPTION_ADDING,
  OPTION_DELETING,
  SHOW_EDIT_FILTERITEM_MODAL,
  CLOSE_EDIT_FILTERITEM_MODAL,
  EDITED_FILTERITEM,
  ITEM_RADIO_ADDED,
  ITEM_RADIO_DELETED,
  GET_ITEM_LIST_SORTED,
  ITEM_TABLE_LOADING
} from "../actions/types";

const initialState = {
  isLoading: true,
  items: [],
  showAdd: false,
  showDel: false,
  showEdit: false,
  itemAddPhoto: {},
  itemDel: {},
  itemEdit: {},
  categoryList: [],
  deleting: false,
  adding: false,
  editing: false,
  showAddImg: false,
  imgUploading: false,
  imgBtnDisable: false,
  showOptionAdd: false,
  itemEditOption: {},
  itemOptionsLoading: false,
  optionlist: [],
  availableTypes: [],
  showOptionDel: false,
  optionDelItem: {},
  optionDelList: [],
  //ЧЕКБОКСЫ
  itemEditCheckboxOption: {},
  optionscheckboxlist: [],
  showCheckboxOptionAdd: false,
  showCheckboxOptionDel: false,
  itemCheckboxOptionsLoading: false,
  //RADIO
  itemEditRadioOption: {},
  optionsradiolist: [],
  showRadioOptionAdd: false,
  showRadioOptionDel: false,
  itemRadioOptionsLoading: false,

  //ФИЬТРЫ
  showEditFilterModal: false,
  itemEditFilter: {},
  filterList: [],

  //СОРТИРОВКА
  itemTableLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    //RADIO
    case ITEM_RADIO_DELETED:
      console.log('ITEM_RADIO_DELETED');
      console.log(action.payload);
      const updatedStateItemRadioDel = state.items.map((item) => {
        if (item._id === action.payload.itemoptions._id) {
          return { ...item, ...action.payload.itemoptions };
        } else {
        }
        return item;
      });
      return {
        ...state,
        itemEditRadioOption: action.payload.itemoptions,
        items: updatedStateItemRadioDel,
      };

    case ITEM_RADIO_ADDED:
      console.log('ITEM_RADIO_ADDED');
      const updatedStateItemRadio = state.items.map((item) => {
        if (item._id === action.payload.model._id) {
          return { ...item, ...action.payload.model };
        } else {
        }
        return item;
      });
      return {
        ...state,
        itemEditRadioOption: action.payload.model,
        items: updatedStateItemRadio,
      };
    case GET_ITEM_RADIO_OPTION_LIST:
      return {
        ...state,
        isLoading: false,
        optionsradiolist: action.payload.radio,
        itemRadioOptionsLoading: false,
      };
    case ITEM_RADIO_OPTION_LIST_LOADING:
      return {
        ...state,
        isLoading: false,
        itemRadioOptionsLoading: true,
      };
    case SHOW_OP_RADIO_ITEM_MODAL:
      return {
        ...state,
        isLoading: false,
        showRadioOptionAdd: true,
        itemEditRadioOption: action.payload
      };
    case CLOSE_OP_RADIO_ITEM_MODAL:
      return {
        ...state,
        isLoading: false,
        showRadioOptionAdd: false,
        itemEditRadioOption: {}
      };
    // ------------------------------------------------------------------- //

    //ФИЛЬТРЫ
    case SHOW_EDIT_FILTERITEM_MODAL:
      return {
        ...state,
        showEditFilterModal: true,
        itemEditFilter: action.payload,
      }
    case CLOSE_EDIT_FILTERITEM_MODAL:
      return {
        ...state,
        showEditFilterModal: false,
        itemEditFilter: {},
      }
    //ЧЕКБОКСЫ
    case GET_ITEM_CHECKBOX_OPTION_LIST:
      return {
        ...state,
        isLoading: false,
        optionscheckboxlist: action.payload.checkboxes,
        itemCheckboxOptionsLoading: false,
      };
    case ITEM_CHECKBOX_OPTION_LIST_LOADING:
      return {
        ...state,
        isLoading: false,
        itemCheckboxOptionsLoading: true,
      };
    case SHOW_OP_CHECKBOX_ITEM_MODAL:
      return {
        ...state,
        isLoading: false,
        showCheckboxOptionAdd: true,
        itemEditCheckboxOption: action.payload
      };
    case CLOSE_OP_CHECKBOX_ITEM_MODAL:
      return {
        ...state,
        isLoading: false,
        showCheckboxOptionAdd: false,
        itemEditCheckboxOption: {}
      };
    // ------------------------------------------------------------------- //

    case ITEM_OPTION_ADDED:
      const updatedStateItemOption = state.items.map((item) => {
        if (item._id === action.payload.model._id) {
          return { ...item, ...action.payload.model };
        } else {
        }
        return item;
      });
      return {
        ...state,
        adding: false,
        items: updatedStateItemOption,
        showOptionAdd: false,
      };
    case ITEM_OPTION_DELETED:
      const updatedStateItemOptionDeleted = state.items.map((item) => {
        if (item._id === action.payload.itemoptions._id) {
          return { ...item, ...action.payload.itemoptions };
        } else {
        }
        return item;
      });
      return {
        ...state,
        deleting: false,
        items: updatedStateItemOptionDeleted,
        optionDelList: action.payload.itemoptions.options,
      };
    case OPTION_ADDING:
      return {
        ...state,
        adding: true,
      };
    case OPTION_DELETING:
      return {
        ...state,
        deleting: true,
      };
    case GET_ITEM_OPTION_LIST:
      return {
        ...state,
        isLoading: false,
        optionlist: action.payload.options,
        itemOptionsLoading: false,
        availableTypes: action.payload.availableTypes,
      };
    case ITEM_OPTION_LIST_LOADING:
      return {
        ...state,
        isLoading: false,
        itemOptionsLoading: true,
      };
    case CLOSE_OP_ITEM_MODAL_DEL:
      return {
        ...state,
        showOptionDel: false,
        optionDelItem: {},
        optionDelList: []
      };
    case SHOW_OP_ITEM_MODAL_DEL:
      return {
        ...state,
        showOptionDel: true,
        optionDelItem: action.payload,
        optionDelList: action.payload.options
      };

    case SHOW_OP_ITEM_MODAL:
      return {
        ...state,
        isLoading: false,
        showOptionAdd: true,
        itemEditOption: action.payload
      };
    case CLOSE_OP_ITEM_MODAL:
      return {
        ...state,
        isLoading: false,
        showOptionAdd: false,
        itemEditOption: {}
      };
    case ITEM_TABLE_LOADING:
      return {
        ...state,
        itemTableLoading: true,
      }
    case GET_ITEM_LIST_SORTED:
      return {
        ...state,
        itemTableLoading: false,
        items: action.payload.items,
        categoryList: action.payload.categorylist,
        filterList: action.payload.filterlist,
      }
    case GET_ITEM_LIST:
      return {
        ...state,
        items: action.payload.items,
        categoryList: action.payload.categorylist,
        filterList: action.payload.filterlist,
        isLoading: false
      };
    case LOADING_ITEMS:
      return {
        ...state,
        isLoading: true
      };

    case DELETING_ITEM:
      return {
        ...state,
        deleting: true,
      };
    case ADDING_ITEM:
      return {
        ...state,
        adding: true,
      };
    case EDITING_ITEM:
      return {
        ...state,
        editing: true,
      };
    case IMG_BTN_DISABLE:
      return {
        ...state,
        isLoading: false,
        imgBtnDisable: true
      };
    case ITEM_IMG_DELETED:
      const updatedDelImgState = state.items.map((item) => {
        if (item._id === action.payload.item._id) {
          return { ...item, ...action.payload.item };
        } else {
        }
        return item;
      });
      return {
        ...state,
        items: updatedDelImgState,
        imgBtnDisable: false
      };
    case ITEM_IMG_ADDED:
      const updatedAddImgState = state.items.map((item) => {
        if (item._id === action.payload.item._id) {
          return { ...item, ...action.payload.item };
        } else {
        }
        return item;
      });
      return {
        ...state,
        items: updatedAddImgState,
        imgBtnDisable: false
      };
    case SHOW_ADD_IMG_MODAL:
      return {
        ...state,
        isLoading: false,
        showAddImg: true,
        itemAddPhoto: action.payload
      };
    case CLOSE_ADD_IMG_MODAL:
      return {
        ...state,
        isLoading: false,
        showAddImg: false,
        itemAddPhoto: {},
      };
    case SHOW_ADD_ITEM_MODAL:
      return {
        ...state,
        isLoading: false,
        showAdd: true,
      };
    case CLOSE_ADD_ITEM_MODAL:
      return {
        ...state,
        isLoading: false,
        showAdd: false,
      };
    case ITEM_EDITED:
      const updatedState = state.items.map((item) => {
        if (item._id === action.payload.editeditem._id) {
          return { ...item, ...action.payload.editeditem };
        } else {
        }
        return item;
      });
      return {
        ...state,
        items: updatedState,
        showEdit: false,
        itemEdit: {},
        editing: false,
        showEditFilterModal: false,
        itemEditFilter: {},
      };
    case ITEM_ADDED:
      return {
        ...state,
        items: [...state.items, action.payload.newitem],
        isLoading: false,
        showAdd: false,
        adding: false,
      };
    case ITEM_DELETED:
      const id = action.payload.deleteditem._id;
      return {
        ...state,
        items: state.items.filter((item) => item._id !== id),
        showDel: false,
        itemDel: {},
      };
    case SHOW_ED_ITEM_MODAL:
      return {
        ...state,
        isLoading: false,
        showEdit: true,
        itemEdit: action.payload
      };
    case CLOSE_ED_ITEM_MODAL:
      return {
        ...state,
        isLoading: false,
        showEdit: false,
        itemEdit: {}
      };
    case SHOW_DEL_ITEM_MODAL:
      return {
        ...state,
        isLoading: false,
        showDel: true,
        itemDel: action.payload
      };
    case CLOSE_DEL_ITEM_MODAL:
      return {
        ...state,
        isLoading: false,
        showDel: false,
        itemDel: {}
      };
    default:
      return state;
  }
}
