import {
    GET_REVIEW_LIST,
    LOADING_REVIEW,
    GET_REVIEW_ERRORS,
    REVIEW_EDITING,
    SHOW_ADD_REVIEW_MODAL,
    CLOSE_ADD_REVIEW_MODAL,
    SHOW_EDIT_REVIEW_MODAL,
    CLOSE_EDIT_REVIEW_MODAL,
    REVIEW_EDITED,
    REVIEW_ADDED,
    REVIEW_ADDING,
    REVIEW_DELETED,
  } from "../actions/types";
  
  const initialState = {
    isLoading: true,
    reviews: [],
    isLoading: true,
    editing: false,
    showAdd: false,
    showEdit: false,
    adding: false,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
  
      case REVIEW_EDITED:
       
        const updatedReviewState = state.reviews.map((review) => {
          if (review._id === action.payload.editedreview._id) {
            return { ...review, ...action.payload.editedreview };
          } else {
          }
          return review;
        });
        return {
          ...state,
          reviews: updatedReviewState,
          showEdit: false,
          editing: false,
        };
      case REVIEW_EDITING:
        return {
          ...state,
          editing: true,
        };
  
      case CLOSE_EDIT_REVIEW_MODAL:
        return {
          ...state,
          editing: false,
          showEdit: false,
        }
      case SHOW_EDIT_REVIEW_MODAL:
        return {
          ...state,
          showEdit: true,
        }
  
      case REVIEW_DELETED:
        const id = action.payload.deletedreview._id;
        return {
          ...state,
          reviews: state.reviews.filter((review) => review._id !== id),
        };
      case REVIEW_ADDING:
        return {
          ...state,
          adding: true,
        };
      case REVIEW_ADDED:
        return {
          ...state,
          reviews: [...state.reviews, action.payload.newreview],
          showAdd: false,
          adding: false,
        };
      case SHOW_ADD_REVIEW_MODAL:
        return {
          ...state,
          showAdd: true,
        };
      case CLOSE_ADD_REVIEW_MODAL:
        return {
          ...state,
          showAdd: false
        };
      case GET_REVIEW_LIST:
        //console.log(action.payload.review);
        return {
          ...state,
          isLoading: false,
          reviews: action.payload.review,
        };
      case LOADING_REVIEW:
        return {
          ...state,
          isLoading: true,
        };
      default:
        return state;
    }
  }
  