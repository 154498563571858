import React, { Component } from "react";
import { Typography, Divider, Layout, Row, Col } from 'antd';

const { Title, Text } = Typography;

const CPDashboard = () => {

	return (
		<div className="content-div">
			<Divider className="header-div" orientation="left">Общая сводка</Divider>
			<Row gutter={16}>
				<Col className="gutter-row" xs={24} sm={24}>
				<Title level={4}>Версия - <Text code>0.5.9.14</Text></Title><br />
						- Краткий отчет теперь учитывает выбранного курьера в окне заказов.<br />
						- Информация по курьеру теперь выводит столько же данных, сколько в панели курьера.<br />
						- Добавлена должноть "Оператор".<br />
						- Оператор не может менять статус оплаты и удалять позиции из заказа.<br />
						- Оператор может управлять стоп листом.<br />
					<Divider />
					<Title level={4}>Версия - <Text code>0.5.9.13</Text></Title><br />
						- Новые заказы, с первоначальным статусом, подсвечиваются зеленым цветов в списке продаж.<br />
						- Добавлены новые фильтры в список продаж. По имени курьера, по статусу оплаты, по прошедшим и будущим дням.<br />
						- Фильтр по дням, фильтрует заказы у которых выставлена дата и время доставки.<br />
						- Краткий отчет показывает статистику с учетом выбранного дня в фильтре.<br />
						- При выборе фильтра по дню недели, появилась возможность распечатать список товаров к заказам.<br />
						- Добавлен первоначальный функционал PUSH уведомлений.<br/>
					<Divider />
					<Title level={4}>Версия - <Text code>0.5.8.31</Text></Title><br />
						- Добавлена возможность блокировать товары от оплаты бонусами<br />
					<Divider />
					<Title level={4}>Версия - <Text code>0.5.8.26</Text></Title><br />
						- Добавлен поиск по товарам при редактировании состава заказа<br />
						- Добавлена возможность указать кол-во позиций товара при редактировании состава ( внезапно )<br />
						- Теперь при назначении курьера он получает письмо оповещение с данными о заказе<br />
						- Авторизованный курьер получит оповещение на сайте при поступлении нового заказа<br />
						- При оплате Яндекс, статусы корректно проставляются у временно созданного заказа<br />
						- Зарегистрированные покупатели отмечены зеленым цветом в списке продаж<br />
						- Исправлена ошибка в сумме при печати чека<br />
					<Divider />
					<Title level={4}>Версия - <Text code>0.5.8.21</Text></Title><br />
						- Изменена верстка чека для 80мм термо-принтера<br />
						- Добавлен параметр "Используется курьером" в настройках статусов заказов. Именно этот статус будет присваиваться курьером при доставке товара. Он может быть только 1<br />
						- Добавлен функционал переноса пользователей из других систем<br />
						- В окне продажи зарегистрированные пользователи подсвечены зеленым цветом<br />
						- Добавлен пункт "Среднее число позиций в заказах" в кратком отчете<br />
						- Начисление бонусных балов игнорирует стоимость доставки<br />
					<Divider />
					<Title level={4}>Версия - <Text code>0.5.8.18/19</Text></Title><br />
						- Устранен баг с двойным списанием бонусных балов<br />
						- Добавлен краткий отчет за текущие сутки на экране "Продажи"<br />
						- В краткий отчет добавлены данные по деньгам полученным курьерами с завершенных заказов<br />
						- Переработана система смены статусов, времени, способов доставки на экране "Продажи"<br />
						- Добавлена возможность расчитывать сдачу, пометка о которой запишется в заметки заказа ( в окне подробностей о заказе )<br />
						- Добавлена возможность редактировать заметки заказа ( в окне подробностей о заказе )<br />
						- Курьеры доставившие заказ, автоматически присваивают заказу первый статус с действием "Выполнен"<br />
					<Divider />
					<Title level={4}>Версия - <Text code>0.5.8.17</Text></Title><br />
						- Добавлено оповещение и синхронизация заказа по факту доставки заказа курьером.<br />
						- Добавлена возможность создавать заказ из панели управления.<br />
						- При создании заказа вы можете выполнить поиск по зарегистрированным пользователям и автоматически заполнить поля заказа<br />
						- Заказ создается пустым. Наполнение его товарами происходит в меню "Редактирование состава"<br />
						- Добавлена возможность указать стоимость доставки у созданного заказа в общем списке заказов ( столбик "Доставка" )<br />
						- Курьеры в момент доставки заказа автоматически меняют статус оплаты заказа на "Оплачено"<br />
						- Добавлено оповещение при поступлении нового резерва<br />
						- Исправлен баг со сменой статусов заказов<br />
					<Divider />
					<Title level={4}>Версия - <Text code>0.5.8.15</Text></Title><br />
						- Добавлен важный функционал E-mail оповещейний. Настраивается в разделе "Настройки - Настройки E-mail"<br />
						- Исправлен баг с печатью чека ( СУММА NaN )<br />
						- Исправлен баг фильтрацией заказов<br />
					<Divider />
					<Title level={4}>Версия - <Text code>0.5.8.14</Text></Title><br />
				- При создании категории можно указать может ли пользователь с должностью менеджера редактировать товары из этой категории<br />
				- Менеджеры могут просматривать товары и добавлять / убирать из стоп-листа<br />
				- Включено редактирование адреса доставки у пользователя<br />
				- Добавлен поиск по пользователям ( E-mail, номер телефона )<br />
				- Добавлена возможность начислять и списывать бонусные балы пользователя<br />
				- Добавлена возможность проверять историю заказов и начисления/списания бонусов пользователя<br />
				- Добавлен поиск по продажам ( E-mail клиента, телефон клиента, номер заказа )<br />
				- Добавлена возможность менять статус оплаты на странице продаж<br />
				- Добавлена возможность создавать сборный товар<br />
				- Для создания сборного товара, укажите при его создании минимальное кол-во опций для его покупки. При добавлении опций к данному товару, укажите, что эта опция учитывается при оформлении.<br />
					<Divider />
					<Title level={4}>Версия - <Text code>0.5.8.12</Text></Title><br />
				- Добавлена возможность скрыть цену в приложении и на сайте. При скрытии цены в карточке товара будет надпись "Выберите опции"<br />
				- Добавлен поиск по стоп листу, названию товара, категориям.<br />
				- Статус заказов для внутреннего использования и для отображения клиенту настраивается в разделе "Настройки - API - Статусы заказов"<br />
				- Список товаров в заказе отображается при наведении курсора на поле "Состав" в разделе "Продажи"<br />
				- Подробный список и редактирование состава заказа доступно в подробностях о заказе в разделе "Продажи"<br />
				- Добавлена история смены статуса заказа с отметками времени ( доступна при наведении курсора на номер заказа )<br />
				- Добавлено быстрое редактирование статуса заказа ( при нажатии на статус в списке продаж )<br />
				- Добавлено быстрое редактирование назначенного курьера ( при нажатии на курьера в списке продаж )<br />
				- Добавлено поле с выбором даты и времени доставки товара ( при нажатии на времени в списке продаж )<br />
				- Добавлена возможность менять способ оплаты ( смена не доступна, если заказ был оплачен онлайн. работает как и смена статуса )<br />
				- Включено редактирование и удаление статей<br />
					<Divider />
					<Title level={4}>Версия - <Text code>0.5.8.05</Text></Title><br />
				- Постраничная загрузка данных внедрена в раздел "Продажи"<br />
				- Переработан интерфейс раздела "Продажи"<br />
				- Добавлен раздел с Акциями. Работает по аналогии с Новостями<br />
					<Divider />
					<Title level={4}>Версия - <Text code>0.5.8.03</Text></Title><br />
				- Добавлена постраничная загрузка списка зарегистрированных покупателей для возможности работы с большим количеством записей. ( данная технология будет использована во всех разделах )<br />
				- Добавлена возможность назначать курьеров к заказу.<br />
				- Добавлен раздел для администраторов и менеджеров, в котором можно сделать выборку заказов по доступным курьерам.<br />
				- В карточке заказа отображается дата и время назначения курьера, дата и время факта доставки.<br />
				- Теперь курьеры могут авторизовываться в панели управления и видеть назначенные им заказы.<br />
				- Курьеры могут отмечать факт доставки с привязкой ко времени.<br />
				- Курьеры могут фильтровать заказы по временному промежутку.<br />
					<Divider />
					<Title level={4}>Версия - <Text code>0.5.7.29</Text></Title><br />
				- Добавлен раздел Обратной связи, для приема обращений с приложения или сайта.<br />
				- Добавлен раздел Резервирования столов.<br />
				- Добавлен функционал пользователей панели управления. Создавать, редактировать, менять должности, можно в новом разделе "Пользователи"<br />
				- Добавлены настройки печати чека под принтер 80мм.<br />
				- Добавлены настройки под новый тип отображения приложения. ( Сайд меню )<br />
				- Поступившие заказы при оплате Яндексом, появляются сразу в списке продаж. Вне зависимости от того, прошла оплата или нет.<br />
					<Divider />
					<Title level={4}>Версия - <Text code>0.4.7.10</Text></Title><br />
				- Добавлен раздел с новостями. Размещенные новости будут отображатся как в приложении, так и на сайте в соответствующем разделе.<br />
				- Добавлен средний рейтинг по отзывам. Средний рейтинг отображается только, в панели управления.<br />
				- Исправлена ошибка с редактированием товара, при которой было не возможно сохранить товар, не меняя URL.<br />
				- Ускорено время загрузки списка покупателей<br /><br />
					<Divider />
					<Title level={4}>Ведутся работы</Title><br />
				- Синхронизация оплаты с системой Яндекс<br />
				- Загрузка и автоматическое сжатие изображения загруженного пользователем мобильного приложения из раздела "Отзывы"<br />
				- Базовая отчетность по совершенным заказам.
				</Col>
			</Row>
		</div>
	);
};

export default CPDashboard;
