import {
    GET_IIKO_SETTINGS,
    EDIT_IIKO_SETTINGS,
    SAVE_IIKO_SETTINGS,
    LOADING_IIKO_SETTINGS,
    IIKO_AUTH_CHECK,
    IIKO_MENU_LOADED,
    IIKO_MENU_LOADING
} from "../actions/types";
import axios from "axios";
import serverIp from "../utils/serverIp";

// ПОЛУЧАЕМ НАСТРОЙКИ
export const getIikoSettings = () => dispatch => {
    console.log('getIikoSettings');
    dispatch(setIikoSettingsLoading());
    axios
        .get(serverIp + "api/iiko/getsettings")
        .then(res => {
            dispatch({
                type: GET_IIKO_SETTINGS,
                payload: res.data
            });
        })
        .catch(err => { });
};
export const setIikoSettingsLoading = () => {
    return {
        type: LOADING_IIKO_SETTINGS
    };
};

// РЕДАКТИРУЕМ НАСТРОЙКИ
export const editIikoSettings = (data) => dispatch => {
    dispatch({
        type: EDIT_IIKO_SETTINGS,
        payload: data
    });
};

// СОХРАНЯЕМ НАСТРОЙКИ
export const saveIikoSettings = (setdata) => dispatch => {
    axios
        .put(serverIp + "api/iiko/savesettings", setdata)
        .then(res => {
            dispatch({
                type: SAVE_IIKO_SETTINGS,
                payload: res.data
            });
        })
        .catch(err => { });
};

// ПРОВЕРЯЕМ НАСТРОЙКИ
export const checkAuth = () => dispatch => {
    axios
        .get(serverIp + "api/iiko/checkorder")
        .then(res => {
            dispatch({
                type: IIKO_AUTH_CHECK,
                payload: res.data
            });
        })
        .catch(err => { });
};

// ПОДГРУЖАЕМ МЕНЮ
export const getMenuFromIiko = () => dispatch => {
    dispatch(setIikoMenuLoading());
    axios
        .get(serverIp + "api/iiko/getmenufromiiko")
        .then(res => {
            dispatch({
                type: IIKO_MENU_LOADED,
                payload: res.data
            });
        })
        .catch(err => { });
};
export const setIikoMenuLoading = () => {
    return {
        type: IIKO_MENU_LOADING
    };
};