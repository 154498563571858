import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getMenuFromSumoIiko, getSumoIikoSettings, checkSumoIiko } from '../../actions/sumoIiikoActions'
import { Select, Checkbox, Button, Typography, Divider, Spin, Layout, Card, Input } from 'antd';
import QueueAnim from 'rc-queue-anim';
const { Option } = Select;

const CPSumoIiko = props => {
    useFetching(getSumoIikoSettings, useDispatch());
    const dispatch = useDispatch();
    const loading = useSelector(state => state.sumoiiko.isLoading);
    const menuLoading = useSelector(state => state.sumoiiko.menuLoading);
    const mainUserLogin = useSelector(state => state.sumoiiko.mainUserLogin);
    const mainUserSecret = useSelector(state => state.sumoiiko.mainUserSecret);

    const settings = useSelector(state => state.sumoiiko.settings);

    const checkSumoIikoPress = (fId) => {
        dispatch(checkSumoIiko(fId));
    }

    const getSumoIikoMenu = (fId) => {
        console.log('getSumoIikoMenu');
        dispatch(getMenuFromSumoIiko(fId));
    }

    function renderSumoIiko() {
        console.log('renderSumoIiko');
        console.log(settings)
        return (
            <>
                {settings.map(franshise => {
                    return (
                        <Card title={franshise.fName} style={{ width: '100%', marginBottom: 20 }} size="small">
                            <Typography>Название точки: {franshise.fName}</Typography>
                            <Typography>API Логин: {franshise.fApiLogin}</Typography>
                            <Typography>Город: {franshise.fCityName}</Typography>
                            <Typography>ID - точки: {franshise.fId}</Typography>
                            <Button type="primary" icon="save" onClick={() => checkSumoIikoPress(franshise.fId)}>Проверить доступность</Button>
                            <Button type="primary" icon="save" onClick={() => getSumoIikoMenu(franshise.fId)}>Загрузить меню</Button>
                        </Card>
                    )
                })}
            </>
        )

    }


    if (loading) {
        return (
            <div className="content-div">
                <Divider className="header-div" orientation="left"><Spin /></Divider>
            </div>
        );
    } else {
        return (
            <QueueAnim className="anim-content"
                type={['right', 'left']}
                ease={['easeOutQuart', 'easeInOutQuart']}>
                {!loading ? [
                    <div key="a" className="content-div">
                        <div>
                            <Divider className="header-div" orientation="left">Настройки SUMOEAST</Divider>
                        </div>
                        {renderSumoIiko()}
                    </div>
                ] : null}
            </QueueAnim>
        );
    }

};

const useFetching = (getSumoIikoSettings, dispatch) => {
    useEffect(() => {
        dispatch(getSumoIikoSettings());
    }, []);
};

export default CPSumoIiko;
