import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Row, Divider, Spin, Select, Typography, Layout, Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {
	getBonusSettings,
	editBonusSettings,
	saveBonusSettings
} from "../../actions/bonusSystemActions";
const { Option } = Select;

const CPBonusSystem = props => {
	useFetching(getBonusSettings, useDispatch());
	const dispatch = useDispatch();
	const loading = useSelector(state => state.bonus.isLoading);
	const needSave = useSelector(state => state.bonus.needSave);
	const enabled = useSelector(state => state.bonus.enabled);
	const percentToPay = useSelector(state => state.bonus.percentToPay);
	const autoAdd = useSelector(state => state.bonus.autoAdd);
	const autoRemove = useSelector(state => state.bonus.autoRemove);
	const bonusRate = useSelector(state => state.bonus.bonusRate);
	const autoAddRate = useSelector(state => state.bonus.autoAddRate);


	const handleEditChange = (e, from) => {
		var newEnabled = enabled;
		var newPercentToPay = percentToPay;
		var newAutoAdd = autoAdd;
		var newAutoRemove = autoRemove;
		var newBonusRate = bonusRate;
		var newAutoAddRate = autoAddRate;

		if (from == 'enabled') {
			newEnabled = e;
		}
		if (from == 'percentToPay') {
			const reg = /^-?[0-9]*(\.[0-9]*)?$/;
			if ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '' || e.target.value === '-') {
				if (e.target.value <= 100) {
					newPercentToPay = e.target.value;
				}
			}
		}
		if (from == 'bonusRate') {
			const reg = /^-?[0-9]*(\.[0-9]*)?$/;
			if ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '' || e.target.value === '-') {
				newBonusRate = e.target.value;
			}
		}
		if (from == 'autoAddRate') {
			const reg = /^-?[0-9]*(\.[0-9]*)?$/;
			if ((!isNaN(e.target.value) && reg.test(e.target.value)) || e.target.value === '' || e.target.value === '-') {
					newAutoAddRate = e.target.value;
			}
		}
		if (from == 'autoAdd') {
			newAutoAdd = e;
		}
		if (from == 'autoRemove') {
			newAutoRemove = e;
		}
		const data = {
			newEnabled,
			newPercentToPay,
			newAutoAdd,
			newAutoRemove,
			newBonusRate,
			newAutoAddRate
		}
		dispatch(editBonusSettings(data));
	}

	const handleSaveButton = (e) => {
		const setsettings = {
			enabled,
			percentToPay,
			autoAdd,
			autoRemove,
			bonusRate,
			autoAddRate
		}
		dispatch(saveBonusSettings(setsettings));
	};

	function renderContent() {
		return (
			<div>
				<Typography>Система включена:</Typography>
				<Select
					defaultValue={enabled}
					style={{ width: 220, marginBottom: 10 }}
					onChange={(e) => handleEditChange(e, "enabled")}
				>
					<Option value={0}>Нет</Option>
					<Option value={1}>Да</Option>
				</Select>
				<Typography>Максимальный % от суммы корзины, который можно оплатить бонусными баллами ( не более 100 ):</Typography>
				<Input
					value={percentToPay}
					addonBefore={'%'}
					style={{ width: 220, marginBottom: 10 }}
					onChange={(e) => handleEditChange(e, "percentToPay")}
					placeholder="Input a number"
					maxLength={25}
				/>
				<Typography>Начислять бонусы автоматически:</Typography>
				<Select
					defaultValue={autoAdd}
					style={{ width: 220, marginBottom: 10 }}
					onChange={(e) => handleEditChange(e, "autoAdd")}
				>
					<Option value={0}>Нет</Option>
					<Option value={1}>Да</Option>
				</Select>
				<Typography>Процент от суммы корзины, который начислить бонусами:</Typography>
				<Input
					value={autoAddRate}
					addonBefore={'%'}
					style={{ width: 220, marginBottom: 10 }}
					onChange={(e) => handleEditChange(e, "autoAddRate")}
					placeholder="Input a number"
					maxLength={25}
				/>
				<Typography>Списывать бонусы автоматически:</Typography>
				<Select
					defaultValue={autoRemove}
					style={{ width: 220, marginBottom: 10 }}
					onChange={(e) => handleEditChange(e, "autoRemove")}
				>
					<Option value={0}>Нет</Option>
					<Option value={1}>Да</Option>
				</Select>
				{/* <Typography>Курс бонусных балов к валюте:</Typography>
				<Input
					value={bonusRate}
					addonBefore={'1 к '}
					style={{ width: 220, marginBottom: 10 }}
					onChange={(e) => handleEditChange(e, "bonusRate")}
					placeholder="Input a number"
					maxLength={25}
				/> */}
				<Divider />
				<Button disabled={!needSave} type="primary" icon="save" onClick={handleSaveButton}>
					Сохранить
      			</Button>
			</div>
		)
	}

	if (loading) {
		return (
				<div className="content-div">
					<Divider className="header-div" orientation="left"><Spin /></Divider>
				</div>
		);
	} else {
		return (
				<QueueAnim className="anim-content"
					type={['right', 'left']}
					ease={['easeOutQuart', 'easeInOutQuart']}>
					{!loading ? [
						<div key="a" className="content-div">
							<Divider className="header-div" orientation="left">Бонусная система</Divider>
							<Divider />
							{renderContent()}
						</div>
					] : null}
				</QueueAnim>
		);
	}
};

const useFetching = (getBonusSettings, dispatch) => {
	useEffect(() => {
		dispatch(getBonusSettings());
	}, []);
};

export default CPBonusSystem;
