import {
  GET_INTEGRATIONS,
  LOADING_INTEGRATION_SETTINGS,
  EDIT_ARCHI_INTEGRATION,
  SAVE_ARCHI_INTEGRATION,
  EDIT_DELIVERYCLUB_INTEGRATION,
  SAVE_DELIVERYCLUB_INTEGRATION,
} from "../actions/types";

const initialState = {
  isLoading: true,
  // --- Archi Delivery --- //
  archiEnabled: 0,
  archiSettings: {
    sendOrders: false,
    useBonus: false,
    ip: '',
  },
  needSaveArchi: false,
  // --- Delivery Club --- //
  deliveryClubEnabled: 0,
  needSaveDeliveryclub: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INTEGRATIONS:
      console.log(action.payload);
      return {
        ...state,
        isLoading: false,
        archiEnabled: action.payload.archi.enabled,
        archiSettings: {
          sendOrders: action.payload.archi.settings.sendOrders,
          useBonus: action.payload.archi.settings.useBonus,
          ip: action.payload.archi.settings.ip,
        },
        deliveryClubEnabled: action.payload.deliveryclub.enabled,
      };
    case EDIT_ARCHI_INTEGRATION:
      return {
        ...state,
        needSaveArchi: true,
        archiEnabled: action.payload.newArchiEnabled,
        archiSettings: {
          sendOrders: action.payload.newArchiSettings.newArchiSendOrders,
          useBonus: action.payload.newArchiSettings.newArchiUseBonus,
          ip: action.payload.newArchiSettings.newArchiIp,
        }
      };
    case SAVE_ARCHI_INTEGRATION:
      return{
        ...state,
        needSaveArchi: false,
      };
    case EDIT_DELIVERYCLUB_INTEGRATION:
      return {
        ...state,
        needSaveDeliveryclub: true,
        deliveryClubEnabled: action.payload.newDeliveryClubEnabled,
      };
    case SAVE_DELIVERYCLUB_INTEGRATION:
      return{
        ...state,
        needSaveDeliveryclub: false,
      };
    case LOADING_INTEGRATION_SETTINGS:
      return {
        ...state,
        isLoading: true
      };
    default:
      return state;
  }
}
