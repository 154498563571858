import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { delTabLogo, addTabLogo, addSMLogo, delSMLogo, delSMBlockImg, addSMBlockImg, getSideMenu, getSettings, editSideMenu, editSettings, saveSideMenu, saveSettings, showAddPromoModal, closeAddPromoModal, addPromo, addPromoImg, deletePromo, deletePromoImg, showAddPromoImgModal, closeAddPromoImgModal, editPromo, showEditPromoModal, closeEditPromoModal } from '../../actions/settingActions'
import { Select, Input, Button, Typography, Divider, Spin, Layout, Tabs, Modal, Card, Upload, Popconfirm, Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import serverIp from "../../utils/serverIp";
const { Option } = Select;
const { TabPane } = Tabs;


const CPSettings = props => {
	useFetching(getSettings, getSideMenu, useDispatch());
	const dispatch = useDispatch();
	const loading = useSelector(state => state.settings.isLoading);
	const viewStyle = useSelector(state => state.settings.viewStyle);
	const productStyle = useSelector(state => state.settings.productStyle);
	const mainHeader = useSelector(state => state.settings.mainHeader);

	const headerBackground = useSelector(state => state.settings.headerBackground);
	const headerTextColor = useSelector(state => state.settings.headerTextColor);
	const bottomBackground = useSelector(state => state.settings.bottomBackground);
	const bottomTextColor = useSelector(state => state.settings.bottomTextColor);
	const bottomTextColorActive = useSelector(state => state.settings.bottomTextColorActive);

	const mainBackgroundColor = useSelector(state => state.settings.mainBackgroundColor);
	const secondBackgroundColor = useSelector(state => state.settings.secondBackgroundColor);
	const textHeaderColor = useSelector(state => state.settings.textHeaderColor);
	const headerSliderBackgroundColor = useSelector(state => state.settings.headerSliderBackgroundColor);
	const baseTextColor = useSelector(state => state.settings.baseTextColor);

	const parallaxHeight = useSelector(state => state.settings.parallaxHeight);
	const optionTint = useSelector(state => state.settings.optionTint);
	const optionSelectedTint = useSelector(state => state.settings.optionSelectedTint);
	const optionBackTint = useSelector(state => state.settings.optionBackTint);
	const productInfoStyle = useSelector(state => state.settings.productInfoStyle);

	const mainElementsColor = useSelector(state => state.settings.mainElementsColor);
	const mainElementsTextColor = useSelector(state => state.settings.mainElementsTextColor);

	const extraField1Name = useSelector(state => state.settings.extraField1Name);
	const extraField2Name = useSelector(state => state.settings.extraField2Name);

	const smallCart = useSelector(state => state.settings.smallCart);
	const returnToMenu = useSelector(state => state.settings.returnToMenu);

	const contactPhone = useSelector(state => state.settings.contactPhone);
	const contactEmail = useSelector(state => state.settings.contactEmail);
	const contactWorkTime = useSelector(state => state.settings.contactWorkTime);
	const contactAdress = useSelector(state => state.settings.contactAdress);
	const contactMapLa = useSelector(state => state.settings.contactMapLa);
	const contactMapLo = useSelector(state => state.settings.contactMapLo);
	const contactMapZoom = useSelector(state => state.settings.contactMapZoom);
	const contactFieldName = useSelector(state => state.settings.contactFieldName);
	const contactFieldContent = useSelector(state => state.settings.contactFieldContent);
	const needSave = useSelector(state => state.settings.needSave);
	const isAddPromoModal = useSelector(state => state.settings.isAddPromoModal);
	const promoAdding = useSelector(state => state.settings.promoAdding);
	const promoBlocks = useSelector(state => state.settings.promoBlocks);
	const isAddPromoImgModal = useSelector(state => state.settings.isAddPromoImgModal);
	const promoBlockToAddImg = useSelector(state => state.settings.promoBlockToAddImg);
	const promoImgAdding = useSelector(state => state.settings.promoImgAdding);
	const promoBlockToEdit = useSelector(state => state.settings.promoBlockToEdit);
	const isEditPromoModal = useSelector(state => state.settings.isEditPromoModal);
	const promoEditing = useSelector(state => state.settings.promoEditing);

	const baseStyle = useSelector(state => state.settings.baseStyle);
	const tabLogo = useSelector(state => state.settings.tabLogo);
	const tabLogoAdding = useSelector(state => state.settings.tabLogoAdding);

	const needSaveSM = useSelector(state => state.settings.needSaveSM);
	const smHeader1 = useSelector(state => state.settings.smHeader1);
	const smHeader2 = useSelector(state => state.settings.smHeader2);
	const smHeader3 = useSelector(state => state.settings.smHeader3);
	const smHeader4 = useSelector(state => state.settings.smHeader4);
	const smHeader1_2 = useSelector(state => state.settings.smHeader1_2);
	const smHeader2_2 = useSelector(state => state.settings.smHeader2_2);
	const smHeader3_2 = useSelector(state => state.settings.smHeader3_2);
	const smHeader4_2 = useSelector(state => state.settings.smHeader4_2);

	const smHeader1Image = useSelector(state => state.settings.smHeader1Image);
	const smHeader2Image = useSelector(state => state.settings.smHeader2Image);
	const smHeader3Image = useSelector(state => state.settings.smHeader3Image);
	const smHeader4Image = useSelector(state => state.settings.smHeader4Image);
	const smHeaderLogo = useSelector(state => state.settings.smHeaderLogo);
	const smBlockImgAdding = useSelector(state => state.settings.smBlockImgAdding);

	const smDeliveryArticle = useSelector(state => state.settings.smDeliveryArticle);
	const smAboutArticle = useSelector(state => state.settings.smAboutArticle);
	const smArticles = useSelector(state => state.settings.smArticles);

	const smBlockArticle1 = useSelector(state => state.settings.smBlockArticle1);
	const smBlockArticle2 = useSelector(state => state.settings.smBlockArticle2);
	const smBlockArticle3 = useSelector(state => state.settings.smBlockArticle3);
	const smBlockArticle4 = useSelector(state => state.settings.smBlockArticle4);

	const smBlockPath1 = useSelector(state => state.settings.smBlockPath1);
	const smBlockPath2 = useSelector(state => state.settings.smBlockPath2);
	const smBlockPath3 = useSelector(state => state.settings.smBlockPath3);
	const smBlockPath4 = useSelector(state => state.settings.smBlockPath4);


	const salesDisabled = useSelector(state => state.settings.salesDisabled);
	const salesDisabledTitle = useSelector(state => state.settings.salesDisabledTitle);
	const salesDisabledText = useSelector(state => state.settings.salesDisabledText);


	const androidVersion = useSelector(state => state.settings.androidVersion);
	const appleVersion = useSelector(state => state.settings.appleVersion);
	const androidStoreLink = useSelector(state => state.settings.androidStoreLink);
	const appleStoreLink = useSelector(state => state.settings.appleStoreLink);

	const showAddPromo = useCallback(
		() => dispatch(showAddPromoModal()),
		[dispatch]
	);

	const showAddImg = promo => {
		dispatch(showAddPromoImgModal(promo));
	};
	const closeAddImg = () => {
		dispatch(closeAddPromoImgModal());
		setValuesImg({
			image: ''
		});
	};

	const closeAddPromo = useCallback(
		() => dispatch(closeAddPromoModal()),
		[dispatch]
	);

	const showEditPromo = promo => {
		setEditPromoValues({
			...editpromovalues,
			_id: promo._id,
			text: promo.text,
			image: promo.image,
			itemId: promo.itemId
		});
		dispatch(showEditPromoModal(promo));
	};

	const closeEditPromo = () => {
		dispatch(closeEditPromoModal());
	};

	const handleDeletePromo = (promo) => {
		dispatch(deletePromo(promo));
	};

	const handleEditPromoChange = name => event => {
		setEditPromoValues({ ...editpromovalues, [name]: event.target.value });
	};

	const handleEditPromo = () => {
		dispatch(editPromo(editpromovalues));
	};

	const handleAddPromo = () => {
		console.log('handleAddPromo');
		dispatch(addPromo(promoAddingValues));
		setPromoAddingValues({
			text: "",
			itemId: ""
		});
	};

	const handleImageDelete = (img, promo) => event => {
		setValuesImg({ ...valuesImg, ['image']: '' });
		dispatch(deletePromoImg(promo));
	};

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	const dummyRequest = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess("ok");
		}, 0);
	};

	const [valuesImg, setValuesImg] = React.useState({
		image: ''
	});

	const [editpromovalues, setEditPromoValues] = React.useState({
		_id: promoBlockToEdit._id,
		text: promoBlockToEdit.text,
		image: promoBlockToEdit.image,
		itemId: promoBlockToEdit.itemId
	});

	const handleChangePromoImage = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, image => {
				let imageFormObj = new FormData();
				var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", promoBlockToAddImg._id);
				imageFormObj.append("imageData", info.file.originFileObj);
				setValuesImg({ ...valuesImg, "image": imageToShow });
				dispatch(addPromoImg(imageFormObj));
			});
		}
	};
	const uploadButton = (
		<div style={{ width: 220 }}>
			<div className="ant-upload-text">Загрузить</div>
		</div>
	);

	const [promoAddingValues, setPromoAddingValues] = React.useState({
		text: "",
		itemId: ""
	});

	const handleAddPromoChange = name => event => {
		setPromoAddingValues({ ...promoAddingValues, [name]: event.target.value });
	};

	const handleEditChange = (e, from) => {

		var newAndroidVersion = androidVersion;
		var newAppleVersion = appleVersion;
		var newAndroidStoreLink = androidStoreLink;
		var newAppleStoreLink = appleStoreLink;

		var newViewStyle = viewStyle;
		var newProductStyle = productStyle;
		var newMainHeader = mainHeader;
		var newHeaderBackground = headerBackground;
		var newHeaderTextColor = headerTextColor;
		var newBottomBackground = bottomBackground;
		var newBottomTextColor = bottomTextColor;
		var newBottomTextColorActive = bottomTextColorActive;

		var newMainBackgroundColor = mainBackgroundColor;
		var newSecondBackgroundColor = secondBackgroundColor;
		var newTextHeaderColor = textHeaderColor;
		var newHeaderSliderBackgroundColor = headerSliderBackgroundColor;
		var newBaseTextColor = baseTextColor;

		var newSmallCart = smallCart;
		var newReturnToMenu = returnToMenu;

		var newParallaxHeight = parallaxHeight;
		var newOptionTint = optionTint;
		var newOptionSelectedTint = optionSelectedTint;
		var newOptionBackTint = optionBackTint;
		var newProductInfoStyle = productInfoStyle;
		var newMainElementsColor = mainElementsColor;
		var newMainElementsTextColor = mainElementsTextColor;
		var newExtraField1Name = extraField1Name;
		var newExtraField2Name = extraField2Name;
		var newContactPhone = contactPhone;
		var newContactEmail = contactEmail;
		var newContactWorkTime = contactWorkTime;
		var newContactAdress = contactAdress;
		var newContactMapLa = contactMapLa;
		var newContactMapLo = contactMapLo;
		var newContactMapZoom = contactMapZoom;
		var newContactFieldName = contactFieldName;
		var newContactFieldContent = contactFieldContent;

		var newBaseStyle = baseStyle;

		if (from == 'baseStyle') {
			newBaseStyle = e;
		}

		if (from == 'viewStyle') {
			newViewStyle = e;
		}
		if (from == 'productStyle') {
			newProductStyle = e;
		}
		if (from == 'productInfoStyle') {
			newProductInfoStyle = e;
		}

		if (from == 'smallCart') {
			newSmallCart = e;
		}
		if (from == 'returnToMenu') {
			newReturnToMenu = e;
		}

		if (e.target != null) {

			if (e.target.name == 'androidVersion') {
				newAndroidVersion = e.target.value;
			}
			if (e.target.name == 'appleVersion') {
				newAppleVersion = e.target.value;
			}

			if (e.target.name == 'androidStoreLink') {
				newAndroidStoreLink = e.target.value;
			}
			if (e.target.name == 'appleStoreLink') {
				newAppleStoreLink = e.target.value;
			}

			if (e.target.name == 'mainHeader') {
				newMainHeader = e.target.value;
			}
			if (e.target.name == 'headerBackground') {
				newHeaderBackground = e.target.value;
			}
			if (e.target.name == 'headerTextColor') {
				newHeaderTextColor = e.target.value;
			}
			if (e.target.name == 'bottomBackground') {
				newBottomBackground = e.target.value;
			}
			if (e.target.name == 'bottomTextColor') {
				newBottomTextColor = e.target.value;
			}
			if (e.target.name == 'bottomTextColorActive') {
				newBottomTextColorActive = e.target.value;
			}



			if (e.target.name == 'mainBackgroundColor') {
				newMainBackgroundColor = e.target.value;
			}
			if (e.target.name == 'secondBackgroundColor') {
				newSecondBackgroundColor = e.target.value;
			}
			if (e.target.name == 'textHeaderColor') {
				newTextHeaderColor = e.target.value;
			}
			if (e.target.name == 'headerSliderBackgroundColor') {
				newHeaderSliderBackgroundColor = e.target.value;
			}
			if (e.target.name == 'baseTextColor') {
				newBaseTextColor = e.target.value;
			}

			if (e.target.name == 'parallaxHeight') {
				newParallaxHeight = e.target.value;
			}


			if (e.target.name == 'optionTint') {
				newOptionTint = e.target.value;
			}

			if (e.target.name == 'optionSelectedTint') {
				newOptionSelectedTint = e.target.value;
			}

			if (e.target.name == 'optionBackTint') {
				newOptionBackTint = e.target.value;
			}

			if (e.target.name == 'mainElementsColor') {
				newMainElementsColor = e.target.value;
			}
			if (e.target.name == 'mainElementsTextColor') {
				newMainElementsTextColor = e.target.value;
			}
			if (e.target.name == 'extraField1Name') {
				newExtraField1Name = e.target.value;
			}
			if (e.target.name == 'extraField2Name') {
				newExtraField2Name = e.target.value;
			}
			if (e.target.name == 'contactPhone') {
				newContactPhone = e.target.value;
			}
			if (e.target.name == 'contactEmail') {
				newContactEmail = e.target.value;
			}
			if (e.target.name == 'contactWorkTime') {
				newContactWorkTime = e.target.value;
			}
			if (e.target.name == 'contactAdress') {
				newContactAdress = e.target.value;
			}
			if (e.target.name == 'contactMapLa') {
				newContactMapLa = e.target.value;
			}
			if (e.target.name == 'contactMapLo') {
				newContactMapLo = e.target.value;
			}
			if (e.target.name == 'contactMapZoom') {
				newContactMapZoom = e.target.value;
			}
			if (e.target.name == 'contactFieldName') {
				newContactFieldName = e.target.value;
			}
			if (e.target.name == 'contactFieldContent') {
				newContactFieldContent = e.target.value;
			}
		}
		const data = {
			newAndroidVersion,
			newAppleVersion,

			newAndroidStoreLink,
			newAppleStoreLink,

			newBaseStyle,
			newViewStyle,
			newProductStyle,
			newMainHeader,
			newHeaderBackground,
			newHeaderTextColor,
			newBottomBackground,
			newBottomTextColor,
			newBottomTextColorActive,

			newMainBackgroundColor,
			newSecondBackgroundColor,
			newTextHeaderColor,
			newHeaderSliderBackgroundColor,
			newBaseTextColor,

			newSmallCart,
			newReturnToMenu,

			newParallaxHeight,
			newOptionTint,
			newOptionSelectedTint,
			newOptionBackTint,
			newProductInfoStyle,
			newMainElementsColor,
			newMainElementsTextColor,
			newExtraField1Name,
			newExtraField2Name,
			newContactPhone,
			newContactEmail,
			newContactWorkTime,
			newContactAdress,
			newContactMapLa,
			newContactMapLo,
			newContactMapZoom,
			newContactFieldName,
			newContactFieldContent
		}
		dispatch(editSettings(data));
	};
	const handleSaveButton = (e) => {
		const setsettings = {
			androidVersion,
			appleVersion,
			androidStoreLink,
			appleStoreLink,

			baseStyle,
			viewStyle,
			productStyle,
			mainHeader,
			headerBackground,
			headerTextColor,
			bottomBackground,
			bottomTextColor,
			bottomTextColorActive,

			mainBackgroundColor,
			secondBackgroundColor,
			textHeaderColor,
			headerSliderBackgroundColor,
			baseTextColor,

			smallCart,
			returnToMenu,

			parallaxHeight,
			optionTint,
			optionSelectedTint,
			optionBackTint,
			productInfoStyle,
			mainElementsColor,
			mainElementsTextColor,
			extraField1Name,
			extraField2Name,
			contactPhone,
			contactEmail,
			contactWorkTime,
			contactAdress,
			contactMapLa,
			contactMapLo,
			contactMapZoom,
			contactFieldName,
			contactFieldContent
		}
		dispatch(saveSettings(setsettings));
	};


	const handleSMEditChange = (e, from) => {

		var newSmHeader1 = smHeader1;
		var newSmHeader2 = smHeader2;
		var newSmHeader3 = smHeader3;
		var newSmHeader4 = smHeader4;

		var newSmHeader1_2 = smHeader1_2;
		var newSmHeader2_2 = smHeader2_2;
		var newSmHeader3_2 = smHeader3_2;
		var newSmHeader4_2 = smHeader4_2;

		var newSmDeliveryArticle = smDeliveryArticle;
		var newSmAboutArticle = smAboutArticle;

		var newSmBlockArticle1 = smBlockArticle1;
		var newSmBlockArticle2 = smBlockArticle2;
		var newSmBlockArticle3 = smBlockArticle3;
		var newSmBlockArticle4 = smBlockArticle4;

		var newSmBlockPath1 = smBlockPath1;
		var newSmBlockPath2 = smBlockPath2;
		var newSmBlockPath3 = smBlockPath3;
		var newSmBlockPath4 = smBlockPath4;

		var newSalesDisabled = salesDisabled;
		var newSalesDisabledTitle = salesDisabledTitle;
		var newSalesDisabledText = salesDisabledText;

		if (from == 'salesDisabled') {
			newSalesDisabled = e;
		}

		if (from == 'smBlockArticle1') {
			console.log(e);
			smArticles.map(article => {
				if (article._id == e) {
					newSmBlockArticle1.name = article.name;
					newSmBlockArticle1.id = article._id;
				}
			})
		}

		if (from == 'smBlockArticle2') {
			console.log(e);
			smArticles.map(article => {
				if (article._id == e) {
					newSmBlockArticle2.name = article.name;
					newSmBlockArticle2.id = article._id;
				}
			})
		}

		if (from == 'smBlockArticle3') {
			console.log(e);
			smArticles.map(article => {
				if (article._id == e) {
					newSmBlockArticle3.name = article.name;
					newSmBlockArticle3.id = article._id;
				}
			})
		}

		if (from == 'smBlockArticle4') {
			console.log(e);
			smArticles.map(article => {
				if (article._id == e) {
					newSmBlockArticle4.name = article.name;
					newSmBlockArticle4.id = article._id;
				}
			})
		}


		if (from == 'smDeliveryArticle') {
			console.log(e);

			smArticles.map(article => {
				if (article._id == e) {
					newSmDeliveryArticle.name = article.name;
					newSmDeliveryArticle.id = article._id;
				}
			})
			//newSmDeliveryArticle = e;
		}

		if (from == 'smAboutArticle') {
			console.log(e);
			smArticles.map(article => {
				if (article._id == e) {
					newSmAboutArticle.name = article.name;
					newSmAboutArticle.id = article._id;
				}
			})
		}

		if (e.target != null) {
			if (e.target.name == 'salesDisabledTitle') {
				newSalesDisabledTitle = e.target.value;
			}
			if (e.target.name == 'salesDisabledText') {
				newSalesDisabledText = e.target.value;
			}
			if (e.target.name == 'smHeader1') {
				newSmHeader1 = e.target.value;
			}
			if (e.target.name == 'smHeader2') {
				newSmHeader2 = e.target.value;
			}
			if (e.target.name == 'smHeader3') {
				newSmHeader3 = e.target.value;
			}
			if (e.target.name == 'smHeader4') {
				newSmHeader4 = e.target.value;
			}
			if (e.target.name == 'smHeader1_2') {
				newSmHeader1_2 = e.target.value;
			}
			if (e.target.name == 'smHeader2_2') {
				newSmHeader2_2 = e.target.value;
			}
			if (e.target.name == 'smHeader3_2') {
				newSmHeader3_2 = e.target.value;
			}
			if (e.target.name == 'smHeader4_2') {
				newSmHeader4_2 = e.target.value;
			}
			if (e.target.name == 'smBlockPath1') {
				newSmBlockPath1 = e.target.value;
			}
			if (e.target.name == 'smBlockPath2') {
				newSmBlockPath2 = e.target.value;
			}
			if (e.target.name == 'smBlockPath3') {
				newSmBlockPath3 = e.target.value;
			}
			if (e.target.name == 'smBlockPath4') {
				newSmBlockPath4 = e.target.value;
			}
		}

		const data = {

			newSalesDisabled,
			newSalesDisabledTitle,
			newSalesDisabledText,
			newSmHeader1,
			newSmHeader2,
			newSmHeader3,
			newSmHeader4,
			newSmHeader1_2,
			newSmHeader2_2,
			newSmHeader3_2,
			newSmHeader4_2,
			newSmDeliveryArticle,
			newSmAboutArticle,
			newSmBlockArticle1,
			newSmBlockArticle2,
			newSmBlockArticle3,
			newSmBlockArticle4,
			newSmBlockPath1,
			newSmBlockPath2,
			newSmBlockPath3,
			newSmBlockPath4,
		}
		dispatch(editSideMenu(data));

	}

	const handleSaveSMButton = (e) => {
		const setsettings = {
			salesDisabled,
			salesDisabledTitle,
			salesDisabledText,
			smHeader1,
			smHeader2,
			smHeader3,
			smHeader4,
			smHeader1_2,
			smHeader2_2,
			smHeader3_2,
			smHeader4_2,
			smDeliveryArticle,
			smAboutArticle,
			smHeader1Image,
			smHeader2Image,
			smHeader3Image,
			smHeader4Image,
			smHeaderLogo,
			smBlockArticle1,
			smBlockArticle2,
			smBlockArticle3,
			smBlockArticle4,
			smBlockPath1,
			smBlockPath2,
			smBlockPath3,
			smBlockPath4
		}
		dispatch(saveSideMenu(setsettings));
	};

	const [valuesTabLogo, setValuesTabLogo] = React.useState({
		image: ''
	});

	const [valuesSMImage1, setValuesSMImage1] = React.useState({
		image: ''
	});
	const [valuesSMImage2, setValuesSMImage2] = React.useState({
		image: ''
	});
	const [valuesSMImage3, setValuesSMImage3] = React.useState({
		image: ''
	});
	const [valuesSMImage4, setValuesSMImage4] = React.useState({
		image: ''
	});
	const [valuesSMLogo, setValuesSMLogo] = React.useState({
		image: ''
	});

	const changeTabLogo = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, image => {
				let imageFormObj = new FormData();
				var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", 1);
				imageFormObj.append("imageData", info.file.originFileObj);
				setValuesSMImage1({ ...valuesTabLogo, "image": imageToShow });
				dispatch(addTabLogo(imageFormObj));
			});
		}
	};

	const changeSMBlock1Image = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, image => {
				let imageFormObj = new FormData();
				var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", 1);
				imageFormObj.append("imageData", info.file.originFileObj);
				setValuesSMImage1({ ...valuesSMImage1, "image": imageToShow });
				dispatch(addSMBlockImg(imageFormObj));
			});
		}
	};

	const changeSMBlock2Image = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, image => {
				let imageFormObj = new FormData();
				var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", 2);
				imageFormObj.append("imageData", info.file.originFileObj);
				setValuesSMImage2({ ...valuesSMImage2, "image": imageToShow });
				dispatch(addSMBlockImg(imageFormObj));
			});
		}
	};

	const changeSMBlock3Image = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, image => {
				let imageFormObj = new FormData();
				var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", 3);
				imageFormObj.append("imageData", info.file.originFileObj);
				setValuesSMImage3({ ...valuesSMImage3, "image": imageToShow });
				dispatch(addSMBlockImg(imageFormObj));
			});
		}
	};

	const changeSMBlock4Image = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, image => {
				let imageFormObj = new FormData();
				var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", 4);
				imageFormObj.append("imageData", info.file.originFileObj);
				setValuesSMImage4({ ...valuesSMImage4, "image": imageToShow });
				dispatch(addSMBlockImg(imageFormObj));
			});
		}
	};

	const changeSMHeaderLogo = info => {
		if (info.file.status === 'done') {
			getBase64(info.file.originFileObj, image => {
				let imageFormObj = new FormData();
				var imageToShow = URL.createObjectURL(info.file.originFileObj);
				imageFormObj.append("id", 1);
				imageFormObj.append("imageData", info.file.originFileObj);
				setValuesSMLogo({ ...valuesSMLogo, "image": imageToShow });
				dispatch(addSMLogo(imageFormObj));
			});
		}
	};

	const handleDelTabLogo = () => event => {
		setValuesTabLogo({ ...valuesTabLogo, ['image']: '' });
		dispatch(delTabLogo());
	};

	const handleDelSMLogo = (img, logo) => event => {
		setValuesSMLogo({ ...valuesSMLogo, ['image']: '' });
		dispatch(delSMLogo(logo));
	};

	const handleDelSMBlockImg = (img, promo) => event => {
		if (promo == 1) {
			setValuesSMImage1({ ...valuesSMImage1, ['image']: '' });
		}
		if (promo == 2) {
			setValuesSMImage2({ ...valuesSMImage2, ['image']: '' });
		}
		if (promo == 3) {
			setValuesSMImage3({ ...valuesSMImage3, ['image']: '' });
		}
		if (promo == 4) {
			setValuesSMImage4({ ...valuesSMImage4, ['image']: '' });
		}
		// setValuesSMImage1({ ...valuesSMImage1, ['image']: '' });
		dispatch(delSMBlockImg(promo));
	};

	function ControlButtons(promo) {
		return (
			<div>
				<Button style={{ marginRight: 5 }} size="small" type="primary" shape="circle" icon="picture" onClick={() => showAddImg(promo)} />
				<Popconfirm title="Удалить промо-блок?" okText="Да" okType="danger" cancelText="Нет" onConfirm={() => handleDeletePromo(promo)}>
					<Button style={{ marginRight: 5 }} size="small" type="danger" shape="circle" icon="delete" />
				</Popconfirm>
				<Button style={{ marginRight: 5 }} size="small" type="primary" shape="circle" icon="edit" onClick={() => showEditPromo(promo)} />
			</div>
		);
	}

	function renderPromos() {
		return (
			<div>
				{promoBlocks.map((promo, i) => (
					<Card title={promo.text} style={{ width: '100%', marginBottom: 20 }} key={i} size="small" extra={ControlButtons(promo)}>
						<Row>
							<Col>
								{promo.image != '' ?
									<img src={serverIp + promo.image} alt="avatar" style={{ maxWidth: 400, width: '100%' }} />
									: (
										<div></div>
									)}
								<br /><br />
							</Col>
						</Row>
					</Card>
				))}
			</div>
		);
	}

	function renderSideMenu() {
		return (
			<div>
				<Row gutter={16}>
					<Col className="gutter-row" xs={12} sm={6}>
						<Typography>Отключить продажи в приложении:</Typography>
						<Select
							defaultValue={salesDisabled}
							style={{ width: 220, marginBottom: 10 }}
							name="salesDisabled"
							onChange={(e) => handleSMEditChange(e, "salesDisabled")}
						>
							<Option value={false}>Нет</Option>
							<Option value={true}>Да</Option>
						</Select>
						<Typography>Заголовок сообщения:</Typography>
						<Input
							value={salesDisabledTitle}
							name="salesDisabledTitle"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "salesDisabledTitle")}
						/>
						<Typography>Текст сообщения:</Typography>
						<Input
							value={salesDisabledText}
							name="salesDisabledText"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "salesDisabledText")}
						/>

					</Col>
				</Row>
				<Divider />

				<Row gutter={16}>

					<Col className="gutter-row" xs={12} sm={6}>
						<Typography>Логотип в шапке:</Typography>
						<div style={{ width: 220 }}>
							{!smBlockImgAdding ? (<Upload
								style={{ width: 220 }}
								name="avatar"
								listType="picture-card"
								className="avatar-uploader"
								showUploadList={false}
								customRequest={dummyRequest}
								onChange={changeSMHeaderLogo}
							>
								{smHeaderLogo != '' ? <img src={serverIp + smHeaderLogo} alt="avatar" style={{ width: 200 }} /> : uploadButton}
							</Upload>
							) : (<><Spin /></>)}
							{(smHeaderLogo != '') ? (<Button type="danger" style={{ width: 220 }} onClick={handleDelSMLogo('image', 1)} disabled={smBlockImgAdding}>Очистить</Button>) : (<div></div>)}
						</div>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col className="gutter-row" xs={12} sm={6}>
						<Typography>Заголовок Блок №1:</Typography>
						<Input
							value={smHeader1}
							name="smHeader1"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "smHeader1")}
						/>
						<Typography>Заголовок Блок №1 вторая строка:</Typography>
						<Input
							value={smHeader1_2}
							name="smHeader1_2"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "smHeader1_2")}
						/>
						<Typography>Изображение Блок №1:</Typography>
						<div style={{ width: 220 }}>
							{!smBlockImgAdding ? (<Upload
								style={{ width: 220 }}
								name="avatar"
								listType="picture-card"
								className="avatar-uploader"
								showUploadList={false}
								customRequest={dummyRequest}
								onChange={changeSMBlock1Image}
							>
								{smHeader1Image != '' ? <img src={serverIp + smHeader1Image} alt="avatar" style={{ width: 200 }} /> : uploadButton}
							</Upload>
							) : (<><Spin /></>)}
							{(smHeader1Image != '') ? (<Button type="danger" style={{ width: 220, marginBottom: 10 }} onClick={handleDelSMBlockImg('image', 1)} disabled={smBlockImgAdding}>Очистить</Button>) : (<div style={{ marginBottom: 10 }}></div>)}
						</div>
						<Typography>Ссылка на статью №1:</Typography>
						<Select
							defaultValue={smBlockArticle1.name}
							style={{ width: 220, marginBottom: 10 }}
							name="smBlockArticle1"
							onChange={(e) => handleSMEditChange(e, "smBlockArticle1")}
						>
							{smArticles.map(article => {
								return (
									<Option key={article.name} value={article._id}>{article.name}</Option>
								);
							})}
						</Select>
						<Typography>Название пути, куда ведет блок. ( заполнять, если хотите перезаписать ссылку на статью ):</Typography>
						<Input
							value={smBlockPath1}
							name="smBlockPath1"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "smBlockPath1")}
						/>
					</Col>
					<Col className="gutter-row" xs={12} sm={6}>
						<Typography>Заголовок Блок №2:</Typography>
						<Input
							value={smHeader2}
							name="smHeader2"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "smHeader2")}
						/>
						<Typography>Заголовок Блок №2 вторая строка:</Typography>
						<Input
							value={smHeader2_2}
							name="smHeader2_2"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "smHeader2_2")}
						/>
						<Typography>Изображение Блок №2:</Typography>
						<div style={{ width: 220 }}>
							{!smBlockImgAdding ? (<Upload
								style={{ width: 220 }}
								name="avatar"
								listType="picture-card"
								className="avatar-uploader"
								showUploadList={false}
								customRequest={dummyRequest}
								onChange={changeSMBlock2Image}
							>
								{smHeader2Image != '' ? <img src={serverIp + smHeader2Image} alt="avatar" style={{ width: 200 }} /> : uploadButton}
							</Upload>
							) : (<><Spin /></>)}
							{(smHeader2Image != '') ? (<Button type="danger" style={{ width: 220, marginBottom: 10 }} onClick={handleDelSMBlockImg('image', 2)} disabled={smBlockImgAdding}>Очистить</Button>) : (<div style={{ marginBottom: 10 }}></div>)}
						</div>
						<Typography>Ссылка на статью №2:</Typography>
						<Select
							defaultValue={smBlockArticle2.name}
							style={{ width: 220, marginBottom: 10 }}
							name="smBlockArticle2"
							onChange={(e) => handleSMEditChange(e, "smBlockArticle2")}
						>
							{smArticles.map(article => {
								return (
									<Option key={article.name} value={article._id}>{article.name}</Option>
								);
							})}
						</Select>
						<Typography>Название пути, куда ведет блок. ( заполнять, если хотите перезаписать ссылку на статью ):</Typography>
						<Input
							value={smBlockPath2}
							name="smBlockPath2"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "smBlockPath2")}
						/>
					</Col>
					<Col className="gutter-row" xs={12} sm={6}>
						<Typography>Заголовок Блок №3:</Typography>
						<Input
							value={smHeader3}
							name="smHeader3"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "smHeader3")}
						/>
						<Typography>Заголовок Блок №3 вторая строка:</Typography>
						<Input
							value={smHeader3_2}
							name="smHeader3_2"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "smHeader3_2")}
						/>
						<Typography>Изображение Блок №3:</Typography>
						<div style={{ width: 220 }}>
							{!smBlockImgAdding ? (<Upload
								style={{ width: 220 }}
								name="avatar"
								listType="picture-card"
								className="avatar-uploader"
								showUploadList={false}
								customRequest={dummyRequest}
								onChange={changeSMBlock3Image}
							>
								{smHeader3Image != '' ? <img src={serverIp + smHeader3Image} alt="avatar" style={{ width: 200 }} /> : uploadButton}
							</Upload>
							) : (<><Spin /></>)}
							{(smHeader3Image != '') ? (<Button type="danger" style={{ width: 220, marginBottom: 10 }} onClick={handleDelSMBlockImg('image', 3)} disabled={smBlockImgAdding}>Очистить</Button>) : (<div style={{ marginBottom: 10 }}></div>)}
						</div>
						<Typography>Ссылка на статью №3:</Typography>
						<Select
							defaultValue={smBlockArticle3.name}
							style={{ width: 220, marginBottom: 10 }}
							name="smBlockArticle3"
							onChange={(e) => handleSMEditChange(e, "smBlockArticle3")}
						>
							{smArticles.map(article => {
								return (
									<Option key={article.name} value={article._id}>{article.name}</Option>
								);
							})}
						</Select>
						<Typography>Название пути, куда ведет блок. ( заполнять, если хотите перезаписать ссылку на статью ):</Typography>
						<Input
							value={smBlockPath3}
							name="smBlockPath3"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "smBlockPath3")}
						/>
					</Col>
					<Col className="gutter-row" xs={12} sm={6}>
						<Typography>Заголовок Блок №4:</Typography>
						<Input
							value={smHeader4}
							name="smHeader4"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "smHeader4")}
						/>
						<Typography>Заголовок Блок №4 вторая строка:</Typography>
						<Input
							value={smHeader4_2}
							name="smHeader4_2"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "smHeader4_2")}
						/>
						<Typography>Изображение Блок №4:</Typography>
						<div style={{ width: 220 }}>
							{!smBlockImgAdding ? (<Upload
								style={{ width: 220 }}
								name="avatar"
								listType="picture-card"
								className="avatar-uploader"
								showUploadList={false}
								customRequest={dummyRequest}
								onChange={changeSMBlock4Image}
							>
								{smHeader4Image != '' ? <img src={serverIp + smHeader4Image} alt="avatar" style={{ width: 200 }} /> : uploadButton}
							</Upload>
							) : (<><Spin /></>)}
							{(smHeader4Image != '') ? (<Button type="danger" style={{ width: 220, marginBottom: 10 }} onClick={handleDelSMBlockImg('image', 4)} disabled={smBlockImgAdding}>Очистить</Button>) : (<div style={{ marginBottom: 10 }}></div>)}
						</div>
						<Typography>Ссылка на статью №4:</Typography>
						<Select
							defaultValue={smBlockArticle4.name}
							style={{ width: 220, marginBottom: 10 }}
							name="smBlockArticle4"
							onChange={(e) => handleSMEditChange(e, "smBlockArticle4")}
						>
							{smArticles.map(article => {
								return (
									<Option key={article.name} value={article._id}>{article.name}</Option>
								);
							})}
						</Select>
						<Typography>Название пути, куда ведет блок. ( заполнять, если хотите перезаписать ссылку на статью ):</Typography>
						<Input
							value={smBlockPath4}
							name="smBlockPath4"
							style={{ width: 220, marginBottom: 10 }}
							onChange={(e) => handleSMEditChange(e, "smBlockPath4")}
						/>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col className="gutter-row" xs={12} sm={6}>
						<Typography>Статья для страницы "О доставке":</Typography>
						<Select
							defaultValue={smDeliveryArticle.name}
							style={{ width: 220, marginBottom: 10 }}
							name="smDeliveryArticle"
							onChange={(e) => handleSMEditChange(e, "smDeliveryArticle")}
						>
							{smArticles.map(article => {
								return (
									<Option key={article.name} value={article._id}>{article.name}</Option>
								);
							})}
						</Select>
					</Col>
					<Col className="gutter-row" xs={12} sm={6}>
						<Typography>Статья для страницы "О нас":</Typography>
						<Select
							defaultValue={smAboutArticle.name}
							style={{ width: 220, marginBottom: 10 }}
							name="smAboutArticle"
							onChange={(e) => handleSMEditChange(e, "smAboutArticle")}
						>
							{smArticles.map(article => {
								return (
									<Option key={article.name} value={article._id}>{article.name}</Option>
								);
							})}
						</Select>
					</Col>
				</Row>
				<Divider />

				<Button disabled={!needSaveSM} type="primary" icon="save" onClick={handleSaveSMButton}>
					Сохранить настройки
      			</Button>
			</div>
		)
	}

	if (loading) {
		return (
			<div className="content-div">
				<Divider className="header-div" orientation="left"><Spin /></Divider>
			</div>
		);
	} else {
		return (
			<QueueAnim className="anim-content"
				type={['right', 'left']}
				ease={['easeOutQuart', 'easeInOutQuart']}>
				{!loading ? [
					<div key="a" className="content-div">
						<div>
							<Divider className="header-div" orientation="left">Настройки приложения</Divider>
						</div>
						<Tabs defaultActiveKey="1">
							<TabPane tab="Общие настройки" key="1">

								<Typography>Версия Android:</Typography>
								<Input
									value={androidVersion}
									name="androidVersion"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "androidVersion")}
								/>
								<Typography>Ссылка на магазин Android:</Typography>
								<Input
									value={androidStoreLink}
									name="androidStoreLink"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "androidStoreLink")}
								/>
								<Typography>Версия Apple:</Typography>
								<Input
									value={appleVersion}
									name="appleVersion"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "appleVersion")}
								/>
								<Typography>Ссылка на магазин Apple:</Typography>
								<Input
									value={appleStoreLink}
									name="appleStoreLink"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "appleStoreLink")}
								/>
								<Typography>Базовый вид приложения:</Typography>
								<Select
									defaultValue={baseStyle}
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "baseStyle")}
								>
									<Option value={0}>Табы</Option>
									<Option value={1}>Сайд меню</Option>
								</Select>


								<Typography>Логотип в шапке ( квадратное изображение ):</Typography>
								<div style={{ width: 220 }}>
									{!tabLogoAdding ? (<Upload
										style={{ width: 220 }}
										name="avatar"
										listType="picture-card"
										className="avatar-uploader"
										showUploadList={false}
										customRequest={dummyRequest}
										onChange={changeTabLogo}
									>
										{tabLogo != '' ? <img src={serverIp + tabLogo} alt="avatar" style={{ width: 200 }} /> : uploadButton}
									</Upload>
									) : (<><Spin /></>)}
									{(tabLogo != '') ? (<Button type="danger" style={{ width: 220, marginBottom: 10 }} onClick={handleDelTabLogo()} disabled={tabLogoAdding}>Очистить</Button>) : (<div style={{ marginBottom: 10 }}></div>)}
								</div>


								<Typography>Тип меню в приложении:</Typography>
								<Select
									defaultValue={viewStyle}
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "viewStyle")}
								>
									<Option value={1}>Список</Option>
									<Option value={2}>Свайп</Option>
								</Select>

								<Typography>Тип отображения товаров в приложении:</Typography>
								<Select
									defaultValue={productStyle}
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "productStyle")}
								>
									<Option value={1}>Тип 1</Option>
									<Option value={2}>Тип 2</Option>
									<Option value={3}>Тип 3</Option>
								</Select>

								<Typography>Тип отображения страницы "Подробнее":</Typography>
								<Select
									defaultValue={productInfoStyle}
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "productInfoStyle")}
								>
									<Option value={1}>Тип 1</Option>
									<Option value={2}>Тип 2</Option>
									<Option value={3}>Тип 3</Option>
									<Option value={4}>Тип 4</Option>
								</Select>

								<Typography>Уменьшенная версию корзины:</Typography>
								<Select
									defaultValue={smallCart}
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "smallCart")}
								>
									<Option value={0}>Нет</Option>
									<Option value={1}>Да</Option>
								</Select>
								<Typography>Закрывать информацию о товаре, после добавления в корзину:</Typography>
								<Select
									defaultValue={returnToMenu}
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "returnToMenu")}
								>
									<Option value={0}>Нет</Option>
									<Option value={1}>Да</Option>
								</Select>

								<Typography>Главный заголовок:</Typography>
								<Input
									value={mainHeader}
									name="mainHeader"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "mainHeader")}
								/>

								<Typography>Цвет главного фона (#):</Typography>
								<Input
									value={mainBackgroundColor}
									name="mainBackgroundColor"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "mainBackgroundColor")}
								/>
								<Typography>Цвет второстепенного фона (#):</Typography>
								<Input
									value={secondBackgroundColor}
									name="secondBackgroundColor"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "secondBackgroundColor")}
								/>
								<Typography>Цвет заголовков (#):</Typography>
								<Input
									value={textHeaderColor}
									name="textHeaderColor"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "textHeaderColor")}
								/>
								<Typography>Базовый цвет текста (#):</Typography>
								<Input
									value={baseTextColor}
									name="baseTextColor"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "baseTextColor")}
								/>
								<Typography>Цвет фона в слайдере (#):</Typography>
								<Input
									value={headerSliderBackgroundColor}
									name="headerSliderBackgroundColor"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "headerSliderBackgroundColor")}
								/>

								<Typography>Цвет шапки (#):</Typography>
								<Input
									value={headerBackground}
									name="headerBackground"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "headerBackground")}
								/>
								<Typography>Цвет текста шапки (#):</Typography>
								<Input
									value={headerTextColor}
									name="headerTextColor"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "headerTextColor")}
								/>
								<Typography>Цвет футера (#):</Typography>
								<Input
									value={bottomBackground}
									name="bottomBackground"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "bottomBackground")}
								/>
								<Typography>Цвет текста в футере (#):</Typography>
								<Input
									value={bottomTextColor}
									name="bottomTextColor"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "bottomTextColor")}
								/>
								<Typography>Цвет активного текста в футере (#):</Typography>
								<Input
									value={bottomTextColorActive}
									name="bottomTextColorActive"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "bottomTextColorActive")}
								/>

								<Typography>Высота заголовка:</Typography>
								<Input
									value={parallaxHeight}
									name="parallaxHeight"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "parallaxHeight")}
								/>



								<Typography>optionTint (#):</Typography>
								<Input
									value={optionTint}
									name="optionTint"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "optionTint")}
								/>
								<Typography>optionSelectedTint (#):</Typography>
								<Input
									value={optionSelectedTint}
									name="optionSelectedTint"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "optionSelectedTint")}
								/>
								<Typography>optionBackTint (#):</Typography>
								<Input
									value={optionBackTint}
									name="optionBackTint"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "optionBackTint")}
								/>

								<Typography>Основной цвет оформления элементов (#):</Typography>
								<Input
									value={mainElementsColor}
									name="mainElementsColor"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "mainElementsColor")}
								/>
								<Typography>Основной цвет текста в оформлении элементов (#):</Typography>
								<Input
									value={mainElementsTextColor}
									name="mainElementsTextColor"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "mainElementsTextColor")}
								/>

								<Typography>Заголовок доп. поля товара №1:</Typography>
								<Input
									value={extraField1Name}
									name="extraField1Name"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "extraField1Name")}
								/>
								<Typography>Заголовок доп. поля товара №2:</Typography>
								<Input
									value={extraField2Name}
									name="extraField2Name"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "extraField2Name")}
								/>
							</TabPane>
							<TabPane tab="Страница контактов" key="2">
								<Typography>Номер телефона:</Typography>
								<Input
									value={contactPhone}
									name="contactPhone"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "contactPhone")}
								/>
								<Typography>Email:</Typography>
								<Input
									value={contactEmail}
									name="contactEmail"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "contactEmail")}
								/>
								<Typography>Время работы:</Typography>
								<Input
									value={contactWorkTime}
									name="contactWorkTime"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "contactWorkTime")}
								/>
								<Typography>Адрес:</Typography>
								<Input
									value={contactAdress}
									name="contactAdress"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "contactAdress")}
								/>
								<Typography>Доп. информация - Заголовок:</Typography>
								<Input
									value={contactFieldName}
									name="contactFieldName"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "contactFieldName")}
								/>
								<Typography>Доп. информация - Текст:</Typography>
								<Input
									value={contactFieldContent}
									name="contactFieldContent"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "contactFieldContent")}
								/>

								<Typography>Координаты для карты (latitude):</Typography>
								<Input
									value={contactMapLa}
									name="contactMapLa"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "contactMapLa")}
								/>
								<Typography>Координаты для карты (longitude):</Typography>
								<Input
									value={contactMapLo}
									name="contactMapLo"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "contactMapLo")}
								/>
								<Typography>Увеличение карты ( 0 - 20 ):</Typography>
								<Input
									value={contactMapZoom}
									name="contactMapZoom"
									style={{ width: 220, marginBottom: 10 }}
									onChange={(e) => handleEditChange(e, "contactMapZoom")}
								/>
							</TabPane>
							<TabPane tab="Промо блоки" key="3">

								{renderPromos()}

								<Button type="primary" icon="plus" onClick={showAddPromo}>
									Добавить блок
      								</Button>
							</TabPane>
							{baseStyle == 1 ? (
								<TabPane tab="Сайд меню" key="4">
									{renderSideMenu()}
								</TabPane>
							) : (<TabPane disabled tab="Сайд меню" key="4">

							</TabPane>)}
						</Tabs>



						<Divider />
						<Button disabled={!needSave} type="primary" icon="save" onClick={handleSaveButton}>
							Сохранить
      						</Button>

						<Modal
							centered
							title="Редактирование промо-блока"
							visible={isEditPromoModal}
							style={{}}
							onCancel={closeEditPromo}
							footer={[
								<Button
									onClick={closeEditPromo}
									key="back"
									type="danger"
									disabled={promoEditing}
								>
									Отмена
									</Button>,
								<Button
									color="secondary"
									onClick={handleEditPromo}
									key="yes"
									type="primary"
									disabled={promoEditing}
								>
									Сохранить
									</Button>,
							]}
						>
							<Input style={{ marginBottom: 10 }} addonBefore="Текст" value={editpromovalues.text} onChange={handleEditPromoChange("text")} />
							<Input style={{ marginBottom: 10 }} addonBefore="ID товара" value={editpromovalues.itemId} onChange={handleEditPromoChange("itemId")} />
						</Modal>

						<Modal
							centered
							title="Добавление промо-блока"
							visible={isAddPromoModal}
							style={{}}
							onCancel={closeAddPromo}
							footer={[
								<Button
									onClick={closeAddPromo}
									key="back"
									type="danger"
									disabled={promoAdding}
								>
									Отмена
									</Button>,
								<Button
									color="secondary"
									onClick={handleAddPromo}
									key="yes"
									type="primary"
									disabled={promoAdding}
								>
									Добавить
									</Button>,
							]}
						>
							<Typography>Текст под промо-блоком:</Typography>
							<Input
								value={promoAddingValues.text}
								name="addPromoText"
								style={{ width: '100%', marginBottom: 10 }}
								onChange={handleAddPromoChange("text")}
							/>
							<Typography>ID товара для перехода:</Typography>
							<Input
								value={promoAddingValues.itemId}
								name="addPromoItemId"
								style={{ width: '100%', marginBottom: 10 }}
								onChange={handleAddPromoChange("itemId")}
							/>
							<Typography>Изображение можно добавить только после создания блока.</Typography>
						</Modal>

						<Modal
							centered
							title="Добавление изображения промо-блока"
							visible={isAddPromoImgModal}
							style={{}}
							onCancel={closeAddImg}
							footer={[
								<Button
									onClick={closeAddImg}
									key="back"
									type="primary"
									disabled={promoImgAdding}
								>
									Отмена
									</Button>,
							]}
						>
							<Upload
								style={{ margin: '0 auto' }}
								name="avatar"
								listType="picture-card"
								className="avatar-uploader"
								showUploadList={false}
								customRequest={dummyRequest}
								onChange={handleChangePromoImage}
							>
								{promoBlockToAddImg.image ? <img src={serverIp + promoBlockToAddImg.image} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
							</Upload>
							{(promoBlockToAddImg.image != '') ? (<Button type="danger" style={{ width: '100%' }} onClick={handleImageDelete('image', promoBlockToAddImg._id)} disabled={promoImgAdding}>Очистить</Button>) : (<div></div>)}
						</Modal>

					</div>
				] : null}
			</QueueAnim>
		);
	}

};


const useFetching = (getSettings, getSideMenu, dispatch) => {
	useEffect(() => {
		dispatch(getSettings());
		dispatch(getSideMenu());
	}, []);
};

export default CPSettings;
